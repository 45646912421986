'use strict';

define('MyStableSettingsDir',[], function () {

    function MyStableSettingsDir() {
        return {
            restrict: 'E',
            scope: true,
            templateUrl: 'js/desktop/modules/Account/templates/desktop/my-stable-settings.html',
            controller: 'MyStableSettingsCtrl'
        };
    }

    return MyStableSettingsDir;
});

