'use strict';

define('FindModalContainerFac',[],
    function () {
        function FindModalContainer() {

            return function(selector) {
                return document.querySelector(selector) ?
                    angular.element(document.querySelector(selector)) :
                    angular.element(document.querySelector('body'));
            };
        }

        return FindModalContainer;
    });

