'use strict';

define('GTMDesktopMod',[
        'GTMFacDecorator',
        'GTMMod'
    ],
    function (GTMFacDecorator) {
        angular.module('TVG.GTM', [
            'TVG.Common.GTM'
        ])
            .decorator('GTMFac', GTMFacDecorator);
    }
);
