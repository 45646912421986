"use strict";

define('RaceReplaysSearchByHorseCtrl',["HorseEntityBuilder", "lodash"], function(horseEntityBuilder, _) {
    function RaceReplaysSearchByHorseCtrl(
        $scope,
        $rootScope,
        $q,
        $timeout,
        RaceReplaysGateway,
        HandicappingRaceReplaysFac,
        $filter,
        GraphPastRaces,
        WagerProfileFac,
        GraphRaceEntities
    ) {
        var vm = this;
        vm.model = $scope.model;

        // initialize horse search
        if (
            vm.model.tabSelected === "Horses" &&
            _searchValueIsValid(vm.model.horseNameSearch)
        ) {
            _searchHorseReplays(vm.model.horseNameSearch.value);
        }

        // search by horse function
        function _searchHorseReplays(horseNameToSearch) {
            vm.model.loading = true;
            vm.model.errorMessage = "";

            if (typeof horseNameToSearch === 'object' && horseNameToSearch !== null){
              _getHorseReplaysList(horseNameToSearch.name, horseNameToSearch.dob);
            } else {
              _getHorseReplaysList(horseNameToSearch, undefined);
            }
        }

         function _searchHorseName(horseName) {

            return GraphRaceEntities.get({
                searchString: horseName,
                horse: true,
                jockey: false,
                trainer: false,
                owner: false,
            })
            .then(function(result){
              var runnersToReturn = result.runners.map(function(runner, index){
                var displayName = runner.name;

                // we need to add the date of birth in front of the horse name when they are duplicates
                for(var count = 0; count < result.runners.length; count++){
                  if(runner.name === result.runners[count].name && index !== count){
                    displayName = runner.name + ' ('+runner.dob +')';
                  }
                }

                return {
                  name: runner.name,
                  dob: runner.dob,
                  displayName: displayName
                };
              });

              return runnersToReturn || [];
            });
        }

        function _getHorseReplayGraphSuccess(response) {
            var pastRaces = [];

            if (Array.isArray(response) && response.length > 0) {
                response.forEach(function(race) {
                    pastRaces.push({
                        distance:
                            race.distanceValue.toFixed(1) +
                            race.distanceCode.toLowerCase(),
                        postTime: race.postTime,
                        raceClass: race.className,
                        raceDate: race.date,
                        raceNumber: race.raceNumber,
                        raceTypeId: race.typeId,
                        raceTypeName: race.typeName,
                        trackAbbr: race.trackCode,
                        trackName: race.trackName,
                        providerTrack: race.trackCode,
                        replayFileName: race.video && race.video.replayFileName
                    });
                });
            }

            _getHorseReplaySuccess(pastRaces);
        }

        function _getHorseReplaysList(horseName, horseDob) {
            vm.model.selectedReplay = {};

            return $rootScope.activeFeatures.enableGraphRaceReplaysByHorse
                ? GraphPastRaces.getRacesByHorseName(
                      WagerProfileFac.getSessionOrGenericProfile(),
                      horseName,
                      {isGreyhound: false},
                      horseDob
                  )
                      .then(_getHorseReplayGraphSuccess, _getHorseReplayFail)
                      .then(function() {
                          $timeout(function() {
                              $rootScope.$apply();
                          });
                      })
                : RaceReplaysGateway.getRacesReplaysByHorseName(horseName).then(
                      _getHorseReplaySuccess,
                      _getHorseReplayFail
                  );
        }

        function _getHorseReplaySuccess(replaysList) {
            _resetTable();
            if (replaysList.length > 0) {
                vm.model.infoMessage = "";
            } else {
                vm.model.infoMessage =
                    $filter("CMSLabels")(
                        "noReplaysFor",
                        "handicappingRaceReplaysLabels"
                    ) + (
                      typeof vm.model.horseNameSearch.value === "object" ?
                        vm.model.horseNameSearch.value.name : vm.model.horseNameSearch.value
                    );
            }
            vm.model.searchToShow = vm.model.horseNameSearch.value;
            if (_.isArray(replaysList) && replaysList.length) {
                vm.model.dataSource = $filter("orderBy")(replaysList, [
                    "-postTime"
                ]);
                vm.model.selectedReplay = vm.model.dataSource[0];
                vm.model.raceReplay = HandicappingRaceReplaysFac.buildResultReplay(
                    vm.model.selectedReplay
                );
            }
            vm.model.loading = false;
            $scope.$emit("pageLoaded", "handicapping#racereplays");
        }

        function _getHorseReplayFail() {
            _resetTable();
            vm.model.errorMessage = $filter("CMSLabels")(
                "failHorseReplayList",
                "handicappingRaceReplaysLabels"
            );
        }

        function _resetTable() {
            vm.model.dataSource = [];
            vm.model.loading = false;
        }

        function _searchValueIsValid(horseSearch) {
            return horseSearch.value.length >= horseSearch.minLength;
        }

        vm.events = {
            searchHorseReplays: _searchHorseReplays,
            searchHorseName: $rootScope.activeFeatures
                .enableGraphRaceReplaysByHorse
                ? _searchHorseName
                : HandicappingRaceReplaysFac.horseNamesFinder.execute.bind(
                      HandicappingRaceReplaysFac.horseNamesFinder
                  )
        };
    }

    RaceReplaysSearchByHorseCtrl.$inject = [
        "$scope",
        "$rootScope",
        "$q",
        "$timeout",
        "RaceReplaysGateway",
        "HandicappingRaceReplaysFac",
        "$filter",
        "GraphPastRaces",
        "WagerProfileFac",
        "GraphRaceEntities"
    ];

    return RaceReplaysSearchByHorseCtrl;
});

