'use strict';

define(
    'AddToBetslipCtrl',[],
    function () {

        function AddToBetslipCtrl($scope, BetslipFactory) {

            $scope.data = BetslipFactory.betslipData;

            $scope.events = {
                onPredefinedBetAmountChanged: BetslipFactory.onPredefinedBetAmountChanged,
                onBetAmountChanged: BetslipFactory.onBetAmountChanged,
                onBetTypeChanged: BetslipFactory.onBetTypeChanged
            };
        }

        AddToBetslipCtrl.$inject = [
            '$scope',
            'BetslipFactory'
        ];

        return AddToBetslipCtrl;
    }
);

