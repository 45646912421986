'use strict';
define(
    'ProgramPageRaceSelectorCtrl',[
        'lodash',
        'MtpDisplayModel',
        'RaceStatusUtils'
    ],
    function (_, MtpDisplay, RaceStatusUtils) {

        function ProgramPageRaceSelector($scope, $rootScope, RaceInfoFac, RaceUrlFac, LocationUnreloadFac, RaceListFac, $location, TrackInfoFac, $timeout) {
            var parameters = RaceUrlFac.getRacesInfoFromRouteParams();
            var previousRaceStatus;

            $scope.model = {
                loading: true,
                tracks: [],
                error: null,
                trackAbbr: parameters.trackAbbr,
                raceNumber: parameters.raceNumber,
                trackName: parameters.trackName,
                trackSelected: {},
                parameters: parameters,
                showDescription: false,
                mtpDisplay: new MtpDisplay()
            };
            function _setRaceOpen() {
                var race = $scope.model.currentRace;

                if (angular.isObject(race)) {
                    $scope.model.raceOpenForBetting = RaceStatusUtils.isRaceOpenForBetting(race);
                    $scope.model.raceOpenForLiveVideo = RaceStatusUtils.isRaceOpenForLiveVideo(race);

                    var raceStatus = race.status.toLowerCase();

                    // the event should be emitted only once, when the race changes its status to "Result" or "Race Off"
                    if (!_.isUndefined(previousRaceStatus) && previousRaceStatus !== raceStatus) {
                        if (RaceStatusUtils.isStatusRaceOfficial(raceStatus)) {
                            $rootScope.$emit('setRaceResults');
                        }

                        if (RaceStatusUtils.isStatusStewardsKey(raceStatus)) {
                            $rootScope.$emit('raceOff', raceStatus);
                        }
                    }

                    previousRaceStatus = race.status.toLowerCase();
                }
            }

            $scope.processTracksList = function (tracks) {
                //Now instead of looking for active tracks we look for all tracks
                TrackInfoFac.getAllTracks().then(function (tracks){
                    $scope.model.tracks = tracks;
                });
                $scope.model.activeTracks = tracks;
                if(!$scope.model.tracks.length){
                    return;
                }

                $scope.model.trackSelected = _.find($scope.model.tracks, function (track) {
                    return track.trackAbbr === $scope.model.trackAbbr;
                });
                if (!angular.isDefined($scope.model.trackSelected)) {
                    var trackAbbr = tracks[0].trackAbbr;
                    var trackName = tracks[0].trackName;
                    var raceNumber;

                    $scope.model.trackAbbr = trackAbbr;
                    $scope.model.trackSelected = tracks[0];
                    $scope.model.trackDataUpdated = false;
                    $scope.model.loading = true;
                    var racesOpenForTrack = _.filter(tracks[0].races, function (race) {
                        return race.status === 'IC' || race.status === 'O';
                    });
                    if (angular.isArray(racesOpenForTrack) && racesOpenForTrack.length > 0) {
                        raceNumber = racesOpenForTrack[0].raceNumber;
                    } else {
                        raceNumber = 1;
                    }

                    $scope.model.raceNumber = raceNumber;

                    if ($location.url().indexOf('racetracks') > -1) {
                        LocationUnreloadFac.pathChange(
                            RaceUrlFac.calculateRacePath(trackAbbr, trackName, raceNumber),
                            true,
                            RaceUrlFac.getRouteParamsForRace(trackAbbr, trackName, raceNumber),
                            ['race:Changed', 'trackChanged']);
                    } else {
                        $location.url(RaceUrlFac.calculateRacePath(trackAbbr, trackName, raceNumber));
                    }
                } else {
                    $scope.model.oldTrackSelected = $scope.model.trackSelected;
                    parameters = RaceUrlFac.getRacesInfoFromRouteParams();
                }

            };

            function _trackChanged() {
                $scope.model.loading = true;
                RaceListFac.getUpcomingRaces($scope.$id);
                _calculateNextTrack();
            }

            $scope.getTrackInfo = function() {
                parameters = RaceUrlFac.getRacesInfoFromRouteParams();
                
                if( !$scope.model.trackSelected ||
                    !$scope.model.trackSelected.trackAbbr ||
                    !$scope.model.trackSelected.name) {
                    return {
                        trackAbbr: parameters.trackAbbr,
                        trackName: parameters.trackName
                    };
                }
                return {
                    trackAbbr: $scope.model.trackSelected.trackAbbr,
                    trackName: $scope.model.trackSelected.name
                };
            };

            $scope.getRaceUrl= function(raceNumber) {
                if (raceNumber) {
                    var trackLinkData = $scope.getTrackInfo();
                    return RaceUrlFac.calculateRacePath(trackLinkData.trackAbbr, trackLinkData.trackName, raceNumber);
                }
                return '';
            };

            function _calculateNextTrack() {
                RaceInfoFac.getTrackCollection($scope.model.trackSelected).then(function (races) {
                    if (races.length) {
                        var raceNumberTemp = RaceListFac.getFirstUpcomingRace(races);
                        var trackLinkData = $scope.getTrackInfo();
                        LocationUnreloadFac.pathChange(
                            RaceUrlFac.calculateRacePath(trackLinkData.trackAbbr, trackLinkData.trackName, raceNumberTemp),
                            true,
                            RaceUrlFac.getRouteParamsForRace(trackLinkData.trackAbbr, trackLinkData.trackName, raceNumberTemp),
                            'race:Changed');
                    } else {
                        $scope.model.trackSelected = $scope.model.oldTrackSelected;
                    }
                    $scope.model.loading = false;
                }, function () {
                    $scope.model.trackSelected = $scope.model.oldTrackSelected;
                    $scope.model.loading = false;
                });
            }

            $scope.updateCurrentRacesValues = function() {
                _setRaceOpen();
                $timeout(function() {
                    $scope.$apply();
                });
            };

            function _changeShowDescription() {
                $scope.model.showDescription = !$scope.model.showDescription;
            }

            function _onTrackChangedForNewTrackSelector(track) {
                if ($scope.model.trackAbbr !== track.trackAbbr) {
                    $rootScope.$broadcast('video:stop', { instanceId: 'video-main-programpage' });
                    $scope.model.trackSelected = track;
                    $scope.model.trackSelected.name = track.trackName;
                    $scope.model.trackAbbr = track.trackAbbr;
                    var raceNumber = track.currentWagerableRace || track.raceNumber;
                    LocationUnreloadFac.pathChange(
                        RaceUrlFac.calculateRacePath(track.trackAbbr, track.trackName, raceNumber),
                        true,
                        RaceUrlFac.getRouteParamsForRace(track.trackAbbr, track.trackName, raceNumber),
                        ['race:Changed', 'trackChanged']);
                }
            }

            function _loadingUpdate() {
                if ($scope.model.trackDataUpdated && $scope.model.trackListReady && $scope.model.loading) {
                    $scope.model.loading = false;
                    $scope.model.trackDataUpdated = false;
                    $scope.model.trackListReady = false;
                }
            }

            $scope.$watch('model.trackDataUpdated', function () {
                _loadingUpdate();
            });

            $scope.$watch('model.trackListReady', function () {
                _loadingUpdate();
            });

            var unbind = [];

            unbind.push($rootScope.$on('trackListReady', function (event, data) {
                $scope.processTracksList(data);
                $scope.model.trackListReady = true;
            }));

            $scope.events = {
                onTrackChangedForNewTrackSelector: _onTrackChangedForNewTrackSelector,
                changeShowDescription: _changeShowDescription,
                trackChanged: _trackChanged
            };

            $scope.$on('$destroy', function () {
                _.forEach(unbind, function (bind) {
                    bind();
                });
            });

        }

        ProgramPageRaceSelector.$inject = [
            '$scope',
            '$rootScope',
            'RaceInfoFac',
            'RaceUrlFac',
            'LocationUnreloadFac',
            'RaceListFac',
            '$location',
            'TrackInfoFac',
            '$timeout'
        ];

        return ProgramPageRaceSelector;
    }
);


