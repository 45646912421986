"use strict";

define('RaceRunnerEntityBuilder',['RaceRunnerEntity'], function (RaceRunnerEntity) {

    function builder() {

        var raceRunner = new RaceRunnerEntity();

        return {

            withBettingInterestNumber: function (biNumber) {
                raceRunner.bettingInterestNumber = biNumber;
                return this;
            },

            withHorse: function (horse) {
                raceRunner.horse = horse;
                return this;
            },

            withOwner: function (owner) {
                raceRunner.owner = owner;
                return this;
            },

            withOdds: function (odds) {
                raceRunner.odds = odds;
                return this;
            },

            withOddsML: function (oddsML) {
                raceRunner.oddsML = oddsML;
                return this;
            },

            withMed: function (med) {
                raceRunner.med = med;
                return this;
            },

            withTrainer: function (trainer) {
                raceRunner.trainer = trainer;
                return this;
            },

            withWeight: function (weight) {
                raceRunner.weight = weight;
                return this;
            },

            withJockey: function (jockey) {
                raceRunner.jockey = jockey;
                return this;
            },

            withAge: function (age) {
                raceRunner.age = age;
                return this;
            },

            withNumberColor: function (nColor) {
                raceRunner.numberColor = nColor;
                return this;
            },

            withSaddleColor: function (sColor) {
                raceRunner.saddleColor = sColor;
                return this;
            },

            withScratched: function (scratched) {
                raceRunner.scratched = scratched;
                return this;
            },

            withSire: function (sire) {
                raceRunner.sire = sire;
                return this;
            },

            withDamSire: function (damSire) {
                raceRunner.damSire = damSire;
                return this;
            },

            withDam: function (dam) {
                raceRunner.dam = dam;
                return this;
            },

            withSex: function (sex) {
                raceRunner.sex = sex;
                return this;
            },

            build: function () {
                return raceRunner;
            }


        };

    }

    return builder;

});

