'use strict';

define('GTMFacDecorator',[],
    function () {
        /**
         * The objective of this decorator is to extend the GTM parameters object with a temporary
         * property: 'navigationPosition'. The idea is to remove this when the next races bar A/B testing finishes.
         *
         * Q: Why not changing the original GTMFac?
         * A: Because it's a temporary add-on to the GTM dataLayers, for A/B test reporting. This way its removal
         * will be easier.
         *
         */
        function gtmFacDecorator($delegate, $rootScope) {
            var gtmEventFn = $delegate.GTMEvent;

            function _getAdditionalParamsExtended(additionalParams) {
                var navigationPositionObject = {};
                var params = additionalParams || {};

                if ($rootScope.showNextRacesBar) {
                    navigationPositionObject.navigationPosition = !$rootScope.showLeftBar ? 'Top Nav' : 'Left Nav';
                }

                return angular.extend(params, navigationPositionObject);
            }

            function newGTMEventFn() {
                // Let's call the original GTMEvent function to get the object that we want to modify.
                var gtmEventObj = gtmEventFn.apply($delegate);
                // This is the function we want to change.
                // We keep a reference to it because this function will continue to be called.
                // The difference is that we want to extend one of its arguments.
                var oldGTMEventObjSendFn = gtmEventObj.send;

                gtmEventObj.send = function (scope, eventName, additionalParams, sendEvent) {
                    return oldGTMEventObjSendFn(
                        scope,
                        eventName,
                        _getAdditionalParamsExtended(additionalParams),
                        sendEvent);
                };

                return gtmEventObj;
            }

            $delegate.GTMEvent = newGTMEventFn;
            return $delegate;
        }

        gtmFacDecorator.$inject = [
            '$delegate',
            '$rootScope'
        ];

        return gtmFacDecorator;
    }
);

