'use strict';

define('MyStableFaqDir',[], function () {

    function MyStableFaqDir() {
        return {
            restrict: 'E',
            templateUrl: 'js/desktop/modules/Account/templates/desktop/my-stable-faq.html',
            controller: 'MyStableFaqCtrl',
            controllerAs: 'vm'
        };
    }

    return MyStableFaqDir;
});

