"use strict";

define('RaceStatusEntityBuilder',['RaceStatusEntity'], function (RaceStatusEntity) {

    function builder(){
        var raceStatus = new RaceStatusEntity();

        return {
            withRaceTypeId: function (raceTypeId) {
                raceStatus.raceTypeId = raceTypeId;
                return this;
            },
            withRaceNumber: function (raceNumber) {
                raceStatus.raceNumber = raceNumber;
                return this;
            },
            withStatus: function (status) {
                raceStatus.status = status;
                return this;
            },
            withPostTime: function (postTime){
                raceStatus.postTime = postTime;
                return this;
            },
            build: function () {
                //return Object.seal(raceResult);
                return raceStatus;
            }
        };
    }

    return builder;

});


