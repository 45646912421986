'use strict';

define('WagerProfileFac',['lodash'], function (_) {

        /**
         * Wager profile factory, used to get/set the wager profile,
         * using user's cookies and hostname information.
         *
         * NOTE: The wager profiles in .net API seem to be missing NJ,
         * PA and IA...
         *
         * Wager profiles form .net API:
         * (Tvg.Api/Tvg.Api.Entities/UserProfile.cs)
         *
         * PORT-Generic
         * PORT-CA
         * PORT-LA
         * PORT-MD
         * PORT-NM
         * PORT-OK
         * PORT-MA
         * PORT-NY
         * PORT-VA
         * PORT-FL
         * PORT-MT
         * PORT-NH
         * PORT-IL
         * PORT-AllTracks
         *
         */
        function WagerProfileFac($rootScope, $location, TvgHostsFac, CookieFac) {

            var WP_COOKIE = 'wp';
            var PROFILES = {
                TVG: 'PORT-Generic',
                NJ: 'PORT-NJ',
                IA: 'PORT-IA',
                PA: 'PORT-PA',
                ALL_TRACKS: 'PORT-AllTracks' // used before user logs in
            };


            /**
             * Get user's wager profile (from cookies).
             * If there's no wp in coookies, a default wager profile will
             * be returned (according hostname or opts.defaultWagerProfile,
             * if set).
             *
             * @param {Object} [opts]                      Options
             * @param {String} [opts.authentication]       Must be logged in to use the WP from cookies
             * @param {String} [opts.defaultWagerProfile]  Fallback default wager profile
             *
             * @type {String} Wager profile
             */
            function getWagerProfile(opts) {
                var wp = '';

                opts = opts || {};

                // if authentication is not required or the user has already
                // logged in
                if (!opts.authentication || $rootScope.userSession) {
                    wp = CookieFac.getCookie(WP_COOKIE);
                }

                // fallback to default wager profile (set by user or from host)
                return wp || opts.defaultWagerProfile || getWagerProfileFromHost();
            }

            /**
             * Get wager profile from cookies, if the session has already
             * started. Fallbacks to "AllTracks" wager profile.
             *
             * @return {String} Wager profile
             */
            function getSessionOrAllTracksProfile() {
                return getWagerProfile({
                    authentication: true,
                    defaultWagerProfile: PROFILES.ALL_TRACKS
                });
            }

            /**
             * Get wager profile from cookies, if the session has already
             * started. Fallbacks to "PORT-Generic" wager profile.
             *
             * @return {String} Wager profile
             */
            function getSessionOrGenericProfile() {
                return getWagerProfile({
                    authentication: true,
                    defaultWagerProfile: PROFILES.TVG
                });
            }

            /**
             * Get default wager profile accoring to the hostname
             * @type {String} Default wager profile
             */
            function getWagerProfileFromHost() {
                var host = $location.host();
                var profiles = [
                    { equalsHost: TvgHostsFac.isNJ,  value: PROFILES.NJ },
                    { equalsHost: TvgHostsFac.isPA,  value: PROFILES.PA },
                    { equalsHost: TvgHostsFac.isIA,  value: PROFILES.IA },
                    { equalsHost: TvgHostsFac.isTVG, value: PROFILES.TVG }
                ];
                var profile = _.find(profiles, function (profile) {
                    var isOk = profile.equalsHost(host);
                    return isOk;
                });

                return profile ?
                    profile.value : PROFILES.TVG;
            }

            /**
             * Set user's wager profile (in cookies)
             * @param {String} wp  New wager profile
             */
            function setWagerProfile(wp) {
                CookieFac.setCookie(WP_COOKIE, wp);
                return;
            }

            /**
             * Clear user's wager profile (from cookies)
             */
            function clearWagerProfile() {
                CookieFac.removeCookie(WP_COOKIE);
                return;
            }


            // exports
            return {
                PROFILES: PROFILES,
                getWagerProfile: getWagerProfile,
                getSessionOrAllTracksProfile: getSessionOrAllTracksProfile,
                getSessionOrGenericProfile: getSessionOrGenericProfile,
                getWagerProfileFromHost: getWagerProfileFromHost,
                setWagerProfile: setWagerProfile,
                clearWagerProfile: clearWagerProfile
            };
        }

        WagerProfileFac.$inject = [
            '$rootScope',
            '$location',
            'TvgHostsFac',
            'CookieFac'
        ];

        return WagerProfileFac;
    });

