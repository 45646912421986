'use strict';

define(
    'NextRacesItemCtrl',[],
    function () {

        function NextRacesItemCtrl($scope, $rootScope, $location, ManageFavoritesFac, GTMFac, LocationUnreloadFac, RaceUrlFac) {

            var newFavTrackWithUserNotLoggedIn = {};
            var tooltip = {
                add: 'Add track to favorites',
                remove: 'Remove track from favorites'
            };

            $scope.events = {
                updateUserFavorites: _updateUserFavorites,
                checkTooltipState: _checkTooltipState,
                clickOnTrackTitle: _clickOnTrackTitle,
                getRaceUrl: _getRaceUrl
            };

            $scope.model = {
                loading: false
            };

            function _checkTooltipState (isFavorite) {
                return isFavorite ? tooltip.remove : tooltip.add;
            }

            function _updateUserFavList(race) {
                ManageFavoritesFac.updateFavTrackList(race.trackAbbr).finally(
                    function () {
                        $scope.model.loading = false;
                    }
                );
            }

            /**
             * Check if user hits add favorite by log in
             * in that case check if it is already a favorite track
             * otherwise add favorite track
             * @param race
             * @param firstTime : user click in fav star without be logged in
             * @private
             */
            function _checkLoginClick(race, firstTime) {

                $scope.model.loading = true;
                if (firstTime) {

                    var favoriteTracksList = ManageFavoritesFac.getFavTrackList();
                    if (favoriteTracksList.indexOf(race.trackAbbr) === -1) {
                        _updateUserFavList(race);
                    }
                    newFavTrackWithUserNotLoggedIn = {};

                }
                else {
                    _updateUserFavList(race);
                }
            }

            function _displayLoginModal() {
                $rootScope.$broadcast('callLoginRequiredModal');
            }

            /**
             * Check if user is logged in
             * In that case update user favorites list
             * Otherwise trigger login modal and save clicked race to update user favorites list if necessary
             * @param race
             * @private
             */
            function _updateUserFavorites(race, index) {
                var p = {};
                index++;
                p.gaEventCategory = 'Site Click';
                p.gaEventAction = GTMFac.Tvg4ScreenName() + ' Click';
                p.gaEventLabel = race.isFavorite ? "Top Nav | Next Races | Remove Favorite Track | " + race.trackName + " | " + index :
                    "Top Nav | Next Races | Add Favorite Track | " + race.trackName + " | " + index;
                p.eventLabel = race.isFavorite ? "NextRaces-RemoveFavoriteTrack" : "NextRaces-AddFavoriteTrack";
                p.raceNumber = "R" + race.raceNumber;
                p.trackName = race.trackName;
                p.screenName = GTMFac.Tvg4ScreenName();
                p.sectionName = GTMFac.Tvg4SectionName();

                if ($scope.model.loading) {
                    return;
                }
                else if ($rootScope.userSession) {
                    _checkLoginClick(race, false);
                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
                }
                else {
                    newFavTrackWithUserNotLoggedIn = race;
                    _displayLoginModal();
                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
                }
            }

            function _buildLocationPathParams(trackAbbr, trackName, raceNumber) {
                var args = [];
                args.push(RaceUrlFac.calculateRacePath(trackAbbr, trackName, raceNumber));
                args.push(false);
                args.push(RaceUrlFac.getRouteParamsForRace(trackAbbr, trackName, raceNumber));
                args.push('race:Changed');
                return args;
            }

            function _getRaceUrl(race) {
                if (race) {
                    return RaceUrlFac.generateRacePath(
                        race.trackAbbr,
                        race.trackName,
                        race.raceNumber,
                        false);
                }
                return '';
            }

            function _changeToRace(race){
                var currLoc = $location.url();
                var res = currLoc.indexOf('/racetracks/');
                if(res > -1){
                    LocationUnreloadFac.pathChange.apply(this, _buildLocationPathParams(race.trackAbbr, race.trackName, race.raceNumber));
                }
                else{
                    $location.url(RaceUrlFac.calculateRacePath(race.trackAbbr, race.trackName, race.raceNumber));
                }

                $scope.$emit('changeToRace');
            }


            function _clickOnTrackTitle(race, index, event) {
                if (event) {
                   event.preventDefault();
                }
                _changeToRace(race);


                var p = {};
                index++;
                p.gaEventCategory = 'Site Click';
                p.gaEventAction = GTMFac.Tvg4ScreenName() + ' Click';
                p.gaEventLabel = race.isFavorite ? "Top Nav | Next Races | Favorite Track | " + race.trackName + " | " + index : "Top Nav | Next Races | " + race.trackName + " | " + index;
                p.eventLabel = race.isFavorite ? "NextRaces-FavoriteTrack" : "NextRaces-Track";
                p.raceNumber = "R" + race.raceNumber;
                p.trackName = race.trackName;
                p.screenName = GTMFac.Tvg4ScreenName();
                p.sectionName = GTMFac.Tvg4SectionName();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
            }

            $scope.$on('login', function () {
                if (_.size(newFavTrackWithUserNotLoggedIn) !== 0) {
                    _checkLoginClick(newFavTrackWithUserNotLoggedIn, true);
                }
            });

        }

        NextRacesItemCtrl.$inject = [
            '$scope',
            '$rootScope',
            '$location',
            'ManageFavoritesFac',
            'GTMFac',
            'LocationUnreloadFac',
            'RaceUrlFac'
        ];
        return NextRacesItemCtrl;
    }
);

