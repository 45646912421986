'use strict';

define('AlertBoxDir',[], function() {

    /**
     * Alert Box component
     * Display feedback to user.
     *
     * @param {String} [type=neutral]
     * @param {Function} [close]
     * @example
     *     <alert-box type="warning" close="events.close({$event: $event})">Awesome warning message</alert-box>
     *
     *     <alert-box ng-repeat="alert in data.alerts" type="alert.type" close="events.close($index)">{{alert.message}}</alert-box>
     *
     * @TODO
     *   - Organize/Simplify the existing ng-class inside the template
     */
    return function AlertBoxDir() {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            templateUrl: 'js/desktop/modules/WidgetsDesktop/templates/alert-box-directive-template.html',
            scope: {
                close: '&onClose',
                type: '@'
            },
            link: function (scope, element, attrs) {
                scope.closeable = !!attrs.onClose;
            }
        };
    };

});

