'use strict';

define(
    'LayoutFac',[],function () {
        function LayoutFac($rootScope, $window, $location, CookieFac) {

            var cookieName = 'layout';


            function _goToDesktop() {
                _setLayoutName('desktop');
                $location.url('/');
            }

            function _goToMobile() {
                _setLayoutName('mobile');
                $location.url('/');
            }

            function _setLayoutName(layoutName) {
                CookieFac.setCookie(cookieName, layoutName);
            }

            function isWindow(currentPage) {
                switch (currentPage) {
                    case "betslip":
                        return true;
                    default:
                        return false;
                }
            }

            function isDesktop() {
                return __LAYOUT === 'desktop';
            }

            function isPhone() {
                return __LAYOUT === 'mobile' && $window.innerWidth < 800;
            }

            function isPhoneLarge() {
                return __LAYOUT === 'mobile' && $window.innerWidth > 500;
            }

            function isTabletLarge() {
                return __LAYOUT === 'mobile' && $window.innerWidth > 1280;
            }

            function _calculateLayout(currentPage) {
                var layout = {};

                if (isDesktop()) {
                    layout.layout = "desktop";
                    if (isWindow(currentPage)) {
                        layout.subLayout = "window";
                    }
                } else if (isPhone()) {
                    layout.layout = "phone";
                    if (isPhoneLarge()) {
                        layout.subLayout = "large";
                    } else {
                        layout.subLayout = "small";
                    }
                } else {
                    layout.layout = "tablet";
                    if (isTabletLarge()) {
                        layout.subLayout = "large";
                    }
                }

                return layout;
            }

            return {
                calculateLayout: _calculateLayout,
                setLayoutName: _setLayoutName,
                goToMobile: _goToMobile,
                goToDesktop: _goToDesktop
            };
        }

        LayoutFac.$inject = [
            '$rootScope',
            '$window',
            '$location',
            'CookieFac'
        ];

        return LayoutFac;
    });

