'use strict';

define(
    'TvgAutocompleteMod',[
        'TvgAutocompleteDir',
        'TvgAutocompleteCtrl',
        'uibootstrap',
        'uibootstraptpls'
    ],
    function TrackSelectorModule(TvgAutocompleteDir, TvgAutocompleteCtrl) {
        angular.module('TVG.AutoComplete', ['ui.bootstrap'])
            .config(['$uibTooltipProvider', function($uibTooltipProvider){
                $uibTooltipProvider.setTriggers({
                    'showTooltip': 'hideTooltip'
                });
            }])
            .controller('TvgAutocompleteCtrl', TvgAutocompleteCtrl)
            .directive('tvgAutocomplete', TvgAutocompleteDir);
    }
);

