'use strict';

define(
    'RaceReplaysInfoHeaderCtrl',[],
    function () {

        function RaceReplaysInfoHeaderCtrl($scope, $rootScope, GTMFac) {

            $scope.events = {
                showDescription: _showDescription,
                hideDescription: _hideDescription
            };

            function _showDescription() {
                $scope.showDescription = true;

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('')
                    .withEventLabel('')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
            }

            function _hideDescription() {
                $scope.showDescription = false;

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('')
                    .withEventLabel('')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
            }
        }

        RaceReplaysInfoHeaderCtrl.$inject = [
            '$scope',
            '$rootScope',
            'GTMFac'
        ];

        return RaceReplaysInfoHeaderCtrl;
    }
);

