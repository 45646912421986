'use strict';

define('DatePickerDir',[], function () {

    function DatePickerDir() {

        return {
            restrict: 'E',
            scope: {
                maxdate: '=',
                mindate: '=',
                customclass: '=',
                datedisabled: '=',
                showWeeks: '=',
                value: '=',
                disable: '='
            },
            replace: false,
            templateUrl: 'js/desktop/modules/WidgetsDesktop/components/datePicker/templates/datepicker-directive-template.html',
            link: function(scope) {
                scope.status = {
                  opened: false
                };

                scope.datePickerOption = {
                    customClass: scope.customclass,
                    dateDisabled: scope.datedisabled,
                    minDate: scope.mindate,
                    maxDate: scope.maxdate,
                    showWeeks: scope.showWeeks || false
                };

                scope.open = function() {
                  scope.status.opened = true;
                };
            }
        };

    }

    return DatePickerDir;

});

