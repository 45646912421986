'use strict';

define('HandlePagination',[],function () {

    function HandlePagination(itemsPerPage, pagesInfo) {
        this.dataSource = [];
        this.itemsPerPage = itemsPerPage;
        this.selectedPage = 1;
        this.numberOfPages = 0;
        this.currentList = [];
        this.pagesInfo = pagesInfo;
    }

    HandlePagination.prototype.setDataSource = function (dataSource) {
        this.dataSource = dataSource;
        this.buildSubset();
    };
    HandlePagination.prototype.buildSubset = function () {
        if (this.dataSource.length === 0) {
            this.selectedPage = 1;
            this.numberOfPages = 0;
            this.currentList = [];
            return;
        }

        this.numberOfPages = Math.floor(this.dataSource.length / this.itemsPerPage);

        if (this.dataSource.length % this.itemsPerPage !== 0) {
            this.numberOfPages = this.numberOfPages + 1;
        }

        this.startIndex = (this.selectedPage - 1) * this.itemsPerPage;

        if ((this.startIndex + this.itemsPerPage) <= this.dataSource.length) {
            this.endIndex = this.startIndex + this.itemsPerPage;
        } else {
            this.endIndex = this.dataSource.length;
        }

        this.currentList = _.slice(this.dataSource, this.startIndex, this.endIndex);

        this.fillPagesInfo();
    };
    HandlePagination.prototype.goToPage = function (page) {
        if (page && page > 0 && page <= this.numberOfPages) {
            this.selectedPage = page;
            this.buildSubset();
        }

    };

    HandlePagination.prototype.fillPagesInfo = function () {
        this.pagesInfo.initialIndex = this.startIndex + 1;
        this.pagesInfo.finalIndex = this.endIndex;
        this.pagesInfo.totalIndex = this.dataSource.length;
        this.pagesInfo.pageSelected = this.selectedPage;
        this.pagesInfo.numberOfPages = this.numberOfPages;
    };

    return HandlePagination;

});

