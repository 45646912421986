'use strict';

define(
    'FullScheduleReloaded',[
        'FullScheduleReloadedCtrl',
        'FullScheduleReloadedFac',
        'FullScheduleReloadedByTrackRaceList',
        'FullScheduleReloadedUpdateFavoriteFeedbackAction'
    ],
    function (FullScheduleReloadedCtrl, FullScheduleReloadedFac, FullScheduleReloadedByTrackRaceList, UpdateFavoriteFeedbackAction) {

        angular.module('TVG.FullScheduleReloaded', [])
            .controller('FullScheduleReloadedCtrl', FullScheduleReloadedCtrl)
            .factory('FullScheduleReloadedFac', FullScheduleReloadedFac)
            .directive('fullScheduleReloaded', function () {
                return {
                    restrict: 'E',
                    scope: {},
                    controller: 'FullScheduleReloadedCtrl',
                    controllerAs: 'fullScheduleCtrl',
                    templateUrl: 'js/desktop/modules/FullScheduleReloaded/templates/full-schedule-reloaded.html'
                };
            })
            .directive('fullScheduleReloadedTableElement', function () {
                return {
                    restrict: 'A',
                    scope: {
                        raceInfo: '=',
                        updateFavorite: '&',
                        raceUrl: '='
                    },
                    templateUrl: 'js/desktop/modules/FullScheduleReloaded/templates/partials/table-element.html'
                };
            })
            .directive('fullScheduleReloadedFeaturedElement', function () {
                return {
                    restrict: 'A',
                    scope: {
                        raceInfo: '=',
                        updateFavorite: '&',
                        raceUrl: '='
                    },
                    templateUrl: 'js/desktop/modules/FullScheduleReloaded/templates/partials/featured-element.html'
                };
            })
            .directive('fullScheduleReloadedByTrackElement', function () {
                return {
                    restrict: 'E',
                    scope: {
                        track: '=',
                        updateFavorite: '&',
                        originComponent: '@',
                    },
                    templateUrl: 'js/desktop/modules/FullScheduleReloaded/templates/partials/by-track-element.html'
                };
            })
            .directive('updateFavoriteActionFeedback', UpdateFavoriteFeedbackAction)
            .directive('fullScheduleReloadedByTrackRacelist', FullScheduleReloadedByTrackRaceList);
    }
);

