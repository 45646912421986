'use strict';

define('FullScheduleReloadedByTrackRaceList',['HandleListNavigation'], function (HandleListNavigation) {
    FullScheduleReloadedByTrackRaceList.$inject = [];

    function FullScheduleReloadedByTrackRaceList() {
        return {
            restrict: 'E',
            scope: {
                trackRaces: '=',
                originComponent: '<',
            },
            templateUrl: 'js/desktop/modules/FullScheduleReloaded/templates/partials/by-track-race-list.html',
            link: function (scope) {
                var initialEndIndex = 6;
                var handleNavigation = new HandleListNavigation(initialEndIndex);
                handleNavigation.setDataSource(scope.trackRaces);
                handleNavigation.buildSubset();

                scope.shownRaces = handleNavigation.currentList;
                scope.hideArrows = scope.shownRaces.length < initialEndIndex;
                scope.showLeftArrow = false;
                scope.showRightArrow = handleNavigation.canGoNext();

                scope.rightClick = function () {
                    scope.showLeftArrow = true;
                    handleNavigation.goNext();
                    handleNavigation.buildSubset();
                    scope.shownRaces = handleNavigation.currentList;
                    scope.showRightArrow = handleNavigation.canGoNext();
                };
                scope.leftClick = function () {
                    scope.showRightArrow = true;
                    handleNavigation.goBack();
                    handleNavigation.buildSubset();
                    scope.shownRaces = handleNavigation.currentList;
                    scope.showLeftArrow = handleNavigation.canGoBack();
                };
            }
        };
    }

    return FullScheduleReloadedByTrackRaceList;
});

