'use strict';

define(
    'ModalPersistentCtrl',[],
    function () {
        function ModalPersistentCtrl($scope, $uibModalInstance) {

            $scope.$on('modal.closing', function($event, reason, closing) {
                if(reason === '$uibUnscheduledDestruction' || !closing) {
                    $event.preventDefault();
                }
            });

            $scope.closePopup = function () {
                nlStop($scope.selectedVideoRace.instanceId);
                $uibModalInstance.close();
            };
        }

        ModalPersistentCtrl.$inject = [
            '$scope',
            '$uibModalInstance'
        ];

        return ModalPersistentCtrl;
    }
);

