'use strict';

define('PreferencesEntityBuilder',['PreferencesEntity'], function (PreferencesEntity) {

    function builder(){
        var preferences = new PreferencesEntity();

        return {
            withDescription: function (description) {
                preferences.description = description;
                return this;
            },
            withMetadata: function (metadata) {
                preferences.metadata = metadata;
                return this;
            },
            withHash: function (hash) {
                preferences.hash = hash;
                return this;
            },
            build: function () {
                return preferences;
            }
        };
    }

    return builder;

});


