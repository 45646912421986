"use strict";

define('UserSessionSvc',['PollingScheduler'], function (PollingScheduler) {

    function UserSessionSvc(SecuritySvc, $q, $rootScope) {

        var USER_SESSION_INTERVAL_MS = 60000;
        var _userSessionScheduler;

        function _shouldSetUserProfile(forceGetUserProfile) {
            return forceGetUserProfile || !$rootScope.user;
        }

        function _initializeUserSessionPolling() {
            if (!_userSessionScheduler) {
                _userSessionScheduler = new PollingScheduler(USER_SESSION_INTERVAL_MS, function () {
                    _validateUserSession(true);
                });
            }
            return _userSessionScheduler;
        }

        function _validateUserSession(forceGetUserProfile) {
            var deferred = $q.defer();

            if (_shouldSetUserProfile(forceGetUserProfile)) {
                SecuritySvc.restoreSession()
                    .then(function (response) {
                        _userSessionScheduler.start();
                        deferred.resolve(response);
                    }, function (err) {
                        deferred.reject(err);
                    });
            } else {
                SecuritySvc.validateSession()
                    .then(function (response) {
                        deferred.resolve(response);
                    }, function (err) {
                        deferred.reject(err);
                    });
            }

            return deferred.promise;
        }

        /**
         * Check if there's a created session
         * (should return true after the session validation process runs)
         *
         * @TODO should be moved to the correct fac (if any)
         *
         * @return {Boolean} Whether has session
         */
        function hasSession() {
            var sessionRequested = $rootScope.sessionRequested || false;

            return !!($rootScope.userSession || sessionRequested);
        }

        /**
         * Execute the passed "method" after the session start event
         * is triggered
         *
         * @TODO should we unbind when rootScope is destroyed?
         *
         * @param {Function} method  Method to delay
         * @return {Promise}
         */
        function callAfterSession(method) {
            var deferred = $q.defer();
            var unbinds = [];
            var sessionHandler = function () {
                var response;

                // unbind all listeners
                unbinds.forEach(function (unbind) {
                    unbind();
                });

                unbinds = [];

                if (typeof method === 'function') {
                    response = method();
                }

                deferred.resolve(response);
            };

            // bind to session and afterLogin
            unbinds.push($rootScope.$on('session', sessionHandler));
            unbinds.push($rootScope.$on('afterLogin', sessionHandler));

            return deferred.promise;
        }


        return {
            validate: function (forceGetUserProfile) {
                var promise;

                if (!$rootScope.activeFeatures.userSessionOnly) {
                    //when toggle is off, it forces the session request that gets and sets the user profile
                    promise = SecuritySvc.restoreSession();
                } else {
                    _initializeUserSessionPolling();
                    promise = _validateUserSession(forceGetUserProfile);
                }

                return promise;
            },
            initializeUserSessionPolling: _initializeUserSessionPolling,
            hasSession: hasSession,
            callAfterSession: callAfterSession
        };

    }


    UserSessionSvc.$inject = [
        'SecuritySvc',
        '$q',
        '$rootScope'
    ];

    return UserSessionSvc;
});

