"use strict";

define(
    'RaceAlertFactory',[
        "RaceAlert",
        'lodash'
    ],
    function (RaceAlert, _) {

        var _raceAlertNameById = {
            "0": "Results",
            "1": "Race Day",
            "2": "Entry"
        };

        function _getRaceAlertNameById(alertId) {
            if (_.isNumber(alertId)) {
                alertId = alertId.toString();
            }
            return _raceAlertNameById[alertId];
        }

        return {

            createRaceAlertBasedOnId: function (alertId) {
                var raceAlertName = _getRaceAlertNameById(alertId);
                if (_.isUndefined(raceAlertName)) {
                    //throw new Error()
                    return;
                }
                return new RaceAlert(alertId, raceAlertName);
            },
            createRaceAlert: function (alertId, raceAlertName) {
                return new RaceAlert(alertId, raceAlertName);
            },
            createAllRaceAlerts: function () {
                return [
                    new RaceAlert(0, "Results"),
                    new RaceAlert(1, "Race Day"),
                    new RaceAlert(2, "Entry")
                ];
            }
        };

    });

