'use strict';

define(
    'MyStableCtrl',[],
    function () {
        function MyStableCtrl($scope, $rootScope) {

            (function init() {
                $scope.errorMessage = false;
                $scope.errorMessageContent = '';
                $scope.defaultTab = 1; // manage tab
                _setDefaultTab();
            }());

            $scope.events = {
                closeAlertBox: _closeAlertBox,
                selectMyStableManage: _selectMyStableManage,
                selectMyStableSearch: _selectMyStableSearch
            };

            function _selectMyStableManage() {
                $rootScope.$emit("refreshMyStableManage");
            }

            function _selectMyStableSearch() {
                $rootScope.$emit("selectMyStableSearch");
            }

            function _setDefaultTab() {
                $scope.defaultTab = $rootScope.activeFeatures.myStableRaceToday ? 0 : 1;
            }

            $rootScope.$on("myStableErrorMessage", function (event, msg) {
                $scope.errorMessage = true;
                $scope.errorMessageContent = msg;
            });

            function _closeAlertBox() {
                $scope.errorMessage = false;
            }
        }

        MyStableCtrl.$inject = [
            '$scope',
            '$rootScope'
        ];

        return MyStableCtrl;
    }
);

