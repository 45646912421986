'use strict';

define(
    'VideoGridCtrl',[],
    function () {
        VideoGridCtrl.$inject = ['$rootScope', '$scope', 'GTMFac'];

        function VideoGridCtrl($rootScope, $scope, GTMFac){
            var maximumSlots = 4, elementID = 0;

            $scope.slotsUsed = 0;

            $scope.isUserLoggedIn = !!$rootScope.userSession;

            $scope.allAvailableVideoStreams = [];

            $scope.videosInGrid = {};

            var unbindTrackListReady = $rootScope.$on('trackListReady', function(event, trackList) {
                $scope.allAvailableVideoStreams = [];

                if (trackList && angular.isArray(trackList) && trackList.length > 0) {
                    angular.forEach(trackList, function (track) {
                        if (track.liveStreaming) {
                            var videoTrack = {
                                TrackName: track.name,
                                TrackAbbr: track.trackAbbr,
                                StartDateTime: track.currentWagerableRacePostTime,
                                EndDateTime: null,
                                raceData: {
                                    raceNumber: track.currentWagerableRace,
                                    trackAbbr: track.trackAbbr,
                                    trackName: track.name,
                                    racePerf: track.perfAbbr,
                                    liveStreaming: true,
                                    isReplay: false,
                                    autoPlay: true,
                                    isLeftBarVideo: false,
                                    raceDate: track.currentWagerableRacePostTime,
                                    streamCode: track.streamCode
                                }
                            };

                            $scope.allAvailableVideoStreams.push(videoTrack);
                        }
                    });
                }
            });

            $scope.$on("$destroy", function () {
                unbindTrackListReady();
            });

            $scope.$on('user', function(evt, user){
                $scope.isUserLoggedIn = !!user;
            });

            $scope.$on('logout', function(){
                $scope.isUserLoggedIn = false;
            });

            $scope.addVideo = function(event){
                if ($rootScope.activeFeatures.showLeftNavAdditionalVideos && $rootScope.showLeftBar) {
                    if (event) {
                        event.stopPropagation();
                    }
                    $rootScope.$emit('showAdditionalVideoMessage');
                    return;
                }
                if ($scope.allAvailableVideoStreams.length > 0 && $scope.slotsUsed < maximumSlots){
                    var element = createInitializedVideoInstance(
                        elementID,
                        $scope.allAvailableVideoStreams[$scope.slotsUsed % $scope.allAvailableVideoStreams.length]
                    );

                    addInfoToGrid(elementID, element);
                    $scope.slotsUsed++;
                    elementID++;

                    var eventData = GTMFac.gaEventBuilder()
                        .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                        .withGaEventCategory('Site Click')
                        .withGaEventLabel('Right Rail | Video Player | Add Video Player: ' + $scope.slotsUsed)
                        .withEventLabel('RightRail-MoreVideoPlayer')
                        .build();

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
                }
            };

            $scope.removeVideo = function(index){
                    clearGridSlot(index);
                    $scope.slotsUsed--;
                    delete $scope.videosInGrid["grid_" + getSuffix(index) + index];
            };

            $scope.isGridPlaceholderVisible = function(){
                var isPositionOdd = $scope.slotsUsed % 2 !== 0;
                var areSlotsAvailable = $scope.slotsUsed < maximumSlots;
                return isPositionOdd && areSlotsAvailable;
            };

            $scope.showGridSlots = function () {
                return $rootScope.activeFeatures.showLeftNavAdditionalVideos ? $scope.slotsUsed > 0 && !$rootScope.showLeftBar
                                                                             : $scope.slotsUsed > 0;
            };

            $scope.isBottomPlaceholderVisible = function(){
                var showOnLeftBar = $rootScope.activeFeatures.showLeftNavAdditionalVideos && $rootScope.showLeftBar;
                var isPositionEven = $scope.slotsUsed % 2 === 0;
                var areSlotsAvailable = $scope.slotsUsed < maximumSlots;
                return ($scope.slotsUsed === 0) || (isPositionEven && areSlotsAvailable) || showOnLeftBar;
            };

            $scope.updateVideo = function(index, rawVideoData){
                $scope.videosInGrid["grid_" + getSuffix(index) + index] = createInitializedVideoInstance(index, rawVideoData);
            };

            function createInitializedVideoInstance(index, rawVideoData){
                var element = angular.copy(rawVideoData);
                var videoId = "video_grid_" + getSuffix(index) + index;

                if (!element.raceData) {
                    element.raceData = {};
                }

                element.raceData.containerId = videoId;
                element.raceData.instanceId = videoId;

                if (!element.hasOwnProperty('id')){
                    element.id = index;
                }

                return element;
            }

            function addInfoToGrid(gridPos, rawData){
                $scope.videosInGrid["grid_" + getSuffix(gridPos) + gridPos] = rawData;
            }

            function clearGridSlot(gridPos){
                $scope.videosInGrid["grid_" + getSuffix(gridPos) + gridPos] = null;
            }

            function getSuffix(elementNr){
                if(elementNr < 10){
                    return "000";
                }
                else if(elementNr >= 10 && elementNr < 100){
                    return "00";
                }
                else{
                    return "0";
                }
            }
        }
        return VideoGridCtrl;
    }
);

