var defaultFeatures = {
    featureOverridesEnabled: {
        description: 'Allow overriding features on local browsers'
    },
    transfersToggle: {
        description: 'Transfers (deposits)'
    },
    tvg4PlaceHolders: {
        description: 'This toggle should be removed'
    },
    raceInfoFullPhones: {
        description: 'TVG4: Shows full phone information on account info'
    },
    tvg4V1: {
        description: 'TVG4: Enables/disables desktop version'
    },
    contentApi: {
        description: 'Consuming Content API instead of using CMS directly'
    },
    neulionScript: {
        description: 'Loading Neulion script asynchronously'
    },
    showRunnerReplays: {
        description: 'TVG4: Runner video replays'
    },
    rewardsInfo: {
        description: 'TVG4: Desktop Rewards Info'
    },
    wagerRewardsHistory: {
        description: 'TVG4: Desktop promotions - Wager Rewards History'
    },
    promoPageTab: {
        description: 'TVG4: Desktop promotions - Live Promos'
    },
    raceReplaysViewResults: {
        description: 'TVG4: Race replays on results page'
    },
    showAddToMyStable: {
        description: 'TVG4: add entities to My Stable'
    },
    userPreferences: {
        description: 'TVG4: Manage user preferences'
    },
    myTracks: {
        description: 'TVG4: Manage My Tracks'
    },
    popOutWindowVideo: {
        description: 'TVG4: Pop out new Window Video player'
    },
    showRaceDescriptionOption: {
        description: 'TVG4: Show race description on race program page'
    },
    raceReplaysOnResultsPage: {
        description: 'TVG4: Race replays on program page'
    },
    raceTimeformPredicts: {
        description: 'TVG4: Show Timeform Predicts on program page table'
    },
    helpTutorial: {
        description: 'Touch: Show tutorial videos'
    },
    showRaceChanges: {
        description: 'TVG4: Show race changes on race program page'
    },
    showBalanceBetSlip: {
        description: 'Show the balance of the client on the BetSlip window'
    },
    betSlipRepeatBet: {
        description: 'TVG4: Repeat bet on betslip'
    },
    openBetWindowsInGrid: {
        description: 'Open betSlip in grid mode'
    },
    newStreamToggle: {
        description: 'Touch: new live video stream format'
    },
    tutorialVideos: {
        description: 'Tutorials Videos'
    },
    currentBetsOnProgramPage: {
        description: 'TVG4: Show list of my bets placed for the current track and race selected in the program page.'
    },
    carryOverPools: {
        description: 'Carry Over Pools'
    },
    useNewFeaturedTracks: {
        description: 'Use the new featured tracks on home page'
    },
    tooltipHandicapping: {
        description: 'Show tooltip for handicapping'
    },
    additionalVideoPlayers: {
        description: 'TVG4: Ability to add additional video players'
    },
    useNewFullSchedule: {
        description: 'Use the new full schedule page layout and components'
    },
    storeStateOnLogin: {
        description: 'Store the user\'s chosen US State in the login process'
    },
    showPendingWithdrawals: {
        description: 'TVG4: Show pending withdrawals'
    },
    useTVG3handicappingStoreUrl: {
        description: 'Use handicapping store url get method from TVG3'
    },
    tvgPickProgramPageResults: {
        description: 'TVG Pick for Program Page and Results Page'
    },
    talentPicksViewRaceAndResultsButtons: {
        description: 'Display expert picks for today\'s program with three buttons: Create this Bet Ticket, View Race and Results'
    },
    passwordRecoveryPreflow: {
        description: 'Display the password recovery pre flow'
    },
    enableComingSoonHomepage: {
        description: 'Enable the coomming soon features slider on homepage'
    },
    enableComingSoonPage: {
        description: 'Enable coming soon page'
    },
    equibaseBet: {
        description: 'Enable equibase bet'
    },
    videoPopupFix: {
        description: "this toogle serve to test a video fix"
    },
    videoPlayFix: {
        description: "fix video playing the wrong video"
    },
    enableAccountSummaryWagersInline: {
        description: 'Enable account summary wager details'
    },
    videoTimeZoneCorrect: {
        description: 'Test video timezone'
    },
    enableTVGLiveButton: {
        description: 'Enables TVG Live button on the main menu bar'
    },
    passwordRecoveryMicroApp: {
        description: 'Replace the password recovery preflow by the password recovery micro app'
    },
    defaultDepositAmount: {
        description: 'Change the default amount of the deposits'
    },
    enableNextRacesFilter: {
        description: 'Manage filters for next races bar'
    },
    newProgramPageRaceSelector: {
        description: 'new behaviour for program page race selector'
    },
    programPageHeaderRevamp: {
        description: 'Enables the new layout for the program page header'
    },
    userSessionOnly: {
        description: 'For some cases, it replaces the current session request to a new one that does not retrieve the user profile'
    },
    raceReplaysHideResults: {
        description: 'hide and show results in race replays'
    },
    raceReplaysByDate: {
        description: 'toggle for race replay by date on handicapping menu'
    },
    pastResultsOnMainNavigation: {
        description: 'Changes main menu Race Replays to Past Results and Results opens Past Results'
    },
    geoComply: {
        description: 'use geoComply on login'
    },
    enableOptimizely: {
        description: 'enable optimizely on the site'
    },
    seoPageMetaInfo: {
        description: 'toggle the new page title and meta key'
    },
    handicappingWithRoutes: {
        description: 'toggle new handicapping with routes instead of hash'
    },
    specificCMSMessages: {
        description: 'toggle the cms endpoint to get specific cms keys'
    },
    promotionsWithRoutes: {
        description: 'toggle new handicapping with routes instead of hash'
    },
    addBlogLink: {
        description: 'add blog link in handicapping menu'
    },
    trackListPage: {
        description: 'add a track list page with all tracks and their details'
    },
    videoProviderRCN: {
        description: 'Switch to RCN video provider'
    },
    showLeftBar: {
        description: 'New left bar to navigate in races'
    },
    addCarryoverPoolsLink: {
        description: 'add Todays Carryover Pools link to race'
    },
    changeSSNTooltipMessagePosition: {
        description: 'Changes the position of the helper message in the SSN tooltip'
    },
    globalMtpRefreshSystem: {
        description: 'A system to control the MTP displayed in every place where it is needed'
    },
    showLeftNavFeatured: {
        description: 'Add template for Featured Races and services'
    },
    graphqlVolatileData: {
        description: 'Updates program page data from graphQL'
    },
    graphqlVolatileDataBetslip: {
        description: 'Updates bet slip data from graphQL'
    },
    newWagerEndpoint: {
        description: 'Uses the new wager endpoint on the wtx microservice'
    },
    leftBarLiveIcon: {
        description: 'Show live icon when race is off'
    },
    leftBarOnTvgVideo: {
        description: 'Show live video on tvg on left bar'
    },
    leftBarOnTvg2Video: {
        description: 'Show live video on tvg 2 on left bar'
    },
    addTabsLeftBar: {
        description: 'Add tabs at left races bar'
    },
    graphqlBetslipRunners: {
        description: 'Updates bet slip data from graphQL'
    },
    myStableRaceToday: {
        description: 'Show Race Today tab on My Stable'
    },
    showMoreFeatured: {
        description: 'showMoreFeatures'
    },
    showLeftNavAdditionalVideos: {
        description: 'Show more live streams'
    },
    showLeftNavFull: {
        description: 'Show nav full'
    },
    enableTooltip: {
        description: 'Enables tooltip.io script'
    },
    cancelBetWtx: {
        description: 'Cancels a bet using the wtx service'
    },
    showEquibasePastPerformanceOnProgramPage: {
        description: 'Shows the purchase PP on the tvg4 program page for usa tracks'
    },
    loginRequiredOnEquibaseTrackPastPerformanceButton: {
        description: 'Enables the login step on the purchase PP button'
    },
    showGrandSlamInfo: {
        description: 'Show info about the Grand Slam wager type'
    },
    signalProviderModal: {
        description: 'Opens a modal to update the signal provider'
    },
    asyncPlaceWagerHandler: {
        description: 'Handles async bet placing'
    },
    gkeAccountSummary: {
        description: 'Activates account summary via new gke UAS endpoint'
    },
    gkeMyBets: {
        description: 'Activates my bets via new gke WRO endpoint'
    },
    uarStatesList: {
        description: 'Get the list of states from uar micro service'
    },
    qaConsumingStagingGraph: {
        description: 'Consumes graph through staging to populate qa feeds'
    },
    subscriptionsUAMEndpoint: {
        description: 'Change subscriptions endpoint to use the UAM service'
    },
    useUamAddUsername: {
        description: 'Uses the UAM endpoint to add a new username'
    },
    useUamChangeEmailAddress: {
        description: 'Use the UAM endpoint to change the email address'
    },
    useUamChangePIN: {
        description: 'Use the UAM endpoint to change PIN'
    },
    useUamChangeUsername: {
        description: 'Uses the new UAM endpoint to change the email'
    },
    useUamGetProfile: {
        description: 'Use new Uam endpoint to get the user profile'
    },
    useUamChangePassword: {
        description: 'Use the UAM endpoint to change password'
    },
    useUamPreferences: {
        description: 'Use UAM to store user preferences'
    },
    enableGraphSearchHorseName: {
        description: 'Use Graph to search by horse name in race replays'
    },
    carryoverPoolsLinkBetableRaces: {
        description: 'show only betable races in Carryover Pools section'
    }
};

define("defaultFeatures", function(){});

