'use strict';

define('BetDir',[],function () {

    function BetDir() {
        return {
            restrict: 'E',
            scope: {
                bet: '=betData',
                status: '=betStatus',
                cancelFunc: '=cancelFunc'
            },
            templateUrl: 'js/desktop/modules/Account/templates/bet.html',
            link: function ($scope) {
                $scope.confirmCancelOption = false;
                $scope.openConfirmCancel = function openConfirmCancel() {
                    $scope.confirmCancelOption = true;
                };
                $scope.closeConfirmCancel = function closeConfirmCancel() {
                    $scope.confirmCancelOption = false;
                };
                $scope.toggleConfirmCancel = function toggleConfirmCancel() {
                    $scope.confirmCancelOption = !$scope.confirmCancelOption;
                };
                $scope.cancelBet = function cancelBet(bet) {
                    if ($scope.confirmCancelOption) {
                        $scope.cancelFunc(bet);
                    }
                };
            }
        };
    }

    return BetDir;
});

