/**
 * Created by pasoares on 01/06/2015.
 */
'use strict';

define(
    'LoginRequiredSvc',['Mediator'],
    function (mediator) {

        function LoginRequiredSvc($rootScope) {

            var loginRequired = {

                loginRequiredModal: function (event, callBack, dismissCallback, showRegistrationSection, email) {
                    mediator.dispatch('TVG_LOGIN:OPEN_LOGIN_MODAL', {
                        callback: function (error, success) {
                            if (success && callBack && typeof callBack === 'function'){
                                callBack();
                            }
                            if (success && dismissCallback && typeof dismissCallback === 'function') {
                                setTimeout(
                                    function(){
                                        dismissCallback();
                                    }, 400
                                );
                            }
                        },
                    });
                },
                forgotCredentialsModal: function () {
                    mediator.dispatch('OPEN_LOGIN_FLOW', {
                            loginActiveFlow: 'forgot-credentials'
                        }
                    );
                },
                recoverEmailModal: function () {
                    mediator.dispatch('OPEN_LOGIN_FLOW', {
                            loginActiveFlow: 'recover-email'
                        }
                    );
                }
            };

            $rootScope.$on('callForgotCredentialsModal', loginRequired.forgotCredentialsModal);
            $rootScope.$on('callRecoverEmailModal', loginRequired.recoverEmailModal);
            $rootScope.$on('callLoginRequiredModal', loginRequired.loginRequiredModal);

            return loginRequired;
        }

        LoginRequiredSvc.$inject = [
            '$rootScope'
        ];

        return LoginRequiredSvc;
    });


