"use strict";

define('RaceResultsEntityBuilder',['RaceResultsEntity'], function (RaceResultsEntity) {

    function builder(){
        var raceResult = new RaceResultsEntity();

        return {
            withTrackAbbr: function (trackAbbr) {
                raceResult.trackAbbr = trackAbbr;
                return this;
            },
            withPerfAbbr: function (perfAbbr) {
                raceResult.perfAbbr = perfAbbr;
                return this;
            },
            withRaceNumber: function (raceNumber) {
                raceResult.raceNumber = raceNumber;
                return this;
            },
            withRaceDate: function (raceDate) {
                raceResult.raceDate = raceDate;
                return this;
            },
            withRunners: function (runners) {
                raceResult.runners = runners;
                return this;
            },
            withPayoffs: function (payoffs) {
                raceResult.payoffs = payoffs;
                return this;
            },
            withHasReplays: function (hasReplays) {
                raceResult.hasReplays = hasReplays;
                return this;
            },
            withOnTvg: function (onTvg) {
                raceResult.onTvg = onTvg;
                return this;
            },
            withOnTvg2: function (onTvg2) {
                raceResult.onTvg2 = onTvg2;
                return this;
            },
            build: function () {
                //return Object.seal(raceResult);
                return raceResult;
            }
        };
    }

    return builder;

});


