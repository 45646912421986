'use strict';

define(
    'AccountSummaryCtrl',['moment'],
    function (moment) {
        function AccountSummaryCtrl($rootScope, AccountSummarySvc, GTMFac, $filter, $scope, $timeout, $window) {
            var vm = this;
            var gkeAccountSummary = $rootScope.activeFeatures.gkeAccountSummary;
            vm.gkeAccountSummary = !!gkeAccountSummary;

            vm.events = {
                expandCategory: _expandCategory,
                changeDateRange : _changeDateRange,
                selectFilter : _selectFilter,
                blockFutureDates : _blockFutureDates,
                triggerOptions: _triggerOptions
            };

            function _getCategoriesByDate() {
                vm.data.loading = true;
                vm.data.categories = [];
                vm.data.previousBalance = {};
                vm.data.currentBalance = {};

                var oldStartDate = moment(vm.model.startDate).format('MM/DD/YYYY');
                var oldEndDate = moment(vm.model.endDate).format('MM/DD/YYYY');

                AccountSummarySvc.getAccountSummaryCategoriesByDate(oldStartDate, oldEndDate, gkeAccountSummary).then(
                    function (response) {
                        if (gkeAccountSummary) {
                            vm.data.accountSummary = response;
                        } else {
                            if (Array.isArray(response)) {
                                _.forEach(response, function (category) {
                                    if (category.id === "-1") {
                                        if (category.transaction.indexOf("Start") > -1) {
                                            vm.data.previousBalance = category;
                                        } else if (category.transaction.indexOf("End") > -1) {
                                            vm.data.currentBalance = category;
                                        } else {
                                            vm.data.categories.push(category);
                                        }
                                    } else {
                                        vm.data.categories.push(category);
                                    }
                                });
                            }
                        }
                    },
                    function(){
                        var genericEvent = _buildGtmEvent("Account Summary | Generic Error", "AccountSummary_GenericError");
                        GTMFac.GTMEvent().send($rootScope, "siteClick", genericEvent);
                    })
                    .finally(function () {
                        vm.data.loading = false;
                    });
            }

            function _expandCategory(category) {
                // distinguish basic from wager tables
                var wagerCategoriesIds = [15, 4, 14, 18];
                category.categoryType = _.includes(wagerCategoriesIds, category.transactionTypeID) ? 'wagering' : 'basic';
                category.expanded = !category.expanded;

                var action = category.expanded ? "Expand" : "Collapse";
                var gaEventLabel = ("Account Summary | _action_ Category").replace("_action_", action);
                var eventLabel = ("AccountSummary__action_Category").replace("_action_", action);

                var genericEvent = _buildGtmEvent(gaEventLabel, eventLabel);
                GTMFac.GTMEvent().send($rootScope, "siteClick", genericEvent);
            }

            function _selectFilter (type) {
                switch (type) {
                    case 'yesterday':
                        vm.model.startDate = moment().subtract(1, 'day').toDate();
                        vm.model.endDate = moment().subtract(1, 'day').toDate();
                        vm.data.selectedFilter = 'yesterday';
                        break;
                    case 'lastWeek':
                        vm.model.startDate = moment().subtract(7, 'days').toDate();
                        vm.model.endDate = moment().toDate();
                        vm.data.selectedFilter = 'lastWeek';
                        break;
                    case 'lastMonth':
                        vm.model.startDate = moment().subtract(1, 'month').toDate();
                        vm.model.endDate = moment().toDate();
                        vm.data.selectedFilter = 'lastMonth';
                        break;
                    case 'ytd':
                        vm.model.startDate = moment().startOf('year').toDate();
                        vm.model.endDate = moment().toDate();
                        vm.data.selectedFilter = 'ytd';
                        break;
                    default:
                        vm.model.startDate = moment().toDate();
                        vm.model.endDate = moment().toDate();
                        vm.data.selectedFilter = 'today';
                }
                _getCategoriesByDate();

                var genericEvent = _buildGtmEvent("Account Summary | Tab Filter | " + type, "AccountSummary_TabFilter");
                GTMFac.GTMEvent().send($rootScope, "siteClick", genericEvent);
            }

            function _checkDatePickersRange () {
                if(vm.model.endDate < vm.model.startDate){
                    vm.model.endDate = angular.copy(vm.model.startDate);
                }
            }

            function _showHideErrorTooltip(targetTooltip) {
                return function () {
                    angular.element(document).find("tvg-datepicker").trigger(targetTooltip);
                };
            }

            function _checkIfStartDateIsValid () {
                $timeout(_showHideErrorTooltip('hideStartDateTooltip'), 0);
                if(!vm.model.startDate){
                    $timeout(_showHideErrorTooltip('showStartDateTooltip'), 0);
                }
                else{
                    _checkDatePickersRange();
                }
            }

            function _checkIfEndDateIsValid () {
                $timeout(_showHideErrorTooltip('hideEndDateTooltip'), 0);
                if(!vm.model.endDate){
                    $timeout(_showHideErrorTooltip('showEndDateTooltip'), 0);
                }
                else {
                    _checkDatePickersRange();
                }
            }

            function _blockFutureDates(data) {
                var date = data.date;
                return date > moment().toDate();
            }

            function _changeDateRange() {
                vm.data.selectedFilter = '';
                _getCategoriesByDate();

                var genericEvent = _buildGtmEvent("Account Summary | Go Button", "AccountSummary_GoButton");
                GTMFac.GTMEvent().send($rootScope, "siteClick", genericEvent);
            }

            function _buildGtmEvent(gaEventLabel, eventLabel) {
                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Page Click')
                    .withGaEventCategory("Site Click")
                    .withGaEventLabel(gaEventLabel)
                    .withEventLabel(eventLabel)
                    .build();

                var genericEvent = GTMFac.genericEvent(eventData);
                genericEvent.sectionName = genericEvent.sectionName.concat(" | Account Summary");

                return genericEvent;
            }

            function _triggerOptions(type) {
                if (type === 'pdf' || type === 'csv') {
                    var url = '/uas/v1/summary/';
                    url += type;

                    var startDate = moment(vm.model.startDate);
                    var startDay = startDate.date();
                    var startMonth = startDate.month() + 1;
                    var startYear = startDate.year();
                    var endDate = moment(vm.model.endDate);
                    var endDay = endDate.date();
                    var endMonth = endDate.month() + 1;
                    var endYear = endDate.year();

                    url += '?startDay=' + startDay;
                    url += '&startMonth=' + startMonth;
                    url += '&startYear=' + startYear;
                    url += '&endDay=' + endDay;
                    url += '&endMonth=' + endMonth;
                    url += '&endYear=' + endYear;

                    $window.open(url);
                } else if(type === 'print') {
                    $window.print();
                }
            }

            (function init(){
                vm.model = {
                    startDate: moment().toDate(),
                    endDate: moment().toDate()
                };
                vm.data = {
                    loading: false,
                    categories: [],
                    previousBalance: {},
                    currentBalance: {},
                    selectedFilter : 'today',
                    tooltipForInvalidDate: 'The inserted date is invalid.',
                    //TODO: put 'name' property in 'filterTypes' to CMS
                    filterTypes: [ {name: "today", label: $filter('CMSLabels')('todayLabel', 'filtersLabels'), id: 1},
                        {name: "yesterday", label: $filter('CMSLabels')('yesterdayLabel', 'filtersLabels'), id: 2},
                        {name: "lastWeek", label: $filter('CMSLabels')('lastWeekLabel', 'filtersLabels'), id: 3},
                        {name: "lastMonth", label: $filter('CMSLabels')('lastMonthLabel', 'filtersLabels'), id: 4},
                        {name: "ytd", label: $filter('CMSLabels')('ytdLabel', 'filtersLabels'), id: 5}]
                };

                _getCategoriesByDate();
            }());

            $scope.$watch(function () {
                return vm.model.startDate;
            }, function () {
                _checkIfStartDateIsValid();
            });
            $scope.$watch(function () {
                return vm.model.endDate;
            }, function () {
                _checkIfEndDateIsValid();
            });
        }

        AccountSummaryCtrl.$inject = [
            '$rootScope',
            'AccountSummarySvc',
            'GTMFac',
            '$filter',
            '$scope',
            '$timeout',
            '$window'
        ];

        return AccountSummaryCtrl;
    }
);

