'use strict';

define(
    'Betslip',[
        'BetRunnerPicksSvc',
        'BetslipFactory',
        'AddToBetslipCtrl',
        'BetslipCtrl'
    ],

    function (BetRunnerPicksSvc, BetslipFactory, AddToBetslipCtrl, BetslipCtrl) {
        angular.module('TVG.Betslip', [])
            .service('BetRunnerPicksSvc', BetRunnerPicksSvc)
            .factory('BetslipFactory', BetslipFactory)

            .controller('AddToBetslipCtrl', AddToBetslipCtrl)
            .controller('BetslipCtrl', BetslipCtrl)

            .directive('addToBetslip', function () {
                return {
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/Betslip/templates/add-to-betslip.html'
                };
            })
            .directive('betslipForm', function () {
                return {
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/Betslip/templates/betslip-form.html'
                };
            })
            .directive('betslip', function () {
                return {
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/Betslip/templates/betslip.html'
                };
            });
    });

