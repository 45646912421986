"use strict";

define(
    'RegistrationPageCtrl',[
    ],
    function() {

        function RegistrationPageCtrl($scope, $location) {
            var searchObject = $location.search();

            if (searchObject.promoCode) {
                $scope.go('registration', { promoCode: searchObject.promoCode });
            } else {
                $scope.go('registration');
            }
        }

        RegistrationPageCtrl.$inject = [
            '$scope',
            '$location'
        ];

        return RegistrationPageCtrl;
    }
);

