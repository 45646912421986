"use strict";

define('RaceTimeForm',[],function () {

    function RaceTimeForm(runner, runnerHandicapp) {
        this.horseId = runner.horseID;
        this.horseNumber = runner.bettingInterestNumber;
        this.saddleColor = runner.saddleColor;
        this.horseName = runner.horseName;
        this.numberColor = runner.numberColor;
        this.pickNumber = runnerHandicapp.snapshot_freepick_info.pick;
        this.pickText = runnerHandicapp.snapshot_freepick_info.pickText;
        this.pickDescription = runnerHandicapp.snapshot_freepick_info.pickInfo;
        this.runnerOdds = runner.currentOddsString;
        this.isFavorite = runner.favorite;
        this.isScratched = runner.scratched;
    }

    RaceTimeForm.prototype = {
        getFormattedDescription: function () {
            return this.pickDescription
                .replace('#' + this.horseNumber + ' ' + this.horseName.toUpperCase(), '')
                .replace('-', '')
                .trim();
        }
    };

    return RaceTimeForm;

});

