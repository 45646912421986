'use strict';

define(
    'UsernameValidatorSvc',[
        'SecuritySvc',
        'PasswordValidatorSvc'
    ],

    function () {

    function UsernameValidatorSvc($filter, SecuritySvc, PasswordValidatorSvc) {

        function _fillUsernameCharactersValidations(username, validationsHolder) {
            validationsHolder.isValid = false;
            validationsHolder.isSizeValid = true;
            validationsHolder.hasInvalidCharacters = false;

            if (username.length < 6) {
                validationsHolder.isSizeValid = false;
            } else if (username.match(/^(?![0-9]*$)(?![!@#$%^&*-_+.]*$)[a-zA-Z0-9!@#$%^&*-_+.]+$/) == null) {
                validationsHolder.hasInvalidCharacters = true;
            } else {
                validationsHolder.isValid = true;
            }
        }

        function _fillIfUsernameIsTaken(username, validationsHolder) {
            SecuritySvc.checkUsername(username, false).then(function (response) {
                validationsHolder.isTaken = response.exists;
                validationsHolder.isValid = !response.exists;
            });
        }

        function _fillUsernameValidations(currentUsername, newUsername, validationsHolder) {

            _fillUsernameCharactersValidations(newUsername, validationsHolder);
            if (!currentUsername || newUsername !== currentUsername) {
                validationsHolder.isTaken = false;
                if (validationsHolder.isValid) {
                    _fillIfUsernameIsTaken(newUsername, validationsHolder);
                }
            }
        }

        function _validateUsernameRules(username, messageHandlerCallback) {
            var valid = false;

            //TODO create cms messages
            if (username.length < 6) {
                messageHandlerCallback('Username must have at least 6 characters');
            } else if (username.match(/^(?![0-9]*$)(?![!@#$%^&*-_+.]*$)[a-zA-Z0-9!@#$%^&*-_+.]+$/) == null) {
                messageHandlerCallback('Username must have at least 1 letter. Allowed characters: A-Z, a-z, 0-9, !@#$%^&*-_+. and no spaces');
            } else {
                valid = true;
            }

            return valid;
        }

        function _validateUsernameToAdd(username, newPassword, confirmPassword, messageHandlerCallback) {
            return _validateUsernameRules(username, messageHandlerCallback) &&
                PasswordValidatorSvc.validatePasswordRules(newPassword, confirmPassword, messageHandlerCallback);
        }

        function _validateEmptyUsernameToAdd(username, newPassword, confirmPassword, messageHandlerCallback) {
            var valid = true;
            if (!username || username == "") {
                valid = false;
                //TODO create cms message
                messageHandlerCallback('Field is empty. Please fill the Username field.');
            } else if (!newPassword || newPassword == "") {
                valid = false;
                messageHandlerCallback($filter('CMSValue')('changePasswordFillNewPassword'));
            } else if (!confirmPassword || confirmPassword == "") {
                valid = false;
                messageHandlerCallback($filter('CMSValue')('changePasswordFillConfirmPassword'));
            }

            return valid;
        }

        function _validateEmptyUsername(username, messageHandlerCallback) {
            var valid = true;
            if (!username || username == "") {
                valid = false;
                //TODO create cms message
                messageHandlerCallback('Field is empty. Please fill the Username field.');
            }
            return valid;
        }

        function _validateUsernameChange(oldUsername, username, messageHandlerCallback) {
            var valid = true;
            if (oldUsername && oldUsername.length > 0 && oldUsername === username) {
                valid = false;
                //TODO create cms message
                messageHandlerCallback('Please choose a new username.');
            }
            return valid;
        }

        function _validateUsernameToEdit(oldUsername, username, messageHandlerCallback) {
            return _validateUsernameRules(username, messageHandlerCallback) &&
                _validateUsernameChange(oldUsername, username, messageHandlerCallback);
        }

        function _validateEmptyUsernameToEdit(username, password, messageHandlerCallback) {
            return _validateEmptyUsername(username, messageHandlerCallback) &&
            PasswordValidatorSvc.validateEmptyPassword(password, messageHandlerCallback);
        }

        return {
            fillUsernameValidations: _fillUsernameValidations,
            validateUsernameRules: _validateUsernameRules,
            validateUsernameToAdd: _validateUsernameToAdd,
            validateEmptyUsernameToAdd: _validateEmptyUsernameToAdd,
            validateUsernameToEdit: _validateUsernameToEdit,
            validateEmptyUsernameToEdit: _validateEmptyUsernameToEdit
        };
    }

    UsernameValidatorSvc.$inject = [
        '$filter',
        'SecuritySvc',
        'PasswordValidatorSvc'
    ];

    return UsernameValidatorSvc;
});

