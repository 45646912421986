'use strict';

define('LoginRedirectFac',[], function () {
    function LoginRedirectFac($q, $location, RaceUrlFac, RaceMtpStatusFac) {

        function _buildUrlForClosestRace() {
            var deferred = $q.defer();
            var availableRace = RaceMtpStatusFac.getClosestRaceToBet();

            if (availableRace) {
                deferred.resolve(_urlBuilder(availableRace.trackAbbr, availableRace.trackName, availableRace.raceNumber));
            } else {
                deferred.reject();
            }

            return deferred.promise;
        }

        function _urlBuilder(trackAbbr, trackName, raceNumber) {
            return RaceUrlFac.calculateRacePath(trackAbbr, trackName, raceNumber);
        }

        function _existingLoginBehaviours() {
            return {
                'CLOSEST': function () {
                    _buildUrlForClosestRace().then(function (closestRaceUrl) {
                        $location.url(closestRaceUrl);
                    }, function () { //Do nothing

                    });
                },
                'HOME': function () {
                    $location.path('/').search('');
                }
            };
        }

        function _checkRedirect() {
            if ($location.url().match('^/$')) {
                _existingLoginBehaviours().CLOSEST();
            }

            if ($location.url().match('/registration')) {
                _existingLoginBehaviours().HOME();
            }
        }

        return {
            checkRedirect: _checkRedirect
        };
    }

    LoginRedirectFac.$inject = [
        '$q',
        '$location',
        'RaceUrlFac',
        'RaceMtpStatusFac'
    ];

    return LoginRedirectFac;
});

