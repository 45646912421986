"use strict";

define('RaceStatusUtils',['lodash'], function (_) {

    return {

        // from API code
        RACE_STATUS: {
            OPEN: 'O',             // Open
            STEWARDS_KEY: 'SK',    // Steward's Key
            RACE_OFFICIAL: 'RO',   // Race Official
            UP_NEXT: 'IC',         // Up Next
            MANUALLY_OPENED: 'MO', // Manually Opened
            MANUALLY_CLOSED: 'MC'  // Manually Closed
        },

        /**
         * Get the race status label to be rendered in the MTP label
         * @param  {String} raceStatus  Race status code
         * @return {String}             Label for race status
         */
        getRaceStatusLabel: function (raceStatus) {

            // stop
            if (!_.isString(raceStatus)) { return undefined; }

            var code = raceStatus.toUpperCase();
            var labels = {};


            labels[this.RACE_STATUS.OPEN] = 'Open';
            labels[this.RACE_STATUS.STEWARDS_KEY] = 'Race Off';
            labels[this.RACE_STATUS.RACE_OFFICIAL] = 'Result';
            labels[this.RACE_STATUS.MANUALLY_CLOSED] = 'Closed';

            return labels[code];
        },

        /**
         * Check if the race is open for betting, according to its race status
         *
         * @param  {Object}  race         Race
         * @param  {String}  race.status  Race status code
         * @return {Boolean}              Is it open?
         */
        isRaceOpenForBetting: function (race) {
            var status = this._getStatusCode(race);

            // cannot be closed
            if (this.isStatusRaceOfficial(status) ||
                this.isStatusManuallyClosed(status) ||
                this.isStatusStewardsKey(status)) {
                return false;
            }

            // it's not open if there are already runners
            if (this._hasRunners(race.results)) {
                return false;
            }

            return true;
        },

        /**
         * Check if the race has live video, according to its race status
         *
         * @param  {Object}  race         Race
         * @param  {String}  race.status  Race status code
         * @return {Boolean}              Is it open?
         */
        isRaceOpenForLiveVideo: function (race) {
            var status = this._getStatusCode(race);

            // cannot be closed
            if (this.isStatusRaceOfficial(status) ||
                this.isStatusManuallyClosed(status)) {
                return false;
            }

            // it's not open if there are already runners
            if (this._hasRunners(race.results)) {
                return false;
            }

            return true;
        },

        /**
         * Has the race been marked as open (status="O")?
         *
         * @param  {String}  raceStatus  Race status code
         * @return {Boolean}             Is it manually closed?
         */
        isStatusOpen: function (raceStatus) {
            return this._normalizeCode(raceStatus) === this.RACE_STATUS.OPEN;
        },

        /**
         * Has the race been marked as stewards's key (status="SK")?
         *
         * @param  {String}  raceStatus  Race status code
         * @return {Boolean}             Is it manually closed?
         */
        isStatusStewardsKey: function (raceStatus) {
            return this._normalizeCode(raceStatus) === this.RACE_STATUS.STEWARDS_KEY;
        },

        /**
         * Has the race been marked as official (status="RO")?
         *
         * @param  {String}  raceStatus  Race status code
         * @return {Boolean}             Is it official?
         */
        isStatusRaceOfficial: function (raceStatus) {
            return this._normalizeCode(raceStatus) === this.RACE_STATUS.RACE_OFFICIAL;
        },

        /**
         * Has the race been marked as up next (status="IC")?
         *
         * @param  {String}  raceStatus  Race status code
         * @return {Boolean}             Is it official?
         */
        isStatusUpNext: function (raceStatus) {
            return this._normalizeCode(raceStatus) === this.RACE_STATUS.UP_NEXT;
        },

        /**
         * Has the race been marked as manually opened (status="MO")?
         *
         * @param  {String}  raceStatus  Race status code
         * @return {Boolean}             Is it manually closed?
         */
        isStatusManuallyOpened: function (raceStatus) {
            return this._normalizeCode(raceStatus) === this.RACE_STATUS.MANUALLY_OPENED;
        },

        /**
         * Has the race been marked as manually closed (status="MC")?
         *
         * @param  {String}  raceStatus  Race status code
         * @return {Boolean}             Is it manually closed?
         */
        isStatusManuallyClosed: function (raceStatus) {
            return this._normalizeCode(raceStatus) === this.RACE_STATUS.MANUALLY_CLOSED;
        },

        /**
         * Does the race already has reuslts?
         *
         * @param  {String}  raceStatus  Race status code
         * @return {Boolean}             Does it have results?
         */
        hasRaceResults: function (raceStatus) {
            return this.isStatusRaceOfficial(raceStatus) ||
                this.isStatusManuallyClosed(raceStatus);
        },

        /**
         * Is the race closed for betting?
         *
         * @param  {String}  raceStatus  Race status code
         * @return {Boolean}             Is it closed for betting?
         */
        isClosedForBetting: function (raceStatus) {
            return this.isStatusRaceOfficial(raceStatus) ||
                this.isStatusManuallyClosed(raceStatus) ||
                this.isStatusStewardsKey(raceStatus);
        },

        /**
         * Make sure race status code is a uppercased string
         * @param {String} raceStatus  Race status code
         * @return {String}            Uppercase status code
         */
        _normalizeCode: function (raceStatus) {
            return _.isString(raceStatus) ?
                raceStatus.toUpperCase() : '';
        },

        /**
         * Is one of the valid race status?
         *
         * @param  {String}  raceStatus  Race status code
         * @return {Boolean}             Is the status valid?
         */
        _isValidStatus: function (raceStatus) {

            // must be a string
            if (!_.isString(raceStatus)) { return false; }

            var status = this._getRaceStatusList();
            var code = raceStatus.toUpperCase();


            return status.indexOf(code) > -1;
        },

        /**
         * Get status code from race
         *
         * @param {Object} race  Race details
         * @return {String}      Status code
         */
        _getStatusCode: function (race) {
            race = race || {};

            var status = _.has(race, 'status.code') ?  race.status.code : race.status;

            // if the race status is not a valid one, something's phishy...
            if (!this._isValidStatus(status)) {
                throw new Error('Invalid race status code (' + status + ')');
            }

            return status;
        },

        /**
         * Are there runners in the results?
         * @param  {Object}  results  Race results
         * @return {Boolean}          Whether has runners
         */
        _hasRunners: function (results) {
            return _.has(results || {}, 'runners.length') &&
                results.runners.length > 0;
        },

        /**
         * Extract RACE_STATUS values to an array
         * @return {Array}  Race statuses array
         */
        _getRaceStatusList: function () {
            return Object.keys(this.RACE_STATUS).map(function (key) {
                    return this.RACE_STATUS[key];
                }.bind(this));
        }
    };

});

