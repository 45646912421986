"use strict";

define('BetService',["BetsSvc", "StepSelectionsStringFilter", "GTMMod"], function (
    BetsSvc,
    StepSelectionsStringFilter
) {
    angular
        .module("TVG.BetService", ["TVG.Common.GTM"])
        .factory("BetsSvc", BetsSvc)
        .filter("stepSelectionsString", StepSelectionsStringFilter);
});

