'use strict';
define(
    'ProgramPageRaceSelectorSwipeableCtrl',[
        'MtpDisplayModel',
        'RaceSelectorSwipeableModel',
        'lodash',
        'RaceStatusUtils'
    ],
    function (MtpDisplay, RaceSelector, _, RaceStatusUtils) {

        function ProgramPageRaceSelectorSwipeableCtrl($scope, $rootScope, RaceInfoFac, RaceUrlFac,
            LocationUnreloadFac, TrackPerformanceProvider,
            RaceListFac, GTMFac, $controller,
            RaceMtpStatusFac, GraphRacesNavigation,
            WagerProfileFac, $log, $timeout, $filter) {
            $controller('ProgramPageRaceSelectorCtrl', {
                $scope: $scope,
                $rootScope: $rootScope,
                RaceInfoFac: RaceInfoFac,
                RaceUrlFac: RaceUrlFac,
                LocationUnreloadFac: LocationUnreloadFac,
                RaceListFac: RaceListFac
            });

            var parameters = RaceUrlFac.getRacesInfoFromRouteParams();
            var NUMBER_RACES_PER_PAGE = 11;
            var NUMBER_RACES_BEFORE = 3;
            var graphPollingInterval = $filter('CMSLabels')('med', 'graphqlPollingRates') || 60000; // in ms
            var graphRacesSubscription;

            $scope.model.raceSelector = new RaceSelector(NUMBER_RACES_PER_PAGE, NUMBER_RACES_BEFORE);
            $scope.model.rebuildRaceSelector = true;
            $scope.model.upComingRaceListReady = false;
            $scope.model.mtp = {
                postTime: '',
                mtp: 0,
                statusCode: '',
                liveStreaming: false
            };

            function _switchRace(raceNumber) {
                $scope.data.hasUserPromo = false;
                $scope.model.raceNumber = raceNumber;
                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Top Nav | Track Name | Race Click | R' + raceNumber)
                    .withEventLabel('TopNav-RaceClick')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));

                var trackLinkData = $scope.getTrackInfo();
                LocationUnreloadFac.pathChange(
                    RaceUrlFac.calculateRacePath(trackLinkData.trackAbbr, trackLinkData.trackName, raceNumber),
                    true,
                    RaceUrlFac.getRouteParamsForRace(trackLinkData.trackAbbr, trackLinkData.trackName, raceNumber),
                    'race:Changed');
            }

            function _updateProgramPageSelectorMTP() {
                var raceMtpStatusValue = RaceMtpStatusFac.getRaceMtpStatus($scope.model.trackName + '_' + $scope.model.raceNumber);

                if (raceMtpStatusValue.mtpDisplay) {
                    $scope.model.mtpDisplay = raceMtpStatusValue.mtpDisplay;
                } else {
                    if ($scope.model.currentRace) {
                        $scope.model.mtpDisplay = new MtpDisplay($scope.model.currentRace.postTime);
                    }
                }

                _mtpUpdate();
            }

            function _updateComponentsData(fetchRaces) {
                parameters = RaceUrlFac.getRacesInfoFromRouteParams();

                if (TrackPerformanceProvider.isTrackPerformanceDifferent(parameters.trackAbbr)) {
                    $scope.model.rebuildRaceSelector = true;
                }

                $scope.model.raceNumber = parameters.raceNumber;

                var queryParams = {
                    trackAbbr: $scope.model.trackAbbr,
                    wagerProfile: WagerProfileFac.getSessionOrGenericProfile()
                };

                if (!graphRacesSubscription) {
                    graphRacesSubscription = GraphRacesNavigation.subscribe(queryParams, {
                        success: function (races) {
                            // The callback is executed twice on a refetch so we need to verify if we are getting the same object
                            if (!angular.equals(races, $scope.racesData)) {
                                $scope.racesData = races;
                                $scope.model.raceSelector.setDataSource(races, $scope.model.raceNumber, $scope.model.rebuildRaceSelector);
                                _updateCurrentRace(races);
                            }
                        },
                        fail: function (error) {
                            $log.error(error);
                        }
                    }, graphPollingInterval);
                } else {
                    if (fetchRaces) {
                        GraphRacesNavigation.refetch(queryParams);
                    }
                    else {
                        _updateCurrentRace($scope.racesData);
                    }
                }
            }

            function _updateCurrentRace(races) {
                if (!graphRacesSubscription) {
                    $scope.model.currentRace = TrackPerformanceProvider.getRace($scope.model.raceNumber);
                } else {
                    if (races && races.length > 0) {
                        $scope.model.currentRace = races.filter(function (race) {
                            return (parseInt(race.raceNumber, 10) === parseInt($scope.model.raceNumber, 10)) &&
                                race.trackAbbr === $scope.model.trackAbbr;
                        })[0];
                    }
                    // Clause which prevents the next emit
                    if (!$scope.model.currentRace) {
                        return;
                    }

                    $rootScope.$emit('_raceprogramRaceUpdate', (function () {
                        var RaceUpdateObject = {
                            currentTrackAbbr: $scope.model.currentRace.trackAbbr,
                            currentRaceID: $scope.model.currentRace.id,
                            currentRaceNumber: $scope.model.currentRace.raceNumber,
                            currentRaceStatus: $scope.model.currentRace.status,
                            currentRaceIsWagerable: true
                        };

                        if (!RaceStatusUtils.isRaceOpenForBetting($scope.model.currentRace)) {
                            RaceUpdateObject.raceList = $scope.racesData;
                            RaceUpdateObject.currentRaceIsWagerable = false;
                        }

                        return RaceUpdateObject;
                    }()));
                }

                _updateProgramPageSelectorMTP();

                if ($scope.model.currentRace) {
                    $scope.updateCurrentRacesValues();
                    $scope.model.loading = false;
                }

                if (races && races.length) {
                    $scope.model.rebuildRaceSelector = false;
                }
            }

            function _mtpUpdate() {
                var raceMtpStatusValue = RaceMtpStatusFac.getRaceMtpStatus($scope.model.trackAbbr + '_' + $scope.model.raceNumber);

                if ($scope.model && $scope.model.currentRace) {
                    $scope.model.mtp = {
                        postTime: _.get(raceMtpStatusValue, 'mtpDisplay.time.racePostTime') ?
                            raceMtpStatusValue.mtpDisplay.time.racePostTime : $scope.model.currentRace.postTime,
                        mtp: _.get(raceMtpStatusValue, 'mtp') ? raceMtpStatusValue.mtp : null,
                        statusCode: _.get(raceMtpStatusValue, 'raceStatus.code') ?
                            raceMtpStatusValue.raceStatus.code :
                            $scope.model.currentRace.status,
                        liveStreaming: undefined
                    };
                }

                if ($scope.data.currentRace) {
                    $scope.model.mtp.liveStreaming = $scope.data.currentRace.liveStreaming;
                }
            }

            var unbind = [];

            unbind.push($rootScope.$on('mtpStatusUpdated', function () {
                _updateProgramPageSelectorMTP();
            }));

            unbind.push($rootScope.$on('upComingRacesListReady', function () {
                $scope.model.upComingRaceListReady = true;
                _updateComponentsData(true);
            }));

            unbind.push($rootScope.$on('race:Changed', function () {
                parameters = RaceUrlFac.getRacesInfoFromRouteParams();
                var fetchRaces = false;

                if (parameters.trackAbbr !== $scope.model.trackAbbr) { //check if the track changed
                    $rootScope.$broadcast('video:stop', { instanceId: 'video-main-programpage' });
                    $scope.model.trackSelected = _.find($scope.model.activeTracks, function (track) {
                        return track.trackAbbr === parameters.trackAbbr;
                    });
                    $scope.model.trackAbbr = parameters.trackAbbr;
                    fetchRaces = true;
                }

                $timeout(function () {
                    $scope.model.raceChanged = true;
                    $scope.$apply();
                });

                _updateComponentsData(fetchRaces);
            }));

            unbind.push($rootScope.$on('raceChangedLoaded', function () {
                $timeout(function () {
                    $scope.model.loading = false;
                    $scope.model.raceChanged = false;
                    $scope.$apply();
                });
            }));

            unbind.push($rootScope.$on('trackChanged', function () {
                $scope.model.loading = true;
                $scope.model.rebuildRaceSelector = true;
                _updateComponentsData(true);
            }));

            unbind.push($rootScope.$on('login', function () {
                _updateComponentsData(true);
            }));

            $scope.events.switchRace = _switchRace;

            $scope.$on('$destroy', function () {
                if (graphRacesSubscription) {
                    graphRacesSubscription.unsubscribe();
                }

                _.forEach(unbind, function (bind) {
                    bind();
                });
            });

            (function init() {
                $scope.model.loading = true;

                var unbindOnSession = $rootScope.$on('session', function () {
                    _updateComponentsData(true);
                    unbindOnSession();
                });
            }());
        }

        ProgramPageRaceSelectorSwipeableCtrl.$inject = [
            '$scope',
            '$rootScope',
            'RaceInfoFac',
            'RaceUrlFac',
            'LocationUnreloadFac',
            'TrackPerformanceProvider',
            'RaceListFac',
            'GTMFac',
            '$controller',
            'RaceMtpStatusFac',
            'GraphRacesNavigation',
            'WagerProfileFac',
            '$log',
            '$timeout',
            '$filter'
        ];

        return ProgramPageRaceSelectorSwipeableCtrl;
    }
);

