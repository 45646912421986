'use strict';

define(
    'AccountSummaryWagerDetailsCtrl',[
        'lodash',
        'MyBetsDataTransformer'
    ],
    function (_, MyBetsDataTransformer) {

        function AccountSummaryWagerDetailsCtrl($scope, MyBetsSvc, METADATA) {

            $scope.data = {
                resultToShow:[],
                betStatus: [
                    {id: 1, name: "All"},
                    {id: 2, name: "Resolved"},
                    {id: 3, name: "Pending"},
                    {id: 4, name: "Cancelled"},
                    {id: 5, name: "Winnings"},//Winners
                    {id: 6, name: "Refunded"},
                    {id: 7, name: "Losers"}
                ]
            };

            $scope.events = {
                changeNavigation: _changeNavigation
            };

            function _getWagerStatusID(status) {
                var id = $scope.data.betStatus[0].id;

                for (var i=0; i<$scope.data.betStatus.length; i++) {
                    if (status.indexOf($scope.data.betStatus[i].name) > -1) {
                        id = $scope.data.betStatus[i].id;
                        break;
                    }
                }

                return id;
            }

            function _getBets() {
                $scope.loading = true;

                MyBetsSvc.getBetsByDate($scope.startDate, $scope.endDate, null, null, _getWagerStatusID($scope.status),
                    $scope.pagination.currentPage, $scope.pagination.selectedRecordsPerPage, 'wagerDate', 'asc')
                    .then(
                        function(data) {
                            $scope.data.resultToShow = [];

                            if (data && data.wagerHistory) {
                                $scope.data.resultToShow = data.wagerHistory.rowData.map(MyBetsDataTransformer.toModel(METADATA.wagerTypes));

                                $scope.pagination.currentPage = data.wagerHistory.curPage;
                                $scope.pagination.totalRecords = data.wagerHistory.totalRecords;
                            } else {
                                _resetPagination();
                            }
                        },
                        function(){
                            $scope.data.resultToShow = [];
                            _resetPagination();
                        })
                    .finally(function () {
                        $scope.loading = false;
                    });
            }

            function _changeNavigation(page, changeRecordsPerPage) {
                $scope.pagination.currentPage = changeRecordsPerPage ? "1" : (page || $scope.pagination.currentPage);

                _getBets();
            }

            function _resetPagination() {
                $scope.pagination = {
                    recordsPerPage: ['25', '50', '100'],
                    currentPage: '1',
                    maxPagesOnPagination: '3'
                };

                $scope.pagination.selectedRecordsPerPage = $scope.pagination.recordsPerPage[0];
                $scope.pagination.totalRecords = 0;
            }

            (function init() {
                _resetPagination();

                _getBets();
            }());
        }

        AccountSummaryWagerDetailsCtrl.$inject = [
            '$scope',
            'MyBetsSvc',
            'METADATA'
        ];

        return AccountSummaryWagerDetailsCtrl;
    }
);

