'use strict';

define('StatesSvc',[],function () {

    function StatesSvc($rootScope, $http, ConfigurationFac) {
        var _defaultStates = {
            "AL": "Alabama",
            "AK": "Alaska",
            "AZ": "Arizona",
            "AR": "Arkansas",
            "CA": "California",
            "CO": "Colorado",
            "CT": "Connecticut",
            "DE": "Delaware",
            "DC": "District of Columbia",
            "FL": "Florida",
            "GA": "Georgia",
            "HI": "Hawaii",
            "ID": "Idaho",
            "IL": "Illinois",
            "IN": "Indiana",
            "IA": "Iowa",
            "KS": "Kansas",
            "KY": "Kentucky",
            "LA": "Louisiana",
            "ME": "Maine",
            "MD": "Maryland",
            "MA": "Massachusetts",
            "MI": "Michigan",
            "MN": "Minnesota",
            "MS": "Mississippi",
            "MO": "Missouri",
            "MT": "Montana",
            "NE": "Nebraska",
            "NV": "Nevada",
            "NH": "New Hampshire",
            "NJ": "New Jersey",
            "NM": "New Mexico",
            "NY": "New York",
            "NC": "North Carolina",
            "ND": "North Dakota",
            "OH": "Ohio",
            "OK": "Oklahoma",
            "OR": "Oregon",
            "PA": "Pennsylvania",
            "RI": "Rhode Island",
            "SC": "South Carolina",
            "SD": "South Dakota",
            "TN": "Tennessee",
            "TX": "Texas",
            "UT": "Utah",
            "VT": "Vermont",
            "VA": "Virginia",
            "WA": "Washington",
            "WV": "West Virginia",
            "WI": "Wisconsin",
            "WY": "Wyoming"
        };

        var _stateBuilder = function (){

            var state = {};

            return {
                withName: function (name){
                    state.name = name;
                    return this;
                },
                withAbbreviation: function (abbr){
                    state.abbreviation = abbr;
                    return this;
                },
                build: function (){
                    return state;
                }
            };

        };

        var _createState = function (states) {

            return function (name, abbr) {
                var state;

                state = _stateBuilder()
                    .withAbbreviation(abbr)
                    .withName(name)
                    .build();

                states.push(state);
            };

        };

        function _getDefaultStatesList() {
            var states = [];

            angular.forEach(_defaultStates, _createState(states));

            return states;
        }

        function _getStateBasedOnAbbr(abbr) {
            return _defaultStates[abbr];
        }

        function _getStates() {
            if($rootScope.activeFeatures && $rootScope.activeFeatures.uarStatesList){
                var apiBaseMS = ConfigurationFac.getServiceApiUrl('uar');

                return $http.get(apiBaseMS + '/states').then(function (response) {
                    var states = [];

                    angular.forEach(response.data.states, function (currentValue) {
                        states.push({
                            value: currentValue.abbreviation,
                            name: currentValue.name
                        });
                    });

                    states.sort(function (a, b) {
                        if (a.name < b.name)
                            return -1;

                        if (a.name > b.name)
                            return 1;

                        return 0;
                    });
                    return states;
                });
            } else {
                return $http.get("/ajax/states").then(function (response) {
                    var states = [];

                    for (var i in response.data) {
                        states.push({
                            abbreviation: i,
                            name: response.data[i]
                        });
                    }

                    states.sort(function (a, b) {
                        if (a.name < b.name)
                            return -1;

                        if (a.name > b.name)
                            return 1;

                        return 0;
                    });
                    return states;
                });
            }
        }

        return {
            getDefaultStatesList: _getDefaultStatesList,
            getStateBasedOnAbbr: _getStateBasedOnAbbr,
            getStates: _getStates
        };
    }

    StatesSvc.$inject = [
        '$rootScope',
        '$http',
        'ConfigurationFac'
    ];

    return StatesSvc;
});

