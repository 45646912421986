'use strict';

define('TodayAlertsDir',[], function () {

    function TodayAlertsDir() {
        return {
            restrict: 'E',
            templateUrl: 'js/desktop/modules/Account/templates/desktop/today-alerts.html',
            controller: 'TodayAlertsCtrl',
            scope: true
        };
    }

    TodayAlertsDir.$inject = [];

    return TodayAlertsDir;
});

