'use strict';

define(
    'MyProfileChangePinCtrl',[
        'SecuritySvc'
    ],
    function () {

        function MyProfileChangePinCtrl($rootScope, $scope, $uibModal, SecuritySvc, $filter, GTMFac, FindModalContainerFac) {

            $scope.events = {
                changePinPressed: _changePinPressed,
                isValidNewPin: _isValidNewPin,
                cancel: _cancel,
                isNewPin: _isNewPin,
                isInvalidPins: _isInvalidPins
            };

            $scope.data = {
                minLength: 4,
                maxLength: 4,
                pinPattern: /^\d+$/,
                oldPIN: "",
                newPIN: "",
                confirmPIN: "",
                loading: false,
                errorMessage: null,
                message: null,
                confirmDialog: null,
                shouldCloseOnMessage: false
            };

            var changePin = {
                old_pin: '',
                new_pin: '',
                confirm_pin: ''
            };

            function _changePinPressed(formValid) {
                if (formValid && !$scope.data.loading) {

                    var preconditionErrors = _verifyChangePinPrecondition();

                    if (preconditionErrors) {
                        _handlePreconditionErrors(preconditionErrors);
                        return;
                    }

                    _callChangePin();

                } else {
                    _verifyErrors();
                }
            }

            function _callChangePin() {
                var p;
                $scope.data.loading = true;
                SecuritySvc.changePin(changePin).then(function (response) {
                    $scope.data.loading = false;
                    $scope.data.shouldCloseOnMessage = true;
                    _showMessage(response.message);
                    _setPristine();

                   p = GTMFac.EventBuilder()
                        .withGaEventLabel('User Profile | Change Pin')
                        .withGaEventAction('My Profile Page Click')
                        .withGaEventCategory('Site Click')
                        .withSectionName(GTMFac.Tvg4SectionName())
                        .withScreenName(GTMFac.Tvg4ScreenName())
                        .withEventLabel("MyAccount_UserProfilePin")
                        .build();

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
                }, function (error) {
                    $scope.data.loading = false;
                    var genericErrMessage = 'There was a problem updating your pin.';
                    var errorMessage = genericErrMessage;  
                    errorMessage = error.code && $filter('CMSLabels')(error.code, 'profileUpdatePinErrorHandling') ? 
                        $filter('CMSLabels')(error.code, 'profileUpdatePinErrorHandling') : genericErrMessage;
                    _showErrorMessage(errorMessage);
                    _setPristine();
                });
            }

            function _verifyErrors() {
                if (!$scope.data.oldPIN || $scope.data.oldPIN === "") {
                    _showErrorMessage($filter('CMSValue')('changePasswordInsertOldPin'));
                } else if (!$scope.data.newPIN || $scope.data.newPIN === "") {
                    _showErrorMessage($filter('CMSValue')('changePasswordInsertNewPin'));
                } else if (!$scope.data.confirmPIN || $scope.data.confirmPIN === "") {
                    _showErrorMessage($filter('CMSValue')('changePasswordInsertConfirmPin'));
                }
                _setPristine();
            }

            function _verifyChangePinPrecondition() {
                if (_isValidNewPin() === false) {
                    return 'changePinMessageMatch';
                }

                changePin.old_pin = $scope.data.oldPIN;
                changePin.new_pin = $scope.data.newPIN;
                changePin.confirm_pin = $scope.data.confirmPIN;

                if (changePin.old_pin === changePin.new_pin) {
                    return 'changePinMessageDifferent';
                }

                return false;
            }

            function _handlePreconditionErrors(value) {
                _setPristine();
                _showErrorMessage($filter('CMSValue')(value));
            }

            function _setPristine() {
                $scope.changePINFrm.oldPIN.$setPristine();
                $scope.changePINFrm.newPIN.$setPristine();
                $scope.changePINFrm.confirmPIN.$setPristine();
                $scope.data.oldPIN = "";
                $scope.data.newPIN = "";
                $scope.data.confirmPIN = "";
                changePin.old_pin = "";
                changePin.new_pin = "";
                changePin.confirm_pin = "";
            }

            function _showMessage(message) {
                $scope.data.message = message;

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Account/templates/change-pin-password-success.html',

                    scope: $scope
                });
            }

            function _showErrorMessage(message) {
                $scope.data.errorMessage = message;
                $scope.data.errorMessageTitle = "Change Pin Failed";

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Login/templates/login-error.html',

                    scope: $scope
                });
            }

            function _cancel() {
                if ($scope.data.confirmDialog) {
                    $scope.data.confirmDialog.close();
                    $scope.data.confirmDialog = null;
                }
            }

            function _isValidNewPin() {
                return ($scope.data.newPIN === $scope.data.confirmPIN && $scope.data.newPIN.length > 0 );
            }

            function _isNewPin() {
                return $scope.data.newPIN !== $scope.data.oldPIN;
            }

            function _isInvalidPins() {
                if($scope.changePINFrm.oldPIN.$invalid ||
                   $scope.changePINFrm.newPIN.$invalid ||
                   $scope.changePINFrm.confirmPIN.$invalid) {
                    return true;
                } else {
                    return false;
                }
            }

            $scope.$on("about-you::on-form-cancel", _setPristine);
        }

        MyProfileChangePinCtrl.$inject = [
            '$rootScope',
            '$scope',
            '$uibModal',
            'SecuritySvc',
            '$filter',
            'GTMFac',
            'FindModalContainerFac'
        ];

        return MyProfileChangePinCtrl;
    }
);

