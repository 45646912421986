'use strict';

define('Notifications',['angular', 'ScrollDecorator'],
    function (angular, ScrollDecorator) {
        return angular.module('TVG.Notification', ['TVG.Deposit']).config(['$provide', function($provide) {
            $provide.decorator('NotificationService', ScrollDecorator);
        }]).name;
    }
);

