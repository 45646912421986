'use strict';

define(
    'CurrentLiveStreamListFac',[],
    function () {

        function CurrentLiveStreamListFac(METADATA, RaceInfoFac, $q, poller) {

            var currentLiveStreamList = [],
                liveStreamPoller;

            function _pollLiveStreamList() {
                liveStreamPoller = poller.get(this, {
                    action: 'getCurrentLiveStreamList',
                    delay: 60000,
                    argumentsArray: [
                        {}
                    ]
                });

                return liveStreamPoller;
            }

            function _stopPollLiveStreamList(deferred) {
                if (liveStreamPoller) {
                    liveStreamPoller.removeDeferred(deferred);
                }
            }

            function _getCurrentLiveStreamList(){

                var deferred = $q.defer();

                var currentTime = new Date().getTime();
                var liveVideoScheduleList = METADATA.liveVideoSchedule;

                RaceInfoFac.getUpcomingRaces().then(function (upcomingRaces) {
                    _resetCurrentLiveStreamList();
                    angular.forEach(liveVideoScheduleList, function (liveVideoSchedule) {
                        var startStreamTime = _getTimestamp(liveVideoSchedule.StartDateTime);
                        var endStreamTime = _getTimestamp(liveVideoSchedule.EndDateTime);

                        if(currentTime > startStreamTime && currentTime < endStreamTime){
                            //Stream is live
                            if(upcomingRaces != null && upcomingRaces.length > 0) {
                                for (var i = 0; i < upcomingRaces.length; i++) {
                                    if (upcomingRaces[i].trackAbbr == liveVideoSchedule.TrackAbbr) {
                                        liveVideoSchedule.raceData = upcomingRaces[i];

                                        // @TODO is this correct?... :s
                                        // liveVideoSchedule.raceData.raceOpenForBetting = true;
                                        liveVideoSchedule.raceData.raceOpenForLiveVideo = true;

                                        currentLiveStreamList.push(liveVideoSchedule);
                                        break;
                                    }
                                }
                            }
                        }
                    });

                    if (currentLiveStreamList) {
                        deferred.resolve(currentLiveStreamList);
                    } else {
                        deferred.reject();
                    }

                });
                return deferred.promise;

            }

            function _resetCurrentLiveStreamList() {
                currentLiveStreamList = [];
            }

            function _getTimestamp(input) {
                if (!input) {
                    return 0;
                }

                var numbers = input.match(/[0-9]+/g);
                if (!numbers) {
                    numbers = [0];
                }
                return parseInt(numbers[0], 10);
            }


            return {
                pollLiveStreamList: _pollLiveStreamList,
                stopPollLiveStreamList: _stopPollLiveStreamList,
                getCurrentLiveStreamList: _getCurrentLiveStreamList,
                resetCurrentLiveStreamList: _resetCurrentLiveStreamList
            };
        }

        CurrentLiveStreamListFac.$inject = [
            'METADATA',
            'RaceInfoFac',
            '$q',
            'poller'
        ];

        return CurrentLiveStreamListFac;

    });

