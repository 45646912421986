'use strict';

define('CalculatePurseFac',[],
    function () {
        function CalculatePurseFac() {

            function _calculateMinimizedPurse(aRace) {
                // Round purse value
                var purseValue;
                if (aRace.purse % 1000 != 0) {
                    if (aRace.purse % 1000 < 50) {
                        purseValue = (aRace.purse / 1000).toFixed(0);
                    } else {
                        purseValue = (aRace.purse / 1000).toFixed(1);
                    }
                } else {
                    purseValue = aRace.purse / 1000;
                }
                return purseValue;
            }

            return {
                calculateMinimizedPurse: _calculateMinimizedPurse
            };
        }

        return CalculatePurseFac;
    });

