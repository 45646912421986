'use strict';

define('FeaturedTrackElementDir',['lodash'], function (_) {
    FeaturedTrackElementDir.$inject = [
        '$location',
        '$filter',
        'RaceUrlFac'
    ];

    function FeaturedTrackElementDir($location, $filter, RaceUrlFac) {
        return {
            restrict: 'E',
            scope: {
                races: '=trackRaces'
            },
            templateUrl: 'js/desktop/modules/FeaturedTracks/templates/featuredTrackElement.html',
            link: function(scope) {

                var featuredTracksImages = {};

                try {
                    if($filter('CMSValue')('FeaturedTracksImages')) {
                        featuredTracksImages = JSON.parse($filter('CMSValue')('FeaturedTracksImages'));
                    }
                }
                catch(ignore) { }

                function _raceUrl(){
                    if (_isRaceDefined()){
                        return RaceUrlFac.generateRacePath(
                            scope.races[0].trackAbbr,
                            scope.races[0].trackName,
                            scope.races[0].raceNumber,
                            false);
                    }
                    return '';
                }

                function _isRaceDefined() {
                    return (_.isArray(scope.races)
                        && scope.races.length
                        && !_.isUndefined(scope.races[0].trackAbbr)
                        && !_.isUndefined(scope.races[0].trackName)
                        && !_.isUndefined(scope.races[0].raceNumber));
                }

                scope.showOverlay = false;

                scope.raceUrl = _raceUrl;

                scope.featuredImage = function(trackAbbr) {
                    if (featuredTracksImages && featuredTracksImages[trackAbbr]) {
                        return featuredTracksImages[trackAbbr];
                    }
                };

                scope.goToRace = function () {
                    if (_isRaceDefined()) {
                        $location.url(_raceUrl());
                    }
                };
            }
        };
    }

    return FeaturedTrackElementDir;
});

