'use strict';

define('MyStableSearchDir',[], function () {

    function MyStableSearchDir() {
        return {
            restrict: 'E',
            templateUrl: 'js/desktop/modules/Account/templates/my-stable-search.html',
            controller: "MyStableSearchCtrl",
            controllerAs: "myStableSearch"
        };
    }

    return MyStableSearchDir;
});
