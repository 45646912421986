'use strict';

define(
    'TrackSelectorItemBuilder',[
        'TrackSelectorItem'
    ],
    function (TrackSelectorItem) {

        function TrackSelectorItemBuilder() {

            var trackSelectorItem = new TrackSelectorItem();

            return {
                withTrackAbbr: function (trackAbbr) {
                    trackSelectorItem.trackAbbr = trackAbbr;
                    return this;
                },
                withTrackName: function (trackName) {
                    trackSelectorItem.trackName = trackName;
                    return this;
                },
                withRaceNumber: function (raceNumber) {
                    trackSelectorItem.raceNumber = raceNumber;
                    return this;
                },
                withPostTime: function (racePostTime) {
                    trackSelectorItem.racePostTime = racePostTime;
                    return this;
                },
                withPerfAbbr: function (perfAbbr) {
                    trackSelectorItem.perfAbbr = perfAbbr;
                    return this;
                },
                withCurrentWagerableRace: function (currentWagerableRace) {
                    trackSelectorItem.currentWagerableRace = currentWagerableRace;
                    return this;
                },
                build: function () {
                    return trackSelectorItem;
                }
            };

        }

        return TrackSelectorItemBuilder;

    });

