/**
 * Created by pasoares on 12/05/2015.
 */
'use strict';

define(
    'AccountHistoryCtrl',[],
    function() {

        function AccountHistoryCtrl($scope) {

            $scope.events = {
                goto: _goto
            };

            function _goto(name) {
                switch (name) {
                    case "My Bets":
                        $scope.push("js/desktop/views/profile/my-bets.html", {title: "My Bets"});
                        break;
                    default:
                        break;
                }
            }
        }
        AccountHistoryCtrl.$inject = [
            '$scope'
        ];

        return AccountHistoryCtrl;
    }
);

