'use strict';

define(
    'AppRequestInterceptor',[],
    function () {
        function AppRequestInterceptor($httpProvider, $provide) {

            function _setHeadersRequest() {

                // @TODO remove RequestContextHeaders from SecurityMod and delete all manual overrides
                // of the 'x-tvg-context' header
                var TVG_CONTEXTS = {
                    tvg4_nj: 'tvg4-4njbets',
                    tvg4_pa: 'tvg4-pabets',
                    tvg4_all: 'tvg4-tvg',
                    tvg4_ia: 'tvg4-iowa',
                    touch2_nj: 'touch2-4njbets',
                    touch2_pa: 'touch2-pabets',
                    touch2_all: 'touch2-tvg',
                    touch2_ia: 'touch2-iowa'
                };

                $httpProvider.defaults.withCredentials = true;

                $httpProvider.interceptors.push(function () {
                    return {
                        'request': function (config) {

                            // Set headers only for tvg.com or us.betfair.com service calls back to the server so it has context
                            if (config.url.indexOf("tvg.com") != -1 || config.url.indexOf("us.betfair.com") != -1) {
                                config.headers['X-ClientApp'] = window.clientApp;
                                config.headers['x-tvg-context'] = TVG_CONTEXTS[window.clientApp + '_' + window.locationContext];
                            }

                            //Make sure we identify as a XMLHttpRequest when doing ajax calls
                            if (config.url.indexOf("ajax/") != -1) {
                                config.headers['X-ClientApp'] = window.clientApp;
                                config.headers['x-requested-with'] = 'XMLHttpRequest';
                                config.headers.Accept = 'application/json';
                            }

                            return config;
                        }
                    };
                });
            }

            function _setInterceptResponse() {

                //http interceptor used to detect User Session errors
                $httpProvider.interceptors.push(function ($q, $rootScope) {
                    return {
                        'response': function (response) {
                            if(!$rootScope.sessionExpiredFired && !$rootScope.activeFeatures.gkeSessionError) {
                                if ($rootScope.user && response.data && ((response.data.status == 'error' && response.data.response && response.data.response.errorMessage == 'ApiUserSessionNotFound')
                                        || (response.data.ErrorStatus && response.data.ErrorStatus.name == 'Error' && response.data.ErrorCode && response.data.ErrorCode == 'ApiUserSessionNotFound')
                                    )) {
                                    $rootScope.sessionExpiredFired = true;
                                    $rootScope.$broadcast('sessionExpired');
                                }
                            }

                            return response;
                        },
                        'responseError': function (response) {
                            var gkeSessionError = $rootScope.activeFeatures.gkeSessionError && (response.status === 403 || response.status === 401) && !response.data;
                            var legacySessionError = !$rootScope.activeFeatures.gkeSessionError && (response.status === 401 || response.status === 503) && response.data && response.data.notlogin;

                            if(!$rootScope.sessionExpiredFired && $rootScope.user && (gkeSessionError || legacySessionError)) {
                                $rootScope.sessionExpiredFired = true;
                                $rootScope.$broadcast('sessionExpired');
                            }

                            return $q.reject(response);
                        }
                    };
                });
            }

            return {
                setHeadersRequest: _setHeadersRequest,
                setInterceptResponse: _setInterceptResponse,
                setMetadata: function () {
                    $provide.value('METADATA', __METADATA || {});
                    __METADATA = null;
                },
                setFeatures: function () {
                    $provide.value('FEATURES', __FEATURES || {});
                    __FEATURES = null;
                },
                setCmsContent: function () {
                    $provide.value('CMSCONTENT', __CMSCONTENT || {});
                    __CMSCONTENT = null;
                },
                $get: function () {
                    return {
                        xclientapp: $httpProvider.defaults.headers.common,
                        metadata: METADATA || null,
                        features: FEATURES || null,
                        cmscontent: CMSCONTENT || null
                    };
                }
            };
        }

        return AppRequestInterceptor;
    });

