'use strict';

define(
    'AccountChangePinCtrl',[
        'SecuritySvc'
    ],
    function () {

        function AccountChangePinCtrl($scope, $uibModal, SecuritySvc, $filter, $timeout, FindModalContainerFac) {

            $scope.events = {
                changePinPressed: _changePinPressed,
                cancel: _cancel
            };

            $scope.data = {
                minLength: 4,
                maxLength: 4,
                pinPattern: /^\d+$/,
                oldPIN: "",
                newPIN: "",
                confirmPIN: "",
                loading: false,
                errorMessage: null,
                message: null,
                confirmDialog: null,
                shouldCloseOnMessage: false
            };

            var changePin = {
                old_pin: '',
                new_pin: '',
                confirm_pin: ''
            };

            function _changePinPressed(formValid) {
                if (formValid && !$scope.data.loading) {
                    if (_isValidNewPin() == false) {
                        _setPristine();
                        _showErrorMessage($filter('CMSValue')('changePinMessageMatch'));
                        return;
                    }

                    changePin.old_pin = $scope.data.oldPIN;
                    changePin.new_pin = $scope.data.newPIN;
                    changePin.confirm_pin = $scope.data.confirmPIN;

                    if (changePin.old_pin === changePin.new_pin) {
                        _setPristine();
                        _showErrorMessage($filter('CMSValue')('changePinMessageDifferent'));
                        return;
                    }

                    $scope.data.loading = true;

                    SecuritySvc.changePin(changePin).then(function (response) {
                        $scope.data.loading = false;
                        $scope.data.shouldCloseOnMessage = true;
                        _showMessage(response.message);
                        _setPristine();
                    }, function (error) {
                        $scope.data.loading = false;
                        var genericErrMessage = 'There was a problem updating your pin.';
                        var errorMessage = genericErrMessage;  
                        errorMessage = error.code && $filter('CMSLabels')(error.code, 'profileUpdatePinErrorHandling') ? 
                            $filter('CMSLabels')(error.code, 'profileUpdatePinErrorHandling') : genericErrMessage;
                        _showErrorMessage(errorMessage);
                        _setPristine();
                    });
                } else {
                    if (!$scope.data.oldPIN || $scope.data.oldPIN == "") {
                        _showErrorMessage($filter('CMSValue')('changePasswordInsertOldPin'));
                    } else if (!$scope.data.newPIN || $scope.data.newPIN == "") {
                        _showErrorMessage($filter('CMSValue')('changePasswordInsertNewPin'));
                    } else if (!$scope.data.confirmPIN || $scope.data.confirmPIN == "") {
                        _showErrorMessage($filter('CMSValue')('changePasswordInsertConfirmPin'));
                    }
                    _setPristine();
                }
            }

            function _setPristine() {
                $scope.changePINFrm.oldPIN.$setPristine();
                $scope.changePINFrm.newPIN.$setPristine();
                $scope.changePINFrm.confirmPIN.$setPristine();
                $scope.data.oldPIN = "";
                $scope.data.newPIN = "";
                $scope.data.confirmPIN = "";
                changePin.old_pin = "";
                changePin.new_pin = "";
                changePin.confirm_pin = "";
            }

            function _showMessage(message) {
                $scope.data.message = message;

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Account/templates/change-pin-password-success.html',

                    scope: $scope
                });
            }

            function _showErrorMessage(message) {
                $scope.data.errorMessage = message;
                $scope.data.errorMessageTitle = "Change Pin Failed";

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Login/templates/login-error.html',

                    scope: $scope
                });
            }

            function _cancel() {
                if ($scope.data.confirmDialog) {
                    $scope.data.confirmDialog.close();
                    $scope.data.confirmDialog = null;
                }
                if ($scope.data.shouldCloseOnMessage == true) {
                    $scope.pop();
                }

                $timeout(function () {
                    $scope.data.errorMessage = null;
                }, 300);
            }

            function _isValidNewPin() {
                return $scope.data.newPIN == $scope.data.confirmPIN && $scope.data.newPIN.length > 0;
            }
        }

        AccountChangePinCtrl.$inject = [
            '$scope',
            '$uibModal',
            'SecuritySvc',
            '$filter',
            '$timeout',
            'FindModalContainerFac'
        ];

        return AccountChangePinCtrl;
    }
);

