'use strict';

define('AddToMyStableDir',['Mediator'], function (mediator) {

    function AddToMyStableDir($uibModal, FindModalContainerFac) {
        return {
            restrict: 'E',
            scope: true,
            link: function (scope, elem, attrs) {
                var horse = attrs.horse,
                    jockey = attrs.jockey,
                    trainer = attrs.trainer,
                    owner = attrs.owner,
                    dob = attrs.dob,
                    addMyStableDialog = null;

                scope.events = {
                    addToMyStable: _addToMyStable
                };

                function _closeAddMyStableDialog() {
                        addMyStableDialog.close();
                        addMyStableDialog = null;
                }

                function _addToMyStable(event) {
                    mediator.dispatch('PPMA_ADD_STABLE', {});

                    if (event) {
                        event.stopPropagation();
                    }

                    addMyStableDialog = $uibModal.open({
                        appendTo: FindModalContainerFac('#angular-app'),
                        backdrop: true,
                        windowClass: 'my-stable-modal',
                        windowTemplateUrl: 'js/desktop/modules/Races/templates/desktop/default-modal-window.html',
                        templateUrl: 'js/desktop/modules/Account/templates/desktop/add-my-stable.html',
                        controller: 'AddAlertsToMyStableCtrl as addAlertsToMyStable',
                        resolve: {
                            runner: function() {
                                return {
                                    horse: horse,
                                    dob: dob,
                                    jockey: jockey,
                                    trainer: trainer,
                                    owner: owner
                                };
                            },
                            closeAddMyStableDialog: function() {
                                return _closeAddMyStableDialog;
                            }
                        }
                    });

                }
            }
        };
    }

    AddToMyStableDir.$inject = ['$uibModal', 'FindModalContainerFac'];

    return AddToMyStableDir;
});

