'use strict';

define('RaceChangesCtrl',[
    'lodash'
], function (_) {

    function RaceChangesCtrl($scope, $rootScope) {

        /**
         *
         * DECLARATIONS
         */
        var changesFormatter = {
            'horseChanges': function () { //Atm we don't process this information.
                return undefined;
            },
            'jockeyChanges': function (jockeyChanges) {
                var formattedObj;
                if (angular.isArray(jockeyChanges) && jockeyChanges.length !== 0) {
                    formattedObj = {};
                    formattedObj.type = 'jockey';
                    formattedObj.title = 'Jockey changes';
                    formattedObj.values = [];

                    jockeyChanges.sort(_sortByDate);

                    angular.forEach(jockeyChanges, function(change) {
                        if (change.newValue) {
                            formattedObj.values.push(change);
                        }
                    });
                }
                return formattedObj;
            },
            'raceChanges': function (raceChanges) {
                var formattedObj;
                if (angular.isArray(raceChanges) && raceChanges.length !== 0) {
                    formattedObj = {};
                    formattedObj.type = 'race';
                    formattedObj.title = 'Track changes';
                    formattedObj.values = [];

                    raceChanges.sort(_sortByDate);

                    angular.forEach(raceChanges, function(change) {
                        // only return first change of description type
                        if (change.newValue) {
                            var changeObject = _buildChangeObject(change.oldValue, change.newValue, change.description, change.timestamp);
                            formattedObj.values.push(changeObject);
                        }
                    });
                }
                return formattedObj;
            }
        };

        var ctrl = this;
        ctrl.userLoggedIn = $rootScope.user && $rootScope.userSession;
        $scope.formattedChanges = _formatRaceChanges($scope.raceChanges); //using $scope to save formattedChanges for testing purposes

        /**
         * EVENTS
         */
        $scope.$on('user', function () {
            ctrl.userLoggedIn = $rootScope.user && $rootScope.userSession;
        });

        $scope.$on('RaceChanges:CurrentRaceUpdated', function (event, params) {
            $scope.formattedChanges = _formatRaceChanges(params);
        });

        /**
         * FUNCTIONS
         */
        ctrl.openLoginDialog = function () {
            $rootScope.$broadcast('callLoginRequiredModal');
        };

        function _sortByDate(x, y) {
            return new Date(x.timestamp).getTime() < new Date(y.timestamp).getTime();
        }

        function _buildChangeObject(oldValue, newValue, description, date) {
            if (oldValue) {
                return { oldValue: oldValue, newValue: newValue, description: description, timestamp: date};
            } else {
                return { setTo:'Set to ' + newValue, timestamp: date, description: description};
            }
        }

        function _formatRaceChanges(raceChanges) {
            var changes = [];

            if (raceChanges && angular.isObject(raceChanges)) {
                _.forEach(raceChanges, function (value, key) {
                    var result = changesFormatter[key](value);
                    if (result) {
                        changes.push(result);
                    }
                });
            }
            return changes;
        }
    }

    RaceChangesCtrl.$inject = [
        '$scope',
        '$rootScope'
    ];

    return RaceChangesCtrl;

});

