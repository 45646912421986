'use strict';

define('PasswordRecoveryGtm',[
        'PasswordRecoveryGtmFac',
        'GTMMod'
    ],
    function (PasswordRecoveryGtmFac) {

        angular.module('TVG.PasswordRecoveryGtm', [
            'TVG.PasswordRecovery',
            'TVG.Common.GTM'
        ])
            .factory('PasswordRecoveryGtmFac', PasswordRecoveryGtmFac)
            .run(['PasswordRecoveryGtmFac', function (PasswordRecoveryGtmFac) {
                PasswordRecoveryGtmFac.register();
            }]);
    });

