'use strict';

define('MyStableSvc',[
        'StableEntityBuilder',
        'StableEntityTypeFactory',
        'RaceAlertFactory',
        'SubscriptionAlertsFactory',
        'PreferencesCookieReloadedFac',
        'RaceTodayBuilder',
        'MtpDisplayModel',
        'moment'
    ],
    function (StableEntityBuilder, stableEntityTypeFactory, raceAlertFactory, subscriptionAlertsFactory, PreferencesFac, raceTodayBuilder, MtpDisplay, moment) {

        function MyStableSvc($rootScope, $http, $q, $timeout, ConfigurationFac, RaceMtpStatusFac, TimeUtilFac, PreferencesCookieReloadedFac) {

            var RaceAlertsFac = {
                _getSettings: function () {
                    var deferred = $q.defer();

                    PreferencesCookieReloadedFac.getPreferences().then(function (response) {
                        console.log('preferences', response);
                        if (response) {
                            var emailNoticationsEnabled = response.emailNotificationsDisabled === "false" || !response.emailNotificationsDisabled;
                            deferred.resolve({ enabled: emailNoticationsEnabled });
                        } else {
                            deferred.reject(response);
                        }
                    }, function (e) {
                        deferred.reject(e.data);
                    });

                    return deferred.promise;
                },

                _changeSettings: function (alertsEnabled) {
                    var deferred = $q.defer();
                    var postData = { emailNotificationsDisabled: !alertsEnabled };
                    PreferencesCookieReloadedFac.updatePreferences(postData).then(function (response) {
                        if (response) {
                            deferred.resolve(response);
                        } else {
                            deferred.reject();
                        }
                    }, function (e) {
                        deferred.reject(e.data);
                    });

                    return deferred.promise;
                }
            };

            /***
             *
             * @param stableEntity
             * @param command: valid values - "add" "remove"
             * @param accountNumber: user account nnumber
             * @returns promise
             * @private
             */
            function _performMyStableRequest(stableEntity, command, accountNumber) {
                var deferred = $q.defer();
                var url;
                var ufcSubscriptions = [];
                var method;
                var newFavorite = [];
                if (["add", "remove"].indexOf(command) === -1) {
                    $timeout(function () {
                        deferred.reject("Invalid arguments: command");
                    }, 0);

                    return deferred.promise;
                }

                if ($rootScope.activeFeatures.myStableSearchByHorseFCPGraph && accountNumber) {
                    // use fcp to manage add/remove favorites
                    if (command === "remove") {
                        // add method
                        url = ConfigurationFac.getServiceApiUrl("ufc") + "users/" + accountNumber + "/favorites/" + stableEntity.id;
                        method = "DELETE";
                    } else {
                        ufcSubscriptions = stableEntity.subscriptions || ["timeToRace", "raceDay", "raceResults"];
                        // add method
                        newFavorite.push({
                            entityType: stableEntity.entityType,
                            entityName: stableEntity.name,
                            entityDob: stableEntity.dob,
                            entityRunnerId: stableEntity.entityRunnerId,
                            subscriptions: setSubscriptions(ufcSubscriptions)
                        });

                        url = ConfigurationFac.getServiceApiUrl("ufc") + "users/" + accountNumber + "/favorites";
                        method = "POST";
                    }
                } else {
                    method = "GET";
                    url = "ajax/stable/id/_command_/eid/_entityId_/type/_entityTypeId_";
                    url = url
                        .replace("_command_", command)
                        .replace("_entityId_", stableEntity.id)
                        .replace("_entityTypeId_", stableEntity.entityType.id);
                }

                $http({
                    method: method,
                    url: url,
                    data: newFavorite
                }).then(
                    function (response) {
                        if ($rootScope.activeFeatures.myStableSearchByHorseFCPGraph) {
                            stableEntity.added = true;
                        }
                        deferred.resolve(response.data);
                    }, function (err) {
                        deferred.reject(err);
                    }
                );

                return deferred.promise;
            }

            function _getEntitiesFiltered (stableEntity, postData) {
                if (postData.addFromModal) {
                    return stableEntity.subscriptions.filter(function (subscription) {
                        return subscription.active === true;
                    });
                } else {
                    return stableEntity.subscriptions;
                }
            }

            function setSubscriptions(subscriptionList) {
                return subscriptionList.map(function (subscriptionName) {
                    return ({
                        eventType: subscriptionName,
                        channels: [{
                            type: "email"
                        }]
                    });
                });
            }

            function _getEntityType (entityType) {
                // entityType: "runner", "jockey", "trainer", "owner"
                var baseEntites = {
                    runner: {
                        id: 1,
                        name: "Horse"
                    },
                    jockey: {
                        id: 2,
                        name: "Jockey"
                    },
                    trainer: {
                        id: 3,
                        name: "Trainer"
                    },
                    owner: {
                        id: 4,
                        name: "Owner"
                    },
                };

                return baseEntites[entityType];
            }

            function _getSubscriptions (subscriptions) {
                var baseRaceAlerts = {
                    raceResults: {
                        id: 0,
                        name: "Results"
                    },
                    raceDay: {
                        id: 1,
                        name: "Race Day"
                    },
                    timeToRace: {
                        id: 2,
                        name: "Entry"
                    },
                };

                var defaultSubscriptions = [
                    {
                        active: false,
                        alert: {
                            id: 0,
                            name: "Results"
                        }
                    },
                    {
                        active: false,
                        alert: {
                            id: 1,
                            name: "Race Day"
                        }
                    },
                    {
                        active: false,
                        alert: {
                            id: 2,
                            name: "Entry"
                        }
                    }
                ];

                subscriptions.forEach(function (subscription) {
                    var index = _.findIndex(defaultSubscriptions, function(el) {
                        return el.alert.name === baseRaceAlerts[subscription.eventType].name;
                    });
                    defaultSubscriptions[index].active = true;
                });

                return defaultSubscriptions;
            }

            function _transformStableEntityArrayToFormData(postData) {

                var formData = [];
                var stableEntityArray = postData.stableEntitiesList;

                stableEntityArray.forEach(function (stableEntity) {

                    var stableEntityBase = [
                        encodeURIComponent(stableEntity.entityType.id),
                        "[",
                        encodeURIComponent(stableEntity.id),
                        "]"
                    ].join("");

                    //As we cannot have the previous state of subscriptions we cannot perform any remove operation.
                    //So, we've to implement a filter to remove all subscriptions that have active prop as false
                    //TODO: Remove filter operation when API service with current status os subscriptions by entity is integrated.
                    _getEntitiesFiltered(stableEntity, postData).forEach(function (subscription) {
                        var formDataRow = [
                            stableEntityBase,
                            "[",
                            encodeURIComponent(subscription.alert.id),
                            "]",
                            "=",
                            subscription.active ? "add" : "rem"
                        ].join("");
                        formData.push(formDataRow);
                    });
                });

                return formData.join("&");
            }

            function _getRaceTodayEntities() {
                var deferred = $q.defer();
                var url = "ajax/stable/id/load/entity/today";

                $http.get(url).then(
                    function (response) {
                        deferred.resolve(_createRaceTodayEntitiesList(response.data));
                    }, function (err) {
                        deferred.reject(err);
                    }
                );
                return deferred.promise;
            }

            function _createRaceTodayEntitiesList(data) {
                var raceEntitiesList = [];

                if (_.isArray(data.races) && data.races.length) {
                    _.forEach(data.races, function(race) {
                        raceEntitiesList.push(
                            raceTodayBuilder()
                                .withId(race.EntityID)
                                .withName(race.EntityName)
                                .withType(data.types[race.EntityType])
                                .withTrackName(race.TrackName)
                                .withTrackAbbr(race.TrackAbbreviation)
                                .withRaceNumber(race.Race)
                                .withTime(moment(race.PostTime).format('HH:mm A'))
                                .withMtp(new MtpDisplay(new Date(TimeUtilFac.getTimestamp(race.PostTime))).timeText)
                                .build()
                        );
                    });
                }

                return raceEntitiesList;
            }

            function _createSubscriptions(subscriptions, onlyNames) {
                var updatedSubscriptions = [];
                var eventTypeParser = {
                    timeToRace: "Entry",
                    raceDay: "Race Day",
                    raceResults: "Results"
                };

                subscriptions.forEach(function (subscription) {
                    if (subscription.active) {
                        if (onlyNames) {
                            updatedSubscriptions.push(_.invert(eventTypeParser)[subscription.alert.name]);
                        } else {
                            updatedSubscriptions.push({
                                eventType: _.invert(eventTypeParser)[subscription.alert.name],
                                channels: [
                                    {
                                        type: "email"
                                    }
                                ]
                            });
                        }
                    }
                });
                return updatedSubscriptions;
            }

            return {
                getRaceAlertsSettings: RaceAlertsFac._getSettings,
                changeRaceAlertsSettings: RaceAlertsFac._changeSettings,
                getRaceTodayEntities: _getRaceTodayEntities,
                createSubscriptions: _createSubscriptions,
                getEntityType: _getEntityType,

                addToMyStable: function (stableEntity, accountNumber) {
                    if (accountNumber) {
                        return _performMyStableRequest(stableEntity, "add", accountNumber);
                    }
                    return _performMyStableRequest(stableEntity, "add");
                },

                removeFromMyStable: function (stableEntity, accountNumber) {
                    if (accountNumber) {
                        return _performMyStableRequest(stableEntity, "remove", accountNumber);
                    }
                    return _performMyStableRequest(stableEntity, "remove");
                },

                searchForMyStableEntity: function (entityTypeName, entitySearchValue) {

                    var deferred = $q.defer();

                    if (!_.isString(entityTypeName) || ["horse", "jockey", "owner", "trainer"].indexOf(entityTypeName.toLowerCase()) === -1) {
                        $timeout(function () {
                            deferred.reject("Invalid parameter: entityTypeName");
                        }, 0);
                        return deferred.promise;
                    }

                    if (!_.isString(entitySearchValue) || entitySearchValue.length < 1) {
                        $timeout(function () {
                            deferred.reject("Invalid parameter: entitySearchValue");
                        }, 0);
                        return deferred.promise;
                    }

                    //BUILD URL
                    var url = "ajax/stable/id/search/type/_entityType_/searchString/_searchValue_"
                        .replace("_entityType_", entityTypeName)
                        .replace("_searchValue_", entitySearchValue);

                    $http.get(url).then(function (response) {
                        //success
                        var entitiesSearched = [];

                        _.forEach(response.data, function (entity) {
                            entitiesSearched.push(
                                StableEntityBuilder()
                                    .withId(entity.ID)
                                    .withName(entity.Name)
                                    .withEntityType(stableEntityTypeFactory.createStableEntityBasedOnId(entity.EntityTypeID))
                                    .build()
                            );
                        });
                        //handle success data
                        deferred.resolve(entitiesSearched);
                    }, function (response) {
                        //error
                        //handle err
                        deferred.reject(response.data);
                    });

                    return deferred.promise;
                },

                addToRaceAlertsUfc: function (stableEntity, accountNumber) {

                    var deferred = $q.defer();

                    var updatedFavoriteData = [
                        {
                            favoriteId: stableEntity.id,
                            subscriptions: _createSubscriptions(stableEntity.subscriptions)
                        }
                    ];

                    $http({
                        method: "PUT",
                        url: ConfigurationFac.getServiceApiUrl("ufc") + "users/" + accountNumber +"/favorites",
                        headers: {
                            'x-tvg-context': 'tvg4-tvg'
                        },
                        data: updatedFavoriteData
                    }).then(function () {
                        //success
                        //handle success data
                        deferred.resolve();
                    }, function () {
                        //error

                        //handle err
                        deferred.reject();
                    });
                    return deferred.promise;
                },

                addToRaceAlerts: function (stableEntityArray, addFromModal) {

                    var deferred = $q.defer();

                    var url = "ajax/stable";
                    var headers = {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    };

                    $http({
                        method: "POST",
                        url: url,
                        // By default, the $http service will transform the outgoing request by
                        // serializing the data as JSON and then posting it with the content-
                        // type, "application/json". When we want to post the value as a FORM
                        // post, we need to change the serialization algorithm and post the data
                        // with the content-type, "application/x-www-form-urlencoded".
                        transformRequest: _transformStableEntityArrayToFormData,
                        data: {
                            stableEntitiesList: stableEntityArray,
                            addFromModal: addFromModal
                        },
                        headers: headers
                    }).then(function () {
                        //success
                        //handle success data
                        deferred.resolve();
                    }, function () {
                        //error

                        //handle err
                        deferred.reject();
                    });

                    return deferred.promise;
                },

                getStableEntities: function (horseName, jockeyName, trainerName, ownerName) {

                    var deferred = $q.defer();

                    var url = "ajax/stable/id/popup";


                    if (_.isString(horseName)) {
                        url += "/horse/" + encodeURI(horseName);
                    }
                    if (_.isString(jockeyName)) {
                        url += "/jockey/" + encodeURI(jockeyName);
                    }
                    if (_.isString(trainerName)) {
                        url += "/trainer/" + encodeURI(trainerName);
                    }
                    if (_.isString(ownerName)) {
                        url += "/owner/" + encodeURI(ownerName);
                    }

                    if (url === "ajax/stable/id/popup") {
                        $timeout(function () {
                            deferred.reject("invalid parameters");
                        }, 0);
                        return deferred.promise;
                    }

                    //ATM this endpoint is only returning specific data from each entity (id, entityTypeId) and not the subscriptions.
                    //Therefore, we are setting all the subscriptions to false.
                    //TODO: Update this behaviour when the backend service returns subscription information
                    $http.get(url).then(function (data) {

                        var stableEntities = [];

                        _.forEach(data.data.entities, function (value, keyName) {

                            if (["horse", "jockey", "trainer", "owner"].indexOf(keyName.toLowerCase()) === -1) {
                                return;
                            }

                            //Only first element is read. Just like TVG4
                            var entity = data.data.entities[keyName][0];

                            if (!_.isUndefined(entity)) {
                                var entityType = stableEntityTypeFactory.createStableEntityBasedOnId(entity.EntityTypeID);

                                //TODO: Update this behaviour when the backend service returns subscription information
                                var subscriptions = subscriptionAlertsFactory.createAllSubscriptionsAsDisabled();
                                var inMyStable = false;

                                stableEntities.push(
                                    StableEntityBuilder()
                                        .withId(entity.ID)
                                        .withName(entity.Name)
                                        .withEntityType(entityType)
                                        .withSubscriptions(subscriptions)
                                        .withInMyStable(inMyStable)
                                        .build()
                                );
                            }
                        });

                        deferred.resolve(stableEntities);

                    }, function () {
                        //handle error
                        deferred.reject();
                    });

                    return deferred.promise;
                },

                /**
                 *
                 * @param entityTypeName: String must "horse", "jockey", "owner", "trainer"
                 * @param pageNumber: Number Optional
                 * @param recordsPerPage: Number Optional
                 * @param useFCPService: toggle to use fcp service to retrieve favorites
                 * @param accountNumber: user accountnumber
                 * @returns {*}
                 */
                getRaceAlertsByEntityTypeName: function (entityTypeName, pageNumber, recordsPerPage, useFCPService, accountNumber) {
                    var deferred = $q.defer();

                    if (!_.isString(entityTypeName) || ["horse", "jockey", "owner", "trainer", "today"].indexOf(entityTypeName.toLowerCase()) === -1) {
                        $timeout(function () {
                            deferred.reject("Invalid parameter: entityTypeName");
                        }, 0);
                        return deferred.promise;
                    }

                    if (useFCPService && accountNumber) {
                        // refactor with UFC service ----------------------------
                        var ufcUrl= ConfigurationFac.getServiceApiUrl("ufc") + "users/" + accountNumber + "/favorites?entityTypes=_entityType_"
                            .replace("_entityType_", entityTypeName);

                        ufcUrl = ufcUrl.replace("horse", "runner");

                        if (_.isNumber(pageNumber)) {
                            ufcUrl += "&page=" + pageNumber;
                        }

                        if (_.isNumber(recordsPerPage)) {
                            ufcUrl += "&results=" + recordsPerPage;
                        }

                        $http.get(ufcUrl, {
                            headers: {
                                'x-tvg-context': 'tvg4-tvg'
                            }
                        }).then(function (data) {
                                var stableEntitiesPage = {
                                    stableEntities: [],
                                    pageInfo: {}
                                };
                                // info about pagination and results page
                                stableEntitiesPage.pageInfo = {
                                    PageNumber: data.data.pagination.currentPage,
                                    RecordsPerPage: recordsPerPage,
                                    TotalPageCount: data.data.pagination.totalPages,
                                    TotalRecordCount: data.data.pagination.totalResults,
                                };
                                // create format for display all favorites with subscriptions
                                _.forEach(data.data.favorites, function (favorite) {
                                    stableEntitiesPage.stableEntities.push({
                                        id: favorite.favoriteId,
                                        inMyStable: true,
                                        name: favorite.entityName,
                                        entityType: _getEntityType(favorite.entityType),
                                        subscriptions: _getSubscriptions(favorite.subscriptions)
                                    });
                                });
                                deferred.resolve(stableEntitiesPage);
                            },
                            function () {
                                //Handle error
                                $timeout(function () {
                                    deferred.reject("Request error");
                                }, 0);
                            }
                        );
                    } else {
                    //BUILD URL
                    var url = "ajax/stable/id/load/entity/_entityType_"
                        .replace("_entityType_", entityTypeName);

                    if (_.isNumber(pageNumber)) {
                        url += "/page/" + pageNumber;
                    }

                    if (_.isNumber(recordsPerPage)) {
                        url += "/records/" + recordsPerPage;
                    }

                    $http.get(url).then(function (data) {

                            var stableEntitiesPage = {
                                stableEntities: [],
                                pageInfo: {}
                            };

                            _.forEach(data.data.stable, function (value, entityTypeName) {

                                _.forEach(value, function (stableItem, keyName) {

                                    if (keyName === "info") {
                                        stableEntitiesPage.pageInfo = stableItem;
                                        return;
                                    }

                                    var subscriptions = [];
                                    stableItem.SubscriptionsAlerts.forEach(function (subscriptionId) {
                                        if ([0, 1, 2].indexOf(subscriptionId) === -1) {
                                            return;
                                        }
                                        var raceAlert = raceAlertFactory.createRaceAlertBasedOnId(subscriptionId);
                                        subscriptions[subscriptionId] = subscriptionAlertsFactory.createSubscription(raceAlert, true);
                                    });

                                    //set missing subscriptions as disable (not active);
                                    subscriptions = subscriptionAlertsFactory.createMissingSubscriptionsAsDisabled(subscriptions);

                                    //Each entity that has race alerts is automatically "in my stable"
                                    var inMyStable = true;
                                    var entityType = stableEntityTypeFactory.createStableEntityBasedOnName(entityTypeName);
                                    stableEntitiesPage.stableEntities.push(
                                        StableEntityBuilder()
                                            .withId(stableItem.ID)
                                            .withName(stableItem.Name)
                                            .withEntityType(entityType)
                                            .withSubscriptions(subscriptions)
                                            .withInMyStable(inMyStable)
                                            .build()
                                    );
                                });
                            });
                            deferred.resolve(stableEntitiesPage);
                        },
                        function () {
                            //Handle error
                            $timeout(function () {
                                deferred.reject("Request error");
                            }, 0);
                        });
                    }

                    return deferred.promise;
                }
            };
        }

        MyStableSvc.$inject = [
            '$rootScope',
            '$http',
            '$q',
            '$timeout',
            'ConfigurationFac',
            'RaceMtpStatusFac',
            'TimeUtilFac',
            'PreferencesCookieReloadedFac'
        ];

        return MyStableSvc;
    });

