'use strict';

define(
    'RaceSvc',[],
    function () {

        function RaceSvc() {
            var raceSvc;

            raceSvc = {
                createRacesArray: function (races) {
                    var result = [];
                    _.forEach(races, function (race) {
                        if (race.raceNumber) {
                            result.push({raceNumber: race.raceNumber, status: race.status});
                        } else {
                            result.push({raceNumber: race, status: "RO"});
                        }
                    });
                    return result;
                },
                searchForCurrentRace: function (races, raceId) {

                    var result = null;
                    if (angular.isArray(races) && races.length) {

                        var nRaces = races;
                        var i;
                        var race;

                        nRaces.sort(function (a, b) {
                            if (!a || !b) {
                                return 0;
                            }
                            a = parseInt(a.raceNumber);
                            b = parseInt(b.raceNumber);
                            return a - b;
                        });

                        for (i = 0; i < nRaces.length; i++) {
                            race = nRaces[i];

                            if (race.id === raceId) {
                                result = {index: i, race: race};
                                break;
                            }
                        }
                    }

                    return result;
                }
            };

            return {
                searchForCurrentRace: raceSvc.searchForCurrentRace,
                createRacesArray: raceSvc.createRacesArray
            };
        }

        return RaceSvc;
    }
);

