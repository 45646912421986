'use strict';

define(
    'TvgAutocompleteDir',[],function () {
        function TvgAutocompleteDir($timeout) {
            return {
                restrict: 'E',
                templateUrl: 'js/desktop/modules/WidgetsDesktop/components/autocomplete/templates/tvg-autocomplete.html',
                controller: 'TvgAutocompleteCtrl',
                controllerAs: 'tvgAutocompleteCtrl',
                bindToController: true,
                scope: {
                    tValue: "=",
                    tOnSubmit: "&",
                    tMinLength: "=",
                    tCustomSearchFunction: "&",
                    tDelay: "=",
                    tMinimumCharMessage: "@",
                    tNoResultsMessage: "@",
                    tPlaceholder: "@",
                    tMaxlength: "@"
                },
                link: function(scope, elem){

                    scope._triggerTooltipEvent = function(nValue){
                        if (nValue) {
                            $timeout(function(){
                                elem.find("input").trigger("showTooltip");
                            }, 0);
                        }
                        else {
                            $timeout(function(){
                                elem.find("input").trigger("hideTooltip");
                            }, 0);
                        }
                    };

                    scope.$watch(scope.tvgAutocompleteCtrl.events.shouldShowTooltip, scope._triggerTooltipEvent);
                    scope.$watch(scope.tvgAutocompleteCtrl.events.shouldShowTooltip, scope._triggerTooltipEvent);
                }
            };
        }

        TvgAutocompleteDir.$inject = [
            "$timeout"
        ];

        return TvgAutocompleteDir;
    }
);


