'use strict';

define(
    'BetSlipOpenerHelperCtrl',[],
    function () {

        function BetSlipOpenerHelperCtrl($scope, $rootScope, RaceListFac, BetSlipOpenerHelperFactory, GTMFac) {

            var vm = this;
            vm.reFireWindowCallback = false;
            vm.windowCallbackCtrl = null;
            vm.unbindFunctions = [];

            // Create new scope.data else use the parent scope.date
            if(!$scope.data) {
                $scope.data = {};
            }

            $scope.data.isLoading = true;
            $scope.data.trackAbbr = '';

            BetSlipOpenerHelperFactory.setMtpRefreshSystem($rootScope.activeFeatures.globalMtpRefreshSystem);

            /**
             * Detect any route change success. If we're on the program page we'll be using the current track and race the user is viewing
             * If not, we need to get the soonest upcoming race
             */
            $scope.$on('$routeChangeSuccess', function(){
                _updateBetSlipUrl();
            });

            /**
             * This function is for the new-window directive.
             * Before the betslip window opens up, this function will execute.
             * If the user was logged out, probably by a session timeout, the button to open betslip should pop the login modal.
             * @param windowCallback
             */
            $scope.events.validateConditions = function(windowCallback){
                if(!$rootScope.userSession){
                    vm.reFireWindowCallback = true;
                    vm.windowCallbackCtrl = windowCallback;
                    $rootScope.$emit("callLoginRequiredModal");
                }else{
                    vm.reFireWindowCallback = false;
                    vm.windowCallbackCtrl = null;
                    windowCallback();
                }

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Header | Create Bet Ticket')
                    .withEventLabel('Header-CreateBetTicket')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));

            };

            /**
             * This event is fired when the user logs in.
             * Refires the window open callback.
             * Check $scope.events.validateConditions function.
             */
            $scope.$on('login', function (event, valid) {
                if(valid && vm.windowCallbackCtrl && vm.reFireWindowCallback){
                    vm.reFireWindowCallback = false;
                    vm.windowCallbackCtrl();
                    vm.windowCallbackCtrl = null;
                }
            });

            /**
             * On destroy unsubscribe the upcoming races poller
             */
            $scope.$on("$destroy", function () {
                vm.unbindFunctions.forEach(function (unbind) {
                    unbind();
                });
                RaceListFac.unSubscribe($scope.$id);
            });

            /**
             * Updates betslip url and broadcasts event to directive to update its attribute with the new value.
             * @private
             */
            function _updateBetSlipUrl(){
                $scope.data.isLoading = true;
                BetSlipOpenerHelperFactory.getBetSlipUrl().then(function(betslipUrl){
                    $scope.$broadcast('BetSlipOpenerHelper::UpdateWindowRoute', betslipUrl);
                    $scope.data.isLoading = false;
                    $scope.data.trackAbbr = BetSlipOpenerHelperFactory.getCurrentTrackAbbr();
                    $scope.data.trackName = BetSlipOpenerHelperFactory.getCurrentTrackName();
                    $scope.data.raceNumber = BetSlipOpenerHelperFactory.getCurrentRaceNumber();
                });
            }

            /**
             * Subscribe to the upcoming races poller.
             * @private
             */
            function _init() {
                if(!$rootScope.activeFeatures.globalMtpRefreshSystem) {
                    RaceListFac.subscribe($scope.$id);

                    vm.unbindFunctions.push($rootScope.$on('upComingRacesListReady', function () {
                        //we dont need the information sent with the event. It will be already available on the factory.
                        _updateBetSlipUrl();
                    }));
                }
                else {
                    vm.unbindFunctions.push($rootScope.$on('mtpStatusUpdated', function () {
                        _updateBetSlipUrl();
                    }));
                }

                // expects to receive the event with the current race status to
                // built the betslip opener with a wagerable race at all times
                vm.unbindFunctions.push($rootScope.$on('_raceprogramRaceUpdate', function (event, data) {
                    BetSlipOpenerHelperFactory.setProgramPageRacesData(data);
                    _updateBetSlipUrl();
                }));
            }

            _init();
        }

        BetSlipOpenerHelperCtrl.$inject = [
            '$scope',
            '$rootScope',
            'RaceListFac',
            'BetSlipOpenerHelperFactory',
            'GTMFac'
        ];

        return BetSlipOpenerHelperCtrl;
    }
);

