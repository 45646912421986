'use strict';

define(
    'RaceMtpStatusFac',['lodash'],
    function (_) {
    /**
     * This factory starts a polling to know every race's MTP that is under 120 MTP (2 hours)
     * and will broadcast an event to the entire application when detecting changes.
     * All MTPs across the platform must come from here.
     *
     * @param GraphRacesMtpStatus
     * @param $rootScope
     * @param CookieFac
     * @param $log
     * @returns {{getRaceMtpStatus: getRaceMtpStatus}}
     * @constructor
     */
    var raceDate = "";

    function RaceMtpStatusFac(GraphRacesMtpStatus, $rootScope, $log, $filter, WagerProfileFac) {
        var mtpStatusRegistry = {};

        var subscriptionTimer = $filter('CMSLabels')('low', 'graphqlPollingRates') || 30000; // in ms

        function _onSuccess(result) {
            raceDate = result.raceDate;

            _.forEach(mtpStatusRegistry, function (race) {
                race.raceStatus = 'RO';
            });

            _.forEach(result.races, function (race) {
                mtpStatusRegistry[race.raceId] = race;
            });

            $rootScope.$broadcast('mtpStatusUpdated');
        }

        function _subscribe() {
            GraphRacesMtpStatus.subscribe(
                { wagerProfile: WagerProfileFac.getWagerProfile() },
                { success: _onSuccess, fail: _onError },
                subscriptionTimer,
                true
            );
        }

        function _refreshData() {
            return GraphRacesMtpStatus.refetch({ wagerProfile: WagerProfileFac.getWagerProfile() });
        }

        function _onError() {
            $log.error('Error getting the MTP status via GraphQL');
        }

        (function () {
            _subscribe();
        }());

        return {
            getRaceMtpStatus: function (raceId) {
                return mtpStatusRegistry[raceId] || {};
            },
            getClosestRaceToBet: function() {
                return _.find(mtpStatusRegistry, function (race) {
                    var code = (race.raceStatus.code || '').toLowerCase();
                    return code === 'ic' || code === 'o' || code === 'mo';
                });
            },
            getRaceDate: function () {
                return raceDate;
            },
            refreshData: _refreshData
        };
    }

    RaceMtpStatusFac.$inject = [
        'GraphRacesMtpStatus',
        '$rootScope',
        '$log',
        '$filter',
        'WagerProfileFac'
    ];

    return RaceMtpStatusFac;

});

