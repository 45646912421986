"use strict";

define('NextRacesItemsFac',['NextRaceItemModel'], function (NextRaceItemModel) {


    function NextRacesItemsFactory(FavoriteTracksFac) {

        return {
            getFavoriteTrackList: function () {
                return FavoriteTracksFac.getFavoriteTracks();
            },
            createNextRacesItems: function (racesRaw, isUserLoggedIn) {
                var favoriteTracksList;

                if(!racesRaw) return [];

                if (isUserLoggedIn) {
                    favoriteTracksList = FavoriteTracksFac.getFavoriteTracks();
                } else {
                    favoriteTracksList = [];
                }

                return racesRaw.map(function (race) {
                    var isFavorite = favoriteTracksList.indexOf(race.trackAbbr) > -1;

                    return new NextRaceItemModel(
                        race.trackAbbr,
                        race.trackName,
                        race.perfAbbr,
                        race.raceNumber,
                        race.MTP,
                        race.postTime,
                        isFavorite,
                        race.raceTypeId
                    );
                });
            }
        };

    }

    NextRacesItemsFactory.$inject = [
        'FavoriteTracksFac'
    ];


    return NextRacesItemsFactory;


});

