'use strict';

define('RaceListFac',[
        'lodash',
        'RaceStatusUtils'
    ],
    function (_, RaceStatusUtils) {

        function RaceListFac(RaceInfoFac, $rootScope) {

            var upComingRaces = null, resultsList = null,
                deferredUpcoming = null, deferredResults = null, subscriptions = [];

            function _getUpcomingRaces(id){
                if(id)
                    _subscribe(id);

                if (upComingRaces) {
                    return upComingRaces;
                } else {
                    return [];
                }
            }

            function _getResultRaces(id){
                _subscribe(id);
                if (resultsList) {
                    return resultsList;
                } else {
                    return [];
                }
            }
            // pollers logic
            function processUpcomingRaces(data){
                upComingRaces = data ? data : [];
                $rootScope.$emit('upComingRacesListReady', upComingRaces);
            }

            function processResultsRaces(data){
                resultsList = data ? data : [];
                $rootScope.$emit('resultsRacesListReady', resultsList);
            }

            function _startPollers() {
                if(!deferredUpcoming && !deferredResults && subscriptions.length) {
                    deferredUpcoming = RaceInfoFac.pollUpcomingRaces().then(null, null, processUpcomingRaces);
                    deferredResults = RaceInfoFac.pollRaceResultsList().then(null, null, processResultsRaces);
                }
            }

            function _stopPollers() {
                if(deferredUpcoming && deferredResults) {
                    RaceInfoFac.stopPollingUpcomingRaces(deferredUpcoming);
                    RaceInfoFac.stopPollingRaceResultsList(deferredResults);
                    deferredUpcoming = null;
                    deferredResults = null;
                }
            }

            function _restartPollers() {
                _stopPollers();
                _startPollers();
            }

            // subscribers
            function _subscribe(id) {
                var exist = _.find(subscriptions, function(subscription){
                    return subscription === id;
                });
                if(!exist) {
                    subscriptions.push(id);
                    _startPollers();
                }
            }

            function _unSubscribe(id) {
                subscriptions = _.filter(subscriptions, function(subscription) {
                    return subscription !== id;
                });
                if(subscriptions.length === 0) {
                    _resetRaces();
                    _stopPollers();
                }
            }

            // Auxiliary function
            function _resetRaces() {
                upComingRaces = [];
                resultsList = [];
            }

            // business logic function
            function _getAllowedRaces(trackRaces) {
                var races = [];

                // skip
                if (!upComingRaces) { return races; }

                _.forEach(trackRaces, function (trackRace) {
                    var status = trackRace.status;

                    if (!RaceStatusUtils.isStatusOpen(status) &&
                        !RaceStatusUtils.isStatusManuallyOpened(status)) {
                        races.push(trackRace);
                    }
                    else if (verifyRaceAllowed(trackRace, upComingRaces)) {
                        races.push(trackRace);
                    }
                });

                return races;
            }

            function _getFirstUpcomingRace(races) {
                var allowedRaces = _getAllowedRaces(races);
                var nextOpenRace = _.find(races, function (raceCandidate) {
                var status = raceCandidate.status;

                    if (RaceStatusUtils.isStatusOpen(status) ||
                        RaceStatusUtils.isStatusUpNext(status) ||
                        RaceStatusUtils.isStatusManuallyOpened(status)) {
                        return verifyRaceAllowed(raceCandidate, allowedRaces);
                    }

                    return false;
                });

                return nextOpenRace ?
                    nextOpenRace.raceNumber : races[0].raceNumber;
            }

            function verifyRaceAllowed(raceCandidate, allowedRaces) {
                var foundRace = _.find(allowedRaces, function (race) {
                    return race.id === raceCandidate.id;
                });
                return foundRace ? true : false;
            }

            $rootScope.$on("wagerprofile", function () {
                _resetRaces();
                _restartPollers();
            });

            return {
                getUpcomingRaces: _getUpcomingRaces,
                getResultRaces: _getResultRaces,
                getAllowedRaces: _getAllowedRaces,
                getFirstUpcomingRace: _getFirstUpcomingRace,
                resetRaces: _resetRaces,
                unSubscribe: _unSubscribe,
                subscribe: _subscribe
            };
        }

        RaceListFac.$inject = [
            'RaceInfoFac',
            '$rootScope'
        ];

        return RaceListFac;

    });

