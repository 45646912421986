"use strict";

define('RaceInfoEntityBuilder',['RaceInfoEntity'], function (RaceInfoEntity) {

    function builder() {
        var raceInfo = new RaceInfoEntity();

        return {
            withTrackAbbr: function (trackAbbr) {
                raceInfo.trackAbbr = trackAbbr;
                return this;
            },
            withPerfAbbr: function (perfAbbr) {
                raceInfo.perfAbbr = perfAbbr;
                return this;
            },
            withRaceNumber: function (raceNumber) {
                raceInfo.raceNumber = raceNumber;
                return this;
            },
            withRaceDate: function (raceDate) {
                raceInfo.raceDate = raceDate;
                return this;
            },
            withSurfaceTypeId: function (surfaceTypeId) {
                raceInfo.surfaceTypeId = surfaceTypeId;
                return this;
            },
            withRaceTypeId: function (raceTypeId) {
                raceInfo.raceTypeId = raceTypeId;
                return this;
            },
            withSurfaceType: function (surfaceType) {
                raceInfo.surfaceType = surfaceType;
                return this;
            },
            withSurfaceName: function (surfaceName) {
                raceInfo.surfaceName = surfaceName;
                return this;
            },
            withRaceClassId: function (raceClassId) {
                raceInfo.raceClassId = raceClassId;
                return this;
            },
            withRaceClass: function (raceClass) {
                raceInfo.raceClass = raceClass;
                return this;
            },
            withTrackName: function (trackName) {
                raceInfo.trackName = trackName;
                return this;
            },
            withPurse: function (purse) {
                raceInfo.purse = purse;
                return this;
            },
            withDistance: function (distance) {
                raceInfo.distance = distance;
                return this;
            },
            withId: function (id) {
                raceInfo.id = id;
                return this;
            },
            withDescription: function (description) {
                raceInfo.description = description;
                return this;
            },
            build: function () {
                //return Object.seal(raceResult);
                return raceInfo;
            }
        };
    }

    return builder;

});


