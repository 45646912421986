'use strict';

// TODO - support multiple legs

define(
    'BetRunnerPicksSvc',[],
    function () {

        function BetRunnerPicksSvc() {

            var pickStrings = {
                'WN': ['To win'],
                'PL': ['To place'],
                'SH': ['To show'],
                'WP': ['To win or place'],
                'WS': ['To win or show'],
                'PS': ['To place or show'],
                'WPS': ['To win, place or show']
            };

            function byRunnerNumber(runnerNumber) {
                return function (runner) {
                    return runnerNumber === parseInt(runner.horseID);
                };
            }

            function getPickedRunners(bettingInterests, runnersArray) {
                var pickedRunners = [];
                runnersArray.forEach(function (runnerPick) {
                    var runner = _.find(bettingInterests, byRunnerNumber(runnerPick));
                    if (runner) {
                        pickedRunners.push(runner);
                    }
                });
                return pickedRunners;
            }

            function betTypeStpesMatchPickedLegs(pickedLegs, pickSteps) {
                return _.isArray(pickedLegs) && pickSteps.length === pickedLegs.length;
            }

            function _getPicksForBetType(race, pickedLegs, betType) {
                var betPicks = [];

                var pickSteps = pickStrings[betType.abbreviation];
                if (betTypeStpesMatchPickedLegs(pickedLegs, pickSteps)) {
                    pickSteps.forEach(function (step, i) {
                        betPicks.push({
                            'pickStepName': step,
                            'bettingInterests': getPickedRunners(race.bettingInterests, pickedLegs[i])
                        });
                    });
                }

                return betPicks;
            }

            return {
                getPicksForBetType: _getPicksForBetType
            };
        }

        BetRunnerPicksSvc.$inject = [
            'TrackPerformanceProvider'
        ];

        return BetRunnerPicksSvc;
    }
);

