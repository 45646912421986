'use strict';

define('PreferencesEntity',[],function () {

    function preferencesEntity(description, metadata, hash){

        this.description = description;
        this.metadata = metadata;
        this.hash = hash;

    }

    return preferencesEntity;

});


