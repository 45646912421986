'use strict';

define('FavoriteCookieFac',[
        'CookieFac'
    ],
    function () {
        function FavoriteCookieFac(
                base64,
                CookieFac,
                $http,
                $q,
                $window
            ) {
            var COOKIE_NAME = 'TVG_favorite_tracks';

            function _getFavoriteTrackList() {
                var favCookie = {
                    track_abrev_list: []
                };

                var tmpCookie = CookieFac.getCookie(COOKIE_NAME);

                if (tmpCookie) {
                    try {
                        var decodedFavList = base64.decode(tmpCookie);
                        favCookie.track_abrev_list = decodedFavList.split(',');
                    }
                    catch (e) {
                        favCookie.track_abrev_list = [];
                    }
                }
                return favCookie;
            }

            /**
             * Convert the favorites tracks abbreviations array into a
             * base 64 encoded list string (separated by commas).
             *
             * @example
             * _updateFavoriteTrackList(['HKG', 'LKJ']) -> 'SEtHLExLSg=='
             *
             * @param {Array}  newFavTrackAbrevList  Array of favorites tracks abbreviations
             * @param {Object}                       Cookie key, name and options
             */
            function _updateFavoriteTrackList(newFavTrackAbrevList) {
                var decoded = (newFavTrackAbrevList || []).filter(function (fav) {
                    return (fav && fav !== 'undefined');
                }).join(',');

                var key = COOKIE_NAME;
                var value = base64.encode(decoded);

                return CookieFac.setCookie(key, value, {
                    path: '/',
                    domain: CookieFac.extractDomain($window.location.hostname),
                    Session: true
                });
            }

            function _storeFavoriteTrackList() {
                var deferred = $q.defer();

                $http({
                    method: 'GET',
                    url: "/ajax/preferences/update/favoriteData"
                }).success(function (response) {
                    deferred.resolve(response);
                }).error(function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }

            return {
                getFavoriteTrackList: _getFavoriteTrackList,
                updateFavoriteTrackList: _updateFavoriteTrackList,
                storeFavoriteTrackList: _storeFavoriteTrackList
            };
        }

        FavoriteCookieFac.$inject = [
            'base64',
            'CookieFac',
            '$http',
            '$q',
            '$window'
        ];

        return FavoriteCookieFac;
    });

