'use strict';

define(
    'FooterLinks',[
        'FooterLinksDir',
        'angular'
    ],
    function (FooterLinksDir, angular) {

        return angular.module('TVG.FooterLinks', [])
            .component('footerLinksList', FooterLinksDir).name;
    }
);

