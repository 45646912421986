'use strict';

define(
    'AccountSummaryBasicDetailsCtrl',[],
    function () {
        function AccountSummaryBasicDetailsCtrl($rootScope, $scope, AccountSummarySvc) {
            var gkeAccountSummary = $rootScope.activeFeatures.gkeAccountSummary;
            $scope.gkeAccountSummary = gkeAccountSummary;

            $scope.data = {
                resultToShow: []
            };

            $scope.events = {
                changeNavigationResultsPerPage: _changeNavigationResultsPerPage,
                switchPage: _switchPage
            };

            $scope.error = null;

            function _switchPage(page) {
                $scope.pagination.currentPage = page;
                $scope.loading = true;
                _requestDetailsByDate();
            }

            function _requestDetailsByDate() {
                AccountSummarySvc.getAccountSummaryCategoryDetailByDateFromGke(
                    $scope.url,
                    $scope.pagination.currentPage,
                    $scope.pagination.selectedRecordsPerPage
                ).then(getResultsSuccess, getResultsFailed);
            }

            function _changeNavigationResultsPerPage() {
                $scope.loading = true;
                _requestDetailsByDate();
            }

            function getResultsSuccess(result) {
                $scope.data.resultToShow = result.categoryDetailsList;
                $scope.loading = false;
                $scope.error = null;
                $scope.pagination.totalRecords = result.totalRecordCount;
            }

            function getResultsFailed(error) {
                $scope.error = error;
                $scope.loading = false;
            }

            function _resetPagination() {
                $scope.pagination = {
                    recordsPerPage: [25, 50, 100],
                    currentPage: 1,
                    totalPages: 1,
                    totalRecords: 0,
                    maxPagesOnPagination: 3,
                    selectedRecordsPerPage: 25
                };
            }

            (function init() {
                $scope.loading = true;
                _resetPagination();

                if (gkeAccountSummary) {
                    _requestDetailsByDate();
                } else {
                    AccountSummarySvc.getAccountSummaryCategoryDetailByDate($scope.categoryId, $scope.startDate, $scope.endDate).then(getResultsSuccess, getResultsFailed);
                }
            }());
        }

        AccountSummaryBasicDetailsCtrl.$inject = [
            '$rootScope',
            '$scope',
            'AccountSummarySvc'
        ];

        return AccountSummaryBasicDetailsCtrl;
    }
);

