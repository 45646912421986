'use strict';

define('GetNestedPropertyValueFilter',[],
    function () {

        function _getNestedPropertyValue(propertyPath, obj, defaultVal) {
            if (!angular.isObject(obj)) {
                throw new Error("_getNestedProperty(): obj must be an object.");
            }
            if (!angular.isString(propertyPath)) {
                throw new Error("_getNestedProperty(): propertyPath must be a String.");
            }

            var value = obj;
            propertyPath.split(".").forEach(function (prop) {
                value = value[prop];
            });

            return value || defaultVal;
        }

        function GetNestedPropertyValueFilter(input, obj, defaultVal) {
            var value;
            try {
                value = _getNestedPropertyValue(input, obj, defaultVal);
            }
            catch (e) {
                value = defaultVal;
            }

            return value;
        }

        return function () {
            return GetNestedPropertyValueFilter;
        };

    });

