'use strict';

define(
    'RaceReplaysNavigationCtrl',['lodash'],
    function (_) {
        function RaceReplaysNavigationCtrl($scope, $rootScope, $filter) {

            $scope.navigationParams = {};

            var unbind = [];
            var data = $scope.$parent;
            var selectedRunnerForReplays = data.hasOwnProperty('selectedRunnerForReplays') ? data.selectedRunnerForReplays : false;

            $scope.navigation = {
                pages: [
                    {
                        id: "REPLAYS",
                        title: $rootScope.activeFeatures.raceReplaysHideResults ? $filter('CMSLabels')('replaysNavigationPage', 'raceReplaysLabels') : (selectedRunnerForReplays.hasOwnProperty('horseName') ? "Race Replays for " + selectedRunnerForReplays.horseName : ""),
                        templateUrl: "js/desktop/modules/Races/templates/desktop/race-replays-video-and-race-list.html"
                    }
                ],
                history: [],
                goTo: function (pageId, params) {
                    $scope.navigation.isPush = true;
                    var page = _.find(this.pages, function (page) {
                        return page.id === pageId;
                    });
                    this.history.push(page);
                    $scope.navigationParams = params;
                    this.current = page;
                },
                goBack: function () {
                    if (this.history.length > 1) {
                        this.history.pop();
                        this.current = this.history[this.history.length - 1];
                    }
                },
                current: {}
            };

            (function init() {
                $scope.navigation.goTo("REPLAYS");

                unbind.push($rootScope.$on('closeRaceReplaysModal', $scope.$close));

                $scope.$on('$destroy', function() {
                    _.forEach(unbind, function (bind) {
                        bind();
                    });
                });
            }());

        }

        RaceReplaysNavigationCtrl.$inject = [
            '$scope',
            '$rootScope',
            '$filter'
        ];

        return RaceReplaysNavigationCtrl;
    }
);

