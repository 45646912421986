'use strict';

define('RaceReplayItemCtrl',[], function () {

        function RaceReplayItemCtrl($scope, $rootScope, $filter, GTMFac, RaceUrlFac) {

            $scope.messages = {
                'btnClose': $filter('CMSLabels')('toggleButtonClose', 'raceReplaysLabels'),
                'btnOpen': $filter('CMSLabels')('toggleButtonOpen', 'raceReplaysLabels')
            };

            $scope.raceCardOpened = false;
            $scope.errorGettingResults = false;

            $scope.events = {
                toggleRaceCard: _toggleRaceCard,
                showRedirectBtn: _showRedirectBtn,
                programPageUrl: _programPageUrl,
                goToProgramPage: _goToProgramPage
            };

            function _goToProgramPage() {
                $rootScope.$emit('closeRaceReplaysModal');
            }

            function _programPageUrl() {
                return RaceUrlFac.generateRacePath(
                    $scope.data.trackAbbr,
                    $scope.data.trackName,
                    $scope.data.raceNumber,
                    false);
            }

            function _showRedirectBtn() {
                return $rootScope.activeFeatures.RaceReplayToProgramPageLink && moment($scope.data.postTime).format('YYYY-MM-DD') === moment().format("YYYY-MM-DD");
            }

            function _toggleRaceCard() {
                $scope.raceCardOpened = !$scope.raceCardOpened;

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction("Race Replays Page Click")
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Race card toggle | ' + ($scope.raceCardOpened ? 'Expand' : 'Collapse'))
                    .withEventLabel('RaceReplays_RaceCardToggle_' + ($scope.raceCardOpened ? 'Expand' : 'Collapse'))
                    .build();
                var genericEvent = GTMFac.genericEvent(eventData);
                GTMFac.GTMEvent().send($rootScope, 'siteClick', genericEvent);
            }
        }

        RaceReplayItemCtrl.$inject = [
            '$scope',
            '$rootScope',
            '$filter',
            'GTMFac',
            'RaceUrlFac'
        ];

        return RaceReplayItemCtrl;
    }
);

