'use strict';

define('PasswordValidatorSvc',[],function () {

    function PasswordValidatorSvc($filter) {

        var data = {
            validations : {
                letter: /[a-zA-Z]/,
                number: /\d/,
                maxLenght: 20,
                minLenght: 8
            }
        };

        function _fillPasswordValidations(password, validationsHolder) {
            validationsHolder.validPasswordPattern = true;

            if (_validLenght(password.length)) {
                validationsHolder.ruleLength = true;
            } else {
                validationsHolder.validPasswordPattern = false;
                validationsHolder.ruleLength = false;
            }

            if (data.validations.letter.test(password)) {
                validationsHolder.ruleLetter = true;
            } else {
                validationsHolder.validPasswordPattern = false;
                validationsHolder.ruleLetter = false;
            }

            if (data.validations.number.test(password)) {
                validationsHolder.ruleNumber = true;
            } else {
                validationsHolder.validPasswordPattern = false;
                validationsHolder.ruleNumber = false;
            }
        }

        function _validLenght(length) {
            return length >= data.validations.minLenght
                   && length <= data.validations.maxLenght;
        }

        function _arePasswordsMatching(currentPassword, newPassword) {
            return !(newPassword !== currentPassword && currentPassword !== "");
        }

        var _invalidPasswordReasonsEnum = {
            MIN_LENGTH: 1,
            LETTER: 2,
            NUMBER: 4,
            PASSWORDS_DONT_MATCH: 7,
            EQUAL_TO_OLD: 8
        };

        function _validatePasswordRules(newPassword, confirmPassword, messageHandlerCallback) {
            var ret = false;
            if (!_validLenght(newPassword.length)) {
                _handleInvalidPasswordBasedOnReason(_invalidPasswordReasonsEnum.MIN_LENGTH, messageHandlerCallback);
            } else if (!(data.validations.letter.test(newPassword))) {
                _handleInvalidPasswordBasedOnReason(_invalidPasswordReasonsEnum.LETTER, messageHandlerCallback);
            } else if (!(data.validations.number.test(newPassword))) {
                _handleInvalidPasswordBasedOnReason(_invalidPasswordReasonsEnum.NUMBER, messageHandlerCallback);
            } else if (newPassword !== confirmPassword) {
                _handleInvalidPasswordBasedOnReason(
                    _invalidPasswordReasonsEnum.PASSWORDS_DONT_MATCH, messageHandlerCallback);
            } else {
                ret = true;
            }

            return ret;
        }

        function _handleInvalidPasswordBasedOnReason(reasonEnum, messageHandlerCallback){

            switch(reasonEnum){

                case _invalidPasswordReasonsEnum.MIN_LENGTH:
                    messageHandlerCallback($filter('CMSValue')('changePasswordMessageAtLeast'));
                    break;

                case _invalidPasswordReasonsEnum.LETTER:
                    messageHandlerCallback($filter('CMSValue')('changePasswordMessageLowercase'));
                    break;

                case _invalidPasswordReasonsEnum.NUMBER:
                    messageHandlerCallback($filter('CMSValue')('changePasswordMessageNumber'));
                    break;

                case _invalidPasswordReasonsEnum.PASSWORDS_DONT_MATCH:
                    messageHandlerCallback($filter('CMSValue')('changePasswordMessageMatch'));
                    break;

                case _invalidPasswordReasonsEnum.EQUAL_TO_OLD:
                    messageHandlerCallback($filter('CMSValue')('changePasswordMessageDifferentOldPassword'));
                    break;
            }
        }

        function _validatePasswordEquality(newPassword, oldPassword, handleMessageCallback) {
            var valid = true;
            if (newPassword === oldPassword) {
                _handleInvalidPasswordBasedOnReason(_invalidPasswordReasonsEnum.EQUAL_TO_OLD, handleMessageCallback);
                valid = false;
            }
            return valid;
        }

        function _validatePasswords(newPassword, confirmPassword, oldPassword, handleMessageCallback) {
            return _validatePasswordRules(newPassword, confirmPassword, handleMessageCallback) &&
                    _validatePasswordEquality(newPassword, oldPassword, handleMessageCallback);
        }

        function _validateEmptyPasswords(newPassword, confirmPassword, oldPassword, handleMessageCallback) {
            var valid = true;
            if (!oldPassword || oldPassword == "") {
                handleMessageCallback($filter('CMSValue')('changePasswordFillOldPassword'));
                valid = false;
            } else if (!newPassword || newPassword == "") {
                handleMessageCallback($filter('CMSValue')('changePasswordFillNewPassword'));
                valid = false;
            } else if (!confirmPassword || confirmPassword == "") {
                valid = false;
                handleMessageCallback($filter('CMSValue')('changePasswordFillConfirmPassword'));
            }
            return valid;
        }

        function _validateEmptyPassword(password, handleMessageCallback) {
            var valid = true;
            if (!password || password == "") {
                valid = false;
                handleMessageCallback($filter('CMSValue')('loginPasswordMissing'));
            }
            return valid;
        }

        return {
            fillPasswordValidations: _fillPasswordValidations,
            arePasswordsMatching: _arePasswordsMatching,
            validatePasswords: _validatePasswords,
            validatePasswordRules: _validatePasswordRules,
            validateEmptyPasswords: _validateEmptyPasswords,
            validateEmptyPassword: _validateEmptyPassword,
            validLenght: _validLenght
        };
    }

    PasswordValidatorSvc.$inject = [
        '$filter'
    ];

    return PasswordValidatorSvc;
});

