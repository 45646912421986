'use strict';

define('CookieFac',[
        'angularCookies',
        'angularUTF8Base64'
    ],
    function () {
        function CookieFac(base64, ipCookie, $window) {

            //'ipCookie',

            // this factory will take in a cookie name, and decode it and return the cookie object... too this factory will need to be able to set / update params on these cookies
            function _getCookie(cookieName) {
                var value = ipCookie(cookieName);
                if (!angular.isDefined(value)) {
                    return null;
                }
                return value;
            }

            function _setCookie(cookieName, cookieString, options) {
                return ipCookie(cookieName, cookieString, options);
            }

            function _removeCookie(key, options) {
                return ipCookie.remove(key, options);
            }

            function _getTVGFormatCookie(cookieName) {
                var returnData = {},
                    cookie = _getCookie(cookieName),
                    cookieData = cookie ? base64.decode(cookie).split(';') : [];

                angular.forEach(cookieData, function (key) {
                    var tmp = key.split('=');
                    if (tmp[0] != undefined && tmp[0]) {
                        returnData[tmp[0]] = tmp[1];
                    }
                });

                return returnData;
            }

            // Set values to null to remove them from the cookie
            function _setTVGFormatCookieValue(cookieName, keyValueObject) {
                var cookieData = _getTVGFormatCookie(cookieName);

                if (keyValueObject === null || keyValueObject === undefined || !angular.isObject(keyValueObject) || typeof keyValueObject !== 'object') {
                    return false;
                }

                angular.extend(cookieData, keyValueObject);

                var newString = _convertCookieData(cookieData);

                return _setCookie(cookieName, base64.encode(newString), {path: '/'});
            }

            function _convertCookieData(keyValueObject) {
                var tmp = '';
                angular.forEach(keyValueObject, function (value, key) {
                    tmp += key + '=' + value + ';';
                });

                return tmp;
            }

            function _setEncodedCookie(cookieName, value, options) {
                var encodedValue = base64.encode(value);
                _setCookie(cookieName, encodedValue, options);
            }

            function _getEncodedCookie(cookieName) {
                var value = _getCookie(cookieName);
                if (value) {
                    return base64.decode(value);
                }
                return value;
            }

            /**
             * Calculate the correct domain for cookies, so it matches with the value
             * set by the server in Tvg\Util\Cookie::getDomain().
             * If we fail to set the correct domain, we will end up with repeated
             * cookies with different domains (causing some bugs in QA and other envs)
             *
             * 1) betfair domains -> get all parts except the first
             * 2) others -> get the last two domain parts
             *
             * see more:
             * /tvg3/library/Tvg/Util/Cookie.php
             *
             * @param {String}  [hostname] Current hostname
             * @return {String}            Cookie's domain
             */
            function _extractDomain(hostname) {
                var host = hostname || $window.location.hostname || '';
                var isBetfair = !!host.match(/betfair\./);
                var domain = '';

                if (isBetfair) {
                    domain = host.split('.').splice(1).join('.');
                } else {
                    domain = host.split('.').splice(-2, 2).join('.');
                }

                return domain;
            }

            return {
                getCookie: _getCookie,
                setCookie: _setCookie,
                removeCookie: _removeCookie,
                getTVGFormatCookie: _getTVGFormatCookie,
                setTVGFormatCookieValue: _setTVGFormatCookieValue,
                getEncodedCookie: _getEncodedCookie,
                setEncodedCookie: _setEncodedCookie,
                extractDomain: _extractDomain
            };
        }

        CookieFac.$inject = [
            'base64',
            'ipCookie',
            '$window'

        ];

        return CookieFac;
    });

