'use strict';

define('DistanceConverterFilter',[
        'Content'
    ],
    function () {
        function DistanceConverterFilter(DistanceConverterFac) {
            return function(distance) {
                return DistanceConverterFac.convertDistance(distance);
            };
        }

        DistanceConverterFilter.$inject = [
            'DistanceConverterFac'
        ];

        return DistanceConverterFilter;
    }
);

