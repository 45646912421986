/**
 * Created by pasoares on 12/05/2015.
 */
'use strict';

define(
    'MyBetsCancelBetCtrl',[],
    function() {

        function MyBetsCancelBetCtrl($scope, $rootScope, BetsSvc, GTMFac) {

            var SUCCESS_MESSAGE = 'Your bet has been cancelled successfully';

            /**
             * @private
             * @description
             * Function called when the user undo the cancel f the bet.
             *
             * @param {Number} index The index that matches the bet that might be canceled, in order to show the
             form in the correct position.
             */
            function _onNoClick() {
                var p;
                if (_.isUndefined($scope.raceBets[$scope.index])) {
                    return;
                }

                $scope.raceBets[$scope.index].showCancelForm = false;

                p = {
                    gaEventCategory: 'Site Click',
                    gaEventAction: 'My Bets Page Click',
                    gaEventLabel: 'Undo Bet Cancel',
                    sectionName: GTMFac.Tvg4SectionName({sectionName: 'Today'}),
                    screenName: GTMFac.Tvg4ScreenName(),
                    eventLabel: "MyAccount-UndoCancel"

                };

                GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
            }

            function countRepeatedBets(cancelledBet, allBets) {
                return allBets
                    .filter(function (bet) {
                        return bet.betStatus.name === 'active' && bet.serialNumber !== cancelledBet.serialNumber;
                    })
                    .reduce(function (acc, raceBet) { 
                        var cancellingBet = cancelledBet;

                        var isCancelingIterationRace = raceBet.isCanceling; 
                        var areRepeated = raceBet.wagerReference === cancellingBet.wagerReference;
                        
                        acc += areRepeated && !isCancelingIterationRace ? 1 : 0;
                        return acc;
                    }, 0);
            }

            function onCancelBetSuccess() {
                var p;
                var repeatBet = countRepeatedBets($scope.raceBets[$scope.index], $scope.raceBets);

                if (!$scope.keepCanceled) {
                    $scope.raceBets.splice($scope.index, 1);
                } else {
                    $scope.raceBets[$scope.index].showCancelForm = false;
                    $scope.raceBets[$scope.index].cancelable = false;
                    $scope.raceBets[$scope.index].refundAmount = $scope.raceBets[$scope.index].betTotal;

                    if ($scope.raceBets[$scope.index].betStatus) {
                        $scope.raceBets[$scope.index].betStatus.code = 'C';
                        $scope.raceBets[$scope.index].betStatus.name = 'Canceled';
                    }
                }


                // Message displayed at the top of the table.
                // Only if we still have visible bets.
                if ($scope.raceBets.length > 0) {
                    $scope.message.iconClass = 'tvg-icon tvg-icon-check text-success';
                    $scope.message.message = SUCCESS_MESSAGE;
                    $scope.message.showMessage = true;
                }
                $rootScope.$emit('betCancelled');
                $scope.$emit('betCancelled');
                $scope.isCanceling = false;
                p = {
                    gaEventCategory: 'Bet',
                    gaEventAction: 'Cancel Bet Success',
                    gaEventLabel: $scope.raceBets[$scope.index].serialNumber,
                    runnerAmmount: $scope.raceBets[$scope.index].wagerAmount,
                    trackName: $scope.raceBets[$scope.index].trackName,
                    raceNumber: $scope.raceBets[$scope.index].raceNumber,
                    betAmount: $scope.raceBets[$scope.index].betTotal,
                    betType: $scope.raceBets[$scope.index].wagerType.name,
                    sport: $scope.raceBets[$scope.index].raceTypeAbbreviation === 'G' ?
                        'Greyhounds Racing' :
                        'Horse Racing',
                    runnerSelectionList: $scope.raceBets[$scope.index].selections.selectionString,
                    tag: undefined,
                    module: 'My Bets My Account',
                    repeatBet: repeatBet
                };

                GTMFac.GTMEvent().send($rootScope, 'bet', p);
            }

            function onCancelBetFail(response) {
                // Message displayed in a new row just before the one that matches the bet that we're supposed to cancel.
                $scope.raceBets[$scope.index].cancelBet.iconClass = 'tvg-icon tvg-icon-danger text-error';
                $scope.raceBets[$scope.index].cancelBet.message = response.errorMessage;
                $scope.raceBets[$scope.index].cancelBet.showMessage = true;

                $scope.raceBets[$scope.index].showCancelForm = false;
                $scope.raceBets[$scope.index].isCanceling = false;
                $scope.isCanceling = false;
                $rootScope.$emit('cancelBetFail');
                $scope.$emit('cancelBetFail');

                var repeatBet = countRepeatedBets($scope.raceBets[$scope.index], $scope.raceBets);

                var event = {
                    event: 'bet',
                    gaEventCategory: 'Bet',
                    gaEventAction: 'Cancel Bet Error',
                    gaEventLabel: response.errorMessage,
                    runnerAmmount: $scope.raceBets[$scope.index].wagerAmount,
                    module: 'My Bets Home',
                    runnerSelectionList: $scope.raceBets[$scope.index].selections.selectionString,
                    tag: undefined,
                    trackName: $scope.raceBets[$scope.index].trackName,
                    raceNumber: $scope.raceBets[$scope.index].raceNumber,
                    betId: undefined,
                    betAmount: $scope.raceBets[$scope.index].betTotal,
                    betType: $scope.raceBets[$scope.index].wagerType.name,
                    sport: $scope.raceBets[$scope.index].raceTypeAbbreviation === "G" ?
                        "Greyhounds Racing" :
                        "Horse Racing",
                    repeatBet: repeatBet
                };

                GTMFac.GTMEvent().send($rootScope, 'betCancelError', event);
            }

            /**
             * @private
             * @description
             * Function called when the user clicks on the 'Yes' button of the inline cancel bet form.
             * Calls the cancel bet service and shows a message after receiving the response.
             *
             * @param {Number} index The index that matches the bet that might be canceled, in order to show the
             form in the correct position.
             */
            function _onYesClick() {
                if (_.isUndefined($scope.raceBets[$scope.index])) {
                    return;
                }

                $scope.raceBets[$scope.index].isCanceling = true;
                $scope.isCanceling = true;
                $rootScope.$emit('cancelingBet');
                $scope.$emit('cancelingBet');
                BetsSvc.cancelBet($scope.raceBets[$scope.index]).then(onCancelBetSuccess, onCancelBetFail);
            }

            $scope.events = {
                onNoClick: _onNoClick,
                onYesClick: _onYesClick
            };
        }
        MyBetsCancelBetCtrl.$inject = [
            '$scope',
            '$rootScope',
            'BetsSvc',
            'GTMFac'
        ];

        return MyBetsCancelBetCtrl;
    }
);

