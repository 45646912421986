"use strict";

define('NextRaceItemDir',[],function () {

    function NextRaceItemDir() {
        return {
            restrict: 'E',
            replace: false,
            transclude: true,
            scope: {
                race: '=raceData',
                index: '=raceIndex'
            },
            controller: 'NextRacesItemCtrl',
            templateUrl: 'js/desktop/modules/Races/components/nextRaceItem/templates/next-races-item.html'
        };
    }

    return NextRaceItemDir;

});

