'use strict';

define(
    'AboutYouCtrl',[
    ],
    function () {

        function AboutYouCtrl($scope, $rootScope, GTMFac) {

            $scope.isEdit = false;
            $scope.editLabel = "Edit";

            $scope.toggleEdit = function() {
                var p,
                    editModeName = $scope.isEdit ? 'Close' : 'Open';

                $scope.isEdit = !$scope.isEdit;
                $scope.editLabel = $scope.isEdit ? "Cancel" : "Edit";
                if(!$scope.isEdit){
                    $scope.$broadcast("about-you::on-form-cancel", $scope.isEdit);
                }

                p = {
                    gaEventCategory: 'Site Click',
                    gaEventAction: 'My Profile Page Click',
                    gaEventLabel: 'User Profile | ' + editModeName + ' Module',
                    sectionName: GTMFac.Tvg4SectionName(),
                    screenName: GTMFac.Tvg4ScreenName(),
                    eventLabel: "MyAccount_UserProfile" + editModeName
                };

                GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
            };

            function setUser(){

                if(!$rootScope.user){
                    return;
                }

                if($scope.userProfile) {
                    return;
                }

                $scope.userProfile = $rootScope.user;
                $scope.hasUsername = $scope.userProfile.userName && $scope.userProfile.userName.length > 0;
                $scope.$emit('pageLoaded', 'my-profile');
            }

            (function init() {
                setUser();
            }());

            var unbindWatch = $rootScope.$watch("user", setUser);
            $scope.$on("$destroy", unbindWatch);
        }

        AboutYouCtrl.$inject = [
            '$scope',
            '$rootScope',
            'GTMFac'
        ];

        return AboutYouCtrl;
    }
);

