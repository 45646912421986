'use strict';

define(
	'VideoRaceCtrl',[
		'RaceInfoFac'
	],

	function() {

		function VideoRaceCtrl($scope, RaceInfoFac, $location, RaceUrlFac) {
			var deferred = null;

			$scope.events = {
				gotoRace: _gotoRace
			};

			$scope.$on("$destroy", function() {
				RaceInfoFac.stopPollingUpcomingRaces(deferred);
			});

            $scope.$on("session", function () {
                deferred = RaceInfoFac.pollUpcomingRaces().then(null, null, _fetchRacesData);
            });

			function _fetchRacesData (data) {
				data = data.filter(function (race) {
					return race.liveStreaming;
				})[0];
				if (data) {
					$scope.data = {
						race: data
					};
				}
			}

			function _gotoRace () {
				if ($scope.data.race) {
					var race = $scope.data.race;
					$location.url(RaceUrlFac.calculateRacePath(race.trackAbbr, race.trackName, race.raceNumber, true));
				}
			}

		}

		VideoRaceCtrl.$inject = [
			'$scope',
			'RaceInfoFac',
			'$location',
            'RaceUrlFac'
		];

		return VideoRaceCtrl;
	}
);


