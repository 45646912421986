'use strict';

define('ManageNextRaces',[],function () {

    function ManageNextRaces () {
        this.nextRacesList = [];
    }

    ManageNextRaces.prototype.setNextRacesList = function (list) {
        this.nextRacesList = list;
    };

    ManageNextRaces.prototype.getNextRacesList = function () {
        return this.nextRacesList;
    };

    ManageNextRaces.prototype.favoritesFilter = function (flag) {
        if(flag){
            this.nextRacesList = _.filter(this.nextRacesList, function (race){
                return race.isFavorite === true;
            });
        }
        return this;
    };

    ManageNextRaces.prototype.filterByBreed = function (thoroughbred, harness){
        if(thoroughbred && harness){
            this.nextRacesList = _.filter(this.nextRacesList, function (race){
                return race.raceTypeId === 2 || race.raceTypeId === 7;
            });
        }
        if(thoroughbred && !harness){
            this.nextRacesList = _.filter(this.nextRacesList, function (race){
                return race.raceTypeId === 7;
            });
        }
        if(!thoroughbred && harness){
            this.nextRacesList = _.filter(this.nextRacesList, function (race){
                return race.raceTypeId === 2;
            });
        }
        return this;
    };

    return ManageNextRaces;
});
