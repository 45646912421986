'use strict';

define('ReverseFilter',[],
    function () {
        function ReverseFilter() {
            return function (values) {
                return values.slice().reverse();
            };
        }

        return ReverseFilter;
    }
);

