'use strict';

define('HtmlCompile',[],function () {

    function HtmlCompile($compile) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                scope.$watch(function () {
                    return scope.$eval(attrs.htmlCompile);
                }, function (value) {
                    element.html(value);
                    $compile(element.contents())(scope);
                });
            }
        };
    }

    HtmlCompile.$inject = [
        '$compile'
    ];

    return HtmlCompile;
});

