'use strict';

define('WagersDir',[],function() {
    return function WagersDir($rootScope) {
        return {
            restrict: 'E',
            templateUrl: $rootScope.activeFeatures.gkeMyBets ? 'js/desktop/modules/MyBets/templates/wagersGke.html' : 'js/desktop/modules/MyBets/templates/wagers.html',
            controller: 'MyBetsCtrl'
        };
    };
});

