'use strict';

define(
    'Preferences',[
        'PreferencesFac'
    ],

    function (PreferencesFac) {

        angular.module('TVG.Preferences', [])
            .factory('PreferencesFac', PreferencesFac);
    }
);

