/* QA-Staging */
// var NL_LOC_RESOURCE = "https://secure.footprint.net/neulion-e4/tvg/player/tvg_qa/";
// var NL_LOC_SERVER = "https://neulionstage.tvg.com/tvg/";
// var NL_LOC_SERVER_SECURE = "https://neulionstage.tvg.com/tvg/";
// var NL_LOC_QOS = "http://nlqosdrecv02.neulion.com/qadrecv/ProxyBean";
// var NL_GAA_PRODUCTION = false;


 // Production
var NL_LOC_RESOURCE = "https://secure.footprint.net/neulion-e4/tvg/player/tvg/";
var NL_LOC_SERVER = "https://neulion.tvg.com/tvg/";
var NL_LOC_SERVER_SECURE = "https://neulion.tvg.com/tvg/";
var NL_LOC_QOS = "http://nlqosdrecv01.neulion.com/msdrecv/ProxyBean";
var NL_GAA_PRODUCTION = true;
var NL_LOC_SERVER_RTSP = "http://tvg-ws1.insinc.com/ibc/b2b/streamauth_mobile";
var newStreamToggle;


function nlInitialize(isTVG, isMainPage, callbackFunction, newStreamToggleParam)
{
    newStreamToggle = typeof newStreamToggleParam !== 'undefined' ? newStreamToggleParam : false;

	nlg_isTVG = isTVG;

	if(!nlg_isTVG)
	{
		NL_LOC_SERVER = NL_LOC_SERVER.replace("tvg.com", "betfair.com");
		NL_LOC_SERVER_SECURE = NL_LOC_SERVER_SECURE.replace("tvg.com", "betfair.com");
	}

	if(NL_GAA_PRODUCTION)
	{
		if(isTVG)
			nlg_gaa = "UA-37367165-2";
		else
			nlg_gaa = "UA-37367165-3";
	}
	else
		nlg_gaa = "UA-37367165-1";

	nlg_isMainPage = (isMainPage==true);
	nlg_callbackFunc = callbackFunction;
	var ua = navigator.userAgent.toLowerCase();
	if(ua.indexOf("ipad")>=0 || ua.indexOf("iphone")>=0  || ua.indexOf("android")>=0)
	{
		nlg_isHTML5 = true;
        nlAuthCheck();
	}
	else
	{
		nlLoadScript(NL_LOC_RESOURCE+"scripts/swfobject.js");
		setTimeout(nlInitFlashCheck, 200);
	}
}

function nlAuthorize(userId, timestamp, token)
{
	nlLoadScript(NL_LOC_SERVER_SECURE+"secure/login?format=json&cb=nlAuthCallback&uid="+encodeURIComponent(userId)+"&ts="+timestamp+"&h="+token);
}

function nlRenderPlayer(containerId, instanceId, video)
{
	var playerid = "nlplayer"+instanceId;
	if(nlg_isHTML5)
	{
		var v = document.createElement("video");
		v.style.width = "100%";
		v.style.height = "100%";
		v.style.backgroundColor = "#000000";
		v.controls = true;
		v.autoplay = true;
		v.id = playerid;
		document.getElementById(containerId).appendChild(v);

		v.addEventListener("loadedmetadata", nlHTML5MetadataListener);
		v.addEventListener("ended", nlHTML5CompleteListener);
		v.addEventListener("timeupdate", nlHTML5ProgressListener);
		v.addEventListener("onerror", nlHTML5ErrorListener);
		v.addEventListener("error", nlHTML5ErrorListener);

		nlg_playerMap[String(instanceId)] = {video:video, player:document.getElementById(playerid)};

		var gaLoaded = (window._gaq!=null);
		if(!gaLoaded)
		{
			window._gaq = [];
			_gaq.push(['_setAccount', nlg_gaa]);
			_gaq.push(['_trackPageview','nlplayerhtml5']);

			(function() {
			var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
			ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
			var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
			})();
		}
		else
			_gaq.push(['_trackPageview','nlplayerhtml5']);

		nlg_callbackFunc("ready", instanceId);
		nlPlayVideo(instanceId, video);
	}
	else
	{
		var html = '<div id="nlplayerplaceholder'+instanceId+'" style="width:100%;height:100%;background-color:#000000;color:#FFFFFF;text-align:center;padding-top:10px"><p>To view this page ensure that Adobe Flash Player version 10.2 or greater is installed.</p><p><a href="http://www.adobe.com/go/getflashplayer" style="color:#FFFFFF">Get Adobe Flash player</a></p></div>';
		document.getElementById(containerId).innerHTML = html;

		nlg_playerMap[String(instanceId)] = {video:video};

		var flashvars = {htmlid:playerid,readyCallback:"nlPlayerReady",disconnectCallback:"nlDisconnect"};
		flashvars.server = NL_LOC_SERVER;
		if(NL_LOC_QOS!=null)
		{
			flashvars.locqos = NL_LOC_QOS;
			flashvars.siteid = "TVG";
			flashvars.prodid = (nlg_isTVG)?"TVG":"BETFAIR";
			flashvars.uid = nlg_uid;
		}
		if(nlg_gaa!=null)
			flashvars.gaa = nlg_gaa;
		if(navigator.userAgent.toLowerCase().indexOf("chrome")!=-1)
			flashvars.disablesv = true;
		var params = {quality:"high",bgcolor:"#000000",allowScriptAccess:"always",allowFullScreen:"true",wmode:"opaque"};
		var attributes = {id:playerid,name:playerid,align:"middle"};
		swfobject.embedSWF(NL_LOC_RESOURCE+"nlplayer.swf", "nlplayerplaceholder"+instanceId, "100%", "100%", "10.2.0",NL_LOC_RESOURCE+"scripts/expressInstall.swf", flashvars, params, attributes);
	}
}

function nlPlayVideo(instanceId, video)
{
    var obj = nlg_playerMap[instanceId];
    var ua = navigator.userAgent.toLowerCase();
	if(obj!=null && video!=null && obj.player!=null)
	{
		if(nlg_isHTML5)
		{
			nlg_lastInstanceId = instanceId;
            if(ua.indexOf("android") !== -1 && !video.isReplay && newStreamToggle){
                getHTML5PublishPointAndroid(video);
            }
            else{
                getHTML5PublishPoint(video);
            }
		}
		else
		{
			if(video.isReplay)
			{
				var v = {id:video.trackId,name:video.trackId,publishPointParams:{track:video.trackId,replay:"true",date:video.date,time:video.time,race:video.raceNumber}};
				if(video.mute)
					v.mute = true;
				if(video.sound)
					v.sound = true;
				obj.player.playProgram(v);
			}
			else
			{
				var v = {id:video.trackId,name:video.trackId,isLive:true,publishPointParams:{track:video.trackId,bitrate:video.bitrate}};
				if(!nlg_isTVG)
					v.publishPointParams.betfair = "true";
				if(video.mute)
					v.mute = true;
				if(video.sound)
					v.sound = true;
				obj.player.playProgram(v);
			}
		}
	}
}

function nlMuteSound(instanceId, muted)
{
	var obj = nlg_playerMap[instanceId];
	if(obj!=null && obj.player!=null)
	{
		if(nlg_isHTML5)
			obj.player.muted = muted;
		else
			obj.player.mute(muted);
	}
}

function nlVolume(volume)
{
	for(instanceId in nlg_playerMap)
	{
		var obj = nlg_playerMap[instanceId];
		if(obj!=null && obj.player!=null)
		{
			if(nlg_isHTML5)
			{
				obj.player.volume = volume;
			}
			else
			{
				obj.player.volume(volume);
			}
		}
	}
}

function nlPauseResume(instanceId, pause)
{
	var obj = nlg_playerMap[instanceId];
	if(obj!=null && obj.player!=null)
	{
		if(nlg_isHTML5)
		{
			if(pause)
				obj.player.pause();
			else
				obj.player.play();
		}
		else
		{
			obj.player.pause(pause);
		}
	}
}

function nlStop(instanceId)
{
	var obj = nlg_playerMap[instanceId];
	if(obj!=null && obj.player!=null)
	{
		try {
			if(nlg_isHTML5)
			{

				obj.player.pause();
			}
			else
			{
				obj.player.stopPlayer();
			}
		}
		catch(e) {}
	}
}

function nlDisconnect(htmlId)
{
	var instanceId = htmlId.substr(8);
	var obj = nlg_playerMap[instanceId];
	if(obj==null)
	{
		obj = new Object();
		nlg_playerMap[instanceId] = obj;
	}
	obj.player = document.getElementById(htmlId);
	nlg_callbackFunc("disconnect", instanceId);
}

var nlg_isTVG = true;
var nlg_gaa = null;
var nlg_isMainPage = false;
var nlg_callbackFunc = null;
var nlg_flashCheckCounter = 0;
var nlg_uid = null;
var nlg_sessionInt = 0;
var nlg_isHTML5 = false;
var nlg_playerMap = new Object();
var nlg_lastInstanceId = null;
var NL_VIDEO_PING_INTERVAL = 60;

function nlLoadScript(src)
{
	var script = document.createElement("script");
	script.type = "text/javascript";
	script.src = src;
	var stag = document.getElementsByTagName("script")[0];
	stag.parentNode.insertBefore(script, stag);
}

function nlInitFlashCheck()
{
	if(window.swfobject==null)
	{
		if((nlg_flashCheckCounter++)<100)
			setTimeout(nlInitFlashCheck, 100);
		else
			setTimeout(nlInitFlashCheck, 5000);
	}
	else
	{
		var pv = swfobject.getFlashPlayerVersion();
		if((pv!=null && pv.major==0))
		{
            nlg_callbackFunc("noflash");
		}
		else
			nlAuthCheck();
	}
}

function nlAuthCheck()
{
	nlLoadScript(NL_LOC_SERVER+"servlets/sessionpoll?uid=true&format=json&cb=nlAuthCheckCallback&t="+(new Date()).getTime());
}

function nlAuthCheckCallback(obj)
{
	if(obj.data!=null)
	{
		if(obj.data.isLoggedIn=="true")
		{
			nlg_uid = obj.data.uid;
			nlg_callbackFunc("authorized");
			nlSessionPollStart();
		}
		else
			nlg_callbackFunc("initialized");
	}
	else
		nlg_callbackFunc("error");
}

function nlAuthCallback(obj)
{
	if(obj.code!=null)
	{
		if(obj.code=="loginsuccess")
		{
			nlg_uid = obj.data.userName;
			nlg_callbackFunc("authorized");
			nlSessionPollStart();
		}
		else
			nlg_callbackFunc("unauthorized");
	}
	else
		nlg_callbackFunc("error");
}

function nlSessionPollStart()
{
	if(nlg_isMainPage)
	{
		clearInterval(nlg_sessionInt);
		nlg_sessionInt = setInterval(nlSessionPoll, 1000 * 60 * 5);
	}
}

function nlSessionPoll()
{
	nlLoadScript(NL_LOC_SERVER+"servlets/sessionpoll?format=json&cb=nlSessionPollCallback&t="+(new Date()).getTime());
}

function nlSessionPollCallback(obj)
{
	if(obj.data!=null)
	{
		if(obj.data.isLoggedIn!="true")
		{
			nlg_uid = null;
			nlg_callbackFunc("expired");
			clearInterval(nlg_sessionInt);
		}
	}
}

function nlPlayerReady(htmlId)
{
	var instanceId = htmlId.substr(8);
	var obj = nlg_playerMap[instanceId];
	if(obj==null)
	{
		obj = new Object();
		nlg_playerMap[instanceId] = obj;
	}
	obj.player = document.getElementById(htmlId);
	nlg_callbackFunc("ready", instanceId);
	nlPlayVideo(instanceId, obj.video);
}

function getHTML5PublishPointAndroid(video){
    var data = new Array();
    data.push("uid=" + nlg_uid);
    data.push("pa=" + video.trackId);

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", "/neulion/rtsp/" + nlg_uid + "/" + video.trackId, true);
    xmlHttp.send(null);

    xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
            if(xmlHttp.responseText.indexOf("rtsp")>=0 ) {
                window.location.href = xmlHttp.responseText;
            }
        }
    };
}

function getHTML5PublishPoint(video)
{
	var data = new Array();
	data.push("type=video");
	data.push("id=0");
	data.push("track="+video.trackId);
	if(!nlg_isTVG)
		data.push("betfair=true");
	if(video.isReplay)
	{
		data.push("replay=true");
		data.push("date="+video.date);
		data.push("time="+video.time);
		data.push("race="+video.raceNumber);
	}
	data.push("format=json");
	data.push("callback=getHTML5PublishPointCallback");
	if(nlg_lastInstanceId!=null)
	{
		var obj = nlg_playerMap[nlg_lastInstanceId];
		if(obj!=null && obj.player!=null)
		{
			if(video.mute)
				obj.player.muted = true;
			if(video.sound)
				obj.player.muted = false;
		}
	}
	nlLoadScript(NL_LOC_SERVER+"servlets/publishpoint?"+data.join("&"));
}
function getHTML5PublishPointCallback(obj)
{
	var path = obj.path;
	if(path!=null && path.length>0)
	{
		if(nlg_lastInstanceId!=null)
		{
			var obj = nlg_playerMap[nlg_lastInstanceId];
			if(obj!=null && obj.player!=null)
				obj.player.src = path;
		}
	}
	else
		nlg_callbackFunc("expired");
}
function nlHTML5MetadataListener(e)
{
	var instanceId = e.target.id.substr(8);
	var obj = nlg_playerMap[instanceId];
	if(obj!=null && obj.video!=null)
	{
		_gaq.push(['_trackEvent',(obj.video.isReplay)?'Video On Demand':'Video Live','Video Start',obj.video.trackId+":"+obj.video.trackId]);
	}
}
function nlHTML5CompleteListener(e)
{
	var instanceId = e.target.id.substr(8);
	var obj = nlg_playerMap[instanceId];
	if(obj!=null && obj.video!=null)
	{
		_gaq.push(['_trackEvent',(obj.video.isReplay)?'Video On Demand':'Video Live','Video Complete',obj.video.trackId+":"+obj.video.trackId]);
	}
}
function nlHTML5ProgressListener(e)
{
	var instanceId = e.target.id.substr(8);
	var obj = nlg_playerMap[instanceId];
	if(obj!=null && obj.video!=null && obj.player!=null)
	{
		var lastPlayheadTime = obj.video.lastPlayheadTime;
		if(lastPlayheadTime==null)
			lastPlayheadTime = 0;

		if(obj.player.currentTime>lastPlayheadTime+NL_VIDEO_PING_INTERVAL)
		{
			obj.video.lastPlayheadTime = obj.player.currentTime;
			_gaq.push(['_trackEvent',(obj.video.isReplay)?'Video On Demand':'Video Live','Video Duration',obj.video.trackId+":"+obj.video.trackId,NL_VIDEO_PING_INTERVAL]);
		}
		else if(obj.player.currentTime<lastPlayheadTime)
			obj.video.lastPlayheadTime = obj.player.currentTime;

		if(obj.video.isReplay)
		{
			if(obj.video.tracked50==null && Math.round(obj.player.currentTime)==Math.round(obj.player.duration/2))
			{
				obj.video.tracked50 = true;
				_gaq.push(['_trackEvent','Video On Demand','Video 50 Percent',obj.video.trackId+":"+obj.video.trackId]);
			}
		}
	}
}
function nlHTML5ErrorListener(e)
{
	try
	{
		if(e.target.error.code==e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED)
			nlg_callbackFunc("unsupporteddevice");
		else
			nlg_callbackFunc("error");
	}
	catch(e)
	{
		nlg_callbackFunc("error");
	}
}
;
define("neulion", ["swfobject"], function(){});

