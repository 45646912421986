'use strict';

define('MyBetsCancelBetDir',[],function() {
    return function MyBetsCancelBetDir() {
        return {
            restrict: 'E',
            scope: {
                raceBets: '=',
                message: '=',
                index: '=',
                keepCanceled: '='
            },
            replace: true,
            templateUrl: 'js/desktop/modules/MyBets/templates/my-bets-cancel-bet.html',
            controller: 'MyBetsCancelBetCtrl'
        };
    };
});

