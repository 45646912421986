"use strict";

define('GraphRaceFac',["RaceStatusUtils", "lodash"], function (RaceStatusUtils, _) {

    function GraphRaceFac(RaceInfoFac, BetsSvc, $filter, CalculatePurseFac) {
        var _currentDate = new Date();

        /**
         * Builds race object from graph data
         * @param race
         * @constructor
         */
        function GraphRace(race) {
            // #maythorbewithyou
            this.claimingPrice = race.claimingPrice && CalculatePurseFac.calculateMinimizedPurse({ purse: race.claimingPrice });
            this.postTime = new Date(race.postTime);
            this.postTimeString = $filter("date")(
                new Date(race.postTime),
                "hh:mm"
            );
            this.postTimeMarker = $filter("date")(new Date(race.postTime), "a");
            this.postTimeDateString = $filter("date")(
                new Date(race.postTime),
                "MMM dd"
            );
            this.postTimeDay = RaceInfoFac.getTimeDayDescription(
                new Date(race.postTime),
                _currentDate
            ).toLocaleUpperCase();
            this.raceDate = new Date(race.postTime);
            this.MTP = RaceInfoFac.getTimeToPost(
                this.postTime,
                _currentDate.getTime()
            );
            this.MTPClass = RaceInfoFac.getMTPClass(this.MTP);
            this.near = this.MTPClass !== null;
            this.promo = _.get(race, "promos[0]");
            this.promos = _.get(race, "promos", []);
            this.surfaceTypeId = race.surface.id;
            this.raceType = race.type;
            this.raceTypeId = race.type.id;
            this.tvgRaceId = race.tvgRaceId;
            // this.surfaceType = RaceInfoFac.getSurfaceType(this.surfaceTypeId, race.surface.name);
            this.surfaceName = race.surface.name;
            this.defaultCondition = race.surface.defaultCondition;
            this.raceClassId = race.raceClass.id;
            this.raceClass = race.raceClass.name;
            this.featured = race.track.featured;
            this.raceStatus = race.status.code;
            this.trackAbbr = race.track.code;
            this.trackName = race.track.name;
            this.dataSource = race.track.trackDataSource;
            this.raceNumber = parseInt(race.number, 10);
            this.runners = race.numRunners;
            this.perfAbbr = race.track.perfAbbr;
            this.country = race.track.location
                ? race.track.location.country
                : "unknown";
            this.status = race.status.code;
            this.breed = race.type.name;
            this.purse = race.purse;
            this.description = race.description;
            this.liveStreaming = race.video ? race.video.liveStreaming : false;
            this.onTvg = race.video ? race.video.onTvg : false;
            this.onTvg2 = race.video ? race.video.onTvg2 : false;
            this.hasReplays =
                RaceStatusUtils.isStatusRaceOfficial(this.status) &&
                race.video &&
                race.video.hasReplay &&
                race.video.replayFileName &&
                race.video.flashAvailable;
            this.showLiveVideo = !RaceStatusUtils.isStatusRaceOfficial(
                this.status
            );
            this.streamCode =
                race.video &&
                    race.video.streams &&
                    race.video.streams.length > 0
                    ? race.video.streams[0]
                    : false;
            this.replayCode =
                race.video &&
                    race.video.replays &&
                    race.video.replays.length > 0
                    ? race.video.replays[0]
                    : false;
            this.flashAvailable = race.video.flashAvailable;
            this.replayFileName =
                race.video && race.video.replayFileName
                    ? race.video.replayFileName
                    : false;
            this.isStreamHighDefinition = race.video && race.video.isStreamHighDefinition
                ? race.video.isStreamHighDefinition
                : false;
            this.fromResults = false;
            this.distance = race.distance;
            if (this.distance) {
                if (!this.distance.match(/[myf]/g)) {
                    this.distance += "m";
                }
            }

            this.results = { payoffs: [], runners: [] };
            this.id = this.trackAbbr + "_" + this.raceNumber;

            // A race is closed for betting when its status is one of those "Race Official", "Stewards Key" or "Manually Closed"
            this.closedForBetting =
                RaceStatusUtils.isStatusStewardsKey(this.status) ||
                RaceStatusUtils.isStatusManuallyClosed(this.status) ||
                RaceStatusUtils.isStatusRaceOfficial(this.status);

            // Transform betting interests
            this.bettingInterests = race.bettingInterests
                ? RaceInfoFac.runnersBuilder(
                    race.bettingInterests,
                    this.raceTypeId,
                    true
                )
                : [];

            // Transform handicapping data
            this.handicapping = race.bettingInterests
                ? RaceInfoFac.handicappingInfoBuilder(
                    _buildHandicappingData(this.id, race.bettingInterests),
                    this
                )
                : null;

            // Transfor wager types data
            this.wagerTypes = BetsSvc.apiResponseWagerTypesTransformer(
                race.wagerTypes,
                true
            );

            // Transform pools data
            this.poolsPerBI =
                race.bettingInterests && race.racePools
                    ? RaceInfoFac.poolsBuilder(
                        race.bettingInterests,
                        this.raceTypeId,
                        race.racePools
                    )
                    : { runners: [] };

            // Transform probables data
            this.probables =
                angular.isArray(race.probables) &&
                    race.probables.length > 0 &&
                    race.racePools
                    ? RaceInfoFac.probablesBuilder(
                        race.probables,
                        this.raceTypeId,
                        race.racePools,
                        [
                            race.bettingInterests,
                            race.nextRace
                                ? race.nextRace.bettingInterests
                                : null
                        ]
                    )
                    : [];

            // Transform will pays data
            this.willPays = race.willPays
                ? RaceInfoFac.willPaysBuilder(
                    race.willPays,
                    race.bettingInterests,
                    this.raceTypeId
                )
                : [];

            // Transform late changes data
            this.changes = RaceInfoFac.changesBuilder(race.lateChanges);
        }

        // Builds handicapping data object to be used by handicappingBuilder
        function _buildHandicappingData(raceId, bettingInterests) {
            var handicapping = {
                freePicks: [],
                handicappingData: [],
                raceId: raceId
            };

            angular.forEach(bettingInterests, function (bi) {
                var runnerData = [];
                var biNumber = bi.biNumber;
                angular.forEach(bi.runners, function (runner) {
                    // Flatten and add to handicapping
                    runnerData.push({
                        RunnerID: runner.runnerId,
                        Scratched: runner.scratched,
                        HorseName: runner.horseName,
                        AvgClassRating:
                            runner.handicapping.speedAndClass.avgClassRating,
                        AvgDistance:
                            runner.handicapping.speedAndClass.avgDistance,
                        AvgSpeed: runner.handicapping.speedAndClass.avgSpeed,
                        HighSpeed: runner.handicapping.speedAndClass.highSpeed,
                        LastClassRating:
                            runner.handicapping.speedAndClass.lastClassRating,
                        DaysOff: runner.handicapping.snapshot.daysOff,
                        PowerRating: runner.handicapping.snapshot.powerRating,
                        Starts: runner.handicapping.snapshot.horseStarts,
                        Wins: runner.handicapping.snapshot.horseWins,
                        AvgPace: {
                            Early: runner.handicapping.averagePace.early,
                            Finish: runner.handicapping.averagePace.finish,
                            Middle: runner.handicapping.averagePace.middle,
                            NumRaces: runner.handicapping.averagePace.numRaces
                        },
                        JockeyTrainer: {
                            JockeyName:
                                runner.handicapping.jockeyTrainer.jockeyName,
                            Places: runner.handicapping.jockeyTrainer.places,
                            Shows: runner.handicapping.jockeyTrainer.shows,
                            Starts: runner.handicapping.jockeyTrainer.starts,
                            TrainerName:
                                runner.handicapping.jockeyTrainer.trainerName,
                            Wins: runner.handicapping.jockeyTrainer.wins
                        }
                    });

                    // Add to free picks
                    if (
                        runner.handicapping.freePick &&
                        runner.handicapping.freePick.number
                    ) {
                        handicapping.freePicks.push({
                            Info: runner.handicapping.freePick.info,
                            Number: runner.handicapping.freePick.number,
                            biNumber: biNumber,
                            runnerId: runner.runnerId
                        });
                    }
                });

                handicapping.handicappingData.push({
                    BettingInterestNumber: bi.biNumber,
                    Runners: runnerData
                });
            });

            return handicapping;
        }

        /**
         * this receives the current race displayed, and the fresh data from the graph poller
         * and assigns the volatile properties to its scope fields.
         *
         * TODO: this is just a temporary fix while the toggle for volatile data from graph is on
         * Once we can remove the toggle, all this raceInfoFac needs to be refactored to grab all the info from graph and inject it to the template in a more effective way
         * @param currentRace
         * @param refreshData
         * @returns {*}
         * @private
         */
        function _refreshCurrentRace(currentRace, refreshData) {
            var updatedRace = angular.copy(currentRace);

            RaceInfoFac.setLowestOdd(refreshData.race.bettingInterests);

            updatedRace.postTime = new Date(refreshData.race.postTime);
            updatedRace.postTimeString = $filter("date")(
                updatedRace.postTime,
                "hh:mm"
            );
            updatedRace.postTimeMarker = $filter("date")(
                updatedRace.postTime,
                "a"
            );
            updatedRace.postTimeDateString = $filter("date")(
                updatedRace.postTime,
                "MMM dd"
            );
            updatedRace.postTimeDay = RaceInfoFac.getTimeDayDescription(
                updatedRace.postTime,
                _currentDate
            ).toLocaleUpperCase();
            updatedRace.raceDate = updatedRace.postTime;

            updatedRace.MTP = parseInt(refreshData.race.mtp, 10);
            updatedRace.MTPClass = RaceInfoFac.getMTPClass(
                refreshData.race.mtp
            );
            updatedRace.status = refreshData.race.status.code;
            updatedRace.near = updatedRace.MTPClass !== null;

            updatedRace.closedForBetting =
                RaceStatusUtils.isStatusStewardsKey(updatedRace.status) ||
                RaceStatusUtils.isStatusManuallyClosed(updatedRace.status) ||
                RaceStatusUtils.isStatusRaceOfficial(updatedRace.status);

            // Transform pools data
            updatedRace.poolsPerBI =
                refreshData.race.bettingInterests && refreshData.race.racePools
                    ? RaceInfoFac.poolsBuilder(
                        refreshData.race.bettingInterests,
                        updatedRace.raceTypeId,
                        refreshData.race.racePools
                    )
                    : { runners: [] };

            // Transform probables data
            updatedRace.probables =
                angular.isArray(refreshData.race.probables) &&
                    refreshData.race.probables.length > 0 &&
                    refreshData.race.racePools
                    ? RaceInfoFac.probablesBuilder(
                        refreshData.race.probables,
                        updatedRace.raceTypeId,
                        refreshData.race.racePools,
                        [
                            refreshData.race.bettingInterests,
                            refreshData.race.nextRace
                                ? refreshData.race.nextRace.bettingInterests
                                : null
                        ]
                    )
                    : [];

            // Betting interests - odds and scratches
            if (
                angular.isArray(updatedRace.bettingInterests) &&
                angular.isArray(refreshData.race.bettingInterests)
            ) {
                var useLowestOddFavorite = RaceInfoFac.isFavoriteBIScratched(updatedRace.bettingInterests);

                updatedRace.bettingInterests.forEach(function (interest) {
                    refreshData.race.bettingInterests.forEach(function (
                        refreshedInterest
                    ) {
                        if (
                            refreshedInterest.biNumber ===
                            parseInt(interest.bettingInterestNumber, 10)
                        ) {
                            // odds
                            if (refreshedInterest.currentOdds) {
                                interest.currentOdds.denominator =
                                    refreshedInterest.currentOdds.denominator;
                                interest.currentOdds.numerator =
                                    refreshedInterest.currentOdds.numerator;
                                interest.currentOddsString =
                                    interest.currentOdds.numerator != null
                                        ? interest.currentOdds.numerator +
                                        (interest.currentOdds.denominator
                                            ? "/" +
                                            interest.currentOdds.denominator
                                            : "")
                                        : "N/A";

                                if (useLowestOddFavorite) {
                                    var _lowestOdd = RaceInfoFac.getLowestOdd();

                                    interest.favorite = _lowestOdd === RaceInfoFac.getDecimalOddsValue(interest.currentOdds) && _lowestOdd !== 99;
                                }
                            }

                            // scratches
                            refreshedInterest.runners.forEach(function (runner) {
                                if (runner.runnerId === interest.horseID) {
                                    // not parse int because the horseId / runnerId can be "1A" in case of having 2 runners on the same BI
                                    interest.scratched = runner.scratched;
                                    if (interest.scratched) {
                                        interest.currentOddsString = "Scr";
                                        interest.currentOdds.Numerator = "Src";
                                    }
                                }
                            });
                        }
                    });
                });
            }

            updatedRace.raceOpenForBetting = RaceStatusUtils.isRaceOpenForBetting(
                updatedRace
            );
            updatedRace.raceOpenForLiveVideo = RaceStatusUtils.isRaceOpenForLiveVideo(
                updatedRace
            );
            updatedRace.raceCancelled = RaceStatusUtils.isStatusManuallyClosed(
                updatedRace.status
            );

            // Results
            if (refreshData.race.results) {
                updatedRace.results = RaceInfoFac.resultsBuilder(
                    refreshData.race.results,
                    updatedRace.raceTypeId
                );

                // Discarding payoffs that are not to be shown in the results payoffs table
                updatedRace.results.payoffs = updatedRace.results.payoffs.filter(
                    function (payoff) {
                        return (
                            payoff.wagerTypeAbbr !== "WN" &&
                            payoff.wagerTypeAbbr !== "PL" &&
                            payoff.wagerTypeAbbr !== "SH"
                        );
                    }
                );
            }

            return updatedRace;
        }

        return {
            buildRace: function (rawData) {
                return new GraphRace(rawData);
            },
            refreshCurrentRace: _refreshCurrentRace
        };
    }

    GraphRaceFac.$inject = ["RaceInfoFac", "BetsSvc", "$filter", "CalculatePurseFac"];

    return GraphRaceFac;
});

