'use strict';

define('PageMetaInfoFac',['lodash', 'PageMetaInfoEntityBuilder'], function(_, PageMetaInfoEntityBuilder) {
    function PageMetaInfoFac($http, $q, $filter, ConfigurationFac) {

        var pageMetaInfo = {
            createBasedOnUrl: function (path) {
                var deferred;

                if (_.isUndefined(path))
                {
                    deferred = $q.defer();
                    deferred.reject(_onGetMetaInfoError());
                    return deferred.promise;
                }

                return $http.get(buildPageMetaInfoUrl(path)).then(_onGetMetaInfoSuccess, _onGetMetaInfoError);
            }
        };

        function buildPageMetaInfoUrl(path) {
            var url = ConfigurationFac.getServiceApiUrl('seo') + "metainfo?basepath=" + path;
            return url;
        }

        function _onGetMetaInfoSuccess(response) {
            if(response && response.hasOwnProperty('data')) {
                return PageMetaInfoEntityBuilder().withTitle(response.data.title)
                    .withMetaTag(response.data.description)
                    .build();
            }
        }

        function _onGetMetaInfoError() {
            var title = $filter('CMSValue')('defaultPageTitle');
            var metaTag = $filter('CMSValue')('defaultPageMetaTag');
            return PageMetaInfoEntityBuilder().withTitle(title)
                                            .withMetaTag(metaTag)
                                            .build();
        }

        return pageMetaInfo;
    }

    PageMetaInfoFac.$inject = [
        '$http',
        '$q',
        '$filter',
        'ConfigurationFac'
    ];

    return PageMetaInfoFac;
});

