'use strict';

define(
    'ComingSoon',[
        'ComingSoonHomepageDir',
        'ComingSoonItemDir',
        'ComingSoonCtrl',
        'ComingSoonSvc'
    ],

    function (ComingSoonHomepageDir, ComingSoonItemDir, ComingSoonCtrl, ComingSoonSvc) {

        angular.module('TVG.ComingSoon', ['ngAnimate', 'ui.bootstrap'])
            .directive('comingSoonHomepage', ComingSoonHomepageDir)
            .directive('comingSoonItem', ComingSoonItemDir)
            .service('ComingSoonSvc', ComingSoonSvc)
            .controller('ComingSoonCtrl', ComingSoonCtrl);
    }
);



