'use strict';

define(
    'ResourceCenterCtrl',[],

    function () {
        function ResourceCenterCtrl($scope) {
            $scope.menu = {
                formsAndGuides: true
            };

            $scope.setActiveMenu = function(name) {
                _.forEach($scope.menu, function(value, key) {
                  $scope.menu[key] = false;
                });

                $scope.menu[name] = true;
            };
        }

        ResourceCenterCtrl.$inject = [
          '$scope'
        ];

        return ResourceCenterCtrl;
    }
);

