'use strict';

define(
    'MyBetsService',[
        'MyBetsSvc'
    ],

    function (MyBetsSvc) {

        angular.module('TVG.MyBetsService', [])
            .factory('MyBetsSvc', MyBetsSvc);
    }
);
