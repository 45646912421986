"use strict";

define('RaceReplaysSearchByFavoriteCtrl',[], function() {
    function RaceReplaysSearchByFavoriteCtrl(
        $scope,
        $rootScope,
        $timeout,
        $filter,
        HandicappingRaceReplaysFac,
        WagerProfileFac,
        ManageFavoritesFac,
        GraphRacesListResults
    ) {
        var vm = this;
        vm.gotoMyfavoritesLabel = $filter("CMSLabels")(
            "goToMyFavorites",
            "handicappingRaceReplaysLabels"
        ) || "Go to My Favorites to manage favorite tracks.";
        vm.gotoMyfavoritesPath = "/my-favorite-tracks";
        vm.model = $scope.model;
        vm.model.searchToShow = "Today's Favorite tracks";

        _getRacesReplays();

        function _reset() {
            vm.model.infoMessage = '';
            vm.model.errorMessage = '';
            vm.model.dataSource = [];
        }

        function _buildRacesArray(racesList) {
            var races = [];

            if (Array.isArray(racesList) && racesList.length > 0) {
                racesList.forEach(function(race) {
                    races.push({
                        distance:
                        race.distanceValue.toFixed(1) +
                        race.distanceCode.toLowerCase(),
                        postTime: race.postTime,
                        raceClass: race.className,
                        raceDate: race.postTime,
                        raceNumber: race.raceNumber,
                        raceTypeId: race.typeId,
                        raceTypeName: race.typeName,
                        trackAbbr: race.trackAbbr,
                        trackName: race.trackName,
                        providerTrack: race.trackAbbr,
                        replayFileName: race.replayFileName
                    });
                });
            }

            return races;
        }

        function _getRaceResultsSuccess(racesList) {
            var races = _buildRacesArray(racesList);

            if (races.length) {
                vm.model.dataSource = $filter("orderBy")(races, [
                    "-postTime"
                ]);
                vm.model.selectedReplay = vm.model.dataSource[0];
                vm.model.raceReplay = HandicappingRaceReplaysFac.buildResultReplay(
                    vm.model.selectedReplay
                );
            } else {
                vm.model.infoMessage = $filter("CMSLabels")(
                    "noFavoriteResults",
                    "handicappingRaceReplaysLabels"
                );
            }
        }

        function _getRaceResultsFail() {
            vm.model.errorMessage = $filter("CMSLabels")(
                "failToRetrieveRaces",
                "handicappingRaceReplaysLabels"
            );
        }

        function _getRacesReplays() {
            ManageFavoritesFac.getFavTrackListAsync().then(function (tracks) {
                _reset();
                vm.model.loading = true;

                if (tracks && tracks.length) {
                    GraphRacesListResults.get(WagerProfileFac.getSessionOrAllTracksProfile(), {
                        trackCode: tracks,
                        status: "RO", //results
                        isGreyhound: false
                    })
                        .then(_getRaceResultsSuccess, _getRaceResultsFail)
                        .then(function () {
                            vm.model.loading = false;
                            $timeout(function () {
                               $rootScope.$apply();
                            });
                        });
                } else {
                    vm.model.infoMessage = $filter("CMSLabels")(
                        "noFavoriteResults",
                        "handicappingRaceReplaysLabels"
                    );
                    vm.model.loading = false;
                }
            });
        }
    }

    RaceReplaysSearchByFavoriteCtrl.$inject = [
        "$scope",
        "$rootScope",
        "$timeout",
        "$filter",
        "HandicappingRaceReplaysFac",
        "WagerProfileFac",
        "ManageFavoritesFac",
        "GraphRacesListResults"
    ];

    return RaceReplaysSearchByFavoriteCtrl;
});

