'use strict';

define('TrackListFromMetadataFac',[],
    function () {
        function TrackListFromMetadataFac(METADATA, ManageFavoritesFac) {


            function _generateMetadataTrackListWithFavoritesOnTop() {
                var listFav = [];
                var listOther = [];
                _.forEach(METADATA.allTracks, function (track, index) {
                    if (ManageFavoritesFac.checkIfTrackIsFavorite(track.Abbreviation)) {
                        listFav.push({
                            id: index + 1,
                            name: track.Name,
                            trackAbbr: track.Abbreviation,
                            isFavorite: true
                        });
                    } else {
                        listOther.push({
                            id: index + 1,
                            name: track.Name,
                            trackAbbr: track.Abbreviation,
                            isFavorite: false
                        });
                    }
                });
                return listFav.concat(listOther);
            }

            function _generateMetadataTrackList() {
                var listTracks = [];
                _.forEach(METADATA.allTracks, function (track, index) {
                    listTracks.push({id: index + 1, name: track.Name, trackAbbr: track.Abbreviation});
                });
                return listTracks;
            }

            return {
                generateMetadataTrackListWithFavoritesOnTop: _generateMetadataTrackListWithFavoritesOnTop,
                generateMetadataTrackList: _generateMetadataTrackList
            };
        }

        TrackListFromMetadataFac.$inject = [
            'METADATA',
            'ManageFavoritesFac'
        ];

        return TrackListFromMetadataFac;
    });

