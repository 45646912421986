"use strict";

define('HandleListNavigation',[],function(){

    function HandleListNavigation(size){
        this.dataSource = [];
        this.size = size;
        this.range = {
            min: 0,
            max: size
        };
        this.step = 1;
        this.currentList = [];
    }
    HandleListNavigation.prototype.setDataSource = function(dataSource){
        this.dataSource = dataSource;
    };
    HandleListNavigation.prototype.buildSubset = function () {
        if(this.dataSource.length === 0 ){
            this.range.min = 0;
            this.range.max = this.size;
            this.currentList = [];
            return;
        }

        if(this.dataSource.length < this.size) {
            this.range.min = 0;
            this.range.max = this.dataSource.length;

        }else if(this.range.max > (this.dataSource.length)){
            this.range.min = this.dataSource.length - this.size;
            this.range.max = this.range.min + this.size;
        }

        this.currentList = _.slice(this.dataSource, this.range.min, this.range.max);
    };
    HandleListNavigation.prototype.goNext = function(){
        this.range.max++;
        if(this.range.max > this.dataSource.length){
            this.range.max = this.dataSource.length;
            return;
        }
        this.range.min++;
        this.buildSubset();
    };
    HandleListNavigation.prototype.goBack = function(){
        this.range.min--;
        if(this.range.min < 0 ){
            this.range.min = 0;
            return;
        }
        this.range.max--;
        this.buildSubset();
    };
    HandleListNavigation.prototype.canGoBack = function(){
        return this.range.min > 0;
    };
    HandleListNavigation.prototype.canGoNext = function(){
        return this.range.max < this.dataSource.length;
    };

    return HandleListNavigation;

});

