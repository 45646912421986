'use strict';

define(
    'TalentPicksBase',[
        'TalentPicksSvc',
        'TalentPicksFactory',
        'GTMMod'
    ],

    function (TalentPicksSvc, TalentPicksFactory) {

        angular.module('TVG.TalentPicksBase', [
            'TVG.Common.GTM'
        ])

        .factory('TalentPicksSvc', TalentPicksSvc)

        .factory('TalentPicksFactory', TalentPicksFactory);
    }
);

