'use strict';

define(
    'Account',[
        'UserProfile',
        'QuickAccessCtrl',
        'AccountCtrl',
        'AccountBettingPrefsCtrl',
        'AccountChangePinCtrl',
        'AccountChangePasswordCtrl',
        'StackViewDir',
        'BetDir',
        'AccountHistoryCtrl',
        'AccountUserProfileCtrl',
        'AccountPanelCtrl',
        'MyFundsCtrl',
        'AboutYouCtrl',
        'AddressDetailsCtrl',
        'ContactDetailsCtrl',
        'ChangePasswordCtrl',
        'EditUsernameCtrl',
        'MyProfileChangePinCtrl',
        'EditEmailCtrl',
        'UserPreferencesCtrl',
        'AddAlertsToMyStableCtrl',
        'MyStableSvc',
        'MyStableCtrl',
        'MyTracksCtrl',
        'NotificationMyStableCtrl',
        'AddToMyStableDir',
        'AccountMarketingCtrl',
        'MyFavoritesCtrl',
        'MyStableFaqDir',
        'MyStableFaqCtrl',
        'MyTracksDir',
        'TodayAlertsDir',
        'TodayAlertsCtrl',
        'RacesTodayFac',
        'HeaderButtonDir',
        'MyStableSettingsDir',
        'MyStableSettingsCtrl',
        'MyStableManageCtrl',
        'MyStableSearchDir',
        'MyStableSearchCtrl',
        'MyStableRaceTodayCtrl',
        'ReportsCtrl',
        'GTMMod'
    ],

    function (UserProfile, QuickAccessCtrl, AccountCtrl, AccountBettingPrefsCtrl, AccountChangePinCtrl, AccountChangePasswordCtrl,
              StackViewDir, BetDir, AccountHistoryCtrl, AccountUserProfileCtrl,
              AccountPanelCtrl, MyFundsCtrl, AboutYouCtrl, AddressDetailsCtrl, ContactDetailsCtrl,
              ChangePasswordCtrl, EditUsernameCtrl, MyProfileChangePinCtrl,
              EditEmailCtrl, UserPreferencesCtrl, AddAlertsToMyStableCtrl, MyStableSvc,
              MyStableCtrl, MyTracksCtrl, NotificationMyStableCtrl, AddToMyStableDir, AccountMarketingCtrl,
              MyFavoritesCtrl, MyStableFaqDir, MyStableFaqCtrl, MyTracksDir, TodayAlertsDir, TodayAlertsCtrl, RacesTodayFac,
              HeaderButtonDir, MyStableSettingsDir, MyStableSettingsCtrl, MyStableManageCtrl, MyStableSearchDir, MyStableSearchCtrl,
              MyStableRaceTodayCtrl, ReportsCtrl) {


        angular.module('TVG.Account', [
            'TVG.Security',
            'TVG.UserProfile',
            'TVG.Common.GTM'
        ])

            .service('MyStableSvc', MyStableSvc)

            .factory('RacesTodayFac', RacesTodayFac)

            .controller("QuickAccessCtrl", QuickAccessCtrl)

            .controller('AccountCtrl', AccountCtrl)

            .controller('ReportsCtrl', ReportsCtrl)

            .controller('AddAlertsToMyStableCtrl', AddAlertsToMyStableCtrl)

            .controller('EditEmailCtrl', EditEmailCtrl)

            .controller('AccountPanelCtrl', AccountPanelCtrl)

            .controller('AccountBettingPrefsCtrl', AccountBettingPrefsCtrl)

            .controller('MyProfileChangePinCtrl', MyProfileChangePinCtrl)

            .controller('AccountChangePinCtrl', AccountChangePinCtrl)

            .controller('AccountHistoryCtrl', AccountHistoryCtrl)

            .controller('AccountUserProfileCtrl', AccountUserProfileCtrl)

            .controller('AccountChangePasswordCtrl', AccountChangePasswordCtrl)

            .controller('MyFundsCtrl', MyFundsCtrl)

            .controller('AboutYouCtrl', AboutYouCtrl)

            .controller('AddressDetailsCtrl', AddressDetailsCtrl)

            .controller('ContactDetailsCtrl', ContactDetailsCtrl)

            .controller('ChangePasswordCtrl', ChangePasswordCtrl)

            .controller('EditUsernameCtrl', EditUsernameCtrl)

            .controller('UserPreferencesCtrl', UserPreferencesCtrl)

            .controller('AccountMarketingCtrl', AccountMarketingCtrl)

            .controller('MyTracksCtrl', MyTracksCtrl)

            .controller('MyStableCtrl', MyStableCtrl)

            .controller('TodayAlertsCtrl', TodayAlertsCtrl)

            .controller("NotificationMyStableCtrl", NotificationMyStableCtrl)

            .controller("MyFavoritesCtrl", MyFavoritesCtrl)

            .controller("MyStableManageCtrl", MyStableManageCtrl)

            .controller("MyStableSettingsCtrl", MyStableSettingsCtrl)

            .controller('MyStableFaqCtrl', MyStableFaqCtrl)

            .controller("MyStableSearchCtrl", MyStableSearchCtrl)

            .controller('MyStableRaceTodayCtrl', MyStableRaceTodayCtrl)

            .directive('stackView', StackViewDir)

            .directive('bet', BetDir)

            .directive('addToMyStable', AddToMyStableDir)

            .directive("quickAccess", function () {
                return {
                    restrict: 'E',
                    scope: false,
                    templateUrl: 'js/desktop/modules/Account/templates/quickAccess.html'
                };
            })

            .directive("quickAccesstvg4", function () {
                return {
                    restrict: 'E',
                    scope: false,
                    templateUrl: 'js/desktop/modules/Account/templates/quickAccess-tvg4.html'
                };
            })

            .directive('accountPanel', function () {
                return {
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/Account/templates/account-panel.html'
                };
            })

            .directive('account', function () {
                return {
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/Account/templates/account.html'
                };
            })

            .directive('myStableManage', function() {
                return {
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/Account/templates/my-stable-manage.html',
                    controller: "MyStableManageCtrl",
                    controllerAs: "myStableManage"
                };
            })
            .directive('myStableSearch', MyStableSearchDir)

            .directive('myStableFaq', MyStableFaqDir)

            .directive('myStableSettings', MyStableSettingsDir)

            .directive('myTracks', MyTracksDir)

            .directive('todayAlerts', TodayAlertsDir)

            .directive('headerButton', HeaderButtonDir)

            .component('myStableRaceToday', {
                controller: 'MyStableRaceTodayCtrl',
                templateUrl: 'js/desktop/modules/Account/templates/my-stable-race-today.html'
            })

            .constant('PreferencesLabelForGTM', {
                5: {gaEventLabel: 'Marketing Emails', eventLabel: "MyAccount_CommunicationsMarketingEmails"},
                6: {gaEventLabel: 'Enewsletter', eventLabel: "MyAccount_CommunicationsEnewsletter"},
                7: {gaEventLabel: 'Special Offers-Announcements', eventLabel: "MyAccount_CommunicationsSpecialOffers"},
                8: {gaEventLabel: 'Alerts', eventLabel: "MyAccount_CommunicationsAlerts"},
                9: {gaEventLabel: 'Partners News-Offers', eventLabel: "MyAccount_CommunicationsPartnersNews"}
            });
    }
);

