'use strict';

define('NewWindowDir',[],function() {

        function NewWindowDir ($rootScope, $window, NewWindowGridFac, GTMFac) {

            return {
                restrict: 'A',
                scope: true,
                link: function (scope, element, attr) {

                    scope.windowRef = {};

                    var windowParams = [];
                    var windowConfig = {
                        toolbar: 0,
                        location: 0,
                        status: 0,
                        menubar: 0,
                        scrollbars: 1,
                        resizable: 0,
                        top: 0,
                        left: 0,
                        width: attr.hasOwnProperty('windowWidth') ? attr.windowWidth : null,
                        height: attr.hasOwnProperty('windowHeight') ? attr.windowHeight : null

                    };

                    var horseClick = attr.hasOwnProperty('horseClick') ?  attr.horseClick : false;

                    var buildWindowParams = function () {

                        if ($rootScope.activeFeatures.openBetWindowsInGrid)
                        {
                            var coordinates = NewWindowGridFac.getOpenCoordinates($window);
                            windowConfig.top = coordinates.top;
                            windowConfig.left = coordinates.left;
                        }


                        angular.forEach(windowConfig, function(value, key) {
                            windowParams.push(key + '=' + value);
                        });
                        return windowParams.join(',');
                    };

                    scope.openWindow = function(r) {
                        //ensure that we have a route
                        var route = r || element.attr('window-route');

                        // ensure that a betslip window dont open tvg4
                        if (route.indexOf('betslip') > -1 && !route.indexOf('betslip/') > -1) {
                            route.replace('betslip', 'betslip/');
                        }

                        if (route) {
                            var newWindow = $window.open(route, '_blank', buildWindowParams());

                            //If the user blocks popups we won't have this object
                            if (newWindow) {
                                newWindow.opener.talentPickHost = element.attr('talent-pick-host');
                                //TODO: in order to remove this crossWIndowMessaging must rely on postMessage instead of rootScope events
                                newWindow.opener.__rootScope = $rootScope;

                                if ($rootScope.activeFeatures.openBetWindowsInGrid) {
                                    NewWindowGridFac.onChildInit($window, newWindow);
                                }

                                if(attr.hasOwnProperty('closeOnEvent')) {
                                    registerWindow(newWindow);
                                }
                            }
                        }
                    };

                    //Removed closed window references
                    $window.addEventListener('message', function(e) {
                        if (e.origin === $window.location.origin) {
                            _.remove(scope.windowRef[attr.closeOnEvent], function (windowInstance) {
                                return (windowInstance.ID === e.data.substring(17, e.data.length) || windowInstance.window === null);
                            });
                        }
                    }, false);

                    element.bind('click', function(){
                        if(!element.attr('window-disable') || element.attr('window-disable') === "false") {

                            //horseClick GTM event
                            if(horseClick) {
                                var eventData = GTMFac.gaEventBuilder()
                                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                                    .withGaEventCategory('Site Click')
                                    .withGaEventLabel('Race Card | Runner Click | ' + horseClick)
                                    .withEventLabel('RaceInfo-RunnerClick')
                                    .build();

                                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
                            }

                            if (element.attr('window-callback')) {
                                try {
                                    scope.$parent.events[element.attr('window-callback')](scope.openWindow.bind(this, element.attr('window-route')));
                                }
                                catch (e) {
                                }
                            } else {
                                scope.openWindow();
                            }
                        }
                    });

                    if(attr.hasOwnProperty('closeOnEvent')) {
                        var unsubscribeCloseOnEvent = $rootScope.$on(attr.closeOnEvent, function () {
                            closeWindows();
                            unsubscribeCloseOnEvent();
                        });
                    }

                    function registerWindow(newWindow) {
                        if (_.isUndefined(scope.windowRef[attr.closeOnEvent])) {
                            scope.windowRef[attr.closeOnEvent] = [];
                        }
                        scope.windowRef[attr.closeOnEvent].push(newWindow);
                    }

                    function closeWindows() {
                        if (!_.isUndefined(scope.windowRef[attr.closeOnEvent])) {
                            scope.windowRef[attr.closeOnEvent].forEach(function (windowInstance) {
                                // Propagate the event through the child window
                                if (windowInstance.angular && windowInstance.angular.element(document.querySelector('body')).scope()) {
                                    var windowScope = windowInstance.angular.element(document.querySelector('body')).scope();
                                    windowScope.$broadcast(attr.closeOnEvent);
                                }
                                // Close the window
                                windowInstance.close();
                            });
                            scope.windowRef = {};
                        }
                    }

                    var goToNewDeposit = $rootScope.$on('goto_new_deposit', function () {
                        if (window.ID) {
                            window.referenceWindow.goToDeposit();
                            window.close();
                            goToNewDeposit();
                        }
                    });
                }
            };
        }

        NewWindowDir.$inject = [
            '$rootScope',
            '$window',
            'NewWindowGridFac',
            'GTMFac'
        ];

        return NewWindowDir;
    });

