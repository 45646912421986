'use strict';

define(
    'RaceReplays',[
        'RaceReplaysGateway',
        'RaceReplayItemCtrl',
        'RaceReplaysInfoHeaderCtrl',
        'GTMMod'
    ],

    function (RaceReplaysGateway, RaceReplayItemCtrl, RaceReplaysInfoHeaderCtrl) {

        angular.module('TVG.RaceReplays', [
            'TVG.Common.GTM'
        ])
            .factory('RaceReplaysGateway', RaceReplaysGateway)

            .controller('RaceReplayItemCtrl', RaceReplayItemCtrl)
            .controller('RaceReplaysInfoHeaderCtrl', RaceReplaysInfoHeaderCtrl)

            .directive('raceReplayItem', function () {
                return {
                    restrict: 'E',
                    scope: {
                        data: '=',
                        replay: '=',
                        hideResults: '='
                    },
                    templateUrl: 'js/desktop/modules/RaceReplays/templates/race-replay-item.html',
                    controller: 'RaceReplayItemCtrl'
                };
            })
            .directive('raceReplayInfo', function () {
                return {
                    restrict: 'E',
                    scope: {
                        raceInfo: "="
                    },
                    templateUrl: 'js/desktop/modules/RaceReplays/templates/raceReplayInfoHeader.html',
                    controller: RaceReplaysInfoHeaderCtrl
                };
            });
    }
);

