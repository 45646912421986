'use strict';

define(
    'VideoCtrl',[
        'lodash',
        'StatePersistenceFac'
    ],
    function (_) {

        function VideoCtrl(
                $scope,
                $http,
                METADATA,
                $rootScope,
                StatePersistenceFac,
                $routeParams,
                $timeout,
                $interval,
                GTMFac,
                RaceListFac,
                VideoProviderSvc,
                $document,
                $window,
                $q,
                VideosLoggingFac
            ) {
            var RCN_PLAYER_PREFIX = 'rcn-player-';
            var dateRegex = /\d{4}\-\d{2}\-\d{2}/;
            var timeRegex = /\d{2}:\d{2}:\d{2}/;

            var previousState = StatePersistenceFac.loadState('tvgvideoctrl');
            var isPlaying = previousState ? previousState.isPlaying : false;
            var bitrate = 'high';
            var resumePlay = false;
            var autoplayPrompt = false;
            var video;
            var liveSchedulePoll = 0;
            var hasLiveStreaming = 1;
            var isGridVideo = $scope.isGridVideo || false;

            if($routeParams && $routeParams.video) {
                $timeout(function () {
                    _playVideo();
                }, 0);
            }
            $rootScope.videoIsPlaying = false;

            if (!isGridVideo){
                $rootScope.$emit('startVideoCtrl', $scope.raceData);
            }

            $scope.$on('video:stop', function($event, data) {
                if (!data || data.instanceId === $scope.data.instanceId && !_.get($scope,'raceData.isLeftBarVideo')) {
                   stopVideo(true);
                }
            });

            $scope.$on('replayVideo:stop', function(){
                if ($scope.raceData.isReplay) {
                    stopVideo(true);
                }
            });

            $scope.$on('leftVideo:stop', function($event, instanceId){
                if (_.get($scope,'raceData.isLeftBarVideo') && $scope.raceData.instanceId === instanceId) {
                    stopVideo(true);
                }
            });

            // We have all information to show a video
            if ($scope.raceData) {
                video = {
                    trackId: $scope.raceData.trackAbbr,
                    trackName: $scope.raceData.trackName,
                    raceNumber: $scope.raceData.raceNumber,
                    racePerf: $scope.raceData.perfAbbr,
                    isReplay: false,
                    date: METADATA.raceDate || moment().format('YYYY-MM-DD'),
                    bitrate: 'high',
                    sound: true, // force the sound to be on by default
                    streamCode: $scope.streamCode,
                    replayCode: false
                };

                // Set stream codes for live TVG1 and TVG2 players
                if(video.trackId === 'TVG1' && !video.streamCode) {
                    video.streamCode = 'tvg_mbr';
                } else if(video.trackId === 'TVG2' && !video.streamCode) {
                    video.streamCode = 'tvg2_mbr';
                }

                $scope.data = {
                    currentRace: $scope.raceData,
                    showInfo: $scope.showInfo,
                    isRendering: false,
                    videoRendered: false,
                    playButton: $scope.raceData.liveStreaming || $scope.raceData.isReplay ? true : false,
                    playerMessage: null,
                    collapsed: false,
                    collapsible: $scope.collapsible,
                    containerId: $scope.raceData.containerId || 'video',
                    instanceId: $scope.raceData.instanceId || 'video-main-programpage'
                };
            } else {
                //We don't have the information needed to start video, so we just show a splash
                //Used on Horse Replays
                video = {};
                $scope.data = {
                    currentRace: $scope.raceData,
                    showInfo: $scope.showInfo,
                    videoRendered: false,
                    playButton: false,
                    playerMessage: null,
                    collapsed: false,
                    collapsible: $scope.collapsible,
                    containerId: 'video',
                    instanceId: 'video-main-programpage'
                };
            }

            /* REGARDING INSTANCE ID OF NEULION
             *  Based on tests, neulion seems to treat 'video' equals to 'video****'.
             *  That way, when we stopped a video with instanceId "video_grid_0001" the neulion script would also stop the main video which instance id was just "video".
             *  By adding a more specific name which isn't a substring of any other video's instanceId we're preventing unexpected behaviours.
             */

            $scope.events = {
                playVideo: _playVideo,
                onLiveStreamChange: _onLiveStreamChange,
                _initVideoHandlers: _initVideoHandlers // just for testing
            };



            $scope.$watch('raceData', function (race) {
                if (_shouldUpdateRaceData(race)) {
                    $scope.data.currentRace = race;
                }

                if ($scope.data.currentRace && $scope.data.currentRace.isReplay) {
                    if (isPlaying === false){
                        $scope.data.playButton = true;
                    }

                    $scope.data.playerMessage = null;
                    $scope.data.isReplay = true;
                    $scope.data.raceDate = $scope.data.currentRace.raceDate;
                    $scope.data.postTime = $scope.data.currentRace.postTime;

                    if ($scope.data.currentRace.instanceId) {
                        $scope.data.instanceId = $scope.data.currentRace.instanceId;
                    }

                    if ($scope.data.currentRace.containerId) {
                        $scope.data.containerId = $scope.data.currentRace.containerId;
                    }

                    if ($scope.data.currentRace.autoPlay === true) {
                        $timeout($scope.events.playVideo, 500);
                    }

                } else {
                    if (race && !$scope.data.videoRendered) {
                        $scope.data.playButton = race.liveStreaming ? true : false;
                        $scope.data.playerMessage = race.liveStreaming ? null : 'No live streaming available.';


                        if (race.liveStreaming && !race.isLeftBarVideo && (window.location.href.indexOf('autoplay') > -1 || race.isPlaying) && !autoplayPrompt) {
                            $timeout(function () {
                                _playVideo();
                            }, 500);
                            autoplayPrompt = true;
                        }
                    } else if (race && (isGridVideo || race.isLeftBarVideo)){
                        $timeout(function () {
                            _playVideo();
                        }, 500);
                    }
                }
            });

            var unbind = [];

            unbind.push($rootScope.$on('race:Changed', function () {

                // live races stream the same video so there's no need to stop them
                // popouts are also excluded, if for replays
                if ($scope.data && $scope.data.videoRendered && $rootScope.videoIsPlaying) {
                    if ($scope.data.currentRace.isReplay) {
                        stopVideo(true);
                    } else if (!$scope.raceData.isPopup) {
                        $interval.cancel(liveSchedulePoll);
                    }
                }
                autoplayPrompt = false;
            }));

            $scope.$on("$destroy", function () {
                if ($scope.data && $scope.data.videoRendered) {
                    stopVideo(true);
                    $interval.cancel(liveSchedulePoll);
                }
                _.forEach(unbind, function (bind) {
                    bind();
                });
                var stateToSave = previousState || {
                        isPlaying: isPlaying
                    };
                StatePersistenceFac.saveState("tvgvideoctrl", stateToSave);
            });

            $scope.$on("user", function () {
                if (resumePlay) {
                    _playVideo();
                }
                resumePlay = false;
            });

            $scope.$on("logout", function () {
                if ($scope.data.videoRendered) {
                    var playerMap = {};
                    if($rootScope.activeFeatures.videoProviderRCN) {
                        playerMap = $rootScope.playerMap;
                        $rootScope.playerMap = {};
                    } else {
                        playerMap = nlg_playerMap;
                        nlg_playerMap = {};
                    }

                    for(var instanceId in playerMap) {
                        stopVideo(true, instanceId);
                    }
                }
            });


            /**
             * Start video playback
             */
            function _playVideo() {
                // stop -> login is required
                if (!$rootScope.userSession) {
                    return _playVideoAfterLogin();
                }

                VideosLoggingFac.logPlaybackLoad(_getVideoInfo());

                // check cenas? :p
                // seriously, I think that if the video is a replay, we already know we can play the video,
                // so "hasLiveStreaming" was already set as true, if not, we're using polling and to check
                // if the video may or not be played...
                //
                if (!_getVideoInfo().isReplay && !$scope.raceData.notLiveScheduled) {
                    liveschedulePollStart();
                } else {
                    hasLiveStreaming = 1;
                }


                // if the video is playable
                if (hasLiveStreaming === 1) {
                    $scope.data.isRendering = true;

                    // live streaming or replay
                    $rootScope.$emit('playingVideo', $scope.raceData);

                    if (_getVideoInfo().isReplay){
                        $scope.data.videoRendered = false;
                    }

                    if (!$scope.data.videoRendered) {
                        _initVideoHandlers();
                    } else {
                        if (!$scope.raceData.isPopup) {
                            $rootScope.videoIsPlaying = true;
                        }

                        if ($rootScope.activeFeatures.videoProviderRCN) {
                            _initVideoHandlers();
                        } else {
                            nlPlayVideo($scope.data.instanceId, _getVideoInfo());
                        }
                    }
                }
            }

            function _playVideoAfterLogin() {
                resumePlay = true;
                $rootScope.$broadcast('callLoginRequiredModal');

                if (GTMFac.isTVG4()) {
                    sendLoginLinkVideoEvent();
                }
            }

            function sendLoginLinkVideoEvent() {
                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Right Rail | Video Player | Login')
                    .withEventLabel('RightRail-LoginLinkVideo')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
            }

            function _shouldUpdateRaceData(race) {
                if (race && race.raceDate) {
                    return true;
                }

                var isSameRace = $scope.data.currentRace && race ? race.trackAbbr === $scope.data.currentRace.trackAbbr &&
                    race.number === $scope.data.currentRace.raceNumber : false;

                return !isSameRace && race && race.isLeftBarVideo;// second verification to not break legacy code
            }

            function _getVideoInfo() {
                var date = getRaceDate();
                var time = getRacePostTime();
                var isReplay = isReplayOn();
                var videoInfFromGraph = $rootScope.activeFeatures.rcnMappingsFromGraph &&
                    $rootScope.activeFeatures.graphqlEnabled &&
                    $rootScope.activeFeatures.graphqlProgramPage;

                return {
                    trackId: $scope.data.currentRace.trackAbbr,
                    trackName: $scope.data.currentRace.trackName,
                    raceNumber: $scope.data.currentRace.raceNumber,
                    racePerf: $scope.data.currentRace.perfAbbr,
                    isReplay: isReplay,
                    date: date,
                    time: time,
                    bitrate: bitrate,
                    sound: true, // force the sound to be on by default
                    streamCode: videoInfFromGraph ? $scope.data.currentRace.streamCode : false,
                    replayCode: videoInfFromGraph ? $scope.data.currentRace.replayCode : false,
                    replayFileName: isReplay && $scope.data.currentRace.replayFileName ? $scope.data.currentRace.replayFileName : false,
                    isHD: $scope.data.currentRace.isStreamHighDefinition,
                    useRCNMappingFromCMS: isReplay && $scope.data.currentRace.replayFileName ? false : !!$scope.data.currentRace.useRCNMappingFromCMS
                };
            }

            function isReplayOn() {
                return $scope.data.isReplay || false;
            }

            function getRaceDate() {
                var raceDate = METADATA.raceDate || moment().format('YYYY-MM-DD');
                var matches;
                // TODO remove if when this pass qa
                if ($rootScope.activeFeatures.videoTimeZoneCorrect) {
                    if (isReplayOn() && $scope.data.postTime) {
                        matches = $scope.data.postTime.match(dateRegex);
                        if (!_.isNull(matches) && matches.length === 1) {
                            raceDate = matches[0];
                        }
                    }
                } else {
                    if (isReplayOn() && $scope.data.raceDate) {
                        matches = $scope.data.raceDate.match(dateRegex);
                        if (!_.isNull(matches) && matches.length === 1) {
                            raceDate = matches[0];
                        }
                    }
                }
                return raceDate;
            }

            function getRacePostTime() {
                var time;
                if (isReplayOn() && _.isString($scope.data.postTime)) {
                    var matches = $scope.data.postTime.match(timeRegex);
                    if (!_.isNull(matches) && matches.length === 1) {
                        time = matches[0];
                    }
                }
                return time;
            }

            /**
             * Initialize RCN video player
             * @return {Promise}
             */
            function _initVideoHandlersForRCN() {
                var videoWidth = isGridVideo ? 121 : 242;
                var videoHeight = isGridVideo ? 100 : 196;
                var containerId = '#' + $scope.data.containerId;
                var playerId = RCN_PLAYER_PREFIX + $scope.data.instanceId;


                $scope.data.playerMessage = null;
                video = _getVideoInfo(); // should be local?...

                // flash player needs this <div> to exist...
                $document.find(containerId).html('<div id="' + playerId + '">');
                _enablePlayButton(false);


                return VideoProviderSvc.buildPlayer(
                        playerId,
                        videoWidth,
                        videoHeight,
                        video
                    )
                    .then(function(player) {
                        $rootScope.playerMap[playerId] = { video: player };
                        _enablePlayButton(false);

                        // While Flash player is inserted in DOM by VideoProviderSvc.js,
                        // the HTML5 player is only created and returned until this point
                        // so we must append it here.
                        // Because of this, "player" may be a string (player ID) or a video element
                        // (a HTML5 <video>).
                        // This must be changed, but we'll have to leave it here a little longer.
                        if (angular.isObject(player) && (player.tagName === 'VIDEO' || player.tagName === 'IFRAME')) {
                            $document.find(containerId).html(player);
                        }

                        isPlaying = true;

                        if (!$scope.raceData.isPopup) {
                            $rootScope.videoIsPlaying = true;
                        }

                        if (!$scope.data.videoRendered) {
                            $scope.$emit('videoRendered');
                            $scope.data.videoRendered = true;
                        }

                        VideosLoggingFac.logPlaybackSuccess(video);
                    })
                    .catch(function (status) {
                        var errorStatus = status;
                        var errorMessage = '';
                        var enableButton = false;


                        switch (status) {
                            case 'nolivestream':
                                errorMessage = 'Stream unavailable for the current race';
                                break;
                            case 'noreplay':
                                errorMessage = 'No video replay available for this race.';
                                break;
                            case 'invalidhash':
                                errorMessage = 'An error has occurred, please try again later.';
                                enableButton = true;
                                break;
                            case 'notauthorized':
                                errorMessage = 'This video is unavailable at the moment.';
                                break;
                            case 'expired':
                                errorMessage = 'This stream has expired, please try again.';
                                enableButton = true;
                                break;
                            case 'noflash':
                                errorMessage = 'Please update your Flash player <a href="https://get.adobe.com/flashplayer" target="_blank">here</a>';
                                break;
                            default:
                                errorMessage = 'An error has occurred, please try again later.';
                                enableButton = true;
                        }

                        $scope.data.playerMessage = errorMessage;
                        _enablePlayButton(enableButton);

                        VideosLoggingFac.logPlaybackError(video, {
                            error: errorStatus,
                            errorMessage: errorMessage
                        });
                    });
            }

            /**
             * Initialize Neulion video player
             * @return {Promise}
             */
            function _initVideoHandlersForNeulion() {
                var isTVG = true;
                var isMainPage = true;
                var deferred = $q.defer();


                video = _getVideoInfo(); // should be local...
                $scope.data.playerMessage = null;

                // nlInitialize is globally set by the neulion script
                nlInitialize(isTVG, isMainPage, function (status) {
                    var errorStatus = '';
                    var errorMessage = '';

                    switch (status) {
                        case 'unsupporteddevice':
                            _enablePlayButton(false);
                            errorStatus = status;
                            errorMessage = 'Unsupported device.';
                            break;

                        case 'noflash':
                            errorMessage = 'Please update your Flash player <a href="https://get.adobe.com/flashplayer" target="_blank">here</a>';
                            errorStatus = status;
                            _enablePlayButton(false);
                            break;

                        case 'error':
                            _enablePlayButton(true);
                            errorStatus = status;
                            errorMessage = 'An error occur, please try again later.';
                            break;

                        // security was invalid or userId/tiomstampt/token was missing
                        case 'unauthorized':
                            _enablePlayButton(true);
                            errorStatus = status;
                            errorMessage = 'You are not able to play this video.';
                            break;

                        case 'initialized':
                        case 'expired':
                            _enablePlayButton(true);
                            // Continue with authorization
                            $http.get('/ajax/video/id/token').then(function (response) {
                                if (response.data !== '') {
                                    nlAuthorize(response.data.userId, response.data.timestamp, response.data.token);
                                }
                            });
                            break;

                        case 'authorized':
                            var ua = navigator.userAgent.toLowerCase();

                            if (ua.indexOf('android') == -1 || _getVideoInfo().isReplay || !$rootScope.activeFeatures.newStreamToggle) {
                                _enablePlayButton(false);
                            }

                            isPlaying = true;

                            if (!$scope.raceData.isPopup) {
                                $rootScope.videoIsPlaying = true;
                            }

                            if (!$scope.data.videoRendered) {
                                nlRenderPlayer($scope.data.containerId, $scope.data.instanceId, video);
                                $scope.$emit('videoRendered');
                                $scope.data.videoRendered = true;
                            }

                            VideosLoggingFac.logPlaybackSuccess(video);
                            break;

                        case 'disconnect':
                            _enablePlayButton(true);
                            break;
                    }

                    if (errorStatus) {
                        $scope.data.playerMessage = errorMessage;
                        VideosLoggingFac.logPlaybackError(video, {
                            error: errorStatus,
                            errorMessage: errorMessage
                        });

                        deferred.reject(errorStatus);
                    } else {
                        deferred.resolve({});
                    }

                }, $rootScope.activeFeatures.newStreamToggle);

                return deferred.promise;
            }

            /**
             * Initialize video player (RCN or Neulion)
             * @return {Promise}
             */
            function _initVideoHandlers() {

                return ($rootScope.activeFeatures.videoProviderRCN) ?
                    _initVideoHandlersForRCN() :
                    _initVideoHandlersForNeulion();
            }

            function _enablePlayButton(enabled) {
                $scope.data.playButton = enabled;
            }

            function liveschedulePollStart() {
                liveScheduleUpdate();

                if (hasLiveStreaming == 1) {
                    liveSchedulePoll = $interval(liveScheduleUpdate, 5000);
                }
            }

            function liveScheduleUpdate(){
                // RCN doesn't use the liveVideoSchedule which is dependent on Neulion
                if($rootScope.activeFeatures.videoProviderRCN) {
                    hasLiveStreaming = 1;
                } else {
                    var currentTime = new Date().getTime();
                    var liveVideoSchedule = METADATA.liveVideoSchedule;
                    var raceLiveVideoSchedule = liveVideoSchedule[$scope.raceData.trackAbbr];

                    var startStreamTime = _getTimestamp(raceLiveVideoSchedule.StartDateTime);
                    var endStreamTime = _getTimestamp(raceLiveVideoSchedule.EndDateTime);
                    var errorStatus = '';
                    var errorMessage = '';

                    // Stream hasn't started yet
                    if (currentTime < startStreamTime) {
                        hasLiveStreaming = 0;
                        stopVideo(true);
                        errorStatus = 'live_streaming_unavailable';
                        errorMessage = 'Live Streaming not available at this moment. Please check again later.';
                        $scope.data.playerMessage = errorMessage;
                        $interval.cancel(liveSchedulePoll);

                        // Stream is live
                    } else if (currentTime > startStreamTime && currentTime < endStreamTime) {
                        hasLiveStreaming = 1;

                        // Stream has ended
                    } else if (currentTime > startStreamTime && currentTime > endStreamTime) {
                        if ($scope.data && $scope.data.videoRendered && $rootScope.videoIsPlaying && !$scope.data.currentRace.isReplay) {
                            stopVideo(true);
                            errorStatus = 'live_streaming_ended';
                            errorMessage = 'The Live Streaming for this track has ended.';
                            $scope.data.playerMessage = errorMessage;
                            $interval.cancel(liveSchedulePoll);
                        }
                    }

                    if (errorStatus) {
                        VideosLoggingFac.logPlaybackError(_getVideoInfo(), {
                            error: errorStatus,
                            errorMessage: errorMessage
                        });
                    }
                }
            }

            function _getTimestamp(input) {
                if (!input) {
                    return 0;
                }

                var numbers = input.match(/[0-9]+/g);
                if (!numbers) {
                    numbers = [0];
                }
                return parseInt(numbers[0], 10);
            }

            function stopVideo(enablePlayButton, instanceId){
                instanceId = typeof instanceId !== 'undefined' ? instanceId : $scope.data.instanceId;

                if ($rootScope.activeFeatures.videoProviderRCN) {
                    if (instanceId.indexOf(RCN_PLAYER_PREFIX) === -1) {
                        instanceId = RCN_PLAYER_PREFIX + instanceId;
                    }
                    $document.find('#' + instanceId).remove();
                } else {
                    nlStop(instanceId);
                }

                _enablePlayButton(enablePlayButton);
                $rootScope.videoIsPlaying = false;
                $scope.data.videoRendered = false;
                isPlaying = false;
            }

            function _onLiveStreamChange(trackAbbr){
                //get the race data with the trackAbbr
                var raceData = null;
                var upcomingRaces = RaceListFac.getUpcomingRaces($scope.$id);

                if(upcomingRaces != null && upcomingRaces.length > 0) {
                    for (var i = 0; i < upcomingRaces.length; i++) {
                        if (upcomingRaces[i].trackAbbr == trackAbbr) {
                            raceData = upcomingRaces[i];
                            break;
                        }
                    }

                    if (raceData != null) {
                        $scope.raceData = raceData;

                        if (!$scope.data.videoRendered || $rootScope.activeFeatures.videoProviderRCN) {
                            _initVideoHandlers();
                        }
                        else {
                            nlPlayVideo($scope.data.instanceId, _getVideoInfo());
                        }
                    }

                    RaceListFac.unSubscribe($scope.$id);
                }
            }
        }

        VideoCtrl.$inject = [
            '$scope',
            '$http',
            'METADATA',
            '$rootScope',
            'StatePersistenceFac',
            '$routeParams',
            '$timeout',
            '$interval',
            'GTMFac',
            'RaceListFac',
            'VideoProviderSvc',
            '$document',
            '$window',
            '$q',
            'VideosLoggingFac'
        ];

        return VideoCtrl;
    }
);

