'use strict';

define('RacesTodayFac',[],
    function () {
        function RacesTodayFac($http, $q, poller, RaceInfoFac) {
            var racesTodayPoller = null;

            function _pollRacesToday() {
                if (_.isNull(racesTodayPoller)) {
                    racesTodayPoller = poller.get(this, {
                        action: 'getRacesToday',
                        delay: 60000,
                        argumentsArray: [
                            {}
                        ]
                    });
                }

                return racesTodayPoller;
            }

            function _stopPollingRacesToday(deferred) {
                if (racesTodayPoller) {
                    racesTodayPoller.removeDeferred(deferred);
                    racesTodayPoller = null;
                }
            }

            function _getPerformanceAbbreviations() {
                var deferred = $q.defer();

                function _extractPerformanceAbbreviation(obj, item) {
                    if (_.isUndefined(obj[item.trackAbbr])) {
                        obj[item.trackAbbr] = {};
                    }
                    obj[item.trackAbbr][item.raceNumber] = item.perfAbbr;
                    return obj;
                }

                // Both services never return an error, so there is no need to handle errors here.
                $q.all([RaceInfoFac.getUpcomingRaces(), RaceInfoFac.getRaceResultsList()]).then(
                    function (responses) {
                        var upcomingRacesPerfAbbrs = _.reduce(responses[0], function (obj, item) {
                            return _extractPerformanceAbbreviation(obj, item);
                        }, {});

                        var raceResultsPerfAbbrs = _.reduce(responses[1], function (obj, item) {
                            return _extractPerformanceAbbreviation(obj, item);
                        }, {});

                        var perfAbbrs = _.merge({}, upcomingRacesPerfAbbrs, raceResultsPerfAbbrs);

                        deferred.resolve(perfAbbrs);
                    }
                );

                return deferred.promise;
            }

            function _getRacesToday() {
                var deferred = $q.defer();

                var url = 'ajax/stable/id/load/entity/today';

                $q.all([$http.get(url), _getPerformanceAbbreviations()]).then(
                    function (responses) {
                        if (responses && responses.length == 2 && responses[0].data && responses[1] && _.size(responses[1]) > 0) {
                            var racesTodayResponse = responses[0].data;
                            var performanceAbbreviations = responses[1];

                            _.forEach(racesTodayResponse.races, function (race) {
                                var performanceAbbreviation = performanceAbbreviations[race.TrackAbbreviation];

                                if (performanceAbbreviation) {
                                    race.PerfAbbr = performanceAbbreviation[race.Race];
                                }
                            });

                            deferred.resolve(racesTodayResponse);
                        } else {
                            deferred.reject(Error('Invalid response from the server.'));
                        }
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );

                return deferred.promise;
            }

            return {
                pollRacesToday: _pollRacesToday,
                stopPollingRacesToday: _stopPollingRacesToday,
                getRacesToday: _getRacesToday
            };
        }

        RacesTodayFac.$inject = [
            '$http',
            '$q',
            'poller',
            'RaceInfoFac'
        ];

        return RacesTodayFac;
    });

