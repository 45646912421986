'use strict';

define('FeaturedTracksFac',[
    'lodash',
    'FeaturedTrackModel',
    'WindowUtils',
    'MtpDisplayModel'
], function (_, FeaturedTrackModel, WindowUtils, MtpDisplay) {

    function FeaturedTracksFac($filter) {

        function _isFeaturedRace(race) {
            return race.featured === true;
        }

        function _getImageFromCMS(trackAbbr) {
            if($filter('CMSValue')('FeaturedTracksImages')){
                var featuredTracksImages = JSON.parse($filter('CMSValue')('FeaturedTracksImages'));
                if (featuredTracksImages && featuredTracksImages[trackAbbr]) {
                    return featuredTracksImages[trackAbbr];
                }
            }
        }

        function _getImage(trackAbbr) {
            var cmsImage = _getImageFromCMS(trackAbbr);
            return cmsImage || false;
        }

        function _aggregateRace(race, orderedFeaturedTracks) {
            var existingTrackIndex = _.findIndex(orderedFeaturedTracks, function (track) {
                return track.trackAbbr === race.trackAbbr;
            });

            if (existingTrackIndex != -1) {
                orderedFeaturedTracks[existingTrackIndex].races.push(new FeaturedTrackModel(race.trackName, race.trackAbbr, race.perfAbbr, race.MTP, race.raceNumber, race.raceStatus, race.postTime, new MtpDisplay(race.postTime), _getImage(race.trackAbbr)));
            } else {
                orderedFeaturedTracks.push({
                    trackAbbr: race.trackAbbr,
                    races: [new FeaturedTrackModel(race.trackName, race.trackAbbr, race.perfAbbr, race.MTP, race.raceNumber, race.raceStatus, race.postTime, new MtpDisplay(race.postTime), _getImage(race.trackAbbr))]
                });
            }
        }

        function _getFeaturedTracks(data) {
            var result = [];

            _.forEach(data, function (race) {
                if (_isFeaturedRace(race)) {
                    _aggregateRace(race, result);
                }
            });

            return result;
        }

        return {
            getFeaturedTracks: _getFeaturedTracks
        };
    }

    FeaturedTracksFac.$inject = [
        '$filter'
    ];

    return FeaturedTracksFac;

});

