"use strict";

define('NextRaceItemModel',[],function () {

    function NextRaceItemModel(trackAbbr, trackName, perfAbbr, raceNumber, mtp, postTime, isFavorite, raceTypeId){
        this.trackAbbr = trackAbbr;
        this.trackName = trackName;
        this.perfAbbr = perfAbbr;
        this.raceNumber = raceNumber;
        this.mtp = mtp;
        this.postTime = postTime;
        this.isFavorite = isFavorite;
        this.raceTypeId = raceTypeId;
    }

    return NextRaceItemModel;

});

