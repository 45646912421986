'use strict';

define('FullScheduleReloadedUpdateFavoriteFeedbackAction',[], function () {
    UpdateFavoriteActionFeedbackDir.$inject = [];

    function UpdateFavoriteActionFeedbackDir() {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                scope.trackAbbr = scope.raceInfo && scope.raceInfo.trackAbbr ? scope.raceInfo.trackAbbr : attrs.trackAbbr;

                scope.$on('FullScheduleReloaded::UpdateFavoriteStart', function (event, params) {
                    scope.showActionFeedback = scope.trackAbbr === params;
                });

                scope.$on('FullScheduleReloaded::UpdateFavoriteEnd', function (event, params) {
                    if (scope.trackAbbr == params) {
                        scope.showActionFeedback = false;
                    }
                });
            }
        };
    }

    return UpdateFavoriteActionFeedbackDir;
});

