'use strict';

define('SelectionLegDir',[],
    function() {

        function selectionLeg(stepSelectionsStringFilter) {

            function _linkFn(scope) {
                scope.selection = stepSelectionsStringFilter(scope.selectionLeg.split(','));
            }

            return {
                restrict: 'EA',
                require: '^runnersSelection',
                scope: {
                    raceNumber: '=',
                    selectionLeg: '=',
                    index: '='
                },
                templateUrl: 'js/desktop/modules/RaceTrackCurrentBets/templates/selection-leg-template.html',
                link: _linkFn
            };
        }

        selectionLeg.$inject = [
            'stepSelectionsStringFilter'
        ];

        return selectionLeg;
    });

