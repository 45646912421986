'use strict';

define('FeaturesFac',[
    'lodash',
    'muton'
],
    function (_) {
        function FeaturesFac($location, FEATURES, LocalStorageFac, md5) {
            var _overridesEnabled = true;
            var StorageDelegate = {

                /**
                 * Get features configurations from local storage
                 * (configurations are the md5 hashes for each key)
                 *
                 * @return {Object}  Object with feature configurations hashes
                 */
                getFeaturesConfig: function () {
                    var stringified = '';
                    var featuresConfig = null;

                    try {
                        stringified = LocalStorageFac.getEncodedItem('featuresConfig');
                        featuresConfig = JSON.parse(stringified || 'null');
                    } catch (e) {
                        // ignore
                    }

                    return featuresConfig;
                },

                /**
                 * Save features configurations in local storage
                 * (configurations are the md5 hashes for each key)
                 *
                 * @param {Object} featuresConfig  Object with feature configurations hashes
                 */
                setFeaturesConfig: function (featuresConfig) {

                    var stringified = JSON.stringify(featuresConfig || null);

                    LocalStorageFac.setEncodedItem('featuresConfig', stringified);
                },

                /**
                 * Get saved features from local storage
                 * @return {Object} Features from storage
                 */
                getFeatures: function () {
                    var value = LocalStorageFac.getItem('features');

                    var features = angular.isObject(value) ? value : _toObject(value);

                    return features;
                },

                /**
                 * Convert into string and save features in local storage
                 * @param {Object} features  Hash of features to save
                 */
                setFeatures: function (features) {
                    var stringified = _toString(features);

                    LocalStorageFac.setItem('features', stringified);
                }
            };

            /**
             * Get features list from query parameter "featureOverrides",
             * and parse it into a features overrides object
             *
             * @return {Object} Features overrides
             */
            function _getUrlOverrides() {
                var featureOverrides = $location.search().featureOverrides || '';
                return _toObject(featureOverrides);
            }

            /**
             * Convert a stringified list of features into an object (separated by commas)
             *
             * @example
             * _toObject('a:true,b:false') => { a:true, b:false }
             *
             * @param  {String} stringified  Stringified features, separated by comma
             * @return {Object}              Features hash
             */
            function _toObject(stringified) {
                var pairs = (stringified || '').split(',');

                return pairs.reduce(function (acc, pair) {
                    var feature = pair.split(':');
                    var key = feature[0];
                    var value = (feature[1] === 'true');

                    if (key) {
                        acc[key] = value;
                    }

                    return acc;
                }, {});
            }

            /**
             * Convert an hash of features into a stringified list (separated by commas)
             *
             * @example
             * _toObject({ a:true, b:false }) => 'a:true,b:false'
             *
             * @param  {Object} features  Hash of features
             * @return {String}           Stringified features, separated by comma
             */
            function _toString(features) {
                return Object.keys(features || {}).map(function (key) {
                    return key + ':' + features[key];
                }).join(',');
            }

            /**
             * Encode a single feature configuration into a standarized md5 hash
             *
             * Each feature configuration may look something like these:
             * {"toggle": false }
             * {"toggle": true, "buckets": ["active", "active"] }
             * {"throttle": "20%"}
             * transfersToggle (feature) { return _.omit(feature, propertyIsDefined); } // @TODO why?
             *
             * @example
             * encodeFeatureConfig({ toggle: true, buckets: ['active', 'inactive'] })
             *   => dc3e537a6ac62a7838c4a207eba4a832
             *
             * @param  {Object/Function}  feature             Feature to encode
             * @param  {Boolean}          [feature.toggle]    Whether is toggled on
             * @param  {Array}            [feature.buckets]   Feature buckets
             * @param  {String}           [feature.throttle]  Percentage of users that should use the feature
             * @return {String}                               Encoded feature configuration
             */
            function _encodeFeatureConfig(feature) {
                var encoded = '';

                if (feature) {
                    encoded = (typeof feature === 'function') ?
                        feature.toString() : JSON.stringify(feature);
                    encoded = md5.createHash(encoded);
                }

                return encoded;
            }

            /**
             * Encode hash of feature configurations
             *
             * @example
             * encodeFeatureConfig({
             *     showLeftBar   : { toggle: true, buckets: ['active', 'inactive'] },
             *     showNewHeader : {"toggle": false },
             *     showNewFooter : {"throttle": "20%"}
             * })
             *   => {
             *     showLeftBar   : 40dec5a9bfaa7f5003f97c381cd35e0f,
             *     showNewHeader : 0b82ea90e3bb92e69689a6eb4960524b,
             *     showNewFooter : 76c580f656bef2084df0e762e315f726
             *   }
             *
             * @param  {Object} features   Features configurations hash
             * @return {Object}            Encoded features configurations hash
             */
            function _encodeFeaturesConfig(features) {
                return Object.keys(features || {}).reduce(function (acc, key) {
                    acc[key] = _encodeFeatureConfig(features[key]);
                    return acc;
                }, {});
            }

            /**
             * Compare the previously saved features configurations with the updated list
             * and identify changed features keys.
             *
             * @param {Object} newEncodedConfigs  New features configurations
             * @param {Object} oldEncodedConfigs  Old features configurations
             * @return {Object}                   Features that already have a cached value
             */
            function _findCachedFeatures(newEncodedConfigs, oldEncodedConfigs) {
                var newConfigs = newEncodedConfigs || {};
                var oldConfigs = oldEncodedConfigs || {};
                var cached = {};
                var ignoreCache = !!$location.search().noCacheFeatures;


                if (ignoreCache) {
                    return {};
                }

                Object.keys(newConfigs).filter(function (key) {
                    if (newConfigs[key] === oldConfigs[key]) {
                        cached[key] = true;
                    }
                });

                return cached;
            }

            /**
             * Get the currently set features, calculating any added/updated feature values.
             *
             * @param  {Object} userProperties  User's properties
             * @return {Object}                 Updated feature toggles hash
             */
            function _getFeatures(userProperties) {

                var pristineFeatures = _.cloneDeep(FEATURES) || {};
                var enableUrlOverrides = false;
                var urlFeatures = _getUrlOverrides() || {};
                var updatedFeatures = {};


                // parse new CMS features content and configuration
                updatedFeatures = _compileUpdatedFeatures(userProperties, pristineFeatures);
                enableUrlOverrides = !!_.get(updatedFeatures.features, 'featureOverridesEnabled');

                // don't allow to change features overrides
                delete updatedFeatures.features['featureOverridesEnabled'];

                // save the updated CMS configurations in localStorage, so the can be reused
                // in a future session
                StorageDelegate.setFeatures(updatedFeatures.features);
                StorageDelegate.setFeaturesConfig(updatedFeatures.featuresConfigs);

                // override features passed by URL parameters (for this session only)
                _areOverridesEnabled(enableUrlOverrides);

                if (enableUrlOverrides) {
                    Object.keys(urlFeatures).forEach(function (key) {
                        if (urlFeatures[key] !== undefined) {
                            updatedFeatures.features[key] = urlFeatures[key];
                        }
                    });
                }

                return updatedFeatures.features;
            }

            /**
             * Get feature toggles, respecting URL overrides, cache validation for previously saved
             * features, buckets and throttling values.
             *
             * @param  {Object} userProperties  User properties
             * @param  {Object} rawFeatures     Raw features (as in metadata FEATURES)
             * @return {Object}                 Updated features content and configurations
             */
            function _compileUpdatedFeatures(userProperties, rawFeatures) {

                var pristineFeatures = rawFeatures || {};
                var oldFeatures = StorageDelegate.getFeatures() || {};
                var oldFeaturesKeys = Object.keys(oldFeatures);
                var oldFeaturesConfigs = StorageDelegate.getFeaturesConfig() || {};
                var cached = {};
                var features = {};
                var newFeatures = null;
                var newFeaturesConfigs = null;


                // 1) parse new CMS features content and configuration
                newFeatures = window.muton.getFeatureMutations(userProperties, pristineFeatures);
                newFeaturesConfigs = _encodeFeaturesConfig(pristineFeatures);

                // 2) compare previously saved CMS keys with the updated list
                cached = _findCachedFeatures(newFeaturesConfigs, oldFeaturesConfigs);

                // 3) compile the updated features by checking if any of the new features
                // had already a valid value from a previous session
                features = Object.keys(newFeatures).reduce(function (acc, key) {

                    var isBucketKey = _isBucketKey(key);
                    var parentKey = _getParentKey(key);
                    var cachedBucketKey = '';


                    // check  select the correct key when using buckets
                    if (isBucketKey && cached[parentKey]) {
                        cachedBucketKey = _findOtherBucketKey(key, oldFeaturesKeys);

                        if (cachedBucketKey && oldFeatures[cachedBucketKey] !== undefined) {
                            acc[cachedBucketKey] = oldFeatures[cachedBucketKey];
                            return acc;
                        }
                    }

                    // is a cached toggle/throttle or a new one
                    if (cached[key] && oldFeatures[key] !== undefined) {
                        acc[key] = oldFeatures[key];
                    } else {
                        acc[key] = newFeatures[key];
                    }

                    return acc;
                }, {});


                return {
                    features: features,
                    featuresConfigs: newFeaturesConfigs
                };
            }

            /**
             * Is a key from a bucket feature?
             *
             * @example
             * _isBucketKey('showLeftBar') => false
             * _isBucketKey('showLeftBar.active') => true
             *
             * @param  {Strinkg}  key  Feature key
             * @return {Boolean}       Whether it's a bucket key
             */
            function _isBucketKey(key) {
                return (key || '').match(/.*\..*/);
            }

            /**
             * Try to extract the parent key from a key
             *
             * @example
             * _getParentKey('showLeftBar') => showLeftBar
             * _getParentKey('showLeftBar.active') => showLeftBar
             *
             * @param  {Strinkg}  key  Feature key
             * @return {Strinkg}       Parent key (until first dot)
             */
            function _getParentKey(key) {
                return (key || '').replace(/(.*?)\..*/i, '$1');
            }

            /**
             * Has other bucket values cached
             * @param {String} key          Feature key (e.g: showLeftBar.active)
             * @param {Object} oldFeatures  Saved features hash
             * @return {String}             Cached bucket key for with the same parent
             */
            function _findOtherBucketKey(key, oldFeaturesKeys) {
                key = key || '';
                oldFeaturesKeys = oldFeaturesKeys || [];

                var isBucketKey = _isBucketKey(key);
                var parentKey;


                // skip
                if (!isBucketKey) { return ''; }

                parentKey = _getParentKey(key) + '.';

                return _.find(oldFeaturesKeys, function (featureKey) {
                    var isSameKey = (featureKey === key);
                    var hasSameParentKey = (featureKey.indexOf(parentKey) === 0);

                    return !isSameKey && hasSameParentKey;
                });
            }

            /**
             * Manually force feature values updates
             * (does not create new features!)
             *
             * @param {Object} features  Forced features
             */
            function _setFeatures(features) {
                StorageDelegate.setFeatures(features);
            }

            /**
             * Get/set "_overridesEnabled" flag value that controls whether URL
             * feautre overrides should be enabled
             *
             * @param  {Boolean} [value]  Set a value to enable/disable feature overrides
             * @return {Boolean}          Whether feature overrides are enabled
             */
            function _areOverridesEnabled(value) {

                if (typeof value === 'boolean') {
                    _overridesEnabled = value;
                }

                return !!_overridesEnabled;
            }

            return {
                getFeatures: _getFeatures,
                setFeatures: _setFeatures,
                areOverridesEnabled: _areOverridesEnabled,
                _compileUpdatedFeatures: _compileUpdatedFeatures // for unit-tests
            };
        }

        FeaturesFac.$inject = [
            '$location',
            'FEATURES',
            'LocalStorageFac',
            'md5'
        ];

        return FeaturesFac;
    });

