'use strict';

define(
    'TrackListFac',[

    ],
    function () {

        function TrackListFac(TrackInfoFac, $rootScope) {

            var trackList = null,
                deferred = null,
                subscriptions = [];

            function _getTrackList(id){
                _subscribe(id);
                if (trackList) {
                    return trackList;
                } else {
                    return [];
                }
            }

            // pollers logic
            function processTrackList(data){
                trackList = data ? data : [];
                $rootScope.$emit('trackListReady', trackList);
            }

            function _startPollers() {
                if(!deferred && subscriptions.length) {
                    deferred = TrackInfoFac.pollDayTracks().then(null, null, processTrackList);
                }
            }

            function _stopPollers() {
                if(deferred) {
                    TrackInfoFac.stopPollingDayTracks(deferred);
                    deferred = null;
                }
            }

            function _restartPollers() {
                _stopPollers();
                _startPollers();
            }

            // subscribers
            function _subscribe(id) {
                var exist = _.find(subscriptions, function(subscription){
                    return subscription === id;
                });
                if(!exist) {
                    subscriptions.push(id);
                    _startPollers();
                }
            }

            function _unSubscribe(id) {
                subscriptions = _.filter(subscriptions, function(subscription) {
                    return subscription !== id;
                });
                if(subscriptions.length === 0) {
                    _stopPollers();
                }
            }

            function _resetTracks() {
                trackList = [];
            }

            $rootScope.$on("wagerprofile", function () {
                _resetTracks();
                _restartPollers();
            });

            return {
                getTrackList: _getTrackList,
                resetTracks: _resetTracks,
                unSubscribe: _unSubscribe,
                subscribe: _subscribe
            };
        }

        TrackListFac.$inject = [
            'TrackInfoFac',
            '$rootScope'
        ];

        return TrackListFac;

    });

