'use strict';

define(
    'HandicappingStoreFac',[],
    function () {

        function HandicappingStoreFac($http, $q, $rootScope, ConfigurationFac) {
            var HandicappingStoreSvc;

            HandicappingStoreSvc = {
                getHandicappingStoreUrl: function () {

                    var deferred = $q.defer();
                    var url = "";

                    if($rootScope.activeFeatures.useTVG3handicappingStoreUrl) {
                        url = "/ajax/handicapping-store";

                        $http.get(url).then(function (response) {
                            deferred.resolve(response.data.replace(/http:\/\//g, "https://"));
                        }, function (e) {
                            deferred.reject(e);
                        });
                    }
                    else {
                        url = "/deposit/api/public/v1.0/handicappingStoreUrl";
                        var context = JSON.stringify(ConfigurationFac.getApplicationContext());

                        $http.get(url, {
                                headers: {
                                    'x-tvgcontext': context
                                }
                            }
                        ).then(function (response) {
                                if (response && response.data && response.data.status === 'success') {
                                    deferred.resolve(response.data.response);
                                } else {
                                    deferred.reject({});
                                }
                            }, function (e) {
                                deferred.reject(e);
                            });
                    }

                    return deferred.promise;
                }
            };

            return {
                getHandicappingStoreUrl: HandicappingStoreSvc.getHandicappingStoreUrl
            };
        }

        HandicappingStoreFac.$inject = [
            '$http',
            '$q',
            '$rootScope',
            'ConfigurationFac'
        ];

        return HandicappingStoreFac;
    });

