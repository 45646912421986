'use strict';

// TODO - support multiple betslips

define(
    'BetslipFactory',["lodash", "Mediator"],
    function (_, mediator) {

        function BetslipFactory($rootScope, $routeParams, $timeout, TrackPerformanceProvider, BetRunnerPicksSvc, PreferencesCookieFac, BetsSvc) {
            var betslipMessageTimeout = 3000;

            // TODO - retrieve params from dynamic data
            var minAmount = 2;
            var maxAmount = 10000;
            var betSubmitted = false;

            function _hasSuccessfulSubmitBet() {
                return betSubmitted;
            }

            function _getBetAmount() {
                return parseFloat($routeParams.betamount) || PreferencesCookieFac.getBetAmount();
            }

            function _getBetType() {
                var betTypeId = $routeParams.bettype || PreferencesCookieFac.getBetType();
                return _getBetTypeBasedOnId(betTypeId) || _getFirstAvailableBetType();
            }

            function _getBetTypeBasedOnId(id){
                return _.find(_betslipData.wagerTypes, function (elem) {
                    return elem.id === id;
                });
            }

            function _getFirstAvailableBetType(){
                return _betslipData.wagerTypes[0];
            }

            var betSelections = $routeParams.betselections || null;
            var talentPickId = $routeParams.talentPickId || null;
            var talentPickName = $routeParams.talentPickName || null;

            var _betslipData = {
                bet: {
                    isPlacingBet: false,
                    successFullyPlaced: false,
                    errorWhilePlacing: false,
                    isToPlace: true,
                    isValid: false,
                    amountIsValid: true,
                    needsBetConfirmation: needsToConfirmBet,
                    isAskingConfirmation: false,
                    preDefinedBetAmount: _getBetAmount(),
                    betAmount: _getBetAmount(),
                    betSelections: betSelections,
                    talentPickId: talentPickId,
                    talentPickName: talentPickName
                },
                wagerTypes: [],
                isAskingConfirmation: isAskingConfirmation
            };
            _betslipData.bet.betType = _getBetType();

            var isToResubmit = false;

            function cleanBetLegs(numberOfLegs) {
                _betslipData.bet.legs = createLegsArray(numberOfLegs);
            }

            function createLegsArray(numberOfLegs) {
                var legsArray = [];
                for (var i = 0; i < numberOfLegs; i++) {
                    legsArray.push([]);
                }
                return legsArray;
            }

            function calculateBetTotals() {
                return BetsSvc.calculateBetTotal(
                    _betslipData.bet.betAmount, _betslipData.bet.betType, _betslipData.bet.legs);
            }

            function userIsNotLoggedIn() {
                return !$rootScope.userSession;
            }

            function isUserBalanceInsufficient() {
                return angular.isDefined($rootScope.accountBalanceNumber) && angular.isNumber($rootScope.accountBalanceNumber) &&
                    $rootScope.accountBalanceNumber < parseFloat(_betslipData.bet.betAmount);
            }

            function showPlacingBet() {
                _betslipData.bet.isPlacingBet = true;
            }

            function showBetSuccessfullyPlaced() {
                _betslipData.bet.isToPlace = true;
                _betslipData.bet.successfullyPlaced = true;
                _betslipData.bet.isPlacingBet = false;
            }

            function showBetNotPlaced(errorMessage) {
                _betslipData.bet.errorMessage = errorMessage;
                _betslipData.bet.isToPlace = true;
                _betslipData.bet.errorWhilePlacing = true;
                _betslipData.bet.isPlacingBet = false;
            }

            function setReadyToPlace() {
                $timeout(function () {
                    _betslipData.bet.isToPlace = true;
                    _betslipData.bet.isPlacingBet = false;
                    _betslipData.bet.successfullyPlaced = false;
                    _betslipData.bet.errorWhilePlacing = false;
                }, betslipMessageTimeout);
            }

            function validateBetslip(betTotals) {
                _betslipData.bet.amountIsValid = true;

                _betslipData.bet.validation = betTotals;
                _betslipData.bet.isValid = betTotals.betCount > 0;

                if (isUserBalanceInsufficient()) {
                    showBetAmountExceedsBalance();
                } else if (!amountIsWithinMinMax(betTotals.betAmount)) {
                    showInvalidBetAmountRange();
                }
            }

            function showBetAmountExceedsBalance() {
                _betslipData.bet.amountIsValid = false;
                // TODO - place the messge on CMS
                _betslipData.bet.amountFailureErrorMessage = 'Bet amount exceeds your balance';
            }

            function showInvalidBetAmountRange() {
                _betslipData.bet.amountIsValid = false;
                _betslipData.bet.isValid = false;
                // TODO - place the messge on CMS
                _betslipData.bet.amountFailureErrorMessage = 'Please enter a value between ' + minAmount + ' and ' + maxAmount;
            }

            function amountIsWithinMinMax(amountValue) {
                return amountValue >= minAmount && amountValue <= maxAmount;
            }

            function needsToConfirmBet() {
                var prefObj = PreferencesCookieFac.getPreferences();
                return prefObj.show_bet_confirm || $rootScope.user.wagerStatus == 1;
            }

            function isAskingConfirmation() {
                return needsToConfirmBet() && !_betslipData.bet.isToPlace;
            }

            function _onRunnerSelectionsChanged(race, numberOfLegs, legNumber, selectedRunners) {
                cleanBetLegs(numberOfLegs);

                _betslipData.bet.race = race;
                if (selectedRunners && selectedRunners.length > 0) {
                    _betslipData.bet.hasSelections = true;
                    _betslipData.bet.legs[legNumber] = selectedRunners;
                } else {
                    _betslipData.bet.hasSelections = false;
                    _betslipData.bet.legs[legNumber] = [];
                }

                _updateBetslip();
            }

            function _onTrackDataUpdated() {
                _betslipData.wagerTypes = TrackPerformanceProvider.getWagerTypes($routeParams.race);

                //shall override with user preferences only if user is logged in
                if (userIsNotLoggedIn()) {
                    _betslipData.bet.betType = _getFirstAvailableBetType();
                    _betslipData.bet.preDefinedBetAmount = minAmount;
                    _onPredefinedBetAmountChanged();
                } else {
                    _betslipData.bet.betType = _getBetType();
                    _betslipData.bet.preDefinedBetAmount = parseFloat(_getBetAmount());
                    _onPredefinedBetAmountChanged();
                }
            }

            function _onPredefinedBetAmountChanged() {
                _betslipData.bet.betAmount = parseFloat(_betslipData.bet.preDefinedBetAmount);

                if (_isToShow()) {
                    _updateBetslip();
                }
            }

            function _updateBetslip() {
                setReadyToPlace();

                var betTotals = calculateBetTotals();
                validateBetslip(betTotals);

                _betslipData.bet.betRunnerPicks = BetRunnerPicksSvc
                    .getPicksForBetType(_betslipData.bet.race, _betslipData.bet.legs, _betslipData.bet.betType);
            }

            function _placeBet() {
                if (userIsNotLoggedIn()) {
                    isToResubmit = true;
                    $rootScope.$emit("callLoginRequiredModal");
                } else if (isUserBalanceInsufficient()) {
                    mediator.dispatch("OPEN_QUICK_DEPOSIT", {});
                } else if (!needsToConfirmBet()) {
                    _submitBet();
                } else {
                    _betslipData.bet.isToPlace = false;
                }
            }

            function _betNow(betNowCallback) {
                if (userIsNotLoggedIn()) {
                    isToResubmit = true;
                    $rootScope.$emit("callLoginRequiredModal");
                } else {
                    betNowCallback();
                }
            }

            function _tryToBetNowAgain(betNowCallback) {
                if (isToResubmit) {
                    _betNow(betNowCallback);
                    isToResubmit = false;
                }
            }

            function _isToShow() {
                return _betslipData.bet.race && _betslipData.bet.hasSelections;
            }

            function _submitBet() {
                var betValidation = _betslipData.bet.validation;
                var race = _betslipData.bet.race;

                showPlacingBet();

                BetsSvc.placeBet(betValidation, race).then(function () {
                    showBetSuccessfullyPlaced();
                    setReadyToPlace();
                    betSubmitted = true;
                }, function (error) {
                    showBetNotPlaced(error.errorMessageKey);
                    setReadyToPlace();
                });
            }

            function _cancelBet() {
                _betslipData.bet.isToPlace = true;
            }

            function _tryToResubmit() {
                if (isToResubmit) {
                    _placeBet();
                    isToResubmit = false;
                }
            }

            function _clearBetslip() {
                _betslipData.bet.hasSelections = false;
                _betslipData.bet.isPlacingBet = false;
                _betslipData.bet.successFullyPlaced = false;
                _betslipData.bet.errorWhilePlacing = false;
                _betslipData.bet.isToPlace = true;
                _betslipData.bet.isValid = false;
                _betslipData.bet.amountIsValid = true;
                _betslipData.bet.isAskingConfirmation = false;
                _betslipData.bet.betType = _getBetType();
                _betslipData.bet.preDefinedBetAmount = _getBetAmount();
                _betslipData.bet.betAmount = _getBetAmount();
            }

            /**
             * Transform each selection leg String into an array of integers of selections.
             *
             * @param {String} betSelectionsString The bet selections string.
             * Expected to have the following regexp format: ([\d]\,*\;*)
             * @returns {*} the received parameter if not a valid String or an array of array(s) of integer(s).
             * @private
             */
            function _parseBetSelectionsString(betSelectionsString) {
                if (!betSelectionsString || typeof betSelectionsString !== 'string') {
                    return betSelectionsString;
                }

                return betSelectionsString.split(';')
                    .map(function (currentSelectionLeg) {
                        return _.reduce(currentSelectionLeg.split(','), function (result, currentSplitSelection) {
                            // parse String to integer
                            currentSplitSelection = parseInt(currentSplitSelection, 10);
                            if (!isNaN(currentSplitSelection) && !_.includes(result, currentSplitSelection)) {
                                result.push(parseInt(currentSplitSelection, 10));
                            }
                            return result;
                        }, [])
                        // Using Boolean as a function to convert a non-boolean value to a boolean value.
                        // This removes any selection that could not be parsed.
                            .filter(Boolean);
                    });
            }

            return {
                parseBetSelectionsString: _parseBetSelectionsString,
                onRunnerSelectionsChanged: _onRunnerSelectionsChanged,
                onTrackDataUpdated: _onTrackDataUpdated,
                onPredefinedBetAmountChanged: _onPredefinedBetAmountChanged,
                onBetTypeChanged: _updateBetslip,
                onBetAmountChanged: _updateBetslip,

                betNow: _betNow,
                tryToBetNowAgain: _tryToBetNowAgain,

                placeBet: _placeBet,
                submitBet: _submitBet,
                cancelBet: _cancelBet,
                tryToResubmit: _tryToResubmit,
                clearBetslip: _clearBetslip,

                hasSuccessfulSubmitBet: _hasSuccessfulSubmitBet,
                isToShow: _isToShow,
                betslipData: _betslipData
            };
        }

        BetslipFactory.$inject = [
            '$rootScope',
            '$routeParams',
            '$timeout',
            'TrackPerformanceProvider',
            'BetRunnerPicksSvc',
            'PreferencesCookieFac',
            'BetsSvc'
        ];

        return BetslipFactory;
    }
);

