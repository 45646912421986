'use strict';

define('VideoPopOutFac',[],
    function () {

        function VideoPopOutFac(
                $window,
                $rootScope,
                $q,
                VideoProviderSvc,
                BrowserCheckFac,
                SecuritySvc
            ) {
            var PLAYER_WIDTH = 640;
            var PLAYER_HEIGHT = 480;
            var _windows = [];


            /**
             * Create video player URL for Neulion
             * @param  {Object} race  Race/track info
             * @return {String}       URL
             */
            function _createNeulionVideoUrl(race) {
                var url = '';

                if (race) {
                    url = '/ajax/video?stream=';

                    if (race.raceOpenForLiveVideo) {
                        url += 'live&track=' + race.trackAbbr;
                    } else {
                        url += 'replay&track={trackAbbr}&perf={perfAbbr}&raceDate={raceDate}&postTime={postTime}&provider={provider}&name={name}&speed={speed}'
                            .replace('{trackAbbr}', race.trackAbbr)
                            .replace('{perfAbbr}', race.perfAbbr)
                            .replace('{raceDate}', race.raceDate)
                            .replace('{postTime}', race.postTime)
                            .replace('{provider}', 1)
                            .replace('{name}', race.trackName)
                            .replace('{speed}', 2);
                    }
                }

                return url;
            }

            /**
             * Open new window with video player
             *
             * @param  {String} url     New window's URL
             * @param  {Number} width   New window's width
             * @param  {Number} height  New window's height
             * @return {Promise}        New window object properties
             */
            function _openVideoWindow(url, width, height) {
                var specs = 'width=' + (width || 640) + ',height=' + (height || 480);
                var sessionUrl = SecuritySvc.getValidateSessionUrl();
                var safariBlockedPopupsText = '';
                var win;


                // @HACK pass session endpoint to new window
                // because video.html doesn't have features and factories
                url += (url.match(/\?/)) ? '' : '?';
                url += '&session_url=' + encodeURIComponent(sessionUrl);

                win = $window.open(url, '', specs);


                if (win) {
                    _windows.push(win);
                } else if (BrowserCheckFac.isSafari()){
                    // by default, safari blocks popups (win=null)
                    safariBlockedPopupsText = BrowserCheckFac.getSafariBlockedPopupsText();
                    $window.alert(safariBlockedPopupsText);
                }

                return {
                    window: win,
                    src: url
                };
            }

            /**
             * Open window player, making sure the user is slogged in
             * (may need to wait for session start)
             *
             * @param  {String} url     New window's URL
             * @param  {Number} width   New window's width
             * @param  {Number} height  New window's height
             * @return {Promise}        New window object (promised)
             */
            function _openVideoWindowAfterLogin(url, width, height) {

                return $q(function (resolve, reject) {
                    var _createWindow = function () {
                        var popup = _openVideoWindow(url, width, height);

                        if (popup) {
                            resolve(popup);
                        } else {
                            reject(new Error('Failed to open video window popup.'));
                        }
                    };

                    if ($rootScope.userSession) {
                        _createWindow();
                    } else {
                        $rootScope.$emit('callLoginRequiredModal', _createWindow);
                    }
                });
            }

            /**
             * Create popout video player for RCN
             * @param  {Object} race  Race/track information
             * @return {Promise}      New player window (promised)
             */
            function _popOutRcnVideo(race) {

                return VideoProviderSvc.getIframeSource(race, '100%', '100%')
                    .then(function (iframeUrl) {
                        var width = PLAYER_WIDTH;
                        var height = PLAYER_HEIGHT;
                        var player = VideoProviderSvc.hasHLSStreaming() ? 'html5' : 'iframe';
                        var url = '/video.html' +
                            '?src=' + encodeURIComponent(iframeUrl) +
                            '&name=' + encodeURIComponent(race.trackName) +
                            '&w=' + width +
                            '&h=' + height +
                            '&player=' + player; // must match parameters in video.html

                        return _openVideoWindowAfterLogin(url, width, height);
                    });
            }

            /**
             * Create popout video player for Neulion
             * @param  {Object} race  Race/track information
             * @return {Promise}      New player window (promised)
             */
            function _popOutNeulionVideo(race) {
                var width = PLAYER_WIDTH;
                var height = PLAYER_HEIGHT;
                var url = _createNeulionVideoUrl(race);

                return _openVideoWindowAfterLogin(url, width, height);
            }

            /**
             * Create popout video player (RCN or Neulion)
             * @param  {Object} race  Race/track information
             * @return {Promise}      New player window (promised)
             */
            function popOutVideo(race) {
                return ($rootScope.activeFeatures.videoProviderRCN) ?
                    _popOutRcnVideo(race) :
                    _popOutNeulionVideo(race);
            }

            /**
             * Show no session warning when user logs out in parent window
             */
            function _logoutPopupWindows() {
                _broadcastPopupWindows('logout_video_popup');
            }

            /**
             * Close all open video player windows when user
             * closes the parent window
             */
            function _closePopupWindows() {
                _broadcastPopupWindows('close_video_popup');
            }

            /**
             * Broadcast event to all open popup windows
             * @param  {String} event  Event name or stringified event data
             */
            function _broadcastPopupWindows(event) {
                var openWindows = [];

                _windows.forEach(function (win) {
                    if (!win.closed) {
                        openWindows.push(win);
                        win.postMessage(event, $window.location.origin);
                    }
                });

                _windows = openWindows;
            }

            /**
             * Initialize factory
             */
            function _init() {
                $rootScope.$on('logout', _logoutPopupWindows);
                $window.addEventListener('beforeunload', _closePopupWindows);
            }


            _init();

            return {
                popOutVideo: popOutVideo
            };
        }

        VideoPopOutFac.$inject = [
            '$window',
            '$rootScope',
            '$q',
            'VideoProviderSvc',
            'BrowserCheckFac',
            'SecuritySvc'
        ];

        return VideoPopOutFac;
    });

