'use strict';

define('FeaturedTracksDir',[],function() {
    return function FeaturedTracksDir() {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'js/desktop/modules/FeaturedTracks/templates/featuredTracks.html',
            controller: 'FeaturedTracksController',
            controllerAs: 'featuredTracks'
        };
    };
});

