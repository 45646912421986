"use strict";

define('RaceMtpLabelDir',[
    'RaceMTP'
], function (RaceMTP) {

    function RaceMtpLabelDir() {

        return {
            restrict: '',
            replace: false,
            scope: {
                racePostTime: "=",
                withTimeDay: "=",
                mtp: '='
            },
            templateUrl: 'js/desktop/modules/Races/components/raceMtpLabel/templates/race-mtp-label.html',
            link: function (scope) {
                scope.mtpFormat = {};
                scope.raceMtp = {};
                scope.mtpFormatsEnum = {
                    MTP: 1,
                    TIME: 2,
                    TIME_WITH_DAY: 3,
                    NONE: 4
                };

                scope.currentMtpFormat = scope.mtpFormatsEnum.NONE;

                function _getMtpFormat(mtpDate) {
                    if (angular.isDefined(mtpDate.mtp)) {
                        return scope.mtpFormatsEnum.MTP;
                    }
                    else if (scope.withTimeDay && angular.isDefined(mtpDate.timeWithDay)) {
                        return scope.mtpFormatsEnum.TIME_WITH_DAY;
                    }
                    else if (!scope.withTimeDay && angular.isDefined(mtpDate.date)) {
                        return scope.mtpFormatsEnum.TIME;
                    }
                    return scope.mtpFormatsEnum.NONE;
                }

                function _updateBindings() {
                    if (!scope.racePostTime) {
                        return;
                    }
                    var raceMTP = new RaceMTP(scope.racePostTime);

                    scope.raceMtp = raceMTP.getMTPDate(scope.mtp);
                    scope.currentMtpFormat = _getMtpFormat(scope.raceMtp);
                }

                (function init() {
                    _updateBindings();
                }());

                scope.$watch("racePostTime", _updateBindings);
                scope.$watch("mtp", _updateBindings);
            }
        };

    }

    RaceMtpLabelDir.$inject = [];

    return RaceMtpLabelDir;

});

