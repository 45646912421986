'use strict';

define(
    'CMSPages',[
        'CMSPagesCtrl'
    ],
    function (CMSPagesCtrl) {

        angular.module('TVG.CMSPages', [])

            .controller('CMSPagesCtrl', CMSPagesCtrl);
    }
);

