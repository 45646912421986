'use strict';

define('NotificationMyStableCtrl',[],
    function () {
        function NotificationMyStableCtrl(messages, closeDialog) {
            var vm = this;
            vm.closeDialog = closeDialog;
            vm.messages = messages;
        }

        NotificationMyStableCtrl.$inject = [
            'messages',
            'closeDialog'
        ];

        return NotificationMyStableCtrl;
});

