'use strict';

define('TrackSvc',[],function () {

    function TrackSvc() {

        function _isTrackFromHongKong(track) {
            return (track.CountryName === 'CHN' && track.State === 'HK');
        }

        function _isHongKongRace(trackAbbr) {
            return (trackAbbr === 'HK1'
                || trackAbbr === 'HK2'
                || trackAbbr === 'HKH'
                || trackAbbr === 'HKS');
        }

        return {
            isTrackFromHongKong: _isTrackFromHongKong,
            isHongKongRace: _isHongKongRace
        };
    }

    return TrackSvc;
});
