'use strict';

define(
    'AddressDetailsCtrl',[
    ],
    function () {

        function AddressDetailsCtrl($scope, $rootScope, StatesSvc) {

            function matchesProperties(obj, obj2, properties){
                return properties.every(function (property){
                    return obj[property] === obj2[property];
                });
            }

            function getHomeAddress(){
                if(!$rootScope.user){
                    return;
                }

                var address = $rootScope.user.homeAddress;
                address.State = StatesSvc.getStateBasedOnAbbr(address.StateAbbr);

                return address;
            }

            function getMailingAddress(){
                if(!$rootScope.user){
                    return;
                }

                var address = $rootScope.user.mailingAddress;

                if (address && address.StateAbbr) {
                    address.State = StatesSvc.getStateBasedOnAbbr(address.StateAbbr);
                }

                return address;
            }

            function checkIfAddressesAreTheSame(){
                if(!$scope.homeAddress || !$scope.mailingAddress){
                    return false;
                }
                return matchesProperties($scope.homeAddress, $scope.mailingAddress, ["Address1", "City", "Zip", "State", "StreetNumber"]);
            }

            function setAddresses(){
                if(!$scope.homeAddress)
                {
                    $scope.homeAddress = getHomeAddress();
                }
                if(!$scope.mailingAddress){
                    $scope.mailingAddress = getMailingAddress();
                }

                $scope.addressesAreEquals = checkIfAddressesAreTheSame();
            }

            (function init() {
                setAddresses();
            }());

            var unbindWatch = $rootScope.$watch("user", setAddresses);
            $scope.$on("$destroy", unbindWatch);

            $scope.showMailingAddress = false;

            $scope.toggleShowMailingAddress = function (){
                $scope.showMailingAddress = !$scope.showMailingAddress;
            };

        }

        AddressDetailsCtrl.$inject = [
            '$scope',
            '$rootScope',
            'StatesSvc'
        ];

        return AddressDetailsCtrl;
    }
);

