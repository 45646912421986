'use strict';

define('ScrollDecorator',[],
    function () {

        function ScrollDecorator($delegate, $document) {
            var error = $delegate.error;
            var success = $delegate.success;
            var warning = $delegate.warning;
            var confirm = $delegate.confirm;
            var cancel = $delegate.cancel;
            var el = $document[0].getElementsByTagName('body')[0];

            function _scroll(type, title, message, elementBeforeBanner, callback, scroll) {
                return type.apply($delegate, [title, message, elementBeforeBanner, callback, scroll])
                .then(function () {
                    if(scroll) {
                        el.scrollTop = 0;
                    }
                });
            }

            function newError(title, message, elementBeforeBanner, callback, scroll) {
                return _scroll(error, title, message, elementBeforeBanner, callback, scroll);
            }

            function newSuccess(title, message, elementBeforeBanner, callback, scroll) {
                return _scroll(success, title, message, elementBeforeBanner, callback, scroll);
            }

            function newWarning(title, message, elementBeforeBanner, callback, scroll) {
                return _scroll(warning, title, message, elementBeforeBanner, callback, scroll);
            }

            function newConfirm(title, message, elementBeforeBanner, callback, scroll) {
                return _scroll(confirm, title, message, elementBeforeBanner, callback, scroll);
            }

            function newCancel(title, message, elementBeforeBanner, callback, scroll) {
                return _scroll(cancel, title, message, elementBeforeBanner, callback, scroll);
            }

            $delegate.error = newError;
            $delegate.success = newSuccess;
            $delegate.warning = newWarning;
            $delegate.confirm = newConfirm;
            $delegate.cancel = newCancel;

            return $delegate;
        }

        ScrollDecorator.$inject = ['$delegate', '$document'];

        return ScrollDecorator;
    }
);

