'use strict';

define('SectionNames',[],
    function () {
        return {
            'my-profile':
            {
                'translation': 'My Account',
                'my-tracks': 'My Tracks',
                'my-favorite-tracks': 'My Favorite Tracks',
                'funds': 'My Funds',
                'my-bets': 'My Bets',
                'my-account-summary': 'My Account Summary',
                'profile': 'My Profile',
                'deposit': 'Deposit',
                'bank': 'Payment Management',
                'withdraw': 'Withdraw',
                'my-stable': 'My Stable'
            },
            handicapping: {
                'translation': 'Handicapping',
                'handicapping-store': 'Handicapping Store',
                'free-pp': 'Free Past Performances',
                'talentpicks': 'Talent Picks',
                'racereplays': 'Race Replays',
                'talent-picks': 'Talent Picks',
                'race-replays': 'Race Replays'
            },
            promotions: {
                translation: 'Promotions',
                'wager-rewards': 'Wager Rewards'
            },
            'wager-rewards': {
                translation: 'Promotions | Wager Rewards',
                'wager-rewards': 'Wager Rewards'
            },
            horseracing: {
                translation: 'Races',
                races: 'Full Schedule',
                results: 'Results'
            },
            home: {
                translation: 'Home | Home Page'
            },
            programpage: {
                translation: 'Program Page'
            },
            siteinfo: {
                translation: 'Site Info',
                privacypolicy: 'Privacy Policy',
                wagerresponsibly: 'Wager Responsibly',
                termsandconditions: 'Terms and Conditions'
            },
            betslip: {
                translation: 'Bet Ticket',
                default: 'Bet Ticket Page'
            },
            'password-recovery': {
                translation: 'Info'
            },
            'registration': {
                translation: 'Registration | Single Step'
            },
            'reset-credentials': {
                translation: 'Credentials Recovery | Credentials Setup'
            },
            'forgot-credentials': {
                translation: 'Credentials Recovery | Start'
            },
            'tracks': {
                translation: 'Track List | Track List Page'
            },
            'live': {
                translation: 'TVG Live'
            }
        };
    }
);

