'use strict';

define('RaceReplayEntityBuilder',['RaceReplayEntity'], function (RaceReplayEntity) {

    function builder(){
        var raceReplay = new RaceReplayEntity();

        return {
            withRaceDate: function (raceDate) {
                raceReplay.raceDate = raceDate;
                return this;
            },
            withPostTime: function (postTime) {
                raceReplay.postTime = postTime;
                return this;
            },
            withRaceTypeName: function (raceTypeName) {
                raceReplay.raceTypeName = raceTypeName;
                return this;
            },
            withTrackName: function (trackName) {
                raceReplay.trackName = trackName;
                return this;
            },
            withTrackAbbr: function (trackAbbr) {
                raceReplay.trackAbbr = trackAbbr;
                return this;
            },
            withProviderTrack: function (providerTrack) {
                raceReplay.providerTrack = providerTrack;
                return this;
            },
            withRaceNumber: function (raceNumber) {
                raceReplay.raceNumber = raceNumber;
                return this;
            },
            withPerfAbbr: function (perfAbbr) {
                raceReplay.perfAbbr = perfAbbr;
                return this;
            },
            withRaceClass: function (raceClass) {
                raceReplay.raceClass = raceClass;
                return this;
            },
            withDistance: function (distance) {
                raceReplay.distance = distance;
                return this;
            },
            withProvider: function (provider) {
                raceReplay.provider = provider;
                return this;
            },
            withRaceTypeId: function (raceTypeId) {
                raceReplay.raceTypeId = raceTypeId;
                return this;
            },
            build: function () {
                return raceReplay;
            }
        };
    }

    return builder;

});


