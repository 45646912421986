'use strict';

define('EditEmailCtrl',['lodash'], function (_) {

    function EditEmailCtrl($scope, $rootScope, SecuritySvc, $uibModal, GTMFac, FindModalContainerFac, $filter) {

        function setUser() {
            if (!$rootScope.user) {
                return;
            }

            if ($scope.userProfile) {
                $scope.data.oldEmail = $scope.userProfile.emailAddress;
                return;
            }

            $scope.userProfile = $rootScope.user;
            $scope.data.oldEmail = $scope.userProfile.emailAddress;

            _setPristine();
        }

        function _getProp(prop) {
            return function(obj) {
                return obj[prop];
            };
        }

        function _openModal(template, scope) {
            return $uibModal.open({
                appendTo: FindModalContainerFac('#angular-app'),
                backdropClass: 'tvgmodal-backdrop',
                windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                windowClass: 'login-error',
                templateUrl: template,
                scope: scope
            });
        }

        function _notifyGMT () {
            return GTMFac.GTMEvent().send($rootScope, 'siteClick', {
                gaEventCategory: 'Site Click',
                gaEventAction: 'My Profile Page Click',
                gaEventLabel: 'User Profile | Change Email',
                sectionName: GTMFac.Tvg4SectionName(),
                screenName: GTMFac.Tvg4ScreenName(),
                eventLabel: 'MyAccount_UserProfileEmail'
            });
        }

        function _changeEmailPressed(formValid) {
            if (formValid && _isNewEmailDifferent()) {
                return _callChangeEmailService();
            }

            _verifyErrors(_isNewEmailDifferent());
            _setPristine();
        }

        function _verifyErrors(isEmailDifferent) {
            var message = (!isEmailDifferent) ?
                'New email equal to the old one' :
                'Invalid Email';

            return _showErrorMessage(message);
        }

        function _callChangeEmailService() {
            $scope.data.loading = true;

            return SecuritySvc.changeEmail({
                email: $scope.userProfile.emailAddress
            })
            .then(_getProp('message'))
            .then(_showMessage)
            .then(function () {
                $scope.data.shouldCloseOnMessage = true;
                $scope.data.oldEmail = $scope.userProfile.emailAddress;
            })
            .then(_notifyGMT)
            .catch(function (error) {
                var genericErrMessage = 'There was a problem updating your email.';
                var errorMessage = genericErrMessage;
                errorMessage = error.code && $filter('CMSLabels')(error.code, 'profileUpdateEmailErrorHandling') ?
                    $filter('CMSLabels')(error.code, 'profileUpdateEmailErrorHandling') : genericErrMessage;
               _showErrorMessage(errorMessage);
            })
            .finally(function() {
                $scope.data.loading = false;
                _setPristine();
            });
        }

        function _showMessage(message) {
            $scope.data.message = message || 'Your email was successfully changed';
            $scope.data.confirmDialog = _openModal('js/desktop/modules/Account/templates/change-pin-password-success.html', $scope);
        }

        function _showErrorMessage(message) {
            $scope.data.errorMessage = message || 'Error changing your email';
            $scope.data.errorMessageTitle = 'Change Email Failed';
            $scope.data.confirmDialog = _openModal('js/desktop/modules/Login/templates/login-error.html', $scope);
        }

        function _cancel() {
            if ($scope.data.confirmDialog) {
                $scope.data.confirmDialog.close();
                $scope.data.confirmDialog = null;
            }
        }

        function _setPristine(){
            $scope.changeEmailFrm.emailAddress.$setPristine();
            $scope.userProfile.emailAddress = $scope.data.oldEmail || '';
        }

        function _isNewEmailDifferent() {
            return !!$scope.data.oldEmail && $scope.data.oldEmail !== $scope.userProfile.emailAddress;
        }

        function _emailExists() {
            var email = $scope.changeEmailFrm.emailAddress;
            if(email.$viewValue && email.$valid) {
                $scope.emailExists = false;
                SecuritySvc.checkUsername(email.$viewValue, true).then(function (response) {
                    $scope.emailExists = response.exists;
                    $scope.emailValid = response.valid;
                });
            }
        }

        var unbindWatch = $rootScope.$watch('user', setUser);
        $scope.$on('about-you::on-form-cancel', _setPristine);
        $scope.$on('$destroy', unbindWatch);

        $scope.data = {
            oldEmail: ''
        };
        $scope.emailValid = true;
        $scope.emailExists = false;

        $scope.events = {
            changeEmailPressed: _changeEmailPressed,
            isNewEmailDifferent: _isNewEmailDifferent,
            cancel: _cancel,
            emailExists: _.debounce(_emailExists, 500)
        };

        setUser();
    }

    EditEmailCtrl.$inject = [
        '$scope',
        '$rootScope',
        'SecuritySvc',
        '$uibModal',
        'GTMFac',
        'FindModalContainerFac',
        '$filter'
    ];

    return EditEmailCtrl;
});

