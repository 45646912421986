'use strict';

define(
    'RaceInfoCtrl',[],

    function() {

        function RaceInfoCtrl($scope, $rootScope) {
            if (!$scope.data) {
                $scope.data = {
                    handicapSortAscending: true
                };
            }

            $scope.data.selectedHandicapping = null;
            if ($scope.handicapping && $scope.handicapping.handicapData && $scope.handicapping.handicapData.length) {
                $scope.data.selectedHandicapping = $scope.handicapping.handicapData[0];
            }

            $scope.data.selectedWillPay = null;
            if ($scope.race && $scope.race.willPays && $scope.race.willPays.length) {
                $scope.data.selectedWillPay = $scope.race.willPays[0];
            }

            $scope.data.selectedProbable = null;
            if (
                $scope.race &&
                $scope.race.probables &&
                $scope.race.probables.length
            ) {
                var exactaIndex = $scope.race.probables.findIndex(function (
                    probable
                ) {
                    return probable.wagerTypeName === "Exacta";
                });
                $scope.data.selectedProbable =
                    $scope.race.probables[exactaIndex === -1 ? 0 : exactaIndex];
            }


            $scope.events = {
                loginfn: _loginfn,
                sortHeaderTapped: _sortHeaderTapped,
                resetSort: _resetSort,
                isSortSelected: _isSortSelected,
                isSortReverseSelected: _isSortReverseSelected
            };

            function _sortHeaderTapped (index) {
                if (!$scope.data) {
                    return;
                }
                // avoid sorting options which are not visible to the user
                if ($scope.data.selectedHandicapping.sortOptions[index] === ""){
                    return;
                }
                if ($scope.data.handicapHeaderToSort != $scope.data.selectedHandicapping.sortOptions[index]) {
                    _resetSort(true);
                }
                if ($scope.data.handicapHeaderToSort && $scope.data.handicapSortAscending) {
                    $scope.data.handicapHeaderToSort = null;
                    $scope.data.handicapSortIndex = 0;
                    $scope.data.handicapSortAscending = true;
                    return;
                }

                $scope.data.handicapSortAscending = $scope.data.handicapHeaderToSort ? true : false;

                $scope.data.handicapHeaderToSort = $scope.data.selectedHandicapping.sortOptions[index];
                $scope.data.handicapSortIndex = index;
            }

            function _loginfn () {
                $rootScope.$emit("callLoginRequiredModal");
            }

            function _resetSort (anotherCol) {
                if (!anotherCol) {
                    $scope.data.handicapHeaderToSort = null;
                    $scope.data.handicapSortIndex = 0;
                    $scope.data.handicapSortAscending = true;
                } else if ($scope.data.handicapHeaderToSort != anotherCol) {
                    $scope.data.handicapSortAscending = true;
                    $scope.data.handicapHeaderToSort = null;
                }
            }

            function _isSortSelected (index) {
                if ($scope.data && $scope.data.selectedHandicapping) {
                    return $scope.data.handicapHeaderToSort == $scope.data.selectedHandicapping.sortOptions[index];
                }
                return false;
            }

            function _isSortReverseSelected (index) {
                if ($scope.data && $scope.data.selectedHandicapping) {
                    return $scope.data.handicapSortAscending && $scope.data.handicapHeaderToSort == $scope.data.selectedHandicapping.sortOptions[index];
                }
                return false;
            }

            function _getRunnerIndexByHorseId(runner){
                for(var i = 0; i < ($scope.data.selectedHandicapping.runners || {}).length; i++){
                    if($scope.data.selectedHandicapping.runners[i].horseId === runner.horseId) {
                        return i;
                    }
                }
                //not found
                return -1;
            }

            $scope.$watch('race.probables', function() {
                var isWagerTypeIdEqualToPrevious = false;

                if ($scope.race && $scope.data.selectedProbable && $scope.race.probables && $scope.race.probables.length) {
                    angular.forEach($scope.race.probables, function(w) {
                        if (w.wagerTypeId == $scope.data.selectedProbable.wagerTypeId) {
                            $scope.data.selectedProbable = w;
                            isWagerTypeIdEqualToPrevious = true;
                            return;
                        }
                    });
                }

                if (!isWagerTypeIdEqualToPrevious) {
                    $scope.data.selectedProbable = (
                        $scope.race && $scope.race.probables && $scope.race.probables.length && $scope.race.probables.length > 0 ?
                            $scope.race.probables[0] : null);
                }
            });

            $scope.$watch('race.willPays', function() {
                if ($scope.race && $scope.data.selectedWillPay && $scope.race.willPays && $scope.race.willPays.length) {
                    //find if we have the same will pay
                    var willPaysFiltered = $scope.race.willPays.filter(function(willPay) {
                        return willPay.description == $scope.data.selectedWillPay.description;
                    });

                    // select will pay
                    if (willPaysFiltered.length){
                        $scope.data.selectedWillPay = willPaysFiltered[0];
                    } else {
                        $scope.data.selectedWillPay = $scope.race.willPays[0];
                    }
                }
            });

            $scope.$watch('handicapping', function() {
                if ($scope.handicapping && $scope.handicapping.handicapData && $scope.handicapping.handicapData.length) {
                    if (!$scope.data.selectedHandicapping) {
                        $scope.data.selectedHandicapping = $scope.handicapping.handicapData[0];
                    } else {
                        angular.forEach($scope.handicapping.handicapData, function(h) {
                            if ($scope.data && $scope.data.selectedHandicapping && h.type == $scope.data.selectedHandicapping.type) {
                                $scope.data.selectedHandicapping = h;
                            }
                        });
                    }
                }
            });

            $scope.handicapSort = function (runner) {
                var header = $scope.data.handicapHeaderToSort,
                    col = $scope.data.handicapSortIndex - 2,
                    aValue = runner.values[col];

                if (!aValue && aValue !== 0) {
                    return _getRunnerIndexByHorseId(runner);
                }

                if (isNaN(aValue) && aValue.indexOf("/") > 0) {
                    var aFractionalParts = aValue.split("/");
                    if (aFractionalParts[1] == 0) {
                        aFractionalParts[1] = -Number.MIN_VALUE;
                    }
                    aValue = parseFloat(aFractionalParts[0]) / parseFloat(aFractionalParts[1]);

                } else if (isNaN(aValue) && header != "JTMed") {
                    aValue = 0.0;
                } else if (!isNaN(aValue)) {
                    aValue = parseFloat(aValue);
                }
                return aValue;
            };

            /**
             * @param runner {Object}
             * @description
             * When the user sorts a column, whether in ascending or descending order,
             * if there are equal values, the runner with lowest horseId number shall
             * be presented at first
             * @returns {Number} -  less than 0 if sorting in descending order
             *                      greater than 0 if sorting in ascending order
             */
            $scope.getHorseIdAsInt = function (runner) {
                return $scope.data.handicapSortAscending ? _getRunnerIndexByHorseId(runner) : -_getRunnerIndexByHorseId(runner);
            };

        }

        RaceInfoCtrl.$inject = [
            '$scope',
            '$rootScope'  
        ];

        return RaceInfoCtrl;
    }
);

