"use strict";

define('MyBetsFilters',[],function () {

        return {
            /**
             *
             * @private
             * @description
             * Filter by race and status, the two parameters that weren't sent in the ajax request.
             * The server doesn't recognize these parameters. We need to filter on the client-side.
             *
             */
            filterActiveBets: function(race) {
                return function(bet) {
                    return ((race && bet.raceNumber !== String(race.raceNumber)) || (bet.wagerStatus !== 'Active'));
                };

            },

            /**
             *
             * @private
             * @description
             * Filter by race and status, the two parameters that weren't sent in the ajax request.
             * The server doesn't recognize these parameters. We need to filter on the client-side.
             *
             */
            filterPlacedBets: function(race) {
                return function(bet) {
                    return ((race && bet.raceNumber !== String(race.raceNumber)) || (bet.wagerStatus === 'Cancelled'));
                };
            }
        };


});


