'use strict';

define('PageMetaInfoEntity',[],function () {

    /***
     *
     * @param title
     * @param metaTag
     * @constructor
     */
    function pageInfoEntity(title, metaTag){

        this.title = title;
        this.metaTag = metaTag;

    }

    return pageInfoEntity;

});


