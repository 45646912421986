'use strict';

define('RaceStatusLabelDir',[
    'RaceStatusUtils'
], function (raceStatusUtils) {

    function RaceStatusLabelDir () {
        return {
            restrict: 'E',
            template:
                '<span class="info" ' +
                    'ng-show="shouldShowRaceStatusLabel()" ' +
                    'ng-class="raceStatus">' +
                    '<strong ' +
                        'ng-bind="raceStatusLabel" ' +
                        'qa-label="raceStatusLabel">' +
                    '</strong>' +
                '</span>',
            scope: {
                raceStatus: '=',
                raceShowOnlyForClosed: '='
            },
            link: function (scope) {

                scope.shouldShowRaceStatusLabel = function () {
                    var statesWithLabel = {};

                    statesWithLabel[raceStatusUtils.RACE_STATUS.RACE_OFFICIAL] = true;
                    statesWithLabel[raceStatusUtils.RACE_STATUS.STEWARDS_KEY] = true;
                    statesWithLabel[raceStatusUtils.RACE_STATUS.MANUALLY_CLOSED] = true;

                    return !scope.raceShowOnlyForClosed || statesWithLabel[scope.raceStatus];
                };

                function _setRaceStatusLabel(raceStatus) {
                    scope.raceStatusLabel = raceStatusUtils.getRaceStatusLabel(raceStatus);
                }

                (function init() {
                    _setRaceStatusLabel(scope.raceStatus);
                }());

                scope.$watch('raceStatus', _setRaceStatusLabel);
            }
        };
    }

    RaceStatusLabelDir.$inject = [];

    return RaceStatusLabelDir;

});

