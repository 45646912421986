'use strict';

define('WidgetsFac',['lodash', 'muton'],
    function (_) {

        function WidgetsFac($rootScope,$q,ContentFac) {

            var _isCarousel = function (elem){
                return elem.type === "carousel";
            };

            var _isTalentPicks = function (elem){
                return elem.value === "talentpicks";
            };

            var _isVideoRace = function (elem) {
                return elem.value === "nextlivevideo";
            };

            var _setCarouselWidget = function () {
                WidgetsFac.carousel = _.head(WidgetsFac.widgetsFromCMS.filter(_isCarousel));
            };

            WidgetsFac.getCarousel = function () {
                return WidgetsFac.getWidgetsFromCMS().then(_setCarouselWidget);
            };

            WidgetsFac.getWidgetsFromCMS = function(){
                if($rootScope.activeFeatures.useCmsCarousel) {
                    return ContentFac.getNamespaceContent('widget').then(function(data) {
                        WidgetsFac.widgetsFromCMS = data;
                    });
                } else {
                    var deferred = $q.defer();
                    WidgetsFac.widgetsFromCMS = [
                        {
                            type: 'carousel',
                            value: []
                        }
                    ];

                    deferred.resolve();
                    return deferred.promise;
                }

            };

            WidgetsFac.hasTalentPicks = function () {
                return WidgetsFac.widgetsFromCMS.filter(_isTalentPicks).length > 0;
            };

            WidgetsFac.hasVideoRaces = function () {
                return WidgetsFac.widgetsFromCMS.filter(_isVideoRace).length > 0;
            };

            return WidgetsFac;

        }

        WidgetsFac.$inject = [
            '$rootScope',
            '$q',
            'ContentFac'
        ];

        return WidgetsFac;

    });

