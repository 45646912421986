'use strict';

define(
        'AccountCtrl',[
            'SecuritySvc',
            'PreferencesCookieFac'
        ],
        function () {

            function AccountCtrl($scope, $rootScope, SecuritySvc, METADATA, PreferencesCookieFac, $filter, $location, GTMFac) {

                $scope.withPassword = $rootScope.user.userName ? true : false;
                $scope.accountID = $rootScope.user.accountNumber ? $rootScope.user.accountNumber : "no data";

                $scope.events = {
                    logoutPressed: _logoutPressed,
                    goto: _goto,
                    setDisplayBalance: _setDisplayBalance,
                    setRequestBetConfirmation: _setRequestBetConfirmation
                };

                $rootScope.$on("open", function(e, name) {
                   $scope.pop();
                   $scope.events.goto(name);
                });

                $scope.$on("user", function () {
                    $scope.withPassword = $rootScope.user.userName ? true : false;
                    $scope.accountID = $rootScope.user.accountNumber ? $rootScope.user.accountNumber : "no data";
                });

                var prefObj = PreferencesCookieFac.getPreferences();

                if (METADATA) {

                    $scope.data = {
                        homepage: METADATA.wagerTypes[prefObj.bet_type_quick].Name,
                        raceprogram: METADATA.wagerTypes[prefObj.bet_type].Name,
                        betamount: prefObj.bet_amount,
                        showracesby: prefObj.home_show_races_by,
                        sortracesby: prefObj.home_sort_runners_by,
                        confirmbetrequest: prefObj.show_bet_confirm == 1,
                        displaybalance: prefObj.balance_is_shown == 1
                    };
                } else {
                    $scope.data = {
                        homepage: "",
                        raceprogram: "",
                        betamount: "",
                        showracesby: "",
                        sortracesby: "",
                        confirmbetrequest: prefObj.show_bet_confirm == 1,
                        displaybalance: prefObj.balance_is_shown == 1
                    };
                }

                try {
                    var videoObj = JSON.parse($filter('CMSValue')('globalHelpTutorialConfiguration'));

                    if ($rootScope.layout != "desktop" && $scope.$root.activeFeatures.helpTutorial) {
                        $scope.data.showHelpOption = videoObj.showHelp;
                    } else {
                        $scope.data.showHelpOption = false;
                    }
                } catch (e) {
                    //console.log('[WARNING] Failed to load help video tutorial list');
                }

                function getHomePageBetTypes(obj) {
                    var myArray = [];
                    for (var bet in obj) {
                        if (obj[bet].Name.indexOf('Win') != -1 || obj[bet].Name.indexOf('Place') != -1 || obj[bet].Name.indexOf('Show') != -1) {
                            if (obj[bet].Name.indexOf('Pick') == -1) {
                                myArray.push(obj[bet]);
                            }
                        }
                    }

                    return myArray;
                }

                function sortWagerTypes(obj) {
                    var myArray = [];
                    for (var bet in obj) {
                        if(obj.hasOwnProperty(bet)) {
                            myArray.push(obj[bet]);
                        }
                    }
                    myArray.sort(function (a, b) {
                        return parseInt(a.Id, 10) - parseInt(b.Id, 10);
                    });
                    return myArray;
                }

                function getContactUsTypes() {
                    return ["By Email", "By Phone"];
                }

                function _goto(name) {
                    switch (name) {
                        case "Deposit":
                            $scope.push("js/desktop/modules/Account/templates/account-deposit.html", {title: "Deposit"});
                            break;
                        case "Withdraw":
                            $scope.push("js/desktop/modules/Account/templates/account-withdraw.html", {title: "Withdraw"});
                            break;
                        case "Withdrawal Cancellation":
                            $scope.push("js/desktop/modules/Account/templates/account-cancel-withdraw.html", {title: "Withdrawal Cancellation"});
                            break;
                        case "Transfer":
                            $scope.push("js/desktop/modules/Account/templates/account-transfer.html", {title: "Transfer"});
                            break;
                        case "ManagePaymentMethods":
                            $scope.push("js/desktop/modules/Account/templates/account-managePaymentMethods.html", {title: "Manage Payment Methods"});
                            break;
                        case "Change Pin":
                            $scope.push("js/desktop/modules/Account/templates/account-change-pin.html", {title: "Change Pin"});
                            break;
                        case "Change Password":
                            $scope.push("js/desktop/modules/Account/templates/account-change-password.html", {title: "Change Password"});
                            break;
                        case "Add Username":
                            $scope.push("js/desktop/modules/Account/templates/account-add-username.html", {title: "Add Username"});
                            break;
                        case "Quick Bet":
                            $scope.push("js/desktop/modules/Account/templates/account-options-list.html", {title: "Bet Type - Quick Bet", Objs: getHomePageBetTypes(METADATA.wagerTypes)});
                            break;
                        case "Race Program Page":
                            $scope.push("js/desktop/modules/Account/templates/account-options-list.html", {title: "Bet Type - Race Program", Objs: sortWagerTypes(METADATA.wagerTypes)});
                            break;
                        case "Bet Amount":
                            $scope.push("js/desktop/modules/Account/templates/account-options-list.html", {title: "Predefined Bet Amount", Objs: PreferencesCookieFac.getBetAmounts()});
                            break;
                        case "Show Races":
                            $scope.push("js/desktop/modules/Account/templates/account-options-list.html", {title: "Show Races By", Objs: PreferencesCookieFac.getShowRacesBy()});
                            break;
                        case "Sort Races":
                            $scope.push("js/desktop/modules/Account/templates/account-options-list.html", {title: "Sort Races By", Objs: PreferencesCookieFac.getSortRacesBy()});
                            break;
                        case "PANEL_HELP_OVERLAYS":
                            break;
                        case "Contact Us":
                            $scope.push("js/desktop/modules/Account/templates/account-options-list.html", {title: "Contact Us", Objs: getContactUsTypes()});
                            break;
                        case "Privacy Policy":
                            $scope.push("js/desktop/modules/Account/templates/account-privacy-policy.html", {title: "Privacy Policy"});
                            break;
                        case "Terms and Conditions":
                            $scope.push("js/desktop/modules/Account/templates/account-terms-condition.html", {title: "Terms and Conditions"});
                            break;
                        case "Account History":
                            $scope.push("js/desktop/modules/Account/templates/account-history.html", {title: "Account History"});
                            break;
                        case "My Bets":
                            $scope.push("js/desktop/modules/MyBets/templates/my-bets.html", {title: "My Bets"});
                            break;
                        case "My Account Summary":
                            $scope.push("js/desktop/modules/AccountSummary/templates/account-summary.html", {title: "My Account Summary"});
                            break;

                        case "Help":
                            $rootScope.$broadcast('openHelp');
                            break;

                        case "User Profile":
                            $scope.push("js/desktop/modules/Account/templates/account-user-profile.html", {title: "User Profile"});
                            break;

                        default:
                            break;
                    }
                }

                function _setDisplayBalance(value) {
                    var prefObj = PreferencesCookieFac.getPreferences();
                    prefObj.balance_is_shown = (value == true) ? 1 : 0;

                    PreferencesCookieFac.updatePreferences(prefObj);

                    var req = value ? 'On' : 'Off';
                    var p = {};
                    p.gaEventCategory = 'Account';
                    p.gaEventAction = 'Preferences';
                    p.gaEventLabel = 'Display Balance ' + req;
                    p.sectionName = 'My account';

                    GTMFac.GTMEvent().send($scope, 'displayBalance', p);

                    $scope.data.displaybalance = value;
                }

                function _setRequestBetConfirmation(value) {
                    var prefObj = PreferencesCookieFac.getPreferences();

                    prefObj.show_bet_confirm = (value == true) ? 1 : 0;

                    PreferencesCookieFac.updatePreferences(prefObj);

                    var req = value ? 'On' : 'Off';
                    var p = {};
                    p.gaEventCategory = 'Account';
                    p.gaEventAction = 'Preferences';
                    p.gaEventLabel = 'Request Bet Confirmation ' + req;
                    p.sectionName = 'My account';

                    GTMFac.GTMEvent().send($scope, 'requestBetConfirmation', p);

                    $scope.data.confirmbetrequest = value;
                }

                function _logoutPressed() {
                    SecuritySvc.logout().then(function() {
                        $scope.$close();
                        $location.path("/").hash('');
                    });
                }

            }

            AccountCtrl.$inject = [
                '$scope',
                '$rootScope',
                'SecuritySvc',
                'METADATA',
                'PreferencesCookieFac',
                '$filter',
                '$location',
                'GTMFac'
            ];

            return AccountCtrl;
        }
);

