'use strict';

define(
        'AccountBettingPrefsCtrl',[
            'PreferencesCookieFac'
        ],
        function () {

            function AccountBettingPrefsCtrl($scope, $rootScope, $uibModal, METADATA, PreferencesCookieFac, $location, $filter, $timeout, GTMFac, FindModalContainerFac) {
                $scope.events = {
                    setHomePageBetType: _setHomePageBetType,
                    setRaceProgramPageBetType: _setRaceProgramPageBetType,
                    setBetAmount: _setBetAmount,
                    setShowRacesBy: _setShowRacesBy,
                    setSortRacesBy: _setSortRacesBy,
                    selectedContactUs: _selectedContactUs,
                    cancel: _cancel,
                    closeInfo: _closeInfo
                };

                var prefObj = PreferencesCookieFac.getPreferences();

                $scope.data = {
                    homepage: METADATA.wagerTypes[prefObj.bet_type_quick].Name,
                    raceprogram: METADATA.wagerTypes[prefObj.bet_type].Name,
                    betamount: prefObj.bet_amount,
                    showracesby: prefObj.home_show_races_by,
                    sortracesby: prefObj.home_sort_runners_by,
                    confirmDialog: null,
                    mainDepositSuccessData: null,
                    mainDepositErrorData: null,
                    mainWithdrawSuccessData: null,
                    mainWithdrawErrorData: null,
                    wagerTypesObj: METADATA.wagerTypes
                };

                $scope.$on('onDepositSuccess', function (event, data) {
                    $scope.mainDepositErrorData = null;
                    $rootScope.$broadcast("accountBalance_update");

                    if (data && data.notification) {
                        data.notification();
                    }
                });

                $scope.$on('onDepositError', function (event, data) {
                    $rootScope.$broadcast("scroll_reset_needed");
                    $scope.mainDepositSuccessData = null;

                    if (data && data.notification) {
                        data.notification();
                    }
                });

                $scope.$on('onDepositCancelled', function (event, errorMessageObj) {
                    if ($scope.notify) {
                        $scope.notify("cancel", errorMessageObj, true);
                    }
                });

                $scope.$on('depositFormValidationError', function () {
                    if ($scope.notify) {

                        $scope.notify("error", {
                            title: "This form contains errors.",
                            text: "Please fix all errors before continuing."
                        }, true);
                    }
                });

                $scope.$on("onWithdrawalCancelError", function () {
                    $rootScope.refreshListPendingWithdraw();
                });

                $scope.$on("onWithdrawalCancelSuccess", function () {
                    $rootScope.refreshListPendingWithdraw();
                    $rootScope.$broadcast("accountBalance_update");
                });

                $scope.$on('onDepositEventSuccess', function () {
                    $rootScope.refreshListPendingWithdraw();
                });

                $scope.$on('onWithdrawalError', function (event, withdrawalData) {
                    if ($scope.notify) {
                        $scope.notify("error", withdrawalData, true);
                    }
                    $rootScope.$broadcast("scroll_reset_needed");
                    $scope.mainWithdrawSuccessData = null;
                    $scope.mainWithdrawErrorData = withdrawalData;
                });

                $scope.$on("more_info", function (event, cmsKey) {
                    $scope.push("js/desktop/modules/Account/templates/more-info.html", {title: "More Info", Objs: {moreInfoCmsKey: cmsKey}});
                });

                $scope.$on("logout", function () {
                    $scope.$close();
                });

                function _setHomePageBetType(id) {
                    var prefObj = PreferencesCookieFac.getPreferences();
                    prefObj.bet_type_quick = id;
                    PreferencesCookieFac.updatePreferences(prefObj);

                    var p = {};
                    p.gaEventCategory = 'Account';
                    p.gaEventAction = 'Preferences';
                    p.gaEventLabel = 'Bet Type | Home | ' + METADATA.wagerTypes[id].Abbreviation;
                    p.sectionName = 'My account';

                    GTMFac.GTMEvent().send($scope, 'betType', p);

                    $scope.data.homepage = id;
                    $scope.pop();
                }

                function _setRaceProgramPageBetType(id) {
                    var prefObj = PreferencesCookieFac.getPreferences();
                    prefObj.bet_type = id;
                    PreferencesCookieFac.updatePreferences(prefObj);

                    var p = {};
                    p.gaEventCategory = 'Account';
                    p.gaEventAction = 'Preferences';
                    p.gaEventLabel = 'Bet Type | Race Program | ' + METADATA.wagerTypes[id].Abbreviation;
                    p.sectionName = 'My account';

                    GTMFac.GTMEvent().send($scope, 'betType', p);

                    $scope.data.raceprogram = id;
                    $scope.pop();
                }

                function _setBetAmount(value) {
                    var prefObj = PreferencesCookieFac.getPreferences();
                    prefObj.bet_amount = value;
                    PreferencesCookieFac.updatePreferences(prefObj);

                    var p = {};
                    p.gaEventCategory = 'Account';
                    p.gaEventAction = 'Preferences';
                    p.gaEventLabel = 'Change Bet Amount';

                    GTMFac.GTMEvent().send($scope, 'BetAmount', p);

                    $scope.data.betamount = value;
                    $scope.pop();
                }

                function _setShowRacesBy(value) {
                    var prefObj = PreferencesCookieFac.getPreferences();
                    prefObj.home_show_races_by = value.toLowerCase();
                    PreferencesCookieFac.updatePreferences(prefObj);

                    var p = {};
                    p.gaEventCategory = 'Account';
                    p.gaEventAction = 'Preferences';
                    p.gaEventLabel = 'Show Races | ' + value;
                    p.sectionName = 'My Account';
                    p.eventLabel = 'ShowRaces';

                    GTMFac.GTMEvent().send($scope, 'homeDisplay', p);

                    $scope.data.showracesby = value.toLowerCase();
                    $scope.pop();
                }

                function _setSortRacesBy(value) {
                    var prefObj = PreferencesCookieFac.getPreferences();
                    prefObj.home_sort_runners_by = value.toLowerCase();
                    PreferencesCookieFac.updatePreferences(prefObj);

                    var p = {};
                    p.gaEventCategory = 'Account';
                    p.gaEventAction = 'Preferences';
                    p.gaEventLabel = 'Sort Races | ' + value;
                    p.sectionName = 'My Account';
                    p.eventLabel = 'SortRaces';

                    GTMFac.GTMEvent().send($scope, 'homeDisplay', p);

                    $scope.data.sortracesby = value.toLowerCase();
                    $scope.pop();
                }

                function _selectedContactUs(value) {
                    if (value == "By Email") {
                        _showMessage(value, $filter('CMSValue')('contactUsByEmail'));
                    } else {
                        _showMessage(value, $filter('CMSValue')('contactUsByPhone'));
                    }
                }

                function _showMessage(title, message) {

                    $scope.data.title = title;
                    $scope.data.message = message;

                    $scope.data.confirmDialog = $uibModal.open({
                        appendTo: FindModalContainerFac('#angular-app'),
                        backdropClass: 'tvgmodal-backdrop',
                        windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                        windowClass: 'login-error',
                        templateUrl: 'js/desktop/modules/Account/templates/contact_us_message.html',
                        scope: $scope
                    });
                }

                function _cancel() {

                    if ($scope.data.confirmDialog) {
                        $scope.data.confirmDialog.close();
                        $scope.data.confirmDialog = null;
                    }

                    $timeout(function () {
                        $scope.data.errorMessage = null;
                    }, 300);

                    $scope.pop();
                }

                function _closeInfo() {

                    if ($scope.data.confirmDialog) {
                        $scope.data.confirmDialog.close();
                        $scope.data.confirmDialog = null;
                    }
                }
            }

            AccountBettingPrefsCtrl.$inject = [
                '$scope',
                '$rootScope',
                '$uibModal',
                'METADATA',
                'PreferencesCookieFac',
                '$location',
                '$filter',
                '$timeout',
                'GTMFac',
                'FindModalContainerFac'
            ];

            return AccountBettingPrefsCtrl;
        }
);

