'use strict';

define(
	'BetNow',[
		'BetNowCtrl'
	],

	function (BetNowCtrl) {
		angular.module('TVG.BetNow', [])
			.controller('BetNowCtrl', BetNowCtrl)
			.directive('betNow', function () {
				return {
					restrict: 'E',
					templateUrl: 'js/desktop/modules/BetNow/templates/bet-now.html'
				};
			});
	}
);

