'use strict';

define(
    'DatePicker',[
        'DatePickerDir'
    ],
    function (DatePickerDir) {

        angular.module('TVG.DatePicker', ['ui.bootstrap'])
            .directive('tvgDatepicker', DatePickerDir)
            .config(['$provide', Decorate]);

            function Decorate($provide) {
                _decorateDirectiveTemplate($provide, 'uibDaypickerDirective', 'js/desktop/modules/WidgetsDesktop/components/datePicker/templates/daypicker-override-template.html');
               _decorateDirectiveTemplate($provide, 'uibDatepickerPopupWrapDirective', 'js/desktop/modules/WidgetsDesktop/components/datePicker/templates/popup-override-template.html');
            }

            function _decorateDirectiveTemplate($provide, directiveName, directiveTemplateUrl) {
                $provide.decorator(directiveName, function($delegate) {
                    var directive = $delegate[0];
                    directive.templateUrl = directiveTemplateUrl;

                    return $delegate;
                });
            }

    }
);

