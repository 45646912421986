'use strict';

define('sortTracksAlphabetically',[
        'sortTracksAlphabetically'
    ],
    function () {

        function _toArray(input) {

           return Object.keys(input || {}).sort().map(function(key){
              return input[key];
           });

        }

        return function() {
            return _toArray;
        };
    });

