'use strict';

define('RoundAmountFilter',[],
    function () {
        function RoundAmountFilter(AmountOptionsFac) {
            return function(amount) {
                return AmountOptionsFac.roundAmountToTwoDecimalPlaces(amount);
            };
        }

        RoundAmountFilter.$inject = [
            'AmountOptionsFac'
        ];

        return RoundAmountFilter;
    }
);

