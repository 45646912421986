'use strict';

define('EquibaseTrackPastPerformanceButtonCtrl',['moment'], function (moment) {
    function EquibaseTrackPastPerformanceButtonCtrl($scope,
                                                    $rootScope,
                                                    $uibModal,
                                                    HandicappingStoreFac) {
        var vm = this;

        vm.isLoginRequired = $rootScope.activeFeatures.loginRequiredOnEquibaseTrackPastPerformanceButton || false;
        vm.isModalPendingLogin = false;
        vm.events = {
            launchEquibaseModal: _launchEquibaseModal,
            isTrackUSA: _isTrackUSA
        };
        vm.sendGTMEvent = _sendGTMEvent;

        var EQUIBASE_LP_URL = 'https://tvg.equibase.com/newsite2/tvgHCLandingPage.cfm?' +
                'rurl=https://tvg.equibase.com/newsite2/tvgChooseProductsByTrack.cfm' +
                '&QS={QS}&' +
                'acct={ACCT}&' +
                'uid={UID}',
            EQUIBASE_PP_PARAM = 'track_id={TRACK-ID}' +
                '&racedate={RACEDATE}' +
                '&track_type={TRACK-TYPE}' +
                '&fType=f';

        vm.userParam = { acct: 'BYPASS', uid: 'BYPASS' };

        function _getTVGUserParams() {
            var accountNumber = 'BYPASS';
            var accountToken = 'BYPASS';

            HandicappingStoreFac.getHandicappingStoreUrl().then(function (data) {
                var matches = {
                    an: data.match(/acct=(.*)&/),
                    at: data.match(/&uid=(.*)/)
                };

                accountNumber = matches.an ? matches.an[1] : 'BYPASS';
                accountToken = matches.at ? matches.at[1] : 'BYPASS';
            }).finally(function () {
                vm.userParam = {'acct': accountNumber, uid: accountToken};
            });
        }

        function _launchEquibaseModal() {
            if (vm.isLoginRequired && !$rootScope.userSession) {
                $rootScope.$emit("callLoginRequiredModal");
                vm.isModalPendingLogin = true;
            }
            else {
                $scope.data = {
                    title: 'Equibase Store',
                    iframeurl: EQUIBASE_LP_URL
                        .replace('{QS}', encodeURIComponent(EQUIBASE_PP_PARAM.replace('{TRACK-ID}', vm.trackAbbr)
                            .replace('{RACEDATE}', moment(vm.raceDate).format('MM/DD/YYYY'))
                            .replace('{TRACK-TYPE}', vm.raceType === 'Harness' ? 'HA' : 'TB')))
                        .replace('{ACCT}', vm.userParam.acct)
                        .replace('{UID}', vm.userParam.uid)
                };

                $uibModal.open({
                    windowTemplateUrl: 'js/desktop/modules/Races/templates/desktop/default-modal-window.html',
                    templateUrl: 'js/desktop/modules/EquibaseUtils/templates/equibase-iframe-modal.html',
                    windowClass: 'track-equibase-modal',
                    scope: $scope
                });

                vm.sendGTMEvent();
            }
        }

        $rootScope.$on('user', function () {
            _getTVGUserParams();
            if (vm.isModalPendingLogin) {
                vm.isModalPendingLogin = false;
                vm.events.launchEquibaseModal();
            }
        });

        function _isTrackUSA() {
            return vm.trackCountry === 'USA';
        }

        function _sendGTMEvent() {
            // GTM EVENT
            window.parent.dataLayer.push({
                'event': 'programPage',
                'gaEventCategory': 'Program Page',
                'gaEventAction': 'Open',
                'gaEventLabel': 'Purchase PP\'s',
                'module': 'Race Card Header',
                'microApp': 'Program Page'
            });
        }

        (function init() {
            _getTVGUserParams();
        }());
    }

    EquibaseTrackPastPerformanceButtonCtrl.$inject = [
        '$scope',
        '$rootScope',
        '$uibModal',
        'HandicappingStoreFac'
    ];

    return EquibaseTrackPastPerformanceButtonCtrl;
});

