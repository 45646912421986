"use strict";

define('RaceResultsEntity',[],function () {

    /***
     *
     * @param runners
     * @param payoffs
     * @param trackAbbr
     * @param perfAbbr
     * @param raceNumber
     * @param raceDate
     * @param hasReplays
     * @param onTvg
     * @param onTvg2
     * @constructor
     */
    function RaceResults(runners, payoffs, trackAbbr, perfAbbr, raceNumber, raceDate, hasReplays, onTvg, onTvg2){
        this.trackAbbr = trackAbbr;
        this.perfAbbr = perfAbbr;
        this.raceNumber = raceNumber;
        this.raceDate = raceDate;
        this.runners = runners;
        this.payoffs = payoffs;
        this.hasReplays = hasReplays;
        this.onTvg = onTvg;
        this.onTvg2 = onTvg2;
    }

    return RaceResults;

});


