"use strict";

define('MyBetsDataTransformer',['GenericMessage'], function (GenericMessage) {

    /**
     * @private
     * @description
     * If the bet type is "Key", "Box" or "Key Box", the first value of the "runnerList" holds a
     * String representing this information, e.g., 'KY,2'.
     * This value is to be in the bet type name and not in the selection.
     * We've to pay attention not to remove the number of the horse that is Key and/or Box.
     *
     * @param {Array} selectionArray
     * @param {Object} bet
     */
    function _checkIfBetIsKeyAndOrBox(selectionArray, bet) {
        var keyBox = selectionArray[0].split(',')[0];
        if (keyBox && isNaN(Number(keyBox))) {
            // Remove this 'String' from the selection.
            selectionArray[0] = selectionArray[0].slice(selectionArray[0].indexOf(',') + 1);

            bet.isKeyOrBox = true;
            bet.betType += keyBox;

            if (keyBox.indexOf('KY') !== -1) {
                bet.betTypeName += ' Key'; // Tooltip
            }
            if (keyBox.indexOf('BX') !== -1) {
                bet.betTypeName += ' Box'; // Tooltip
            }
        }
    }



    function _toModel(wagerTypes) {
        return function(myBetRowData) {
            // The type of the value of the property 'raceNumber' is String.
            // We need a type Number to guarantee that we show the correct
            // race number in the 'pick' bets, e.g. daily double, pick 3, etc ...
            myBetRowData.integerRaceNumber = Number(myBetRowData.raceNumber) || 0;

            // Get the wager type name.
            Object.getOwnPropertyNames(wagerTypes).some(function (prop) {
                var currentProp = wagerTypes[prop];
                if (currentProp.Abbreviation === myBetRowData.wagerTypeId) {
                    myBetRowData.betTypeName = currentProp.Name; // Tooltip
                    myBetRowData.betType = currentProp.Abbreviation;
                    // The selection is prefixed with 'L:' if the column type is 'Leg'.
                    // The selection is prefixed with the ordinal number if the column type is
                    // 'Pos' like Trifecta, e.g., 1st, 2nd, 3rd.
                    myBetRowData.columnType = currentProp.ColumnType;
                    return true;
                }
                return false;
            });

            myBetRowData.selection = myBetRowData.runnerList.split(' / ');

            _checkIfBetIsKeyAndOrBox(myBetRowData.selection, myBetRowData);

            angular.extend(myBetRowData, {
                cancelBet: new GenericMessage(),
                showCancelForm: false,
                isCanceling: false
            });

            return myBetRowData;
        };
    }

    return {
        toModel: _toModel
    };

});

