'use strict';

define('UserProfileSvc',[ 'lodash', 'Mediator' ], function (_, mediator) {

    function UserProfileSvc(
        $http,
        $q,
        $rootScope,
        poller,
        $filter,
        ConfigurationFac,
        PreferencesCookieFac
    ) {

        var balancePoller = null;

        var BalanceFac = {
            pollBalance: function (forceUpdate) {

                if(!balancePoller || forceUpdate) {
                    balancePoller = poller.get(BalanceFac, {
                        action: 'getAccountBalance',
                        delay: 120000,
                        argumentsArray: [
                            {}
                        ]
                    });
                }

                return balancePoller;
            },

            stopPollingBalance: function (deferred) {
                if (balancePoller) {
                    balancePoller.removeDeferred(deferred);
                    balancePoller = null;
                }
            },

            getAccountBalance: function (user) {
                var deferred = $q.defer();

                if(!user || !user.accountNumber) {
                    deferred.resolve({balance: 0});
                }

                var url = ConfigurationFac.getBaseServiceUrl() + '/uwt/v1/users/'+ user.accountNumber +'/balance';

                $http.get(url, {
                    headers: {
                        'Cache-Control': 'no-cache',
                        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
                        'Pragma': 'no-cache'
                    }
                }).success(function (response) {
                    deferred.resolve(response);
                }).error(function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            },

            getVideoCountInfo: function () {
                var deferred = $q.defer();
                var url = '/ajax/video/id/user';

                $http.get(url).success(function (response) {
                    deferred.resolve(response);
                }).error(function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            },

            updateAccountBalance: function() {
                var deferred = $q.defer();

                this.getAccountBalance($rootScope.user).then(function (response) {

                    if ((response && response.balance === 0) || (response && response.balance && !isNaN(response.balance))) {
                        $rootScope.accountBalanceNumber = response.balance;
                        $rootScope.accountBalance = $filter("currency")(response.balance, "$");
                        $rootScope.$broadcast("accountBalance_changed", response.balance);

                        // meidator event dispatch for outer react components
                        mediator.dispatch('ACCOUNT_BALANCE_CHANGED', {balance: response.balance});
                    }

                    deferred.resolve(response);
                }, function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }
        };

        var CommunicationsPreferencesFac = {
            _getApiUrl: function (contextName, serviceEndpoint) {
                return "/" + contextName + "/api/v1.0/" + serviceEndpoint;
            },

            _getCommunicationsPreferences: function() {
                var url = ConfigurationFac.getBaseServiceUrl() + '/uam/v1/users/' + $rootScope.user.accountNumber + "/subscriptions" ;
                var deferred = $q.defer();
                var context = JSON.stringify(ConfigurationFac.getApplicationContext());

                var handleSuccessResponse = function (response) {
                    deferred.resolve(response.data);
                };

                $http.get(url, {
                        headers: {
                            'x-tvgcontext': context
                        }
                    }
                ).then(handleSuccessResponse, function (e) {
                    deferred.reject(e.data);
                });

                return deferred.promise;
            },

            _getSubscriptions: function() {
                var deferred = $q.defer();
                var url = [ConfigurationFac.getServiceApiUrl('uam'), '/subscriptions/email'].join('');

                $http.get(url, {})
                    .success(function (response) {
                        deferred.resolve(response);
                    })
                    .error(function (response) {
                        deferred.reject(response);
                    });

                return deferred.promise;
            },

            _setSubscriptions: function (email, postData) {
                var deferred = $q.defer();
                var url = [ConfigurationFac.getServiceApiUrl('uam'), '/subscriptions/email/', email].join('');

                $http.put(url, postData, {})
                    .success(function (response) {
                        deferred.resolve(response);
                    })
                    .error(function (response) {
                        deferred.reject(response);
                    });

                return deferred.promise;
            },

            _changeCommunicationsPreferences: function(emailAddress, item) {
                var url = ConfigurationFac.getBaseServiceUrl() + '/uam/v1/users/' + $rootScope.user.accountNumber + "/subscriptions" ;
                var deferred = $q.defer();
                var context = JSON.stringify(ConfigurationFac.getApplicationContext());

                $http.put(url, item, {
                        headers: {
                            'x-tvgcontext': context
                        }
                    }
                ).then(function (response) {
                    if (response.data) {
                        deferred.resolve(response.data);
                    } else {
                        deferred.reject(response.data);
                    }
                }, function (e) {
                    deferred.reject(e.data);
                });

                return deferred.promise;
            }
        };

        var userPreferencesFac = {
            setRequestBetConfirmation: function (isConfirmed) {
                var deferred = $q.defer();

                if (_.isBoolean(isConfirmed) === false) {
                    deferred.reject('Error: isConfirmed must be a boolean value.');
                } else {
                    var prefObj = PreferencesCookieFac.getPreferences();
                    prefObj.show_bet_confirm = (isConfirmed == true) ? 1 : 0;
                    PreferencesCookieFac.updatePreferences(prefObj)
                        .then(function(isConfirmed) {
                            deferred.resolve(isConfirmed);
                        }, function() {
                            deferred.reject('Error: failed to updatePreferences.');
                        });
                }

                return deferred.promise;
            },

            getUserPreferences: function() {
                var url = ConfigurationFac.getBaseServiceUrl() + '/uam/v1/users/' + $rootScope.user.accountNumber + "/preferences" ;
                var deferred = $q.defer();
                var context = JSON.stringify(ConfigurationFac.getApplicationContext());

                var handleSuccessResponse = function (response) {
                    deferred.resolve(response.data);
                };

                $http.get(url, {
                        headers: {
                            'x-tvgcontext': context
                        }
                    }
                ).then(handleSuccessResponse, function (e) {
                    deferred.reject(e.data);
                });

                return deferred.promise;
            }
        };

        return {
            pollBalance: BalanceFac.pollBalance,
            stopPollingBalance: BalanceFac.stopPollingBalance,
            getAccountBalance: BalanceFac.getAccountBalance,
            getVideoCountInfo: BalanceFac.getVideoCountInfo,
            updateAccountBalance: BalanceFac.updateAccountBalance,
            getCommunicationsPreferences: CommunicationsPreferencesFac._getCommunicationsPreferences,
            changeCommunicationsPreferences: CommunicationsPreferencesFac._changeCommunicationsPreferences,
            setRequestBetConfirmationPreference: userPreferencesFac.setRequestBetConfirmation,
            getUserPreferences: userPreferencesFac.getUserPreferences,
            getSubscriptions: CommunicationsPreferencesFac._getSubscriptions,
            setSubscriptions: CommunicationsPreferencesFac._setSubscriptions
            
        };
    }

    UserProfileSvc.$inject = [
        '$http',
        '$q',
        '$rootScope',
        'poller',
        '$filter',
        'ConfigurationFac',
        'PreferencesCookieFac'
    ];

    return UserProfileSvc;
});

