'use strict';

define('DistanceConverterFac',[],
    function () {
        function DistanceConverterFac() {

            function _convertDistance(distance) {

                if (!distance) {
                    return '';
                }

                var distanceUnits = distance.replace(/[\d\.]/g, '');
                var distanceValue = distance.replace(/[^\d\.]/g, '');
                
                distanceValue = distanceValue > 90 ? ~~distanceValue : distanceValue;

                switch(distanceUnits) {
                    case 'y':
                        return distanceValue + ' Yards';
                    case 'f':
                        return distanceValue + ' Furlongs';
                    case 'mtr':
                        return distanceValue + ' Meters';
                    default:
                        return distanceValue + ' Mile';
                }
            }

            return {
                convertDistance: _convertDistance
            };
        }

        return DistanceConverterFac;
    });

