'use strict';

define(
    'AccountPanelCtrl',['SecuritySvc'],
    function () {

        function AccountPanelCtrl($scope, $rootScope, SecuritySvc, $location, PreferencesCookieFac, $route, GTMFac, RaceUrlFac, METADATA) {

            var prefObj = PreferencesCookieFac.getPreferences();
            var shouldShowBalance = prefObj.balance_is_shown || false;
            var routeParams = RaceUrlFac.getRacesInfoFromRouteParams();

            $scope.events = {
                logoutPressed: _logoutPressed,
                switchShowBalance: _switchShowBalance,
                changeView: _changeView
            };

            $scope.showBalance = shouldShowBalance;
            $scope.hasValidAccountBalance = _hasValidAccountBalance;
            $scope.isLoading = false;

            $scope.$watch("isLoading", function (value) {
                $scope.$emit('loadingChanged', value);
            });

            $scope.$on('preferencesUpdated', function (event, data) {
                prefObj = data;
                $scope.showBalance = prefObj.balance_is_shown || false;
            });

            function _logoutPressed() {
                $scope.isLoading = true;
                SecuritySvc.logout().then(function () {
                    $scope.isLoading = false;
                    $location.path("/").hash('');
                }, function () {
                    $scope.isLoading = false;
                });
            }

            function _switchShowBalance() {
                routeParams = RaceUrlFac.getRacesInfoFromRouteParams();
                $scope.showBalance = !$scope.showBalance;
                prefObj.balance_is_shown = $scope.showBalance ? 1 : 0;
                PreferencesCookieFac.updatePreferences(prefObj).then(function () {
                    var req = $scope.showBalance ? 'Show My Balance' : 'Hide My Balance';
                    var p = {};
                    p.gaEventCategory = 'Site Click';
                    p.gaEventAction = GTMFac.Tvg4ScreenName() + ' Click';
                    p.gaEventLabel = 'Header | My Account | ' + req;
                    p.screenName = GTMFac.Tvg4ScreenName();
                    p.sectionName = GTMFac.Tvg4SectionName();
                    p.sectionName = "Header-MyAccountDropDown";

                    if (routeParams.trackAbbr && routeParams.raceNumber && METADATA.hasOwnProperty('allTracks') && METADATA.allTracks.hasOwnProperty(routeParams.trackAbbr) && METADATA.allTracks[routeParams.trackAbbr].Name) {
                        p.trackName = METADATA.allTracks[routeParams.trackAbbr].Name;
                        p.raceNumber = "R" + routeParams.raceNumber;
                    }

                    GTMFac.GTMEvent().send($rootScope, 'displayBalance', p);
                });
            }

            function _changeView(destination) {
                if ($location.path().indexOf(destination) < 0) {
                    sendGTMMyAccountDropDownEvent(destination);
                    $location.url('/'+destination);
                }
            }

            function _hasValidAccountBalance() {
                return typeof $rootScope.accountBalanceNumber !== "undefined"
                    && $rootScope.accountBalanceNumber !== null;
            }

            function sendGTMMyAccountDropDownEvent(destination) {
                var gaEventLabelTitle;
                routeParams = RaceUrlFac.getRacesInfoFromRouteParams();

                switch (destination) {
                    case 'my-profile':
                        gaEventLabelTitle = 'My Profile';
                        break;
                    case 'my-bets':
                        gaEventLabelTitle = 'My Bets';
                        break;
                    case 'my-account-summary':
                        gaEventLabelTitle = 'My Account Summary';
                        break;
                    case 'my-funds':
                        gaEventLabelTitle = 'My Funds';
                        break;
                    case 'my-reports':
                        gaEventLabelTitle = 'My Reports';
                        break;
                    case 'my-favorite-tracks':
                        gaEventLabelTitle = 'My Favorite Tracks';
                        break;
                    case 'my-stable':
                        gaEventLabelTitle = 'My Stable';
                        break;
                    default:
                        gaEventLabelTitle = $rootScope.appTitle;
                        break;
                }

                var p = {};
                p.gaEventCategory = 'Site Click';
                p.gaEventAction = GTMFac.Tvg4ScreenName() + ' Click';
                p.gaEventLabel = 'Header | My Account | ' + gaEventLabelTitle;
                p.screenName = GTMFac.Tvg4ScreenName();
                p.sectionName = GTMFac.Tvg4SectionName();
                p.eventLabel = "Header-MyAccountDropDown";

                if (routeParams.trackAbbr && routeParams.raceNumber && METADATA.hasOwnProperty('allTracks') && METADATA.allTracks.hasOwnProperty(routeParams.trackAbbr) && METADATA.allTracks[routeParams.trackAbbr].Name) {
                    p.trackName = METADATA.allTracks[routeParams.trackAbbr].Name;
                    p.raceNumber = "R" + routeParams.raceNumber;
                }

                GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
            }
        }

        AccountPanelCtrl.$inject = [
            '$scope',
            '$rootScope',
            'SecuritySvc',
            '$location',
            'PreferencesCookieFac',
            '$route',
            'GTMFac',
            'RaceUrlFac',
            'METADATA'
        ];

        return AccountPanelCtrl;
    }
);

