"use strict";

define('RaceMTP',[
    'moment',
    'DateTimeFormatters',
    'lodash'
], function (moment, DateTimeFormatters, _) {

    function RaceMTP(racePostTime) {
        if (angular.isDate(racePostTime)) {
            this.racePostTime = racePostTime;
        } else {
            this.racePostTime = new Date(racePostTime);
        }
    }

    RaceMTP.prototype.mtpDateFormats = {
        WITH_PM_AM_AND_TIME_DAY: 1,
        WITH_PM_AM: 2
    };

    RaceMTP.prototype.getMTPDate = function (mtp) {
        var output = {
            mtp: undefined,
            date: undefined,
            timeWithDay: undefined
        };

        if (moment(this.racePostTime).isValid()) {
            if (!_.isUndefined(mtp)) {
                output.mtp = mtp;
            } else {
                var millisecondsCurrentTime = new Date().getTime();
                var millisecondsToRaceStart = this.racePostTime.getTime() - millisecondsCurrentTime;
                var minutesToRaceStart = millisecondsToRaceStart / 1000 / 60;

                if (minutesToRaceStart >= 0 && minutesToRaceStart <= 60) {
                    output.mtp = Math.round(minutesToRaceStart);
                } else if (minutesToRaceStart < 0) {
                    output.mtp = 0;
                } else {
                    // minutesToRaceStart >= 60
                    output.date = moment(this.racePostTime).format("hh:mm A");
                    output.timeWithDay = {
                        time: moment(this.racePostTime).format("hh:mm"),
                        amPmMarker: moment(this.racePostTime).format("A"),
                        day: DateTimeFormatters.getRelativeDayBasedOnGivenDate(this.racePostTime, new Date())
                    };
                }
            }
        }

        return output;
    };

    RaceMTP.prototype.getDisplayableTime = function () {
        var mtpDate = this.getMTPDate();
        return angular.isDefined(mtpDate.mtp) ? mtpDate.mtp + " MTP" : mtpDate.date;
    };

    return RaceMTP;

});



