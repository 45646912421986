"use strict";

define(
    'Features',[
        "FeaturesFac",
        "FeaturesConfigController",
        "FindModalContainerFac"
    ],
    function (FeaturesFac, FeaturesConfigController, FindModalContainerFac) {

        angular
            .module("TVG.Features", [])
            .run(["$uibModal", "$rootScope", "$templateCache", function ($uibModal, $rootScope, $templateCache) {
                function shouldShowFeaturesConfigModal(){
                    return window.location.href.indexOf("?features") >= 0;
                }

                function showFeaturesConfigModal(){
                    $uibModal.open({
                        appendTo: FindModalContainerFac()('#angular-app'),
                        backdrop: 'static',
                        windowTemplateUrl: 'src/templates/features-modal-window.html',
                        templateUrl: 'js/common/modules/Features/templates/feature-overrides-panel.html',
                        controller: 'FeaturesConfigController'
                    });
                }

                function init(){
                    // TODO - the following code couples this module to the FeaturesHandlerSvc with bi-directional inter dependency, this should be fixed
                    $rootScope.$watch('featureOverridesEnabled', function (featureOverridesEnabled) {
                        if(featureOverridesEnabled && shouldShowFeaturesConfigModal()){
                            addFeaturesConfigModalWindowTemplateToTemplateCache();
                            showFeaturesConfigModal();
                        }
                    });
                }

                init();

                function addFeaturesConfigModalWindowTemplateToTemplateCache(){
                    $templateCache.put('src/templates/features-modal-window.html',
                        "<div tabindex=\"-1\" class=\"modal features-config-modal no-click-feedback\" ng-style=\"{'z-index': 1050 + index*10, display: 'block'}\" ng-click=\"$close($event)\"><div class=\"modal-dialog\"><div class=\"modal-content\" ng-transclude ></div></div></div>"
                    );
                }

            }])
            .factory("FeaturesFac", FeaturesFac)
            .controller("FeaturesConfigController", FeaturesConfigController);

    }
);



