'use strict';

define(
    'RaceResultsGateway',[

        'RaceResultsEntityBuilder', 'RaceResultsRunnerEntityBuilder',
        'RaceResultsPayoffEntityBuilder', 'RaceRunnerEntityBuilder',
        'RaceInfoEntityBuilder', 'RaceStatusEntityBuilder', 'lodash'

    ],
    function (RaceResultsEntityBuilder, RaceResultsRunnerEntityBuilder,
              RaceResultsPayoffEntityBuilder, RaceRunnerEntityBuilder,
              RaceInfoEntityBuilder, RaceStatusEntityBuilder, _) {

        function RaceResultsGateway($http, $q, METADATA, CalculatePurseFac, RaceInfoFac) {

            function calculateDistance(distance) {
                var distanceResult = distance;
                if (distance) {
                    if (!distance.match(/[myf]/g)) {
                        distanceResult = distance + "m";
                    } else if (distance.match(/[m]/g)) {
                        distanceResult = distance + "ts";
                    }
                }
                return distanceResult;
            }

            function _buildRaceStatusEntityList(list) {
                return function (race) {
                    var raceInfoEntity = RaceStatusEntityBuilder()
                        .withRaceTypeId(race.RaceTypeID)
                        .withRaceNumber(race.RaceNumber)
                        .withStatus(race.RaceStatus)
                        .withPostTime(Date(race.PostTime))
                        .build();

                    raceInfoEntity.setRaceOpen();

                    list.push(raceInfoEntity);
                };
            }

            function _buildRaceInfoEntity(race) {
                    var distance = calculateDistance(race.summary.dist);
                    var id = race.trackAbbr + '_' + race.perfAbbr + '_' + race.raceNumber;
                    var raceClass = METADATA.raceClasses[race.summary.raceClassId] ? METADATA.raceClasses[race.summary.raceClassId].Name : null;
                    var surfaceName = METADATA.surfaceTypes[race.summary.surfaceTypeId] ? METADATA.surfaceTypes[race.summary.surfaceTypeId].Name : "";



                var raceInfoEntity = RaceInfoEntityBuilder()
                        .withTrackAbbr(race.trackAbbr)
                        .withPerfAbbr(race.perfAbbr)
                        .withRaceNumber(race.raceNumber)
                        .withDistance(distance)
                        .withId(id)
                        .withRaceTypeId(race.raceTypeId)
                        .withPurse(race.summary.purse)
                        .withRaceClass(raceClass)
                        .withSurfaceName(surfaceName)
                        .build();

                    return raceInfoEntity;

            }

            function _buildRaceSummary(race) {
                var purse = CalculatePurseFac.calculateMinimizedPurse(race.summary);
                var surfaceName = METADATA.surfaceTypes[race.summary.surfaceTypeId] ? METADATA.surfaceTypes[race.summary.surfaceTypeId].Name : "";

                var raceInfoEntity = RaceInfoEntityBuilder()
                    .withTrackAbbr(race.trackAbbr)
                    .withDistance(race.summary.dist)
                    .withRaceTypeId(race.raceTypeId)
                    .withPurse(purse)
                    .withRaceClass(race.summary.raceClass)
                    .withSurfaceName(surfaceName)
                    .withDescription(race.summary.desc)
                    .build();

                return raceInfoEntity;
            }

            function _buildRaceResultRunnersEntitiesList(list, colors, raceTypeId) {
                return function (runner) {
                    var saddleLayout = RaceInfoFac.getSaddleLayout(colors, runner.binumber);
                    var saddleColor = '';
                    var numberColor = '';
                    if(saddleLayout && saddleLayout.saddleColor && saddleLayout.numberColor) {
                        saddleColor = saddleLayout.saddleColor;
                        numberColor = saddleLayout.numberColor;
                    } else {
                        // fix to fallback metadata colors on past results when colors are not present
                        var metadataColor = METADATA.biColors[raceTypeId || 1];
                        saddleColor = RaceInfoFac.getSaddleColor(metadataColor, runner.binumber);
                        numberColor = RaceInfoFac.getNumberColor(metadataColor, runner.binumber);
                    }
                    var runnerEntity = RaceResultsRunnerEntityBuilder()
                        .withBettingInterestNumber(runner.binumber)
                        .withFinishPosition(runner.position)
                        .withPlacePayoff(runner.place)
                        .withRunnerName(runner.horse)
                        .withSaddleColor(saddleColor)
                        .withNumberColor(numberColor)
                        .withShowPayoff(runner.show)
                        .withWinPayoff(runner.win)
                        .build();

                    list.push(runnerEntity);
                };
            }

            function _buildRaceResultPayoffsEntities(list) {
                return function (payoff) {

                    var payoffEntity = RaceResultsPayoffEntityBuilder()
                        .withPayoffText(payoff.id)
                        .build();

                    list.push(payoffEntity.text);
                };
            }

            function _buildRaceRunnersEntitiesList(list, colors) {
                return function (trackPosition) {
                    var color = colors[trackPosition.id];

                    trackPosition.runners.forEach(function(runnerInfo){
                        var raceRunnerEntity = RaceRunnerEntityBuilder()
                            .withBettingInterestNumber(runnerInfo.id)
                            .withHorse(runnerInfo.name)
                            .withOwner(runnerInfo.owner)
                            .withOdds(trackPosition.curOddsDisp)
                            .withOddsML(trackPosition.mlOddsDisp)
                            .withMed(runnerInfo.medication)
                            .withTrainer(runnerInfo.trainer)
                            .withWeight(runnerInfo.weight)
                            .withJockey(runnerInfo.jockey)
                            .withAge(runnerInfo.age)
                            .withNumberColor(color.numberColor)
                            .withSaddleColor(color.saddleColor)
                            .withDam(runnerInfo.dam)
                            .withSire(runnerInfo.sire)
                            .withDamSire(runnerInfo.damSire)
                            .withSex(runnerInfo.sex)
                            .withScratched(runnerInfo.scratched === "true" || runnerInfo.scratched === true)
                            .build();

                        list.push(raceRunnerEntity);
                    });

                };
            }

            function _buildApiUrlGetRaceResultsStatusUrl(trackAbbr, perfAbbr, date) {
                var url = "ajax/races/get/list/track/_trackAbbr_/performance/_perfAbbr_/date/_raceDate_";

                return url
                    .replace("_trackAbbr_", trackAbbr)
                    .replace("_perfAbbr_", perfAbbr)
                    .replace("_raceDate_", date);
            }

            function _buildApiUrlGetRaceResultsUrl(trackAbbr, perfAbbr, raceNumber, raceTypeId, date) {
                var url = 'ajax/race-results/track/_trackAbbr_/performance/_perfAbbr_/race/_raceNumber_/rtid/_raceTypeId_/racedate/_raceDate_';

                return url
                    .replace("_trackAbbr_", trackAbbr)
                    .replace("_perfAbbr_", perfAbbr)
                    .replace("_raceNumber_", raceNumber)
                    .replace("_raceTypeId_", raceTypeId)
                    .replace("_raceDate_", date);
            }

            function _isValidDate(dateStr) {
                var dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                return dateRegex.test(dateStr);
            }

            function _getTodaysDate() {
                return METADATA.raceDate;
            }

            return {

                /***
                 *  Makes http request to get race results and builds raceResult entity
                 * @param input
                 * @returns promise raceResultEntity
                 */
                getRaceResults: function (input) {

                    var raceDate = input.raceDate;
                    if (!_isValidDate(input.raceDate)) {
                        raceDate = _getTodaysDate();
                    }

                    var url = _buildApiUrlGetRaceResultsUrl(input.trackAbbr, input.perfAbbr, input.raceNumber, input.raceTypeId, raceDate);
                    var deferred = $q.defer();

                    $http.get(url, {cache: false}).then(function (apiResponse) {
                        var raceRawData = apiResponse.data;
                        var runners = [];
                        var payoffs = [];
                        var colors = apiResponse.data.bicolors;
                        _.forEach(raceRawData.resultrunners, _buildRaceResultRunnersEntitiesList(runners, colors, input.raceTypeId));
                        _.forEach(raceRawData.payoffs, _buildRaceResultPayoffsEntities(payoffs));

                        var raceResultEntity = RaceResultsEntityBuilder()
                            .withTrackAbbr(input.trackAbbr)
                            .withPerfAbbr(input.perfAbbr)
                            .withRaceNumber(input.raceNumber)
                            .withRaceDate(raceDate)
                            .withRunners(runners)
                            .withPayoffs(payoffs)
                            .withHasReplays(apiResponse.data.summary.replay === true || apiResponse.data.summary.replay === "true")
                            .build();

                        deferred.resolve(raceResultEntity);
                    }, function (e) {
                        deferred.reject(e);
                    });

                    return deferred.promise;
                },

                /***
                 *
                 * @param input
                 */
                getRaceRunnersList: function (input) {

                    var raceDate = input.raceDate;
                    if (!_isValidDate(input.raceDate)) {
                        raceDate = _getTodaysDate();
                    }

                    var url = _buildApiUrlGetRaceResultsUrl(input.trackAbbr, input.perfAbbr, input.raceNumber, input.raceTypeId, raceDate);
                    var deferred = $q.defer();

                    $http.get(url, {cache: true}).then(function (apiResponse) {
                        var raceRawData = apiResponse.data;
                        var raceRunnersList = [];
                        var colors = apiResponse.data.bicolors;
                        var raceSummary = _buildRaceSummary(raceRawData);

                        _.forEach(raceRawData.bettinginterests, _buildRaceRunnersEntitiesList(raceRunnersList, colors));

                        deferred.resolve({runners:raceRunnersList, raceSummary:raceSummary});
                    }, function (e) {
                        deferred.reject(e);
                    });

                    return deferred.promise;

                },

                /***
                 *
                 * @param input
                 */
                getRaceInfo: function (input) {

                    var raceDate = input.raceDate;
                    if (!_isValidDate(input.raceDate)) {
                        raceDate = _getTodaysDate();
                    }

                    var url = _buildApiUrlGetRaceResultsUrl(input.trackAbbr, input.perfAbbr, input.raceNumber, input.raceTypeId, raceDate);
                    var deferred = $q.defer();

                    $http.get(url, {cache: true}).then(function (apiResponse) {
                        var raceInfo = _buildRaceInfoEntity(apiResponse.data);
                        deferred.resolve(raceInfo);
                    }, function (e) {
                        deferred.reject(e);
                    });

                    return deferred.promise;
                },

                /***
                 *
                 * @param input
                 */
                getRaceTypeRaceStatusAndPostTime: function (input) {

                    var raceDate = input.raceDate;
                    if (!_isValidDate(input.raceDate)) {
                        raceDate = _getTodaysDate();
                    }

                    var url = _buildApiUrlGetRaceResultsStatusUrl(input.trackAbbr, input.perfAbbr, raceDate);
                    var deferred = $q.defer();

                    $http.get(url, {cache: true}).then(function (apiResponse) {
                        var raceStatusList = [];
                        _.forEach(apiResponse.data, _buildRaceStatusEntityList(raceStatusList));
                        deferred.resolve(raceStatusList);
                    }, function (e) {
                        deferred.reject(e);
                    });

                    return deferred.promise;
                }
            };

        }

        RaceResultsGateway.$inject = [
            '$http',
            '$q',
            'METADATA',
            'CalculatePurseFac',
            'RaceInfoFac'
        ];

        return RaceResultsGateway;
    }
);

