'use strict';

define(
    'Security',[
        'RegistrationContainerCtrl',
        'SecuritySvc',
        'UserSessionSvc',
        'GeoComplyLoginSvc',
        'GeoComplySvc',
        'GTMMod'
    ],

    function (RegistrationContainerCtrl, SecuritySvc, UserSessionSvc, GeoComplyLoginSvc, GeoComplySvc) {

        angular.module('TVG.Security', [
            'TVG.Common.GTM',
            'TVG.FavoriteTracks'
        ])

            .factory('SecuritySvc', SecuritySvc)
            .service('UserSessionSvc', UserSessionSvc)
            .service('GeoComplyLoginSvc', GeoComplyLoginSvc)
            .service('GeoComplySvc', GeoComplySvc)
            .controller('RegistrationContainerCtrl', RegistrationContainerCtrl)
            .constant('RequestContextHeaders', {
                tvg4_nj: 'tvg4-4njbets',
                tvg4_pa: 'tvg4-pabets',
                tvg4_all: 'tvg4-tvg',
                tvg4_ia: 'tvg4-iowa',
                touch2_nj: 'touch2-4njbets',
                touch2_pa: 'touch2-pabets',
                touch2_all: 'touch2-tvg',
                touch2_ia: 'touch2-iowa'
            });
    }
);

