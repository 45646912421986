'use strict';

define(
    'UserProfile',[
        'UserProfileSvc',
        'QuickDepositCtrl',
        'StatesSvc',
        'PasswordValidatorSvc',
        'UsernameValidatorSvc',
        'ProfileHelperSvc',
        'UserBalanceSvc',
        'UserPromotionsSvc'
    ],

    function (UserProfileSvc,
              QuickDepositCtrl,
              StatesSvc,
              PasswordValidatorSvc,
              UsernameValidatorSvc,
              ProfileHelperSvc,
              UserBalanceSvc,
              UserPromotionsSvc) {

        angular.module('TVG.UserProfile', ['TVG.Security'])

            .service('UserProfileSvc', UserProfileSvc)

            .service('StatesSvc', StatesSvc)

            .service('PasswordValidatorSvc', PasswordValidatorSvc)

            .service('UsernameValidatorSvc', UsernameValidatorSvc)

            .service('UserBalanceSvc', UserBalanceSvc)

            .service('UserPromotionsSvc', UserPromotionsSvc)

            .service('ProfileHelperSvc', ProfileHelperSvc)

            .controller('QuickDepositCtrl', QuickDepositCtrl)

            .filter('trust', function ($sce) {
                return $sce.trustAsHtml;
            })

            .filter('startFrom', function () {
                return function (input, start) {
                    start = +start;
                    return input.slice(start);
                };
            });
    }
);

