'use strict';

define('SEO',[
        'PageMetaInfoFac',
        'SeoLifeCycleManagerSvc',
        'CurrentPageMetaInfoSingleton',
        'SeoPageTitleDirective',
        'SeoMetaDescriptionDirective'
    ], function (PageMetaInfoFac, SeoLifeCycleManagerSvc, CurrentPageMetaInfoSingleton, SeoPageTitleDirective, SeoMetaDescriptionDirective) {

        angular
            .module('TVG.SEO', [])
            .factory('PageMetaInfoFac', PageMetaInfoFac)
            .factory('CurrentPageMetaInfoSingleton', CurrentPageMetaInfoSingleton)
            .service('SeoLifeCycleManagerSvc', SeoLifeCycleManagerSvc)
            .directive('seoPageTitle', SeoPageTitleDirective)
            .directive('seoMetaDescription', SeoMetaDescriptionDirective)
            .constant('pageMetaInfoDefault', {
                title: "Home",
                description: "TVG the home of horse racing"
            });

    }
);

