'use strict';
define('FullScheduleReloadedCtrl',[], function () {
    function FullScheduleReloadedCtrl(
            $scope,
            $rootScope,
            $log,
            FullScheduleReloadedFac,
            $location,
            RaceUrlFac,
            GraphFullScheduleRaces,
            GraphFullScheduleTracks,
            GraphFeaturedTracks,
            WagerProfileFac,
            RaceMtpStatusFac,
            GTMFac,
            RacesPageGTMFac,
            $filter
        ) {
        var featuredTracksSubscription;
        var options;
        var wagerProfile;

        var vm = this;
        var unbind = [];
        var afterLoginUpdateFav = null;

        var subscriptionTime = $filter('CMSLabels')('med', 'graphqlPollingRates') || 60000; // in ms

        var gettingData = false;
        var fullScheduleRacesArray = [];

        $rootScope.appTitle = 'Races | By Time';

        vm.model = {
            onAccessLoading: true,
            todaysFavorites: [],
            featuredRaces: [],
            fullSchedule: [],
            racesByTrack: [],
            favoriteRacesByTrack: [],
            disableFavoriteAction: false,
            favoriteActionTarget: {},
            hasCarryOverPools: false,
            shownElements: 20,
            showByTime: $location.search().view !== 'bytrack'
        };

        vm.events = {
            sendGoToRaceGtm: _sendGoToRaceGtm,
            goToRace: _goToRace,
            updateFavorite: _updateFavorite,
            displayLoginModal: _displayLoginModal,
            goToMyFavorites: _goToMyFavorites,
            changeTab: _changeTab,
            loadMore: _loadMore,
            calculateRaceUrl: _calculateRaceUrl
        };

        $scope.$on('session', function() {
            wagerProfile = WagerProfileFac.getSessionOrGenericProfile();

            options = {
                wagerProfile: wagerProfile
            };

            // Full Schedule Races
            _getFullScheduleRacesFromGraph(wagerProfile, 0);

            // Full Schedule Tracks
            _getFullScheduleTracksFromGraph(wagerProfile);

            // Featured Tracks
            featuredTracksSubscription = GraphFeaturedTracks.subscribe(options, {
                success: function (featureTracks) {
                    var _sortingCallback_ = _sortMTP;

                    vm.model.featuredRaces = _reMapFeaturedTracks(featureTracks.sort(_sortingCallback_));
                    vm.model.featuredRaces.forEach( function(race) {
                        race.mtp = RaceMtpStatusFac.getRaceMtpStatus(race.raceId).mtp;
                    });
                    if ($scope.$root && !$scope.$root.$$phase) {
                        $scope.$apply();
                    }
                },
                fail: function(err) {
                    $log.error('Error', err); // eslint-disable-line
                }
            }, subscriptionTime, true);
        });

        function _getFullScheduleTracksFromGraph(wagerProfile){
            GraphFullScheduleTracks.get({
                wagerProfile: wagerProfile
            }).then(function (racesByTrack) {
                vm.model.racesByTrack = racesByTrack;
                vm.model.racesByTrack.forEach( function(track) {
                    track.isFavorite = FullScheduleReloadedFac.isFavoriteTrack(track);
                    track.races.forEach(function(race) {
                        race.mtp = RaceMtpStatusFac.getRaceMtpStatus(race.raceId).mtp;
                    });
                });

                if ($scope.$root && !$scope.$root.$$phase) {
                    $scope.$apply();
                }
            },
            function (err) {
                $log.error('Error', err); // eslint-disable-line
            });
        }

        function _getFullScheduleRacesFromGraph (wagerProfile) {
            gettingData = true;
            GraphFullScheduleRaces.get({
                wagerProfile: wagerProfile
            }).then(function (upcomingRaces) {
                if (_.isArray(upcomingRaces)) {
                    fullScheduleRacesArray = fullScheduleRacesArray.concat(upcomingRaces);

                    var processedData = FullScheduleReloadedFac.getData(fullScheduleRacesArray);

                    //Full Schedule Favorite Races
                    vm.model.todaysFavorites = processedData.todaysFavorites;

                    // Full Schedule Favorite Tracks
                    vm.model.favoriteRacesByTrack = processedData.favoriteRacesByTrack;

                    vm.model.fullSchedule = fullScheduleRacesArray;

                    vm.model.fullSchedule.forEach( function(race) {
                        race.isFavorite = FullScheduleReloadedFac.isFavoriteTrack(race);
                        race.mtp = RaceMtpStatusFac.getRaceMtpStatus(race.raceId).mtp;
                    });

                    vm.model.onAccessLoading = false;
                    $scope.racesLoaded = true;
                    gettingData = false;

                    if ($scope.$root && !$scope.$root.$$phase) {
                        $scope.$apply();
                    }
                }
            },
            function (err) {
                $log.error('Error', err); // eslint-disable-line
            });
        }

        function _calculateRaceUrl(race) {
            //if a race exists then append to the URL trackAbbr, trackName, and raceNumber
            if (race) {
                var raceUrl = RaceUrlFac.generateRacePath(
                    race.trackAbbr,
                    race.trackName,
                    race.raceNumber,
                    false);
                return raceUrl;
            }
            return '';
        }

        function _goToRace(trackAbbr, raceNumber, originComponent) {            
            var race;
            var raceIndex =  _.findIndex(vm.model.fullSchedule, function(race) {
                return race.trackAbbr == trackAbbr && race.raceNumber == raceNumber;
            });
            if (raceNumber) race = vm.model.fullSchedule[raceIndex];
            var raceUrl = _calculateRaceUrl(race);
            $location.url(raceUrl);
            
            _sendGoToRaceGtm(race, originComponent, raceUrl);
        }

        function _sendGoToRaceGtm(race, originComponent, raceUrl) {
            if (race && originComponent, raceUrl) {
                RacesPageGTMFac.clickNavigateToRace({
                    raceUrl: raceUrl,
                    originComponent: originComponent,
                    trackName: race.trackName,
                    raceNumber: race.raceNumber,
                    mtp: race.MTP,
                });
            }
        }

        function _goToMyFavorites() {
            $location.path('/my-favorite-tracks');
        }

        function _updateFavorite(trackAbbr, isFavorite, event, trackName) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (!vm.model.disableFavoriteAction) {
                if (!$rootScope.userSession) {
                    afterLoginUpdateFav = trackAbbr;
                    _displayLoginModal();
                } else {
                    vm.model.disableFavoriteAction = true;
                    $scope.$broadcast('FullScheduleReloaded::UpdateFavoriteStart', trackAbbr);
                    FullScheduleReloadedFac.updateFavorite(trackAbbr).finally(function () {
                        _releaseFavoriteAction(trackAbbr, isFavorite);
                        if(!isFavorite) {
                            // Apptentive event
                            window.ApptentiveSDK.engage("Race track is favorited");
                        }
                        
                        if (!isFavorite && trackName) {
                            RacesPageGTMFac.clickRacesAddToFavFeaturedTrack({
                                trackName: trackName,
                            });
                        }
                    });
                }
            }
        }

        function _displayLoginModal() {
            _sendGtmLoginClickEvent();
            $rootScope.$broadcast('callLoginRequiredModal');
        }

        function _updateModel(collection, refetch) {
            var processedData;

            // @TODO should the fallback be TVG and not ALL_TACKS?

            if(refetch) {
                wagerProfile = WagerProfileFac.getSessionOrGenericProfile();

                options = {
                    wagerProfile: wagerProfile
                };

                //Full Schedule Races
                _getFullScheduleRacesFromGraph(wagerProfile, 0);

                // Full Schedule Tracks
                _getFullScheduleTracksFromGraph(wagerProfile);

                //Featured Tracks
                if(featuredTracksSubscription) {
                    GraphFeaturedTracks.refetch(options);
                }
            }

            if(collection) {
                processedData = FullScheduleReloadedFac.getData(collection);

                //Full Schedule Favorite Races
                vm.model.todaysFavorites = processedData.todaysFavorites;

                // Full Schedule Favorite Tracks
                vm.model.favoriteRacesByTrack = processedData.favoriteRacesByTrack;
            }

            $scope.$emit('pageLoaded', 'races');
        }

        function _reMapFeaturedTracks(featuredTracksRaw){
            var featuredTracks = [];
            _.forEach(featuredTracksRaw, function (track) {
               // _.forEach(track.races, function (race) {
                track.races.some(function(race) {
                    if (race.raceStatus.code === "IC" || race.raceStatus.code === "O") {
                        var raceModel = {
                            trackAbbr: race.trackAbbr,
                            trackName: race.trackName,
                            perfAbbr: race.perfAbbr,
                            raceNumber: race.raceNumber,
                            MTP: race.mtp,
                            postTime: new Date(race.postTime),
                            id: race.id,
                            raceId: race.raceId,
                            isFavorite: FullScheduleReloadedFac.isFavoriteTrack(race),
                            country: race.country
                        };

                        featuredTracks.push(raceModel);

                        return true;
                    }
                });
            });
            return featuredTracks;
        }

        function _sortMTP(a, b) {
            if (a.races[0].mtp < b.races[0].mtp) return -1;
            if (a.races[0].mtp > b.races[0].mtp) return 1;
            return 0;
        }

        function _releaseFavoriteAction(trackAbbr, isFavorite) {
            $scope.$broadcast('FullScheduleReloaded::UpdateFavoriteEnd', trackAbbr);
            vm.model.disableFavoriteAction = false;

            vm.model.fullSchedule.forEach( function(race) {
                if(trackAbbr === race.trackAbbr) {
                    race.isFavorite = !isFavorite;
                }
            });
            vm.model.todaysFavorites.forEach( function(race) {
                if(trackAbbr === race.trackAbbr) {
                    race.isFavorite = !isFavorite;
                }
            });
            vm.model.favoriteRacesByTrack.forEach( function(track) {
                if(trackAbbr === track.trackAbbr) {
                    track.isFavorite = !isFavorite;
                }
            });
            vm.model.racesByTrack.forEach( function(track) {
                if(trackAbbr === track.trackAbbr) {
                    track.isFavorite = !isFavorite;
                }
            });
            vm.model.featuredRaces.forEach( function(race) {
                if(trackAbbr === race.trackAbbr) {
                    race.isFavorite = !isFavorite;
                }
            });
        }

        function _changeTab() {
            vm.model.shownElements = 20; //reset shown elements when changing view
            vm.model.showByTime = !vm.model.showByTime;
            $rootScope.appTitle = vm.model.showByTime ? 'Races | By Time' : 'Races | By Track';

            RacesPageGTMFac.clickRacesViewOrder({
                showByTime: vm.model.showByTime,
            });
        }

        function _loadMore() {
            if(!gettingData) {
                if (vm.model.shownElements < vm.model.fullSchedule.length) {
                    vm.model.shownElements += 10;
                }
            }
        }

        function _updateRacesMtp(race){
            //update the race mtp
            var mtpObj = RaceMtpStatusFac.getRaceMtpStatus(race.raceId);
            //Check if the race mtp is empty then the race is over and we remove it from the array
            if(Object.keys(mtpObj).length === 0 && race.mtp >= 0){
                return false;
            }
            else if(!mtpObj) {
                mtpObj = {mtp: null};
            }

            return mtpObj;
        }

        // Update MTP Remove race from list if it's already closed or in sk
        function _updateRaceMTPandRemoveClosed(raceList, race, index) {
            var mtpObj = _updateRacesMtp(race);
            if(mtpObj){
                var raceStatus = mtpObj.raceStatus ? mtpObj.raceStatus.code : false;
                if(raceStatus === "RO" || raceStatus === "SK") {
                    raceList.splice(index, 1);
                }
                else if (parseInt(mtpObj.mtp, 10) >= 0){
                    race.mtp = mtpObj.mtp;
                }
            }
            else if(!mtpObj) {
                raceList.splice(index, 1);
            }
        }

        function _sendGtmLoginClickEvent() {
            var eventData = GTMFac.gaEventBuilder()
                .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                .withGaEventCategory('Site Click')
                .withGaEventLabel('Favorites | Login Link')
                .withEventLabel('RacesInPage-LoginLink')
                .build();

            GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
        }

        $scope.$on('$destroy', function () {
            if (featuredTracksSubscription) {
                featuredTracksSubscription.unsubscribe();
            }

            if (featuredTracksSubscription) {
                featuredTracksSubscription.unsubscribe();
            }
        });

        $scope.$on('login', function () {
            if (afterLoginUpdateFav && !FullScheduleReloadedFac.isFavoriteTrack({trackAbbr: afterLoginUpdateFav})) {
                _updateFavorite(afterLoginUpdateFav);
                afterLoginUpdateFav = null;
            } else {
                fullScheduleRacesArray = [];
                vm.model.shownElements = 20;

                $rootScope.$broadcast('scrollToTop');

                _updateModel(vm.model.fullSchedule, true);
            }
        });

        $scope.$on('CarryOverPools::HasData', function () {
            vm.model.hasCarryOverPools = true;
        });

        unbind.push($rootScope.$on("newFavTrack", function () {
            _updateModel(vm.model.fullSchedule, false);
        }));

        unbind.push($rootScope.$on('mtpStatusUpdated', function () {
            vm.model.fullSchedule.forEach( function(race, index) {
                _updateRaceMTPandRemoveClosed(vm.model.fullSchedule, race, index);
            });

            vm.model.favoriteRacesByTrack.forEach(function(track, trackIndex) {
                track.races.forEach(function(race, index) {
                    _updateRaceMTPandRemoveClosed(vm.model.favoriteRacesByTrack[trackIndex].races, race, index);
                    // Remove track if there are no more races
                    if(vm.model.favoriteRacesByTrack[trackIndex].races.length === 0) {
                        vm.model.favoriteRacesByTrack.splice(trackIndex, 1);
                    }
                });
            });

            //Update todays favorites with next race if it has been removed
            var processedData = FullScheduleReloadedFac.getData(vm.model.fullSchedule);
            vm.model.todaysFavorites = processedData.todaysFavorites;

            vm.model.todaysFavorites.forEach( function(race, index) {
                _updateRaceMTPandRemoveClosed(vm.model.todaysFavorites, race, index);
            });

            vm.model.racesByTrack.forEach( function(track, trackIndex) {
                track.races.forEach(function(race, index) {
                    _updateRaceMTPandRemoveClosed(vm.model.racesByTrack[trackIndex].races, race, index);
                    // Remove track if there are no more races
                    if(vm.model.racesByTrack[trackIndex].races.length === 0) {
                        vm.model.racesByTrack.splice(trackIndex, 1);
                    }
                });
            });
            vm.model.featuredRaces.forEach( function(race, index) {
                _updateRaceMTPandRemoveClosed(vm.model.featuredRaces, race, index);
            });
        }));
    }

    FullScheduleReloadedCtrl.$inject = [
        '$scope',
        '$rootScope',
        '$log',
        'FullScheduleReloadedFac',
        '$location',
        'RaceUrlFac',
        'GraphFullScheduleRaces',
        'GraphFullScheduleTracks',
        'GraphFeaturedTracks',
        'WagerProfileFac',
        'RaceMtpStatusFac',
        'GTMFac',
        'RacesPageGTMFac',
        '$filter'
    ];

    return FullScheduleReloadedCtrl;
});

