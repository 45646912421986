"use strict";

define('SubscriptionAlerts',[],function () {

    function SubscriptionAlerts(alert, active){
        this.alert = alert;
        this.active = active;
    }

    return SubscriptionAlerts;

});

