'use strict';

define('TalentPicksEventsFactory',['RaceStatusUtils', 'RaceInfoEntityBuilder'],
    function (RaceStatusUtils, RaceInfoEntityBuilder) {
        function TalentPicksEventsFactory($location, BetslipFactory, GTMFac, $rootScope, RaceInfoFac, RaceUrlFac) {

            function _openTalentPickRace(talentPick, event) {
                if (event) {
                    event.preventDefault();
                }
                talentPick.sendGTMEvent('Race');

                $location.url(RaceUrlFac.calculateRacePath(talentPick.trackAbbr, talentPick.trackName, talentPick.raceNumber, false, talentPick.raceTypeCode)).hash('');
            }

            function _talentPickRaceUrl(talentPick) {
                return RaceUrlFac.calculateRacePath(talentPick.trackAbbr, talentPick.trackName, talentPick.raceNumber, false, talentPick.raceTypeCode);
            }

            function _openBetTicket(scope) {
                return function(windowCallback) {
                    scope.windowCallback = windowCallback;
                    BetslipFactory.betNow(scope.windowCallback);
                };
            }

            function _goToTalentPicks(event) {
                if (event) {
                    event.preventDefault();
                }
                var obj = GTMFac.EventBuilder()
                    .withSectionName(GTMFac.Tvg4SectionName())
                    .withScreenName(GTMFac.Tvg4ScreenName())
                    .withGaEventAction('Home Page Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('See All TVG Picks')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', obj);

                if ($rootScope.activeFeatures.handicappingWithRoutes) {
                    $location.url('/handicapping/talent-picks').hash('');
                } else {
                    $location.url('/handicapping').hash('talentpicks');
                }

            }

            function _goToProgramPage(talentPick) {

                _sendGTM(talentPick, 'View Race');

                $location.url(RaceUrlFac.calculateRacePath(talentPick.trackAbbr, talentPick.trackName, talentPick.raceNumber, false, talentPick.raceTypeCode)).hash('');
            }

            function _goToRaceResults(talentPick) {

                _sendGTM(talentPick, 'View Results');

                $location.url(RaceUrlFac.calculateRacePath(talentPick.trackAbbr, talentPick.trackName, talentPick.raceNumber, false, talentPick.raceTypeCode)).hash('');
            }

            function _showMTP(talentPick) {
                return !talentPick.isRaceOff && !talentPick.isRaceOver;
            }

            function _onWagerProfileListener(scope) {
                return function() {
                    if (scope.windowCallback) {
                        BetslipFactory.tryToBetNowAgain(scope.windowCallback);
                    }

                    scope.windowCallback = null;
                };
            }

            function _getPromiseRaceInfoOfLastRaceOfMultiRacePick(talentPick) {
                var lastRaceNumber = talentPick.raceNumber + talentPick.BIs.length - 1;
                var mockLastRaceFromMultiRacePick = RaceInfoEntityBuilder()
                    .withTrackAbbr(talentPick.trackAbbr)
                    .withPerfAbbr(talentPick.perfAbbr)
                    .withRaceNumber(lastRaceNumber)
                    .withId(talentPick.trackAbbr + '_' + talentPick.perfAbbr + '_' + lastRaceNumber)
                    .build();

                return RaceInfoFac.getRaceInfo(mockLastRaceFromMultiRacePick);
            }

            function _setRaceStatusPropertiesOfTalentPick(talentPick, raceStatus) {
                talentPick.isRaceOff = false;
                talentPick.isRaceOver = false;
                if (RaceStatusUtils.hasRaceResults(raceStatus)) {
                    talentPick.isRaceOff = false;
                    talentPick.isRaceOver = true;
                } else if (RaceStatusUtils.isClosedForBetting(raceStatus)) {
                    talentPick.isRaceOff = true;
                    talentPick.isRaceOver = false;
                }
            }

            function _sendGTM(talentPick, page) {
                var pageName = GTMFac.Tvg4SectionName().indexOf('Talent Picks') > -1 ? 'Talent Picks' : GTMFac.Tvg4ScreenName();

                var obj = GTMFac.EventBuilder()
                    .withSectionName(GTMFac.Tvg4SectionName())
                    .withScreenName(GTMFac.Tvg4ScreenName())
                    .withGaEventAction(pageName + ' Page Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel(page + ' | ' + talentPick.talentName)
                    .withEventLabel('TalentPicks-' + page.replace(' ', ''))
                    .withRaceNumber(talentPick.gtmRaceNumber)
                    .withTrackName(talentPick.trackName)
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', obj);
            }

            return {
                talentPickRaceUrl: _talentPickRaceUrl,
                onWagerProfileListener: _onWagerProfileListener,
                openTalentPickRace: _openTalentPickRace,
                openBetTicket: _openBetTicket,
                goToTalentPicks: _goToTalentPicks,
                goToProgramPage: _goToProgramPage,
                goToRaceResults: _goToRaceResults,
                showMTP: _showMTP,
                setRaceStatusPropertiesOfTalentPick: _setRaceStatusPropertiesOfTalentPick,
                getPromiseRaceInfoOfLastRaceOfMultiRacePick: _getPromiseRaceInfoOfLastRaceOfMultiRacePick
            };

        }

        TalentPicksEventsFactory.$inject = [
            '$location',
            'BetslipFactory',
            'GTMFac',
            '$rootScope',
            'RaceInfoFac',
            'RaceUrlFac'
        ];

        return TalentPicksEventsFactory;
    });

