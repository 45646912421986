"use strict";

define('PromotionsSvc',[], function() {
    function PromotionsSvc($http, ConfigurationFac, $log) {
        function _getPromotionsByUser(userId, type) {
            var url =
                ConfigurationFac.getServiceApiUrl("pro") +
                    "/users/" +
                    userId +
                    "/promotions?promoType=" +
                    type || "race";

            return $http
                .get(url)
                .then(function(response) {
                    return (response.data && response.data.promotions) || [];
                })
                .catch(function(data, status) {
                    $log.error("Status: " + status + " | Message: " + data);
                    return [];
                });
        }

        return {
            getPromotionsByUser: _getPromotionsByUser
        };
    }

    PromotionsSvc.$inject = ["$http", "ConfigurationFac", "$log"];

    return PromotionsSvc;
});

