"use strict";

define('RaceSelectorSwipeableModel',[
        'RaceStatusUtils'
    ], function (RaceStatusUtils) {

    function RaceSelector(numberOfRacesPerPage, numberOfRacesBefore) {
        this.numberOfRacesPerPage = numberOfRacesPerPage;
        this.numberOfRacesBefore = numberOfRacesBefore;
        this.dataSource = [];
        this.currentRace = 0;
        this.racesForCurrentSubSet = [];
        this.currentMax = 0;
        this.currentMin = 0;
    }

    RaceSelector.prototype = {
        setDataSource: function (dataSource, currentRaceNumber, forceNavigationToSelectedRace) {
            if (!dataSource)
                return;
            this.dataSource = dataSource;
            this.currentRace = currentRaceNumber ? currentRaceNumber - 1 : 0;
            if (forceNavigationToSelectedRace === true)
                this.goForPageBasedOnRaceNumber();
            else
                this.updateRacesForCurrentSubSet();
        },
        goForPageBasedOnRaceNumber: function () {
            if (!this.dataSource.length){
                this.dataSource = [];
                this.racesForCurrentSubSet = [];
                return;
            }
            this.currentMin = this.currentRace;
            this.currentMax = this.currentRace;
            this.calcRaceOpenBeforeCurrentRaceIndex();
            this.calcRaceOffBeforeCurrentRaceIndex(this.numberOfRacesBefore);
            this.calcUpcomingRacesIndex();
            this.calcRaceResultsIndex();
            this.updateRacesForCurrentSubSet();
        },

        updateRacesForCurrentSubSet: function () {
            this.racesForCurrentSubSet = this.dataSource.slice(this.currentMin, this.currentMax + 1);
        },
        calcRaceOpenBeforeCurrentRaceIndex: function() {
            if (!this.hasPreviousRaces()){
                return;
            }

            if (this.isRacesSubsetFilled()) {
                return;
            }

            if (!this.isRaceOpenForBetting(this.currentMin-1)) {
                return;
            }

            this.currentMin--;
            this.calcRaceOpenBeforeCurrentRaceIndex();

        },
        calcRaceOffBeforeCurrentRaceIndex: function(iteration) {
            if (iteration === 0) {
                return;
            }

            if (!this.hasPreviousRaces()){
                return;
            }

            if (!this.isRaceOff(this.currentMin -1)){
                return;
            }

            if (this.isRacesSubsetFilled()) {
                return;
            }

            this.currentMin--;
            this.calcRaceOffBeforeCurrentRaceIndex(iteration - 1);
        },
        calcUpcomingRacesIndex: function() {
            if (!this.hasNextRaces()){
                return;
            }

            if (this.isRacesSubsetFilled()) {
                return;
            }

            this.currentMax++;
            this.calcUpcomingRacesIndex();
        },
        calcRaceResultsIndex: function() {
            if (!this.hasPreviousRaces()){
                return;
            }

            if (this.isRacesSubsetFilled()) {
                return;
            }

            this.currentMin--;
            this.calcRaceResultsIndex();

            return;
        },
        calcRacesForCurrentSubSetSize: function() {
            return (this.currentMax - this.currentMin) + 1;
        },
        // @TODO use raceStatusUtils!!!
        isRaceOpenForBetting: function(raceIndex) {
            var status = this.getRaceStatus(raceIndex);

            return (
                !RaceStatusUtils.isStatusRaceOfficial(status) &&
                !RaceStatusUtils.isStatusManuallyClosed(status) &&
                !RaceStatusUtils.isStatusStewardsKey(status)
            );
        },
        isRaceOff: function(raceIndex) {
            var status = this.getRaceStatus(raceIndex);

            return RaceStatusUtils.isStatusStewardsKey(status);
        },
        isRacesSubsetFilled: function () {
            return this.calcRacesForCurrentSubSetSize() >= this.numberOfRacesPerPage;
        },
        getRaceStatus: function (id) {
            var race = this.dataSource[id];
            return (race && race.status) ? race.status.toLowerCase(): false;
        },
        goNext: function () {
            if (!this.hasNextRaces()) {
                return;
            }

            this.currentMax++;
            this.currentMin++;
            this.updateRacesForCurrentSubSet();
        },
        goBack: function () {
            if (!this.hasPreviousRaces()) {
                return;
            }

            this.currentMax--;
            this.currentMin--;
            this.updateRacesForCurrentSubSet();
        },
        hasPreviousRaces: function () {
            return this.currentMin > 0;
        },
        hasNextRaces: function () {
            return this.currentMax < this.dataSource.length - 1;
        }
    };

    return RaceSelector;

});

