'use strict';

define(
    'RunnerComparators',['GenericComparators', 'lodash'],
    function (GenericComparators, _) {


        function RunnerComparators() {
        }

        /*
          Private Functions
         */

        /***
         * This function converts an alphanumeric string into a numeric based on its ASCII code
         * Example : 1A will be 1.065
         *           1B will be 1.066
         *           1 will be 1
         * @param horseId
         * @returns Number
         * @private
         */
        function convertHorseIdToDecimal(horseId) {
            var value;
            if (!isNaN(horseId)) {
                return parseInt(horseId);
            }
            var regex = /(\d+)[A-Z]{1}$/;
            //check if horseId is like 1A, 1B, 10A
            if (regex.test(horseId)) {
                var extractNumberRgx = /^\d+/;
                var extractAlphaRgx = /[A-Z]{1}$/;
                var integerPart = parseInt(horseId.match(extractNumberRgx)[0]);
                var decimalPart = parseInt((horseId.match(extractAlphaRgx)[0]).charCodeAt(0));
                var toDecimalFactor = 1 / 1000;
                value = integerPart + (decimalPart * toDecimalFactor);
            }
            return value;
        }

        /***
         * check if value is a fractional number
         * @param value
         * @returns {boolean}
         */

        function isFractionalNumber(value) {
            var fractionalRegex = /((\d+)\/(\d+)){1}/g;
            return fractionalRegex.test(value);
        }

        /***
         * convert a string to a number
         * Example : '1/2' to 0.5
         * @param value
         * @returns {number}
         */
        function convertFractionalToDecimal(value) {
            var aFractionalParts = value.split("/");
            var numerator = aFractionalParts[0],
                denominator = aFractionalParts[1];
            if (denominator == 0) {
                denominator = Number.MAX_VALUE;
            }
            return parseFloat(numerator) / parseFloat(denominator);
        }

        /***
         *
         * @param value
         * @returns returns value if convertible to float. Otherwise returns Number.MIN_VALUE.
         */
        function castToNumber(value) {
            if (isFractionalNumber(value)) {
                value = convertFractionalToDecimal(value);
            }
            value = parseFloat(value);
            return isNaN(value) ? -Number.MAX_VALUE : value;
        }

        /***
         *
         * @param runner1
         * @param runner2
         * @returns {boolean}
         */
        function hasScratched(r1, r2) {
            return r1.scratched || r2.scratched;
        }

        /*
         Public Functions
         */

        RunnerComparators.byScratched = function (r1, r2) {
            if (!r1.scratched && r2.scratched) {
                return 1;
            } else if (r1.scratched && !r2.scratched) {
                return -1;
            }
            return this.isReverse ? RunnerComparators.byId(r1, r2) : -RunnerComparators.byId(r1, r2);
        };

        RunnerComparators.byOdds = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = r1.hasOwnProperty('oddsDecimalValue') && !r1.scratched ? r1.oddsDecimalValue : -Number.MAX_VALUE;
            var item2 = r2.hasOwnProperty('oddsDecimalValue') && !r2.scratched ? r2.oddsDecimalValue : -Number.MAX_VALUE;

            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);

            }
            return value;
        };

        RunnerComparators.byWeight = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.jockey_trainer_info.Weight : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.jockey_trainer_info.Weight : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);

            }
            return value;
        };

        RunnerComparators.byWeightLoggedOut = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = r1.hasOwnProperty('weight') ? r1.weight : -Number.MAX_VALUE;
            var item2 = r2.hasOwnProperty('weight') ? r2.weight : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byFirst = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.jockey_trainer_stats['1st'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.jockey_trainer_stats['1st'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.bySecond = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.jockey_trainer_stats['2nd'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.jockey_trainer_stats['2nd'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byThird = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.jockey_trainer_stats['3rd'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.jockey_trainer_stats['3rd'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byStarts = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.jockey_trainer_stats.Starts : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.jockey_trainer_stats.Starts : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byDaysOff = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.snapshot_freepick_info['Days Off'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.snapshot_freepick_info['Days Off'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byPowerRating = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.snapshot_freepick_info['Power Rating'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.snapshot_freepick_info['Power Rating'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byWinsStarts = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.snapshot_freepick_info['Wins/ Starts'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.snapshot_freepick_info['Wins/ Starts'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byNumberOfRaces = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.pace['# of Races'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.pace['# of Races'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byEarly = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.pace.Early : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.pace.Early : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byFinish = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.pace.Finish : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.pace.Finish : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byMiddle = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.pace.Middle : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.pace.Middle : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byAvgClass = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.speed_class_rating['Avg Class'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.speed_class_rating['Avg Class'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byAvgDist = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.speed_class_rating['Avg Dist'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.speed_class_rating['Avg Dist'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byAvgSpeed = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.speed_class_rating['Avg Speed'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.speed_class_rating['Avg Speed'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byHighSpeed = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.speed_class_rating['High Speed'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.speed_class_rating['High Speed'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        RunnerComparators.byLastClass = function (r1, r2) {
            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var item1 = !_.isEmpty(r1.handicappingInfo) ? r1.handicappingInfo.speed_class_rating['Last Class'] : -Number.MAX_VALUE;
            var item2 = !_.isEmpty(r2.handicappingInfo) ? r2.handicappingInfo.speed_class_rating['Last Class'] : -Number.MAX_VALUE;
            var value = GenericComparators.byNumber(item1, item2, castToNumber);

            //if they are equal
            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };


        RunnerComparators.byId = function (r1, r2) {
            if (GenericComparators.greaterThan(r1.horseID, r2.horseID, convertHorseIdToDecimal)) {
                return 1;
            } else if (GenericComparators.lessThan(r1.horseID, r2.horseID, convertHorseIdToDecimal)) {
                return -1;
            }
            return 0;
        };

        RunnerComparators.byAge = function(r1, r2) {

            if (hasScratched(r1, r2)) {
                return this.isReverse ? RunnerComparators.byScratched(r1, r2) : -RunnerComparators.byScratched(r1, r2);
            }

            var value = 0;

            if (GenericComparators.greaterThan(r1.age, r2.age)) {
                value = 1;
            } else if (GenericComparators.lessThan(r1.age, r2.age)) {
                value = -1;
            }

            if (value === 0) {
                value = this.isReverse ? -RunnerComparators.byId(r1, r2) : RunnerComparators.byId(r1, r2);
            }

            return value;
        };

        return RunnerComparators;
    }
);

