'use strict';

define('UserPropertiesSvc',[],function() {
        function UserPropertiesSvc ($routeParams, $rootScope, $document) {

            // User properties builder

            var userPropertiesBuilder = function() {
                var userProperties = {};
                var gears = [];

                return {
                    build: function () {
                        gears.forEach(function (propertyBuilder) {
                            propertyBuilder(userProperties);
                        });
                        return userProperties;
                    },

                    addPropertyGear: function (propertyBuilder) {
                        gears.push(propertyBuilder);
                        return this;
                    }
                };
            };

            function isUserDefined(user) {
                return user != undefined;
            }

            function isUserHomeAddressDefined(user) {
                return isUserDefined(user) && user.homeAddress != undefined;
            }

            // Properties gears

            function referrerGear(userProperties) {
                var parser = document.createElement('a');
                parser.href = $document.referrer;
                userProperties.referrer = parser.hostname;
            }

            function accountIdGear(userProperties) {
                if (isUserDefined($rootScope.user)) {
                    userProperties.accountId = $rootScope.user.accountNumber;
                }
            }

            function stateGear(userProperties) {
                if (isUserHomeAddressDefined($rootScope.user)) {
                    userProperties.residence = $rootScope.user.homeAddress.StateAbbr;
                }
            }

            function promotionGear(userProperties) {
                var promoCode = _.get($routeParams, 'promoCode');
                if (!_.isUndefined(promoCode)) {
                    userProperties.promoCode = promoCode;
                }
            }

            this.getUserProperties = function () {
                return userPropertiesBuilder()
                    .addPropertyGear(referrerGear)
                    .addPropertyGear(accountIdGear)
                    .addPropertyGear(stateGear)
                    .addPropertyGear(promotionGear)
                    .build();
            };

        }

        UserPropertiesSvc.$inject = [
            '$routeParams',
            '$rootScope',
            '$document'
        ];

        return UserPropertiesSvc;
    });
