'use strict';

define('SelectionPositionAndNotKeyBoxDir',[],
    function() {

        function selectionPositionAndNotKeyBox(stepSelectionsStringFilter, ordinalNumberStringFilter) {

            function _linkFn(scope) {
                var ordinalNumber = scope.index + 1;

                scope.selection = {
                    ordinalNumber: ordinalNumberStringFilter(ordinalNumber),
                    selection: stepSelectionsStringFilter(scope.selectionPositionAndNotKeyBox.split(','))
                };
            }

            return {
                restrict: 'EA',
                require: '^runnersSelection',
                scope: {
                    selectionPositionAndNotKeyBox: '=',
                    index: '='
                },
                templateUrl: 'js/desktop/modules/RaceTrackCurrentBets/templates/selection-position-and-not-key-box-template.html',
                link: _linkFn
            };
        }

        selectionPositionAndNotKeyBox.$inject = [
            'stepSelectionsStringFilter',
            'ordinalNumberStringFilter'
        ];

        return selectionPositionAndNotKeyBox;
    });

