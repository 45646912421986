'use strict';

define(
    'RaceReplaysCtrl',['lodash'],
    function (_) {
        function RaceReplaysCtrl($scope, $filter, RunnerInfoFac, $rootScope, GTMFac, CookieFac, $timeout, GraphPastRaces, WagerProfileFac) {

            (function init() {
                $scope.data = $scope.$parent;

                $scope.data.replaysList = [];
                $scope.data.loading = true;
                $scope.data.race = null;
                $scope.data.selectedReplay = {};

                var hideResultsCookie = $rootScope.activeFeatures.raceReplaysHideResults ? CookieFac.getCookie("toggleHideResults") : false;
                if (_.isUndefined(hideResultsCookie) || _.isNull(hideResultsCookie)) {
                    hideResultsCookie = true;
                    CookieFac.setCookie("toggleHideResults", hideResultsCookie, {expires: 90});
                }
                $scope.data.hideResults = hideResultsCookie;

                _getHorseReplaysList(
                  $scope.data.selectedRunnerForReplays.horseName,
                  $scope.data.selectedRunnerForReplays.dob
                )
                    .then(function() {
                        $scope.data.loading = false;
                        $timeout(function() {
                            $rootScope.$apply();
                        });
                    });
            }());

            function _getHorseReplayFail() {
                _getHorseReplaysListSuccess();
            }

            function _getHorseReplayGraphSuccess(response) {
                var pastRaces = [];

                if (Array.isArray(response) && response.length > 0) {
                    response.forEach(function(race) {
                        pastRaces.push({
                            distance:
                            race.distanceValue.toFixed(1) +
                            race.distanceCode.toLowerCase(),
                            postTime: race.postTime,
                            raceClass: race.className,
                            raceDate: race.date,
                            raceNumber: race.raceNumber,
                            raceTypeId: race.typeId,
                            raceTypeName: race.typeName,
                            trackAbbr: race.trackCode,
                            trackName: race.trackName,
                            providerTrack: race.trackCode,
                            replayFileName: race.video && race.video.replayFileName ? race.video.replayFileName : false
                        });
                    });
                }

                _getHorseReplaysListSuccess(pastRaces);
            }

            function _getHorseReplaysListSuccess(data) {
                $scope.data.replaysList = data;
                $scope.data.selectedReplay = _.head(data);

                if ($scope.data.selectedReplay) {
                    $scope.data.race = {
                        raceDate: $filter('date')($scope.data.selectedReplay.raceDate, 'yyyy-MM-dd'),
                        postTime: $scope.data.selectedReplay.postTime,
                        isReplay: true,
                        trackAbbr: $scope.data.selectedReplay.trackAbbr,
                        trackName: $scope.data.selectedReplay.trackName,
                        raceNumber: $scope.data.selectedReplay.raceNumber,
                        perfAbbr: $scope.data.selectedReplay.perfAbbr,
                        instanceId: 'replay-video',
                        containerId: 'replay-video',
                        autoPlay: false,
                        replayFileName: $scope.data.selectedReplay.replayFileName ? $scope.data.selectedReplay.replayFileName : false,
                        useRCNMappingFromCMS: false
                    };
                }
            }

            function _getHorseReplaysList(horseName, horseDob) {
                return $rootScope.activeFeatures.graphRaceReplaysOnProgramPage
                    ? GraphPastRaces.getRacesByHorseName(
                            WagerProfileFac.getSessionOrGenericProfile(),
                            horseName,
                            {isGreyhound: false},
                            horseDob
                        ).then(_getHorseReplayGraphSuccess, _getHorseReplayFail)
                    : RunnerInfoFac.getRunnerReplays(horseName).then(_getHorseReplaysListSuccess);
            }

            function _sendGtmEvent(gaEventLabel, eventLabel) {
                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Page Click')
                    .withGaEventCategory("Site Click")
                    .withGaEventLabel(gaEventLabel)
                    .withEventLabel(eventLabel)
                    .build();

                var genericEvent = GTMFac.genericEvent(eventData);

                GTMFac.GTMEvent().send($rootScope, "siteClick", genericEvent);
            }

            $scope.changeHideResults = function() {
                CookieFac.setCookie("toggleHideResults", $scope.data.hideResults, {expires: 90});

                _sendGtmEvent("Hide Races Results | Toggle " + ($scope.data.hideResults ? "On" : "Off"), "HandicappingRaceReplays_HideRacesResultsToggle");
            };

            $scope.selectReplay = function (replay) {
                if ($scope.isSelectedReplay(replay)) {
                    $scope.data.race = null;
                    $scope.data.selectedReplay = {};
                    return;
                }

                $rootScope.$broadcast("replayVideo:stop");
                $scope.data.selectedReplay = replay;

                $scope.data.race = {
                    raceDate: $filter('date')(replay.raceDate, 'yyyy-MM-dd'),
                    postTime: replay.postTime,
                    isReplay: true,
                    trackAbbr: replay.trackAbbr,
                    trackName: replay.trackName,
                    raceNumber: replay.raceNumber,
                    perfAbbr: replay.perfAbbr,
                    replayFileName: replay.replayFileName ? replay.replayFileName : false,
                    instanceId: 'replay-video',
                    containerId: 'replay-video',
                    autoPlay: false,
                    useRCNMappingFromCMS: false
                };

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction('Race Replays Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel($scope.data.selectedRunnerForReplays.horseName + ' | ' +
                        'Race Replays' + ' | ' + $scope.data.selectedRunnerForReplays.horseID)
                    .withEventLabel('HorseInfo-RaceReplays')
                    .build();

                var event = GTMFac.genericEvent(eventData);
                event.screenName = 'Race Replays';
                event.sectionName = 'Race Replays | Race Replays Page';

                GTMFac.GTMEvent().send($rootScope, 'siteClick', event);
            };

            $scope.isSelectedReplay = function (replay) {
                return _.isEqual(replay, $scope.data.selectedReplay);
            };
        }

        RaceReplaysCtrl.$inject = [
            '$scope',
            '$filter',
            'RunnerInfoFac',
            '$rootScope',
            'GTMFac',
            'CookieFac',
            '$timeout',
            'GraphPastRaces',
            'WagerProfileFac'
        ];

        return RaceReplaysCtrl;
    }
);

