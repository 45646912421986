'use strict';

define('HorseEntityBuilder',['HorseEntity'], function (HorseEntity) {

    function builder(){
        var horse = new HorseEntity();

        return {
            withHorseID: function (horseID) {
                horse.horseID = horseID;
                return this;
            },
            withHorseName: function (horseName) {
                horse.horseName = horseName;
                return this;
            },
            withHorseType: function (horseType) {
                horse.horseType = horseType;
                return this;
            },
            build: function () {
                return horse;
            }
        };
    }

    return builder;

});


