'use strict';

define('CarouselDir',[
    'WidgetsFac',
    'lodash'
], function (WidgetsFac, _) {

    function CarouselDir(WidgetsFac) {
        return {
            restrict: 'E',
            scope: true,
            replace: false,
            templateUrl: 'js/desktop/modules/WidgetsDesktop/components/carousel/templates/carousel-directive-template.html',
            link: function (scope) {

                scope.carousel = {};

                function setCarouselSlides(slides) {
                    scope.carousel = {
                        type: "carousel",
                        value: slides,
                        noWrapSlides: false,
                        interval: 5000,
                        active: 0
                    };

                    scope.$emit('appReady', 'carousel');
                }

                (function init() {
                    WidgetsFac.getCarousel().then( function() {
                        if (_.isUndefined(WidgetsFac.carousel) || !_.isArray(WidgetsFac.carousel.value)) {
                            return;
                        }
                        setCarouselSlides(WidgetsFac.carousel.value);
                    });
                }());
            }

        };

    }

    return CarouselDir;

});

