'use strict';

define('MediatorSubscriptionsFac',[], function () {
        function MediatorSubscriptionsFac(SecuritySvc,
                                          $uibModal,
                                          $rootScope,
                                          $window,
                                          $timeout,
                                          $filter,
                                          FindModalContainerFac,
                                          RunBlockMediatorFactory,
                                          LoginRedirectFac,
                                          RaceMtpStatusFac,
                                          VideoPopOutFac,
                                          FavoriteTracksFac
        ) {
          //Mediator is subscribing to topics of interest, in this case, app navigation when such is triggered by outer modules
                var MEDIATOR_SUBSCRIPT_CONFIG = [];

                //mediator handle login and logout
                function mediatorLoginLogout(data) {
                    var isValidSession = data && data.logged && data.user;
                    $rootScope.sessionRequested = true;

                    if (isValidSession) {
                        SecuritySvc.externalLogin(data.user);
                    } else {
                        SecuritySvc.externalLogout();
                    }

                    mediator.dispatch('sessionValid', {value: isValidSession});
                    RaceMtpStatusFac.refreshData();
                }

                // Open video popout
                function mediatorVideoPopOut(data) {
                    VideoPopOutFac.popOutVideo(data.selectedTrack || {});
                }

                //mediator handle open deposit
                function mediatorOnOpenQuickDeposit() {

                    if ($rootScope.quickDepositModal) {
                        $rootScope.quickDepositModal.close();
                    }
                    $rootScope.quickDepositModal = $uibModal.open({
                        appendTo: FindModalContainerFac('#angular-app'),
                        windowClass: 'quick-deposit new-quick-deposit',
                        backdropClass: 'tvgmodal-backdrop',
                        windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                        templateUrl: 'js/desktop/modules/Account/templates/quick-deposit.html',
                        controller: 'QuickDepositCtrl'
                    });
                }

                //mediator handle navigation
                function mediatorOnNavigation(data) {
                    var dataCheck = data && data.route && (typeof data.route).toLowerCase() === 'string';
                    var routeCheck = dataCheck ? RunBlockMediatorFactory.validateRoute(data.route) : false;
                    var routeNeedsLogin = RunBlockMediatorFactory.validateRouteLogin(data.route, $rootScope.userSession);
                    if (routeCheck) {
                        routeNeedsLogin ?
                            $rootScope.$broadcast("callLoginRequiredModal", function (){
                                RunBlockMediatorFactory.navToRoute(data.route, true);
                            }) :
                            RunBlockMediatorFactory.navToRoute(data.route, false);
                    }
                }

                function mediatorOnRedirect(error) {
                    var modalScope = $rootScope.$new(true);

                    function _cancel() {
                        if (modalScope.confirmDialog) {
                            modalScope.confirmDialog.close();
                            modalScope.confirmDialog = null;
                        }

                        //Redirect user if it is in the wrong app
                        if (modalScope.data.redirectUrl && modalScope.data.redirectUrl != "") {
                            $window.location.href = modalScope.data.redirectUrl;
                        }

                        $timeout(function () {
                            modalScope.data.errorMessageTitle = null;
                            modalScope.data.errorMessage = null;
                        }, 300);
                    }

                    function normalizeUrl(url) {
                        url = url.trim();
                        if (url.indexOf('http') < 0) {
                            url = ($window.location.protocol || 'https:') + '//' + url;
                        }

                        return url;
                    }

                    modalScope.events = {
                        cancel: _cancel
                    };

                    modalScope.$on("$destroy", function () {
                        _cancel();
                    });

                     if (modalScope.confirmDialog) {
                        return;
                    }

                    $rootScope.userSession = false;

                    if (error.messageKey === "ApiUserSessionTimedOut" || error.messageKey === "ApiUserSessionNotFound" || error.messageKey === "ApiMissingUserSessionToken") {
                        if (angular.isUndefined($rootScope.user) || $rootScope.user == null) {
                            //no message is needed
                            return;
                        }
                    }

                    $rootScope.user = null;

                    if(angular.isDefined(error.redirectUrl) && error.redirectUrl !== "") {
                        error.redirectUrl = normalizeUrl(error.redirectUrl);

                        error.errorMessage = $filter('CMSValue')('loginSiteRedirect') + ' ' + error.redirectUrl;

                        modalScope.data = error;

                        modalScope.confirmDialog = $uibModal.open({
                            appendTo: FindModalContainerFac('#angular-app'),
                            windowClass: 'login-error',
                            templateUrl: 'js/desktop/modules/Login/templates/login-error.html',
                            backdropClass: 'tvgmodal-backdrop',
                            windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                            scope: modalScope
                        });
                    }
                }

                function mediatorBalanceUpdate(balance) {
                    if ((balance === 0) || (balance && !isNaN(balance))) {
                        $rootScope.accountBalanceNumber = balance;
                        $rootScope.accountBalance = $filter('currency')(balance, '$');
                        $rootScope.$broadcast('accountBalance_changed', balance);
                    }
                }

                function mediatorUpdateFavoriteTracks(payload) {
                    if (payload && payload.favorites) {
                        FavoriteTracksFac.setFavoriteTracks(payload.favorites, payload.trackCode, payload.favoriteId);
                    }
                }

                MEDIATOR_SUBSCRIPT_CONFIG = [
                    {
                        topic: 'TVG_LOGIN:USER_SESSION_UPDATE',
                        callback: mediatorLoginLogout,
                        delayed: true
                    },
                    {
                        topic: 'TVG4:POPOUT_VIDEO',
                        callback: mediatorVideoPopOut,
                        delayed: false
                    },
                    {
                        topic: 'OPEN_QUICK_DEPOSIT',
                        callback: mediatorOnOpenQuickDeposit,
                        delayed: false
                    },
                    {
                        topic: 'TVG4_NAVIGATION',
                        callback: mediatorOnNavigation,
                        delayed: true
                    },
                    {
                        topic: 'APP_REDIRECT',
                        callback: mediatorOnRedirect,
                        delayed: true
                    },
                    {
                        topic: 'BALANCE_UPDATE',
                        callback: mediatorBalanceUpdate,
                        delayed: false
                    },
                    {
                        topic: 'UPDATE_FAVORITE_TRACKS',
                        callback: mediatorUpdateFavoriteTracks,
                        delayed: false
                    }
                ];

                function subscribeAll() {
                  _.each(MEDIATOR_SUBSCRIPT_CONFIG, function (subscription) {
                      subscription.delayed ?
                          mediator.subscribeWithPast(subscription.topic, subscription.callback) :
                          mediator.subscribe(subscription.topic, subscription.callback);
                  });
                }

                function unsubscribeAll() {
                  _.each(MEDIATOR_SUBSCRIPT_CONFIG, function (subscription) {
                      mediator.unsubscribe(subscription.topic, subscription.callback);
                  });
                }

                return {
                  subscribeAll: subscribeAll,
                  unsubscribeAll: unsubscribeAll
                };
        }

        MediatorSubscriptionsFac.$inject = [
          'SecuritySvc',
          '$uibModal',
          '$rootScope',
          '$window',
          '$timeout',
          '$filter',
          'FindModalContainerFac',
          'RunBlockMediatorFactory',
          'LoginRedirectFac',
          'RaceMtpStatusFac',
          'VideoPopOutFac',
          'FavoriteTracksFac'
        ];

        return MediatorSubscriptionsFac;
    });

