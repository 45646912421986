'use strict';

define('SiteTabsDir',[], function() {

    return function SiteTabsDir() {
        return {
            restrict: 'E',
            scope: true,
            templateUrl: 'js/desktop/modules/SiteTabs/templates/site-tabs-directive.html',
            controller: 'SiteTabsCtrl',
            controllerAs: 'vm'
        };
    };
});

