'use strict';

define('FeaturedTracksCtrl',[], function () {
    function FeaturedTracksCtrl(
            $scope,
            $rootScope,
            $location,
            GTMFac,
            GraphFeaturedTracks,
            RaceMtpStatusFac,
            $log,
            WagerProfileFac,
            $filter
        ) {
        var vm = this;
        var featuredTracksSubscription;
        var unbind = [];
        var graphPollerInterval = $filter('CMSLabels')('med', 'graphqlPollingRates') || 30000; // in ms

        /* istanbul ignore next */
        function _sortMTP(a, b) {
            if (a.races[0].mtp < b.races[0].mtp) return -1;
            if (a.races[0].mtp > b.races[0].mtp) return 1;
            return 0;
        }

        function _getMtpValues() {
            vm.model.orderedTracks.forEach(function (track) {
                track.races.forEach(function (race) {
                    var raceMtpInfo = RaceMtpStatusFac.getRaceMtpStatus(race.trackAbbr + '_' + race.raceNumber);

                    if (raceMtpInfo.mtpDisplay) {
                        race.mtpDisplay = raceMtpInfo.mtpDisplay;
                    }
                });
            });
        }

        function _subscribeMtpEvent() {
            unbind.push($rootScope.$on('mtpStatusUpdated', function () {
                _getMtpValues();
            }));
        }

        function _refetchTracks() {
            // @TODO should the fallback be TVG and not ALL_TACKS?
            var wagerProfile = WagerProfileFac.getSessionOrGenericProfile();
            var options = {
                wagerProfile: wagerProfile
            };

            if (!featuredTracksSubscription) {
                featuredTracksSubscription = GraphFeaturedTracks.subscribe(options, {
                    success: function (featureTracks) {
                        if (_.isArray(featureTracks)) {
                            vm.model.orderedTracks = featureTracks.sort(_sortMTP);

                            //Only the 3 elements case is different.
                            //Any other size we'll show to a limit of 5 tiles,when there's only 3 tracks we show 2 tiles.
                            vm.model.repeaterLimit = vm.model.orderedTracks.length == 3 ? 2 : 5;
                        }

                        // Update the races MTP with the global MTP
                        _getMtpValues();

                        vm.model.onAccessLoading = false;
                        $scope.$apply();
                        $scope.$emit('appReady', 'featuredTracks');
                    },
                    fail: function (err) {
                        $log.error('Error', err);
                        vm.model.onAccessLoading = false;
                    }
                }, graphPollerInterval, true);
            } else {
                vm.refreshData();
            }
        }

        (function () {
            vm.model = {
                onAccessLoading: true,
                orderedTracks: [],
                repeaterLimit: 5 //max default value
            };

            vm.events = {
                goToFullSchedule: function (event) {
                    if (event) {
                        event.preventDefault();
                    }
                    var obj = GTMFac.EventBuilder()
                        .withSectionName(GTMFac.Tvg4SectionName())
                        .withScreenName(GTMFac.Tvg4ScreenName())
                        .withGaEventAction('Home Page Click')
                        .withGaEventCategory('Site Click')
                        .withGaEventLabel('See Full Schedule')
                        .withEventLabel('Home-SeeFullSchedule')
                        .build();

                    GTMFac.GTMEvent().send($scope, 'siteClick', obj);

                    $location.path('/races');
                }
            };

            $scope.$on('logout', function () {
                if(featuredTracksSubscription) {
                    vm.refreshData();
                }
            });

            $scope.$on('$destroy', function () {
                if (featuredTracksSubscription){
                    featuredTracksSubscription.unsubscribe();
                }

                // destroy all possible rootScope listeners to avoid possible memory leaks
                unbind.forEach(function (bind) {
                    bind();
                });
            });

            $scope.$on('session', function () {
                _refetchTracks();
            });

            $scope.$on('login', function () {
                _refetchTracks();
            });

            vm.refreshData = function() {
                // @TODO should the fallback be TVG and not ALL_TACKS?
                var wagerProfile = WagerProfileFac.getSessionOrGenericProfile();

                GraphFeaturedTracks.refetch({wagerProfile: wagerProfile})
                    .then(null, function(err) {
                        $log.error('Error', err);
                    });
            };

            _subscribeMtpEvent();
        }());
    }

    FeaturedTracksCtrl.$inject = [
        '$scope',
        '$rootScope',
        '$location',
        'GTMFac',
        'GraphFeaturedTracks',
        'RaceMtpStatusFac',
        '$log',
        'WagerProfileFac',
        '$filter'
    ];

    return FeaturedTracksCtrl;
});

