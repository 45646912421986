"use strict";

define('OnScrollDir',[],function() {
    /*
    onScroll ng directive

    Purpose: Detect browser's window scroll events and apply custom css classes
    to current dom element according to the window's offset.
    */
    function OnScrollDir($window) {
        return {
            scope: {}, // {} = isolate, true = child, false/undefined = no change
            restrict: "A", // E = Element, A = Attribute, C = Class, M = Comment
            link: function($scope, iElm, iAttrs) {
                var onScrollDownClassName = "scrolled-down";
                var onScrollUpClassName = "scrolled-up";
                var scrollableContainer = $window;
                var lastY = $(scrollableContainer).scrollTop();

                // optionally override default values
                if (!_.isUndefined(iAttrs.onScrollDownClass)) {
                    onScrollDownClassName = iAttrs.onScrollDownClass;
                }
                if (!_.isUndefined(iAttrs.onScrollUpClass)) {
                    onScrollUpClassName = iAttrs.onScrollUpClass;
                }
                if (!_.isUndefined(iAttrs.onScrollMinHeight)) {
                    iAttrs.onScrollMinHeight = parseInt(
                        iAttrs.onScrollMinHeight
                    );
                }

                $scope.handleScroll = function(scrollY) {
                    var minHeight = iAttrs.onScrollMinHeight
                        ? iAttrs.onScrollMinHeight
                        : 0;

                    if (scrollY < minHeight) {
                        angular
                            .element(iElm)
                            .removeClass(onScrollDownClassName);
                    } else {
                        angular.element(iElm).addClass(onScrollDownClassName);
                    }
                };

                angular.element(scrollableContainer).on("scroll", function() {
                    $scope.handleScroll($(scrollableContainer).scrollTop());
                });
            }
        };
    }

    OnScrollDir.$inject = ["$window"];

    return OnScrollDir;
});

