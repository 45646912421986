'use strict';

define(
    'HelpCtrl',[],
    function () {

        function HelpCtrl($scope, $filter) {
            $scope.helpTabs = [
                {
                    name: 'Tutorial Videos',
                    src: 'js/desktop/views/help/tutorial-videos.html',
                    qaLabel: 'tutorial-videos',
                    askForLogin: false,
                    id: 'tutorial-videos',
                    show: function () {
                        return $scope.$parent.activeFeatures.tutorialVideos && $scope.$root.tutorialVideosFeature.length > 0;
                    }
                },
                {
                    name: 'Resource Center',
                    src: 'js/desktop/modules/Help/templates/forms-and-guides.html',
                    qaLabel: 'forms-and-guides',
                    askForLogin: false,
                    id: 'resource-center',
                    show: function () {
                        return true;
                    }
                },
                {
                    name: $filter('CMSValue')('comingSoonButton'),
                    src: 'js/desktop/views/coming-soon/coming-soon.html',
                    qaLabel: 'comingSoonButton',
                    askForLogin: false,
                    id: 'coming-soon',
                    show: function () {
                        return $scope.$parent.activeFeatures.enableComingSoonPage && $scope.$root.comingSoonFeatures.length > 0;
                    }
                }
            ];

        }

        HelpCtrl.$inject = [
            '$scope',
            '$filter'
        ];

        return HelpCtrl;
    }
);

