/**
 * angular-poller
 *
 * @description
 * Angular poller service. It uses a timer and sends requests every few seconds to
 * keep the client synced with the server.
 *
 * @version v0.3.1
 * @link http://github.com/emmaguo/angular-poller
 * @license MIT
 *
 * @example
 * Simple example:
 *      var myPoller = poller.get(target);
 *      myPoller.promise.then(null, null, callback);
 *
 * Advanced example:
 *      var myPoller = poller.get(target, {
 *          action: 'query',
 *          argumentsArray: [
 *              {
 *                  verb: 'greet',
 *                  salutation: 'Hello'
 *              }
 *          ],
 *          delay: 6000,
 *          smart: true,
 *          catchError: true
 *      });
 *      myPoller.promise.then(null, null, callback);
 */

'use strict';

(function (window, angular, undefined) {

    angular.module('TVG.poller', [])

        .constant('pollerConfig', {
            stopOnRouteChange: false,
            stopOnStateChange: false
        })

        .run(function ($rootScope, poller, pollerConfig) {

            /**
             * Automatically stop all pollers before route change ($routeProvider) or state change ($stateProvider).
             */
            if (pollerConfig.stopOnRouteChange) {

                $rootScope.$on('$routeChangeStart', function () {
                    poller.stopAll();
                });
            } else if (pollerConfig.stopOnStateChange) {

                $rootScope.$on('$stateChangeStart', function () {
                    poller.stopAll();
                });
            }
        })

        .factory('poller', function ($interval, $q, $http) {

            var pollers = [], // Poller registry

                defaults = {
                    action: 'get',
                    argumentsArray: [],
                    delay: 5000,
                    smart: false,
                    catchError: true,
                    deferInit: false
                },

                /**
                 * Poller model:
                 *  - target (can be $resource object, or Restangular object, or $http url)
                 *  - action
                 *  - argumentsArray
                 *  - delay
                 *  - smart (indicates whether poller should only send new request if the previous one is resolved)
                 *  - catchError (indicates whether poller should get notified of error responses)
                 *  - promise
                 *  - interval
                 *
                 * @param target
                 * @param options
                 */
                Poller = function (target, options) {

                    this.target = target;
                    this.set(options);
                    this.deferreds = [];
                },

                /**
                 * Find poller by target in poller registry.
                 *
                 * @param target
                 * @returns {object}
                 */
                findPoller = function (target, options) {

                    var poller = null;

                    angular.forEach(pollers, function (item) {
                        if (angular.equals(item.target, target) && item.action === options.action) {
                            poller = item;
                        }
                    });

                    return poller;
                };

            angular.extend(Poller.prototype, {

                /**
                 * Set poller action, argumentsArray, delay, smart and catchError flags.
                 *
                 * If options.action is defined, then set poller action to options.action,
                 * else if poller.action is undefined, then set it to defaults.action,
                 * else do nothing. The same goes for poller.argumentsArray, poller.delay, poller.smart and poller.catchError.
                 *
                 * @param options
                 */
                set: function (options) {

                    angular.forEach(['action', 'argumentsArray', 'delay', 'smart', 'catchError', 'deferInit'], function (prop) {
                        if (options && options[prop]) {
                            this[prop] = options[prop];
                        } else if (!this[prop]) {
                            this[prop] = defaults[prop];
                        }
                    }, this);
                },

                /**
                 * Start poller service.
                 */
                start: function () {

                    var target = this.target,
                        action = this.action,
                        argumentsArray = this.argumentsArray.slice(0),
                        delay = this.delay,
                        smart = this.smart,
                        catchError = this.catchError,
                        self = this,
                        current,
                        timestamp,
                        deferred,
                        deferInit = this.deferInit;

                    deferred = $q.defer();
                    deferred.promise.timestamp = Date.now();
                    this.deferreds.push(deferred);

                    /**
                     * $resource: typeof target === 'function'
                     * Restangular: typeof target === 'object'
                     * $http: typeof target === 'string'
                     */
                    if (typeof target === 'string') {

                        /**
                         * Update argumentsArray and target for target[action].apply(self, argumentsArray).
                         *
                         * @example
                         * $http.get(url, [config])
                         * $http.post(url, data, [config])
                         */
                        argumentsArray.unshift(target);
                        target = $http;
                    }

                    function tick() {

                        // If smart flag is true, then only send new request if the previous one is resolved.
                        if (!smart || !angular.isDefined(current) || current.$resolved) {

                            timestamp = new Date();
                            current = target[action].apply(self, argumentsArray);
                            current.$resolved = false;

                            /**
                             * $resource: current.$promise.then
                             * Restangular: current.then
                             * $http: current.then
                             */
                            (current.$promise || current).then(function (result) {

                                current.$resolved = true;

                                // Ignore success response if request is sent before poller is stopped.
                                if (angular.isUndefined(self.stopTimestamp) || timestamp >= self.stopTimestamp) {
                                    angular.forEach(self.deferreds, function (d) {
                                        d.notify(result);
                                    });
                                }

                            }, function (error) {
                                current.$resolved = true;

                                // Send error response if catchError flag is true and request is sent before poller is stopped
                                if (catchError && (angular.isUndefined(self.stopTimestamp) || timestamp >= self.stopTimestamp)) {
                                    angular.forEach(self.deferreds, function (d) {
										d.notify(error);
                                    });
                                }
                            });
                        }
                    }

                    if (!deferInit) {
                        tick();
                    }

                    this.interval = $interval(tick, delay);
                    this.promise = deferred.promise;
                    this.then = function then () {
                        this.promise.then.apply(this.promise, arguments);
                        return this.promise;
                    };
                },

                /**
                 * Stop poller service if it is running.
                 */
                stop: function () {

                    if (angular.isDefined(this.interval)) {
                        $interval.cancel(this.interval);
                        this.interval = undefined;
                        this.stopTimestamp = new Date();
                    }
                },

                /**
                 * Restart poller service.
                 */
                restart: function () {
                    this.stop();
                    this.start();
                },

                removeDeferred: function (promise) {
                    var activeDeferreds = [];

                    for (var i = 0; i < this.deferreds.length; i++) {
                        var d = this.deferreds[i];

                        if (!angular.equals(d.promise, promise)) {
                            activeDeferreds.push(d);
                        }
                    }
                    this.deferreds = activeDeferreds;
                    if (!this.deferreds.length) {
                        this.stop();
                    }
                }
            });

            return {

                /**
                 * Return a singleton instance of a poller. If poller does not exist, then register and
                 * start it. Otherwise return it and restart it if necessary.
                 *
                 * @param target
                 * @param options
                 * @returns {object}
                 */
                get: function (target, options) {

                    var poller = findPoller(target, options);

                    if (!poller) {

                        poller = new Poller(target, options);
                        pollers.push(poller);
                        poller.start();

                    } else {

                        poller.set(options);
                        poller.restart();
                    }

                    return poller;
                },

                /**
                 * Total number of pollers in poller registry.
                 *
                 * @returns {number}
                 */
                size: function () {
                    return pollers.length;
                },

                /**
                 * Stop all poller services.
                 */
                stopAll: function () {
                    angular.forEach(pollers, function (p) {
                        p.stop();
                    });
                },

                /**
                 * Restart all poller services.
                 */
                restartAll: function () {
                    angular.forEach(pollers, function (p) {
                        p.restart();
                    });
                },

                /**
                 * Stop and remove all poller services
                 */
                reset: function () {
                    this.stopAll();
                    pollers = [];
                }
            };
        }
    );
}(window, window.angular));

define("Poller", ["angular"], function(){});

