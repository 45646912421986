'use strict';

define('GeoComplySvc',['lodash', 'PollingScheduler'], function (_, PollingScheduler) {

    function GeoComplySvc($window, $log, $q, $http, RequestContextHeaders, ConfigurationFac) {

        /**
         * Configures geoComply object to interact with its API and registers the necessary events
         * @private
         */
        function _setUpGeoComply() {
        }

        /**
         * Gets a geo packet for the user.
         * @param userId
         * @returns {*|promise}
         * @private
         */
        function _getGeoPacket(userId) {
            return Promise.resolve();
        }

        function _getGeoClient() {
            return geoClient;
        }

        /**
         * Gets a list of Regions
         * @param latitude  
         * @param longitude
         * @private
         */
        function _getGeoRegion(position) {
            return Promise.resolve();
        }

        /**
         * Gets the geo position using the navigator.geolocation (only works in https)
         * @private
         */
        function _getPosition(callback) { }

        /**
         * Initializes the _getRegionScheduler polling
         * @returns {*}
         * @private
         */
        function _initializeGetRegionPolling(callback) { }

        return {
            getGeoPacket: _getGeoPacket,
            getGeoClient: _getGeoClient,
            initializeGetRegionPolling: _initializeGetRegionPolling,
            getPosition: _getPosition,
            setupGeoComply: _setUpGeoComply
        };

    }

    GeoComplySvc.$inject = [
        '$window',
        '$log',
        '$q',
        '$http',
        'RequestContextHeaders',
        'ConfigurationFac'
    ];

    return GeoComplySvc;
}
)
    ;

