'use strict';

define(
    'TrackPerformanceListFac',[

    ],
    function () {

        function TrackPerformanceListFac(RaceInfoFac, $rootScope) {

            var trackPerformanceList = null,
                deferred = null,
                subscriptions = [],
                trackSelected = null;

            function _getTrackPerformanceList(track, id){
                if(trackSelected !== track){
                    trackPerformanceList = null;
                    trackSelected = track;
                    _stopPollers();
                }
                _subscribe(id);
                if (trackPerformanceList) {
                    return trackPerformanceList;
                } else {
                    return [];
                }
            }

            // pollers logic
            function processTrackPerformanceList(data){
                trackPerformanceList = data ? data : [];
                $rootScope.$emit('trackPerformanceListReady', trackPerformanceList);
            }

            function _startPollers() {

                if(!deferred && subscriptions.length) {
                    deferred = RaceInfoFac.pollTrackCollection(trackSelected).then(null, null, processTrackPerformanceList);
                }
            }

            function _stopPollers() {
                if(deferred) {
                    RaceInfoFac.stopPollingTrackCollection(deferred);
                    deferred = null;
                }
            }

            function _restartPollers() {
                _stopPollers();
                _startPollers();
            }

            // subscribers
            function _subscribe(id) {
                var exist = _.find(subscriptions, function(subscription){
                    return subscription === id;
                });
                if(!exist) {
                    subscriptions.push(id);
                    _startPollers();
                }
            }

            function _unSubscribe(id) {
                subscriptions = _.filter(subscriptions, function(subscription) {
                    return subscription !== id;
                });
                if(subscriptions.length === 0) {
                    _stopPollers();
                    _resetTrackPerformance();
                }
            }

            function _resetTrackPerformance() {
                trackPerformanceList = [];
            }

            $rootScope.$on("wagerprofile", function () {
                _resetTrackPerformance();
                _restartPollers();
            });

            return {
                getTrackPerformanceList: _getTrackPerformanceList,
                resetTrackPerformance: _resetTrackPerformance,
                unSubscribe: _unSubscribe,
                subscribe: _subscribe
            };
        }

        TrackPerformanceListFac.$inject = [
            'RaceInfoFac',
            '$rootScope'
        ];

        return TrackPerformanceListFac;

   });

