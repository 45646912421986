'use strict';

define(
    'ContactDetailsCtrl',[
    ],
    function () {

        function ContactDetailsCtrl($scope, $rootScope) {

            $scope.contacts = {};

            $scope.setContacts = function () {
                if(!$rootScope.user)
                    return;
                $scope.phoneNumber = $rootScope.user.phoneNumber;
            };

            (function init(){
                $scope.setContacts();
            }());

            var unbindWatch = $rootScope.$watch("user", function () {
                $scope.setContacts();
            }, true);

            $scope.$on("$destroy", unbindWatch);

        }

        ContactDetailsCtrl.$inject = [
            '$scope',
            '$rootScope'
        ];

        return ContactDetailsCtrl;
    }
);

