'use strict';

define(
    'TodayAlertsCtrl',[],
    function () {
        function TodayAlertsCtrl($scope, $filter, RacesTodayFac, $location, RaceUrlFac) {
            (function init() {
                $scope.data = {
                    loading: true,
                    racesToday: {
                        racesTitle: {
                            0: {column: 'name', name: 'Name'},
                            1: {column: 'type', name: 'Type'},
                            2: {column: 'trackName', name: 'Track Name'},
                            3: {column: 'raceNumber', name: 'Race #'},
                            4: {column: 'postTime', name: 'Post Time'},
                            5: {column: 'mtp', name: 'MTP'}
                        },
                        races: [],
                        racesPaged: [],
                        sort: 'mtp',
                        sortReverse: false,
                        itemsPerPage: 10,
                        totalPages: 0,
                        activePage: 0,
                        pages: []
                    }
                };

                $scope.events = {
                    orderResults: _orderResults,
                    goToRace: _goToRace
                };

                var racesTodayDeferred = null;

                $scope.$on("$destroy", function () {
                    RacesTodayFac.stopPollingRacesToday(racesTodayDeferred);
                    $scope.data.loading = false;
                });

                racesTodayDeferred = RacesTodayFac.pollRacesToday().then(null, null, function (data) {
                    var types = data.types;
                    var races = data.races;

                    if (races && types) {
                        $scope.data.racesToday.races = [];

                        var currentTime = new Date();

                        races.forEach(function (race) {
                            var postTime = new Date(_getTimestamp(race.PostTime));
                            var mtp = _getTimeToPost(postTime, currentTime);
                            var newRace = {
                                'name': race.EntityName,
                                'type': types[race.EntityType],
                                'trackName': race.TrackName,
                                'raceNumber': race.Race,
                                'postTime': $filter('date')(postTime, 'hh:mm a'),
                                'mtp': mtp === 0 ? '--' : mtp,
                                'trackAbbr': race.TrackAbbreviation,
                                'perfAbbr': race.PerfAbbr
                            };

                            $scope.data.racesToday.races.push(newRace);
                        });
                        var sliceStart = $scope.data.racesToday.activePage * $scope.data.racesToday.itemsPerPage,
                            sliceEnd = sliceStart + $scope.data.racesToday.itemsPerPage;

                        $scope.data.racesToday.racesPaged = $scope.data.racesToday.races.slice(sliceStart, sliceEnd);
                        $scope.data.racesToday.totalPages = Math.ceil($scope.data.racesToday.races.length / $scope.data.racesToday.itemsPerPage);
                        $scope.data.racesToday.pages = _.range(1, $scope.data.racesToday.totalPages + 1);
                    }

                    $scope.data.loading = false;
                });
            }());

            function _getTimestamp(input) {
                if (!input) {
                    return 0;
                }
                var numbers = input.match(/[0-9]+/g);
                if (!numbers) {
                    numbers = [0];
                }
                return parseInt(numbers[0], 10);
            }

            function _getTimeToPost(postTime, currentTime) {
                var timeToPost = postTime - currentTime;
                timeToPost = timeToPost / 1000 / 60;
                if (timeToPost < 0) {
                    timeToPost = 0;
                }
                return Math.round(timeToPost);
            }

            function _orderResults(column) {
                if (column !== $scope.data.racesToday.sort) {
                    $scope.data.racesToday.sort = column;
                    $scope.data.racesToday.sortReverse = false;
                } else {
                    $scope.data.racesToday.sortReverse = !$scope.data.racesToday.sortReverse;
                }
            }

            function _goToRace(trackAbbr, trackName, raceNumber) {
                if (trackAbbr && trackName && raceNumber) {
                    $location.url(RaceUrlFac.calculateRacePath(trackAbbr, trackName, raceNumber));
                }
            }
        }

        TodayAlertsCtrl.$inject = [
            '$scope',
            '$filter',
            'RacesTodayFac',
            '$location',
            'RaceUrlFac'
        ];

        return TodayAlertsCtrl;
    }
);

