'use strict';

define(
    'BetslipCtrl',[],
    function () {

        function BetslipCtrl($scope, $rootScope, $routeParams, $timeout, BetslipFactory) {
            $scope.data = BetslipFactory.betslipData;
            $scope.events = {
                placeBet: BetslipFactory.placeBet,
                submitBet: BetslipFactory.submitBet,
                cancelBet: BetslipFactory.cancelBet,
                clearBetslip: BetslipFactory.clearBetslip,
                onBetAmountChanged: BetslipFactory.onBetAmountChanged
            };

            $scope.isToShow = BetslipFactory.isToShow;
            $scope.title = $routeParams.trackAbbr + ' - Race ' + $routeParams.race;

            $scope.$watch('data.bet.amountIsValid', function() {
                triggerBetslipAmountTooltip();
            });

            function triggerBetslipAmountTooltip() {
                // TODO - Please work this around to not manipulate DOM inside this controller
                $timeout(function() {
                    angular.element(document.querySelector('.betslip-form__action')).trigger('triggerTooltip');
                }, 0);
            }

            var unsubscribeSelectionChanges = $rootScope.$on('runnerSelectionsChanged',
                function (event, race, numberOfLegs, legNumber, selectedRunners) {
                    BetslipFactory.onRunnerSelectionsChanged(race, numberOfLegs, legNumber, selectedRunners);
                }
            );

            // Subscribe only until we have the wager types.
            var unsubscribeTrackData = $rootScope.$on('trackDataUpdated', function () {
                BetslipFactory.onTrackDataUpdated();

                // Then unsubscribe
                unsubscribeTrackData();
            });

            $scope.$on("wagerprofile", function () {
                BetslipFactory.tryToResubmit();
            });

            $scope.$on('$destroy', function () {
                BetslipFactory.clearBetslip();

                unsubscribeSelectionChanges();
                unsubscribeTrackData();
            });
        }

        BetslipCtrl.$inject = [
            '$scope',
            '$rootScope',
            '$routeParams',
            '$timeout',
            'BetslipFactory'
        ];

        return BetslipCtrl;
    }
);

