'use strict';

define(
    'AccountSummary',[
        'AccountSummarySvc',
        'AccountSummaryWagerDetailsCtrl',
        'AccountSummaryBasicDetailsCtrl',
        'AccountSummaryCtrl',
        'GTMMod'
    ],

    function (AccountSummarySvc, AccountSummaryWagerDetailsCtrl, AccountSummaryBasicDetailsCtrl, AccountSummaryCtrl) {

        angular.module('TVG.AccountSummary', [
            'TVG.RaceTrackCurrentBetsMod',
            'TVG.Common.GTM'
        ])
            .config(['$uibTooltipProvider', function($uibTooltipProvider){
                $uibTooltipProvider.setTriggers({
                    'showStartDateTooltip': 'hideStartDateTooltip'
                });
                $uibTooltipProvider.setTriggers({
                    'showEndDateTooltip': 'hideEndDateTooltip'
                });
            }])
            .service('AccountSummarySvc', AccountSummarySvc)

            .controller('AccountSummaryWagerDetailsCtrl', AccountSummaryWagerDetailsCtrl)
            .controller('AccountSummaryBasicDetailsCtrl', AccountSummaryBasicDetailsCtrl)
            .controller('AccountSummaryCtrl', AccountSummaryCtrl)

            .directive('accountSummary', function () {
                return {
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/AccountSummary/templates/account-summary.html',
                    controller: 'AccountSummaryCtrl',
                    controllerAs: "accountSummaryCtrl"
                };
            })

            .directive('accountSummaryWagerDetails', function() {
                return {
                    restrict: 'E',
                    scope: {
                        status: '=',
                        startDate: '=',
                        endDate: '='
                    },
                    templateUrl: 'js/desktop/modules/AccountSummary/templates/accountSummaryWagerDetails.html',
                    controller: "AccountSummaryWagerDetailsCtrl"
                };
            })

            .directive('accountSummaryBasicDetails', function() {
                return {
                    restrict: 'E',
                    scope: {
                        categoryId: '=',
                        startDate: '=',
                        endDate: '=',
                        url: '='
                    },
                    templateUrl: 'js/desktop/modules/AccountSummary/templates/accountSummaryBasicDetails.html',
                    controller: 'AccountSummaryBasicDetailsCtrl'
                };
            });
    }
);

