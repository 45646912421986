'use strict';

define('CurrentPageMetaInfoSingleton',['lodash'], function (_) {

    function CurrentPageMetaInfoSingleton() {

        var _currentPageMetaInfo = {};

        return {
            get instance() {
                return _currentPageMetaInfo;
            },
            set instance(pageMetaInfo) {
                _.assign(_currentPageMetaInfo, pageMetaInfo);
            }
        };
    }

    CurrentPageMetaInfoSingleton.$inject = [];

    return CurrentPageMetaInfoSingleton;
});

