'use strict';

define(
    'TrackSelectorItem',[
        'moment'
    ],
    function (moment) {

        function TrackSelectorItem(trackName, trackAbbr, raceNumber, racePostTime, perfAbbr) {
            this.trackName = trackName;
            this.trackAbbr = trackAbbr;
            this.raceNumber = raceNumber;
            this.racePostTime = racePostTime;
            this.perfAbbr = perfAbbr;
        }

        /***
         * This method checks if the time for the start of the race is less than 2h.
         * If so, it shows the MTP, otherwise, it shows the time in format HH:MM.
         */
        TrackSelectorItem.prototype.getTimeToBeDisplayed = function () {
            var timeToBeDisplayed = "N/A";
            var currentTime = new Date().getTime();

            var milisecondsToRaceStart = this.racePostTime - currentTime;
            var minutesToRaceStart = milisecondsToRaceStart / 1000 / 60;

            // 0 < minutesToRaceStart < 60
            if (minutesToRaceStart >= 0 && minutesToRaceStart <= 59) {
                timeToBeDisplayed = Math.round(minutesToRaceStart).toString() + " MTP";
            }
            // minutesToRaceStart < 0
            else if (minutesToRaceStart < 0) {
                timeToBeDisplayed = "0 MTP";
            }
            // minutesToRaceStart >= 60
            else {
                timeToBeDisplayed = moment(this.racePostTime).format("hh:mm A");
            }

            return timeToBeDisplayed;
        };

        return TrackSelectorItem;

    });

