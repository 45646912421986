"use strict";

define(
    'AccountSummaryDetailBuilder',[
        "AccountSummaryDetailEntity"
    ],
    function (AccountSummaryDetailEntity) {

        function builder() {
            var accountSummaryDetailEntity = new AccountSummaryDetailEntity();

            return {
                withId: function (id) {
                    accountSummaryDetailEntity.id = id;
                    return this;
                },
                withAmount: function (amount) {
                    accountSummaryDetailEntity.amount = amount;
                    return this;
                },
                withDescription: function (description) {
                    accountSummaryDetailEntity.description = description;
                    return this;
                },
                withTransactionDate: function (transactionDate) {
                    accountSummaryDetailEntity.transactionDate = transactionDate;
                    return this;
                },
                build: function () {
                    return accountSummaryDetailEntity;
                }
            };
        }

        return builder;
    }
);

