'use strict';

define('Utilities',[
        'YoutubeVideoDir',
        'DrawerMenuButtonDir',
        'InfoDialogDir',
        'InfiniteScrollDir',
        'FirstTimeHelperCtrl',
        'CookieFac',
        'LocalStorageFac',
        'StatePersistenceFac',
        'PreferencesCookieFac',
        'FavoriteCookieFac',
        'CustomerCookieFac',
        'YouTubeLoaderFac',
        'UserPropertiesSvc',
        'FeaturesHandlerSvc',
        'CalculatePurseFac',
        'DistanceConverterFac',
        'TimeUtilFac',
        'NewWindowGridFac',
        'RaceTypeIDFilter',
        'DistanceConverterFilter',
        'MathAbsoluteValueFilter',
        'LocationUnreloadFac',
        'AmountOptionsFac',
        'GetNestedPropertyValueFilter',
        'GetRelativeDayBasedOnGivenDateFilter',
        'MdrCompileHtml',
        'HtmlCompile',
        'TrackListFromMetadataFac',
        'ManageFavoritesFac',
        'RoundAmountFilter',
        'ReverseFilter',
        'PreventDefaultDir',
        'angularUTF8Base64',
        'angularCookie',
        'ngMd5',
        'TVGCommon',
        'WagerProfileFac',
        'TvgHostsFac',
        'BrowserCheckFac',
        'RaceMtpDisplay',
        'Track',
        'SignalProviderFac',
        'FindModalContainerFac',
        'PreferencesCookieReloadedFac'
    ],
    function (
        YoutubeVideoDir,
        DrawerMenuButtonDir,
        InfoDialogDir,
        InfiniteScrollDir,
        FirstTimeHelperCtrl,
        CookieFac,
        LocalStorageFac,
        StatePersistenceFac,
        PreferencesCookieFac,
        FavoriteCookieFac,
        CustomerCookieFac,
        YouTubeLoaderFac,
        UserPropertiesSvc,
        FeaturesHandlerSvc,
        CalculatePurseFac,
        DistanceConverterFac,
        TimeUtilFac,
        NewWindowGridFac,
        RaceTypeIDFilter,
        DistanceConverterFilter,
        MathAbsoluteValueFilter,
        LocationUnreloadFac,
        AmountOptionsFac,
        GetNestedPropertyValueFilter,
        GetRelativeDayBasedOnGivenDateFilter,
        MdrCompileHtml,
        HtmlCompile,
        TrackListFromMetadataFac,
        ManageFavoritesFac,
        RoundAmountFilter,
        ReverseFilter,
        PreventDefaultDir,
        angularUTF8Base64,
        angularCookie,
        ngMd5,
        TVGCommon, //eslint-disable-line no-unused-vars
        WagerProfileFac,
        TvgHostsFac,
        BrowserCheckFac,
        RaceMtpDisplay,
        Track,
        SignalProviderFac,
        FindModalContainerFac,
        PreferencesCookieReloadedFac
    ) {
        angular.module('TVG.Utilities', [
            'ipCookie',
            'TVGCommon',
            'TVG.Track'
        ])
            .value('THROTTLE_MILLISECONDS', null)
            .factory('RaceMtpDisplay', RaceMtpDisplay)
            .factory('CookieFac', CookieFac)
            .factory('LocalStorageFac', LocalStorageFac)
            .factory('StatePersistenceFac', StatePersistenceFac)
            .factory('CustomerCookieFac', CustomerCookieFac)
            .factory('PreferencesCookieFac', PreferencesCookieFac)
            .factory('FavoriteCookieFac', FavoriteCookieFac)
            .factory('YouTubeLoaderFac', YouTubeLoaderFac)
            .factory('CalculatePurseFac', CalculatePurseFac)
            .factory('DistanceConverterFac', DistanceConverterFac)
            .factory('TimeUtilFac', TimeUtilFac)
            .factory('NewWindowGridFac', NewWindowGridFac)
            .factory('FindModalContainerFac', FindModalContainerFac)
            .filter('roundAmount', RoundAmountFilter)
            .filter('RaceTypeIDFilter', RaceTypeIDFilter)
            .filter('DistanceFilter', DistanceConverterFilter)
            .filter('MathAbsoluteValueFilter', MathAbsoluteValueFilter)
            .filter('reverse',ReverseFilter)
            .factory('LocationUnreloadFac', LocationUnreloadFac)
            .factory('SignalProviderFac', SignalProviderFac)
            .factory('AmountOptionsFac', AmountOptionsFac)
            .factory('TrackListFromMetadataFac', TrackListFromMetadataFac)
            .factory('ManageFavoritesFac', ManageFavoritesFac)
            .factory('WagerProfileFac', WagerProfileFac)
            .factory('TvgHostsFac', TvgHostsFac)
            .factory('BrowserCheckFac', BrowserCheckFac)
            .factory('PreferencesCookieReloadedFac', PreferencesCookieReloadedFac)
            .service('UserPropertiesSvc', UserPropertiesSvc)
            .service('FeaturesHandlerSvc', FeaturesHandlerSvc)

            .controller('FirstTimeHelperCtrl', FirstTimeHelperCtrl)

            .directive('preventDefault', PreventDefaultDir)
            .directive('youtubeVideoDir', YoutubeVideoDir)
            .directive('drawerMenuButton', DrawerMenuButtonDir)
            .directive('infoDialog', InfoDialogDir)
			.directive('infiniteScroll', InfiniteScrollDir)
            .directive('mdrCompileHtml', MdrCompileHtml)
            .directive('htmlCompile', HtmlCompile)
            .directive("scrollToTopWhen", function () {
                return {
                    restrict: 'A',
                    scope: {
                        trigger: '=scrollToTop'
                    },
                    link: function postLink(scope, element, attrs) {
                        var unregisterListener = null;
                        element.on("$destroy", function () {
                            if (unregisterListener) {
                                unregisterListener();
                            }
                        });
                        unregisterListener = scope.$on(attrs.scrollToTopWhen, function () {
                            angular.element(element)[0].scrollTop = 0;
                        });
                    }
                };
            })
            .directive('appTitle', function () {
                return {
                    restrict: 'E',
                    replace: true,
                    template: '<title>{{appTitle}}</title>',
                    scope: true
                };
            })
            .directive('removeClass', function () {
                return {
                    restrict: 'A',
                    link: function (scope, element, attrs) {
                        element.removeClass(attrs.removeClass);
                    }
                };
            })
            .filter('trust', function ($sce) {
                return $sce.trustAsHtml;
            })
            .filter('truncate', function () {
                return function (text, length, end) {
                    if (isNaN(length))
                        length = 10;

                    if (end === undefined)
                        end = "...";

                    if (text.length <= length || text.length - end.length <= length) {
                        return text;
                    }
                    else {
                        return String(text).substring(0, length - end.length) + end;
                    }

                };
            })
            .filter('getNestedPropertyValue', GetNestedPropertyValueFilter)
            .filter('getRelativeDayBasedOnGivenDate', GetRelativeDayBasedOnGivenDateFilter)
            .constant('LoginBehaviourConst', {
                HOMEPAGE: 'HOMEPAGE',
                FAVORITE: 'FAVORITE',
                CLOSEST: 'CLOSEST'
            });

            angular.module("template/modal/backdrop.html", []).run(["$templateCache", function ($templateCache) {
                $templateCache.put("template/modal/backdrop.html",
                    "<div class=\"tvgmodal-backdrop\" modal-in-class=\"in\" modal-animation-class=\"fade\" ng-style=\"{'z-index': 1040 + (index && 1 || 0) + index*10}\"></div>");
            }]);

            angular.module("template/modal/window.html", []).run(["$templateCache", function ($templateCache) {
                $templateCache.put("template/modal/window.html",
                    "<div tabindex=\"-1\" class=\"tvgmodal {{ windowClass }} fade\" modal-render=\"{{$isRendered}}\" modal-in-class=\"in\" modal-animation-class=\"fade\" ng-style=\"{'z-index': 1050 + index*10, display: 'block'}\" >\n" +
                    "	<div class=\"tvgmodal-dismiss no-click-feedback\" ng-click=\"windowClass=='confirm-bet'||windowClass=='first-time-help'||windowClass=='quick-deposit'||close($event)\"></div>\n" +
                    "   <div class=\"tvgmodal-dialog\"><div class=\"tvgmodal-content\" ng-transclude></div></div>\n" +
                    "</div>");
            }]);
    }
);

