'use strict';

define('DropdownHoverDir',[],function() {
    /*
    Dropdown hover ng directive

    Purpose: Show element on parent mouseenter or hide on mouseleave
    */
    function DropdownHoverDir() {
        return {
            require: '?^dropdown',
            restrict: 'A',
            scope: {
                'autoClose': '@'
            },
            link: function (scope, element) {

                element.parent().bind('mouseenter', function() {
                    element.show();
                });

                element.parent().bind('mouseleave', function(event) {

                    if(scope.autoClose === 'disabled') {
                        return false;
                    }

                    //Prevent hiding when entering an autofill input
                    if(angular.isDefined(event.target)) {
                            element.hide();
                    }
                    else if(angular.isDefined(event.relatedTarget)) {
                        if(event.relatedTarget !== null) {
                            element.hide();
                        }
                    }
                    else {
                        element.hide();
                    }
                });

                //Hide element when a link is clicked
                scope.$on("$routeChangeStart", function () {
                    element.hide();
                });
            }
        };
    }

    return DropdownHoverDir;
});

