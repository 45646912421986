'use strict';

define(
    'ExternalComponents',[
        'ExternalComponentsSvc'
    ],

    function (ExternalComponentsSvc) {

        angular.module('TVG.ExternalComponents', [])
            .service('ExternalComponentsSvc', ExternalComponentsSvc);
    }
);

