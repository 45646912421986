'use strict';

define(
    'AccountUserProfileCtrl',[
        'PreferencesCookieFac'
    ],
    function () {

        function AccountUserProfileCtrl($scope, $rootScope) {

            $scope.withPassword = !!$rootScope.user.userName;

            $scope.events = {
                goto: _goto
            };

            function _goto(name) {
                switch (name) {
                    case "Change Pin":
                        $scope.push("js/desktop/modules/Account/templates/account-change-pin.html", {title: "Change Pin"});
                        break;
                    case "Change Password":
                        $scope.push("js/desktop/modules/Account/templates/account-change-password.html", {title: "Change Password"});
                        break;
                    case "Add Username":
                        $scope.push("js/desktop/modules/Account/templates/account-add-username.html", {title: "Add Username"});
                        break;
                    default:
                        break;
                }
            }


        }

        AccountUserProfileCtrl.$inject = [
            '$scope',
            '$rootScope'
        ];

        return AccountUserProfileCtrl;
    }
);

