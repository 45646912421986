'use strict';

define(
    'WidgetsDesktop',[
        'Widgets',
        'TopBarNavigationDir',
        'BetSlipOpenerHelperCtrl',
        'BetSlipOpenerHelperDir',
        'BetSlipOpenerHelperFac',
        'OnScrollDir',
        'DropdownHoverDir',
        'AlertBoxDir',
        'PromoWidgetDir',
        'LoginRedirectFac',
        'TvgAutocompleteMod',
        'DatePicker',
        'GTMMod'
    ],
    function (Widgets,
              TopBarNavigationDir,
              BetSlipOpenerHelperCtrl,
              BetSlipOpenerHelperDir,
              BetSlipOpenerHelperFac,
              OnScrollDir,
              DropdownHoverDir,
              AlertBoxDir,
              PromoWidgetDir,
              LoginRedirectFac
    ) {
        angular.module('TVG.WidgetsDesktop', [
            'TVG.Widgets',
            'TVG.DatePicker',
            'TVG.AutoComplete',
            'TVG.Common.GTM'
        ])
            .controller('BetSlipOpenerHelperCtrl', BetSlipOpenerHelperCtrl)
            .directive('topBarNavigation', TopBarNavigationDir)
            .directive('betslipOpenerHelper', BetSlipOpenerHelperDir)
            .directive('onScroll', OnScrollDir)
            .directive('dropdownHover', DropdownHoverDir)
            .directive('alertBox', AlertBoxDir)
            .directive('promoWidget', PromoWidgetDir)
            .factory('BetSlipOpenerHelperFactory', BetSlipOpenerHelperFac)
            .factory('LoginRedirectFac', LoginRedirectFac);


    }
);

