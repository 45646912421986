'use strict';

define('Track',[
        'TrackSvc'
    ], function (TrackSvc) {

        angular.module('TVG.Track', [])
            .factory('trackSvc', TrackSvc);
    }
);

