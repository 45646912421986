"use strict";

define(
    'MtpDisplayModel',[
        'RaceMTP'
    ],

    function (RaceMTP) {
        function MtpDisplay(date, mtp) {
            this.mtp = mtp;

            if (!angular.isDate(date)) {
                this.time = undefined;
            } else {
                this.time = new RaceMTP(date);
            }

            this.mtpStatus = this.getMtpStatus();
            this.timeText = this.toString();
        }

        MtpDisplay.prototype = {
            mtpStatusValues: ['near', 'med', 'far', 'time-format'],

            toString: function () {
                if (this.mtp) {
                    return this.mtp + ' MTP';
                } else {
                    if (this.time && this.time.getDisplayableTime) {
                        return this.time.getDisplayableTime();
                    }
                }

                return '';
            },

            getMtpStatus: function () {
                if (angular.isDefined(this.time) || angular.isDefined(this.mtp)) {
                    var mtpDate;

                    if (angular.isDefined(this.mtp)) {
                        mtpDate = { mtp: this.mtp };
                    } else if (this.time.getMTPDate) {
                        mtpDate = this.time.getMTPDate();
                    } else {
                        return MtpDisplay.prototype.mtpStatusValues[3];
                    }

                    if (mtpDate.mtp <= 5) {
                        return MtpDisplay.prototype.mtpStatusValues[0];
                    } else if (mtpDate.mtp <= 15) {
                        return MtpDisplay.prototype.mtpStatusValues[1];
                    } else if (mtpDate.mtp <= 60) {
                        return MtpDisplay.prototype.mtpStatusValues[2];
                    }
                }

                return MtpDisplay.prototype.mtpStatusValues[3];
            }
        };

        return MtpDisplay;

    });

