'use strict';

define('InfoDialogDir',[],function () {

    function InfoDialogDir() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                actionUrl: '=',
                action: '=',
                actionText: '@'
            },
            template: '<div qa-label="info-dialog" class="container"><div class="square"><div class="text" ng-transclude></div></div><div ng-if="actionUrl"><a class="text-green" href="{{actionUrl}}">{{actionText}}</a></div><div ng-if="action"><span class="clickable text-green" ng-click="action()">{{actionText}}</span></div></div>'
        };
    }

    return InfoDialogDir;
});
