'use strict';

define(
    'RacesNavigationCtrl',[
        'RaceInfoFac',
        'FavoriteCookieFac',
        'PreferencesCookieFac',
        'StatePersistenceFac'
    ],
    function () {

        function RacesNavigationCtrl(
            $scope,
            $rootScope,
            $log,
            RaceInfoFac,
            TrackInfoFac,
            FavoriteTracksFac,
            PreferencesCookieFac,
            $timeout,
            StatePersistenceFac,
            $filter,
            GTMFac,
            $location,
            GraphRacesListResults,
            WagerProfileFac,
            RaceReplaysGateway
        ) {

            $scope.model = {
                dateSelected: new Date(),
                raceReplaysTrackDates: [],
                maxDate: new Date(),
                minDate: new Date(moment().year(2006))
            };

            if (!$scope.startIndex)
                $scope.startIndex = 0;
            $scope.numOfItems = 10;
            $scope.itemsIndex = $scope.numOfItems * 2;
            $scope.isRacePath = $location.path().split('/')[1] === 'races';

            var startIndex = $scope.startIndex || 0,
                length = 0,
                withTabs = $scope.withTabs,
                withFilters = $scope.withFilters,
                onlyVideos = $scope.onlyVideos,
                checkUserPrefs = $scope.checkUserPrefs,
                fetchedRaces = [],
                stateKey = $scope.stateKey,
                previousSelectedTab = StatePersistenceFac.loadState(stateKey, true),
                raceNavSelectedFilter = _getSelectedFilter(),
                racesListResultsSubscription,
                racesListResultsInterval = $filter('CMSLabels')('med', 'graphqlPollingRates') || 60000; // in ms

            _setFilterByTimeForLoggedOutUsers();

            $rootScope.loading = true;

            $scope.data = {
                loading: true,
                polling: false,
                races: [],
                filteredRaces: [],
                racesByFavoriteTracks: [],
                withTabs: withTabs,
                withFilters: withFilters,
                listLayout: 'list',
                reverseOrder: false,
                user: $rootScope.user,
                onlyVideos: onlyVideos,
                favoriteTracks: FavoriteTracksFac.getFavoriteTracks(),
                isFavoriteTrack: _isFavoriteTrack,
                raceActionAddFavorite: $filter('CMSValue')('raceActionAddFromFavorite'),
                disableScroll: true,
                typeOfFilterTime: 'Filter by time'
            };

            _resetFilters();

            $scope.events = {
                selectFilter: _selectFilter,
                isTabSelected: _isTabSelected,
                favoriteToggle: _favoriteToggle,
                selectByTrack: _selectByTrack,
                updateRaceList: _updateRaceList,
                loadNextRaces: _loadNextRaces,
                goMyFavorites: _goMyFavorites,
                getLink: _getLink,
                filterTracks: _filterTracks,
                getSelectedTrackName: _getSelectedTrackName,
                trackChange: _trackChange,
                racesTrackingIdGen: _racesTrackingIdGen
            };

            function _racesTrackingIdGen(race, index){
                return (moment(race.raceDate).format('YYYYMMDD') || index) + '_' + race.trackAbbr + '_' + race.raceNumber;
            }

            if (_hasNoDefaultFilter()) {
                $scope.$on("user", function (event, user) {
                    _handleUserLoggedIn(user);
                });

                $scope.$on("logout", function () {
                    _handleUserLoggedOut();
                });

                $scope.$on("wagerprofile", function () {
                    if (!$scope.showResults) {
                        _processRacesData($rootScope.allowedRaces);
                    }
                });

                $scope.$on("preferencesUpdated", function () {
                    _processRacesData(fetchedRaces);
                });
            }

            $scope.$on("$destroy", function () {
                _stopPollingData();
            });

            // Only start polling race data after session is resolved
            $scope.$on("session", function() {
                _startPollingData();
            });

            function _handleUserLoggedIn(user) {
                $scope.data.user = user;
                $scope.data.favoriteTracks = FavoriteTracksFac.getFavoriteTracks();
                _processRacesData(fetchedRaces);
            }

            function _handleUserLoggedOut() {
                $scope.data.user = null;
                raceNavSelectedFilter = 'bytime';
                $scope.data.listLayout = 'list';
                _processRacesData(fetchedRaces);
            }

            function _stopPollingData() {
                if ($scope.isToday()) {
                    if (racesListResultsSubscription) {
                        racesListResultsSubscription.unsubscribe();
                    }
                }
            }

            function _getSelectedFilter() {
                if (_hasNoDefaultFilter()) {
                    return _getPreviousSelectedFilter();
                } else {
                    return $scope.defaultFilter;
                }
            }

            function _hasNoDefaultFilter() {
                return !$scope.defaultFilter;
            }

            function _isFilterSelected(filterName) {
                return raceNavSelectedFilter === filterName;
            }

            function _getPreviousSelectedFilter() {
                return stateKey && previousSelectedTab ? previousSelectedTab : 'bytime';
            }

            function _setFilterByTimeForLoggedOutUsers() {
                if (_isFavouritesSelectedAndUserLoggedOut()) {
                    raceNavSelectedFilter = 'bytime';
                }
            }

            function _isFavouritesSelectedAndUserLoggedOut() {
                return !$rootScope.user && raceNavSelectedFilter == 'favorites';
            }

            function _fetchDataFromGraph() {
                var options = {
                    wagerProfile: WagerProfileFac.getSessionOrGenericProfile(),
                    filterBy: {status: ["RO", "SK"]}
                };
                racesListResultsSubscription = GraphRacesListResults.subscribe(options, {
                    success: function (data) {
                        $scope.data.reverseOrder = true;

                        for (var i = 0; i < data.length; ++i) {
                            data[i].postTimeString = $filter('date')(data[i].postTime, 'hh:mm');
                            data[i].postTimeMarker = $filter('date')(data[i].postTime, 'a');
                            data[i].postTimeDay = _getTimeDayDescription(data[i].postTime, new Date()).toLocaleUpperCase();
                            data[i].fromResults = true;
                        }
                        _getTracks($scope.model.date);
                        _processRacesData(data);
                        _setFinishedLoading(data);
                        _stopPollingData();
                        if ($scope.$root && !$scope.$root.$$phase) {
                            $scope.$apply();
                        }
                        $scope.$emit('pageLoaded', 'results');
                    },
                    fail: function (err) {
                        $log.error('Error', err); // eslint-disable-line
                    }
                }, racesListResultsInterval, false);
            }

            function _startPollingData() {
                if ($scope.isToday()) {
                    if (!racesListResultsSubscription) {
                        $scope.data.polling = true;
                        _selectFilter(raceNavSelectedFilter);
                        _fetchDataFromGraph();
                    }
                }
            }

            function _getTimeDayDescription(raceDate, currentDayDate) {
                var raceDateDay = raceDate.getDate(),
                    tomorrow = new Date(),
                    yesterday = new Date(),
                    description = "";
                tomorrow.setDate(currentDayDate.getDate() + 1);
                yesterday.setDate(currentDayDate.getDate() - 1);

                if (raceDateDay === currentDayDate.getDate()) {
                    description = "today";
                } else if (raceDateDay === yesterday.getDate()) {
                    description = "ystrd";
                } else if (raceDateDay === tomorrow.getDate()) {
                    description = "tmrrw";
                } else {
                    description = $filter('date')(raceDate, 'MMM dd');
                }

                return description;
            }

            function _processRacesData(data) {
                if (data.length) {
                    length = $scope.maxItems || ($scope.itemsIndex || data.length);
                    fetchedRaces = data;
                    if (onlyVideos) {
                        $scope.data.races = fetchedRaces.filter(function (race) {
                            return race.liveStreaming;
                        }).slice($scope.startIndex, length);
                    } else {
                        $scope.data.races = fetchedRaces.slice($scope.startIndex, length);
                    }
                    $scope.data.featuredRaces = fetchedRaces.filter(function (race) {
                        return race.featured;
                    }).slice($scope.startIndex, length);
                    $scope.data.racesByTrack = RaceInfoFac.groupRacesByTrack(fetchedRaces);

                    if (_hasNoDefaultFilter() || _isFilterSelected('favorites')) {
                        _processFavoriteRaces();
                    }

                    if (_hasNoDefaultFilter()) {
                        _checkUserPrefs();
                    }
                    _processFilters();
                } else {
                    $scope.data.races = [];
                }
            }

            function _setFinishedLoading(data) {
                $scope.data.loading = false;
                $rootScope.loading = false;
                $rootScope.allowedRaces = data;
                $scope.data.disableScroll = false;
                $scope.data.polling = false;
            }

            function _processFavoriteRaces() {
                var favoriteTracks = FavoriteTracksFac.getFavoriteTracks(),
                    favoriteRaces = fetchedRaces.filter(function (race) {
                        if (favoriteTracks) {
                            return favoriteTracks.indexOf(race.trackAbbr) >= 0;
                        }
                    });

                $scope.data.favoriteRaces = favoriteRaces.slice(startIndex, length);

                $scope.data.racesByFavoriteTracks = RaceInfoFac.groupRacesByTrack(favoriteRaces);

                for (var i = 0; i < $scope.data.racesByFavoriteTracks.length; i++) {
                    $scope.data.racesByFavoriteTracks[i].isFavorite = true;
                }
            }

            function _getTracks(date) {
                date = date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
                TrackInfoFac.getTracksByDate(date).then(function(trackData) {
                    $scope.data.tracks = trackData;
                    $scope.data.selectedTrack = $scope.isToday() ? '' : $scope.data.tracks.length ? $scope.data.tracks[0].trackAbbr : '';
                });
            }

            function _checkUserPrefs() {
                if (!checkUserPrefs || !$rootScope.user) {
                    return;
                }

                var userPref = PreferencesCookieFac.getPreferences().home_show_races_by,
                    races = $scope.data.races;

                if (userPref == 'featured' && $scope.data.featuredRaces.length) {
                    races = $scope.data.featuredRaces;
                } else if (userPref == 'favorites') {
                    if ($scope.data.favoriteRaces.length) {
                        races = $scope.data.favoriteRaces;
                    } else if ($scope.data.featuredRaces.length) {
                        races = $scope.data.featuredRaces;
                    }
                }

                $scope.data.races = races;
            }

            function _selectFilter(name, gtmFlag) {
                if(raceNavSelectedFilter !== name) {
                    $scope.itemsIndex = $scope.numOfItems * 2;
                }

                if (name === 'bytime')
                    $scope.data.typeOfFilterTime = $scope.data.raceFilter.selectedTime.time;

                raceNavSelectedFilter = name;
                var newLayout = $scope.data.listLayout;
                $scope.data.loading = true;
                _processRacesData(fetchedRaces);

                var screenSuffix = {};
                var fireTag = false;
                switch (raceNavSelectedFilter) {
                    case 'bytime':
                        newLayout = 'list';
                        screenSuffix.sectionName = 'By Time';
                        break;
                    case 'featured':
                        newLayout = 'featuredList';
                        screenSuffix.sectionName = 'Featured';
                        break;
                    case 'favorites':
                        newLayout = 'favoritesGroupedList';
                        screenSuffix.sectionName = 'Favorites';
                        $scope.model.dateSelected = moment().toDate();
                        $scope.data.selectedTrack = '';
                        break;
                    default:
                        screenSuffix.sectionName = '';
                        break;
                }

                if (typeof ga !== 'undefined' && fireTag && $rootScope.pageReload) {
                    var pageViewTitle = eventParams.sectionName;
                    $rootScope.appTitle = pageViewTitle;
                }
                $rootScope.pageReload = true;

                $timeout(function () {
                    $scope.data.listLayout = newLayout;
                    if (!$scope.data.polling) {
                        $scope.data.loading = false;
                        $scope.$emit('appReady', 'featuredTracks');
                    }
                });

                if (stateKey) {
                    StatePersistenceFac.saveState(stateKey, raceNavSelectedFilter, true);
                }

                if (!_.isUndefined(gtmFlag)) {
                    // Acording to spreadsheet, this is under specification
                    var obj = GTMFac.EventBuilder()
                        .withSectionName(GTMFac.Tvg4SectionName(screenSuffix))
                        .withScreenName(GTMFac.Tvg4ScreenName())
                        .build();

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', obj);
                }
            }

            function _isTabSelected(name) {
                return raceNavSelectedFilter === name;
            }

            function _favoriteToggle(track) {
                track.isFavorite = !track.isFavorite;

                var userFavTracks = FavoriteTracksFac.getFavoriteTracks();

                if (track.isFavorite == true) {
                    $scope.data.racesByFavoriteTracks.push(track);
                } else {
                    for (var i = 0; i < $scope.data.favoriteRaces.length; ++i) {
                        if ($scope.data.racesByFavoriteTracks[i].trackAbbr == track.trackAbbr) {
                            $scope.data.racesByFavoriteTracks.splice(i, 1);
                            break;
                        }
                    }
                    var userFavTracksIndex = userFavTracks.indexOf(track.trackAbbr);
                    if (userFavTracksIndex >= 0) {
                        userFavTracks.splice(userFavTracksIndex, 1);
                    }
                }
                var favList = _extractFavTrackAbbrList(userFavTracks, $scope.data.racesByFavoriteTracks);

                FavoriteTracksFac.updateFavoriteTracks(favList);
            }

            function _extractFavTrackAbbrList(userFavTracks, dayFavTracks) {
                var ret = userFavTracks || [];

                for (var i = 0; i < dayFavTracks.length; i++) {
                    if (ret.indexOf(dayFavTracks[i].trackAbbr) < 0) {
                        ret.push(dayFavTracks[i].trackAbbr);
                    }
                }

                return ret;
            }

            function _isFavoriteTrack(track) {
                var ret = false;

                for (var i = 0; i < $scope.data.racesByFavoriteTracks.length; i++) {
                    if ($scope.data.racesByFavoriteTracks[i].trackAbbr == track.trackAbbr) {
                        ret = true;
                        return ret;
                    }
                }
                return ret;
            }

            function _selectByTrack() {
                _selectFilter('bytrack');
            }

            function _goMyFavorites(){
                $location.path('my-favorite-tracks');
            }

            function _loadNextRaces() {
                if ($scope.itemsIndex < fetchedRaces.length) {
                    $scope.itemsIndex = $scope.itemsIndex + $scope.numOfItems;
                    _processRacesData(fetchedRaces);
                }
            }

            function _processFilters() {
                var filterResult;

                switch (raceNavSelectedFilter) {
                    case "featured":
                        _resetByTimeFilter();
                        filterResult = _getFilteredRaces($scope.data.featuredRaces, false);
                        $scope.data.featuredRaces = filterResult.races;
                        break;
                    case "favorites":
                        _resetByTimeFilter();
                        filterResult = _getFilteredRaces($scope.data.racesByFavoriteTracks, true);
                        $scope.data.racesByFavoriteTracks = filterResult.races;
                        break;
                    default:
                        filterResult = _getFilteredRaces(fetchedRaces, false);
                        $scope.data.races = filterResult.races.slice(startIndex, length);
                }
                $scope.data.raceFilter.trackSelected = filterResult.trackSelected;
                $scope.data.raceFilter.tracks = filterResult.tracks;
            }

            function _resetByTimeFilter() {
                $scope.data.raceFilter.selectedTime = $scope.data.raceFilter.raceTime[0];
            }

            //reset filters
            function _resetFilters() {
                $scope.data.raceFilter = {};
                $scope.data.raceFilter.tracks = [];
                $scope.data.raceFilter.raceTime = [
                    {id: 0, time: "Up to end of day"},
                    {id: 1, time: "Next 2 hours"}
                ];
                $scope.data.raceFilter.selectedTime = $scope.data.raceFilter.raceTime[0];
                $scope.data.raceFilter.tracks = RaceInfoFac.resetTracks();
                $scope.data.raceFilter.trackSelected = $scope.data.raceFilter.tracks[0];
                $scope.data.raceFilter.streamingVideoChecked = false;
                $scope.data.raceFilter.onTvgChecked = false;
                //insure that the filter is apply to components when the user return to page
                _selectFilter(raceNavSelectedFilter);
            }

            //update function for the directive
            function _updateRaceList() {
                _processRacesData(fetchedRaces);
                $scope.itemsIndex = $scope.numOfItems * 2;
            }

            //get filtered races and new tracks filter
            function _getFilteredRaces(races, grouped) {
                var raceFilter = $scope.data.raceFilter;
                return RaceInfoFac.racesFilters(races, raceFilter.trackSelected, raceFilter.selectedTime,
                    raceFilter.tracks, raceFilter.streamingVideoChecked, raceFilter.onTvgChecked, grouped);
            }


            /* istanbul ignore next */
            function _changeDay(date) {
                $scope.data.races = [];
                $scope.data.tracks = [];
                $scope.data.selectedTrack = '';
                return RaceInfoFac.getRaceResultsList({date:date}).then(function (data) {
                    _processRacesData(data);
                    _setFinishedLoading(data);
                    _stopPollingData();
                    _getTracks($scope.model.dateSelected);
                });
            }

            function _filterTracks(element) {
                _loadNextRaces();
                $scope.data.disableScroll = true;
                if($scope.data.selectedTrack) {
                    return element.trackAbbr.trim() === $scope.data.selectedTrack.trim();
                } else {
                    return true;
                }
            }

            function _trackChange() {
                $timeout(function () {
                    var date;
                    if (!$scope.isToday() && $scope.data.filteredRaces && !$scope.data.filteredRaces.length) {
                        date = moment($scope.model.dateSelected).format('YYYY-MM-DD');
                        $scope.data.loading = true;
                        RaceReplaysGateway.getRacesReplaysByTrackAndDate($scope.data.selectedTrack, date).then(function (races) {
                            $scope.data.loading = false;
                            races.forEach(function (race) {
                                race.fromResults = true;
                            });
                            Array.prototype.push.apply($scope.data.races, races);
                        });
                    }
                });
            }

            function _getSelectedTrackName() {
                var selectedTrack;
                if ($scope.data.tracks) {
                    selectedTrack = $scope.data.tracks.filter(function(track) {
                        return track.trackAbbr === $scope.data.selectedTrack;
                    });

                    if(selectedTrack.length && $scope.isToday()) {
                        return selectedTrack[0].name;
                    } else if (!selectedTrack.length && $scope.data.tracks.length && !$scope.isToday()){
                        return $scope.data.tracks[0].name;
                    } else {
                        return selectedTrack[0] ? selectedTrack[0].name : '';
                    }
                }

                return '';
            }

            $scope.isToday = function() {
                var dateSelected = moment($scope.model.dateSelected).format('YYYY-MM-DD');
                return dateSelected === moment().format("YYYY-MM-DD");
            };

            /* istanbul ignore next */
            $scope.$watch("model.dateSelected", function () {
                var date = moment($scope.model.dateSelected).format('YYYY-MM-DD');
                if(!$scope.isToday()) {
                    _changeDay(date);
                    $scope.data.loading = true;
                    $scope.changedDate = true;
                } else {
                    _fetchDataFromGraph();
                }
            });

            function _getLink(race, autoplay) {
                if($scope.isToday()) {
                    autoplay = autoplay == true ? '?autoplay=true':'';
                    return $filter('raceLink')(race) + autoplay;
                }
            }
        }

        RacesNavigationCtrl.$inject = [
            '$scope',
            '$rootScope',
            '$log',
            'RaceInfoFac',
            'TrackInfoFac',
            'FavoriteTracksFac',
            'PreferencesCookieFac',
            '$timeout',
            'StatePersistenceFac',
            '$filter',
            'GTMFac',
            '$location',
            'GraphRacesListResults',
            'WagerProfileFac',
            'RaceReplaysGateway'
        ];
        return RacesNavigationCtrl;
    }
);

