function bootApplication() {  //eslint-disable-line no-unused-vars
    angular.bootstrap(document, ['TVG']);
}

require([
    'appDesktop'
], function () {
    initializeApp();
});

define("desktop/bootstrap", function(){});

