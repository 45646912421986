"use strict";

define('VideoProviderSvc',[
    'moment',
    'lodash'
    ],
    function(moment, _) {

    /**
     * RCN video provider
     * (creates Flash and HTML5 video players, including all authorization requests)
     *
     *
     * RCN DOCUMENTATION (stored in JIRA):
     *
     * Live stream:
     * https://betfairus.atlassian.net/secure/attachment/39026/39026_LiveVideo+-Flash-ConnectInfo-TokenBased-R2.pdf
     * https://betfairus.atlassian.net/secure/attachment/39027/39027_Mobile_LiveVideo-ConnectInfo-TokenBased_V2.pdf
     *
     * Replays:
     * https://betfairus.atlassian.net/secure/attachment/39030/39030_Replays-Flash-ConnectionInfo-TokenBased_V3.pdf
     * https://betfairus.atlassian.net/secure/attachment/39029/39029_Replay_DB_Webservice_wmvflashmobile_Flashmulti_V2.pdf
     * https://betfairus.atlassian.net/secure/attachment/39025/39025_HeadonReplay_DB_Webservice_wmvflashmobile_Flashmulti_V2.pdf
     * https://betfairus.atlassian.net/secure/attachment/39028/39028_MobileHeadons_Replays-ConnectInfo-TokenBased_V3.pdf
     *
     *
     * COMPATIBILITY ISSUES:
     * RCN seems to provide RSTP and HLS and streaming solutions, so some compatibility issues
     * show up. We must investigate if HLS.js may be implemented as a fallback to play HLS.
     *
     * https://github.com/dailymotion/hls.js/tree/master
     */
    function videoProviderSvc(
            $q,
            $http,
            $filter,
            $window,
            $location,
            $rootScope,
            ConfigurationFac
        ) {

        // module exports
        // (let us mock methods from this service)
        var module = {};

        // streams
        var FLASH_LIVE_URL   = '//stream.robertsstream.com/streamflash.php';                // ?stream=*&usr=*&referer=*&t=*&h=*
        var FLASH_REPLAY_URL = '//replays.robertsstream.com/racereplays/replaysflash.php';  // ?cust=*&stream=*&t=*&h=*
        var HTML5_LIVE_URL   = '//stream.robertsstream.com/streammobile.php';               // ?stream=*&usr=*&referer=*&t=*&h=*&speed=*
        var HTML5_REPLAY_URL = '//replays.robertsstream.com/racereplays/replaysmobile.php'; // ?race=*&usr=*&cust=*&t=*&h=*&sp eed=*
        var IFRAME_HTML5_LIVE_URL   = '//stream.robertsstream.com/streamlive.php';               // ?stream=*&usr=*&referer=*&t=*&h=*&speed=*
        var IFRAME_HTML5_REPLAY_URL = '//replays.robertsstream.com/racereplays/replaysflash.php'; // ?race=*&usr=*&cust=*&t=*&h=*&sp eed=*

        // flash players (swf players)
        var FLASH_LIVE_PLAYER_SRC   = '//stream.robertsstream.com/flash/rcnplayer_c2.2.2.swf';
        var FLASH_REPLAY_PLAYER_SRC = '//replays.robertsstream.com/racereplays/flash/rcnplayer_2.2.0.swf';

        // RCN apis
        var RCN_HASH_URL    = ConfigurationFac.getBaseServiceUrl() + '/rcn/v1/generateHash';
        var REPLAY_INFO_URL = ConfigurationFac.getBaseServiceUrl() + '/rcn/v1/fetchReplayData';
        var AUTH_URL        = '//auth.robertsstream.com/auth/flashauth.php';

        var ERRORS = {
            INVALID_HASH   : 'invalidhash',
            NOT_AUTHORIZED : 'notauthorized',
            EXPIRED        : 'expired',
            INVALID_PARAM  : 'invalidparam',
            GENERIC_ERROR  : 'error'
        };

        var tracksStreamMap = _loadTracksStreamMap();


        /**
         * Extract response.data from $http response
         * @TODO move to utilities
         *
         * @param {Object} response       HTTP response
         * @param {Object} response.data  Response data
         * @return {Object}               Response data
         */
        function _extractDataFromResponse(response) {
            /* istanbul ignore next */
            return (response || {}).data;
        }

        /**
         * RCN returns stringified JSON for valid requests and normal strings
         * for invalid ones
         * @TODO move to utilities
         *
         * @param  {String} response  RCN stringified response
         * @return {Object/String}    Parsed JSON or error string
         */
        function _parseJsonResponse(response) {
            var data;

            try {
                data = JSON.parse(response);
            } catch(e) {
                // return error string back if is not a valid json
                data = response;
            }

            return data;
        }

        /**
         * Convert into query string
         * @TODO move to utilities
         *
         * @return {String}  Query string (with leading ?)
         */
        function _toQueryString(params) {
            /* istanbul ignore next */
            params = params || {};

            return Object.keys(params).reduce(function (acc, key) {
                var value = encodeURIComponent(params[key]);
                key = encodeURIComponent(key);

                acc += (acc) ? '&' : '?';
                acc += (key + '=' + value);

                return acc;
            }, '');
        }

        /**
         * Try to get initial streams map from CMS string
         * @return {Object}  Tracks streams map
         */
        function _loadTracksStreamMap() {
            var stringifiedMap = $filter('CMSValue')('RCNLiveStreamTrackMap');
            var tracksMap;

            /* istanbul ignore next */
            try {
                tracksMap = JSON.parse(stringifiedMap) || {};
            } catch(err) {
                tracksMap = {};
            }

            return tracksMap;
        }

        /**
         * Get RCN doDebug value from browser's query string
         * @return {String} RCN doDebug value
         */
        function _getRcnDoDebug() {
            var debug = $location.search().debugRCN;
            /* istanbul ignore next */
            var value = (debug === 'true') ? 'true' : 'false'; // must be string!

            return value;
        }

        /**
         * Is video the video allowed to play in HD mode?
         * For now, only TVG1 and TVG2 live channels and a small list of live
         * tracks are supported (we must respect this list and feature toggles).
         *
         * @param {String}  trackId   Track ID (may be a channel)
         * @param {Boolean} isReplay  Is it a replay video?
         * @return {Boolean}          Whether it's HD
         */
        function _isHD(trackId, isReplay) {
            var isHDLiveChannel = _isHDLiveChannel(trackId);
            var isHDTrack = (isReplay) ?
                _isHDReplayTrack(trackId) : _isHDLiveTrack(trackId);

            return !!(isHDLiveChannel || isHDTrack);
        }

        /**
         * Is it a HD live channel?
         * @param {String} trackId  Track ID (may be a channel)
         * @return {Boolean}        Whether it's HD
         */
        function _isHDLiveChannel(trackId) {

            // feature is disabled
            if (!$rootScope.activeFeatures.rcnHdLiveChannels){
                return false;
            }

            // TVG channels are HD
            return _isTvgChannel(trackId);
        }

        /**
         * Check if trackId is from a TVG1 or TVG2 channel
         * @param  {String}  trackId  Track's ID
         * @return {Boolean}          Is TVG channel?
         */
        function _isTvgChannel(trackId) {
            return (trackId === 'TVG1' || trackId === 'TVG2');
        }

        /**
         * Is it a supported HD live track?
         * @param {String}  trackId   Track ID
         * @return {Boolean}          Whether it's HD
         */
        function _isHDReplayTrack(trackId) {
            var track = tracksStreamMap[trackId] || {};

            // feature is disabled
            if (!$rootScope.activeFeatures.rcnHdReplayTracks){
                return false;
            }

            return !!track.isReplayHD;
        }

        /**
         * Is it a supported HD replay track?
         * @param {String}  trackId   Track ID
         * @return {Boolean}          Whether it's HD
         */
        function _isHDLiveTrack(trackId) {
            var track = tracksStreamMap[trackId] || {};

            // feature is disabled
            if (!$rootScope.activeFeatures.rcnHdLiveTracks){
                return false;
            }

            return !!track.isStreamHD;
        }

        /**
         * Find the correct stream name for a trackId using the
         * provided stream and replay code or streams map from CMS.
         *
         * @param  {Object}  raceInfo   Race information
         * @return {String/Boolean}    Stream name (or false if undefined)
         */
        function _getStreamName(raceInfo) {
            var feats = $rootScope.activeFeatures || {};
            var useCMS = !!raceInfo.useRCNMappingFromCMS;
            var useConsole = !!(feats.rcnMappingsFromGraph && feats.graphqlEnabled && feats.graphqlProgramPage);
            var isTvgChannel = _isTvgChannel(raceInfo.trackId);

            // for now, use only RCN from new TVG Console if its not a TVG1/TVG2 video,
            // and the player controller didn't force the CMS fallback using "useRCNMappingFromCMS"
            // (not all race replays are in Console right now)
            if (!useCMS && ((useConsole && !isTvgChannel) || (isTvgChannel && raceInfo.streamCode))) {
                return (!raceInfo.isReplay) ?
                    raceInfo.streamCode : raceInfo.replayFileName || raceInfo.replayCode;
            }

            return (!raceInfo.isReplay) ?
                _getLiveStreamName(raceInfo.trackId) :
                _getReplayStreamName(raceInfo.trackId);
        }

        /**
         * Get track stream name from streams map provided by RCN
         * @param  {String} trackId  Track abbreviation
         * @return {String/Boolean}  Stream name (or false if undefined)
         */
        function _getLiveStreamName(trackId) {
            /* istanbul ignore next */
            var track = tracksStreamMap[trackId] || {};

            return track.stream || false;
        }

        /**
         * Get track replay name (or if available?) from streams map provided by RCN
         * @param  {String} trackId  Track abbreviation
         * @return {Boolean}         Stream replay (or false if undefined)
         */
        function _getReplayStreamName(trackId) {
            /* istanbul ignore next */
            var track = tracksStreamMap[trackId] || {};

            return track.replay || false;
        }

        /**
         * Generates the correct stream URL according to the variables
         * isFlash and isReplay, and appending the additional parameters as
         * query string
         * (params object is converted into query string or appended to the end
         * of stream URL)
         *
         * @param  {Boolean}        isFlash   Is a flash player?
         * @param  {Boolean}        isReplay  Is a replay?
         * @param  {Object/String}  params    Parameters hash or query string (with leading ?)
         * @return {Object}                   Stream URL
         */
        function _createStreamUrl(videoType, isReplay, params) {
            var urls = {
                flash_live   : FLASH_LIVE_URL,
                flash_replay : FLASH_REPLAY_URL,
                html5_live   : HTML5_LIVE_URL,
                html5_replay : HTML5_REPLAY_URL,
                iframe_live   : IFRAME_HTML5_LIVE_URL,
                iframe_replay : IFRAME_HTML5_REPLAY_URL

            };
            var key = '';
            var url = '';


            key += videoType;
            key += isReplay ? '_replay' : '_live';

            url = urls[key];


            /* istanbul ignore else */
            if (params) {
                if (typeof params === 'string') {
                    url += params;
                } else {
                    url += _toQueryString(params);
                }
            }

            return url;
        }

        /**
         * Get the live stream URL from RCN api
         * (also used to validate if flash stream is available)
         *
         * @param {Object}  opts             Stream authorization parameters
         * @param {String}  opts.streamName  Stream name
         * @param {Boolean} opts.isReplay    Is a race/horse replay?
         * @param {String}  opts.timestamp   Timestamp to generate hash
         * @param {String}  opts.tokenHash   Authorization token
         * @param {String}  opts.format      Stream forced format
         * @return {Promise}                 Stream link (promised)
         */
        function _requestStreamUrl(opts) {

            var response = _.cloneDeep(opts);
            var params = _buildStreamParams(opts)
                .withFormat(opts.format)
                .withHD(_isHD(opts.trackId, opts.isReplay))
                .withOutput('json')
                .output();


            // this should be used in a indexof or match with regex...
            var errors = {
                'There was an error: invalidhash'   : ERRORS.INVALID_HASH,
                'There was an error: notauthorized' : ERRORS.NOT_AUTHORIZED,
                'There was an error: expired'       : ERRORS.EXPIRED,
                'There was an error: invalidparam'  : ERRORS.INVALID_PARAM
            };

            // always ask for HTML5 URL, either to validate the Flash streaming or
            // to use the HTML5 URL as <video> source (flash endpoint doesn't work here...)
            var url = _createStreamUrl("html5", opts.isReplay, params);


            // jsonp request cannot be used since the error response returns as string... :(
            return $http({
                    method: 'GET',
                    url: url,
                    transformResponse: [ _parseJsonResponse ],
                    headers: {
                        'X-ClientApp': undefined,
                        'x-tvg-context': undefined,
                    },
                    withCredentials: false
                })
                .then(_extractDataFromResponse)
                .then(function (data) {

                    // error
                    if (typeof data !== 'object' || !data || !data.link) {
                        return $q.reject(errors[data]);
                    }

                    // success
                    response.streamLink = data.link;

                    return response;
                })
                .catch(function (err) {
                    err = (err && typeof err === 'string') ?
                        err : ERRORS.GENERIC_ERROR;

                    return $q.reject(err);
                });
        }

        /**
         * Gets the track name trough the videoinfo service
         *
         * @param {Object}  opts                 Stream authorization parameters
         * @param {String}  opts.streamName      Stream name
         * @param {Boolean} opts.isReplay        Is a race/horse replay?
         * @param {String}  opts.timestamp       Timestamp to generate hash
         * @param {String}  opts.tokenHash       Authorization token
         * @param {String}  opts.format          Stream forced format
         * @param {Object}  raceInfo             Race informations
         * @param {Object}  raceInfo.raceNumber  Order of the race
         * @param {Object}  raceInfo.raceDate    Race date
         * @return {Promise}                     Replay stream name (promised)
         */
        function _requestReplayStreamName(opts, raceInfo) {
            var response = _.cloneDeep(opts);
            var qs = _toQueryString({
                date  : moment(raceInfo.date).format('YYYYMMDD'),
                track : opts.streamName,
                race  : raceInfo.raceNumber
            });

            if (raceInfo.replayFileName) {
                response.streamName = raceInfo.replayFileName;
                return $q.resolve(response);
            }

            return $http({
                    method: 'GET',
                    url: REPLAY_INFO_URL + qs
                })
                .then(_extractDataFromResponse)
                .then(function (data) {

                    // error
                    if (data && data.status === 'error') {
                        return $q.reject(data.message);
                    }

                    // error
                    if (!data || !data.filename) {
                        return $q.reject('noreplay');
                    }

                    // success
                    response.streamName = data.filename;

                    return response;
                })
                .catch(function (err) {
                    err = (err && typeof err === 'string') ?
                        err : ERRORS.GENERIC_ERROR;

                    return $q.reject(err);
                });
        }

        /**
         * Generate stream authorization hash
         * (returns it appended in input object)
         *
         * @param {Object}  opts             Stream authorization parameters
         * @param {String}  opts.streamName  Stream name
         * @param {Boolean} opts.isReplay    Is a race/horse replay?
         * @param {String}  opts.timestamp   Timestamp to generate hash
         * @param {String}  opts.format      Stream forced format
         * @return {Promise}                 Data object containing token hash and other
         *                                   stream properties
         */
        function _generateHash(opts) {
            var response = _.cloneDeep(opts);
            var context = JSON.stringify(ConfigurationFac.getApplicationContext());
            var qs = _toQueryString({
                timestamp: opts.timestamp,
                streamname: opts.streamName
            });

            return $http({
                    method: 'GET',
                    url: RCN_HASH_URL + qs,

                })
                .then(_extractDataFromResponse)
                .then(function (data) {

                    // error
                    if (typeof data !== 'object' || !data || !data.hash){
                        return $q.reject(ERRORS.INVALID_HASH);
                    }

                    // success
                    response.tokenHash = data.hash;

                    return response;
                })
                .catch(function (err) {
                    err = (err && typeof err === 'string') ?
                        err : ERRORS.GENERIC_ERROR;

                    return $q.reject(err);
                });
        }

        /**
         * Builds stream URL parameters
         *
         * @param  {Object} [opts]            Stream options
         * @param  {Object} [opts.timestamp]  Authorization timestamp (optionally inherited from parent scope)
         * @param  {Object} [opts.tokenHash]  Token hash (optionally inherited from parent scope)
         * @param  {Object} [opts.replay]     Is it a replay?
         * @param  {Object} [opts.streamName] Stream name
         * @return {Object}                   Parameters builder
         */
        function _buildStreamParams(opts) {
            var streamParams = {
                usr: '',
                t: opts.timestamp,
                h: opts.tokenHash
            };

            if (opts.isReplay) {
                streamParams.race = opts.streamName;
                streamParams.cust = opts.referer;
            } else {
                streamParams.stream = opts.streamName;
                streamParams.referer = opts.referer;
            }

            return {
                withSize: function(width, height) {
                    streamParams.width = width;
                    streamParams.height = height;
                    return this;
                },
                withOutput: function(type) {
                    streamParams.output = type;
                    return this;
                },
                withFormat: function(format) {
                    streamParams.forceformat = format;
                    return this;
                },
                withHD: function(isHD) {
                    streamParams.hd = (isHD) ? '1' : '0';
                    return this;
                },
                output: function() {
                    return _toQueryString(streamParams);
                }
            };
        }

        /**
         * Builds stream URL parameters
         *
         * @param  {Object} [opts]            Stream options
         * @param  {Object} [opts.timestamp]  Authorization timestamp (optionally inherited from parent scope)
         * @param  {Object} [opts.tokenHash]  Token hash (optionally inherited from parent scope)
         * @param  {Object} [opts.replay]     Is it a replay?
         * @param  {Object} [opts.streamName] Stream name
         * @return {Object}                   Parameters builder
         */
        function _buildNewStreamParams(opts) {
            var streamParams = {
                t: opts.timestamp,
                h: opts.tokenHash
            };

            if (opts.isReplay) {
                streamParams.stream = opts.streamName;
                streamParams.cust = opts.referer;
            } else {
                streamParams.stream = opts.streamName;
                streamParams.referer = opts.referer;
            }

            return {
                withSize: function(width, height) {
                    streamParams.width = width;
                    streamParams.height = height;
                    return this;
                },
                withHD: function(isHD) {
                    streamParams.hd = (isHD) ? '1' : '0';
                    return this;
                },
                withSpeed: function(isHD) {
                    streamParams.speed = (isHD) ? '771' : '400';
                    return this;
                },
                output: function() {
                    return _toQueryString(streamParams);
                }
            };
        }

        /**
         * Create the starting common options for all build players methods.
         * These options will be passed through all build player methods chain,
         * being updated with new keys and values.
         *
         * @param {String}  streamName            Stream name
         * @param {Object}  raceInfo              Race/track informationx
         * @param {String}  raceInfo.trackId      Track abbreviation
         * @param {Boolean} raceInfo.isReplay     Is a replay video?
         * @param {Object}  playerInfo            New player information
         * @param {String}  playerInfo.playerId   New player element ID
         * @param {Number}  playerInfo.height     New player height
         * @param {Number}  playerInfo.width      New player width
         * @param {Boolean} isFlash               Is a Flash player?
         * @return {Object}                       Build player initial options
         */
        function _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash) {
            // sorry for this mess...
            var opts = {
                streamName : streamName,
                isFlash    : isFlash,
                trackId    : raceInfo.trackId,
                isReplay   : raceInfo.isReplay,
                playerId   : playerInfo.instanceId || 'video',
                height     : playerInfo.height,
                width      : playerInfo.width,
                timestamp  : moment().utc().unix(),
                hd         : $rootScope.activeFeatures.hasHdVideo && raceInfo.isHD,
                referer    : 'TVG',
                format     : isFlash ? 'auto' : 'ios', // available formats: ios, rtsp and auto
                tokenHash  : '', // appended dynamically
                streamLink : ''  // appended dynamically
            };

            if (raceInfo.trackId === "TVG1" || raceInfo.trackId === "TVG2") {
                opts.hd = $rootScope.activeFeatures.hasHdVideoLivePage;
            }

            return opts;
        }

        /**
         * Request live race video stream for flash player
         * (used only in desktop)
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _buildPlayerFlashLive(streamName, raceInfo, playerInfo) {
            var isFlash = true;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);

            return _generateHash(opts)
                .then(_requestStreamUrl)
                .then(_createFlashPlayer);
        }

        /**
         * Request race replay video stream for flash player
         * (used only in desktop)
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _buildPlayerFlashReplay(streamName, raceInfo, playerInfo) {
            var isFlash = true;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);

            return _requestReplayStreamName(opts, raceInfo)
                .then(_generateHash)
                .then(_requestStreamUrl)
                .then(_createFlashPlayer);
        }

        /**
         * Request live race video stream for HTMl5 player
         * (used in mobile and as fallback for desktop)
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _buildPlayerHtml5Live(streamName, raceInfo, playerInfo) {
            var isFlash = false;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);

            return _generateHash(opts)
                .then(_requestStreamUrl)
                .then(_createHtml5Player);
        }

        /**
         * Request race replay video stream for HTML5 player
         * (used in mobile and as fallback for desktop)
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _buildPlayerHtml5Replay(streamName, raceInfo, playerInfo) {
            var isFlash = false;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);

            return _requestReplayStreamName(opts, raceInfo)
                .then(_generateHash)
                .then(_requestStreamUrl)
                .then(_createHtml5Player);
        }

        /**
         * Request live race video stream for iframe player
         * (used in mobile and as fallback for desktop)
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _buildPlayerIframeLive(streamName, raceInfo, playerInfo) {
            var isFlash = false;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);
            return _generateHash(opts)
                .then(_createIframePlayer);
        }

        /**
         * Request race replay video stream for iframe player
         * (used in mobile and as fallback for desktop)
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _buildPlayerIframeReplay(streamName, raceInfo, playerInfo) {
            var isFlash = false;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);

            return _requestReplayStreamName(opts, raceInfo)
                .then(_generateHash)
                .then(_createIframePlayer);
        }

        /**
         * Cannot build any player, because neither Flash or HTML5 players are supported
         *
         * @return {Promise}  Rejected promise with error
         */
        function _buildUnsupportedPlayerWarning() {

            // @HACK user sees the install flash warning...
            return $q.reject('noflash');
        }

        /**
         * Normalize raceInfo object
         * @param {Object} raceInfo   Raw raceInfo
         * @return {Object} raceInfo  Normalized raceInfo
         */
        function _normalizeRaceInfo(raceInfo) {
            raceInfo = _.cloneDeep(raceInfo);

            // depending from where it's called, raceInfo may passed trackId as trackAbbr
            if (!raceInfo.trackId && raceInfo.trackAbbr) {
                raceInfo.trackId = raceInfo.trackAbbr;
            }

            return raceInfo;
        }

        /**
         * Create iframe URL for live flash videos
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _getIframeSourceFlashLive(streamName, raceInfo, playerInfo) {
            var isFlash = true;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);

            return _generateHash(opts)
                .then(_createIframeStreamUrl);
        }

        /**
         * Create New RCN Iframe Replay
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _getIframeReplay(streamName, raceInfo, playerInfo) {
            var isFlash = true;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);

            return _requestReplayStreamName(opts, raceInfo)
                .then(_generateHash)
                .then(_createNewIframeStreamUrl);
        }

        /**
         * Create New RCN Iframe live
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _getIframeLive(streamName, raceInfo, playerInfo) {
            var isFlash = true;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);
            return _generateHash(opts)
                .then(_createNewIframeStreamUrl);
        }

        /**
         * Create iframe URL for replay flash videos
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _getIframeSourceFlashReplay(streamName, raceInfo, playerInfo) {
            var isFlash = true;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);

            return _requestReplayStreamName(opts, raceInfo)
                .then(_generateHash)
                .then(_createIframeStreamUrl);
        }

        /**
         * Create iframe URL for live html5 videos
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _getIframeSourceHtml5Live(streamName, raceInfo, playerInfo) {
            var isFlash = false;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);

            return _generateHash(opts)
                .then(_createIframeStreamUrl);
        }

        /**
         * Create iframe URL for replay html5 videos
         *
         * @param {String}  streamName  Stream name
         * @param {Object}  raceInfo    Race/track information
         * @param {Object}  playerInfo  New player information
         * @return {Promise}            New player ID
         */
        function _getIframeSourceHtml5Replay(streamName, raceInfo, playerInfo) {
            var isFlash = false;
            var opts = _createBuildPlayerOptions(streamName, raceInfo, playerInfo, isFlash);

            return _requestReplayStreamName(opts, raceInfo)
                .then(_generateHash)
                .then(_createIframeStreamUrl);
        }

        /**
         * Create iframe video player URL (either HTML5 or Flash)
         *
         * @param {Object}  opts             Stream authorization parameters
         * @param {String}  opts.streamName  Stream name
         * @param {Boolean} opts.isReplay    Is a race/horse replay?
         * @param {String}  opts.timestamp   Timestamp to generate hash
         * @param {String}  opts.tokenHash   Authorization token
         * @param {String}  opts.format      Stream forced format
         * @return {String}                  Iframe embedded player URL
         */
        function _createIframeStreamUrl(opts) {
            var params = _buildStreamParams(opts)
                .withFormat(opts.format)
                .withHD(_isHD(opts.trackId, opts.isReplay))
                .withSize(opts.width, opts.height)
                .withOutput('json')
                .output();


            // to embed flash player, only calculate the stream URL here
            if (opts.isFlash) {
                return $q.when(_createStreamUrl("flash", opts.isReplay, params));
            }

            // otherwise, to embed the html5 player, we need to request the stream link from RCN
            return _requestStreamUrl(opts)
                .then(function (opts) {
                    return opts.streamLink;
                });
        }

        /**
         * Create New iframe video player URL (either HTML5 or Flash)
         *
         * @param {Object}  opts             Stream authorization parameters
         * @param {String}  opts.streamName  Stream name
         * @param {Boolean} opts.isReplay    Is a race/horse replay?
         * @param {String}  opts.timestamp   Timestamp to generate hash
         * @param {String}  opts.tokenHash   Authorization token
         * @param {String}  opts.format      Stream forced format
         * @return {String}                  Iframe embedded player URL
         */
        function _createNewIframeStreamUrl(opts) {
            var params = _buildNewStreamParams(opts)
                .withHD(_isHD(opts.trackId, opts.isReplay))
                .withSize(opts.width, opts.height)
                .output();

            return $q.when(_createStreamUrl("iframe", opts.isReplay, params));
        }


        /**
         * Embed flash player in DOM with stream variables set (for desktop only)
         * (not sure if this shouldn't be in controller...)
         *
         * @param {Object}  opts             Stream authorization parameters
         * @param {String}  opts.streamName  Stream name
         * @param {String}  opts.trackId     Track abbreviation
         * @param {Boolean} opts.isReplay    Is a race/horse replay?
         * @param {String}  opts.timestamp   Timestamp to generate hash
         * @param {String}  opts.playerId    New player instance ID
         * @param {String}  opts.tokenHash   Authorization token
         * @param {String}  opts.streamLink  Authorized stream link
         * @param {String}  opts.format      Stream forced format
         * @return {String}                  Player ID
         */
        function _createFlashPlayer(opts) {

            /* istanbul ignore if */
            if (!module.hasFlashPlayer()) {
                throw 'noflash';
            }

            var data = _.cloneDeep(opts);
            var params = {
                quality           : 'high',
                bgcolor           : '#000000',
                allowScriptAccess : 'always',
                allowFullScreen   : 'true',
                wmode             : 'opaque',
                hd                : data.hd
            };
            var src = '';
            var flashvars = null;


            if (data.isReplay) {
                src = FLASH_REPLAY_PLAYER_SRC;
                data.type = 'replay';
            } else {
                src = FLASH_LIVE_PLAYER_SRC;
                data.type = 'live';
            }

            flashvars = {
                authUrl: $window.escape(AUTH_URL + _encodeFlashAuthParams(data)),
                doDebug: _getRcnDoDebug()
            };

            // insert flash player into parent element (playerId)
            $window.swfobject.embedSWF(
                src,
                data.playerId,
                '100%',
                '100%',
                '10.1.0',
                'expressInstall.swf',
                flashvars,
                params
            );

            return data.playerId;
        }

        /**
         * Builds URL athentication parameters for RCN stream
         *
         * @param  {Object} opts  Parameters to encode
         * @return {String}       RCN parameters as query string
         */
        function _encodeFlashAuthParams(opts) {
            var authParams = {
                cust   : opts.referer, // do not replace 'cust' key by 'referer'
                stream : opts.streamName,
                type   : opts.type,
                t      : opts.timestamp,
                h      : opts.tokenHash,
                hd     : opts.hd
            };

            return _toQueryString(authParams);
        }

        /**
         * Prepares stream and builds HTML5 video player for mobile
         * (not sure if this should be not be in controller...)
         *
         * @param  {String} src  Stream URL
         * @return {Object}      New video DOM element
         */
        function _createHtml5Player(opts) {

            /* istanbul ignore if */
            if (!module.hasHLSStreaming()) {
                throw 'nohls';
            }

            var video = document.createElement('video');

            video.style.width  = '100%';
            video.style.height = '100%';
            video.style.backgroundColor = '#000000';
            video.controls = true;
            video.autoplay = true;
            video.id  = opts.playerId;
            video.src = opts.streamLink;

            return video;
        }

        /**
         * Call new rcn iframe video player
         */
        function _createIframePlayer(opts) {
            var params = _buildNewStreamParams(opts)
                .withHD(opts.hd)
                .withSize(opts.width, opts.height)
                .output();

            var iframe = document.createElement('iframe');
            iframe.src = _createStreamUrl("iframe", opts.isReplay, params);
            iframe.setAttribute('allowfullscreen', "true");
            iframe.width= '100%';
            iframe.height= '100%';
            iframe.allow="fullscreen";
            iframe.scrolling="no";
            iframe.id = opts.playerId;

            return iframe;
        }

        //
        // EXPORTED
        //

        /**
         * Builds and requests video stream or replay player based on
         * browser user agent
         *
         * @param  {String}  instanceId           Player element id
         * @param  {Number}  width                Player width
         * @param  {Number}  height               Player height
         * @param  {Object}  raceInfo             Race details
         * @param  {String}  raceInfo.trackId     Track abbreviation
         * @param  {String}  raceInfo.raceNumber  Race number
         * @param  {Boolean} raceInfo.isReplay    Is a replay video?
         * @return {Promise}
         */
        module.buildPlayer = function (instanceId, width, height, raceInfo) {
            raceInfo = _normalizeRaceInfo(raceInfo);

            var playerInfo = {
                instanceId: instanceId,
                width: width,
                height: height
            };
            var builders = {
                'mobile_html5_live'      : _buildPlayerHtml5Live,
                'mobile_html5_replay'    : _buildPlayerHtml5Replay,
                'desktop_flash_live'     : _buildPlayerFlashLive,
                'desktop_flash_replay'   : _buildPlayerFlashReplay,
                'desktop_html5_live'     : _buildPlayerHtml5Live,
                'desktop_html5_replay'   : _buildPlayerHtml5Replay,
                'desktop_noflash_live'   : _buildUnsupportedPlayerWarning,
                'desktop_noflash_replay' : _buildUnsupportedPlayerWarning,
                'desktop_iframe_live'    : _buildPlayerIframeLive,
                'desktop_iframe_replay'  : _buildPlayerIframeReplay
            };

            var streamName = _getStreamName(raceInfo);
            var isFallbackEnabled = $rootScope.activeFeatures.hlsVideoFallback;
            var builderKey = '';
            var builder = null;
            var err = null;


            // stop -> stream is unavailable
            if (!streamName) {
                err = raceInfo.isReplay ? 'noreplay' : 'nolivestream';
                return $q.reject(err);
            }


            // sorry for this hack, but its easier to read than multiple if-else
            // 1) whether mobile or desktop
            // 2) preference for flash, fallback to html5 (if possible) or return "noflash" error
            // 3) whether replay or live video
            builderKey += (module._isMobile()) ? 'mobile_' : 'desktop_';

            if (module.hasHLSStreaming() && isFallbackEnabled) {
                builderKey += 'html5_';
            } else if (builderKey === 'desktop_') {
                builderKey += 'iframe_';
            } else {
                builderKey += 'noflash_';
            }

            builderKey += (raceInfo.isReplay) ? 'replay' : 'live';
            builder = builders[builderKey];


            return builder.call(null, streamName, raceInfo, playerInfo)
                .catch(function (err) {
                    return $q.reject(err);
                });
        };

        /**
         * Returns iframe source to inject video player page from rcn
         *
         * @param  {Object}  raceInfo             Race details
         * @param  {String}  raceInfo.trackId     Track abbreviation (maybe trackAbbr)
         * @param  {String}  raceInfo.raceNumber  Race number
         * @param  {Boolean} raceInfo.isReplay    Is a replay video?
         * @param  {Number}  width                Player width
         * @param  {Number}  height               Player height
         * @param  {Boolean} isReplay             Is a replay?
         * @return {Promise}                      Iframe embedded player URL
         */
        module.getIframeSource = function (raceInfo, width, height) {
            raceInfo = _normalizeRaceInfo(raceInfo);

            var playerInfo = {
                width  : width,
                height : height
            };
            var builders = {
                'html5_live'     : _getIframeSourceHtml5Live,
                'html5_replay'   : _getIframeSourceHtml5Replay,
                'flash_live'     : _getIframeSourceFlashLive,
                'flash_replay'   : _getIframeSourceFlashReplay,
                'noflash_live'   : _getIframeSourceFlashLive,   // RCN iframe should alert the user to install Flash
                'noflash_replay' : _getIframeSourceFlashReplay,  // RCN iframe should alert the user to install Flash,
                'iframe_live'    : _getIframeLive,
                'iframe_replay'  : _getIframeReplay
            };

            var builderKey = '';
            var builder = null;
            var streamName = _getStreamName(raceInfo);
            var err;


            // stop -> stream is unavailable
            if (!streamName) {
                err = Error('Missing stream name for track:' + raceInfo.trackId);
                return $q.reject(err);
            }


            // sorry for this hack, but its easier to read than multiple if-else
            builderKey += module.hasHLSStreaming() ? 'html5_' : 'iframe_';
            builderKey += (raceInfo.isReplay) ? 'replay' : 'live';
            builder = builders[builderKey];


            return builder.call(null, streamName, raceInfo, playerInfo)
                .catch(function (err) {
                    return $q.reject(err);
                });
        };

        /**
         * Has flash player available?
         * @return {Boolean}  Has flash player?
         */
        module.hasFlashPlayer = function () {

            return !!(
                $window.swfobject &&
                $window.swfobject.getFlashPlayerVersion().major > 0
            );
        };

        /**
         * Is the browser/device compatible with HLS?
         * (e.g. desktop chrome is not, ios chrome is)
         *
         * https://developer.jwplayer.com/articles/html5-report/adaptive-streaming/hls.html
         * https://github.com/videojs/video.js/issues/993
         *
         * IGNORED COVERAGE
         * this method is always mocked, because mocking the user agent was causing the
         * tests to run really slowly (from 30s to 4min)
         *
         * @return {Boolean}  Has HTML5 player?
         */
        /* istanbul ignore next */
        module.hasHLSStreaming = function () {
            var ua = $window.navigator.userAgent;
            var vendor = $window.navigator.vendor;
            var isSafari = !!(vendor.match(/Apple/i) && ua.match(/Safari/i));
            var hasHLS = isSafari || module._isMobile();

            return !!hasHLS;
        };

        /**
         * Is a mobile device?
         * @TODO move to ConfigurationFac (must remove from public methods and tests)
         *
         * IGNORED COVERAGE
         * this method is always mocked, because mocking the user agent was causing the
         * tests to run really slowly (from 30s to 4min)
         *
         * @return {Boolean}  Is mobile?
         */
        /* istanbul ignore next */
        module._isMobile = function () {
            var ua = $window.navigator.userAgent;

            return !!ua.match(/ipad|iphone|android|windows\sphone/i);
        };


        // Public methods
        return module;
    }


    videoProviderSvc.$inject = [
        '$q',
        '$http',
        '$filter',
        '$window',
        '$location',
        '$rootScope',
        'ConfigurationFac'
    ];

    return videoProviderSvc;
});

