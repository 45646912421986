'use strict';

define('ScreenNames',[],
    function () {
        return {
            'my-profile': 'My Account',
            'my-tracks': 'My Account',
            'my-favorite-tracks': 'My Account',
            'my-funds': 'My Account',
            'my-bets': 'My Account',
            'my-account-summary': 'My Account',
            'my-stable': 'My Account',
            'races': 'Races',
            'race': 'Program',
            'racetracks': 'Track List',
            'racetracks/_trackAbbr/_trackName?race=' : 'Program',
            'handicapping': 'Handicapping',
            'info': 'Site Info',
            'home': 'Home',
            '': 'Home',
            'results': 'Races',
            'betslip': 'Bet Ticket',
            'promotions': 'Promotions',
            'wager-rewards': 'Promotions',
            'registration': 'Registration Page',
            'password-recovery': 'Credentials Recovery',
            'forgot-credentials': 'Credentials Recovery',
            'reset-credentials': 'Credentials Recovery',
            'greyhounds': 'Greyhounds'
        };
    }
);

