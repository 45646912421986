'use strict';

define('GenericMessageDir',[],
    function () {

        /**
         * @ngdoc directive
         * @name genericMessage
         * @module TVG.RaceInfo.RaceTrackCurrentBetsMod
         *
         * @restrict 'A'
         *
         * @description
         * This directive is used to show a custom message inside of a table row.
         * Receives the message and the CSS class to be used as the icon on the left side of the message.
         *
         * @example
           <tr generic-message="'Generic Error'" data-icon-class="'tvg-icon-error red'
            data-on-close=onCloseFn()"></tr>
         */
        function genericMessageDir() {
            return {
                restrict: 'A',
                scope: {
                    iconClass: '=',
                    message: '=genericMessage',
                    onClose: '&',
                    colSpan: '='
                },
                templateUrl: 'js/desktop/modules/RaceTrackCurrentBets/templates/generic-message-template.html'
            };
        }

        genericMessageDir.$inject = [];

        return genericMessageDir;
    });
