'use strict';

define(
    'ChangePasswordCtrl',[
    ],
    function () {

        function ChangePasswordCtrl($rootScope, $scope, $uibModal, PasswordValidatorSvc, ProfileHelperSvc, GTMFac, FindModalContainerFac, $filter) {

            function _setInitialData(){

                $scope.data = {
                    passwordPattern: /.+/,
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                    errorMessage: null,
                    message: null,
                    confirmDialog: null,
                    validPasswordPattern: true,
                    loading: false,
                    validClass: 'valid',
                    invalidClass: 'invalid',
                    isPasswordRuleValid: {
                        ruleLength: null,
                        ruleLetter: null,
                        ruleNumber: null,
                        ruleMatchPassword: null
                    }
                };

                $scope.events = {
                    onChangePassword: _onChangePassword,
                    checkPassword: _checkPassword,
                    checkMatchingPassword: _checkMatchingPassword,
                    cancel: _cancel
                };

                $scope.validations = {
                    isValid: _isValid
                };
            }

            function _onChangePassword(formValid) {

                var oldPwd = $scope.data.oldPassword,
                    newPwd = $scope.data.newPassword,
                    confirmPwd = $scope.data.confirmPassword;

                if (!formValid || $scope.data.loading) {
                    PasswordValidatorSvc.validateEmptyPasswords(newPwd, confirmPwd, oldPwd, _showErrorMessage);
                    return;
                }

                if (!PasswordValidatorSvc.validatePasswords(newPwd, confirmPwd, oldPwd, _showErrorMessage)) {
                    _handleFailedPasswordChangeValidations();
                    return;
                }

                $scope.data.loading = true;

                ProfileHelperSvc.changePassword(oldPwd, newPwd, confirmPwd).then(function (response) {
                    _handleSuccessfulPasswordChange(response);

                    var p = {
                        gaEventCategory: 'Site Click',
                        gaEventAction: 'My Profile Page Click',
                        gaEventLabel: 'User Profile | Change Password',
                        sectionName: GTMFac.Tvg4SectionName(),
                        screenName: GTMFac.Tvg4ScreenName(),
                        eventLabel: "MyAccount_UserProfilePassword"
                    };

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
                }, function (response) {
                    _handleUnsuccessfulPasswordChange(response);
                });
            }

            function _handleFailedPasswordChangeValidations() {
                _setPristine();
            }

            function _handleSuccessfulPasswordChange(response) {
                _showMessage(response.message);
                _setPristine();
            }

            function _handleUnsuccessfulPasswordChange(error) {
                var genericErrMessage = 'There was a problem updating your password.';
                var errorMessage = genericErrMessage;  
                errorMessage = error.code && $filter('CMSLabels')(error.code, 'profileUpdatePasswordErrorHandling') ? 
                    $filter('CMSLabels')(error.code, 'profileUpdatePasswordErrorHandling') : genericErrMessage;
               
                _setPristine();
                _showErrorMessage(errorMessage);
            }

            function _checkPassword(password) {
                PasswordValidatorSvc.fillPasswordValidations(password, $scope.data.isPasswordRuleValid);
                $scope.data.validPasswordPattern = $scope.data.isPasswordRuleValid.validPasswordPattern;
            }

            function _checkMatchingPassword(currentPwd, newPwd) {
                $scope.data.isPasswordRuleValid.ruleMatchPassword =
                    PasswordValidatorSvc.arePasswordsMatching(currentPwd, newPwd);
            }

            function _setPristine() {
                $scope.changeCredentialsFrm.oldPassword.$setPristine();
                $scope.changeCredentialsFrm.newPassword.$setPristine();
                $scope.changeCredentialsFrm.confirmPassword.$setPristine();
                $scope.data.oldPassword = "";
                $scope.data.newPassword = "";
                $scope.data.confirmPassword = "";
            }

            function _showMessage(message) {
                $scope.data.message = message;

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Account/templates/change-pin-password-success.html',
                    scope: $scope
                });
            }

            function _showErrorMessage(message) {
                $scope.data.errorMessage = message;
                $scope.data.errorMessageTitle = "Change Password Failed";

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Login/templates/login-error.html',
                    scope: $scope
                });
            }

            function _cancel() {
                if ($scope.data.confirmDialog) {
                    $scope.data.confirmDialog.close();
                    $scope.data.confirmDialog = null;
                }
                $scope.data.loading = false;
            }

            // Validations

            function _isValid(valid, field) {
                var className;
                var dirty = field.$dirty;
                if(dirty) {
                    className = valid ? $scope.data.validClass : $scope.data.invalidClass;
                }
                return className;
            }

            (function init(){
                _setInitialData();
            }());

            $scope.$on("about-you::on-form-cancel", _setPristine);

        }

        ChangePasswordCtrl.$inject = [
            '$rootScope',
            '$scope',
            '$uibModal',
            'PasswordValidatorSvc',
            'ProfileHelperSvc',
            'GTMFac',
            'FindModalContainerFac',
            '$filter'
        ];

        return ChangePasswordCtrl;
    }
);

