'use strict';

define(
    'Races',[
        'RaceInfo',
        'RaceCtrl',
        'RaceTrackCurrentBetsMod',
        'ProgramPageRunnersFac',
        'RaceProgramCtrl',
        'RaceTrackHubCtrl',
        'RaceProgramHeaderCtrl',
        'RaceResultsCtrl',
        'VideoRaceCtrl',
        'RaceInfoCtrl',
        'HorizontalScrollDir',
        'TrackPerformanceProvider',
        'RaceListFac',
        'RaceReplaysCtrl',
        'RunnerInfoFac',
        'RaceResultsDesktopDirectiveCtrl',
        'RaceResultsUseCase',
        'RaceResultsGateway',
        'RaceReplaysNavigationCtrl',
        'RaceResultsRunnersListDirectiveCtrl',
        'TrackListFac',
        'TrackInfoFac',
        'RaceSvc',
        'TrackPerformanceListFac',
        'NextRacesBarCtrl',
        'TopScrollDir',
        'RunnerReplayModalFac',
        'RaceChangesCtrl',
        'trackDetailsCtrl',
        'RaceMTPFilter',
        'NextRacesItemsFac',
        'NextRaceItemDir',
        'NextRacesItemCtrl',
        'CarryOverPoolsCtrl',
        'MtpDisplayModel',
        'RaceStatusLabelDir',
        'RaceMtpLabelMod',
        'VideoCtrl',
        'RaceTimeformCtrl',
        'CurrentLiveStreamListFac',
        'VideoGridCtrl',
        'sortTracksAlphabetically',
        'OrdinalNumberStringFilter',
        'GTMMod',
        'GraphRaceFac',
        'PromotionsSvc'
    ],

    function (RaceInfo,
              RaceCtrl,
              RaceTrackCurrentBetsMod,
              ProgramPageRunnersFac,
              RaceProgramCtrl,
              RaceTrackHubCtrl,
              RaceProgramHeaderCtrl,
              RaceResultsCtrl,
              VideoRaceCtrl,
              RaceInfoCtrl,
              HorizontalScrollDir,
              TrackPerformanceProvider,
              RaceListFac,
              RaceReplaysCtrl,
              RunnerInfoFac,
              RaceResultsDesktopDirectiveCtrl,
              RaceResultsUseCase,
              RaceResultsGateway,
              RaceReplaysNavigationCtrl,
              RaceResultsRunnersListDirectiveCtrl,
              TrackListFac,
              TrackInfoFac,
              RaceSvc,
              TrackPerformanceListFac,
              NextRacesBarCtrl,
              TopScrollDir,
              RunnerReplayModalFac,
              RaceChangesCtrl,
              trackDetailsCtrl,
              RaceMTPFilter,
              NextRacesItemsFac,
              NextRaceItemDir,
              NextRacesItemCtrl,
              CarryOverPoolsCtrl,
              MtpDisplay,
              RaceStatusLabelDir,
              RaceMtpLabelMod,
              VideoCtrl,
              RaceTimeformCtrl,
              CurrentLiveStreamListFac,
              VideoGridCtrl,
              sortTracksAlphabetically,
              OrdinalNumberStringFilter,
              GTMMod,
              GraphRaceFac,
              PromotionsSvc) {

        angular.module('TVG.Races', [
            'TVG.RaceInfo',
            'TVG.RaceTrackCurrentBetsMod',
            'TVG.RaceMTPLabel',
            'TVG.Logging',
            'TVG.Common.GTM'
        ])

            .service('RaceResultsUseCase', RaceResultsUseCase)
            .service('TrackPerformanceProvider', TrackPerformanceProvider)
            .service('RaceSvc', RaceSvc)
            .service('PromotionsSvc', PromotionsSvc)
            .factory('TrackInfoFac', TrackInfoFac)
            .factory('RaceListFac', RaceListFac)
            .factory('TrackListFac', TrackListFac)
            .factory('TrackPerformanceListFac', TrackPerformanceListFac)
            .factory('ProgramPageRunnersFac', ProgramPageRunnersFac)
            .factory('RunnerInfoFac', RunnerInfoFac)
            .factory('RaceResultsGateway', RaceResultsGateway)
            .factory('RunnerReplayModalFac', RunnerReplayModalFac)
            .factory('NextRacesItemsFac', NextRacesItemsFac)
            .factory('CurrentLiveStreamListFac', CurrentLiveStreamListFac)
            .factory('GraphRaceFac', GraphRaceFac)


            .controller('RaceProgramCtrl', RaceProgramCtrl)
            .controller('RaceTrackHubCtrl', RaceTrackHubCtrl)
            .controller('RaceResultsCtrl', RaceResultsCtrl)
            .controller('RaceProgramHeaderCtrl', RaceProgramHeaderCtrl)
            .controller('VideoRaceCtrl', VideoRaceCtrl)
            .controller('trackDetailsCtrl', trackDetailsCtrl)
            .controller('RaceInfoCtrl', RaceInfoCtrl)
            .controller('RaceResultsDesktopDirectiveCtrl', RaceResultsDesktopDirectiveCtrl)
            .controller('RaceReplaysCtrl', RaceReplaysCtrl)
            .controller('RaceReplaysNavigationCtrl', RaceReplaysNavigationCtrl)
            .controller('RaceResultsRunnersListDirectiveCtrl', RaceResultsRunnersListDirectiveCtrl)
            .controller('RaceChangesCtrl', RaceChangesCtrl)
            .controller('NextRacesBarCtrl', NextRacesBarCtrl)
            .controller('NextRacesItemCtrl', NextRacesItemCtrl)
            .controller('CarryOverPoolsCtrl', CarryOverPoolsCtrl)
            .controller('VideoCtrl', VideoCtrl)
            .controller('VideoGridCtrl', VideoGridCtrl)
            .controller('VideoGridItemCtrl', function ($scope) {
                $scope.isOpen = false;
                function stopVideo() {
                    $scope.$broadcast('video:stop');
                }

                $scope.popOutVideo = function (video) {
                    $scope.$emit('video:popOut', video.raceData);
                    stopVideo();
                };

            })
            .controller('RaceCtrl', RaceCtrl)
            .controller('RaceTimeformCtrl', RaceTimeformCtrl)

            //directive to render the race navigation view
            .directive('racesNavigation', function () {
                return {
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/Races/templates/races-navigation-controller.html',
                    scope: {
                        "numOfItems": "=",
                        "startIndex": "=",
                        "withTabs": "=",
                        "withFilters": "=",
                        "showResults": "=",
                        "onlyVideos": "=",
                        "checkUserPrefs": "=",
                        "topOffset": "=",
                        "stateKey": "@",
                        "maxItems": "=",
                        "defaultFilter": "@"
                    }
                };
            })

            .directive('raceRunnersListInline', function () {
                return {
                    restrict: 'E',
                    scope: {
                        racePerfAbbr: "@",
                        raceTrackAbbr: "@",
                        raceNumber: "@",
                        raceTypeId: "@",
                        raceDate: "@" //optional
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/desktop/race-results-runners-list-inline.html',
                    controller: 'RaceResultsRunnersListDirectiveCtrl',
                    controllerAs: 'raceRunnersList',
                    bindToController: true
                };
            })

            .directive('swipelist', HorizontalScrollDir)
            .directive('topswipelist', TopScrollDir)

            //directive to add race program controller
            .directive('raceprogram', function () {
                return {
                    restrict: 'E',
                    scope: true,
                    controller: 'RaceProgramCtrl'
                };
            })

            //directive to add race track hub controller
            .directive('racetrackhub', function () {
                return {
                    restrict: 'E',
                    scope: true,
                    controller: 'RaceTrackHubCtrl'
                };
            })
            .directive('rssFeed', function () {
                return {
                    restrict: 'E',
                    scope: false,
                    controller: 'RaceProgramHeaderCtrl',
                    templateUrl: 'js/desktop/views/rss-feed.html'
                };
            })

            //directive to render a tiny race item
            .directive('tinyRace', function (RacesPageGTMFac) {
                return {
                    restrict: 'E',
                    scope: {
                        race: '=raceData',
                        getLink: '<',
                        originComponent: '<',
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/tiny-race.html',
                    link: function (scope) {

                        function updateMtp() {
                            scope.race.mtpDisplay = new MtpDisplay(scope.race.postTime);
                        }

                        scope.sendGtmGoToRace = function () {
                            if(scope.originComponent && scope.race){
                                RacesPageGTMFac.clickNavigateToRace({
                                    raceUrl: scope.getLink,
                                    originComponent: scope.originComponent,
                                    trackName: scope.race.trackName,
                                    raceNumber: scope.race.raceNumber,
                                    mtp: scope.race.mtp,
                                });
                            }
                        };

                        scope.$watch('race', updateMtp);

                        (function init() {
                            updateMtp();
                        }());
                    }
                };
            })

            .directive('race', function () {
                return {
                    restrict: 'E',
                    scope: {
                        race: '=raceData',
                        isToday: '=',
                        date: '=',
                        getLink: '<'
                    },
                    controller: 'RaceCtrl',
                    templateUrl: 'js/desktop/modules/Races/templates/race.html'
                };
            })
            .directive('videorace', function () {
                return {
                    restrict: 'E',
                    scope: false,
                    controller: 'VideoRaceCtrl',
                    templateUrl: 'js/desktop/modules/Races/templates/videorace.html'
                };
            })
            .directive('racesSubNavigation', function () {
                return {
                    restrict: 'E',
                    replace: true,
                    scope: {
                        page: '@'
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/sub-navigation.html'
                };
            })
            .directive('tvgvideo', function () {
                return {
                    restrict: 'E',
                    scope: {
                        raceData: "=",
                        showInfo: "=",
                        streamCode: "=",
                        collapsible: "=",
                        isGridVideo: "="
                    },
                    controller: 'VideoCtrl',
                    templateUrl: 'js/desktop/modules/Races/templates/tvgvideo.html'
                };
            })

            .directive('tvgvideoGridPlayer', function () {
                return {
                    scope: {
                        raceData: "=",
                        isGridVideo: "="
                    },
                    controller: 'VideoCtrl',
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/Races/templates/desktop/race-video-grid-player.html'
                };
            })

            .directive('tvgvideoGrid', function () {
                return {
                    scope: {}, // {} = isolate, true = child, false/undefined = no change
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/Races/templates/desktop/race-video-grid.html',
                    controller: 'VideoGridCtrl'
                };
            })

            //directive to render a runner item
            .directive('runner', function () {
                return {
                    restrict: 'E',
                    scope: {
                        runner: '=runnerData'
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/runner.html'
                };
            })

            // directive to render payoffs in results page
            .directive('raceresults', function () {
                return {
                    restrict: 'E',
                    scope: {
                        standalone: '=',
                        race: '='
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/results.html'

                };
            })

            .directive('raceresultsDesktop', function () {
                return {
                    restrict: 'E',
                    scope: {
                        showAllRacesButton: "=", //optional
                        showSelectRaceButton: "=", //optional
                        racePerfAbbr: "@",
                        raceTrackAbbr: "@",
                        raceTrackName: "@",
                        raceNumber: "@",
                        raceTypeId: "@",
                        raceDate: "@", //optional,
                        hideResults: "=",
                        errorGettingResults: "=?",
                        pastResults: "@"
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/desktop/results.html',
                    controller: 'RaceResultsDesktopDirectiveCtrl',
                    controllerAs: 'raceResultsCtrl',
                    bindToController: true,
                    link: function (scope, $el, attrs) {
                        // @HACK convert string to boolean (blame angular)
                        scope.raceResultsCtrl.raceCancelled = (attrs.raceCancelled === 'true');
                        return;
                    }
                };
            })

            .directive('runnersList', function () {
                return {
                    restrict: 'E',
                    scope: {
                        race: '='
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/runners-list.html'
                };
            })

            .directive('raceinfo', function () {
                return {
                    restrict: 'E',
                    scope: false,
                    templateUrl: 'js/desktop/modules/Races/templates/race-info.html'
                };
            })

            .directive('poolsList', function () {
                return {
                    restrict: 'E',
                    scope: {
                        race: '='
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/pools-list.html'
                };
            })

            .directive('carryOverPools', function () {
                return {
                    restrict: 'E',
                    controller: 'CarryOverPoolsCtrl',
                    controllerAs: 'carryOverPools',
                    templateUrl: 'js/desktop/modules/Races/templates/carry-over-pools.html'
                };
            })

            .directive('probables', function () {
                return {
                    restrict: 'E',
                    scope: {
                        race: '='
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/probables.html',
                    controller: 'RaceInfoCtrl'
                };
            })

            .directive('willpays', function () {
                return {
                    restrict: 'E',
                    scope: {
                        race: '='
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/willpays.html',
                    controller: 'RaceInfoCtrl'
                };
            })

            .directive('racehandicapping', function () {
                return {
                    restrict: 'E',
                    scope: {
                        handicapping: '='
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/race-handicapping.html',
                    controller: 'RaceInfoCtrl'
                };
            })

            .directive('runnerHandicap', function () {
                return {
                    restrict: 'E',
                    scope: {
                        runners: '=res'
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/runner-handicap.html'
                };
            })

            .directive('latechanges', function () {
                return {
                    restrict: 'E',
                    scope: {
                        race: '='
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/late-changes.html'
                };
            })

            .directive('surfacechangeitem', function () {
                return {
                    restrict: 'E',
                    scope: {
                        change: '=res'
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/surface-change-item.html'
                };
            })

            .directive('runnerchangeitem', function () {
                return {
                    restrict: 'E',
                    scope: {
                        change: '=res'
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/runner-change-item.html'
                };
            })

            .directive('runnerAmounts', function () {
                return {
                    restrict: 'E',
                    scope: {
                        runners: '=res'
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/runner-amounts.html'
                };
            })

            .directive('raceFilters', function () {
                return {
                    restrict: 'E',
                    scope: {
                        filters: '=',
                        applyFilters: '='
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/race-filters.html'
                };
            })

            .directive('raceChangeItem', function () {
                return {
                    restrict: 'E',
                    scope: {
                        item: '='
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/desktop/race-change-item.html'
                };
            })

            /*
             * TVGFOUR
             */
            .directive('runnersTable', function () {
                return {
                    restrict: 'A',
                    scope: {
                        race: '=',
                        raceIsOpen: '='
                    },
                    controller: 'RaceResultsCtrl',
                    templateUrl: 'js/desktop/modules/Races/templates/runners-table.html'
                };
            })
            .directive('greyhoundsTable', function () {
                return {
                    restrict: 'E',
                    scope: {
                        race: '=',
                        raceIsOpen: '='
                    },
                    controller: 'RaceResultsCtrl',
                    templateUrl: 'js/desktop/modules/Races/templates/runners-table-greyhounds.html'
                };
            })
            .directive('raceChangesDir', function () {
                return {
                    restrict: 'EA',
                    scope: {
                        raceChanges: '='
                    },
                    templateUrl: 'js/desktop/modules/Races/templates/desktop/race-changes.html',
                    controller: 'RaceChangesCtrl',
                    controllerAs: 'raceChangesCtrl'
                };
            })
            .filter('raceMtp', RaceMTPFilter)
            .filter('sortTracksAlphabetically', sortTracksAlphabetically)
            .filter('ordinalNumberString', OrdinalNumberStringFilter)
            .directive('nextRacesBar', function () {
                return {
                    restrict: 'E',
                    replace: false,
                    transclude: true,
                    scope: false,
                    controller: 'NextRacesBarCtrl',
                    templateUrl: 'js/desktop/modules/Races/templates/desktop/next-races-bar.html'
                };
            })
            .directive('nextRacesItem', NextRaceItemDir)
            .directive('raceStatusLabel', RaceStatusLabelDir)
            .directive('mtpIcon', function () {
                return {
                    restrict: '',
                    replace: true,
                    scope: {
                        racePostTime: '=',
                        mtp: '='
                    },
                    template: ['<i class="tvg-icon-mtp"',
                        'ng-class="{',
                        '\'mtp-near\' : mtpDisplay.mtpStatus == \'near\',',
                        '\'mtp-med\' : mtpDisplay.mtpStatus == \'med\',',
                        '\'mtp-far\' : mtpDisplay.mtpStatus == \'far\'}"',
                        'ng-show="mtpDisplay.mtpStatus && mtpDisplay.mtpStatus != \'time-format\'"></i>'].join(''),
                    link: function (scope) {
                        scope.mtpDisplay = {};

                        function _setMtpDisplay() {
                            scope.mtpDisplay = new MtpDisplay(scope.racePostTime, scope.mtp);
                        }

                        (function init() {
                            _setMtpDisplay();
                        }());

                        scope.$watch('racePostTime', _setMtpDisplay);
                        scope.$watch('mtp', _setMtpDisplay);
                    }
                };
            })
            .directive('raceTimeform', function () {
                return {
                    restrict: 'EA',
                    scope: {
                        race: '='
                    },
                    controller: 'RaceTimeformCtrl',
                    controllerAs: 'timeformCtrl',
                    templateUrl: 'js/desktop/modules/Races/templates/race-timeform.html'
                };
            })
            .directive('timeformPredictsScrollDir', function () {
                return {
                    restrict: 'A',
                    scope: {},
                    link: function (scope, element, attrs) {
                        var target = attrs.scrollTarget;
                        element.click(function () {
                            $('html, body').animate({
                                scrollTop: $("#" + target).offset().top
                            }, 100);
                        });
                    }
                };
            })
            .directive('trackPage', function () {
                return {
                    restrict: 'E',
                    scope: false,
                    templateUrl: 'js/desktop/views/racetrackhub.html',
                    controller: 'RaceTrackHubCtrl'
                };
            })
            .component('quickResults', {
                templateUrl: 'js/desktop/modules/Races/templates/quick-results.html',
                bindings: {
                    loading: '<',
                    raceOpened: '<',
                    raceResults: '<',
                    raceError: '<',
                    payOff: '&'
                }
            });
    }
);

