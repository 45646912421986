'use strict';

define('NewWindowGridFac',[],
    function () {
        function NewWindowGridFac() {
            var windowID = 0;
            var windowWidth = 470; // default value plus some margin
            var windowHeight = 870; // default value plus some margin

            function _generateWindowID() {
                return ++windowID + "";
            }

            // run on new child init
            function _onChildInit(window, newWindow) {
                window.referenceWindow.addNewWindow(newWindow);
            }

            //handle events received by reference window
            function _referenceWindowEvent(referenceWindow) {
                var eventType = referenceWindow.addEventListener ? "addEventListener" : "attachEvent";
                var addEvent = referenceWindow[eventType];
                addEvent("message", function (e) {
                    if (e.origin === referenceWindow.location.origin) {
                        if (referenceWindow.newWindowColumnIndex < referenceWindow.numberOFWindowsPerRow && referenceWindow.newWindowColumnIndex > 0) {
                            referenceWindow.newWindowColumnIndex--;
                        }
                        _.remove(referenceWindow.childWindows, function (windowInstance) {
                            return (windowInstance.ID === e.data.substring(17, e.data.length) || windowInstance.window === null);
                        });
                    }
                }, false);
            }

            //add new child to reference window list
            function _addNewWindow(window) {
                return function (windowRef) {
                    window.childWindows.push(windowRef);
                };
            }

            //close event for child windows
            function _onCloseEvent(window) {
                return function () {
                    window.referenceWindow.postMessage("destroy-message: " + window.ID, window.location.origin);
                };
            }

            //inject function on reference window
            function _referenceWindowFunctions(window) {
                window.referenceWindow = window;
                window.generateID = _generateWindowID;
                window.childWindows = [];
                window.addNewWindow = _addNewWindow(window);
                _referenceWindowEvent(window);
            }

            //inject function on child window
            function _childWindowFunctions(window) {
                window.referenceWindow = window.opener.referenceWindow;
                window.ID = window.referenceWindow.generateID();
                window.addEventListener('unload', _onCloseEvent(window));
            }

            //identify the type of window
            function _windowFunction(window) {
                try {
                    if (window.opener && window.opener.referenceWindow) {
                        _childWindowFunctions(window);
                    } else {
                        throw "cannot access window";
                    }
                } catch (e) {
                    _referenceWindowFunctions(window);
                    _calculateGrid(window);
                }
            }

            //calculate grid of the screen
            function _calculateGrid(referenceWindow) {
                referenceWindow.numberOFWindowsPerRow = Math.floor(referenceWindow.screen.width / windowWidth);
                referenceWindow.numberOFWindowsPerColumn = Math.floor(referenceWindow.screen.height / windowHeight);
                referenceWindow.newWindowRowIndex = 0;
                referenceWindow.newWindowColumnIndex = 0;
                referenceWindow.topScreenValue = referenceWindow.screen.availTop;
                referenceWindow.leftScreenValue = referenceWindow.screen.availLeft;
                referenceWindow.widthScreenValue = referenceWindow.screen.width;
                referenceWindow.heightScreenValue = referenceWindow.screen.height;
            }

            // select reference window and return open coordinates
            function _getOpenCoordinates(window) {
                return _getCoordinates(window.referenceWindow);
            }

            // get coordinates based on master window
            function _getCoordinates(referenceWindow) {
                if (referenceWindow.topScreenValue != referenceWindow.screen.availTop ||
                    referenceWindow.leftScreenValue != referenceWindow.screen.availLeft ||
                    referenceWindow.widthScreenValue != referenceWindow.screen.width ||
                    referenceWindow.heightScreenValue != referenceWindow.screen.height) {
                    _calculateGrid(referenceWindow);
                }

                var coordinates = {top: referenceWindow.topScreenValue, left: referenceWindow.leftScreenValue};

                if (referenceWindow.newWindowColumnIndex < referenceWindow.numberOFWindowsPerRow) {
                    coordinates = _coordinates(referenceWindow);
                    referenceWindow.newWindowColumnIndex++;
                } else if (referenceWindow.newWindowRowIndex + 1 < referenceWindow.numberOFWindowsPerColumn) {
                    referenceWindow.newWindowColumnIndex = 0;
                    referenceWindow.newWindowRowIndex++;
                    coordinates = _coordinates(referenceWindow);
                    referenceWindow.newWindowColumnIndex++;
                } else {
                    referenceWindow.newWindowColumnIndex = 0;
                    referenceWindow.newWindowRowIndex = 0;
                    coordinates = _coordinates(referenceWindow);
                    referenceWindow.newWindowColumnIndex++;
                }

                return coordinates;
            }

            function _getTopCoordinates(referenceWindow) {
                if (referenceWindow.topScreenValue != referenceWindow.screen.availTop ||
                    referenceWindow.leftScreenValue != referenceWindow.screen.availLeft ||
                    referenceWindow.widthScreenValue != referenceWindow.screen.width ||
                    referenceWindow.heightScreenValue != referenceWindow.screen.height) {
                    _calculateGrid(referenceWindow);
                }

                var coordinates = {top: referenceWindow.topScreenValue, left: referenceWindow.leftScreenValue};
                coordinates = _coordinates(referenceWindow);

                return coordinates;

            }

            // set coordinates
            function _coordinates(referenceWindow) {
                var coordinates = {};
                coordinates.top = referenceWindow.topScreenValue + referenceWindow.newWindowRowIndex * windowHeight;
                coordinates.left = referenceWindow.leftScreenValue + referenceWindow.newWindowColumnIndex * windowWidth;
                return coordinates;
            }

            //setter for height
            function _setBetSlipWindowHeight(value) {
                if (value) {
                    windowHeight = value;
                }
            }

            //setter for width
            function _setBetSlipWindowWidth(value) {
                if (value) {
                    windowWidth = value;
                }
            }

            return {
                windowFunctions: _windowFunction,
                onChildInit: _onChildInit,
                calculateGrid: _calculateGrid,
                getOpenCoordinates: _getOpenCoordinates,
                getTopCoordinates: _getTopCoordinates,
                setBetSlipWindowHeight: _setBetSlipWindowHeight,
                setBetSlipWindowWidth: _setBetSlipWindowWidth
            };
        }

        return NewWindowGridFac;
    });

