'use strict';

define(
    'Login',[
        'Security',
        'LoginRequiredSvc',
        'GTMMod'
    ],

    function (Security, LoginRequiredSvc) {

        angular.module('TVG.Login', [
            'TVG.Security',
            'TVG.Common.GTM',
            'TVG.Utilities'
        ])

            .factory('LoginRequiredSvc', LoginRequiredSvc)

            .run(['LoginRequiredSvc', function () {
                //just to start the login required service
            }]);
    }
);

