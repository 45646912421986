'use strict';

define(
    'MyStableSettingsCtrl',[],
    function () {
        function MyStableSettingsCtrl($scope, $rootScope, $filter, MyStableSvc, CookieFac, GTMFac, PreferencesCookieReloadedFac) {
            $scope.layoutAids = {
                loadingAlertsSettings: true,
                isUpdatingSettings: false,
                isSettingsOpened: true,
                isAlertsActive: false,
                showAlertEmailFeedback: false
            };

            $scope.data = {
                'enabled': false,
                'email': '',
                'savedEmail': '',
                'changed': false
            };

            $scope.events = {
                toggleSettingsPanel: _toggleSettingsPanel,
                toggleAlerts: _toggleAlerts,
                updateAlertsEmail: _updateAlertsEmail,
                closeAlertEmailFeedback: _closeAlertEmailFeedback
            };

            function _toggleSettingsPanel() {
                if($scope.layoutAids.isSettingsOpened) {
                    _sendGtmEvent('MyStable | Settings Close', 'MyStable-SettingsClose');
                } else {
                    _sendGtmEvent('MyStable | Settings Open', 'MyStable-SettingsOpen');
                }

                $scope.layoutAids.isSettingsOpened = !$scope.layoutAids.isSettingsOpened;
                _setSettingsPanelStateCookie();
            }

            function _closeAlertEmailFeedback() {
                $scope.layoutAids.showAlertEmailFeedback = false;
            }

            function _toggleAlerts() {
                if($scope.data.enabled) {
                   _sendGtmEvent('MyStable | Notifications Off', 'MyStable-NotificationsOff');
                } else {
                   _sendGtmEvent('MyStable | Notifications On', 'MyStable-NotificationsOn');
                }

                $scope.layoutAids.isUpdatingSettings = true;
                $scope.data.enabled = !$scope.data.enabled;
                _updateAlerts($scope.data.savedEmail);
            }

            function _updateAlertsEmail() {
                if ($scope.data.email != $scope.data.savedEmail) {
                    _sendGtmEvent('MyStable | Save Alerts Email', 'MyStable-SaveAlertsEmail');
                    _updateAlerts($scope.data.email);
                }
            }

            function _updateAlerts() {
                MyStableSvc.changeRaceAlertsSettings($scope.data.enabled).then(null, function () {
                    _sendGtmEvent('MyStable | Generic Error', 'MyStable-genericError');
                    $scope.data.enabled = !$scope.data.enabled;
                    $scope.$emit('myStableErrorMessage', $filter('CMSLabels')('myStableSettingsError', 'myStableLabels'));
                }).finally(function () {
                    $scope.layoutAids.isAlertsActive = angular.copy($scope.data.enabled);
                    $scope.layoutAids.isUpdatingSettings = false;
                });
            }

            function _setUser() {
                if (!$rootScope.user) {
                    $scope.layoutAids.loadingAlertsSettings = true;
                    return;
                }

                if ($scope.data.user) {
                    return;
                }

                $scope.data.user = $rootScope.user;
                if ($scope.data.email === '') {
                    $scope.data.email = $scope.data.user.emailAddress;
                    $scope.data.savedEmail = $scope.data.user.emailAddress;
                }
            }

            function _setSettingsPanelState() {
                $scope.layoutAids.isSettingsOpened = true;
            }

            function _setSettingsPanelStateCookie() {
                var cookieValue = $scope.layoutAids.isSettingsOpened ? 'opened' : 'closed';
                CookieFac.setCookie("my-stable-opened", cookieValue, {expires: 90});
            }

            function _sendGtmEvent(gaEventLabel, eventLabel) {
                // gtm Event
                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction("My Stable Page Click")
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel(gaEventLabel)
                    .withEventLabel(eventLabel)
                    .build();

                var genericEvent = GTMFac.genericEvent(eventData);
                genericEvent.sectionName = genericEvent.sectionName.concat(" | Settings");
                GTMFac.GTMEvent().send($rootScope, 'siteClick', genericEvent);
            }

            function _updateRaceAlerts(event, data) {
                if(data) {
                    $scope.data.enabled = data.emailNotificationsDisabled === "false" || !!data.emailNotificationsDisabled;
                    $scope.data.changed = data.emailNotificationsDisabled === "false" || !!data.emailNotificationsDisabled;
                }
            }

            (function init() {
                _setUser();

                var preferences = PreferencesCookieReloadedFac.getPreferencesFromCookie();
                if (preferences.emailNotificationsDisabled) {
                    var emailNoticationsEnabled = preferences.emailNotificationsDisabled === "false" || !preferences.emailNotificationsDisabled;

                    $scope.data.enabled = emailNoticationsEnabled;
                    $scope.data.changed = emailNoticationsEnabled;

                    $scope.layoutAids.loadingAlertsSettings = false;
                    $scope.layoutAids.isAlertsActive = angular.copy($scope.data.enabled);

                    _setSettingsPanelState();
                } else {
                    MyStableSvc.getRaceAlertsSettings().then(function (res) {
                        if (res) {
                            $scope.data.enabled = res.enabled;
                            $scope.data.changed = res.enabled;
                        }
                    }, function () {
                        $scope.data.enabled = false;
                        $scope.data.changed = false;
                        $scope.data.email = '';
                        $scope.data.savedEmail = '';

                        $scope.$emit('myStableErrorMessage', $filter('CMSLabels')('myStableSettingsInitError', 'myStableLabels'));
                    }).finally(function () {
                        $scope.layoutAids.loadingAlertsSettings = false;
                        $scope.layoutAids.isAlertsActive = angular.copy($scope.data.enabled);

                        _setSettingsPanelState();
                    });
                }

                var unbindWatch = $rootScope.$watch("preferencesUpdated", _updateRaceAlerts);
                var unbindPreferences =  $rootScope.$watch("user", _setUser);
                $scope.$on("$destroy", unbindWatch);
                $scope.$on("$destroy", unbindPreferences);
            }());
        }

        MyStableSettingsCtrl.$inject = [
            '$scope',
            '$rootScope',
            '$filter',
            'MyStableSvc',
            'CookieFac',
            'GTMFac',
            'PreferencesCookieReloadedFac'
        ];

        return MyStableSettingsCtrl;
    }
);



