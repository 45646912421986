'use strict';

define('trackDetailsCtrl',[],
    function () {

        function trackDetailsCtrl($scope, $rootScope, TrackInfoFac, $routeParams, METADATA, GTMFac, $filter, $location, GraphPastTracks, WagerProfileFac, RaceUrlFac) {

            /**
             * Gets all tracks to show them in the page
             * @return {object} [tracks]
             */
            $scope.loading = true;
            TrackInfoFac.getAllTracks().then(function (tracks) {
                $scope.tracks = tracks;
            }, function (error) {
                $scope.getAllTracksError = true;
                $scope.errorMessage = error.message || $filter('CMSValue')('GenericError');
            }).finally(function () {
                $scope.loading = false;
            });

            //Redirect Url to Updated Track Pages
             if($routeParams.trackName) {

                $scope.loading = true;

                GraphPastTracks.getAllTracks(WagerProfileFac.getSessionOrGenericProfile(), { isGreyhound:false })
                    .then(function(tracks) {
                        var tracksData = tracks;
                        var urlTrackName = $routeParams.trackName;

                         // Format the trackName
                        var urlTrackNameFiltered = urlTrackName.toLowerCase().replace(/-/g, '').replace(/\s/g, '');

                         // Isolate the needed track object
                        var specificTrackObj = tracksData.find(function(track) {
                            var matchedTrackName = track.name.toLowerCase().replace(/-/g, '').replace(/\s/g, '');
                            return matchedTrackName  === urlTrackNameFiltered || urlTrackNameFiltered.includes(matchedTrackName) || matchedTrackName.includes(urlTrackNameFiltered);
                        });

                        // Redirect to a new path containing track abbreviation and track name exist, else to track list page
                        if(specificTrackObj){
                            if ($scope.$root && !$scope.$root.$$phase) {
                                $scope.$apply(function () {
                                    $location.path(RaceUrlFac.generateRacePath(specificTrackObj.code, specificTrackObj.name));
                                });
                            }
                        } else {
                            if ($scope.$root && !$scope.$root.$$phase) {
                                $scope.$apply(function () {
                                    $location.path('/racetracks');
                                });
                            }
                        }
                    })
                    .catch(function(error){
                        $scope.errorMessage = error.message || $filter('CMSValue')('GenericError');
                    })
                    .finally(function () {
                        $scope.loading = false;
                    });
                }

            /**
             * Returns if the URL is an image or not
             * @param  {[string]} the URL of the image
             */
            $scope.isImage = function (str) {
                return !!(str || '').match(/\.(jpg|gif|png)$/);
            };

            /**
             * Removes spaces from strings and replaces them with hypens to
             * create a URL for each track
             * @param  {[string]} URL
             * @return {[string]} String in link form
             */
            $scope.createURL = function (str) {
                if(str){
                    if (str.includes('-') || str.includes("'")) {
                        var newStr = str.replace(/-|'/g, '');
                        return encodeURI(newStr.trim().replace(/\s+/g, '-').toLowerCase());
                    } else {
                        return encodeURI(str.trim().replace(/\s+/g, '-').toLowerCase());
                    }
                }
            };

            /**
             * Returns the Track Name
             * @return {[string]} Track Name
             */
            $scope.getTrackName = function () {
                return $routeParams.trackName.split('-').join(' ');
            };

            /**
             * Send Track GTM event
             * @param  {[name]} track name
             */
            $scope.sendGTMEvent = function (option, trackName) {
                var eventCategory = 'Site Click';
                if (option && trackName) {
                    GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.EventBuilder()
                        .withEventLabel('Track List - Results Back Link')
                        .withGaEventAction(eventCategory)
                        .withGaEventCategory(trackName + ' Track Page Click')
                        .withGaEventLabel(option + ' Link')
                        .withTrackName(trackName)
                        .build());
                }
            };

            $scope.sendGTMEventTrack = function (option) {
                var eventCategory = 'Site Click';

                if (option) {
                    GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.EventBuilder()
                        .withEventLabel('Track List - Track Click')
                        .withGaEventAction(eventCategory)
                        .withGaEventCategory('Track List Page Click')
                        .withGaEventLabel('Track | ' + option.Name)
                        .build());
                }
            };

            /**
             * Get track Abbreviation from the track's name
             * @param  {[string]} trackName
             * @return {[string]} track Abbreviation
             */
            $scope.searchMetadataTrackByName = function (trackName) {
                var track = _.find(METADATA.allTracks, function (track) {
                    return trackName.toLowerCase().trim() === track.Name.toLowerCase().trim();
                });
                return track ? track.Abbreviation : '';
            };

            /**
             * Close the get all tracks error message
             */
            $scope.getAllTracksErrorClose = function () {
                $scope.getAllTracksError = false;
            };

            /**
             * Close the specific track error message
             * and redirect to the all tracks page
             */
            $scope.getTrackErrorClose = function () {
                $scope.getTrackError = false;
                $location.url('/racetracks');
            };


            /**
             * If you are in a detail page get all the tracks info
             */
            if ($routeParams.trackName) {
                var trackName = $routeParams.trackName.split('-').join(' ');
                var abbr = $scope.searchMetadataTrackByName(trackName);
                if (abbr) {
                    TrackInfoFac.getTrackDetails(abbr).then(function (data) {
                        $scope.track = data;
                    }, function () {
                        $scope.getTrackError = true;
                        $scope.errorMessage = $filter('CMSValue')('TrackListErrorMessage');
                    });
                } else {
                    $scope.getTrackError = true;
                    $scope.errorMessage = $filter('CMSValue')('GenericError');
                }
            }
        }

        trackDetailsCtrl.$inject = [
            '$scope',
            '$rootScope',
            'TrackInfoFac',
            '$routeParams',
            'METADATA',
            'GTMFac',
            '$filter',
            '$location',
            'GraphPastTracks',
            'WagerProfileFac',
            'RaceUrlFac'
            ];

        return trackDetailsCtrl;
    }
);

