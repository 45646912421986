'use strict';

define(
    'NextRacesBarCtrl',[
        'lodash',
        'RaceStatusUtils',
        'ManageNextRaces'
    ],
    function (_, RaceStatusUtils) {
        function NextRacesBarCtrl(
                $scope,
                $rootScope,
                NextRacesItemsFac,
                GTMFac,
                GraphNextRaces,
                WagerProfileFac,
                RaceMtpStatusFac,
                FavoriteTracksFac,
                LocalStorageFac,
                $filter,
                $log
            ) {
            var nextRacesListSize = 8;
            var nextRacesSubscription;
            var unbind = [];
            var graphPollerInterval = $filter('CMSLabels')('med', 'graphqlPollingRates') || 60000; // in ms

            $scope.model = {
                filters: {
                    favorites: false,
                    us: false,
                    thoroughbred: false,
                    harness: false
                },
                dropdownFilter: {
                    isOpen: false
                },
                nextRacesList: []
            };

            $scope.data = {
                upcomingRaces : [],
                racesAvailable: true,
                loading: false,
                tempFilter : '',
                filteredListEmpty : false
            };

            $scope.raceTrackId = function (race) {
                return [race.trackAbbr, race.perfAbbr, race.raceNumber, race.raceTypeId].join('_');
            };

            function _dropdownToggle () {
                var genericEvent = {};
                var filterSide = $rootScope.activeFeatures['enableNextRacesFilter.left'] === true ? 'Left' : 'Right';

                if($scope.model.dropdownFilter.isOpen){

                    genericEvent = _buildGtmEvent('Next Races Bar | Filter Settings ' + filterSide + ' Open', 'NextRacesBar-FilterSettingsOpen');
                    GTMFac.GTMEvent().send($rootScope, 'siteClick', genericEvent);
                }
                else{
                    genericEvent = _buildGtmEvent('Next Races Bar | Filter Settings ' + filterSide + ' Close', 'NextRacesBar-FilterSettingsClose');
                    GTMFac.GTMEvent().send($rootScope, 'siteClick', genericEvent);
                }
            }

            function _buildGtmEvent(gaEventLabel, eventLabel) {
                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Page Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel(gaEventLabel)
                    .withEventLabel(eventLabel)
                    .build();

                return GTMFac.genericEvent(eventData);
            }

            $scope.events = {
                changeFilter: _changeFilter,
                dropdownToggle: _dropdownToggle
            };

            function _applyFilters () {
                var typeFilter = [];
                var trackFilter = [];

                if ($scope.model.filters.thoroughbred) {
                    typeFilter.push('T');
                }

                if ($scope.model.filters.harness) {
                    typeFilter.push('H');
                }

                if ($scope.model.filters.favorites) {
                    trackFilter = NextRacesItemsFac.getFavoriteTrackList();
                    if (!trackFilter.length) {
                        trackFilter = [''];
                    }
                }

                if (nextRacesSubscription) {
                    GraphNextRaces.refetch({
                        wagerProfile: WagerProfileFac.getSessionOrGenericProfile(),
                        filterBy: {
                            typeCode: typeFilter,
                            trackCode: trackFilter
                        },
                        favorites: FavoriteTracksFac.getFavoriteTracks()
                    })
                        .then(function (upcomgingRaces) {
                            _buildNextRacesList(upcomgingRaces);
                        }, function () {
                            $scope.data.racesAvailable = false;
                            $scope.model.nextRacesList = [];
                        });
                }

            }

            function _displayLoginModal() {
                $rootScope.$broadcast('callLoginRequiredModal');
            }

            function _saveFilterOnStorage (type) {
                switch (type) {
                    case 'favorites':
                        var fav_filter = ($scope.model.filters.favorites === true) ? 1 : 0;
                        LocalStorageFac.setItem('favorites_filter', fav_filter);
                        break;
                    case 'us':
                        var us_filter = ($scope.model.filters.us === true) ? 1 : 0;
                        LocalStorageFac.setItem('us_filter', us_filter);
                        break;
                    case 'thoroughbred':
                        var thoroughbred_filter = ($scope.model.filters.thoroughbred === true) ? 1 : 0;
                        LocalStorageFac.setItem('thoroughbred_filter', thoroughbred_filter);
                        break;
                    case 'harness':
                        var harness_filter = ($scope.model.filters.harness === true) ? 1 : 0;
                        LocalStorageFac.setItem('harness_filter', harness_filter);
                        break;
                    default:
                        break;
                }
            }

            function _changeFilter(type) {
                if ($rootScope.userSession) {
                    $scope.data.loading = true;
                    _saveFilterOnStorage(type);
                    _applyFilters();
                    $scope.data.loading = false;
                } else {
                    $scope.data.tempFilter = type;
                    _displayLoginModal();
                    $scope.model.filters[type] = false;
                }
            }

            function _buildNextRacesList(upcomingRaces) {
                var updatedRaces = _updateMtpValues(upcomingRaces);
                $scope.model.nextRacesList = _.slice(updatedRaces, 0, nextRacesListSize);
                $scope.data.filteredListEmpty = $scope.model.nextRacesList.length === 0;

                if ($scope.$root && !$scope.$root.$$phase) {
                    $scope.$apply();
                }
            }

            function _startPollingData() {
                $scope.data.loading = true;

                if (!nextRacesSubscription) {
                    var options = {
                        wagerProfile: WagerProfileFac.getSessionOrGenericProfile(),
                        favorites: FavoriteTracksFac.getFavoriteTracks(),
                        filterBy: {}
                    };
                    var typeFilters = [];


                    if ($scope.model.filters.thoroughbred) {
                        typeFilters.push('T');
                    }

                    if ($scope.model.filters.harness) {
                        typeFilters.push('H');
                    }

                    if (typeFilters.length > 0) {
                        options.filterBy.typeCode = typeFilters;
                    }

                    if ($scope.model.filters.favorites) {
                        options.filterBy.trackCode = NextRacesItemsFac.getFavoriteTrackList();

                        if (!options.filterBy.trackCode) {
                            options.filterBy.trackCode = [''];
                        }
                    }

                    nextRacesSubscription = GraphNextRaces.subscribe(options, {
                        success: function(upcomingRaces) {
                            if(upcomingRaces.length > 0) {
                                $scope.data.racesAvailable = true;
                                _buildNextRacesList(upcomingRaces);
                            } else {
                                $scope.data.racesAvailable = false;
                                $scope.model.nextRacesList = [];
                            }
                        },
                        fail: function(err) {
                            $log.error(err);
                        }
                    }, graphPollerInterval, false);
                }

                $scope.data.loading = false;
            }

            function _resetFilters() {
                $scope.model.filters.favorites = false;
                $scope.model.filters.thoroughbred = false;
                $scope.model.filters.harness = false;
                $scope.data.tempFilter = '';
            }

            function _getFiltersFromStorage () {
                $scope.model.filters.favorites = LocalStorageFac.getItem('favorites_filter') === '1';
                $scope.model.filters.thoroughbred = LocalStorageFac.getItem('thoroughbred_filter') === '1';
                $scope.model.filters.harness = LocalStorageFac.getItem('harness_filter') === '1';
            }

            function _refreshData() {
                if (nextRacesSubscription) {
                    _applyFilters();
                } else {
                    _startPollingData();
                }
            }

            function _updateMtpValues(races) {
                var racesList = races || $scope.model.nextRacesList;

                return racesList.filter(function (race) {
                    var raceTmp = RaceMtpStatusFac.getRaceMtpStatus(race.raceId);
                    var raceStatus = {status: raceTmp.raceStatus};

                    if (!raceTmp.raceStatus || !RaceStatusUtils.isRaceOpenForLiveVideo(raceStatus)) {
                        return false;
                    }

                    race.mtp = raceTmp.mtp;
                    return true;
                });
            }

            (function init() {
                if (!angular.isDefined($rootScope.userSession)) {
                    var sessionSubscription = $scope.$on('session', function () {
                        if ($rootScope.userSession) {
                            _getFiltersFromStorage();
                        }

                        _startPollingData();

                        // kill $on session right away after 1st call, because session keeps
                        // being called every time the user changes route
                        sessionSubscription();
                    });
                } else {
                    _getFiltersFromStorage();
                    _startPollingData();
                }

                $scope.$on('login', function () {
                    _getFiltersFromStorage();

                    // if user had reached login by trying to filter without login, store the filter
                    // and assign it
                    if ($scope.data.tempFilter) {
                        switch ($scope.data.tempFilter) {
                            case 'favorites':
                                $scope.model.filters.favorites = true;
                                break;
                            case 'us':
                                $scope.model.filters.us = true;
                                break;
                            case 'thoroughbred':
                                $scope.model.filters.thoroughbred = true;
                                break;
                            case 'harness':
                                $scope.model.filters.harness = true;
                                break;
                            default:
                                break;
                        }
                        _saveFilterOnStorage($scope.data.tempFilter);
                    }

                    _refreshData();
                });

                $scope.$on('userLogout', function () {
                    _resetFilters();
                    _refreshData();
                });

                $scope.$on('newFavTrack', function (event, data) {
                    if (!_.isUndefined(data.trackAbbr) && !_.isUndefined(data.newFavorite)) {
                        _.forEach($scope.model.nextRacesList, function (race) {
                            if (race.trackAbbr === data.trackAbbr) {
                                race.isFavorite = data.newFavorite;
                            }
                        });

                        _applyFilters();
                    }
                });

                $scope.$on('clearFavTrackList', function () {
                    _.forEach($scope.model.nextRacesList, function (race) {
                        race.isFavorite = false;
                    });
                });

                $scope.$on('changeToRace', function () {
                    $scope.data.loading = true;
                    $scope.data.raceChanged = true;
                });

                unbind.push($rootScope.$on('raceChangedLoaded', function () {
                    $scope.data.loading = false;
                    $scope.data.raceChanged = false;
                    $scope.$apply();
                }));

                $scope.$on('$destroy', function () {
                    if (nextRacesSubscription) {
                        nextRacesSubscription.unsubscribe();
                    }

                    // to avoid memory leaks
                    unbind.forEach(function (bind) {
                        bind();
                    });
                });

                unbind.push($rootScope.$on('mtpStatusUpdated', function() {
                    _updateMtpValues();
                }));
            }());
        }

        NextRacesBarCtrl.$inject = [
            '$scope',
            '$rootScope',
            'NextRacesItemsFac',
            'GTMFac',
            'GraphNextRaces',
            'WagerProfileFac',
            'RaceMtpStatusFac',
            'FavoriteTracksFac',
            'LocalStorageFac',
            '$filter',
            '$log'
        ];

        return NextRacesBarCtrl;
    }
);

