'use strict';

define('MyStableSearchCtrl',['SubscriptionAlertsFactory'], function (SubscriptionAlertsFactory) {
    function MyStableSearchCtrl($scope, $rootScope, MyStableSvc, $filter, GTMFac, GraphRaceEntities) {
        var vm = this;

        vm.data = {
            messageGenericError: $filter('CMSLabels')('addMyStableGenericError', 'myStableLabels'),
            entityTypes: [
                {
                    id: 1,
                    name: $filter('CMSLabels')('horsesTabLabel', 'myStableLabels'),
                    label: $filter('CMSLabels')('searchHorseLabel', 'myStableLabels')
                },
                {
                    id: 2,
                    name: $filter('CMSLabels')('jockeysTabLabel', 'myStableLabels'),
                    label: $filter('CMSLabels')('searchJockeyLabel', 'myStableLabels')
                },
                {
                    id: 3,
                    name: $filter('CMSLabels')('trainersTabLabel', 'myStableLabels'),
                    label: $filter('CMSLabels')('searchTrainerLabel', 'myStableLabels')
                },
                {
                    id: 4,
                    name: $filter('CMSLabels')('ownersTabLabel', 'myStableLabels'),
                    label: $filter('CMSLabels')('searchOwnerLabel', 'myStableLabels')
                }
            ],
            searchResults: [],
            searchString: "",
            resultsString: "",
            searchLoading: false,
            noSearch: true,
            entityType: {},
            subscriptionsAlerts: SubscriptionAlertsFactory.createAllSubscriptionsAsEnabled(),
            myStableSearchByHorseFCPGraph: $rootScope.activeFeatures.myStableSearchByHorseFCPGraph
        };

        vm.events = {
            search: _search,
            addMyStable: _addMyStable,
            changeEntityType: _changeEntityType
        };

        function _createQueryParameters(searchString, entityLabel) {
            var baseQueryParameters = {
                searchString: '',
                horse: false,
                jockey: false,
                trainer: false,
                owner: false,
            };

            var newQueryParameters = {
                searchString: searchString,
            };

            newQueryParameters[entityLabel.toLowerCase()] = true;

            return Object.assign({}, baseQueryParameters, newQueryParameters);
        }

        function _getResultFieldFromQuery(searchResult, entityLabel) {
            var baseParameters = {
                horse: 'runners',
                jockey: 'jockeys',
                trainer: 'trainers',
                owner: 'owners'
            };

            return searchResult[baseParameters[entityLabel]].map(function(searchItem) {
                // get entityName by creating name in singular
                searchItem.entityType = baseParameters[entityLabel].slice(0, -1);
                return searchItem;
            }) || [];
        }

        function _search(searchString) {
            var queryParams = _createQueryParameters(vm.data.searchString, vm.data.entityType.label);

            vm.searchFunction = vm.data.myStableSearchByHorseFCPGraph ?
                GraphRaceEntities.get(queryParams) : MyStableSvc.searchForMyStableEntity(vm.data.entityType.label, vm.data.searchString);

            vm.data.searchLoading = true;
            vm.data.searchString = searchString;

            vm.searchFunction
                .then(function (data) {
                    _resetSearch();
                    vm.data.resultsString = vm.data.searchString;
                    vm.data.searchResults = vm.data.myStableSearchByHorseFCPGraph ? _getResultFieldFromQuery(data, vm.data.entityType.label.toLowerCase()) : data;
                    vm.data.noSearch = false;
                    vm.data.myStableSearchByHorseFCPGraph && $rootScope.$apply();
                }, function () {
                    _resetSearch();
                    vm.data.noSearch = false;
                    vm.data.myStableSearchByHorseFCPGraph && $rootScope.$apply();
                })
                .finally(function () {
                    GTMFac.GTMEvent().send($rootScope, 'siteSearch', GTMFac.EventBuilder()
                        .withGaEventAction('My Stable Page Click')
                        .withGaEventCategory('Site Search')
                        .withGaEventLabel('My Stable | Item Search')
                        .withEventLabel('MyStable-ItemSearch')
                        .withScreenName('My Account')
                        .withSectionName('My Account | My Stable | Search')
                        .build());
                });
        }

        function _addMyStable(element) {
            vm.data.searchLoading = true;

            GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.EventBuilder()
                .withGaEventAction('My Stable Page Click')
                .withGaEventCategory('Site Click')
                .withGaEventLabel('My Stable | Add Button')
                .withEventLabel('MyStable-AddButton')
                .withScreenName('My Account')
                .withSectionName('My Account | My Stable | Search')
                .build());

            MyStableSvc.addToMyStable(element, $rootScope.user.accountNumber)
                .then(function () {
                    if (!vm.data.myStableSearchByHorseFCPGraph) {
                        _addAllAlerts(element);
                    }
                    vm.data.searchLoading = false;
                }, function () {
                    $scope.$emit('myStableErrorMessage', vm.data.messageGenericError);
                    vm.data.searchLoading = false;

                    var genericEvent = GTMFac.EventBuilder()
                        .withGaEventAction('My Stable Page Click')
                        .withGaEventCategory('Site Click')
                        .withGaEventLabel('My Stable | Add Button')
                        .withEventLabel('MyStable-AddButtonError')
                        .withScreenName('My Account')
                        .withSectionName('My Account | My Stable | Search')
                        .build();

                    genericEvent.errorType = "Add Button Error";
                    genericEvent.errorMessage = vm.data.messageGenericError;

                    GTMFac.GTMEvent().send($rootScope, "siteClick", genericEvent);
                });
        }

        function _addAllAlerts(element) {
            element.subscriptions = vm.data.subscriptionsAlerts;
            MyStableSvc.addToRaceAlerts([element]).then(
                function () {
                    element.added = true;
                },
                function () {
                    $scope.$emit('myStableErrorMessage', vm.data.messageGenericError);

                    var genericEvent = GTMFac.EventBuilder()
                        .withGaEventAction('My Stable Page Click')
                        .withGaEventCategory('Site Click')
                        .withGaEventLabel('My Stable | Add Button')
                        .withEventLabel('MyStable-AddButtonError')
                        .withScreenName('My Account')
                        .withSectionName('My Account | My Stable | Search')
                        .build();

                    genericEvent.errorType = "Add Button Error";
                    genericEvent.errorMessage = vm.data.messageGenericError;

                    GTMFac.GTMEvent().send($rootScope, "siteClick", genericEvent);
                })
                .finally(function () {
                    vm.data.searchLoading = false;
                });
        }

        function _changeEntityType(entityType) {
            _resetSearch();
            vm.data.entityType = entityType;
        }

        function _resetSearch() {
            vm.data.resultsString = "";
            vm.data.searchResults = [];
            vm.data.searchLoading = false;
            vm.data.noSearch = true;
        }

        var unbind = $rootScope.$on("selectMyStableSearch", function () {
            GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.EventBuilder()
                .withGaEventAction('My Stable Page Click')
                .withGaEventCategory('Site Click')
                .withGaEventLabel('My Stable | Search Tab')
                .withEventLabel('MyStable-SearchTab')
                .withScreenName('My Account')
                .withSectionName('My Account | My Stable | Search')
                .build());
        });

        $scope.$on("$destroy", function () {
            unbind();
        });

        (function init() {
            vm.data.entityType = vm.data.entityTypes[0];
            vm.data.searchString = "";
            _resetSearch();
        }());
    }

    MyStableSearchCtrl.$inject = [
        '$scope',
        '$rootScope',
        'MyStableSvc',
        '$filter',
        'GTMFac',
        'GraphRaceEntities'
    ];

    return MyStableSearchCtrl;

});

