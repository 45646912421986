"use strict";

define('RaceVideoFac',[],function(){


    function RaceVideoFac($filter){


        /***
         * Creates the input data structure to tvgvideo directive for replays
         */
        function _buildRaceReplayInputData(raceDate, postTime, trackAbbr, raceNumber, perfAbbr, autoPlay, replayCode, replayFileName) {

            return {
                raceDate: $filter('date')(raceDate, 'yyyy-MM-dd'),
                postTime: $filter('date')(postTime, 'yyyy-MM-ddTHH:mm:ss'),
                isReplay: true,
                trackAbbr: trackAbbr,
                raceNumber: raceNumber,
                perfAbbr: perfAbbr,
                instanceId: 'result-page-replay-video_' + trackAbbr + perfAbbr + raceNumber,
                containerId: 'result-page-replay-video_' + trackAbbr + perfAbbr + raceNumber,
                autoPlay: autoPlay,
                replayCode: replayCode,
                replayFileName: replayFileName
            };
        }

        return {
            buildRaceReplayInputData: _buildRaceReplayInputData
        };
    }

    RaceVideoFac.$inject = [
        '$filter'
    ];

    return RaceVideoFac;

});

