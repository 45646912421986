'use strict';

define('DateComparators',[],
    function () {
        function _isSameDate(initialDate, dateToCompareWith) {
            return (
                initialDate.getFullYear() === dateToCompareWith.getFullYear() &&
                initialDate.getMonth() === dateToCompareWith.getMonth() &&
                initialDate.getDate() === dateToCompareWith.getDate()
                );
        }

        return {
            isSameDate: _isSameDate
        };
    });
