'use strict';

define(
    'SiteTabs',[
        'SiteTabsDir',
        'SiteTabsCtrl'
    ],
    function (SiteTabsDir, SiteTabsCtrl) {

        angular.module('TVG.SiteTabs', [])
            .directive('siteTabs', SiteTabsDir)
            .controller('SiteTabsCtrl', SiteTabsCtrl);
    }
);

