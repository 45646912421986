'use strict';

define('ProfileHelperSvc',[
        'SecuritySvc'
    ],
    function () {

    function ProfileHelperSvc(SecuritySvc) {

        function _changePassword(oldPassword, newPassword, confirmPassword) {
            var passwordData = {
                old_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword
            };
            return SecuritySvc.changePassword(passwordData);
        }

        function _addUsername(accountId, username, password) {
            var newUsernameData = {
                accountId: accountId,
                username: username,
                password: password
            };

            return SecuritySvc.addUsername(newUsernameData);
        }

        function _changeUsername(accountId, username, oldUsername, password) {
            var newUsernameData = {
                accountId: accountId,
                username: username,
                old_username: oldUsername,
                password: password
            };

            return SecuritySvc.changeUsername(newUsernameData);
        }

        return {
            changePassword: _changePassword,
            addUsername: _addUsername,
            changeUsername: _changeUsername
        };
    }

    ProfileHelperSvc.$inject = [
        'SecuritySvc'
    ];

    return ProfileHelperSvc;
});
