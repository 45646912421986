'use strict';

define(
    'BetNowCtrl',[],
    function () {

        function BetNowCtrl($scope, $rootScope, $location, $routeParams, BetslipFactory) {
            $scope.data = BetslipFactory.betslipData;
            $scope.data.betNow = {};

            $scope.data.betSlipWindowParams = [
                $routeParams.trackAbbr,
                $routeParams.perfAbbr,
                $routeParams.race
            ].join('/');

            $scope.events = {
                onPredefinedBetAmountChanged: _onPredefinedBetAmountChanged,
                onBetAmountChanged: BetslipFactory.onBetAmountChanged,
                onBetTypeChanged: BetslipFactory.onBetTypeChanged,
                openBetTicket: _openBetTicket,
                getBetTicketWindowUrl: _getBetTicketWindowUrl,
                setBetAmount: _setBetAmount
            };

            $scope.data.betNow.otherAmountStep = 0.5;
            $scope.windowCallback = null;

            // Subscribe only until we have the wager types.
            var unsubscribeTrackData = $rootScope.$on('trackDataUpdated', function () {
                BetslipFactory.onTrackDataUpdated();

                // Then unsubscribe
                unsubscribeTrackData();
            });

            $scope.$on('login', function(){
                BetslipFactory.onTrackDataUpdated();
            });

            $scope.$on('wagerprofile', function () {
                if($scope.windowCallback) BetslipFactory.tryToBetNowAgain($scope.windowCallback);
                $scope.windowCallback = null;
            });

            $scope.$on('$destroy', function () {
                BetslipFactory.clearBetslip();
            });

            function _onPredefinedBetAmountChanged() {
                if (!$scope.data.bet.preDefinedBetAmount) {
                    $scope.data.betNow.showOtherAmount = true;
                }
                BetslipFactory.onPredefinedBetAmountChanged();
            }

            function _openBetTicket(windowCallback) {
                $scope.windowCallback = windowCallback;
                if($scope.windowCallback) BetslipFactory.betNow($scope.windowCallback);
            }

            function _getBetTicketWindowUrl() {
                var url;
                if (areBetParametersDefined()) {
                    url = buildWithBetParameters($scope.data.bet.betAmount, $scope.data.bet.betType.id, $scope.data.bet.betSelections, $scope.data.bet.talentPickId, $scope.data.bet.talentPickName);
                } else {
                    url = "/betslip/" + $scope.data.betSlipWindowParams;
                }
                return url;
            }

            function areBetParametersDefined() {
                return isBetAmountDefined() && isBetTypeDefined();
            }

            function isBetAmountDefined() {
                return $scope.data.bet && $scope.data.bet.betAmount;
            }

            function isBetTypeDefined() {
                return $scope.data.bet && $scope.data.bet.betType && $scope.data.bet.betType.id;
            }

            function buildWithBetParameters(betAmount, betTypeId, betSelections, talentPickId, talentPickName ) {
                return "/betslip/" + $scope.data.betSlipWindowParams +
                '?betamount=' + betAmount +
                '&bettype=' + betTypeId +
                ( betSelections ? '&betselections=' + betSelections : '' ) +
                ( talentPickId ? '&talentPickId=' + talentPickId : '' ) +
                ( talentPickName ? '&talentPickname=' + talentPickName : '' );
            }

            function _setBetAmount(amount) {
                $scope.data.bet.betAmount = amount;
            }
        }

        BetNowCtrl.$inject = [
            '$scope',
            '$rootScope',
            '$location',
            '$routeParams',
            'BetslipFactory'
        ];

        return BetNowCtrl;
    }
);

