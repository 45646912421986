'use strict';

define('HorseEntity',[],function () {

    /***
     *
     * @param horseID
     * @param horseName
     * @param horseType
     * @constructor
     */
    function HorseEntity(horseID, horseName, horseType){

        this.horseID = horseID;
        this.horseName = horseName;
        this.horseType = horseType;

    }

    return HorseEntity;

});


