'use strict';

define('MyBetsCtrl',['MyBetsDataTransformer', 'GenericMessage', 'moment'], function(MyBetsDataTransformer, GenericMessage, moment) {
            function MyBetsCtrl($scope, $rootScope, MyBetsSvc, METADATA, GTMFac) {
                var patternDate = 'MM-DD-YYYY';
                var endDate = moment(), startDate;
                var trackSelected;
                var gkeMyBets = $rootScope.activeFeatures.gkeMyBets;

                $scope.data = {
                    columns: setWagerColumns(),
                    periods: [
                        {name: "Today", abbr: "wagerToday", data: {report: 'mybets'}, noBetsSuffix: "For Today"},
                        {name: "Since Yesterday", abbr: "wagerSinceYesterday", data: {report: 'mybets'}, noBetsSuffix: "Since Yesterday"},
                        {name: "Since Last Week", abbr: "wagerWeek", data: {report: 'mybets'}, noBetsSuffix: "Since Last Week"},
                        {name: "Since Last Month", abbr: "wagerMonth", data: {report: 'mybets'}, noBetsSuffix: "Since Last Month"}
                    ],
                    betStatus: [
                        {id: 1, name: "All", code: "all"},
                        {id: 2, name: "Resolved", code: "resolved"},
                        {id: 3, name: "Pending", code: "pending"},
                        {id: 4, name: "Canceled", code: "canceled"},
                        {id: 5, name: "Winners", code: "winners"},
                        {id: 6, name: "Refunded", code: "refunded"},
                        {id: 7, name: "Losers", code: "losses"}
                    ],
                    dateFilter: {
                        fromDate: "",
                        toDate: ""
                    },
                    tracks: _generateTrackList(),
                    wagerReport: [],
                    gkeMyBets: $rootScope.activeFeatures.gkeMyBets
                };

                $scope.pagination = {
                    recordsPerPage: ['25', '50', '100'],
                    currentPage: '1',
                    maxPagesOnPagination: '3'
                };

                $scope.genericMessage = new GenericMessage();
                $scope.data.selectedTrack = $scope.data.tracks[0];
                $scope.data.selectedBetStatus = $scope.data.betStatus[0];
                $scope.data.selectedPeriod = $scope.data.periods[0];
                $scope.data.noRecordsMessage = _noRecordsMessage();

                function _generateTrackList() {
                    var trackList = [];
                    trackList.push({ id: 0, name: "All", trackAbbr: ""});
                    _.forEach(METADATA.allTracks, function (track, index) {
                        trackList.push({ id: index + 1, name: track.Name, trackAbbr: track.Abbreviation});
                    });
                    return trackList;
                }

                function _onGetBetsSuccess(data) {
                    $scope.data.loading = false;
                    if (data.wagerHistory && data.wagerHistory.rowData && data.wagerHistory.totalRecords > 0) {
                        $scope.data.wagerReport = data.wagerHistory.rowData.map(MyBetsDataTransformer.toModel(METADATA.wagerTypes));
                        $scope.pagination.currentPage = data.wagerHistory.curPage;
                        $scope.pagination.totalRecords = data.wagerHistory.totalRecords;
                        $scope.data.betsList = $scope.data.wagerReport;
                        $scope.data.totalAmountWinnings = data.totalAmountWinnings;
                    } else if (data.wagerHistory.totalRecords == 0) {
                        $scope.data.loading = false;
                        $scope.data.wagerReport = [];
                        $scope.data.betsList = [];
                    }
                    $scope.$emit('pageLoaded', 'my-bets');
                }

                function _onGetBetsFail(message) {
                    $scope.genericMessage.iconClass = 'tvg-icon tvg-icon-warning text-warning';
                    $scope.genericMessage.message = message;
                    $scope.genericMessage.showMessage = true;
                    $scope.data.loading = false;
                    $scope.data.wagerReport = [];
                    $scope.data.betsList = [];
                    $scope.$emit('pageLoaded', 'my-bets');
                }

                function _noRecordsMessage() {
                    return 'No wager history found for the selected criteria!';
                }

                function _changePeriod(newP, gtmFlag) {
                    $scope.data.selectedPeriod = newP;

                    if (!_.isUndefined(gtmFlag)) {
                        // Acording to spreadsheet, this is under specification
                        var obj = GTMFac.EventBuilder()
                            .withSectionName(GTMFac.Tvg4SectionName({sectionName: newP.name}))
                            .withScreenName(GTMFac.Tvg4ScreenName())
                            .build();

                        GTMFac.GTMEvent().send($rootScope, 'siteClick', obj);
                    }

                    startDate = _getStartDateBasedOnPeriod(newP.abbr);
                    endDate = moment();
                    _resetCurrentPage();

                    $scope.data.dateFilter.fromDate = new Date( moment(startDate, patternDate).format("MM/DD/YYYY"));
                    $scope.data.dateFilter.toDate = new Date(moment(endDate).format("MM/DD/YYYY"));

                    if (!gkeMyBets) {
                        _getBets();
                    } else {
                        // emits an event to be caught by my bets directive
                        $scope.$broadcast('myBetsPeriodChanged');
                    }
                }

                function _changeDates() {

                    if($scope.data.dateFilter.fromDate > $scope.data.dateFilter.toDate){
                        $scope.data.dateFilter.toDate = $scope.data.dateFilter.fromDate;
                    }
                    startDate = moment($scope.data.dateFilter.fromDate).format(patternDate);
                    endDate = moment($scope.data.dateFilter.toDate);

                    /* update the period filters */
                    var curDate = moment().format('YYYY-MM-DD'),
                        today = moment(curDate).format(patternDate),
                        yesterday = moment(curDate).subtract(1, 'days').format(patternDate),
                        lastWeek = moment(curDate).subtract(1, 'week').format(patternDate),
                        lastMonth = moment(curDate).subtract(1, 'month').format(patternDate);

                    $scope.data.selectedPeriod = null;
                    if(startDate == today && endDate.format(patternDate) == today){
                        $scope.data.selectedPeriod = $scope.data.periods[0];
                    }
                    else if(startDate == yesterday && endDate.format(patternDate) == today){
                        $scope.data.selectedPeriod = $scope.data.periods[1];
                    }
                    else if(startDate == lastWeek && endDate.format(patternDate) == today){
                        $scope.data.selectedPeriod = $scope.data.periods[2];
                    }
                    else if(startDate == lastMonth && endDate.format(patternDate) == today){
                        $scope.data.selectedPeriod = $scope.data.periods[3];
                    }

                    _resetCurrentPage();

                    if (!gkeMyBets) {
                        _getBets();
                    } else {
                        // emits an event to be caught by my bets directive
                        $scope.$broadcast('myBetsPeriodChanged');
                    }
                }

                function _getBets() {
                    $scope.data.totalAmountWinnings = 0;
                    $scope.data.loading = true;
                    trackSelected = $scope.data.selectedTrack.id !== 0 ? $scope.data.selectedTrack.trackAbbr : null;

                    MyBetsSvc.getBetsByDate(startDate, endDate.format(patternDate), trackSelected, null, $scope.data.selectedBetStatus.id,
                        $scope.pagination.currentPage, $scope.pagination.selectedRecordsPerPage, $scope.sortedColumn.sortId, _getSortOrder())
                        .then(_onGetBetsSuccess, _onGetBetsFail);
                }

                function _changeSelectedBetStatus(status) {
                    $scope.data.loading = true;
                    $scope.data.selectedBetStatus = status;
                    _resetCurrentPage();

                    if (!gkeMyBets) {
                        _getBets();
                    }
                }

                function _changeTrack(track) {
                    $scope.data.selectedTrack = track;
                    _resetCurrentPage();

                    if (!gkeMyBets) {
                        _getBets();
                    }
                }

                /**
                 * Get the starting date using client's current date
                 * @param  {Object} key  Date period key (abbreviation)
                 * @return {String}      Stringified starting date
                 */
                function _getStartDateBasedOnPeriod(key) {
                    var date;

                    switch (key){
                        case 'wagerSinceYesterday':
                            date = moment().subtract(1, 'day');
                            break;
                        case 'wagerWeek':
                            date = moment().subtract(1, 'week');
                            break;
                        case 'wagerMonth':
                            date = moment().subtract(1, 'month');
                            break;
                        case 'ytd':
                            date = moment().startOf('year');
                            break;
                        default:
                            date = moment();
                    }

                    return date.format(patternDate);
                }

                function _changeNavigation(page, changeRecordsPerPage) {
                    $scope.data.loading = true;
                    $scope.pagination.currentPage = page || $scope.pagination.currentPage;

                    if (changeRecordsPerPage) {
                        _resetCurrentPage();
                    }

                    if (!gkeMyBets) {
                        _getBets();
                    }
                }

                function _sortColumn(column) {
                    $scope.data.loading = true;

                    if (column === $scope.sortedColumn) {
                        $scope.reverse = !$scope.reverse;
                    } else {
                        $scope.reverse = column.defaultSortOrder === 'asc' ? false : true;
                    }

                    $scope.sortedColumn = column;

                    if (!gkeMyBets) {
                        _getBets();
                    }
                }

                function _getSortOrder() {
                    /*
                     TODO: change the order of this return since atm service is returning the opposite of what we are asking
                     Example: if we ask for asc betTotal, it will return first the higher result which is wrong
                     */
                    return $scope.reverse ? 'asc' : 'desc';
                }

                function _initSort() {
                    $scope.sortedColumn = $scope.data.columns[0];
                    $scope.reverse = $scope.sortedColumn.defaultSortOrder === 'asc' ? false : true;
                }

                function _initPagination () {
                    $scope.pagination.selectedRecordsPerPage = $scope.pagination.recordsPerPage[0];
                }

                function _resetCurrentPage() {
                    $scope.pagination.currentPage = "1";
                }

                /**
                 * @private
                 * @description
                 * This function closes all opened notifications, if any.
                 * This includes generic messages and/or cancel forms.
                 */
                function _closeAllOpenedNotifications() {
                    $scope.genericMessage.showMessage = false;
                    $scope.data.betsList.forEach(function (currentValue) {
                        // Close an already existing form.
                        currentValue.showCancelForm = false;
                        // Close any message that might be visible.
                        currentValue.cancelBet.showMessage = false;
                        currentValue.isCanceling = false;
                    });
                }

                function _isCurrentBetCancelling(current) {
                    return current.isCanceling;
                }

                function _onCancelBetClick(index) {
                    var p;

                    if (_.isUndefined($scope.data.betsList[index])) {
                        return;
                    }
                    // We do nothing if we have a cancellation in progress.
                    if ($scope.data.betsList.some(_isCurrentBetCancelling)) {
                        return;
                    }

                    // Close all messages that might be visible.
                    _closeAllOpenedNotifications();
                    $scope.data.betsList[index].showCancelForm = true;

                    p = {
                        gaEventCategory: 'Site Click',
                        gaEventAction: 'My Bets Page Click',
                        gaEventLabel: 'Cancel Bet',
                        sectionName: GTMFac.Tvg4SectionName({sectionName: 'Today'}),
                        screenName: GTMFac.Tvg4ScreenName(),
                        eventLabel: "MyAccount-CancelBet"
                    };

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
                }

                /**
                 * @private
                 * @description
                 * Function called when the user clicks on the 'X' in the generic message.
                 *
                 * @param {Object} cancelBetObj The object that represents the generic message.
                 */
                function _onCloseButtonClick(cancelBetObj) {
                    cancelBetObj.showMessage = false;
                }

                var listenersToUnBind = [];

                listenersToUnBind.push($rootScope.$on('cancelingBet', function(){
                    $scope.data.loading = true;
                }));

                listenersToUnBind.push($rootScope.$on('betCancelled', function(){
                    if (!gkeMyBets) {
                        _changePeriod($scope.data.selectedPeriod);
                    }
                }));

                listenersToUnBind.push($rootScope.$on('cancelBetFail', function(){
                    $scope.data.loading = false;
                }));


                $scope.events = {
                    onCloseButtonClick: _onCloseButtonClick,
                    closeAllOpenedNotifications: _closeAllOpenedNotifications,
                    onCancelBetClick: _onCancelBetClick,
                    changeSelectedBetStatus: _changeSelectedBetStatus,
                    changeTrack: _changeTrack,
                    changePeriod: _changePeriod,
                    changeDates: _changeDates,
                    changeNavigation: _changeNavigation,
                    sortColumn: _sortColumn
                };

                $scope.$on('$destroy', function () {
                    _.forEach(listenersToUnBind, function (unBindListener) {
                        unBindListener();
                    });
                });

                function setWagerColumns() {
                    return [
                        {name: "Date", sortId: 'wagerDate', defaultSortOrder: 'desc'},
                        {name: "Platform"},
                        {name: "Track", sortId: 'trackName', defaultSortOrder: 'asc'},
                        {name: "#"},
                        {name: "Bet Amount", sortId: 'wagerAmount', defaultSortOrder: 'desc'},
                        {name: "Bet Type", sortId: 'wagerTypeId', defaultSortOrder: 'asc'},
                        {name: "Selections"},
                        {name: "Bet Total", sortId: 'betTotal', defaultSortOrder: 'desc'},
                        {name: "Refund"},
                        {name: "Payout", sortId: 'winningsAmount', defaultSortOrder: 'desc', cssClass: 'text-align-center'},
                        {name: "Status", cssClass: 'text-align-center'}
                    ];
                }

                function init() {
                    _initSort();
                    _initPagination();
                    _changePeriod($scope.data.selectedPeriod);
                }

                init();

            }

            MyBetsCtrl.$inject = [
                '$scope',
                '$rootScope',
                'MyBetsSvc',
                'METADATA',
                'GTMFac'
            ];

            return MyBetsCtrl;
        }
);

