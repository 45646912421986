"use strict";

define('RaceAlert',[],function () {

    function RaceAlert(id, name){
        this.id = id;
        this.name = name;
    }

    return RaceAlert;

});

