"use strict";

define('RaceResultsPayoffEntityBuilder',['RaceResultsPayoffEntity'], function (RaceResultsPayoffEntity){

    function builder() {
        var payoff = new RaceResultsPayoffEntity();

        return {
            withPayoffText: function (text) {
                payoff.text = text;
                return this;
            },

            build: function () {
                //return Object.seal(payoff);
                return payoff;
            }
        };

    }

    return builder;

});

