'use strict';

define('RaceStatusEntity',[],function () {

    /***
     *
     * @param raceTypeId
     * @param status
     * @param status
     * @constructor
     */
    function RaceStatus(raceTypeId, raceNumber, status){

        this.raceTypeId = raceTypeId;
        this.raceNumber = raceNumber;
        this.status = status;

    }

    RaceStatus.prototype.setRaceOpen = function() {
        // @TODO use raceStatusUtils!!!!
        var status = this.status.toLowerCase();
        var raceOpen = status !== 'ro' && status !== 'mc';

        this.raceOpenForBetting = raceOpen;
        this.raceOpen = raceOpen;
    };

    return RaceStatus;

});


