'use strict';

define('SwitchLayoutForDir',[],function () {

    function SwitchLayoutForDir(LayoutFac) {
        return {
            restrict: 'A',
            scope: {
                layout: '@switchLayoutFor'
            },
            link: function (scope, elem) {

                function _onElemClick() {

                    if (!scope.layout) {
                        return;
                    }

                    switch (scope.layout.toLowerCase()) {
                        case "mobile":
                            LayoutFac.goToMobile();
                            window.location.reload();
                            break;

                        case "desktop":
                            LayoutFac.goToDesktop();
                            window.location.reload();
                            break;
                    }
                }

                elem.on("click", _onElemClick);
            }
        };
    }

    SwitchLayoutForDir.$inject = [
        "LayoutFac"
    ];

    return SwitchLayoutForDir;
});

