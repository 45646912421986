"use strict";

define('StableEntityTypeFactory',[
        'StableEntityType',
        'lodash'
    ],
    function (StableEntityType, _) {

        var _stableEntityNameById = {
            "1": "Horse",
            "2": "Jockey",
            "3": "Trainer",
            "4": "Owner"
        };

        var _stableEntityIdByName = {
            "horse": 1,
            "jockey": 2,
            "trainer": 3,
            "owner": 4
        };

        function _getStableEntityNameById(id){
            if(_.isNumber(id)){
                id = id.toString();
            }
            return _stableEntityNameById[id];
        }

        function _getStableEntityIdByName(name){
            if(!_.isString(name)){
                return -1;
            }
            return _stableEntityIdByName[name.toLowerCase()];
        }

        return {
            createStableEntityBasedOnName: function (name){
                var entityId = _getStableEntityIdByName(name);
                return new StableEntityType(entityId, name);
            },
            createStableEntityBasedOnId: function (id){
                var entityName = _getStableEntityNameById(id);
                return new StableEntityType(id, entityName);
            },
            createStableEntityType: function (id, name) {
                return new StableEntityType(id, name);
            }
        };

    }
);

