'use strict';

define('PreferencesCookieReloadedFac',['lodash'],
    function (_) {
        function PreferencesCookieReloadedFac(CookieFac, $http, $q, $rootScope, LoginBehaviourConst, ConfigurationFac, LocalStorageFac) {
            var preferencesBaseUrl = ConfigurationFac.getServiceApiUrl('uam') + '/users/_ACCOUNTNUMBER_/preferences';

            var cookieName = 'TVGPrefs', localPreferences = {
                rewards_updated: '',
                balance_updated: '',
                userVideo_updated: '',

                balance_is_shown: 1,
                bet_amount: 2.0,  //Full Bet Ticket
                bet_type: 10,    //Full Bet Ticket
                bet_amount_quick: 2.0, //Quick Bet Ticket
                bet_type_quick: 10,   //Quick Bet Ticket
                show_bet_confirm: 1,
                home_show_races_by: 'featured',
                home_sort_runners_by: 'odds',

                betTicketWidth: '',
                betTicketHeight: '',
                classic_bt: '',
                qdAmount: '',
                qdPayment: '',
                wager_status: '',
                user_id: '',
                user_email: '',
                state: '',
                signal_provider: '',
                transport_id: '',
                loginType: '',
                authed_cachebust: '',
                lastActivity: '',
                currentTrack: '',
                currentRace: '',
                currentPerf: '',
                ppTimerSync: '',
                homepageLoginBehaviour: LoginBehaviourConst.HOMEPAGE,

                fav_filter: '',
                us_filter: '',
                thoroughbred_filter: '',
                harness_filter: ''
            };


            //########################NOT THAT IMPORTANT ...
            function _getBetAmounts() {
                return [
                    {name: "$0.10", value: 0.1},
                    {name: "$0.20", value: 0.2},
                    {name: "$1.00", value: 1.0},
                    {name: "$2.00", value: 2.0},
                    {name: "$3.00", value: 3.0},
                    {name: "$4.00", value: 4.0},
                    {name: "$5.00", value: 5.0},
                    {name: "$10.00", value: 10.0},
                    {name: "$15.00", value: 15.0},
                    {name: "$20.00", value: 20.0},
                    {name: "$25.00", value: 25.0},
                    {name: "$50.00", value: 50.0},
                    {name: "$100.00", value: 100.0},
                    {name: "$150.00", value: 150.0},
                    {name: "$200.00", value: 200.0},
                    {name: "$250.00", value: 250.0},
                    {name: "$500.00", value: 500.0},
                    {name: "$1,000.00", value: 1000.0}
                ];
            }

            function _getShowRacesBy() {
                return ["Featured", "Favorites", "All"];
            }

            function _getSortRacesBy() {
                return ["Odds", "Number"];
            }

            function _getBetAmount() {
                return _getPreferencesFromCookie().bet_amount;
            }

            function _getBetType() {
                return _getPreferencesFromCookie().bet_type;
            }

            //##################### KIND OF IMPORTANT
            //build put request payload
            function _buildPreferencesPayload(keyValueObj) {
                var userPreferences = {preferences: []};
                if (_.isObject(keyValueObj)) {
                    _.forOwn(keyValueObj, function (value, key) {
                        userPreferences.preferences.push({
                            metadata: value,
                            description: key
                        });
                    });
                }
                return userPreferences;
            }

            function _setCookieAndBroadcast(data) {
                CookieFac.setTVGFormatCookieValue(cookieName, data);
                $rootScope.$broadcast('preferencesUpdated', data);
            }

            function _updatePreferences(data) {
                var deferred = $q.defer();

                if(!data || !_.isObject(data) || _.isEmpty(data)) {
                    return $q.reject('Cannot update with invalid preferences.');
                }

                var dataToStore = _.merge(localPreferences, data);

                //store them in the database
                _putPreferences(_buildPreferencesPayload(dataToStore))
                    .then(function () {
                        deferred.resolve(dataToStore);
                    })
                    .catch(function (error) {
                        deferred.reject(error.error);
                    })
                    .finally(function () {
                        _setCookieAndBroadcast(dataToStore);
                    });

                return deferred.promise;
            }

            //PUT HTTP to UAM service
            function _putPreferences(preferences) {
                var deferred = $q.defer();
                var accountNumber = $rootScope.user ? $rootScope.user.accountNumber : null;

                if (accountNumber == null) {
                    return $q.reject('Error saving user preferences! Need account number.');
                }
                else {
                    $http.put(preferencesBaseUrl.replace('_ACCOUNTNUMBER_', accountNumber), preferences)
                        .success(function () {
                            deferred.resolve();
                        })
                        .error(function (error) {
                            deferred.reject(error.error);
                        });
                }

                return deferred.promise;
            }

            //GET HTTP to UAM service to fetch user preferences
            function _getPreferences() {
                var deferred = $q.defer();
                var accountNumber = $rootScope.user ? $rootScope.user.accountNumber : null;

                if (accountNumber == null) {
                    return $q.reject('Error fetching user preferences! Need account number.');
                }
                else {
                    $http.get(preferencesBaseUrl.replace('_ACCOUNTNUMBER_', accountNumber))
                        .success(function (response) {
                            var tmpCookie = {};

                            if (response.preferences && _.isArray(response.preferences)) {
                                response.preferences.forEach(function (preference) {
                                    tmpCookie[preference.description] = isNaN(preference.metadata) ? preference.metadata : _.toNumber(preference.metadata);
                                });
                            } else {
                                tmpCookie = {};
                            }

                            //merge local preferences with the value stored in cookie. Just making sure we don't miss any default if there're some not set
                            var prefs = _.merge(localPreferences, tmpCookie);

                            //save default payment on local storage
                            LocalStorageFac.setItem('defaultPaymentMethod', prefs['defaultPaymentMethod']);

                            _setCookieAndBroadcast(prefs);
                            deferred.resolve(prefs);
                        })
                        .error(function (response) {
                            deferred.reject(response.error);
                        });
                }

                return deferred.promise;
            }

            //get user preferences from cookie, if there is no cookie return defaults.
            function _getPreferencesFromCookie() {
                var tmpCookie = CookieFac.getTVGFormatCookie(cookieName);

                if (!tmpCookie || !_.isObject(tmpCookie) || _.isEmpty(tmpCookie)) {
                    //something is requesting preferences and we don't have the cookie. OK: request the preferences, serve the defaults.
                    //When we get the preferences we update the cookie and an event will be fired to alert the update
                    _getPreferences();
                    return localPreferences;
                } else {
                    Object.keys(tmpCookie).map(function (key) {
                        tmpCookie[key] = isNaN(tmpCookie[key]) ? tmpCookie[key] : _.toNumber(tmpCookie[key]);
                    });
                    return tmpCookie;
                }
            }

            PreferencesCookieReloadedFac.$inject = [
                'CookieFac',
                '$http',
                '$q',
                '$rootScope',
                'LoginBehaviourConst',
                'ConfigurationFac',
                'LocalStorageFac'
            ];

            return {
                getPreferencesFromCookie: _getPreferencesFromCookie,
                getPreferences: _getPreferences,
                updatePreferences: _updatePreferences,
                getBetAmounts: _getBetAmounts,
                getShowRacesBy: _getShowRacesBy,
                getSortRacesBy: _getSortRacesBy,
                getBetAmount: _getBetAmount,
                getBetType: _getBetType
            };
        }

        return PreferencesCookieReloadedFac;
    }
);

