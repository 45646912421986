'use strict';

define('MathAbsoluteValueFilter',[
        'Content'
    ],
    function () {
        function MathAbsoluteValueFilter() {
            return function(value) {
                return Math.abs(value);
            };
        }

        MathAbsoluteValueFilter.$inject = [];

        return MathAbsoluteValueFilter;
    }
);

