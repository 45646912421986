'use strict';

define('FavoriteTracksFac',['PreferencesEntityBuilder', 'lodash'], function (PreferencesEntityBuilder, _) {

    function FavoriteTracksFac(PreferencesFac, $q, FavoriteCookieFac, $http, $rootScope, ConfigurationFac) {
        var ufcUrl = ConfigurationFac.getServiceApiUrl('ufc');
        var FAVORITE_TRACKS_KEY = 'FAVORITE_TRACKS';
        var favoriteTracks = [];
        var favoriteTracksWithId = {};
        var deferredFavoriteTracks = $q.defer();

        function updateLocalFavorites() {
            //update tracks on react apps
            _.attempt(function() {
                sessionStorage.getItem('favoriteTracks') && sessionStorage.removeItem('favoriteTracks');
                sessionStorage.setItem('favoriteTracks', favoriteTracks.join(','));
            });

            mediator.dispatch('NEW_FAVORITE_TRACKS', {
                favoriteTracks: favoriteTracks,
                favoriteTracksWithId: favoriteTracksWithId
            });

            // TODO Remove this once all preferences update are on the micro service
            FavoriteCookieFac.updateFavoriteTrackList(favoriteTracks);
        }

        var favoriteTracksFac =  {
            loadFavoriteTracks: function(accountId) {
                var deferred = $q.defer();
                var tracksList = [];
                    $http
                        .get(ufcUrl + 'users/' + accountId + '/favorites?entityTypes=track' )
                        .success(function (response) {
                            if (response.favorites && _.isArray(response.favorites)) {
                                response.favorites.forEach(function(favoriteTrack) {
                                    if (!favoriteTrack.subscriptions.length) {
                                        tracksList.push(favoriteTrack.entityName);
                                        favoriteTracksWithId[favoriteTrack.entityName] = favoriteTrack.favoriteId;
                                    }
                                });
                            } else {
                                deferred.reject();
                                deferredFavoriteTracks.reject();
                                mediator.dispatch('FAVORITE_TRACKS_ERROR', { e: 'NO FAVORITE TRACKS' });
                            }

                            favoriteTracks = tracksList;
                            mediator.dispatch('LOAD_FAVORITE_TRACKS', { favoriteTracks: tracksList });
                            _.attempt(function () {
                                sessionStorage.setItem('favoriteTracks', tracksList);
                            });
                            deferred.resolve(tracksList);
                            deferredFavoriteTracks.resolve(tracksList);
                        })
                        .error(function (err) {
                            deferred.reject(err);
                            deferredFavoriteTracks.reject(err);
                            mediator.dispatch('FAVORITE_TRACKS_ERROR', { e: 'NO FAVORITE TRACKS' });
                        });

                return deferred.promise;
            },
            getFavoriteTracks: function() {
                return favoriteTracks;
            },
            getFavoriteTracksAsync: function() {
                return deferredFavoriteTracks.promise;
            },
            setFavoriteTracks: function(tracksList, trackCode, favoriteId) {
                favoriteTracks = tracksList;
                favoriteTracksWithId[trackCode] = favoriteId;
                // TODO Remove this once all preferences update are on the micro service
                FavoriteCookieFac.updateFavoriteTrackList(favoriteTracks);
            },
            clearFavoriteList: function (makeRequest) {
                var deferred = $q.defer();

                if (makeRequest) {
                    favoriteTracks.forEach(function (trackCode) {
                        $http
                            .delete(
                                ufcUrl
                                + 'users/'
                                + _.get($rootScope.user, "accountNumber")
                                + '/favorites/'
                                + favoriteTracksWithId[trackCode])
                            .success(function () {
                            var updatedFavoriteTracks = {};
                            Object.keys(favoriteTracksWithId).forEach(function(key) {
                                if (key !== trackCode) {
                                    updatedFavoriteTracks[key] = favoriteTracksWithId[key];
                                }
                            });
                            favoriteTracksWithId = updatedFavoriteTracks;
                                favoriteTracks = favoriteTracks.filter(function (favoriteTrack) {
                                    return favoriteTrack !== trackCode;
                                });
                                updateLocalFavorites();
                                deferred.resolve();
                        });
                    });
                } else {
                    favoriteTracks = [];
                    favoriteTracksWithId = {};
                    updateLocalFavorites();
                    deferred.resolve();
                }

                return deferred.promise;
            },
            addFavoriteTrack: function (trackCode) {
                var deferred = $q.defer();
                var ufcData;

                ufcData = [{
                    entityType: "track",
                    entityName: trackCode
                }];

                $http({
                    method: "post",
                    url: ufcUrl + 'users/' + _.get($rootScope.user, "accountNumber") + '/favorites',
                    data: ufcData
                })
                    .success(function (response) {
                        favoriteTracks.push(trackCode);
                        favoriteTracksWithId[trackCode] = response[0].favoriteId;
                        updateLocalFavorites();
                        deferred.resolve();
                    })
                    .error(deferred.reject);

                return deferred.promise;
            },
            deleteFavoriteTrack: function (trackCode) {
                var deferred = $q.defer();

                $http({
                    method: "delete",
                    url: ufcUrl
                        + 'users/'
                        + _.get($rootScope.user, "accountNumber")
                        + '/favorites/' + favoriteTracksWithId[trackCode]
                }).success(function (response) {
                        var updatedFavoriteTracks = {};
                        Object.keys(favoriteTracksWithId).forEach(function(key) {
                            if (key !== trackCode) {
                                updatedFavoriteTracks[key] = favoriteTracksWithId[key];
                            }
                        });
                        favoriteTracksWithId = updatedFavoriteTracks;
                        favoriteTracks.splice(favoriteTracks.indexOf(trackCode), 1);
                        updateLocalFavorites();
                        deferred.resolve();
                    })
                    .error(deferred.reject);

                return deferred.promise;
            },
            updateFavoriteTracks: function(favoriteTrackList) {
                var metadata = favoriteTrackList.join(',');
                var postData = PreferencesEntityBuilder()
                    .withMetadata(metadata)
                    .withDescription(FAVORITE_TRACKS_KEY)
                    .build();
                return PreferencesFac.updatePreferences(postData).then(function(){
                    favoriteTracks = favoriteTrackList;

                    //update tracks on react apps
                    _.attempt(function() {
                        sessionStorage.getItem('favoriteTracks') && sessionStorage.removeItem('favoriteTracks');
                        sessionStorage.setItem('favoriteTracks', metadata);
                    });
                    mediator.dispatch('NEW_FAVORITE_TRACKS', {favoriteTracks: metadata});

                    //TODO Remove this once all preferences update are on the micro service
                    FavoriteCookieFac.updateFavoriteTrackList(favoriteTrackList);
                });
            }
        };

        return {
            addFavoriteTrack: favoriteTracksFac.addFavoriteTrack,
            deleteFavoriteTrack: favoriteTracksFac.deleteFavoriteTrack,
            loadFavoriteTracks: favoriteTracksFac.loadFavoriteTracks,
            getFavoriteTracks: favoriteTracksFac.getFavoriteTracks,
            getFavoriteTracksAsync: favoriteTracksFac.getFavoriteTracksAsync,
            updateFavoriteTracks: favoriteTracksFac.updateFavoriteTracks,
            clearFavoriteList: favoriteTracksFac.clearFavoriteList,
            setFavoriteTracks: favoriteTracksFac.setFavoriteTracks
        };
    }

    FavoriteTracksFac.$inject = [
        'PreferencesFac',
        '$q',
        'FavoriteCookieFac',
        '$http',
        '$rootScope',
        'ConfigurationFac'
    ];

    return FavoriteTracksFac;

});

