'use strict';

define('WordBreakOpportunityStringFilter',[],function() {
    // This element (<wbr>) is being appended to the comma because we want to give browsers an opportunity to break the text
    // after a comma. Tried to use a CSS style like this 'white-space: pre;' but this causes a entire number to break,
    // e.g. 10, and a selection with multiple numbers separated by comma to break to a new line, e.g. 1,2,4,5,7.
    var WORD_BREAK_SEPARATOR = '<wbr>';
    var DEFAULT_SEPARATOR = ',';

    function _workBreakOpportunityStringFilter(textToFilter, charToSplitBy) {
        if (!charToSplitBy) {
            charToSplitBy = DEFAULT_SEPARATOR;
        }

        return textToFilter.split(charToSplitBy).join(charToSplitBy + WORD_BREAK_SEPARATOR);
    }

    return function() {
        return _workBreakOpportunityStringFilter;
    };
});

