'use strict';

define(
    'TalentPicks',[
        'TalentPicksBase',
        'TalentPicksCtrl',
        'TalentPicksEventsFactory',
        'RaceTalentPicksCtrl',
        'GTMMod'
    ],

    function(TalentPicksBase, TalentPicksCtrl, TalentPicksEventsFactory, RaceTalentPicksCtrl) {

        angular.module('TVG.TalentPicks', [
            'TVG.TalentPicksBase',
            'TVG.Common.GTM'
        ])

            .controller('TalentPicksCtrl', TalentPicksCtrl)
            .controller('RaceTalentPicksCtrl', RaceTalentPicksCtrl)


            .factory('TalentPicksEventsFactory', TalentPicksEventsFactory)

            .directive('talentPicks', function() {
                return {
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/TalentPicks/templates/talent-picks.html',
                    scope: {
                        showFilter: '=',
                        singleRace: '=?'
                    }
                };
            })

            .directive('raceTalentPicks', function() {
                return {
                    restrict: 'E',
                    scope: {
                        talentPicks: '=',
                        singleRace: '=?'
                    },
                    controller: "RaceTalentPicksCtrl",
                    templateUrl: 'js/desktop/modules/TalentPicks/templates/race-talent-picks.html'
                };
            })

            .directive('pick', function() {
                return {
                    restrict: 'E',
                    scope: true,
                    templateUrl: 'js/desktop/modules/TalentPicks/templates/talent-pick.html',
                    link: function(scope) {
                        scope.talentPick = scope.$parent.pick;
                    }
                };
            });
    }
);

