"use strict";

define('MyFundsCtrl',[], function () {
    function MyFundsCtrl($scope, $routeParams, $location) {
        $scope.myFundsTabs = [];
        $scope.withdrawIndex = 2;
        var toggles;
        var shouldShowPaymentTab = true;

        (function init() {
            $scope.myFundsTabs.push({
                name: "Deposit",
                qaLabel: "deposit",
                id: "deposit",
                active: false,
                initialTab: $routeParams.initialTab,
            });

            $scope.myFundsTabs.push({
                name: "Withdraw",
                qaLabel: "withdraw",
                id: "withdraw",
                active: false,
                initialTab: $routeParams.initialTab,
            });

            if ($scope && $scope.activeFeatures) {
                shouldShowPaymentTab = false;
                $scope.shouldShowPaymentTab = shouldShowPaymentTab;
                $scope.withdrawIndex = shouldShowPaymentTab ? 2 : 1;
            }

            if (shouldShowPaymentTab) {
                $scope.myFundsTabs.splice(1, 0, {
                    name: "Payment Management",
                    qaLabel: "bank",
                    id: "bank",
                    active: false,
                    initialTab: $routeParams.initialTab,
                });
            }

            _checkParams();
        })();

        function _checkParams() {
            if (!_.isUndefined($routeParams.myFundsTab)) {
                var activeTab = _.filter($scope.myFundsTabs, {
                    id: $routeParams.myFundsTab,
                });
                if (!_.isUndefined(activeTab[0])) {
                    activeTab[0].active = true;
                    return;
                }
            }

            $location.path("/my-funds/" + _.head($scope.myFundsTabs).id);
        }
    }

    MyFundsCtrl.$inject = ["$scope", "$routeParams", "$location"];

    return MyFundsCtrl;
});

