'use strict';

define('CustomerCookieFac',[],
    function () {
        function CustomerCookieFac(CookieFac) {
            var cookieName = 'tvgCustomer-cookie',
                customerCookie;

            function _getCookie() {
                if (angular.isObject(customerCookie)) {
                    return customerCookie;
                }

                customerCookie = {
                    betTicketWidth: '',
                    betTicketHeight: '',
                    customer: '',
                    ga_acct: ''
                };

                var tmpCookie = CookieFac.getCookie(cookieName);

                angular.extend(customerCookie, tmpCookie);

                return customerCookie;
            }

            function _setCustomerCookie(val) {
                customerCookie = val;
            }

            function _setCookieValue(keyValueObject) {
                CookieFac.setCookie(cookieName, keyValueObject);
            }

            return {
                getCookie: _getCookie,
                setValue: _setCookieValue,
                setCustomerCookie: _setCustomerCookie
            };

        }

        //CookieFac

        CustomerCookieFac.$inject = [
            'CookieFac'
        ];

        return CustomerCookieFac;
    });

