'use strict';

define('RaceTalentPicksCtrl',[],
    function() {
        function RaceTalentPicksCtrl($scope, TalentPicksEventsFactory, $rootScope) {
            var unbind = [];

            function _destroyListener() {
                $scope.data.loading = false;

                _.forEach(unbind, function (bind) {
                    bind();
                });
            }

            (function init() {
                $scope.data = {};

                $scope.events = {
                    talentPickRaceUrl: TalentPicksEventsFactory.talentPickRaceUrl,
                    openBetTicket: TalentPicksEventsFactory.openBetTicket($scope),
                    openTalentPickRace: TalentPicksEventsFactory.openTalentPickRace,
                    goToTalentPicks: TalentPicksEventsFactory.goToTalentPicks,
                    goToProgramPage: TalentPicksEventsFactory.goToProgramPage,
                    goToRaceResults: TalentPicksEventsFactory.goToRaceResults,
                    showMTP: TalentPicksEventsFactory.showMTP
                };

                unbind.push($rootScope.$on('wagerprofile', TalentPicksEventsFactory.onWagerProfileListener($scope)));

                $scope.$on('$destroy', _destroyListener);
            }());
        }

        RaceTalentPicksCtrl.$inject = [
            '$scope',
            'TalentPicksEventsFactory',
            '$rootScope'
        ];

        return RaceTalentPicksCtrl;
    });

