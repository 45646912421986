'use strict';

define('ComingSoonItemDir',[], function () {

    function ComingSoonItemDir() {
        return {
            restrict: 'E',
            scope: true,
            templateUrl: 'js/desktop/modules/ComingSoon/templates/coming-soon-item.html'
        };
    }

    return ComingSoonItemDir;
});

