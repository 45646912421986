'use strict';

define('HorizontalScrollDir',['StatePersistenceFac'], function () {

    function HorizontalScrollDir($timeout, StatePersistenceFac, $rootScope, $filter) {
        return {
            restrict: 'EA',
            scope: true,
            link: function ($scope, elem, attrs) {
                var $elem = angular.element(elem),
                    $parent = $elem.parent(),
                    leftArrow = $parent.find("prevarrow"),
                    rightArrow = $parent.find("nextarrow"),
                    $list = $parent.find("ul"),
                    list = $list[0],
                    parent = $parent[0],
                    scrollAmount = 90,
                    track = attrs.track,
                    previousScroll = StatePersistenceFac.loadState("hscrolllist"),
                    scrollPosition = previousScroll ? previousScroll[track] : 0;

                $scope.events = {
                    scrollLeft: _scrollLeft,
                    scrollRight: _scrollRight,
                    resetScroll: _resetScroll,
                    getLink: _getLink
                };

                scrollPosition = scrollPosition || 0;

                $scope.$on('swipeLeft', _scrollLeft);
                $scope.$on('swipeRight', _scrollRight);
                $rootScope.$on('resetSwipe', _resetScroll);

                function _scrollLeft() {
                    scrollAmount = parseInt(parent.clientWidth * 0.8, 10);
                    if (!_minScrollReached()) {
                        scrollPosition += scrollAmount;
                        _applyScrollAndcheckArrowsVisibility();
                    }
                }

                function _getLink(race, autoplay, today) {
                    if(today) {
                        autoplay = autoplay == true ? '?autoplay=true':'';
                        return $filter('raceLink')(race) + autoplay;
                    }
                }

                function _scrollRight() {
                    scrollAmount = parseInt(parent.clientWidth * 0.8, 10);
                    if (!_maxScrollReached()) {
                        // parent.scrollLeft += scrollAmount;
                        scrollPosition -= scrollAmount;
                        _applyScrollAndcheckArrowsVisibility();
                    }
                }

                function _delayCheck() {

                    if (_minScrollReached()) {
                        leftArrow.addClass("no-arrow");
                    } else {
                        leftArrow.removeClass("no-arrow");
                    }

                    if (_maxScrollReached()) {
                        rightArrow.addClass("no-arrow");
                    } else {
                        rightArrow.removeClass("no-arrow");
                    }

                    if (list.clientWidth <= parent.clientWidth) {
                        rightArrow.addClass("no-arrow");
                        leftArrow.addClass("no-arrow");
                    }
                }

                function _resetScroll() {
                    scrollPosition = 0;
                    _applyScrollAndcheckArrowsVisibility();
                }

                function _applyScrollAndcheckArrowsVisibility(noAnimation) {
                    rightArrow.addClass("tvg-icon-arrow-right");
                    leftArrow.addClass("tvg-icon-arrow-left");

                    if (-scrollPosition < 0) {
                        scrollPosition = 0;
                    }
                    if (list.clientWidth - parent.clientWidth > 0 && -scrollPosition > list.clientWidth - parent.clientWidth) {
                        scrollPosition = -(list.clientWidth - parent.clientWidth);
                    }
                    if (!previousScroll) {
                        previousScroll = {};
                    }
                    previousScroll[track] = scrollPosition;
                    if (noAnimation) {
                        $list.addClass("no-animation");
                    } else {
                        $list.removeClass("no-animation");
                    }
                    StatePersistenceFac.saveState("hscrolllist", previousScroll);
                    $list.css({
                        '-webkit-transform': 'translateX(' + scrollPosition + 'px)',
                        '-moz-transform': 'translateX(' + scrollPosition + 'px)',
                        '-ms-transform': 'translateX(' + scrollPosition + 'px)',
                        '-o-transform': 'translateX(' + scrollPosition + 'px)',
                        'transform': 'translateX(' + scrollPosition + 'px)'
                    });
                    $timeout(_delayCheck, 0);
                }

                _applyScrollAndcheckArrowsVisibility(true);

                function _maxScrollReached() {
                    var currentScrollPosition = scrollPosition * -1;
                    return (list.clientWidth - parent.clientWidth < 0) || (currentScrollPosition > 0 && list.clientWidth - currentScrollPosition <= parent.clientWidth);
                }

                function _minScrollReached() {
                    var currentScrollPosition = scrollPosition * -1;
                    return currentScrollPosition <= 0;
                }
            }
        };
    }

    HorizontalScrollDir.$inject = ['$timeout', 'StatePersistenceFac', '$rootScope', '$filter'];

    return HorizontalScrollDir;
});

