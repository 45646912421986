'use strict';

define('BetSlipOpenerHelperFac',[
        'lodash',
        'RaceStatusUtils'
    ],
    function (_, RaceStatusUtils) {
        function BetSlipOpenerHelperFac(RaceListFac, $q, $location, RaceUrlFac, RaceMtpStatusFac, GraphNextRaces, WagerProfileFac) {

            var currentTrackAbbr = '';
            var currentTrackName = '';
            var currentRaceNumber;
            var useMtpRefreshSystem = false;
            var programPageRacesData;

            /************************************
             *          Private Functions       *
             ***********************************/

            function _setCurrentTrackAbbr(trackAbbr) {
                currentTrackAbbr = trackAbbr;
            }

            function _setCurrentTrackName(trackName) {
                currentTrackName = trackName;
            }

            function _setCurrentRaceNumber(raceNumber) {
                currentRaceNumber = raceNumber;
            }

            /**
             * Builds url for betslip when the user is situated in the program page. If the race that the user is seeing is closed, reject in order to get the closest race.
             * @returns A promise with the url. If the promise is rejected the default url is returned.
             * @private
             */
            function _buildUrlOnProgramPage() {
                var deferred = $q.defer();
                var routeParams = RaceUrlFac.getRacesInfoFromRouteParams();

                //The current path must contain /race/ (program page) and the necessary path params must be defined
                if (programPageRacesData &&
                    $location.url().match('/racetracks/') &&
                    routeParams.trackAbbr == programPageRacesData.currentTrackAbbr &&
                    routeParams.raceNumber == programPageRacesData.currentRaceNumber) {

                    var urlParam = {
                        trackAbbr: '',
                        raceNumber: ''
                    };

                    _setCurrentTrackAbbr(programPageRacesData.currentTrackAbbr);
                    if (programPageRacesData.currentRaceIsWagerable) {
                        urlParam.trackAbbr = programPageRacesData.currentTrackAbbr;
                        urlParam.raceNumber = programPageRacesData.currentRaceNumber;
                    } else {
                        var race = _.find(programPageRacesData.raceList, function (race) {
                            return RaceStatusUtils.isRaceOpenForBetting(race);
                        });

                        if (race) {
                            urlParam.trackAbbr = race.trackAbbr;
                            urlParam.raceNumber = race.raceNumber;
                        } else {
                            return _buildUrlForClosestRace();
                        }
                    }
                    deferred.resolve(_urlBuilder(urlParam.trackAbbr, routeParams.perfAbbr, urlParam.raceNumber));
                } else {
                    deferred.reject(_defaultUrl());
                }

                return deferred.promise;
            }

            /**
             * Builds url for betslip when the user is not situated at the program page. The closest upcoming race is selected.
             * @returns A promise with the url. If the promise is rejected the default url is returned.
             * @private
             */
            function _buildUrlForClosestRace() {
                var deferred = $q.defer();
                var availableRace;

                if (!useMtpRefreshSystem) {
                    availableRace = _getClosestRace(RaceListFac.getUpcomingRaces());
                } else {
                    var race = RaceMtpStatusFac.getClosestRaceToBet();
                    if (race) {
                        availableRace = {
                            trackAbbr: race.trackAbbr,
                            trackName: race.trackAbbr,
                            raceNumber: race.raceNumber,
                            perfAbbr: race.perfAbbr,
                            MTP: race.mtp,
                            id: race.raceId
                        };
                    }
                }

                if (availableRace) {
                    _setCurrentTrackAbbr(availableRace.trackAbbr);
                    _setCurrentTrackName(availableRace.trackName);
                    _setCurrentRaceNumber(availableRace.raceNumber);
                    deferred.resolve(_urlBuilder(availableRace.trackAbbr, availableRace.perfAbbr, availableRace.raceNumber));
                    return deferred.promise;
                } else {
                    GraphNextRaces
                        .get(WagerProfileFac.getSessionOrGenericProfile(), [], {}, {current: 0, results: 1})
                        .then(function (data) {
                            var race = _.head(data);
                            if (race) {
                                _setCurrentTrackAbbr(race.trackAbbr);
                                _setCurrentTrackName(race.trackName);
                                _setCurrentRaceNumber(race.raceNumber);
                                deferred.resolve(_urlBuilder(race.trackAbbr, race.perfAbbr, race.raceNumber));
                            } else {
                                _resetCurrentTrackAbbr();
                                _resetCurrentTrackName();
                                _resetCurrentRaceNumber();
                                deferred.reject(_defaultUrl());
                            }
                            return deferred.promise;
                        }, function () {
                            _resetCurrentTrackAbbr();
                            _resetCurrentTrackName();
                            _resetCurrentRaceNumber();
                            deferred.reject(_defaultUrl());
                            return deferred.promise;
                        });
                }

                return deferred.promise;
            }

            /**
             * Builds path params part of url
             * @param trackAbbr
             * @param perfAbbr
             * @param raceNumber
             * @returns {string}
             * @private
             */
            function _urlBuilder(trackAbbr, perfAbbr, raceNumber) {
                return ['/betslip', trackAbbr, perfAbbr, raceNumber].join('/');
            }

            /**
             * Default url for betslip
             * @returns {string}
             * @private
             */
            function _defaultUrl() {
                return '/betslip/';
            }

            /**
             * Filters an array of races looking for the closest race.
             * @param races
             * @returns {*}
             * @private
             */
            function _getClosestRace(races) {
                return _.find(races, function (race) {
                    return race.closedForBetting === false;
                });
            }

            /**
             * Resets the current track abbreviation to an empty string
             * @private
             */
            function _resetCurrentTrackAbbr() {
                currentTrackAbbr = '';
            }

            function _resetCurrentTrackName() {
                currentTrackName = '';
            }

            function _resetCurrentRaceNumber() {
                currentRaceNumber = '';
            }

            /************************************
             *          Public Functions        *
             ***********************************/

            function getCurrentTrackAbbr() {
                return currentTrackAbbr;
            }

            function getCurrentTrackName() {
                return currentTrackName;
            }

            function getCurrentRaceNumber() {
                return currentRaceNumber;
            }

            function buildBetSlipUrl() {
                var deferred = $q.defer();

                _buildUrlOnProgramPage().then(function (programPageUrl) {
                    deferred.resolve(programPageUrl);
                }, function () { //Not on program page
                    _buildUrlForClosestRace().then(function (closesRaceUrl) {
                        deferred.resolve(closesRaceUrl);
                    }, function (defaultUrl) { //Well something went wrong...
                        deferred.resolve(defaultUrl);
                    });
                });

                return deferred.promise;
            }

            function setMtpRefreshSystem(toggle) {
                useMtpRefreshSystem = toggle;
            }

            function setProgramPageRacesData(data) {
                programPageRacesData = data;
            }

            return {
                getBetSlipUrl: buildBetSlipUrl,
                getCurrentTrackAbbr: getCurrentTrackAbbr,
                getCurrentTrackName: getCurrentTrackName,
                getCurrentRaceNumber: getCurrentRaceNumber,
                setMtpRefreshSystem: setMtpRefreshSystem,
                setProgramPageRacesData: setProgramPageRacesData
            };
        }

        BetSlipOpenerHelperFac.$inject = [
            'RaceListFac',
            '$q',
            '$location',
            'RaceUrlFac',
            'RaceMtpStatusFac',
            'GraphNextRaces',
            'WagerProfileFac'
        ];

        return BetSlipOpenerHelperFac;
    });

