'use strict';

define('PreferencesCookieFac',[],
    function () {
        function PreferencesCookieFac(CookieFac, $http, $q, $rootScope, $cookies, LoginBehaviourConst, PreferencesCookieReloadedFac) {
            var cookieName = 'TVGPrefs',
                prefsCookie;

            $rootScope.$watch(function () {
                return $cookies.get('TVGPrefs');
            }, function (newValue, oldValue) {
                if (angular.equals(newValue, oldValue)) {
                    return;
                }

                if (!$rootScope.activeFeatures.useUamPreferences) {
                    prefsCookie = null;
                    _getPreferences();

                    $rootScope.$broadcast('preferencesUpdated', prefsCookie);
                }
            });

            function _getPreferences() {
                if (angular.isObject(prefsCookie)) {
                    return prefsCookie;
                }

                prefsCookie = {
                    rewards_updated: '',
                    balance_updated: '',
                    userVideo_updated: '',

                    balance_is_shown: 1,
                    bet_amount: 2.0,  //Full Bet Ticket
                    bet_type: 10,    //Full Bet Ticket
                    bet_amount_quick: 2.0, //Quick Bet Ticket
                    bet_type_quick: 10,   //Quick Bet Ticket
                    show_bet_confirm: 1,
                    home_show_races_by: 'featured',
                    home_sort_runners_by: 'odds',

                    betTicketWidth: '',
                    betTicketHeight: '',
                    classic_bt: '',
                    qdAmount: '',
                    qdPayment: '',
                    wager_status: '',
                    user_id: '',
                    user_email: '',
                    state: '',
                    signal_provider: '',
                    transport_id: '',
                    loginType: '',
                    authed_cachebust: '',
                    lastActivity: '',
                    currentTrack: '',
                    currentRace: '',
                    currentPerf: '',
                    ppTimerSync: '',
                    homepageLoginBehaviour: LoginBehaviourConst.HOMEPAGE,

                    fav_filter: '',
                    us_filter: '',
                    thoroughbred_filter: '',
                    harness_filter: ''
                };

                var tmpCookie = CookieFac.getTVGFormatCookie(cookieName);

                angular.extend(prefsCookie, tmpCookie);

                if (prefsCookie.bet_type == '' || isNaN(prefsCookie.bet_type)) {
                    prefsCookie.bet_type = 10; //Default to Win.
                } else {
                    prefsCookie.bet_type = Number(prefsCookie.bet_type);
                }

                if (prefsCookie.bet_amount === '' || isNaN(prefsCookie.bet_amount)) {
                    prefsCookie.bet_amount = 2.0; //Default to $2.
                } else {
                    prefsCookie.bet_amount = Number(prefsCookie.bet_amount);
                }

                if (prefsCookie.bet_type_quick === '' || isNaN(prefsCookie.bet_type_quick)) {
                    prefsCookie.bet_type_quick = 10; //Default to Win.
                } else {
                    prefsCookie.bet_type_quick = Number(prefsCookie.bet_type_quick);
                }

                if (prefsCookie.bet_amount_quick === '' || isNaN(prefsCookie.bet_amount_quick)) {
                    prefsCookie.bet_amount_quick = 2.0; //Default to $2.
                } else {
                    prefsCookie.bet_amount_quick = Number(prefsCookie.bet_amount_quick);
                }

                if (prefsCookie.home_sort_runners_by === '' ||
                    (prefsCookie.home_sort_runners_by.toLowerCase() != 'odds' && prefsCookie.home_sort_runners_by.toLowerCase() != 'number')) {
                    prefsCookie.home_sort_runners_by = 'odds';
                }

                if (prefsCookie.home_show_races_by === '' ||
                    (prefsCookie.home_show_races_by.toLowerCase() != 'featured' && prefsCookie.home_show_races_by.toLowerCase() != 'favorites' && prefsCookie.home_show_races_by.toLowerCase() != 'all')) {
                    prefsCookie.home_show_races_by = 'featured';
                }

                if (prefsCookie.show_bet_confirm === '' || isNaN(prefsCookie.show_bet_confirm)) {
                    prefsCookie.show_bet_confirm = 1;
                } else {
                    prefsCookie.show_bet_confirm = Number(prefsCookie.show_bet_confirm);
                }

                if (prefsCookie.balance_is_shown === '' || isNaN(prefsCookie.balance_is_shown)) {
                    prefsCookie.balance_is_shown = 1;
                } else {
                    prefsCookie.balance_is_shown = Number(prefsCookie.balance_is_shown);
                }

                prefsCookie.fav_filter = (prefsCookie.fav_filter === '' || isNaN(prefsCookie.fav_filter)) ? 0 : Number(prefsCookie.fav_filter);
                prefsCookie.us_filter = (prefsCookie.us_filter === '' || isNaN(prefsCookie.us_filter)) ? 0 : Number(prefsCookie.us_filter);
                prefsCookie.thoroughbred_filter = (prefsCookie.thoroughbred_filter === '' || isNaN(prefsCookie.thoroughbred_filter)) ? 0 : Number(prefsCookie.thoroughbred_filter);
                prefsCookie.harness_filter = (prefsCookie.harness_filter === '' || isNaN(prefsCookie.harness_filter)) ? 0 : Number(prefsCookie.harness_filter);

                return prefsCookie;
            }

            function _updatePreferences(keyValueObject) {
                var deferred = $q.defer();

                CookieFac.setTVGFormatCookieValue(cookieName, keyValueObject);

                $http({
                    method: 'GET',
                    url: "/ajax/preferences/update/preferencesdata"
                }).success(function (response) {
                    deferred.resolve(response);
                }).error(function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }


            function _getBetAmounts() {
                return [
                    {name: "$0.10", value: 0.1},
                    {name: "$0.20", value: 0.2},
                    {name: "$1.00", value: 1.0},
                    {name: "$2.00", value: 2.0},
                    {name: "$3.00", value: 3.0},
                    {name: "$4.00", value: 4.0},
                    {name: "$5.00", value: 5.0},
                    {name: "$10.00", value: 10.0},
                    {name: "$15.00", value: 15.0},
                    {name: "$20.00", value: 20.0},
                    {name: "$25.00", value: 25.0},
                    {name: "$50.00", value: 50.0},
                    {name: "$100.00", value: 100.0},
                    {name: "$150.00", value: 150.0},
                    {name: "$200.00", value: 200.0},
                    {name: "$250.00", value: 250.0},
                    {name: "$500.00", value: 500.0},
                    {name: "$1,000.00", value: 1000.0}
                ];
            }

            function _getShowRacesBy() {
                return ["Featured", "Favorites", "All"];
            }

            function _getSortRacesBy() {
                return ["Odds", "Number"];
            }

            function _getBetAmount() {
                return _getPreferences().bet_amount;
            }

            function _getBetType() {
                return _getPreferences().bet_type;
            }

            PreferencesCookieFac.$inject = [
                'CookieFac',
                '$http',
                '$q',
                '$rootScope',
                '$cookies',
                'LoginBehaviourConst',
                'PreferencesCookieReloadedFac'
            ];

            return {
                getPreferences: function () {
                    if($rootScope.activeFeatures.useUamPreferences) {
                        return PreferencesCookieReloadedFac.getPreferencesFromCookie();
                    } else {
                        return _getPreferences();
                    }
                },
                updatePreferences: function (data) {
                    if($rootScope.activeFeatures.useUamPreferences) {
                        return PreferencesCookieReloadedFac.updatePreferences(data);
                    } else {
                        return _updatePreferences(data);
                    }
                },
                getBetAmounts: function () {
                    if($rootScope.activeFeatures.useUamPreferences) {
                        return PreferencesCookieReloadedFac.getBetAmounts();
                    } else {
                        return _getBetAmounts();
                    }
                },
                getShowRacesBy: function () {
                    if($rootScope.activeFeatures.useUamPreferences) {
                        return PreferencesCookieReloadedFac.getShowRacesBy();
                    } else {
                        return _getShowRacesBy();
                    }
                },
                getSortRacesBy: function () {
                    if($rootScope.activeFeatures.useUamPreferences) {
                        return PreferencesCookieReloadedFac.getSortRacesBy();
                    } else {
                        return _getSortRacesBy();
                    }
                },
                getBetAmount: function () {
                    if($rootScope.activeFeatures.useUamPreferences) {
                        return PreferencesCookieReloadedFac.getBetAmount();
                    } else {
                        return _getBetAmount();
                    }
                },
                getBetType: function () {
                    if($rootScope.activeFeatures.useUamPreferences) {
                        return PreferencesCookieReloadedFac.getBetType();
                    } else {
                        return _getBetType();
                    }
                }
            };
        }

        return PreferencesCookieFac;
    });

