'use strict';

define('Layout',[
        'LayoutDir',
        'SwitchLayoutForDir',
        'LayoutFac',
        'MediatorSubscriptionsFac',
        'GTMMod'
    ],
    function (LayoutDir,
              SwitchLayoutForDir,
              LayoutFac,
              MediatorSubscriptionsFac) {

        angular.module('TVG.Layout', [
            'TVG.Common.GTM'
        ])

            .factory('LayoutFac', LayoutFac)
            .factory('MediatorSubscriptionsFac', MediatorSubscriptionsFac)

            .directive('layout', LayoutDir)
            .directive('switchLayoutFor', SwitchLayoutForDir);
    }
);

