'use strict';

define(
    'RaceResultsCtrl',[
        'lodash'
    ],
    function (_) {
        function RaceResultsCtrl($scope, RaceInfoFac, ProgramPageRunnersFac, $rootScope, RunnerReplayModalFac, $location, $route, GTMFac, RaceUrlFac) {
            var selectedHandicappingOption;
            var DEFAULT_SORTABLE_COLUMN = "horseID";

            $scope.buildRaceResultsTitle = function (race) {

                var title = "";

                try {
                    title = RaceInfoFac.buildRaceResultsTitle(race);
                } catch (e) {
                    title = "Race";
                }

                return title;
            };

            function _isUserLoggedOut() {
                return !$rootScope.userSession;
            }

            $scope.events = {
                handleBetSlipOpening: function (openWindowCallback) {
                    if (_isUserLoggedOut()) {
                        $rootScope.$emit("callLoginRequiredModal", openWindowCallback);
                    } else {
                        openWindowCallback();
                    }
                },
                getBetSlipUrlForThisRunner: function (runnerId) {
                    if (_.isUndefined($scope.race.trackAbbr)
                        || _.isUndefined($scope.race.perfAbbr)
                        || _.isUndefined($scope.race.raceNumber)) {
                        //"TrackAbbr, PerfAbbr and RaceNumber must be defined!!"
                        return;
                    }

                    var url = "/betslip/{trackAbbr}/{perfAbbr}/{raceNumber}?betselections={selectedHorse}&fromRunnerClick=true" + ($rootScope.isGreyhoundRace ? '&greyhounds' : '');
                    return url
                        .replace("{trackAbbr}", $scope.race.trackAbbr)
                        .replace("{perfAbbr}", $scope.race.perfAbbr)
                        .replace("{raceNumber}", $scope.race.raceNumber)
                        .replace("{selectedHorse}", runnerId);
                },
                sortRunnersBy: _sortRunnersBy
            };

            $scope.data = {
                handicappingAvailable: false
            };

            $scope.data.horseDetails = "js/desktop/modules/Races/templates/desktop/horse-details-template.html";
            $scope.data.dogDetails = "js/desktop/modules/Races/templates/desktop/dog-details-template.html";
            $scope.data.raceReplays = "Race Replays";

            /**
             * public objects for sorting purposes
             * */
            $scope.reverse = true;
            $scope.sortedColumn = '';

            /***
             * set the sortedColumn and if it is reverse or not depending where the user is clicking
             * Example: if the user clicks on sort by weight for the first time
             * sortedColumn = weight
             * reverse = true
             *
             * Example 2: if the user clicks on sort by weight for the second time
             * sortedColumn = weight
             * reverse = false
             *
             * @param columnName
             * @private
             */
            function _sortRunnersBy(columnName) {
                if (columnName === $scope.sortedColumn) {
                    $scope.reverse = !$scope.reverse;
                }
                else {
                    $scope.reverse = !(columnName === DEFAULT_SORTABLE_COLUMN);
                }

                var GTMEventLabels = ProgramPageRunnersFac.getGTMEventLabelsBasedOnColumnName(columnName);

                if(GTMEventLabels){
                    var eventData = GTMFac.gaEventBuilder()
                        .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                        .withGaEventCategory('Site Click')
                        .withGaEventLabel(GTMEventLabels.gaEventLabel + ($scope.reverse ? " Descending" : " Ascending"))
                        .withEventLabel(GTMEventLabels.eventLabel)
                        .build();

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
                }

                ProgramPageRunnersFac.sortColumn(columnName, $scope.reverse);
                $scope.sortedColumn = columnName;
            }

            $scope.handicapingOptions = [];

            $scope.handicapingOptions.push({
                label: "Summary",
                columns: [
                    {name: "Med", property: "handicappingInfo.jockey_trainer_info.Med", qaLabel: "handicap-value__med", cssClass: "text-center handicap-value__med-weight"},
                    {name: "Trainer", isNotSortable: true, qaLabel: "handicap-value__trainer", tpl: '<p><strong qa-label="trainer-name">{{model.trainerName}}</strong></p>', cssClass: "text-center handicap-value__jockey-trainer"},
                    {name: "Weight", property: "handicappingInfo.jockey_trainer_info.Weight", qaLabel: "handicap-value__weight", cssClass: "text-center handicap-value__med-weight"},
                    {name: "Jockey", isNotSortable: true, qaLabel: "handicap-value__jockey", tpl: '<p><strong qa-label="jockey-name">{{model.jockeyName}}</strong></p>', cssClass: "text-center handicap-value__jockey-trainer"}
                ],
                helpTemplateUrl: 'js/desktop/modules/Races/templates/desktop/handicapping-summary.html'
            });
            $scope.handicapingOptions.push({
                label: "Snapshot",
                columns: [
                    {name: "Power Rating", property: "handicappingInfo.snapshot_freepick_info.Power Rating", qaLabel: "handicap-value__power-rating", cssClass: "text-center"},
                    {name: "Wins/ Starts", property: "handicappingInfo.snapshot_freepick_info.Wins/ Starts", qaLabel: "handicap-value__win-starts", cssClass: "text-center"},
                    {name: "Days off", property: "handicappingInfo.snapshot_freepick_info.Days Off", qaLabel: "handicap-value__days-off", cssClass: "text-center"}
                ],
                helpTemplateUrl: 'js/desktop/modules/Races/templates/desktop/handicapping-snapshot.html'
            });
            $scope.handicapingOptions.push({
                label: "Speed & Class",
                columns: [
                    {name: "Avg Speed", property: "handicappingInfo.speed_class_rating.Avg Speed", qaLabel: "handicap-value__avg-speed", cssClass: "text-center"},
                    {name: "Avg Dist", property: "handicappingInfo.speed_class_rating.Avg Dist", qaLabel: "handicap-value__avg-dist", cssClass: "text-center"},
                    {name: "High Speed", property: "handicappingInfo.speed_class_rating.High Speed", qaLabel: "handicap-value__high-speed", cssClass: "text-center"},
                    {name: "Avg Class", property: "handicappingInfo.speed_class_rating.Avg Class", qaLabel: "handicap-value__avg-class", cssClass: "text-center"},
                    {name: "Last Class", property: "handicappingInfo.speed_class_rating.Last Class", qaLabel: "handicap-value__las-class", cssClass: "text-center"}

                ],
                helpTemplateUrl: 'js/desktop/modules/Races/templates/desktop/handicapping-speed-class.html'
            });
            $scope.handicapingOptions.push({
                label: "Pace",
                columns: [
                    {name: "# of Races", property: "handicappingInfo.pace.# of Races", qaLabel: "handicap-value__no-races", cssClass: "text-center"},
                    {name: "Early", property: "handicappingInfo.pace.Early", qaLabel: "handicap-value__early", cssClass: "text-center"},
                    {name: "Middle", property: "handicappingInfo.pace.Middle", qaLabel: "handicap-value__middle", cssClass: "text-center"},
                    {name: "Finish", property: "handicappingInfo.pace.Finish", qaLabel: "handicap-value__finish", cssClass: "text-center"}
                ],
                helpTemplateUrl: 'js/desktop/modules/Races/templates/desktop/handicapping-pace.html'
            });
            $scope.handicapingOptions.push({
                label: "Jockey/Trainer Stats",
                columns: [
                    {name: "Starts", property: "handicappingInfo.jockey_trainer_stats.Starts", qaLabel: "handicap-value__starts", cssClass: "text-center"},
                    {name: "1st", property: "handicappingInfo.jockey_trainer_stats.1st", qaLabel: "handicap-value__1st", cssClass: "text-center"},
                    {name: "2nd", property: "handicappingInfo.jockey_trainer_stats.2nd", qaLabel: "handicap-value__2nd", cssClass: "text-center"},
                    {name: "3rd", property: "handicappingInfo.jockey_trainer_stats.3rd", qaLabel: "handicap-value__3rd", cssClass: "text-center"},
                    {name: "Jockey-Trainer", isNotSortable: true, qaLabel: "handicap-value__jockey-trainer", tpl: '<p><strong qa-label="jockey-name">{{model.jockeyName}}</strong></p><p><strong qa-label="trainer-name">{{model.trainerName}}</strong></p>', cssClass: "text-left handicap-value__jockey-trainer"}],
                helpTemplateUrl: 'js/desktop/modules/Races/templates/desktop/handicapping-jockey-trainer.html'
            });

            $scope.selectedHandicaping = $scope.handicapingOptions[0];

            $scope.greyhoundsHandicapingOptions = [];
            $scope.greyhoundsHandicapingOptions.push({
                name: "Kennel",
                isNotSortable: true,
                qaLabel: "handicap-value__kennel",
                tpl: '<p class="text-overflow"><strong qa-label="jockey-name">{{model.jockeyName}}</strong></p>',
                cssClass: "greyhounds__handicap-value greyhounds__handicap-value_kennel"
            });
            $scope.greyhoundsHandicapingOptions.push({
                name: "Trainer",
                isNotSortable: true,
                qaLabel: "handicap-value__trainer",
                tpl: '<p class="text-overflow"><strong qa-label="trainer-name">{{model.trainerName}}</strong></p>',
                cssClass: "greyhounds__handicap-value greyhounds__handicap-value__trainer"
            });
            $scope.greyhoundsHandicapingOptions.push({
                name: "Weight",
                property: "handicappingInfo.jockey_trainer_info.Weight",
                qaLabel: "handicap-value__weight",
                cssClass: "text-center greyhounds__handicap-value greyhounds__handicap-value__weight"
            });

            if (!$scope.standalone) {
                $scope.data.race = $scope.race;

                if ($scope.race && $scope.race.results && $scope.race.results.payoffs.length && $scope.race.results.runners.length) {
                    $scope.data.race.results.payoffs = _discardPayoffs($scope.data.race);
                }

            } else {
                $scope.data.loading = true;
                var deferred = RaceInfoFac.pollRaceResultsList().then(null, null, function (data) {
                    if (data.length) {
                        var race = data[0];
                        _getRaceInfo(race);
                    } else {
                        $scope.data.loading = false;
                    }
                });

                $scope.$on("$destroy", function () {
                    $scope.data.loading = false;
                    RaceInfoFac.stopPollingRaceResultsList(deferred);
                });
            }

            function _getRaceInfo(race) {
                RaceInfoFac.getRaceInfo(race).then(function (aRace) {
                    if (aRace && aRace.results && aRace.results.payoffs.length && aRace.results.runners.length) {
                        aRace.results.payoffs = _discardPayoffs(aRace);
                    }
                    $scope.data.loading = false;
                    $scope.data.race = aRace;
                }, function () {
                    $scope.data.loading = false;
                });
            }

            function _discardPayoffs(race) {
                return race.results.payoffs.filter(function (payoff) {
                    return (payoff.wagerTypeAbbr != "WN" && payoff.wagerTypeAbbr != "PL" && payoff.wagerTypeAbbr != "SH");
                });
            }

            function _defaultSort() {
                $scope.reverse = false;
                $scope.sortedColumn = DEFAULT_SORTABLE_COLUMN;
                ProgramPageRunnersFac.sortColumn($scope.sortedColumn, $scope.reverse);
            }

            $scope.unselectAllRunners = function () {
                angular.forEach($scope.selectedRunners, function (value, key) {
                    $scope.selectedRunners[key] = false;
                });
            };

            //by reference
            $scope.programPageRunners = ProgramPageRunnersFac.runners;

            var removeListener = $rootScope.$on("onBetSlipClosed", $scope.unselectAllRunners);

            $scope.$on("$destroy", function () {
                ProgramPageRunnersFac.clear();
                removeListener();
                removeRaceChanged();
            });

            $scope.selectedRunners = {};

            $scope.getSelectedHorsesIdsInArray = function () {
                return _.reduce($scope.selectedRunners, function (result, isSelected, horseID) {
                    if (isSelected) {
                        result.push(horseID);
                    }
                    return result;
                }, []);
            };

            $scope.toggleRunnerSelection = function (runner) {
                $scope.selectedRunners[runner.horseID] = _toggle($scope.selectedRunners[runner.horseID]);
                var selectedHorsesIds = $scope.getSelectedHorsesIdsInArray();
                $rootScope.$emit('runnerSelectionsChanged', $scope.race, 1, 0, selectedHorsesIds);
            };


            $scope.showVideoReplaysForRunner = function (runner) {
                $scope.selectedRunnerForReplays = runner;
                RunnerReplayModalFac.openRunnerReplaysModal($scope);

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Race Info | Horse Replays | ' + runner.horseName + ' | ' + runner.horseID)
                    .withEventLabel('RaceInfo-HorseReplays')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
            };

            $scope.toggleShowAllRaces = function () {
                $scope.$emit('showAllRaces', true);
            };

            $scope.goToRace = function (raceNumber) {
                var race = $scope.data.race;
                var url = RaceUrlFac.calculateRacePath(race.trackAbbr, race.trackName, raceNumber);
                if (url === $location.url()) {
                    $route.reload();
                } else {
                    $location.url(url);
                }
            };

            $scope.$on("wagerprofile", function () {
                RunnerReplayModalFac.checkIfResubmitAndOpenRunnerReplaysModal($scope);
            });

            $scope.$on('Hadicapping::Information', function () {
                $scope.data.handicappingAvailable = true;
            });

            var removeRaceChanged = $rootScope.$on('race:Changed', function () {
                _defaultSort();
                $scope.data.handicappingAvailable = false;
                $scope.selectedHandicaping = $scope.handicapingOptions[0];
            });

            $scope.selectHandicapping = function (option) {
                $scope.selectedHandicaping = option;
                $scope.onHandicapChange();

                var handicapOptionLabel, eventLabel;
                switch (option.label) {
                    case 'Summary':
                        handicapOptionLabel = option.label;
                        eventLabel = 'RaceInfo-Summary';
                        break;
                    case 'Snapshot':
                        handicapOptionLabel = option.label;
                        eventLabel = 'RaceInfo-Snapshot';
                        break;
                    case 'Speed & Class':
                        handicapOptionLabel = 'SpeedClass';
                        eventLabel = 'RaceInfo-SpeedClass';
                        break;
                    case 'Pace':
                        handicapOptionLabel = option.label;
                        eventLabel = 'RaceInfo-Pace';
                        break;
                    case 'Jockey/Trainer Stats':
                        handicapOptionLabel = 'JockeyTrainer';
                        eventLabel = 'RaceInfo-JockeyTrainer';
                        break;
                }

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Race Info | ' + handicapOptionLabel + ' Tab Click')
                    .withEventLabel(eventLabel)
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));

                return $scope.selectedHandicaping;
            };

            $scope.isQuickSummary = function () {
                return $scope.selectedHandicaping === $scope.handicapingOptions[0];
            };

            $scope.isHandicappingNotAvailableForSorting = function () {
                return !$scope.isQuickSummary() && !$scope.data.handicappingAvailable;
            };

            $scope.onHandicapChange = function () {
                if (!$rootScope.user && $scope.selectedHandicaping !== $scope.handicapingOptions[0]) {
                    selectedHandicappingOption = $scope.selectedHandicaping;
                    $scope.selectedHandicaping = $scope.handicapingOptions[0];
                    $rootScope.$emit("callLoginRequiredModal");
                }
            };

            var loginDisattach = $scope.$on("login", function () {
                $scope.selectedHandicaping = selectedHandicappingOption || $scope.handicapingOptions[0];
                loginDisattach();
            });

            $scope.$on('closedLoginModal', function () {
                selectedHandicappingOption = $scope.selectedHandicaping;
            });

            function _toggle(bool) {
                if (_.isUndefined(bool)) {
                    return true;
                }
                return !bool;
            }

            (function init() {
                _defaultSort();
            }());
        }

        RaceResultsCtrl.$inject = [
            '$scope',
            'RaceInfoFac',
            'ProgramPageRunnersFac',
            '$rootScope',
            'RunnerReplayModalFac',
            '$location',
            '$route',
            'GTMFac',
            'RaceUrlFac'
        ];

        return RaceResultsCtrl;
    });

