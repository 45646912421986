'use strict';

define('SignalProviderFac',[
    'PreferencesEntityBuilder',
    'lodash'
], function (PreferencesEntityBuilder, _) {
    function SignalProviderFac($uibModal, $rootScope, $filter, $http, $timeout) {

        function _openModal() {
            var apiBase = '/registration/api/v1.0';
            var state = _.get($rootScope, 'user.homeAddress.StateAbbr');

            if (state) {
                // Due to an unknown reason I can't mock this request
                /* istanbul ignore next */
                $http.get(apiBase + '/signal-providers/state/' + state).then(function (data) {
                    var res = data.data.response;
                    var result;

                    $rootScope.SignalProviderModal = $uibModal.open({
                        backdrop: 'static',
                        keyboard: false,
                        backdropClass: 'tvgmodal-backdrop',
                        windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                        windowClass: 'signal-provider-modal',
                        templateUrl: 'js/common/modules/Utilities/templates/signal-provider-modal.html',
                        controller: function ($scope, $rootScope, PreferencesFac) {
                            $scope.providers = res;
                            $scope.SignalProvider = {
                                signalProviderID: $rootScope.user.signalProviderId,
                                hasSignalProvider: $rootScope.user.signalProviderId !== '00000'
                            };
                            $scope.close = function () {
                                $rootScope.SignalProviderModal.close();
                            };

                            $scope.submit = function (form) {
                                var signalAvailable = form.signalAvailable === false;
                                var signalProvider = signalAvailable ?
                                    '00000' : (form.signalProviderID ? form.signalProviderID
                                        : $rootScope.user.signalProviderId);

                                var preference = PreferencesEntityBuilder()
                                    .withDescription('SIGNAL_PROVIDER')
                                    .withMetadata(signalProvider)
                                    .build();

                                $rootScope.SignalProviderModal.close();
                                PreferencesFac.updatePreferences(preference).then(function () {
                                    result = 'success';
                                }).catch(function () {
                                    result = 'error';
                                }).finally(function () {
                                    var SignalProviderModalResponse = $uibModal.open({
                                        windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                                        backdropClass: 'tvgmodal-backdrop',
                                        windowClass: 'signal-provider-modal response ' + result,
                                        template: angular.fromJson($filter('CMSValue')('signalProviderMessages'))[result]
                                    });

                                    $timeout(function () {
                                        SignalProviderModalResponse.close();
                                    }, 2000);
                                });
                            };

                            $scope.getProviderName = function (providerId) {
                                return (_.find($scope.providers, function (p) {
                                    return p.ProviderID === providerId;
                                }) || {}).ProviderName;
                            };
                        }
                    });
                });
            }
        }

        return {
            openModal: _openModal
        };
    }

    SignalProviderFac.$inject = [
        '$uibModal',
        '$rootScope',
        '$filter',
        '$http',
        '$timeout'
    ];

    return SignalProviderFac;
});

