/* global angular */

'use strict';

define('appDesktop',[
    'TVGCommon',
    'DepositGTM',
    'Mediator',
    'RunBlockMediator',
    'ngMd5',
    'uibootstrap',
    'uibootstraptpls',
    'Configuration',
    'Content',
    'AppCore',
    'RegistrationPage',
    'Registration',
    'PasswordRecovery',
    'Races',
    'RaceInfo',
    'Security',
    'Login',
    'UserProfile',
    'Account',
    'Layout',
    'Utilities',
    'ModalWindow',
    'BetService',
    'BetNow',
    'Betslip',
    'TalentPicks',
    'TrackSelectorMod',
    'Poller',
    'angularCookies',
    'angularVsRepeat',
    'Deposit',
    'Widgets',
    'WidgetsDesktop',
    'CMSPages',
    'Features',
    'Handicapping',
    'ComingSoon',
    'tvgTemplates',
    'Help',
    'MyBets',
    'MyBetsService',
    'RaceReplays',
    'FeaturedTracks',
    'DatePicker',
    'FullScheduleReloaded',
    'Carousel',
    'SiteTabs',
    'FooterLinks',
    'AccountSummary',
    'ProgramPageRaceSelector',
    'EquibaseUtils',
    'PasswordRecoveryGtm',
    'SEO',
    'ExternalComponents',
    'VideoProvider',
    'Graph',
    'FavoriteTracks',
    'Preferences',
    'GTMDesktopMod',
    'MTP',
    'Logging',
    'Video',
    'Unsubscribe',
    'Notifications',
    'DMAInjector'
], function (TVGCommon, DepositGTM) {

        angular.module('TVG', [
            'ngRoute',
            'ngTouch',
            'ngCookies',
            'ipCookie',
            'ab-base64',
            'ngSanitize',
            'ngMd5',
            'ui.bootstrap',
            'ngAnimate',
            'vs-repeat',
            'TVGCommon',
            'TVG.Configuration',
            'TVG.Content',
            'TVG.AppCore',
            'TVG.RegistrationPage',
            'TVG.RegistrationMod',
            'TVG.PasswordRecovery',
            'TVG.Races',
            'TVG.RaceInfo',
            'TVG.Security',
            'TVG.Login',
            'TVG.UserProfile',
            'TVG.Account',
            'TVG.Layout',
            'TVG.Utilities',
            'TVG.ModalWindow',
            'TVG.BetService',
            'TVG.BetNow',
            'TVG.Betslip',
            'TVG.Deposit',
            'TVG.Withdraw',
            'TVG.Transfer',
            'TVG.ManagePaymentMethods',
            'TVG.TalentPicks',
            'TVG.Handicapping',
            'TVG.ComingSoon',
            'TVG.poller',
            'TVG.Widgets',
            'TVG.WidgetsDesktop',
            'TVG.CMSPages',
            'TVG.Features',
            'TVG.TrackSelector',
            'TVG.Templates',
            'TVG.Help',
            'TVG.MyBets',
            'TVG.MyBetsService',
            'TVG.RaceReplays',
            'TVG.FeaturedTracks',
            'TVG.DatePicker',
            'TVG.FullScheduleReloaded',
            'TVG.Carousel',
            'TVG.SiteTabs',
            'TVG.FooterLinks',
            'TVG.AccountSummary',
            'TVG.ProgramPageRaceSelector',
            'TVG.EquibaseUtils',
            'TVG.PasswordRecoveryGtm',
            'TVG.SEO',
            'TVG.ExternalComponents',
            'TVG.VideoProvider',
            'TVG.Graph',
            'TVG.FavoriteTracks',
            'TVG.Preferences',
            'TVG.GTM',
            'TVG.MTP',
            'TVG.Logging',
            'TVG.Video',
            'TVG.RunBlockMediator',
            'TVG.Unsubscribe',
            'TVG.Notification'
        ])
            .config([
                '$routeProvider',
                '$locationProvider',
                '$httpProvider',
                '$compileProvider',
                '$uibTooltipProvider',
                'ConfigurationFacProvider',
                'AppRequestInterceptorProvider',
                function ($routeProvider, $locationProvider, $httpProvider, $compileProvider, $uibTooltipProvider, ConfigurationFac, AppRequestInterceptor) {

                    if(envName === 'prod') {
                        $compileProvider.debugInfoEnabled(false);
                    }

                    $uibTooltipProvider.setTriggers({'triggerTooltip': 'triggerTooltip'});

                    AppRequestInterceptor.setMetadata();
                    AppRequestInterceptor.setFeatures();
                    AppRequestInterceptor.setCmsContent();

                    AppRequestInterceptor.setHeadersRequest();
                    AppRequestInterceptor.setInterceptResponse();

                    $locationProvider.html5Mode(true);

                    $routeProvider
                            .when('/', {
                                templateUrl: 'js/desktop/views/home.html',
                                reloadOnSearch: false,
                                title: 'Home Screen'
                            })
                            .when('/races', {
                                templateUrl: 'js/desktop/views/races.html',
                                title: 'Races'
                            })
                            .when('/tracks', {
                                redirectTo: function(){
                                    return '/races?view=bytrack';
                                }
                            })
                            .when('/registration', {
                                templateUrl: 'js/desktop/views/registration.html',
                                title: 'Registration',
                                controller: 'RegistrationPageCtrl',
                                reloadOnSearch: false,
                                customerSupportHeader: true,
                                hasTopBarNavigation: false
                            })
                            .when('/signup', {
                                templateUrl: 'js/desktop/views/default.html',
                                hideFooter: true
                            })
                            .when('/blocked-country', {
                                templateUrl: 'js/common/modules/Security/templates/blocked-country.html',
                                title: 'blocked-country',
                                hideBottomMenu: true,
                                hideNextRaces: true,
                                fullscreenPage: true,
                                resolve: {
                                    hideSideMenu: function($rootScope) {
                                        $rootScope.showLeftBar = false;
                                    }
                                }
                            })
                            .when('/results', {
                                templateUrl: 'js/desktop/views/results.html',
                                title: 'Results'
                            })
                            .when('/page/:cmsPageName', {
                                templateUrl: 'js/desktop/views/cmspage.html',
                                title: 'Home',
                                controller: 'CMSPagesCtrl',
                                namespace: 'LandingPages'
                            })
                            .when('/info/:cmsPageName', {
                                templateUrl: 'js/desktop/views/cmspage.html',
                                title: 'Home',
                                controller: 'CMSPagesCtrl',
                                namespace: 'InformationalPages',
                                class: 'wrap institutional',
                                reloadOnSearch: false
                            })
                            // ATTENTION: If the following two routes change we might have to update the
                            // function "raceUrlFac.getRouteParamsForRaceBasedOnLocationPath".
                            .when('/racetracks/:trackAbbr/:trackName', {
                                templateUrl:'js/desktop/views/raceprogram.html',
                                title: 'Races',
                                reloadOnSearch: false,
                                resolve: function() {
                                        var headId = document.getElementsByTagName('head')[0];
                                        var link = document.createElement('link');
                                        headId.appendChild(link);
                                        link.rel = 'canonical';
                                        var urlWithNoQueryParameter = window.location.href.replace(window.location.search, '');
                                        link.href = urlWithNoQueryParameter;
                                    }
                            })
                            .when('/racetracks/:trackAbbr/:trackName/autoplay/:autoplay', {
                                templateUrl: 'js/desktop/views/raceprogram.html',
                                title: 'Races',
                                reloadOnSearch: false,
                                resolve: function() {
                                        var headId = document.getElementsByTagName('head')[0];
                                        var link = document.createElement('link');
                                        headId.appendChild(link);
                                        link.rel = 'canonical';
                                        var removeAutoplay = window.location.href.split('/autoplay');
                                        link.href = removeAutoplay[0];
                                    }
                            })
                            .when('/greyhounds/:trackAbbr/:trackName', {
                                templateUrl: 'js/desktop/views/raceprogram.html',
                                title: 'Races',
                                reloadOnSearch: false,
                                resolve: function() {
                                        var headId = document.getElementsByTagName('head')[0];
                                        var link = document.createElement('link');
                                        headId.appendChild(link);
                                        link.rel = 'canonical';
                                        var urlWithNoQueryParameter = window.location.href.replace(window.location.search, '');
                                        link.href = urlWithNoQueryParameter;
                                    }
                            })
                            .when('/greyhounds/:trackAbbr/:trackName/autoplay/:autoplay', {
                                templateUrl: 'js/desktop/views/raceprogram.html',
                                title: 'Races',
                                reloadOnSearch: false,
                                resolve:  function() {
                                        var headId = document.getElementsByTagName('head')[0];
                                        var link = document.createElement('link');
                                        headId.appendChild(link);
                                        link.rel = 'canonical';
                                        var removeAutoplay = window.location.href.split('/autoplay');
                                        link.href = removeAutoplay[0];
                                    }
                            })
                            .when('/racetracks/:trackAbbr/:trackName/:raceNumber', {
                                templateUrl: 'js/desktop/views/raceprogram.html',
                                title: 'Races',
                                reloadOnSearch: false,
                                redirectTo: function(routeParams, path, search){
                                    /* the redirectTo's are responsible to redirect a route with
                                    race as a query parameter in case someone puts a path */
                                    var raceNumber = routeParams.raceNumber.split('-');
                                    return '/racetracks/' + routeParams.trackAbbr + '/'
                                    + routeParams.trackName + (search.race != undefined ? '?race=' + search.race : '?race=' + raceNumber[1]);
                                }
                            })
                            .when('/racetracks/:trackAbbr/:trackName/:raceNumber/autoplay/:autoplay', {
                                templateUrl: 'js/desktop/views/raceprogram.html',
                                title: 'Races',
                                reloadOnSearch: false,
                                redirectTo: function(routeParams, path, search){
                                    var raceNumber = routeParams.raceNumber.split('-');
                                    return '/racetracks/' + routeParams.trackAbbr + '/' + routeParams.trackName +
                                    '/autoplay/' + routeParams.autoplay + (search.race != undefined ? '?race=' + search.race : '?race=' + raceNumber[1]);
                                }
                            })
                            .when('/greyhounds/:trackAbbr/:trackName/:raceNumber', {
                                templateUrl: 'js/desktop/views/raceprogram.html',
                                title: 'Races',
                                reloadOnSearch: false,
                                redirectTo: function(routeParams, path, search){
                                    var raceNumber = routeParams.raceNumber.split('-');
                                    return '/greyhounds/' + routeParams.trackAbbr + '/'
                                    + routeParams.trackName + (search.race != undefined ? '?race=' + search.race : '?race=' + raceNumber[1]);
                                }
                            })
                            .when('/greyhounds/:trackAbbr/:trackName/:raceNumber/autoplay/:autoplay', {
                                templateUrl: 'js/desktop/views/raceprogram.html',
                                title: 'Races',
                                reloadOnSearch: false,
                                redirectTo: function(routeParams, path, search){
                                    var raceNumber = routeParams.raceNumber.split('-');
                                    return '/greyhounds/' + routeParams.trackAbbr + '/' + routeParams.trackName +
                                    '/autoplay/' + routeParams.autoplay + (search.race != undefined ? '?race=' + search.race : '?race=' + raceNumber[1]);
                                }
                            })
                            .when('/videos', {
                                templateUrl: 'js/desktop/views/videos.html',
                                title: 'Video'
                            })
                            .when('/open-login', {
                                templateUrl: 'js/desktop/views/home.html',
                                title: 'Login',
                                controller: 'QuickAccessCtrl'
                            })
                            .when('/bets', {
                                template: '&nbsp',
                                title: 'Bets'
                            })
                            .when('/deposit', {
                                templateUrl: 'js/desktop/views/home.html',
                                title: 'Deposits'
                            })
                            .when('/my-profile', {
                                templateUrl: 'js/desktop/views/profile/my-profile.html',
                                title: 'My Profile',
                                needsLogIn: true
                            })
                            .when('/my-bets', {
                                templateUrl: 'js/desktop/views/profile/my-bets.html',
                                title: 'My Bets',
                                needsLogIn: true
                            })
                            .when('/my-account-summary', {
                                templateUrl: 'js/desktop/views/profile/my-account-summary.html',
                                title: 'My Account Summary',
                                needsLogIn: true
                            })
                            .when('/w2-g-reports', {
                                templateUrl: 'js/desktop/views/profile/reports.html',
                                title: 'W2-G Reports',
                                needsLogIn: true,
                                controller: 'ReportsCtrl'
                            })
                            .when('/year-end-reports', {
                                templateUrl: 'js/desktop/views/profile/reports.html',
                                title: 'Year End Reports',
                                needsLogIn: true,
                                controller: 'ReportsCtrl'
                            })
                            .when('/my-funds/:myFundsTab?', {
                                templateUrl: 'js/desktop/views/profile/my-funds.html',
                                title: 'My Funds',
                                controller: 'MyFundsCtrl',
                                // @warning if you want to change the following
                                // value to `true` by some reason, please check
                                // paypal first, since we are required to change search
                                // paramenters in the URL and we can't reload the
                                // page
                                reloadOnSearch: false,
                                needsLogIn: true
                            })
                            .when('/my-favorite-tracks', {
                                templateUrl: 'js/desktop/views/profile/my-favorite-tracks.html',
                                title: 'My Favorite Tracks',
                                needsLogIn: true
                            })
                            .when('/my-stable', {
                                templateUrl: 'js/desktop/views/profile/my-stable.html',
                                title: 'My Stable',
                                needsLogIn: true
                            })
                            .when('/betslip/:trackAbbr/:perfAbbr/:raceNumber', {
                                templateUrl: 'js/desktop/views/betslip-window.html',
                                title: 'Bet Ticket',
                                hasTopBarNavigation: true
                            })
                            .when('/handicapping', {
                                templateUrl: 'js/desktop/views/handicapping/handicapping.html',
                                title: 'Handicapping',
                                controller: 'HandicappingCtrl',
                                hasTopBarNavigation: true,
                                reloadOnSearch: false
                            })
                            .when('/handicapping/handicapping-store', {
                                templateUrl: 'js/desktop/views/handicapping/handicappingByUrl.html',
                                title: 'Handicapping',
                                controller: 'HandicappingByUrlCtrl',
                                hasTopBarNavigation: false,
                                reloadOnSearch: false,
                                needsLogIn: true
                            })
                            .when('/handicapping/:tabSelected', {
                                templateUrl: 'js/desktop/views/handicapping/handicappingByUrl.html',
                                title: 'Handicapping',
                                controller: 'HandicappingByUrlCtrl',
                                hasTopBarNavigation: false,
                                reloadOnSearch: false
                            })
                            .when('/wager-rewards', {
                                templateUrl: 'js/desktop/views/promotions/wager-rewards-page.html',
                                title: 'Promotions',
                                needsLogIn: false,
                                hasTopBarNavigation: false,
                                reloadOnSearch: false,
                                resolve: {
                                    check: function ($location) {
                                        if (locationContext && locationContext === 'pa') {
                                            $location.path('/');
                                        }
                                    }
                                }
                            })
                            .when('/promos', {
                                templateUrl: 'js/desktop/views/promotions/promotions-page.html',
                                title: 'Promotions',
                                needsLogIn: false,
                                hasTopBarNavigation: false,
                                reloadOnSearch: false,
                                toggleKey:'enablePromosStoryblok'
                            })
                        .when('/promos/:slug', {
                            templateUrl: 'js/desktop/views/promotions/promotions-page.html',
                            title: 'Promotions',
                            needsLogIn: false,
                            hasTopBarNavigation: false,
                            reloadOnSearch: false,
                            toggleKey:'enablePromosStoryblok'
                        })
                        .when('/promos/:slug/:id', {
                            templateUrl: 'js/desktop/views/promotions/promotions-page.html',
                            title: 'Promotions',
                            needsLogIn: false,
                            hasTopBarNavigation: false,
                            reloadOnSearch: false,
                            toggleKey:'enablePromosStoryblok'
                        })
                        .when('/promos/:vip/:slug/:id', {
                            templateUrl: 'js/desktop/views/promotions/promotions-page.html',
                            title: 'Promotions',
                            needsLogIn: false,
                            hasTopBarNavigation: false,
                            reloadOnSearch: false,
                            toggleKey:'enablePromosStoryblok'
                        })
                            .when('/password-recovery', {
                                templateUrl: 'js/desktop/views/recover-password/password-recovery-preflow.html',
                                title: 'Password recovery',
                                hasTopBarNavigation: false,
                                reloadOnSearch: false,
                                customerSupportHeader: true,
                                toggleKey: 'passwordRecoveryPreflow'
                            })
                            .when('/recover-email', {
                                templateUrl: 'js/desktop/views/home.html',
                                title: 'Recover email',
                                toggleKey: 'loginPRFv2'
                            })
                            .when('/reset-credentials', {
                                templateUrl: 'js/desktop/views/recover-password/password-recovery-reset-credentials.html',
                                title: 'Password recovery',
                                hasTopBarNavigation: false,
                                reloadOnSearch: false,
                                customerSupportHeader: true,
                                isPRFMicroApp: true,
                                toggleKey: 'passwordRecoveryMicroApp'
                            })
                            .when('/credentials-reset', {
                                templateUrl: 'js/desktop/views/default.html',
                                title: 'Credentials Reset',
                                needsLogIn: false,
                                hideNextRaces: true,
                                fullscreenPage: true,
                                fullContentPage: true,
                                hideHeader: true,
                                hideFooter: true,
                                toggleKey:'resetCredentialsV2'
                            })
                            .when('/coming-soon', {
                                templateUrl: 'js/desktop/views/coming-soon/coming-soon.html',
                                title: 'Coming soon',
                                controller: 'ComingSoonCtrl',
                                hasTopBarNavigation: false,
                                reloadOnSearch: false
                            })
                            .when('/tutorial-videos', {
                                templateUrl: 'js/desktop/views/help/tutorial-videos.html',
                                title: 'Tutorial Videos soon',
                                controller: 'TutorialVideosCtrl',
                                hasTopBarNavigation: false,
                                reloadOnSearch: false
                            })
                            .when('/resource-center', {
                                templateUrl: 'js/desktop/modules/Help/templates/forms-and-guides.html',
                                title: 'Forms and Guides',
                                controller: 'ResourceCenterCtrl',
                                hasTopBarNavigation: false,
                                reloadOnSearch: false
                            })
                            .when('/live', {
                                templateUrl: 'js/desktop/views/cmspage.html',
                                title: 'Watch TVG',
                                controller: 'CMSPagesCtrl',
                                namespace: 'LandingPages',
                                cmsPageName: 'TVGVideoStream'
                            })
                            .when('/racetracks', {
                                templateUrl: 'js/desktop/views/trackDetails/index.html',
                                title: 'Track Details'
                            })

                            //this will redirect to the racetrack hub page
                            //at /racetracks/trabAbbr/trackname
                            .when('/horse-race-track-list/:trackName', {
                                templateUrl: 'js/desktop/views/loader.html',
                                controller: 'trackDetailsCtrl'
                            })

                            /*
                             * The following route will not work on local dev env due to json proxy;
                             * use /dev-login for testing purposes;
                             */
                            .when('/login', {
                                templateUrl: 'js/desktop/views/home.html',
                                title: 'Login',
                            })
                            .when('/dev-login', {
                                templateUrl: 'js/desktop/views/home.html',
                                title: 'Login'
                            })
                            .when('/email-opt-out', {
                                templateUrl: 'js/desktop/views/unsubscribe.html',
                                controller: 'UnsubscribeCtrl'
                            })
                            .when('/responsible-gaming', {
                                templateUrl: 'js/desktop/views/default.html',
                                title: 'Responsible Gaming',
                                needsLogIn: false,
                                fullscreenPage: true,
                                fullContentPage: true
                            })
                            .when('/referral', {
                                templateUrl: 'js/desktop/views/promotions/refer-a-friend-page.html',
                                title: 'Refer a Friend',
                                needsLogIn: false,
                                hasTopBarNavigation: false,
                                reloadOnSearch: false,
                                toggleKey:'enableReferFriendMicroApp'
                            })
                            .when('/preferences', {
                                templateUrl: 'js/desktop/views/default.html',
                                title: 'Preferences Center',
                                needsLogIn: true,
                                hasTopBarNavigation: false,
                                reloadOnSearch: false,
                                toggleKey:'usePrefReact'
                            })
                            .when('/wallet', {
                                templateUrl: 'js/desktop/views/default.html',
                                title: 'Wallet Manager',
                                needsLogIn: true,
                                fullscreenPage: true,
                                fullContentPage: true
                            })
                        .when('/mazoomaStatus', {
                            templateUrl: 'js/desktop/views/default.html',
                            title: 'TEST Mazooma receipt',
                            needsLogIn: false,
                            fullscreenPage: false,
                            fullContentPage: false
                        })
                            .when('/unsubscribe', {
                                templateUrl: 'js/desktop/views/default.html',
                                title: "Email Unsubscribe",
                                needsLogIn: false,
                                hideNextRaces: true,
                                fullscreenPage: true,
                                fullContentPage: true,
                                hideHeader: true,
                                hideFooter: true,
                                toggleKey:'useEmailUnsubscribe'
                            })
                            .otherwise({
                                redirectTo: '/'
                            });
                }
            ])
            .run([
                'AppRootFac',
                'SeoLifeCycleManagerSvc',
                '$rootScope',
                'GraphClient',
                'GraphFCPClient',
                'GraphBehgClient',
                'ConfigurationFac',
                function (
                        AppRootFac,
                        SeoLifeCycleManagerSvc,
                        $rootScope,
                        GraphClient,
                        GraphFCPClient,
                        GraphBehgClient,
                        ConfigurationFac
                    ) {
                    var graphUrl = ConfigurationFac.getServiceApiUrl('graph');
                    var graphFCPUrl =  ConfigurationFac.getServiceApiUrl('fcp');
                    var graphBehgUrl = ConfigurationFac.getServiceApiUrl('behg');

                    if (envName === 'qa' && ($rootScope.activeFeatures && $rootScope.activeFeatures.qaConsumingStagingGraph)) {
                        // when on QA environment, set the graph url to the staging graph so that we have more production-like feeds
                        // this is under toggle so every time anyone wants to consume qa graph we just need to disable the toggle manually
                        graphUrl = ConfigurationFac.getStartupConfig('staging', 'baseUrl', 'service_api') +
                            ConfigurationFac.getStartupConfig('staging', 'service', 'graph');
                    }

                    GraphClient.configureGraphClient(graphUrl);
                    GraphFCPClient.configureGraphClient(graphFCPUrl);
                    GraphBehgClient.configureGraphClient(graphBehgUrl);

                    DepositGTM.default($rootScope);

                    AppRootFac.initTVG4();

                    AppRootFac.addBounceExchange();
                    AppRootFac.addTooltipIO();

                    /* ----- RootScope Functions ----- */
                    AppRootFac.onUpdateBalance();
                    AppRootFac.fnGo();
                    AppRootFac.fnGetLocationPath();
                    AppRootFac.fnPreviousButtonPressed();
                    AppRootFac.fnRaceButtonPressed();
                    AppRootFac.fnNextButtonPressed();
                    AppRootFac.fnRefreshListPendingWithdraw();
                    AppRootFac.fnGetLocationPath();
                    AppRootFac.fnReloadApp();

                    /* ----- RootScope Listeneners ----- */
                    AppRootFac.onUser();
                    AppRootFac.onLocationChangeStart();
                    AppRootFac.onRouteChangeStart();
                    AppRootFac.onRouteChangeSuccess();
                    AppRootFac.onLogin();
                    AppRootFac.onLogout();
                    AppRootFac.onSessionExpired();
                    AppRootFac.onAppReady();
                    AppRootFac.onPageLoaded();
                    AppRootFac.onDepositOpen();
                    AppRootFac.addExternalLogin();
                    AppRootFac.onScrollToTop();
                    AppRootFac.onAppLoaded();

                    if ($rootScope.activeFeatures && $rootScope.activeFeatures.seoPageMetaInfo) {
                        SeoLifeCycleManagerSvc.start();
                    }
                }]
            );
    }
);

