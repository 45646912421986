'use strict';

define('TalentPicksCtrl',['RaceStatusUtils'],
    function(RaceStatusUtils) {
        function TalentPicksCtrl($scope, TalentPicksFactory, $q, $location, TalentPicksEventsFactory, $rootScope) {
            var unbind = [];

            function _pollTalentPicksNotifyFn(talentPicksData) {
                var getRaceInfoPromises = [];
                if ($scope.$root.activeFeatures.talentPicksViewRaceAndResultsButtons) {
                    talentPicksData.filteredTalentPicks.forEach(function(currentFilteredTalentPick) {
                        // Multi-race picks
                        if (currentFilteredTalentPick.stepName === 'Leg' && RaceStatusUtils.isClosedForBetting(currentFilteredTalentPick.raceStatus)) {
                            currentFilteredTalentPick.isRaceOff = true;

                            if (RaceStatusUtils.hasRaceResults(currentFilteredTalentPick.raceStatus)) {
                                currentFilteredTalentPick.isRaceOver = true;
                            }

                            /*getRaceInfoPromises.push(
                                TalentPicksEventsFactory.getPromiseRaceInfoOfLastRaceOfMultiRacePick(currentFilteredTalentPick)
                                    .then(function(race) {
                                        if (RaceStatusUtils.hasRaceResults(race.status)) {
                                            currentFilteredTalentPick.isRaceOver = true;
                                        }
                                    })
                            );*/
                        } else {
                            TalentPicksEventsFactory.setRaceStatusPropertiesOfTalentPick(currentFilteredTalentPick, currentFilteredTalentPick.raceStatus);
                        }
                    });
                }

                // Let's wait for all the race infos before showing all the available talent picks.
                $q.all(getRaceInfoPromises).then(function() {
                    $scope.data = angular.extend(TalentPicksFactory.talentPicksData, $scope.data);
                    $scope.data.loading = false;
                    if($location.path() == '/') {
                        $scope.$emit('appReady', 'tvgPicks');
                    }
                    else if($location.path() == '/handicapping') {
                        $scope.$emit('pageLoaded', 'handicapping#talentpicks');
                    }
                });
            }

            function _destroyListener() {
                TalentPicksFactory.stopPollingTalentPicks();
                $scope.data.loading = false;

                _.forEach(unbind, function (bind) {
                    bind();
                });
            }

            function _pollTalentPicks() {
                TalentPicksFactory.pollTalentPicks(
                    !$scope.$root.activeFeatures.talentPicksViewRaceAndResultsButtons || $scope.data.inHomePage ?
                        'homepage' : null).then(null, null, _pollTalentPicksNotifyFn);
            }

            (function init() {

                $scope.data = {
                    loading: true,
                    inHomePage: $location.path() === '/' || $location.path().indexOf('/live') > -1
                };

                $scope.events = {
                    filterTalentPicks: TalentPicksFactory.filterTalentPicks,
                    openBetTicket: TalentPicksEventsFactory.openBetTicket($scope),
                    talentPickRaceUrl: TalentPicksEventsFactory.talentPickRaceUrl,
                    openTalentPickRace: TalentPicksEventsFactory.openTalentPickRace,
                    goToTalentPicks: TalentPicksEventsFactory.goToTalentPicks,
                    goToProgramPage: TalentPicksEventsFactory.goToProgramPage,
                    goToRaceResults: TalentPicksEventsFactory.goToRaceResults,
                    showMTP: TalentPicksEventsFactory.showMTP
                };

                unbind.push($rootScope.$on('wagerprofile', TalentPicksEventsFactory.onWagerProfileListener($scope)));

                $scope.$on('$destroy', _destroyListener);

                // Refetch data on login
                $scope.$on('login', function () {
                    _destroyListener();
                    _pollTalentPicks();
                });

                $scope.$on('logout', function () {
                    _destroyListener();
                    _pollTalentPicks();
                });

                _pollTalentPicks();
            }());
        }

        TalentPicksCtrl.$inject = [
            '$scope',
            'TalentPicksFactory',
            '$q',
            '$location',
            'TalentPicksEventsFactory',
            '$rootScope'
        ];

        return TalentPicksCtrl;
    });

