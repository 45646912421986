'use strict';

define('TopBarNavigationDir',[],function () {

    function TopBarNavigationDir($location, $rootScope, ContentFac) {

        return {
            restrict: 'E',
            scope: {
                tabs: "=",
                activeTab: "=",
                activeSubTab: "="
            },
            replace: false,
            templateUrl: 'js/desktop/modules/WidgetsDesktop/templates/top-bar-navigation-template.html',
            link: function (scope) {
                var myPath = $location.path();
                scope.getFreeHandicappingFromCapi = $rootScope.activeFeatures.getFreeHandicappingFromCapi;

                scope.$on("$locationChangeSuccess", function () {
                    if ($location.hash() !== "" && myPath === $location.path()) {
                        var tabs = _getTabsByHash($location.hash());
                        _goToTabs(tabs.tab, tabs.subTab);
                    }
                });

                scope.goTo = function (tab, subTab) {
                    var tabs = _getTabsByRef(tab, subTab);

                    if (_isUserLoggedOut() && tab.askForLogin) {
                        $rootScope.$emit("callLoginRequiredModal", _goToTabsCallBack(tabs.tab, tabs.subTab));
                    } else {
                        _goToTabs(tabs.tab, tabs.subTab);
                    }
                };

                function _goToTabsCallBack(tab, subTab) {
                    return function () {
                        if (tab) {
                            var hash = tab.id.toLowerCase();

                            if (subTab) {
                                hash += '+' + subTab.id.toLowerCase();
                            }

                            $location.hash(hash);
                            _activateTabs(tab, subTab);
                        }
                    };
                }

                scope.goToById = function (tabId, subTabId) {
                    var tabs = _getTabsById(tabId, subTabId);
                    _goToTabs(tabs.tab, tabs.subTab);
                };

                function _getTabsByRef(tab, subTab) {
                    var tabId = tab.id;

                    if (subTab) {
                        var subTabId = subTab.id;
                    }

                    return _getTabsById(tabId, subTabId);
                }


                function _isUserLoggedOut(){
                    return !$rootScope.userSession;
                }

                function _getTabsById(tabId, subTabId) {
                    var tab, subTab;

                    if (scope.tabs) {
                        if (tabId) {
                            tab = scope.tabs.filter(function (elem) {
                                return elem.id.toUpperCase() === tabId.toUpperCase();
                            })[0];

                            if (!tab) {
                                tab = scope.tabs[0];
                            }
                        } else {
                            tab = scope.tabs[0];
                        }

                        if (tab.subTabs) {
                            if (subTabId) {
                                subTab = tab.subTabs.filter(function (elem) {
                                    return elem.id.toUpperCase() === subTabId.toUpperCase();
                                })[0];
                            }

                            if (!subTab) {
                                subTab = tab.subTabs[0];
                            }
                        }
                    }

                    return {tab: tab, subTab: subTab};
                }

                function _getTabsByHash(hash) {
                    var hashElements = hash.split('+');
                    return _getTabsById(hashElements[0], hashElements[1]);
                }

                function _goToTabs(tab, subTab) {
                    if(!scope.getFreeHandicappingFromCapi && tab && tab.id == 'free-pp') {
                        ContentFac.getNamespaceContent('freepastperformances').then(function(content) {
                            var _cmsContent = ContentFac.getCMSContent();
                            angular.extend(_cmsContent, content);
                            ContentFac.setCMSContent(_cmsContent);
                        }).finally(function() {
                            _goToTabsCallBack(tab, subTab)();
                        });
                    } else {
                        _goToTabsCallBack(tab, subTab)();
                    }
                }

                function _activateTabs(tab, subTab) {
                    resetSubTabs(tab);

                    scope.activeTab = tab;
                    scope.activeSubTab = undefined;

                    if (subTab) {
                        scope.activeSubTab = subTab;
                        subTab.active = true;
                    }
                }

                function resetSubTabs(tab) {
                    _.forEach(tab.subTabs, function (elem) {
                        elem.active = false;
                    });
                }

                (function init() {
                    var tabs = _getTabsByHash($location.hash());
                    _goToTabs(tabs.tab, tabs.subTab);
                }());
            }
        };
    }

    TopBarNavigationDir.$inject = [
        '$location',
        '$rootScope',
        'ContentFac'
    ];

    return TopBarNavigationDir;

});


