"use strict";

define('RaceInfoEntity',[],function () {

    /***
     *
     * @param trackAbbr
     * @param perfAbbr
     * @param raceNumber
     * @param raceDate
     * @param surfaceTypeId
     * @param raceTypeId
     * @param surfaceType
     * @param surfaceName
     * @param raceClassId
     * @param raceClass
     * @param trackName
     * @param distance
     * @param purse
     * @param description
     * @constructor
     */
    function RaceInfo(trackAbbr, perfAbbr, raceNumber, raceDate, surfaceTypeId, raceTypeId,
                      surfaceType, surfaceName, raceClassId, raceClass, trackName, distance, id,
                      purse, description){

        this.trackAbbr = trackAbbr;
        this.perfAbbr = perfAbbr;
        this.raceNumber = raceNumber;
        this.raceDate = raceDate;
        this.surfaceTypeId = surfaceTypeId;
        this.raceTypeId = raceTypeId;
        this.surfaceType = surfaceType;
        this.surfaceName = surfaceName;
        this.raceClassId = raceClassId;
        this.raceClass = raceClass;
        this.trackName = trackName;
        this.distance = distance;
        this.id = id;
        this.purse = purse;
        this.description = description;
    }

    return RaceInfo;

});


