'use strict';

define(
    'TutorialVideosCtrl',[],

    function () {
        function TutorialVideosCtrl($scope, $rootScope, $window, NewWindowGridFac, TutorialVideosSvc) {

            var windowParams = [];
            var windowConfig = {
                top: 0,
                left: 0,
                width: 800,
                height: 452
            };

            TutorialVideosSvc.getTutorialVideosCmsData().then(function(slides) {
                $scope.slides = slides;
            });

            $scope._getImageVideoUrl = TutorialVideosSvc.getImageVideoUrl;

            $scope._openVideoWindow = function (videoUrl, event) {
                if (event) {
                    event.preventDefault();
                }
                var coordinates = NewWindowGridFac.getTopCoordinates($window);
                windowConfig.left = coordinates.left + (($window.screen.width - windowConfig.width) / 2);
                windowConfig.top = coordinates.top + (($window.screen.height - windowConfig.height) / 2);

                angular.forEach(windowConfig, function (value, key) {
                    windowParams.push(key + '=' + value);
                });

                var videoEmbdedURL = TutorialVideosSvc.getEmbeddedVideoUrl(videoUrl);
                $window.open(videoEmbdedURL, '_blank', windowParams.join(','));
            };
        }

        TutorialVideosCtrl.$inject = [
          '$scope',
          '$rootScope',
          '$window',
          'NewWindowGridFac',
          'TutorialVideosSvc'
        ];

        return TutorialVideosCtrl;
    }
);

