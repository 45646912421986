'use strict';

define(
    'ProgramPageRaceSelector',[
        'ProgramPageRaceSelectorCtrl',
        'ProgramPageRaceSelectorSwipeableCtrl',
        'GTMMod'
    ],

    function (ProgramPageRaceSelectorCtrl, ProgramPageRaceSelectorSwipeableCtrl) {

        angular.module('TVG.ProgramPageRaceSelector', [
            'TVG.Common.GTM'
        ])
            .controller('ProgramPageRaceSelectorCtrl', ProgramPageRaceSelectorCtrl)
            .controller('ProgramPageRaceSelectorSwipeableCtrl', ProgramPageRaceSelectorSwipeableCtrl)

            //Program Page Race Selector Directive
            .directive('programPageRaceSelectorSwipeable', function () {
                return {
                    restrict: 'E',
                    scope: true,
                    controller: 'ProgramPageRaceSelectorSwipeableCtrl',
                    templateUrl: 'js/desktop/modules/ProgramPageRaceSelector/templates/program-page-race-selector.html'
                };
            })

            .directive('programPageHeaderSwipeable', function () {
                return {
                    restrict: 'E',
                    scope: true,
                    controller: 'ProgramPageRaceSelectorSwipeableCtrl',
                    templateUrl: 'js/desktop/modules/ProgramPageRaceSelector/templates/program-page-header-swipeable.html'
                };
            });
    }
);

