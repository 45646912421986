'use strict';

define('VideosLoggingFac',[],function() {

        /**
         * Log videos playback using GTM and transation log
         */
        function VideosLoggingFac(
                $rootScope,
                GTMFac
            ) {

            var PROVIDER = {
                NEULION: 'Neulion',
                RCN: 'RCN'
            };

            var PLAYBACK_STATE = {
                LOAD: 'LOAD',
                SUCCESS: 'SUCCESS',
                ERROR: 'ERROR'
            };


            /**
             * Get the video provider identifier (from constants)
             * @return {String} Video provider identifier
             */
            function _getVideoProvider() {
                return ($rootScope.activeFeatures.videoProviderRCN) ?
                    PROVIDER.RCN : PROVIDER.NEULION;
            }

            /**
             * Get video type and label, according if it's a replay or not
             * @param  {Boolean} isReplay  Whether it's a race replay
             * @return {Object}            Video label and type
             */
            function _getGTMVideoType(isReplay) {
                var type = 'Live Stream';
                var label = 'Video Live';

                if (isReplay) {
                    type = 'VOD';
                    label = 'Video On Demand';
                }

                return {
                    type: type,
                    label: label
                };
            }

            /**
             * Get video type from isReplay property
             * @param  {String}  status           Event status (one of constants)
             * @param  {Object}  race             Race data
             * @param  {String}  race.trackId     Track ID (abbreviation)
             * @param  {String}  race.trackName   Track name
             * @param  {String}  race.racePerf    Race perf (time of day???)
             * @param  {String}  race.raceNumber  Race number
             * @param  {String}  race.date        Race date
             * @param  {String}  race.time        Race time
             * @param  {Boolean} race.isReplay    Wether it's replay?
             * @return {Object}                   GTM event labels
             */
            function _getGTMEventLabels(status, race) {
                var videoType = _getGTMVideoType(race.isReplay);
                var gaEventLabel = videoType.label + ' | ' + race.trackId;
                var events = {};


                // if not live streaming TVG1 or TVG2
                if (!_isTvgLiveChannel(race.trackId)) {
                    gaEventLabel = videoType.label + ' | ' + race.trackName + ' | R' + race.raceNumber;
                }

                events[PLAYBACK_STATE.LOAD] = {
                    event: 'video',
                    eventLabel: 'videoLoad',
                    gaEventAction: 'Video Play | Load',
                    gaEventCategory: 'Video',
                    gaEventLabel: gaEventLabel
                };

                events[PLAYBACK_STATE.SUCCESS] = {
                    event: 'video',
                    eventLabel: 'videoSuccess',
                    gaEventAction: 'Video Play | Success',
                    gaEventCategory: 'Video',
                    gaEventLabel: gaEventLabel
                };

                events[PLAYBACK_STATE.ERROR] = {
                    event: 'video',
                    eventLabel: 'videoError',
                    gaEventAction: 'Video Play | Error',
                    gaEventCategory: 'Video',
                    gaEventLabel: gaEventLabel
                };

                return events[status];
            }

            /**
             * Get provider's name for GTM/Transation log events
             * @param  {String} provider  Provider value (from constants)
             * @return {String}           Provider's name
             */
            function _getProviderName(provider) {
                var providers = {};

                providers[PROVIDER.RCN] = 'RCN';
                providers[PROVIDER.NEULION] = 'Neulion';

                return providers[provider];
            }

            /**
             * Check if the track ID is from a live channel stream
             * (as in www.tvg.com/live -> set from CMS).
             *
             * @param  {String}  trackId  Track ID/abbreviation
             * @return {Boolean}          Whether it's a TVG channel
             */
            function _isTvgLiveChannel(trackId) {
                return !!(trackId === 'TVG1' || trackId === 'TVG2');
            }

            /**
             * Log the video playback start via GTM
             *
             * @param {Object}  opts                   Event opts
             * @param {String}  opts.provider          Video provider
             * @param {Object}  opts.race              Race data
             * @param {String}  opts.race.trackId      Track ID (abbreviation)
             * @param {String}  opts.race.trackName    Track name
             * @param {String}  opts.race.racePerf     Race perf (time of day???)
             * @param {String}  opts.race.raceNumber   Race number
             * @param {String}  opts.race.date         Race date
             * @param {String}  opts.race.time         Race time
             * @param {Boolean} opts.race.isReplay     Wether it's replay?
             * @param {String}  [opts.error]           Error name
             * @param {String}  [opts.errorMessage]    Error message
             */
            function _logPlaybackGTM(status, opts) {
                var race = opts.race || {};
                var labels = _getGTMEventLabels(status, race);
                var providerName = _getProviderName(opts.provider);
                var videoType = _getGTMVideoType(race.isReplay);
                var eventBuilder = null;
                var eventData = null;


                eventBuilder = GTMFac.EventBuilder()
                    .withEventLabel(labels.eventLabel)
                    .withGaEventAction(labels.gaEventAction)
                    .withGaEventCategory(labels.gaEventCategory)
                    .withGaEventLabel(labels.gaEventLabel);

                // there's no track name or race number when streaming
                // TVG channels (like in www.tvg.com/live)
                if (!_isTvgLiveChannel(race.trackId)) {
                    eventBuilder = eventBuilder
                        .withTrackName(race.trackName)
                        .withRaceNumber(race.raceNumber);
                }

                // compile event data
                eventData = eventBuilder.build();

                // extra properties
                eventData.videoProvider = providerName;
                eventData.videoType = videoType.type;

                if (status === PLAYBACK_STATE.ERROR) {
                    eventData.errorType = opts.error;
                    eventData.errorMessage = opts.errorMessage;
                }

                GTMFac
                    .GTMEvent()
                    .send($rootScope, labels.event, eventData);
            }

            //
            // PUBLIC
            //

            /**
             * Load video playback state
             *
             * @param {String} status               Video playback state
             * @param {Object} videoInfo            Video/race data
             * @param {Object} [opts]               Event options
             * @param {String} [opts.error]         Error name
             * @param {String} [opts.errorMessage]  Error message
             * @return {Object}                     Log event data
             */
            function logPlayback(status, videoInfo, opts) {
                opts = opts || {};

                var event = {
                    provider: _getVideoProvider(),
                    race: {
                        trackId: videoInfo.trackId,
                        trackName: videoInfo.trackName,
                        racePerf: videoInfo.racePerf,
                        raceNumber: videoInfo.raceNumber,
                        date: videoInfo.date,
                        time: videoInfo.time,
                        isReplay: videoInfo.isReplay
                    }
                };

                if (status === PLAYBACK_STATE.ERROR) {
                    event.error = opts.error;
                    event.errorMessage = opts.errorMessage;
                }

                _logPlaybackGTM(status, event);

                return {
                    status: status,
                    event: event
                };
            }

            /**
             * Log the video playback loading start
             *
             * @param {Object} videoInfo  Video/race data
             * @return {Object}           Log event data
             */
            function logPlaybackLoad(videoInfo) {
                return logPlayback(PLAYBACK_STATE.LOAD, videoInfo);
            }

            /**
             * Log the video playback success
             *
             * @param {Object} videoInfo  Video/race data
             * @return {Object}           Log event data
             */
            function logPlaybackSuccess(videoInfo) {
                return logPlayback(PLAYBACK_STATE.SUCCESS, videoInfo);
            }

            /**
             * Log the video playback error
             *
             * @param {Object} videoInfo          Video/race data
             * @param {Object} opts               Event opts
             * @param {String} opts.error         Error name
             * @param {String} opts.errorMessage  Error message
             * @return {Object}           Log event data
             */
            function logPlaybackError(videoInfo, opts) {
                return logPlayback(PLAYBACK_STATE.ERROR, videoInfo, opts);
            }


            // exports
            return {
                PROVIDER: PROVIDER,
                PLAYBACK_STATE: PLAYBACK_STATE,
                logPlayback: logPlayback,
                logPlaybackLoad: logPlaybackLoad,
                logPlaybackSuccess: logPlaybackSuccess,
                logPlaybackError: logPlaybackError
            };
        }

        VideosLoggingFac.$inject = [
            '$rootScope',
            'GTMFac'
        ];

        return VideosLoggingFac;
    });

