'use strict';

define(
    'SiteTabsCtrl',[],
    function () {
        function SiteTabsCtrl ($filter, $location, $log) {
            var vm = this;
            var hostname = $location.host();

            vm.showSiteTabs = false;

            try {
                if (/4njbets/gi.test(hostname)) {
                    vm.tabs = JSON.parse($filter('CMSValue')('siteTabs'));
                    vm.showSiteTabs = true;
                }
            } catch (e) {
                $log.error('Failed to load siteTabs');
            }
        }


        SiteTabsCtrl.$inject = ['$filter', '$location', '$log'];

        return SiteTabsCtrl;
    }
);

