'use strict';

define('RaceCtrl',[
   'MtpDisplayModel'
], function (MtpDisplay) {

    function RaceCtrl($scope, RaceInfoFac, TrackInfoFac, RaceResultsUseCase, RaceResultsGateway, $q, $filter) {
        $scope.data = {
            loading: false,
            raceOpened: false,
            raceDataError: false
        };

        function updateMtp() {
            $scope.race.mtpDisplay = new MtpDisplay($scope.race.postTime);
        }

        function _formatPayoffValue(value) {
            if(value && value.payOff > 0) {
                value = parseFloat(value.payOff);
                return $filter('currency')(value);
            }
        }

        function _returnResultFromData(data, raceData) {
            var filteredResults = data.length ? data.filter(function(obj) {
                return obj.trackAbbr.trim() === raceData.trackAbbr.trim() && obj.raceNumber === raceData.raceNumber;
            }) : [];

            if (!filteredResults.length && raceData){
                raceData.raceDate = _formatDate(raceData.raceDate);
                return RaceResultsGateway.getRaceResults(raceData).then(function (resultsData) {
                    resultsData = resultsData ? [resultsData] : [];
                    resultsData.forEach(function (resultData) {
                        resultData.fromResults = true;
                    });
                    return $q.resolve(resultsData);
                });
            } else {
                return $q.resolve(filteredResults);
            }
        }

        function _openDropdown(raceData, date) {
            if(!$scope.data.raceOpened) {
                $scope.data.raceDataError = false;
                $scope.data.loading = true;
                date = _formatDate(date);
                RaceInfoFac.getRaceResultsList({date: date}).then(function (data) {
                    return data.length ? data : $q.reject();
                })
                .then(function(data) {
                    if(data) {
                        return _returnResultFromData(data, raceData);
                    }
                })
                .then(function(race) {
                    if(race) {
                        TrackInfoFac.getTracksByDate(date).then(function(data) {
                            var selectedRace = data.filter(function(obj) {
                              return obj.trackAbbr.trim() === raceData.trackAbbr.trim();
                            });
                            _getInformation(race[0], selectedRace[0], raceData.raceNumber);
                        });
                    } else {
                        _showError();
                    }
                })
                .catch(function() {
                    _showError();
                });
            }
            $scope.data.raceOpened = !$scope.data.raceOpened;
        }

        function _showError() {
            $scope.data.raceDataError = true;
            $scope.data.loading = false;
        }

        function _formatDate(date) {
            return moment(date).format('YYYY-MM-DD');
        }

        function _getInformation(race, selectedRace, raceNumber) {
            if(selectedRace && race) {
                race.perfAbbr = selectedRace.perfAbbr;
                race.id = selectedRace.trackAbbr.trim() + '_' + selectedRace.perfAbbr + '_' + raceNumber;
                _getRaceInfo(race, raceNumber);
            } else {
                _showError();
            }
        }

        function _getRaceInfo(race, raceNumber) {
            var date = _formatDate($scope.date);
            RaceResultsUseCase.getRaceResults(
                race.perfAbbr,
                race.trackAbbr.trim(),
                raceNumber,
                race.raceTypeId,
                date
            )
            .then(function (raceResults) {
                if (raceNumber === raceResults.raceNumber) {
                    $scope.raceResults = raceResults;
                    $scope.raceResults.raceDateString = _formatDate($scope.raceResults.raceDate);
                }
            }).finally( function() {
                $scope.data.loading = false;
            });
        }

        function _getTime(postTime) {
            return moment(postTime).format('HH:MM');
        }

        function _getTimeOfDay(postTime) {
            return moment(postTime).format('A MMM DD');
        }

        $scope.$watch('race', init);

        function init() {
            updateMtp();
            $scope.data.raceOpened = false;
        }

        init();

        $scope.events = {
            getTimeOfDay: _getTimeOfDay,
            getTime: _getTime,
            openDropdown: _openDropdown,
            formatPayoffValue: _formatPayoffValue,
            showError: _showError
        };
    }

    RaceCtrl.$inject = [
        '$scope',
        'RaceInfoFac',
        'TrackInfoFac',
        'RaceResultsUseCase',
        'RaceResultsGateway',
        '$q',
        '$filter'
    ];

    return RaceCtrl;
});

