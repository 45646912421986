'use strict';

define('RaceTrackHubCtrl',[],
    function () {
        function RaceTrackHubCtrl($scope,
                                 $location,
                                 $rootScope,
                                 METADATA,
                                 $filter,
                                 $log,
                                 TrackInfoFac,
                                 $routeParams,
                                 GTMFac) {

            $scope.data = {
                loading: true
            };
            $scope.descriptionOfThisTrack = $filter('CMSValue')('raceTrackHub' + $routeParams.trackAbbr);
             /**
             * Gets all tracks to show them in the page
             * @return {object} [tracks]
             */

            $scope.loading = true;
                 /**
             * Returns if the URL is an image or not
             * @param  {[string]} the URL of the image
             */
            $scope.isImage = function(str) {
                return !!(str || '').match(/\.(jpg|gif|png)$/);
            };
            /**
             * Returns the Track Name
             * @return {[string]} Track Name
             */
            $scope.getTrackName = function() {
                return $routeParams.trackName.split('-').join(' ');
            };

            /**
             * Send Track GTM event
             * @param  {[name]} track name
             */
            $scope.sendGTMEvent = function(option, trackName) {
                var eventCategory = 'Site Click';
                if(option && trackName) {
                    GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.EventBuilder()
                    .withEventLabel('Track List - Results Back Link')
                    .withGaEventAction(eventCategory)
                    .withGaEventCategory(trackName + ' Track Page Click')
                    .withGaEventLabel(option + ' Link')
                    .withTrackName(trackName)
                    .build());
                }
            };

            $scope.sendGTMEventTrack = function(option) {
                var eventCategory = 'Site Click';

                if(option) {
                    GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.EventBuilder()
                    .withEventLabel('Track List - Track Click')
                    .withGaEventAction(eventCategory)
                    .withGaEventCategory('Track List Page Click')
                    .withGaEventLabel('Track | ' + option.Name )
                    .build());
                }
            };

            /**
             * Close the get all tracks error message
             */
            $scope.getAllTracksErrorClose = function() {
                $scope.getAllTracksError = false;
            };

            /**
             * Close the specific track error message
             * and redirect to the all tracks page
             */
            $scope.getTrackErrorClose = function() {
                $scope.getTrackError = false;
                $location.url('/racetracks');
            };


            /**
             * If you are in a detail page get all the tracks info
             */
                    TrackInfoFac.getTrackDetails($routeParams.trackAbbr)
                    .then(function(data){
                        $scope.track = data;
                        $scope.getTrackError = true;
                        $scope.errorMessage = $filter('CMSValue')('TrackListErrorMessage');
                    })
                    .catch(function (error) {
                        $log.error(error);
                    });

        }

        RaceTrackHubCtrl.$inject = [
            '$scope',
            '$location',
            '$rootScope',
            'METADATA',
            '$filter',
            '$log',
            'TrackInfoFac',
            '$routeParams',
            'GTMFac',
        ];

        return RaceTrackHubCtrl;
    }
);

