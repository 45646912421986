'use strict';

define('UserBalanceSvc',[ 'Mediator' ], function (mediator) {

    function UserBalanceSvc($rootScope, UserProfileSvc, $filter) {

        $rootScope.$on('accountBalance_update', _updateAccountBalance);
        $rootScope.$on('accountBalance_force_update', _forceBalaceUpdate);

        var balancePollerDeferred = null;

        function _forceBalaceUpdate($event, data) {
            _responseHandle(data);
        }

        function _startBalancePoller(){
            if (!balancePollerDeferred) {
                balancePollerDeferred = UserProfileSvc.pollBalance(false).then(null, function () {
                    _resetShowedBalance();
                }, function (response) {
                    _responseHandle(response);
                });
            }
        }

        function _stopBalancePoller(){
            if (balancePollerDeferred) {
                UserProfileSvc.stopPollingBalance(balancePollerDeferred);
                balancePollerDeferred = null;
            }
        }

        function _updateAccountBalance() {
            UserProfileSvc.getAccountBalance($rootScope.user).then(function (response) {
                _responseHandle(response);
            }, function () {
                _resetShowedBalance();
            });
        }

        function _responseHandle(response) {
            if ((response && response.balance === 0) || (response && response.balance && !isNaN(response.balance))) {
                $rootScope.accountBalanceNumber = response.balance;
                $rootScope.accountBalance = $filter('currency')(response.balance, '$');
                $rootScope.$broadcast('accountBalance_changed', response.balance);

                // meidator event dispatch for outer react components
                mediator.dispatch('ACCOUNT_BALANCE_CHANGED', {balance: response.balance});
            } else {
                _resetShowedBalance();
            }
        }

        function _resetShowedBalance() {
            $rootScope.accountBalanceNumber = null;
            $rootScope.accountBalance = null;
        }

    }

    UserBalanceSvc.$inject = [
        '$rootScope',
        'UserProfileSvc',
        '$filter'
    ];

    return UserBalanceSvc;
});

