'use strict';

define(
    'MyBets',[
        'MyBetsCtrl',
        'MyBetsDataTransformer',
        'MyBetsCancelBetCtrl',
        'MyBetsSvc',
        'MyBetsCancelBetDir',
        'WagersDir',
        'MyBetsTableDir',
        'MyBetsTableCtrl',
        'GTMMod',
        'MyBetsService'
    ],
    function (MyBetsCtrl, MyBetsDataTransformer, MyBetsCancelBetCtrl, MyBetsSvc, MyBetsCancelBetDir, WagersDir, MyBetsTableDir, MyBetsTableCtrl) {
        angular.module('TVG.MyBets', [
            'TVG.Common.GTM',
            'TVG.MyBetsService'
        ])
            .controller('MyBetsCtrl', MyBetsCtrl)
            .controller('MyBetsCancelBetCtrl', MyBetsCancelBetCtrl)
            .controller('MyBetsTableCtrl', MyBetsTableCtrl)
            .factory('MyBetsDataTransformer', MyBetsDataTransformer)
            .directive('cancelBet', MyBetsCancelBetDir)
            .directive('wagers', WagersDir)
            .directive('myBetsTable', MyBetsTableDir);
    }
);

