"use strict";

define('RaceResultsRunnerEntityBuilder',["RaceResultsRunnerEntity"], function (RaceResultsRunnerEntity) {

    function builder() {

        var runner = new RaceResultsRunnerEntity();

        return {
            withBettingInterestNumber: function (biNumber) {
                runner.bettingInterestNumber = biNumber;
                return this;
            },
            withFinishPosition: function (fPos) {
                runner.finishPosition = fPos;
                return this;
            },
            withPlacePayoff: function (pPayoff) {
                runner.placePayoff = pPayoff;
                return this;
            },
            withRunnerName: function (rName) {
                runner.runnerName = rName;
                return this;
            },
            withSaddleColor: function (sColor) {
                runner.saddleColor = sColor;
                return this;
            },
            withShowPayoff: function (sPayoff) {
                runner.showPayoff = sPayoff;
                return this;
            },
            withWinPayoff: function (wPayoff) {
                runner.winPayoff = wPayoff;
                return this;
            },
            withNumberColor: function (nColor) {
                runner.numberColor = nColor;
                return this;
            },
            build: function () {
                //return Object.seal(runner);
                return runner;
            }
        };
    }

    return builder;

});


