"use strict";

define('RaceParamsEntity',[],function () {

    function RaceParams(trackAbbr, perfAbbr, raceNumber, raceId){

        this.trackAbbr = trackAbbr;
        this.perfAbbr = perfAbbr;
        this.raceNumber = raceNumber;
        this.raceId = raceId;
    }

    return RaceParams;

});


