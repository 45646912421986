'use strict';

define('MTP',['MTPCtrl', 'MTPDateFac'], function (MTPCtrl, MTPDateFac) {
    angular.module('TVG.MTP', [])
        .component('mtp', {
            templateUrl: 'js/common/modules/MTP/templates/mtp.html',
            controller: MTPCtrl,
            bindings: {
                data: '='
            }
        })
        .factory('MTPDateFac', MTPDateFac);
});

