'use strict';

define('MTPDateFac',[
    'moment',
    'DateTimeFormatters'
], function (moment, DateTimeFormatters) {

    function MTPDateFac(racePostTime) {
        if (angular.isDate(racePostTime)) {
            this.racePostTime = racePostTime;
        } else {
            this.racePostTime = new Date(racePostTime);
        }
    }

    MTPDateFac.prototype.getMTP = function () {
        var output = {
            mtp: undefined,
            timeWithDay: undefined
        };

        if (moment(this.racePostTime).isValid()) {
            var millisecondsCurrentTime = new Date().getTime();
            var millisecondsToRaceStart = this.racePostTime.getTime() - millisecondsCurrentTime;
            var minutesToRaceStart = millisecondsToRaceStart / 1000 / 60;

            output.mtp = (minutesToRaceStart < 0) ? 0 : Math.round(minutesToRaceStart);

            output.timeWithDay = {
                time: moment(this.racePostTime).format('hh:mm'),
                amPmMarker: moment(this.racePostTime).format('A'),
                day: DateTimeFormatters.getRelativeDayBasedOnGivenDate(this.racePostTime, new Date())
            };
        }

        return output;
    };

    return MTPDateFac;

});


