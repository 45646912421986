'use strict';

define('MyBetsSvc',['lodash'], function(_){
    function MyBetsSvc($http, $q, $filter, $rootScope, ConfigurationFac, GraphWagerHistory) {

        function _getDateDescription(raceDate) {
            var relativeDay = $filter('getRelativeDayBasedOnGivenDate')(raceDate, new Date());
            var description = "";
            if (relativeDay === 'today') {
                description = "Today";
            } else if (relativeDay === 'ystrd') {
                description = "Yesterday";
            } else {
                description = $filter('date')(raceDate, 'MMM dd yyyy');
            }

            return description;
        }

        function _addAbortPromise(promise, deferredAbort) {
            //Abort http call
            promise.abort = function () {
                deferredAbort.resolve('Aborted');
            };

            // destroy abort function in the end of the service call
            promise.finally(function () {
                promise.abort = angular.noop;
                deferredAbort = null;
            });
        }

        function _getBetsSuccessResponse(promise, betsFilter, period) {
            return function (data) {
                var filteredRowData = [];
                // To detect that it's not a html error dump string
                if (typeof data === 'object') {
                    data.period = period;
                    if (data.wagerHistory && data.wagerHistory.rowData && data.wagerHistory.totalRecords) {
                        data.wagerHistory.rowData.forEach(function (bet) {
                            var wagerDate = new Date(bet.wagerDate),
                                winningsAmountSubStr = bet.winningsAmount.substring(1);
                            //apply filter
                            if (_.isFunction(betsFilter) && betsFilter(bet)) {
                                return;
                            }
                            bet.wagerDateString = $filter('date')(wagerDate, 'hh:mm a');
                            bet.wagerDateDescription = _getDateDescription(wagerDate);
                            if (!isNaN(winningsAmountSubStr)) {
                                bet.winningClass = parseFloat(winningsAmountSubStr) > 0 ? 'winner' : 'loser';
                            }

                            filteredRowData.push(bet);
                        });
                        data.wagerHistory.rowData = filteredRowData;
                    }
                    promise.resolve(data);
                } else {
                    promise.reject($filter('CMSValue')('betTicketErrorRetrievingWagerHistory'));
                }
            };
        }

        function _getBetsFailResponse(promise) {
            return function () {
                var configObj = arguments && arguments.length > 3 && arguments[3];
                if (configObj && configObj.timeout.$$state.value === 'Aborted') {
                    promise.resolve([]);
                    return;
                }
                promise.reject($filter('CMSValue')('betTicketErrorRetrievingWagerHistory'));
            };
        }

        function _buildDataForPost(startDate, endDate, trackAbbr, betsFilter, pageNumber, recordsPerPage, sortedColumn, sortOrder) {
            var dataString = "report=wager&page=|page|&rows=|rows|&startDate=|startDate|&endDate=|endDate|" +
                "&bet=|betsFilter|&track=|trackAbbr|&sidx=|sortedColumn|&sord=|sortOrder|";

            var tAbbr = trackAbbr || "";
            var bFilter = betsFilter || "";
            var pNumber = pageNumber || "";
            var rPerPage = recordsPerPage || "";
            var sColumn = sortedColumn || "";
            var sOrder = sortOrder || "";

            return dataString
                .replace("|startDate|", encodeURIComponent(startDate))
                .replace("|endDate|", encodeURIComponent(endDate))
                .replace("|betsFilter|", encodeURIComponent(bFilter))
                .replace("|trackAbbr|", encodeURIComponent(tAbbr))
                .replace("|page|", encodeURIComponent(pNumber))
                .replace("|rows|", encodeURIComponent(rPerPage))
                .replace("|sortedColumn|", encodeURIComponent(sColumn))
                .replace("|sortOrder|", encodeURIComponent(sOrder));
        }

        var myBetSvc = {
            getBetsByDate: function (startDate, endDate, trackAbbr, filterFunction, betsFilter, pageNumber, recordsPerPage, sortedColumn, sortOrder) {
                var deferred = $q.defer(),
                deferredAbort = $q.defer(),
                promise,
                url = '/ajax/wager-history/';
                promise = deferred.promise;
                var startDateInvalid = 'Start date value invalid';
                var endDateInvalid = 'End date value invalid';

                if (_.isUndefined(startDate)) return $q.reject(startDateInvalid);
                if (_.isUndefined(endDate)) return $q.reject(endDateInvalid);

                $http({
                    method: 'POST',
                    url: url,
                    data: _buildDataForPost(startDate, endDate, trackAbbr, betsFilter, pageNumber, recordsPerPage, sortedColumn, sortOrder),
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    timeout: deferredAbort.promise
                })
                .success(_getBetsSuccessResponse(deferred, filterFunction))
                .error(_getBetsFailResponse(deferred));

                _addAbortPromise(promise, deferredAbort);

                return promise;
            },

            getBetsByDateFromGke: function (startDate, endDate, trackAbbr, raceNumber, betStatus, pageNumber, recordsPerPage, sortingId, sortingReverse) {
                var url = ConfigurationFac.getServiceApiUrl('wro') + '/wagers' +
                    '?startDate=' + startDate +
                    '&endDate=' + endDate +
                    '&page=' + pageNumber +
                    '&recordsPerPage=' + recordsPerPage +
                    '&sort=' + sortingId +
                    '&ascendingOrder=' + sortingReverse +
                    '&status=' + betStatus || 'all';

                var deferred = $q.defer();

                if (trackAbbr) {
                    url += '&track=' + trackAbbr;
                }

                if (raceNumber) {
                    url += '&race=' + raceNumber;
                }

                $http.get(url).then(function (response) {
                    deferred.resolve(response.data);
                }, function (err) {
                    deferred.reject(err);
                });

                return deferred.promise;
            },

            getBetsByTvgRaceIdFromGke: function (tvgRaceId, betStatus, pageNumber, recordsPerPage, sortingId, sortingReverse) {
                var url = ConfigurationFac.getServiceApiUrl('wro') + '/users/'+ $rootScope.user.accountNumber +'/wagers' +
                    '?tvgRaceId=' + tvgRaceId +
                    '&page=' + pageNumber +
                    '&recordsPerPage=' + recordsPerPage +
                    '&sort=' + sortingId +
                    '&ascendingOrder=' + sortingReverse +
                    '&status=' + betStatus || 'all';

                var deferred = $q.defer();


                $http.get(url).then(function (response) {
                    deferred.resolve(response.data);
                }, function (err) {
                    deferred.reject(err);
                });

                return deferred.promise;
            },

            getBetsByTvgRaceIdFromBehg: function(tvgRaceId, betStatus){
                return GraphWagerHistory.get({
                    accountId: $rootScope.user.accountNumber,
                    tvgRaceId: tvgRaceId,
                    status: betStatus
                });
            },

            getBets: function (period, track, betsFilter) {
                var deferred = $q.defer(),
                deferredAbort = $q.defer(),
                promise,
                url = '/ajax/wager-history/report/mybets';

                // Server-side filtering
                if (period) {
                    url += ('/filter/' + period);
                }
                if (track) {
                    url += ('/track/' + track);
                }

                promise = deferred.promise;

                $http.get(url, {timeout: deferredAbort.promise})
                    .success(_getBetsSuccessResponse(deferred, betsFilter, period))
                    .error(_getBetsFailResponse(deferred));

                _addAbortPromise(promise, deferredAbort);

                return promise;
            }
        };

        return myBetSvc;
    }

    MyBetsSvc.$inject = ['$http', '$q', '$filter', '$rootScope','ConfigurationFac', 'GraphWagerHistory'];

    return MyBetsSvc;
});

