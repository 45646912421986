'use strict';

define(
    'TrackSelectorItemFactory',[
        'TrackSelectorItemBuilder'
    ],
    function (TrackSelectorItemBuilder) {

        function TrackSelectorItemsFactory(FavoriteTracksFac) {

            var _mappers = {
                toTrackSelectorItem: function (trackName, trackAbbr, raceNumber, racePostTime, perfAbbr) {
                    return TrackSelectorItemBuilder()
                        .withTrackName(trackName)
                        .withTrackAbbr(trackAbbr)
                        .withRaceNumber(raceNumber)
                        .withPostTime(racePostTime)
                        .withPerfAbbr(perfAbbr)
                        .build();
                }
            };

            function _mapToTrackSelectorItem(track) {
                return _mappers.toTrackSelectorItem(track.name, track.trackAbbr, track.currentWagerableRace, track.currentWagerableRacePostTime, track.perfAbbr);
            }

            return {
                createTrackSelectorItemsForFavoriteTracks: function (tracks) {
                    var favoritesTracks = FavoriteTracksFac.getFavoriteTracks();
                    if (!favoritesTracks || favoritesTracks.length === 0) {
                        return [];
                    }
                    return tracks.filter(function (track) {
                        return track.currentWagerableRace !== 0 && favoritesTracks.indexOf(track.trackAbbr) >= 0;
                    }).map(_mapToTrackSelectorItem);
                },

                createTrackSelectorItemsForFeaturedTracks: function (tracks) {
                    return tracks.filter(function (track) {
                        return track.currentWagerableRace !== 0 && track.featured == true;
                    }).map(_mapToTrackSelectorItem);
                },

                createTrackSelectorItemsForAllTracks: function (tracks) {
                    return tracks.filter(function (track) {
                        return track.currentWagerableRace !== 0;
                    }).map(_mapToTrackSelectorItem);
                }
            };

        }

        TrackSelectorItemsFactory.$inject = [
            'FavoriteTracksFac'
        ];

        return TrackSelectorItemsFactory;
    }
);




