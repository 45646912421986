'use strict';

define('PreferencesFac',[
    'PreferencesEntityBuilder',
    'lodash'
], function (PreferencesEntityBuilder, _) {
    function PreferencesFac($http, $q, ConfigurationFac) {
        var baseUrl = ConfigurationFac.getServiceApiUrl('uam') + '/preferences';

        function isPreferenceObject (pref) {
            return _.isObject(pref) && pref.hasOwnProperty('metadata') && pref.hasOwnProperty('description');
        }

        function _buildPreferenceList(preferences){
            var userPreferences = [];
            if (_.isArray(preferences)) {
                userPreferences = preferences.map(function(preference) {
                    return PreferencesEntityBuilder()
                        .withMetadata(preference.metadata)
                        .withDescription(preference.description)
                        .build();
                });
            }

            if (isPreferenceObject(preferences)) {
                userPreferences.push(preferences);
            }

            return userPreferences;
        }

        var preferencesFac =  {
            getPreferences: function() {
                var deferred = $q.defer();

                $http.get(baseUrl).success(function (response) {
                    deferred.resolve(_buildPreferenceList((response.preferences)));
                }).error(function (response) {
                    deferred.reject(response.error);
                });

                return deferred.promise;
            },
            getUniquePreference: function(preferenceName) {
                var deferred = $q.defer();

                var url = baseUrl + '?preferenceName=' + preferenceName;

                $http.get(url).success(function (response) {
                    if (_.isArray(response.preferences) && response.preferences.length)
                    {
                       var preference = PreferencesEntityBuilder()
                           .withMetadata(response.preferences[0].metadata)
                           .withDescription(response.preferences[0].description)
                           .build();

                        deferred.resolve(preference);
                    } else {
                        deferred.reject('Invalid Data');
                    }
                }).error(function () {
                    var defaultPrefs = {
                        description: 'FAVORITE_TRACKS',
                        metadata: ''
                    };
                    deferred.resolve(defaultPrefs);
                });

                return deferred.promise;
            },
            updatePreferences: function(preferences) {
                var deferred = $q.defer();
                var prefList = _buildPreferenceList(preferences);

                $http.put(baseUrl, {
                    preferences: prefList
                }).success(function (response) {
                    deferred.resolve(response);
                }).error(function (response) {
                    deferred.reject(response.error);
                });

                return deferred.promise;
            }
        };

        return {
            getPreferences: preferencesFac.getPreferences,
            getUniquePreference: preferencesFac.getUniquePreference,
            updatePreferences: preferencesFac.updatePreferences
        };
    }

    PreferencesFac.$inject = [
        '$http',
        '$q',
        'ConfigurationFac'
    ];

    return PreferencesFac;

});

