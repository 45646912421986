'use strict';

define('TrackSelectorCtrl',['lodash'],
        function (_) {

            /***
             * This Controller is bound to the track-selector directive
             * Inherits trackSelectorDefaultOptions (string) ; trackSelectorOnChange (function)
             * @constructor
             */
            function TrackSelectorCtrl(
                $scope,
                $rootScope,
                $location,
                $injector,
                GTMFac,
                GraphTrackList,
                GraphRacesNavigation,
                $log,
                WagerProfileFac,
                RaceUrlFac,
                $routeParams,
                $filter
            ) {
                var vm = this;
                var trackFactory;
                var trackListSubscription;
                var options;
                var optionsRaces;
                var wagerProfile;
                var subscriptionTime = $filter('CMSLabels')('low', 'graphqlPollingRates') || 30000; // in ms
                var races = [];
                var routeParams;
                var selectedTrack;
                var currentTrackList = [];

                vm.model = {
                    favoriteTracks: [],
                    featuredTracks: [],
                    allTracks: [],
                    dropdown: {
                        isOpen: false,
                        selectedTrackName: ""
                    },
                    isLoading: false
                };
                vm.events = {
                    onChangeSelectedTrack: function (track) {
                        vm.trackSelectorOnChange({track: track});
                        vm.trackSelectorDefaultOption = track.trackName;
                        sendGTMTrackInfo(track);
                    },
                    goToFavorites: function () {
                        $location.path("/my-profile").hash("favorites+my-tracks");
                    },
                    openModalMenu: function () {
                        $rootScope.$emit("callLoginRequiredModal");
                    },
                    sendCloseTrackSelectorGTMEvent: sendGtmDropdownEvent
                };

                vm.trackId = function (track) {
                    return [track.trackAbbr, track.perfAbbr, track.raceNumber].join('_');
                };

                function sendGtmDropdownEvent(val) {
                    if (val) {
                        var eventData = GTMFac.gaEventBuilder()
                            .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                            .withGaEventCategory('Site Click')
                            .withGaEventLabel('Top Nav | Close Window')
                            .withEventLabel('TopNav-CloseTrackList')
                            .build();

                        GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
                    }
                }

                $scope.$on('updateTrackUrl', function (event, race) {
                    vm.events.onChangeSelectedTrack(race);
                });

                function sendGTMTrackInfo(barTrack) {
                    var _favoriteTracks = vm.model.favoriteTracks.filter(function (elem) {
                        return elem === barTrack;
                    }).length;
                    var _featuredTracks = vm.model.featuredTracks.filter(function (elem) {
                        return elem === barTrack;
                    }).length;
                    var _allTracks = vm.model.allTracks.filter(function (elem) {
                        return elem === barTrack;
                    }).length;

                    var trackType;
                    var trackEventLabel;

                    if (_favoriteTracks) {
                        trackType = 'Favorite Track';
                        trackEventLabel = 'FavoriteTracks';
                    } else if (_featuredTracks) {
                        trackType = 'Popular Track';
                        trackEventLabel = 'PopularTracks';
                    } else if (_allTracks) {
                        trackType = 'Other Track';
                        trackEventLabel = 'OtherTracks';
                    }

                    if (_favoriteTracks || _featuredTracks || _allTracks) {
                        var eventData = GTMFac.gaEventBuilder()
                            .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                            .withGaEventCategory('Site Click')
                            .withGaEventLabel('Top Nav | ' + trackType + ' | ' + barTrack.trackName)
                            .withEventLabel('TopNav-' + trackEventLabel)
                            .build();

                        GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
                    }
                }

                function _setViewModelTracksBasedOnTrackList(trackList) {
                    selectedTrack = RaceUrlFac.getRacesInfoFromRouteParams().trackAbbr || $routeParams.trackAbbr;

                    // Hammer Time!! Because graph is return the race number as a string
                    trackList.forEach(function (track) {
                        if(track.trackAbbr == selectedTrack) {
                            track.races = races;
                            track.races.forEach(function (race) {
                                race.raceNumber = parseInt(race.raceNumber, 10);
                            });

                            if($rootScope.activeFeatures.selectedTrackName){
                                _setTrackSelectorName(track.name);
                            }
                        }
                    });

                    vm.model.favoriteTracks = trackFactory.createTrackSelectorItemsForFavoriteTracks(trackList);
                    vm.model.featuredTracks = trackFactory.createTrackSelectorItemsForFeaturedTracks(trackList);
                    vm.model.allTracks = trackFactory.createTrackSelectorItemsForAllTracks(trackList);

                    $rootScope.$emit('trackListReady', trackList);
                    currentTrackList = trackList;
                }

                function _refreshFavoriteTracks() {
                    if(currentTrackList.length > 0) {
                        vm.model.favoriteTracks = trackFactory.createTrackSelectorItemsForFavoriteTracks(currentTrackList);
                    }
                }

                function _buildTrackListFromGraph(trackList) {
                    var track = _.find(trackList, function (track) {
                        return track.trackAbbr === selectedTrack;
                    });

                    if (!track) {
                        // means the user logged in with a profile that doesn't allow betting for this race, so lets say its the first track available (ordered alphabetically)
                        // TODO: this should be the track that has the closest race to bet ATM
                        track = trackList && trackList.length > 0 ? trackList[0] : {};
                        selectedTrack = track.trackAbbr;
                        $rootScope.$broadcast('video:stop', { instanceId: 'video-main-programpage' });
                        vm.model.isLoading = false;
                        return;
                    }

                    optionsRaces = {
                        wagerProfile: wagerProfile,
                        trackAbbr: selectedTrack
                    };

                    GraphRacesNavigation.get(optionsRaces).then(function (response) {
                        races = response;

                        // TODO: remove this one graph returns raceNumber as int
                        races.forEach(function (graphRace) {
                            graphRace.raceNumber = parseInt(graphRace.raceNumber, 10);
                        });

                        track.races = races;
                        _setViewModelTracksBasedOnTrackList(trackList);
                        vm.model.isLoading = false;

                        if ($scope.$root && !$scope.$root.$$phase) {
                            $scope.$apply();
                        }
                    });
                }

                function _populateViewModelWithTracks() {
                    vm.model.isLoading = true;

                    // Graph request
                    wagerProfile = WagerProfileFac.getSessionOrGenericProfile();
                    options = {
                        wagerProfile: wagerProfile,
                        filter: {
                            isGreyhound: $rootScope.isGreyhoundRace
                        }
                    };

                    if (!trackListSubscription) {
                        trackListSubscription = GraphTrackList.subscribe(options, {
                            success: _buildTrackListFromGraph,
                            fail: function (error) {
                                $log.error(error); // eslint-disable-line
                                vm.model.isLoading = false;
                            }
                        }, subscriptionTime, true);
                    } else {
                        GraphTrackList.refetch({wagerProfile: WagerProfileFac.getSessionOrGenericProfile()}).then(_buildTrackListFromGraph);
                    }
                }

                function _selectTrackSelectorFactory() {
                    switch (vm.trackSelectorType) {
                        case "programPage":
                            trackFactory = $injector.get('TrackSelectorItemCurrentRaceFactory');
                            break;
                        default:
                            trackFactory = $injector.get('TrackSelectorItemFactory');
                    }

                }

                function _setTrackSelectorName(trackName) {
                    if(trackName){
                        vm.trackSelectorDefaultOption = trackName;
                    } else {
                        routeParams = RaceUrlFac.getRacesInfoFromRouteParams();

                        if (routeParams.trackAbbr && routeParams !== '') {
                            vm.trackSelectorDefaultOption = routeParams.trackName;
                        } else {
                            vm.trackSelectorDefaultOption = $routeParams.trackName;
                        }
                    }
                }

                (function init() {
                    var hasRouteParams;
                    var params;

                    routeParams = RaceUrlFac.getRacesInfoFromRouteParams();
                    hasRouteParams = routeParams.trackAbbr && routeParams !== '';
                    params = hasRouteParams ? routeParams : $routeParams;
                    selectedTrack = params.trackAbbr;

                    if(!$rootScope.activeFeatures.selectedTrackName){
                        vm.trackSelectorDefaultOption = params.trackName;
                    }

                    _selectTrackSelectorFactory();
                }());

                $scope.$on("$destroy", function () {
                    if (trackListSubscription){
                        trackListSubscription.unsubscribe();
                    }
                });

                var unbindOnSession = $scope.$on('session', function () {
                    _populateViewModelWithTracks();
                    unbindOnSession();
                });

                $scope.$on('user', _populateViewModelWithTracks);
                $scope.$on('newFavTrack', _refreshFavoriteTracks);
            }

            TrackSelectorCtrl.$inject = [
                '$scope',
                '$rootScope',
                '$location',
                '$injector',
                'GTMFac',
                'GraphTrackList',
                'GraphRacesNavigation',
                '$log',
                'WagerProfileFac',
                'RaceUrlFac',
                '$routeParams',
                '$filter'
            ];

            return TrackSelectorCtrl;
        }
);

