'use strict';

define('GTMMod',[
    'angular',
    'angularRoute',
    'GTMFac',
    'RegistrationPageGTMFac',
    'RacesPageGTMFac',
    'ScreenNames',
    'SectionNames',
    'TVGCommon',
    'RaceInfo'
], function (
    angular,
    ngRoute,
    GTMFac,
    RegistrationPageGTMFac,
    RacesPageGTMFac,
    ScreenNames,
    SectionNames,
    TVGCommon,
    RaceInfo
) {
    return angular
        .module('TVG.Common.GTM', ['ngRoute', TVGCommon.default, RaceInfo])
        .factory('GTMFac', GTMFac)
        .factory('RegistrationPageGTMFac', RegistrationPageGTMFac)
        .factory('RacesPageGTMFac', RacesPageGTMFac)
        .constant('ScreenNames', ScreenNames)
        .constant('SectionNames', SectionNames)
        .name;
});

