'use strict';

define('RaceLinkFilter',[],
    function () {
        function RaceLinkFilter(RaceUrlFac) {
            return function(race) {
                return RaceUrlFac.calculateRacePath(race.trackAbbr, race.trackName, race.raceNumber, false);
            };
        }

        RaceLinkFilter.$inject = [
            'RaceUrlFac'
        ];

        return RaceLinkFilter;
    }
);

