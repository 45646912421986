'use strict';

define(
    'Unsubscribe',['UnsubscribeCtrl'],
    function (UnsubscribeCtrl) {
        angular.module('TVG.Unsubscribe', [])
            .controller('UnsubscribeCtrl', UnsubscribeCtrl);
    }
);

