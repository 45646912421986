'use strict';

define('RaceTypeIDFilter',[
        'Content'
    ],
    function () {
        function RaceTypeIDFilter(METADATA) {
            return function(raceTypeID) {
                return METADATA.raceTypes ? METADATA.raceTypes[raceTypeID] : {};
            };
        }

        RaceTypeIDFilter.$inject = [
            'METADATA'
        ];

        return RaceTypeIDFilter;
    }
);

