'use strict';

define(
    'VideoProvider',[
        'VideoProviderSvc',
        'Configuration'
    ],

    function (VideoProviderSvc) {

        angular.module('TVG.VideoProvider', [ 'TVG.Configuration' ])
            .service('VideoProviderSvc', VideoProviderSvc);
    }
);

