"use strict";

define('RunnerInfoFac',["RaceReplayEntityBuilder"], function(raceReplayEntityBuilder) {
    function RunnerInfoFac($http, $q, METADATA) {
        var RunnerReplaysSvc, UtilSvc;

        RunnerReplaysSvc = {
            getRunnerReplays: function(runnerName) {
                //This promise will always return an array
                var deferred = $q.defer();
                var url =
                    "ajax/race-replays/id/horse/name/" +
                    encodeURIComponent(runnerName);

                $http.get(url).then(
                    function(response) {
                        if (response && response.data) {
                            var racesData = UtilSvc.sortObject(
                                response.data,
                                "desc"
                            );
                            var raceReplaysList = [];

                            for (var raceReplay in racesData) {
                                var raceReplayEntity = raceReplayEntityBuilder()
                                    .withRaceDate(
                                        racesData[raceReplay].raceDate
                                    )
                                    .withPostTime(
                                        racesData[raceReplay].postTime
                                    )
                                    .withTrackName(
                                        racesData[raceReplay].trackName
                                    )
                                    .withTrackAbbr(racesData[raceReplay].track)
                                    .withProviderTrack(
                                        racesData[raceReplay].providerTrack
                                    )
                                    .withRaceNumber(racesData[raceReplay].race)
                                    .withPerfAbbr(racesData[raceReplay].perf)
                                    .withRaceClass(racesData[raceReplay].class)
                                    .withDistance(
                                        racesData[raceReplay].distance
                                    )
                                    .withProvider(
                                        racesData[raceReplay].provider
                                    )
                                    .withRaceTypeId(
                                        racesData[raceReplay].raceTypeId
                                    )
                                    .withRaceTypeName(
                                        METADATA.raceTypes[
                                            racesData[raceReplay].raceTypeId
                                        ]
                                            ? METADATA.raceTypes[
                                                  racesData[raceReplay]
                                                      .raceTypeId
                                              ].Name
                                            : ""
                                    )
                                    .build();

                                raceReplaysList.push(raceReplayEntity);
                            }

                            deferred.resolve(raceReplaysList);
                        } else {
                            deferred.resolve([]);
                        }
                    },
                    function() {
                        deferred.resolve([]);
                    }
                );

                return deferred.promise;
            }
        };

        UtilSvc = {
            sortObject: function(obj, sortingType) {
                var sorted = {};
                var key;
                var attr = [];

                for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        attr.push(key);
                    }
                }

                switch (sortingType) {
                    case "asc": {
                        attr.sort();
                        break;
                    }
                    case "desc":
                    default: {
                        attr.sort().reverse();
                        break;
                    }
                }

                for (key = 0; key < attr.length; key++) {
                    sorted[attr[key]] = obj[attr[key]];
                }

                return sorted;
            }
        };

        return {
            getRunnerReplays: RunnerReplaysSvc.getRunnerReplays,
            sortObject: UtilSvc.sortObject
        };
    }

    RunnerInfoFac.$inject = ["$http", "$q", "METADATA"];

    return RunnerInfoFac;
});

