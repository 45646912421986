'use strict';

define('FullScheduleReloadedFac',[
    'lodash',
    'FullScheduleReloadedModel'
], function (_, FullScheduleReloadedModel) {

    function FullScheduleReloadedFac($rootScope, RaceMtpStatusFac, FavoriteTracksFac, ManageFavoritesFac) {

        var userFavorites = [];

        function _aggregateRace(race, racesByTracks) {
            var existingTrackIndex = _verifyExistance(race, racesByTracks);

            if (existingTrackIndex != -1) {
                _addElement(race, racesByTracks[existingTrackIndex].races);
            } else {
                var fullScheduleObj;

                // TODO: delete this validation once the globalMtpSystem toggle is 100%
                if ($rootScope.activeFeatures.globalMtpRefreshSystem) {
                    fullScheduleObj = new FullScheduleReloadedModel(race.trackAbbr,
                        _isFavorite(race),
                        race.trackName,
                        race.raceNumber,
                        race.perfAbbr,
                        race.runners,
                        race.distance,
                        race.raceClass,
                        race.surfaceName,
                        race.onTvg,
                        race.onTvg2,
                        race.liveStreaming,
                        race.postTime,
                        race.featured,
                        race.mtp,
                        race.country || ''
                    );
                } else {
                    fullScheduleObj = new FullScheduleReloadedModel(race.trackAbbr,
                        _isFavorite(race),
                        race.trackName,
                        race.raceNumber,
                        race.perfAbbr,
                        race.runners,
                        race.distance,
                        race.raceClass,
                        race.surfaceName,
                        race.onTvg,
                        race.onTvg2,
                        race.liveStreaming,
                        race.postTime,
                        race.featured,
                        race.country || ''
                    );
                }

                racesByTracks.push({
                    trackAbbr: race.trackAbbr,
                    liveStreaming: race.liveStreaming,
                    trackName: race.trackName,
                    isFavorite: _isFavorite(race),
                    races: [
                        fullScheduleObj
                    ]
                });
            }
        }

        function _addElement(race, collection) {
            // TODO: delete this validation once the globalMtpSystem toggle is 100%
            if ($rootScope.activeFeatures.globalMtpRefreshSystem) {
                collection.push(
                    new FullScheduleReloadedModel(
                        race.trackAbbr,
                        _isFavorite(race),
                        race.trackName,
                        race.raceNumber,
                        race.perfAbbr,
                        race.runners,
                        race.distance,
                        race.raceClass,
                        race.surfaceName,
                        race.onTvg,
                        race.onTvg2,
                        race.liveStreaming,
                        race.postTime,
                        race.featured,
                        race.mtp,
                        race.country || ''
                    )
                );
            } else {
                collection.push(
                    new FullScheduleReloadedModel(
                        race.trackAbbr,
                        _isFavorite(race),
                        race.trackName,
                        race.raceNumber,
                        race.perfAbbr,
                        race.runners,
                        race.distance,
                        race.raceClass,
                        race.surfaceName,
                        race.onTvg,
                        race.onTvg2,
                        race.liveStreaming,
                        race.postTime,
                        race.featured,
                        race.country || ''
                    )
                );
            }
        }

        function _verifyExistance(obj, collection) {
            return _.findIndex(collection, function (collectionRace) {
                return collectionRace.trackAbbr === obj.trackAbbr;
            });
        }

        function _readUserFavorites() {
            userFavorites = angular.copy(FavoriteTracksFac.getFavoriteTracks());
        }

        function _isFavorite(obj) {
            _readUserFavorites();
            return userFavorites.indexOf(obj.trackAbbr) != -1;
        }

        function _isFeaturedRace(race) {
            return race.featured === true;
        }

        function _processData(data) {
            _readUserFavorites();

            var result = {
                featuredRaces: [],
                fullSchedule: [],
                todaysFavorites: [],
                racesByTrack: [],
                favoriteRacesByTrack: []
            };

            _.forEach(data, function (race) {
                if ($rootScope.activeFeatures.globalMtpRefreshSystem) {
                    race.mtp = RaceMtpStatusFac.getRaceMtpStatus(race.trackAbbr + '_' + race.raceNumber).mtp;
                }

                if (_isFeaturedRace(race)) {
                    if (_verifyExistance(race, result.featuredRaces) == -1) {
                        _addElement(race, result.featuredRaces);
                    }
                }

                if (_isFavorite(race)) {
                    _aggregateRace(race, result.favoriteRacesByTrack);

                    if (_verifyExistance(race, result.todaysFavorites) == -1) {
                        _addElement(race, result.todaysFavorites);
                    }
                }

                _aggregateRace(race, result.racesByTrack);
                _addElement(race, result.fullSchedule);
            });

            return result;
        }

        function _updateFavorite(trackAbbr) {
            return ManageFavoritesFac.updateFavTrackList(trackAbbr);
        }


        return {
            getData: _processData,
            updateFavorite: _updateFavorite,
            isFavoriteTrack: _isFavorite
        };
    }

    FullScheduleReloadedFac.$inject = [
        '$rootScope',
        'RaceMtpStatusFac',
        'FavoriteTracksFac',
        'ManageFavoritesFac'
    ];

    return FullScheduleReloadedFac;

});

