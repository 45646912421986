"use strict";

define('AppCore',["AppRootFac", "AppRequestInterceptor", "GTMMod"], function (
    AppRootFac,
    AppRequestInterceptor
) {
    return angular
        .module("TVG.AppCore", ["TVG.Common.GTM", "TVG.Utilities"])
        .factory("AppRootFac", AppRootFac)
        .provider("AppRequestInterceptor", AppRequestInterceptor).name;
});

