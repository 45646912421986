'use strict';

define(
        'QuickDepositCtrl',[
        ],
        function () {

            function QuickDepositCtrl($scope, $rootScope, $location, $uibModal, FindModalContainerFac) {

                $scope.depositSuccessData = null;
                $scope.isOpen = true;

                $scope.dismissNotification = function () {
                    $scope.msgHidden = true;
                };

                $scope.notify = function (type, message) {
                    $scope.msgType = type;
                    $scope.msgText = message;
                    $scope.msgHidden = false;
                };

                $scope.$on('logout', function () {
                    if ($scope.isOpen) {
                        $scope.isOpen = false;
                        $scope.$close();
                    }
                });

                $scope.dismissNotification();

                $scope.$on('onDepositSuccess', function (event, depositData) {
                    $scope.depositSuccessData = depositData;
                    if ($rootScope.hasOwnProperty('user') && $rootScope.user.hasOwnProperty('accountNumber')) {
                        $scope.depositSuccessData.accountNum = $rootScope.user.accountNumber;
                    }
                    $rootScope.$broadcast("accountBalance_update");
                });

                $scope.$on('onDepositError', function (event, errorMessageObj) {
                    $scope.depositSuccessData = null;
                    $scope.notify("error", errorMessageObj);
                });

                $scope.$on('onCloseSuccessWindow', function () {
                    $scope.isOpen = false;
                    $scope.$close();
                });

                var unbind = $rootScope.$on('onBinValidationFail', function(){
                    $scope.isOpen = false;
                    $scope.$close();

                    if($rootScope.productContext === "touch2") {
                        var depositTemplate = 'js/mobile/modules/Account/templates/account-deposit.html';
                        _openDepositOnTouch(depositTemplate, 'Deposit');
                    } else {
                        $location.path('/my-funds/deposit');
                    }

                });

                function _openDepositOnTouch(rootUrl, title) {
                    $uibModal.open({
                        appendTo: FindModalContainerFac('#angular-app'),
                        windowClass: 'account',
                        backdropClass: 'tvgmodal-backdrop',
                        windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                        template: '<stack-view data-root-url="' +
                        rootUrl + '" data-root-title="' + title + '"></stack-view>'
                    });
                }

                $scope.$on('$destroy',function(){
                   unbind();
                });

                $scope.events = {
                    close: function close(event) {
                        $scope.isOpen = false;
                        $scope.$close(event);
                    }
                };
            }

            QuickDepositCtrl.$inject = [
                '$scope',
                '$rootScope',
                '$location',
                '$uibModal',
                'FindModalContainerFac'
            ];

            return QuickDepositCtrl;
        }
);

