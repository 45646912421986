'use strict';

define(
    'AccountChangePasswordCtrl',[
        'PasswordValidatorSvc',
        'UsernameValidatorSvc',
        'ProfileHelperSvc'
    ],
    function () {

        function AccountChangePasswordCtrl($rootScope, $scope, $uibModal, PasswordValidatorSvc, UsernameValidatorSvc, ProfileHelperSvc, $timeout, FindModalContainerFac) {

            $scope.events = {
                addUsernamePressed: _addUsernamePressed,
                changePasswordPressed: _changePasswordPressed,
                cancel: _cancel,
                checkPassword: _checkPassword,
                checkMatchingPassword: _checkMatchingPassword
            };

            $scope.data = {
                passwordPattern: /.+/,
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
                username: $rootScope.user.userName,
                usernameInput: "",
                loading: false,
                errorMessage: null,
                message: null,
                confirmDialog: null,
                shouldCloseOnMessage: false,
                invalidPasswordMessage: "",
                validPasswordPattern: true,
                isPasswordRuleValid: {
                    ruleLength: null,
                    ruleLowercase: null,
                    ruleCapital: null,
                    ruleNumber: null,
                    ruleSpecialChar: null,
                    ruleRepeatingChar: null,
                    ruleMatchPassword: null
                }
            };

            function _changePasswordPressed(formValid) {
                var oldPwd = $scope.data.oldPassword;
                var newPwd = $scope.data.newPassword;
                var confirmPwd = $scope.data.confirmPassword;

                //when buttons are disabled, they can not respond the click action
                if (!formValid || !$scope.data.validPasswordPattern ||
                    !$scope.data.isPasswordRuleValid.ruleMatchPassword) {
                    return;
                }

                if (!formValid || $scope.data.loading) {
                    PasswordValidatorSvc.validateEmptyPasswords(newPwd, confirmPwd, oldPwd, _showErrorMessage);
                    return;
                }

                if (!PasswordValidatorSvc.validatePasswords(newPwd, confirmPwd, oldPwd, _showErrorMessage)) {
                    _handleFailedPasswordChangeValidations();
                    return;
                }

                $scope.data.loading = true;

                ProfileHelperSvc.changePassword(oldPwd, newPwd, confirmPwd).then(function (response) {
                    _handleSuccessfulPasswordChange(response);
                }, function (response) {
                    _handleUnsuccessfulPasswordChange(response);
                });
            }

            function _handleFailedPasswordChangeValidations() {
                _setPristine();
                $scope.data.username = $rootScope.user.userName;
            }

            function _handleSuccessfulPasswordChange(response) {
                $scope.data.loading = false;
                $scope.data.shouldCloseOnMessage = true;
                _showMessage(response.message);
                _setPristine();
            }

            function _handleUnsuccessfulPasswordChange(error) {
                $scope.data.loading = false;
                _setPristine();
                _showErrorMessage(error.message);
            }

            function _checkPassword(password) {
                PasswordValidatorSvc.fillPasswordValidations(password, $scope.data.isPasswordRuleValid);
                $scope.data.validPasswordPattern = $scope.data.isPasswordRuleValid.validPasswordPattern;
            }

            function _checkMatchingPassword(currentPwd, newPwd) {
                $scope.data.isPasswordRuleValid.ruleMatchPassword =
                    PasswordValidatorSvc.arePasswordsMatching(currentPwd, newPwd);
            }

            function _addUsernamePressed(formValid) {

                var username = $scope.data.username;
                var newPwd = $scope.data.newPassword;
                var confirmPwd = $scope.data.confirmPassword;
                var accountId = $rootScope.user.accountNumber;

                if (!formValid || $scope.data.loading) {
                    UsernameValidatorSvc.validateEmptyUsernameToAdd(username, newPwd, confirmPwd, _showErrorMessage);
                    return;
                }

                if (!UsernameValidatorSvc.validateUsernameToAdd(username, newPwd, confirmPwd, _showErrorMessage)) {
                    return;
                }

                $scope.data.loading = true;

                ProfileHelperSvc.addUsername(accountId, username, newPwd).then(function (response) {
                    _handleSuccessfulAddedOrUpdatedUsername(response);
                }, function (response) {
                    _handleUnsuccessfulAddedOrUpdatedUsername(response);
                });
            }

            function _handleSuccessfulAddedOrUpdatedUsername(response) {
                $scope.data.loading = false;
                $scope.data.shouldCloseOnMessage = true;
                _showMessage(response.message);
            }

            function _handleUnsuccessfulAddedOrUpdatedUsername(error) {
                $scope.data.loading = false;
                _showErrorMessage(error.message);
            }

            function _setPristine() {
                $scope.changePasswordFrm.oldPassword.$setPristine();
                $scope.changePasswordFrm.newPassword.$setPristine();
                $scope.changePasswordFrm.confirmPassword.$setPristine();
                $scope.data.username = "";
                $scope.data.oldPassword = "";
                $scope.data.newPassword = "";
                $scope.data.confirmPassword = "";
            }

            function _showMessage(message) {
                $scope.data.message = message;

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Account/templates/change-pin-password-success.html',
                    scope: $scope
                });
            }

            function _showErrorMessage(message) {
                $scope.data.errorMessage = message;
                $scope.data.errorMessageTitle = "Change Password Failed";

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Login/templates/login-error.html',
                    scope: $scope
                });
            }

            function _cancel() {
                if ($scope.data.confirmDialog) {
                    $scope.data.confirmDialog.close();
                    $scope.data.confirmDialog = null;
                }
                if ($scope.data.shouldCloseOnMessage == true) {
                    $scope.pop();
                }

                $timeout(function () {
                    $scope.data.errorMessage = null;
                }, 300);
            }
        }

        AccountChangePasswordCtrl.$inject = [
            '$rootScope',
            '$scope',
            '$uibModal',
            'PasswordValidatorSvc',
            'UsernameValidatorSvc',
            'ProfileHelperSvc',
            '$timeout',
            'FindModalContainerFac'
        ];

        return AccountChangePasswordCtrl;
    }
);

