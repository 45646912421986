'use strict';

define('BrowserCheckFac',[],
    function () {
        function BrowserCheckFac($window, $filter) {

            /**
             * @TODO this should be moved to common utilities!
             *
             * Check if the user is using Safari
             * @return {Boolean} Whether it's Safari
             */
            function _isSafari() {
                return typeof $window.navigator !== 'undefined' &&
                    /Version\/[\d\.]+.*Safari/.test($window.navigator.userAgent);
            }

            function _getSafariBlockedPopupsText() {
                return $filter('CMSValue')('safariBlockedPopups') ||
                    'Your browser may be blocking popups.\n' +
                    'Please check your browser preferences and disable the "Block pop-up windows" option.';
            }

            return {
                isSafari: _isSafari,
                getSafariBlockedPopupsText: _getSafariBlockedPopupsText
            };
        }

        BrowserCheckFac.$inject = [
            '$window',
            '$filter'
        ];

        return BrowserCheckFac;
    });

