'use strict';

define('RaceMTPFilter',[
        'RaceMTP'
    ],
    function (RaceMTP) {

        function RaceMTPFilter(input) {
            if (!angular.isDate(input)) {
                return input;
            }

            var raceMTP = new RaceMTP(input);
            return raceMTP.getDisplayableTime();
        }

        return function () {
            return RaceMTPFilter;
        };

    });

