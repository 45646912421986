'use strict';

define(
    'RaceTodayBuilder',[
        "RaceTodayEntity"
    ],
    function (RaceTodayEntity) {

        function builder() {
            var raceTodayEntity = new RaceTodayEntity();

            return {
                withId: function (id) {
                    raceTodayEntity.id = id;
                    return this;
                },
                withName: function (name) {
                    raceTodayEntity.name = name;
                    return this;
                },
                withType: function (type) {
                    raceTodayEntity.type = type;
                    return this;
                },
                withTime: function (time) {
                    raceTodayEntity.time = time;
                    return this;
                },
                withTrackName: function (trackName) {
                    raceTodayEntity.trackName = trackName;
                    return this;
                },
                withTrackAbbr: function (trackAbbr) {
                    raceTodayEntity.trackAbbr = trackAbbr;
                    return this;
                },
                withRaceNumber: function (raceNumber) {
                    raceTodayEntity.raceNumber = raceNumber;
                    return this;
                },
                withMtp: function (mtp) {
                    raceTodayEntity.mtp = mtp;
                    return this;
                },
                build: function () {
                    return raceTodayEntity;
                }
            };
        }

        return builder;
    }
);

