'use strict';

define(
    'ComingSoonCtrl',['Content'],
    function () {

        function ComingSoonCtrl($scope, $rootScope) {
            $scope.slides = $rootScope.comingSoonFeatures;
            $scope.$watch('$root.comingSoonFeatures', function (newValue){
                $scope.slides = newValue;
            });
            $scope.$emit('pageLoad', 'coming-soon');
        }

        ComingSoonCtrl.$inject = [
            '$scope',
            '$rootScope'
        ];

        return ComingSoonCtrl;
    }
);

