'use strict';

define('TvgAutocompleteCtrl',['lodash'],
    function (_) {

        TvgAutoCompleteCtrl.$inject = [
            '$scope',
            '$timeout'
        ];

        /***
         * This Controller is bound to the tvg-autocomplete directive
         * @constructor
         */
        function TvgAutoCompleteCtrl($q) {

            var vm = this;
            vm.events = {
                getDataSource: function (searchText) {
                    return _performSearch(searchText);
                },
                showMinimumCharMessage: function () {
                    return vm.tValue.length < vm.tMinLength;
                },
                showNoResultsMessage: function () {
                    return !vm.events.showMinimumCharMessage() && vm.model.noResults;
                },
                shouldShowTooltip: function () {
                    vm.events.setMessage();
                    return vm.model.focus && (vm.autoCompleteSearchForm && vm.autoCompleteSearchForm.autocomplete.$dirty === true)
                        && (vm.events.showMinimumCharMessage() || vm.events.showNoResultsMessage());
                },
                setMessage: function () {
                    vm.model.message = "";
                    if (vm.events.showMinimumCharMessage()) {
                        vm.model.message = vm.tMinimumCharMessage;
                    } else if (vm.events.showNoResultsMessage()) {
                        vm.model.message = vm.tNoResultsMessage;
                    }
                },
                onSubmit: function () {
                    if (vm.events.showMinimumCharMessage()) {
                        return;
                    }
                    vm.tOnSubmit({ searchText: vm.tValue });
                }
            };

            vm.model = {
                loading: false,
                noResults: false,
                message: "",
                focus: false,
                blur: true
            };

            var defaults = {
                delay: 500,
                minLength: 3,
                placeholder: "Type your search"
            };

            (function init() {
                //apply defaults
                vm.tDelay = _.isNumber(vm.tDelay) ? vm.tDelay : defaults.delay;
                vm.tMinLength = _.isNumber(vm.tMinLength) ? vm.tMinLength : defaults.minLength;
                vm.tDataSource = _.isArray(vm.tDataSource) ? vm.tDataSource : false;
                vm.tPlaceholder = _.isString(vm.tPlaceholder) ? vm.tPlaceholder : defaults.placeholder;
            }());

            function _performSearch(inputText) {
                var defer = $q.defer();

                if (_.isArray(vm.tDataSource)) {
                    inputText = inputText.toLowerCase();
                    var items = vm.tDataSource.filter(function (item) {
                        return item.toLowerCase().indexOf(inputText) > -1;
                    });
                    defer.resolve(items);
                }
                else {
                    vm.tCustomSearchFunction({ inputText: inputText })
                        .then(function (items) {
                            defer.resolve(items);
                        }, function () {
                            defer.reject();
                        });
                }

                return defer.promise;
            }

        }

        TvgAutoCompleteCtrl.$inject = [
            "$q"
        ];

        return TvgAutoCompleteCtrl;
    }
);

