"use strict";

define('PollingScheduler',[],function () {

    function PollingScheduler(intervalMs, callback) {
        this.intervalMs = intervalMs;
        this.intervalId = undefined;
        this.intervalCallback = callback;
    }

    PollingScheduler.prototype.cancel = function () {
        if (typeof this.intervalId === 'undefined') {
            return;
        }
        clearInterval(this.intervalId);
    };

    PollingScheduler.prototype.start = function () {
        this.cancel();
        this.intervalId = setInterval(this.intervalCallback, this.intervalMs);
    };

    return PollingScheduler;
});

