'use strict';

define('ModalWindow',[
        'NewWindowDir',
        'ResizableDir',
        'DraggableDir',
        'ResizableCustomDir',
        'ModalPersistentCtrl',
        'GTMMod'
    ],
    function (NewWindowDir,
              ResizableDir,
              DraggableDir,
              ResizableCustomDir,
              ModalPersistentCtrl) {

        angular.module('TVG.ModalWindow', [
            'TVG.Common.GTM'
        ])

            .controller('ModalPersistentCtrl', ModalPersistentCtrl)

            .directive('newWindow', NewWindowDir)
            .directive('resizable', ResizableDir)
            .directive('draggable', DraggableDir)
            .directive('modalWindow', ResizableCustomDir);
    }
);

