'use strict';

define('RegistrationPageGTMFac',['lodash'], function (_) {

  function RegistrationPageGTMFac(EventEmitterService, GTMFac, $rootScope) {
    /* Common params to all registration events */
    var defaultParams = {
      gaEventCategory: 'Registration',
      registrationStatus: 'Unregistered'
    };

      var fieldLabel = {
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Email',
          password: 'Password',
          birthday: 'Date Of Birth',
          socialSecurity: 'SSN - Full',
          socialSecurity4Digits: 'SSN - 4 Digits',
          phone: 'Mobile Number',
          address: 'Street Address',
          addressSecondLine: 'Street Address Second Line',
          city: 'City',
          zipCode: 'Zip Code',
          state: 'State',
          SignalProviderAvailable: 'TVG Channel Provider',
          globalOptIn: 'TVG Email Updates',
          promoCode: 'Promo Code',
          mailingAddress: 'Mailing Address',
          mailingAddressSecondLine: 'Mailing Address Second Line',
          mailingCity: 'Mailing Address City',
          mailingZipCode: 'Mailing Address Zip Code',
          mailingState: 'Mailing Address State',
          signalProviderId: 'Cable Provider',
          samemailingaddr: 'My Mailing Address'
      };

    var errorLabel = {
        registrationBlockerInvalidWageringState: 'Non Wagering State',
        registrationDuplicateAccount: 'Duplicate Account',
        registrationUnverified: 'Unverified',
        registrationDuplicateEmailAsUsername: 'Duplicate Email as Username',
        registrationSSN4Failure: 'SSN Failure'
    };

    function _getFieldLabel (field) {
        return fieldLabel[field] || '';
    }

    function _getErrorLabel(error) {
        return errorLabel[error] || '';
    }

    function merge (evt) {
        var defaultObj = _.cloneDeep(defaultParams);
        defaultObj.registrationVersion = $rootScope.activeFeatures && $rootScope.activeFeatures.registrationV3 ? 'v3' : 'v2';

        // Remove undefined props
        return _(defaultObj)
          .merge(evt)
          .omitBy(_.isUndefined)
          .value();
    }


    function _gtmStartup () {
        var gtmEvent = {
            gaEventAction: 'Registration Sign Up Start',
            eventLabel: 'SignupStart',
            gaEventLabel: '1'
        };

        GTMFac.GTMEvent().send($rootScope, 'registrationStart', merge(gtmEvent));
    }

    function _sendFieldGTMEvent (params) {
      var label = '';
      var field;
      var evtLabel;
      var gaEventLabel;
      var gtmEvent;
      var eventName;
      var onOffFields = ['SignalProviderAvailable', 'samemailingaddr', 'globalOptIn'];
      var valid = params.isFormValid;

      if (_.indexOf(onOffFields, params.fieldName) > -1) {
        label = ' - '.concat(params.fieldValue ? 'ON' : 'OFF');
      }

      field = _getFieldLabel(params.fieldName);
      evtLabel = field.replace(/ /g, '');
      gaEventLabel = (valid ? '' : 'Error filling ')
        .concat(field.concat(label.replace(/ -/g, '')));
      eventName = valid ? 'registrationSingleStep' : 'registrationError';

      gtmEvent = {
        eventLabel: 'RegFieldFill-'.concat(evtLabel, label.replace(/ /g, '')),
        gaEventAction: valid ? 'Reg Field Fills' : 'Registration Error',
        gaEventLabel: gaEventLabel,
        promoCode: params.promoCode
      };

      if(!valid) {
          gtmEvent.errorType = 'Registration Error';
          gtmEvent.errorMessage = params.errorMessage;
          gtmEvent.gaEventLabel = params.errorMessage;
          gtmEvent.eventLabel = gtmEvent.eventLabel.concat('Failed');
      }

      GTMFac.GTMEvent().send($rootScope, eventName, merge(gtmEvent));
    }

    function _promoCodeErrorDisplay(params) {
      var gtmEvent = {
        gaEventLabel: 'Promo Code is invalid',
        eventLabel: 'PromoErrorDisplay',
        gaEventAction: 'Registration Error',
        promoCode: params.promoCode
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(gtmEvent));
    }

    function _changePromoCodeEvent(promoCode) {
      var evt = {
        eventLabel: 'PromoChangeCode',
        gaEventAction: 'Promo Change Code',
        promoCode: promoCode,
        gaEventLabel: undefined
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(evt));
    }

    function _cancelToChangePromoCode(promoCode) {
      var evt = {
        eventLabel: 'CancelToChangePromoCode',
        gaEventAction: 'Registration Cancel',
        gaEventLabel: 'Cancel To Change Promo Code',
        promoCode: promoCode
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(evt));
    }

    function _showTermsAndConditions (promoCode) {
      var evt = {
        eventLabel: 'TermsAndConditions',
        gaEventLabel: 'Terms And Conditions',
        gaEventAction: 'Registration Click',
        promoCode: promoCode
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(evt));
    }

    function _showPrivacyPolicy(promoCode) {
      var evt = {
        eventLabel: 'PrivacyPolicy',
        gaEventLabel: 'Privacy Policy',
        gaEventAction: 'Registration Click',
        promoCode: promoCode
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(evt));
    }

    function _showHidePassword(params) {
      var evt = {
        eventLabel: params.eventLabel.concat('Password'),
        gaEventAction: 'Registration Click',
        gaEventLabel: params.eventLabel.concat(' ', 'Password'),
        promoCode: params.promoCode
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(evt));
    }

    function _cableSatProviderPref(params) {
      var evt = {
        eventLabel: 'CableSatProviderCommPref-' + params.eventLabel,
        gaEventLabel: 'Cable Sattelite Provider and Comm Preferences ' + params.eventLabel,
        gaEventAction: 'Registration Click',
        promoCode: params.promoCode
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(evt));
    }

    function _addressAutoComplete (params) {
      var evt = {
        eventLabel: 'RegFieldFill-AdressAutoComplete',
        gaEventAction: 'Reg Place Search',
        gaEventLabel: 'Google Place API Help',
        promoCode: params.promoCode
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(evt));
    }

    function _SSN4Error(params) {
      var message = 'We were unable to verify your identity with your last 4, please enter your full 9 digits SSN';
      var evt = {
          errorType: 'Registration Error',
          gaEventAction: 'Registration Error',
          gaEventLabel: message,
          errorMessage: message,
          eventLabel: 'RegFieldFill-4SSNFailed',
          promoCode: params.promoCode
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationError', merge(evt));
    }

    function _zipCodeInvalidWageringStatePopup(params) {
      var evt = {
          gaEventAction: 'Registration Popup',
          gaEventLabel:'Non Wagering State Popup '.concat(params.label),
          eventLabel: 'NonWageringStatePopup-'.concat(params.label),
          promoCode: params.promoCode
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(evt));
    }

    function _zipCodeDomain(params) {
      var evt = {
          gaEventAction: 'Registration Popup',
          gaEventLabel: 'Domain Popup '.concat(params.label),
          eventLabel:'DomainPopup-'.concat(params.label),
          promoCode: params.promoCode
      };

      GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(evt));
    }

    function _nonWageringState(params) {
      var evt = {
          gaEventAction: 'Registration Finish',
          gaEventLabel: 'Registration Non Wagering State',
          eventLabel: 'RegNonWageringState',
          sectionName: 'Registration|Confirmation',
          promoCode: params.promoCode
      };

      GTMFac.GTMEvent().send(
         $rootScope,
         'registrationSingleStep',
         merge(evt)
      );
    }

    function _duplicateEmail (params) {
        var registrationFailEvt = {
            eventLabel: 'RegDuplicateEmailAsUsername',
            gaEventAction: 'Registration Finish',
            gaEventLabel: 'Registration Duplicate Email As Username',
            promoCode: params.promoCode,
            sectionName: 'Registration | Confirmation Page'
        };
        GTMFac.GTMEvent().send($rootScope, 'registrationFinish', merge(registrationFailEvt));
    }

    function _completeRegistrationButton (params) {
        var evt = {
            eventLabel: 'CreateAccountButton',
            gaEventAction: 'Create Account',
            promoCode: params.promoCode,
            gaEventLabel: undefined
        };

        GTMFac.GTMEvent().send($rootScope, 'registrationSingleStep', merge(evt));
    }

      function _registrationSuccess (params, promoCode) {
          var evt = {
              accountId: params.accountId,
              eventLabel: 'RegSuccess',
              residenceState: params.state,
              gaEventAction: 'Registration Finish',
              gaEventLabel: params.verified ? 'Registration Success Verified' : 'Registration Unverified',
              promoCode: promoCode,
              sectionName: 'Registration | Confirmation Page'
          };

          GTMFac.GTMEvent().send($rootScope, 'registrationFinish', merge(evt));
      }

      function _registrationFailed (params) {
          var evt = {
              errorType: 'Registration Error',
              errorMessage: params.content,
              gaEventCategory: 'Registration',
              gaEventAction: 'registrationFailure',
              gaEventLabel: params.content,
              promoCode: params.promoCode
          };

          GTMFac.GTMEvent().send($rootScope, 'registrationError', merge(evt));
      }

      function _registrationFinish (params) {
          var gtmLabel = _getErrorLabel(params.gtmLabel);
          var evt = {
              accountId: params.accountId,
              eventLabel: 'Reg'.concat(gtmLabel.replace(/ /g, '')),
              gaEventCategory: 'Registration',
              gaEventAction: 'Registration Finish',
              gaEventLabel: 'Registration '.concat(gtmLabel),
              promoCode: params.promoCode,
              sectionName: 'Registration | Confirmation Page'
          };

          GTMFac.GTMEvent().send($rootScope, 'registrationFinish', merge(evt));
      }

    return {
      register: function () {
          EventEmitterService.on('reg:signUpStart', _gtmStartup); //User lands in Registration and starts filling the form
          EventEmitterService.on('reg:RegFieldFill', _sendFieldGTMEvent); // Users fills a field in the form
          EventEmitterService.on('reg:promoCodeErrorDisplay', _promoCodeErrorDisplay); // Invalid promocode display
          EventEmitterService.on('reg:promoCodeChange', _changePromoCodeEvent); // User changes promocode
          EventEmitterService.on('reg:cancelToChangePromoCode', _cancelToChangePromoCode); // Clicked on 'cancel' to change promocode
          EventEmitterService.on('reg:showTermsAndConditions', _showTermsAndConditions); // User clicks on 'terms and conditions'
          EventEmitterService.on('reg:showPrivacyPolicy', _showPrivacyPolicy); // User clicked on 'privacy policy'
          EventEmitterService.on('reg:showHidePassword', _showHidePassword); // User clicked on show/hide password button
          EventEmitterService.on('reg:cableSatProviderPref', _cableSatProviderPref); // User collapses/expands cable satellite provider preferences
          EventEmitterService.on('reg:addressAutoComplete', _addressAutoComplete); // User fills address with Google Maps API
          EventEmitterService.on('reg:SSN4Error', _SSN4Error); // Error Filling SSN4
          EventEmitterService.on('reg:registrationSuccess', _registrationSuccess); // Registration Submitted with success
          EventEmitterService.on('reg:zipCodeInvalidWageringStatePopup', _zipCodeInvalidWageringStatePopup); // Popup
          // display for invalid wagering state
          EventEmitterService.on('reg:zipCodeDomain', _zipCodeDomain); // Domain popup display
          EventEmitterService.on('reg:nonWageringState', _nonWageringState); // Non wagering state
          EventEmitterService.on('reg:duplicateEmail', _duplicateEmail); // User inserts an already existing email
          EventEmitterService.on('reg:completedRegistration', _completeRegistrationButton); // User clicks on
          // complete registration button
          EventEmitterService.on('reg:registrationFailed', _registrationFailed); // Registration submit failed
          EventEmitterService.on('reg:registrationFinish', _registrationFinish); // Registration submit finished
          // with some error
      }
    };
  }

  RegistrationPageGTMFac.$inject = [
    'EventEmitterService',
    'GTMFac',
    '$rootScope'
  ];

  return RegistrationPageGTMFac;

});

