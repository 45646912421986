'use strict';

/**
 * A simple angular factory to interact with native html5 localStorage service.
 */
define('LocalStorageFac',[
        'angularUTF8Base64'
    ],
    function () {
        function LocalStorageFac(base64) {
            function _getItem(key) {
                var value;

                try {
                    value = localStorage.getItem(key);
                } catch(e) {
                    return null;
                }

                if (!value) {
                    return null;
                }

                return value;
            }

            function _setItem(key, value) {
                try {
                    localStorage.setItem(key, value);
                } catch (e) {
                    return false;
                }
            }

            function _removeItem(key) {
                return localStorage.removeItem(key);
            }

            function _setEncodedItem(key, value) {
                var encodedValue = base64.encode(value);
                _setItem(key, encodedValue);
            }

            function _getEncodedItem(key) {
                var value = _getItem(key);

                if (value) {
                    return base64.decode(value);
                }

                return null;
            }

            return {
                getItem: _getItem,
                setItem: _setItem,
                removeItem: _removeItem,
                getEncodedItem: _getEncodedItem,
                setEncodedItem: _setEncodedItem
            };
        }

        LocalStorageFac.$inject = [
            'base64'
        ];

        return LocalStorageFac;
    });

