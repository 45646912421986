'use strict';

define('MyTracksDir',[], function () {

    function MyTracksDir() {
        return {
            restrict: 'E',
            templateUrl: 'js/desktop/modules/Account/templates/desktop/my-tracks.html',
            controller: 'MyTracksCtrl'
        };
    }

    return MyTracksDir;
});

