'use strict';

// TODO - document the generated events
// TODO - organize the events code

define(
    'TrackPerformanceProvider',[],
    function () {

        function TrackPerformanceProvider($rootScope, $q, $timeout) {

            var trackPerformanceIds = {};
            var racesToListen = [];
            var trackPerformance = {};

            $rootScope.$on('newTrackData', onNewTrackData);

            function onNewTrackData(event, trackAbbr, trackData) {

                if (isTrackPerformanceDifferent(trackAbbr)) {
                    if (!_.isEmpty(trackPerformanceIds)) {
                        unlistenAllRaces();
                    }
                }

                emitAllEventsAsyncronously(trackAbbr, trackData);
            }

            function isTrackPerformanceDifferent(trackAbbr) {
                return trackAbbr != trackPerformanceIds.trackAbbr;
            }

            function updateTrackPerformanceId(trackAbbr) {
                trackPerformanceIds.trackAbbr = trackAbbr;
            }

            function unlistenAllRaces() {
                racesToListen = [];
            }

            function emitAllEventsAsyncronously(trackAbbr, trackData) {
                var deferreds = [];
                trackData.forEach(function (raceData) {
                    var deferred = $q.defer();
                    $timeout(function () {
                        $rootScope.$apply(function () {
                            deferred.resolve(emitRaceChanges(trackAbbr, raceData));
                        });
                    }, 0);
                    deferreds.push(deferred.promise);
                });
                $q.all(deferreds).then(function () {
                    updateTrackPerformanceId(trackAbbr);
                    updateTrackPerformance(trackData);
                    emitTrackDataUpdated();
                });
            }

            function updateTrackPerformance(trackData) {
                trackPerformance = trackData;
            }

            function emitRaceChanges(trackAbbr, raceData) {
                var raceId = getRaceId(trackAbbr, raceData.raceNumber);

                if (_.includes(racesToListen, raceId)) {
                    explodeToRaceChangeEvents(raceId, raceData);
                }
            }

            function explodeToRaceChangeEvents(raceId, raceData) {
                emitOnRaceClosedForBetting(raceId, raceData.closedForBetting);
                emitOnLateChanges(raceId, raceData.changes);
            }

            function emitOnLateChanges(raceId, changes) {
                emitOnHorseLateChanges(raceId, changes.horseChanges);
            }

            function emitOnHorseLateChanges(raceId, horseLateChanges) {
                var shouldEmitHorseChanges = false;

                var raceNumber = getRaceNumberFromRaceId(raceId);


                if (!_.isEmpty(trackPerformance)) {
                    var race = _getRace(raceNumber);

                    var previousHorseChanges = race.changes.horseChanges;
                    shouldEmitHorseChanges = isAnyLateChanges(previousHorseChanges, horseLateChanges);
                } else if (horseLateChanges.length > 0) {
                    shouldEmitHorseChanges = true;
                }

                emitHorseChanges(raceId, horseLateChanges, shouldEmitHorseChanges);
            }

            function emitOnRaceClosedForBetting(raceId, isClosedForBetting) {
                if (isClosedForBetting) {
                    $rootScope.$emit('raceIsClosedForBetting/' + raceId);
                }
            }

            function emitTrackDataUpdated() {
                $rootScope.$emit('trackDataUpdated');
            }

            function emitHorseChanges(raceId, horseLateChanges, shouldEmitHorseChanges) {
                if (shouldEmitHorseChanges) {
                    $rootScope.$emit('newHorseLateChanges/' + raceId, horseLateChanges);
                }
            }

            function isAnyLateChanges(previousChanges, newChanges) {
                return previousChanges.length !== newChanges.length ||
                    _.difference(previousChanges, newChanges).length > 0;
            }

            // TODO - remove the bellow from here
            function getRaceId(trackAbbr, raceNumber) {
                return trackAbbr + '/' + raceNumber;
            }

            function getRaceNumberFromRaceId(raceId) {
                var lastIndex = raceId.lastIndexOf('/');
                return raceId.substring(lastIndex + 1);
            }

            function byRaceNumber(raceNumber) {
                return function (race) {
                    return parseInt(race.raceNumber, 10) === parseInt(raceNumber, 10);
                };
            }

            function _listenRace(trackAbbr, raceNumber) {

                var raceId = getRaceId(trackAbbr, raceNumber);
                if (!_.includes(racesToListen, raceId)) {
                    racesToListen.push(raceId);
                }
            }

            function _getRace(raceNumber) {
                return angular.copy(_.find(trackPerformance, byRaceNumber(raceNumber)));
            }

            function _getWagerTypes(raceNumber) {
                var wagerTypes = [];

                var race = _getRace(raceNumber);
                if (race) {
                    // TODO - remove this filter to support more wager types
                    wagerTypes = race.wagerTypes;
                }

                return wagerTypes;
            }

            function _getRacesList() {
                return trackPerformance;
            }

            return {
                listenRace: _listenRace,
                getRace: _getRace,
                getWagerTypes: _getWagerTypes,
                getRacesList: _getRacesList,
                isTrackPerformanceDifferent: isTrackPerformanceDifferent
            };
        }

        TrackPerformanceProvider.$inject = [
            '$rootScope',
            '$q',
            '$timeout'
        ];

        return TrackPerformanceProvider;
    }
);

