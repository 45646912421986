'use strict';

define('MyBetsCurrentRaceInputBuilder',['MyBetsCurrentRaceInput'], function (MyBetsCurrentRaceInput) {

    function builder(){
        var myBetInput = new MyBetsCurrentRaceInput();

        return {
            withTrackAbbr: function (trackAbbr) {
                myBetInput.trackAbbr = trackAbbr;
                return this;
            },
            withPerfAbbr: function (perfAbbr) {
                myBetInput.perfAbbr = perfAbbr;
                return this;
            },
            withRaceNumber: function (raceNumber) {
                myBetInput.raceNumber = raceNumber;
                return this;
            },
            withRaceStatus: function (raceStatus) {
                myBetInput.raceStatus = raceStatus;
                return this;
            },
            withTrackName: function (trackName) {
                myBetInput.trackName = trackName;
                return this;
            },
            withPostTime: function (postTime) {
                myBetInput.postTime = postTime;
                return this;
            },
            withTvgRaceId: function(tvgRaceId){
               myBetInput.tvgRaceId = tvgRaceId;
               return this; 
            },
            build: function () {
                return myBetInput;
            }
        };
    }

    return builder;

});


