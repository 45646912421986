'use strict';

define('GTMFac',['lodash', 'angular'], function (_, angular) {

    function GTMFac(
        $location,
        $rootScope,
        $routeParams,
        ScreenNames,
        SectionNames,
        METADATA,
        RaceUrlFac,
        $cookies,
        LocalStorageFac
    ) {

        var talentPicksList;
        var isIncognito = false;
        _isPrivateBrowser();

        function _formatGALAbelUpperCase(input) {
            if (input) {
                var result = input.replace(/([A-Z]+)/g, ",$1").replace(/^,/, "");

                return result.replace(/,/g, ' ').replace(/\b\w/g, function (m) {
                    return m.toUpperCase();
                });
            } else {
                return '';
            }
        }

        var _GAPageviews = function () {
            var sendCondition = true;
            var pageviewTitle = '';

            return {
                addPageViewTitle: function (name) {
                    pageviewTitle = name;
                    return this;
                },
                when: function (condition) {
                    if (sendCondition) {
                        sendCondition = condition;
                    }
                    return this;
                },
                send: function () {
                    if (sendCondition && typeof ga !== 'undefined') {
                        ga('send', 'pageview', {
                            title: pageviewTitle
                        });
                        return pageviewTitle;
                    } else {
                        return '';
                    }
                }
            };
        };

        var _GTMEvent = function () {
            var sendCondition = true;
            return {
                when: function (condition) {
                    if (sendCondition) {
                        sendCondition = condition;
                    }
                    return this;
                },
                send: function (scope, _eventName, aditionalParams, sendEvent) {
                    if (sendCondition) {
                        return sendGTMEvent(scope, _eventName, aditionalParams, sendEvent);
                    } else {
                        return false;
                    }
                }
            };
        };

        var _genericEvent = function (eventData) {
            var event = _EventBuilder()
                .withGaEventCategory(eventData.category)
                .withGaEventAction(eventData.action)
                .withGaEventLabel(eventData.gaLabel)
                .withEventLabel(eventData.label)
                .withTag(eventData.tag)
                .withModule(eventData.module)
                .withMicroApp(eventData.microApp)
                .withScreenName(_tvg4ScreenName())
                .withSectionName(_tvg4SectionName())
                .build();

            var track = $routeParams.trackAbbr || $routeParams.trackName;

            if (track && $routeParams.race) {
                event.trackName = $routeParams.trackName || $routeParams.trackAbbr;

                var raceNumber = $routeParams.race;
                if (typeof raceNumber === 'string') {
                    raceNumber = raceNumber.replace('race=','');
                }
                event.raceNumber = "R" + raceNumber;
            }

            return event;
        };

        function _EventBuilder() {
            var event = {};
            return {
                withScreenName: function(screenName) {
                    event.screenName = screenName;
                    return this;
                },
                withSectionName: function(sectionName) {
                    event.sectionName = sectionName;
                    return this;
                },
                withGaEventAction: function (gaEventAction) {
                    event.gaEventAction = gaEventAction;
                    return this;
                },
                withModule: function (module) {
                    event.module = module;
                    return this;
                },
                withTag: function (tag) {
                    event.tag = tag;
                    return this;
                },
                withMicroApp: function (microApp) {
                    event.microApp = microApp;
                    return this;
                },
                withGaEventCategory: function (gaEventCategory) {
                    event.gaEventCategory = gaEventCategory;
                    return this;
                },
                withGaEventLabel: function (gaEventLabel) {
                    event.gaEventLabel = gaEventLabel;
                    return this;
                },
                withEventLabel: function (eventLabel) {
                    event.eventLabel = eventLabel;
                    return this;
                },
                withRaceNumber: function (raceNumber) {
                    event.raceNumber = 'R' + raceNumber;
                    return this;
                },
                withTrackName: function (trackName) {
                    event.trackName = trackName;
                    return this;
                },
                withBetName: function(betName) {
                    event.betName = betName;
                    return this;
                },
                withBetAmount: function(betAmount) {
                    event.betAmount = betAmount;
                    return this;
                },
                withBetType: function(betType) {
                    event.betType = betType;
                    return this;
                },
                withRunnerSelectionList: function(runnerSelectionList) {
                    event.runnerSelectionList = runnerSelectionList;
                    return this;
                },
                build: function () {
                    return event;
                }
            };
        }

        function _gaEventBuilder() {
            var gaData = {};
            return {
                withGaEventAction: function (gaEventAction) {
                    gaData.action = gaEventAction;
                    return this;
                },
                withGaEventCategory: function (gaEventCategory) {
                    gaData.category = gaEventCategory;
                    return this;
                },
                withModule: function (module) {
                    gaData.module = module;
                    return this;
                },
                withTag: function (tag) {
                    gaData.tag = tag;
                    return this;
                },
                withMicroApp: function (microApp) {
                    gaData.microApp = microApp;
                    return this;
                },
                withSectionName: function (sectionName) {
                    gaData.sectionName = sectionName;
                    return this;
                },
                withScreenName: function (screenName) {
                    gaData.screenName = screenName;
                    return this;
                },
                withGaEventLabel: function (gaEventLabel) {
                    gaData.gaLabel = gaEventLabel;
                    return this;
                },
                withEventLabel: function (eventLabel) {
                    gaData.label = eventLabel;
                    return this;
                },
                withDestinationUrl: function (destinationUrl) {
                    gaData.destinationUrl = destinationUrl;
                    return this;
                },
                build: function () {
                    return gaData;
                }
            };
        }

        /**
         *
         * @param {type} s scope or rootScope param
         * @param {type} en event name
         * @returns {object} defaulParams
         */
        function _baseParams(s, en) {
            var eventParams = {
                event: en,
                customerStatus: $rootScope.hasInitialDeposit,
                page: $location.path(),
                sport: $location.path().indexOf('greyhounds') > 0 ? 'Greyhounds Racing' : $location.path().indexOf('racetracks') > 0 ? 'Horse Racing' : undefined,
                accountId: s.userSession && s.user ? s.user.accountNumber : '',
                residenceState: s.userSession && s.user && s.user.homeAddress ? s.user.homeAddress.StateAbbr : '',
                loginStatus: s.userSession ? 'Logged In' : 'Logged Out',
                registrationStatus: s.hasNeverLogin ? 'Unregistered': 'Registered',
                screenName: _tvg4ScreenName(),
                sectionName: _tvg4SectionName(),
                siteVersion: 'desktop',
                productVersion: 'TVG4',
                privateBrowser: isIncognito ? 'Yes' : 'No'
            };

            if (eventParams.screenName === 'Race') {
                eventParams.screenName = 'Races';
            }

            if (eventParams.page === '/handicapping/handicapping-store' && $rootScope.activeFeatures.equibaseHandicapStore) {
                eventParams.storeVersion = 'v2';
            }

            var promoCode = LocalStorageFac.getItem("promoCode");

            if (s.userSession && eventParams.page.indexOf("/betslip") !== 0 && eventParams.page.indexOf("/registration") !== 0) {
                if (promoCode) {
                    eventParams.promoCode = promoCode;
                    LocalStorageFac.removeItem("promoCode");
                } else {
                    eventParams.promoCode = undefined;
                }
            }

            return eventParams;
        }

        function sendGTMEvent(scope, _eventName, aditionalParams, sendEvent) {
            sendEvent = typeof sendEvent !== 'undefined' ? sendEvent : true;
            // only in this event we should add rfr field
            var nonNJXeventsWithRfr = [
                'registrationError',
                'registrationFinish',
                'registrationStart',
                'registrationSingleStep'
            ];

            if ($rootScope.enableGTM && sendEvent) {
                var finalParams = _baseParams(scope, _eventName);
                if (typeof aditionalParams === 'object') {
                    finalParams = angular.extend(finalParams, aditionalParams);
                }

                if (_.includes(nonNJXeventsWithRfr, _eventName)) {
                    var rfrCookie = $cookies.get('RFR');
                    _.set(finalParams, "rfr", rfrCookie);
                }

                if (window.parent.dataLayer) {
                    window.parent.dataLayer.push(finalParams);
                }
                return finalParams;
            } else {
                return '';
            }
        }

        function _isTVG4() {
            return $rootScope.layout === 'desktop';
        }

        function _isTalentPick(currentAmount, currentSelectedBetTypeId) {
            var _pickBettype = $location.search().bettype;
            var _pickBetamount = $location.search().betamount;
            var _pickSelections = $location.search().betselections;
            var talentPicksListFormatted = '';

            if (talentPicksList && currentAmount && currentSelectedBetTypeId) {
                talentPicksList.forEach(function (entry) {
                    talentPicksListFormatted += entry + ';';
                });
                if (talentPicksListFormatted) {
                    talentPicksListFormatted = talentPicksListFormatted.substring(0, talentPicksListFormatted.length - 1);
                }
            }

            if (talentPicksListFormatted === _pickSelections && _pickBettype.toString() === currentSelectedBetTypeId.toString() && parseFloat(_pickBetamount).toFixed(2) === parseFloat(currentAmount).toFixed(2)) {
                return true;
            } else {
                return false;
            }
        }

        function _setCurrentSelectionList(val) {
            talentPicksList = val;
        }


        function _getScreenName(name) {
            switch (name) {
                case 'funds':
                    screenName = 'My Funds';
                    break;
                case 'wallet':
                  screenName = 'My Wallet';
                  break;
                case 'deposit':
                  screenName = 'Deposit';
                  break;
                case 'withdraw':
                  screenName = 'Withdraw';
                  break;
                case 'my-bets':
                    screenName = 'My Bets';
                    break;
                case 'my-account-summary':
                    screenName = 'My Account Summary';
                    break;
                case 'account':
                    screenName = 'My Account';
                    break;
                case 'handicapping-store':
                    screenName = 'Handicapping Store';
                    break;
                case 'free-pp':
                    screenName = 'Free Past Performances';
                    break;
                case 'talentpicks':
                    screenName = 'TVG Picks';
                    break;
                case 'my-tracks':
                    screenName = 'My Tracks';
                    break;
                case 'my-stable':
                    screenName = 'My Stable';
                    break;
                default:
                    screenName = name;
            }
            var screenName;
            return screenName;
        }

        function _tvg4SectionNameByURLSharp (name, params) {
            var parts = _URLParse(name),
                section = parts.pathname.replace('/', ''),
                hash = parts.hash.replace('#', ''),
                splitHash = hash.split('+'),
                translation = [section, 'translation'].join('.'),
                firstSection = _.isEmpty(splitHash[0]) ? '' : [section, splitHash[0]].join('.'),
                sectionName = _.get(SectionNames, translation),
                fullSectionName;

                if (splitHash.length > 1) {
                    var secondSection = [section, splitHash[1]].join('.');
                    fullSectionName = [sectionName, _.get(SectionNames, firstSection, ''), _.get(SectionNames, secondSection, '')].join(' | ');
                } else {
                    fullSectionName = _.isEmpty(firstSection) ? sectionName : [sectionName, _.get(SectionNames, firstSection, '')].join(' | ');
                }

            return _.isEmpty(params) ? fullSectionName : fullSectionName.concat(' | ').concat(params.sectionName);

        }

        function _URLParse(url) {
            var parser = document.createElement('a');
            parser.href = url;

            return {
                pathname: parser.pathname,
                hash: parser.hash
            };
        }

        function _tvg4SectionNameByURLToken (key, name, params) {
            var sectionName;

            if (_.isString(name) && !_.isEmpty(name) && SectionNames[key][name]) {
                sectionName = SectionNames[key].translation + ' | ' + SectionNames[key][name];
            } else {
                sectionName = SectionNames[key].translation;
            }

            if (!_.isEmpty(params) && !_.isEmpty(params.sectionName)) {
                sectionName += ' | ' + params.sectionName;
            }

            return sectionName;
        }

        function _tvg4SectionNameTrackName (key, params) {
            var sectionName = SectionNames[key].translation,
                track = params.trackName,
                raceNumber = params.race;

            if (_.isString(track) && !_.isEmpty(track) && METADATA.hasOwnProperty('allTracks')) {
                var trackName = _.isUndefined(METADATA.allTracks[track]) ? track : METADATA.allTracks[track].Name;
                if (typeof raceNumber === 'string') {
                    raceNumber = raceNumber.replace('race=','');
                }
                sectionName += ' | ' + trackName + ' | R' + raceNumber;
            }

            if (!_.isEmpty(params) && !_.isEmpty(params.sectionName)) {
                sectionName += ' | ' + params.sectionName;
            }

            return sectionName;
        }

        function _tvg4SectionNameOnlyParams (key, params) {
            var sectionName = SectionNames[key].translation;

            if (_.isUndefined(params) || _.isEmpty(params)) {
                sectionName += ' | ' + SectionNames[key].default;
            } else {
                sectionName += ' | ' + params.sectionName;
            }

            return sectionName;
        }

        function _getURLForRetroCompatibleGTM() {
            var url = $location.url();

            if (url.indexOf('my-funds/deposit') > -1) {
                return '/my-profile#funds+deposit';
            }
            else if (url.indexOf('my-funds/bank') > -1) {
                return '/my-profile#funds+bank';
            }
            else if (url.indexOf('my-funds/withdraw') > -1) {
                return '/my-profile#funds+withdraw';
            }

            return url;
        }

        function _tvg4SectionName (params) {
            var url = _getURLForRetroCompatibleGTM().split('/');
            var urlToken = (url[1].indexOf('?') > -1) ? url[1].split('?')[0] : url[1];

            if (urlToken.indexOf('#') > -1) {
                // TODO add this to the config object

                if (urlToken.indexOf('favorites') > -1 && _.isEmpty(params)) {
                    params = {
                        sectionName: 'All Tracks'
                    };
                }

                return _tvg4SectionNameByURLSharp(urlToken, params);
            }
            else {
                 var defaultParam;
                 var name;
                 switch (urlToken) {
                     case 'racetracks':
                         defaultParam = angular.extend({}, params,
                             RaceUrlFac.getRouteParamsForRaceBasedOnLocationPath());
                         return _tvg4SectionNameTrackName('programpage', defaultParam);
                     case 'greyhounds':
                         defaultParam = angular.extend({}, params,
                             RaceUrlFac.getRouteParamsForRaceBasedOnLocationPath());
                         return _tvg4SectionNameTrackName('programpage', defaultParam);
                     case 'forgot-credentials':
                         return _tvg4SectionNameByURLToken('forgot-credentials', null, params);
                     case 'reset-credentials':
                         return _tvg4SectionNameByURLToken('reset-credentials', null, params);
                     case 'password-recovery':
                         return _tvg4SectionNameByURLToken('password-recovery', null, params);
                    case 'registration':
                        return _tvg4SectionNameByURLToken('registration', null, params);
                     case 'info':
                         return _tvg4SectionNameByURLToken('siteinfo', url[2], params);
                     case 'races':
                         defaultParam = {
                             sectionName: 'By Time'
                         };
                         return _tvg4SectionNameByURLToken('horseracing', urlToken, _.isUndefined(params) || _.isEmpty(params) ? defaultParam : params);
                     case 'results':
                         defaultParam = {
                             sectionName: 'By Time'
                         };
                         return _tvg4SectionNameByURLToken('horseracing', urlToken, _.isUndefined(params) || _.isEmpty(params) ? defaultParam : params);
                     case 'betslip':
                         return _tvg4SectionNameOnlyParams('betslip', params);
                     case '':
                         return _tvg4SectionNameByURLToken('home', null, params);
                     case 'my-profile':
                         return _tvg4SectionNameByURLToken('my-profile', 'profile', params);
                     case 'my-favorite-tracks':
                         return _tvg4SectionNameByURLToken('my-profile', 'my-favorite-tracks', params);
                     case 'my-stable':
                         return _tvg4SectionNameByURLToken('my-profile', 'my-stable', params);
                     case 'my-tracks':
                         return _tvg4SectionNameByURLToken('my-profile', 'my-tracks', params);
                     case 'my-funds':
                         return _tvg4SectionNameByURLToken('my-profile', 'funds', params);
                     case 'my-bets':
                         return _tvg4SectionNameByURLToken('my-profile', 'my-bets', params);
                     case 'my-account-summary':
                         return _tvg4SectionNameByURLToken('my-profile', 'my-account-summary', params);
                     case 'handicapping':
                         name = url[2] || null;
                         return _tvg4SectionNameByURLToken('handicapping', name, params);
                     case 'promos':
                     case 'promotions':
                         name = url[2] || null;
                         return _tvg4SectionNameByURLToken('promotions', name, params);
                     case 'wager-rewards':
                         name = url[2] || null;
                         return _tvg4SectionNameByURLToken('wager-rewards', name, params);
                    case 'tracks':
                            defaultParam = {
                             sectionName: url[2]
                            };
                          return _tvg4SectionNameByURLToken('tracks', 'track-list' , defaultParam);
                    case 'live':
                        return _tvg4SectionNameByURLToken('live', null, params);
                     default:
                         return '';
                }
            }
        }

        function _tvg4SectionNameForMyTracks(type) {
            return _tvg4SectionName() + _getMyTracksFilterForGtm(type);
        }

        function _getMyTracksFilterForGtm(type) {

            switch (type) {
                case "today":
                    return " | Today Tracks";
                case "favorites":
                    return " | Favorite Tracks";
                default:
                    return " | All Tracks";
            }
        }

        function _tvg4ScreenName () {
            var url = $location.path().split('/'),
                urlToken = $location.url().includes('?race') ? $location.path() : url[1],
                screenName = ScreenNames[urlToken];

            if (_.isUndefined(screenName)) {
                return '';
            }

            return screenName;
        }

        function _isPrivateBrowser() {
            var fs = window.RequestFileSystem || window.webkitRequestFileSystem;
            if (fs) {
                fs(window.TEMPORARY, 100, function(fs) {
                    isIncognito = false;
                }, function(err) {
                    isIncognito = true;
                });
            }
        }

        return {
            GTMEvent: _GTMEvent,
            formatGALAbelUpperCase: _formatGALAbelUpperCase,
            GAPageviews: _GAPageviews,
            isTVG4: _isTVG4,
            getScreenName: _getScreenName,
            isTalentPick: _isTalentPick,
            setCurrentSelectionList: _setCurrentSelectionList,
            EventBuilder: _EventBuilder,
            Tvg4SectionName: _tvg4SectionName,
            Tvg4ScreenName: _tvg4ScreenName,
            tvg4SectionNameForMyTracks: _tvg4SectionNameForMyTracks,
            gaEventBuilder: _gaEventBuilder,
            genericEvent: _genericEvent,
            getURLForRetroCompatibleGTM: _getURLForRetroCompatibleGTM
        };
    }

    GTMFac.$inject = [
        '$location',
        '$rootScope',
        '$routeParams',
        'ScreenNames',
        'SectionNames',
        'METADATA',
        'RaceUrlFac',
        '$cookies',
        'LocalStorageFac'
    ];

    return GTMFac;
});

