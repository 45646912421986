'use strict';

define(
    'HandicappingRaceReplaysCtrl',['HandlePagination', 'lodash'],
    function (HandlePagination, _) {

        function HandicappingRaceReplaysCtrl($scope, $rootScope, HandicappingRaceReplaysFac, CookieFac, $timeout) {

            var vm = this;

            function init() {

                //pagination
                vm.pagesInfo = {
                    initialIndex: 0,
                    finalIndex: 0,
                    totalIndex: 0,
                    pageSelected: 1,
                    numberOfPages: 0
                };

                vm.itemsPerPage = 30;
                vm.pagination = new HandlePagination(vm.itemsPerPage, vm.pagesInfo);

                var hideResultsCookie = $rootScope.activeFeatures.raceReplaysHideResults ? CookieFac.getCookie("toggleHideResults") : false;
                if (_.isUndefined(hideResultsCookie) || _.isNull(hideResultsCookie)) {
                    hideResultsCookie = true;
                    CookieFac.setCookie("toggleHideResults", hideResultsCookie, { expires: 90 });
                }

                //search model
                vm.model = {
                    loading: false,
                    dateSelected: null,
                    tabSelected: "Date",
                    trackList: [],
                    raceReplaysTrackDates: [],
                    disableDatePicker: false,
                    dataSource: [],
                    searchToShow: "",
                    horseNameSearch: {
                        value: "",
                        delay: 300,
                        minLength: 3
                    },
                    errorMessage: "",
                    infoMessage: "",
                    selectedReplace: {},
                    raceReplay: {},
                    resultsLoading: false,
                    hideResults: hideResultsCookie
                };

            }

            function _changeHideResults() {
                CookieFac.setCookie("toggleHideResults", vm.model.hideResults, { expires: 90 });
                HandicappingRaceReplaysFac.sendGtmEvent("Hide Races Results | Toggle " + (vm.model.hideResults ? "On" : "Off"), "HandicappingRaceReplays_HideRacesResultsToggle");
            }

            function _resetTab(tab) {
                vm.model.tabSelected = tab;
                vm.model.infoMessage = '';
                vm.model.errorMessage = '';
                vm.model.dateSelected = null;
                _resetTable();
                vm.model.resultsLoading = true;
            }

            // tabs controllers
            function _changeTab(tab) {
                if (tab !== vm.model.tabSelected) {
                    if (tab === "Favorite" && !$rootScope.userSession) {
                        $rootScope.$broadcast('callLoginRequiredModal', function () {
                            _resetTab(tab);
                        });
                    } else {
                        _resetTab(tab);
                    }

                    HandicappingRaceReplaysFac.sendGtmEvent("Past Results | Search By | " + tab, "PastResults-SearchBy");
                }
            }

            function _resetTable() {
                vm.model.dataSource = [];
                vm.pagination = new HandlePagination(vm.itemsPerPage, vm.pagesInfo);
                vm.pagination.setDataSource(vm.model.dataSource);
                vm.model.loading = false;
            }

            function _selectReplay(row) {
                vm.model.resultsLoading = true;
                if (vm.events.isSelectedReplay(row)) {
                    vm.model.selectedReplay = {};
                    vm.model.raceReplay = {};
                    return;
                }
                $rootScope.$broadcast("replayVideo:stop");
                vm.model.selectedReplay = row;
                vm.model.raceReplay = HandicappingRaceReplaysFac.buildResultReplay(row);
            }

            function _isSelectedReplay(row) {
                return _.isEqual(row, vm.model.selectedReplay);
            }

            function _showTrackColumn() {
                return vm.model.tabSelected === "Horses" || (vm.model.tabSelected === "Date" && vm.model.trackSelected && vm.model.trackSelected.trackAbbr === "all") || vm.model.tabSelected === "Favorite";
            }

            $scope.$on('raceResultsDirCompleted', function () {
                vm.model.resultsLoading = false;

                $timeout(function() {
                    $rootScope.$apply();
                });
            });

            $scope.$watch('HandicappingRaceReplays.model.dataSource', function () {
                vm.pagination = new HandlePagination(vm.itemsPerPage, vm.pagesInfo);
                vm.pagination.setDataSource(vm.model.dataSource);
            });

            vm.events = {
                isSelectedReplay: _isSelectedReplay,
                selectReplay: _selectReplay,
                changeTab: _changeTab,
                showTrackColumn: _showTrackColumn,
                changeHideResults: _changeHideResults
            };

            init();
        }

        HandicappingRaceReplaysCtrl.$inject = [
            '$scope',
            '$rootScope',
            'HandicappingRaceReplaysFac',
            'CookieFac',
            '$timeout'
        ];

        return HandicappingRaceReplaysCtrl;
    }
);

