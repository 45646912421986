'use strict';

define('TalentPicksFactory',[
        'lodash',
        'RaceStatusUtils'
    ],
    function (_, RaceStatusUtils) {
        function TalentPicksFactory(TalentPicksSvc, $q, $rootScope) {

            var _talentPicksData = {
                    talentPicks: [],
                    filteredTalentPicks: [],
                    trackList: [],
                    expertList: [],
                    selectedTrack: '',
                    selectedExpert: ''
                },
                deferPollTalentPicks;

            function _orderByMtpAndOpenRaces(talentPicks) {
                var openRaces = talentPicks.filter(function(talentPick) {
                    var status = talentPick.raceStatus;

                    return RaceStatusUtils.isStatusOpen(status) ||
                        RaceStatusUtils.isStatusUpNext(status) ||
                        RaceStatusUtils.isStatusManuallyOpened(status);

                }).sort(function (first, second) {
                    return first.postTime - second.postTime;
                });

                var closedRaces = talentPicks.filter(function(talentPick) {
                    var status = talentPick.raceStatus;

                    return !RaceStatusUtils.isStatusOpen(status) &&
                        !RaceStatusUtils.isStatusUpNext(status) &&
                        !RaceStatusUtils.isStatusManuallyOpened(status);

                }).sort(function (first, second) {
                    return first.postTime - second.postTime;
                });

                return openRaces.concat(closedRaces);
            }

            function _pollTalentPicks(filter) {
                var deferred = $q.defer();

                var parseData = function (data) {

                    //Original list
                    _talentPicksData.talentPicks = data;
                    //Filtered list
                    _talentPicksData.filteredTalentPicks = _orderByMtpAndOpenRaces(data);

                    _talentPicksData.trackList = [];
                    _talentPicksData.expertList = [];

                    _talentPicksData.trackList.push({
                        id: 0,
                        name: 'All Tracks'
                    });

                    _talentPicksData.expertList.push({
                        id: 0,
                        name: 'All Experts'
                    });

                    // Get Unique Tracks
                    _.forEach(_.uniqBy(data, function (talentPick) {
                        return talentPick.trackName;
                    }), function (talentPick) {
                        _talentPicksData.trackList.push({
                            id: _talentPicksData.trackList.length,
                            name: talentPick.trackName
                        });
                    });

                    // Get Unique Experts
                    _.forEach(_.uniqBy(data, function (talentPick) {
                        return talentPick.talentName + ' ' + talentPick.talentLastName;
                    }), function (talentPick) {
                        _talentPicksData.expertList.push({
                            id: _talentPicksData.expertList.length,
                            name: talentPick.talentName + ' ' + talentPick.talentLastName
                        });
                    });

                    var selectedTrackId = _talentPicksData.selectedTrack.id || 0,
                        selectedExpertId = _talentPicksData.selectedExpert.id || 0;

                    _talentPicksData.selectedTrack = _.isUndefined(_talentPicksData.trackList[selectedTrackId]) ? _talentPicksData.trackList[0] : _talentPicksData.trackList[selectedTrackId];
                    _talentPicksData.selectedExpert = _.isUndefined(_talentPicksData.expertList[selectedExpertId]) ? _talentPicksData.expertList[0] : _talentPicksData.expertList[selectedExpertId];

                    deferred.notify(_talentPicksData);
                    _filterTalentPicks();
                };

                if ($rootScope.activeFeatures.enableGraphTalentPicks) {
                    deferPollTalentPicks = TalentPicksSvc.pollTalentPicksList(filter, parseData);
                } else {
                    deferPollTalentPicks = TalentPicksSvc.pollTalentPicksList(filter).then(null, null, parseData);
                }

                return deferred.promise;
            }

            function _mergeName(firstName, lastName) {
                return firstName + ' ' + lastName;
            }

            function _validateTrackNameAndExpert(trackName, ExpertName) {
                return trackName === _talentPicksData.selectedTrack.name && ExpertName === _talentPicksData.selectedExpert.name;
            }

            function _filterTalentPicks() {
                _talentPicksData.filteredTalentPicks = _.filter(_talentPicksData.talentPicks, function (talentPick) {

                    if (_talentPicksData.selectedTrack.id !== 0 && _talentPicksData.selectedExpert.id !== 0) {
                        // Filter Track and Expert
                        return _validateTrackNameAndExpert(talentPick.trackName, _mergeName(talentPick.talentName, talentPick.talentLastName));
                    }
                    if (_talentPicksData.selectedTrack.id !== 0) {
                        // Filter Track
                        return talentPick.trackName === _talentPicksData.selectedTrack.name;
                    }
                    if (_talentPicksData.selectedExpert.id !== 0) {
                        // Filter Expert
                        return (talentPick.talentName + ' ' + talentPick.talentLastName) === _talentPicksData.selectedExpert.name;
                    }
                    // Do not filter anything, return unfiltered list
                    return true;
                });
            }

            function _stopPollingTalentPicks() {
                TalentPicksSvc.stopPollingTalentPicksList(deferPollTalentPicks);
            }

            return {
                talentPicksData: _talentPicksData,
                pollTalentPicks: _pollTalentPicks,
                stopPollingTalentPicks: _stopPollingTalentPicks,
                filterTalentPicks: _filterTalentPicks
            };
        }

        TalentPicksFactory.$inject = [
            'TalentPicksSvc',
            '$q',
            '$rootScope'
        ];

        return TalentPicksFactory;
    });

