'use strict';

define('RaceTrackCurrentBetsDir',['MyBetsFilters', 'MyBetsDataTransformer', 'GenericMessage', 'lodash', 'moment', 'Mediator'],
    function (MyBetsFilters, MyBetsDataTransformer, GenericMessage, _, moment, mediator) {

        /**
         * @ngdoc directive
         * @name raceTrackCurrentBets
         * @module TVG.RaceInfo.RaceTrackCurrentBetsMod
         *
         * @description
         * This directive is used to show a list of active or winning bets placed for the current track,
         * race and logged user.
         * When a new bet is placed this directive is notified and automatically requests all the available
         * bets for the current track, updating the table accordingly.
         * The previous values for the current race are displayed while fetching new bets,
         * e.g., user is on the race 6 that has one bet placed; he changes to the race 3 and then returns to
         * the race 6; while the response from the server doesn't come the table shows the previous state,
         * i.e., the bet he saw before changing to thrace 3, with the overlay.
         *
         * @example
         <race-track-current-bets data-race="currentRace"></race-track-current-bets>
         */
        function raceTrackCurrentBetsDir($rootScope, $interval, METADATA, MyBetsSvc, GTMFac) {
            // private data
            var betPlacedEventListener,
                intervalPromise,
                pendingRequest,
                // This value represents the number of executions of the 'bet_placed' event listener
                // since the last 'scope.raceBets' update.
                numberOfTimesListenerWasExecuted = 0,
                DEFAULT_INTERVAL_DELAY_MILLISECONDS = 5000,
                MAX_NUMBER_OF_INTERVAL_REPETITIONS = 3,
                // This array will contain all the bets of all races for the current track.
                // The bets are added whenever we open a new race.
                // This array is empty when the track changes.
                allRacesBets = [],
                filteredBets,
                listenersToUnBind = [],
                pollRaceBets;

            function _linkFn(scope) {
                var gkeMyBets = $rootScope.activeFeatures.gkeMyBets;
                var raceDate = moment(scope.race.postTime).format('MM-DD-YYYY');
                var deregistrationWatchUserSessionListener;
                scope.gkeMyBets = gkeMyBets;

                /**
                 * @private
                 * @description
                 * Resets all the internal data to its default values.
                 */
                function _resetState() {
                    var entriesToAdd,
                        i;
                    // We have to push new empty entries to the array
                    // if we haven't viewed this race before.
                    if (allRacesBets.length < scope.race.raceNumber) {
                        entriesToAdd = scope.race.raceNumber - allRacesBets.length;
                        for (i = 0; i < entriesToAdd; i += 1) {
                            allRacesBets.push([]);
                        }
                    }

                    // Show the previous values before requesting the current data.
                    scope.raceBets = allRacesBets[scope.race.raceNumber - 1];
                    // The message at the top of the table
                    scope.genericMessage = new GenericMessage();

                    numberOfTimesListenerWasExecuted = 0;
                    if (intervalPromise) {
                        $interval.cancel(intervalPromise);
                    }
                }

                function _transformMyBetsData(records) {
                    if (!records.length) {
                        return [];
                    }
                    var transformedRecords = records;

                    transformedRecords.forEach(function (record) {
                        var recordSelections = record.selections.selection;
                        var positionSelections, legSelections;

                        record.selection = record.selections.selectionString.split(',WT,');

                        // we need to do this because WRO only send the base wager type, ignoring if it is a box or key or both
                        if (record.selections.selectionString.indexOf('KY') !== -1) {
                            record.wagerType.code += 'KY';
                            record.wagerType.isKey = true;
                        }

                        if (record.selections.selectionString.indexOf('BX') !== -1) {
                            record.wagerType.code += 'BX';
                            record.wagerType.isBox = true;
                        }

                        record.cancelBet = new GenericMessage();
                        record.showCancelForm = false;
                        record.isCanceling = false;

                        // detect the column type for this selections
                        // if any of the selection is P, means Pos -> Position
                        positionSelections = _.filter(recordSelections, function (selection) {
                            return selection.selectionType.code === 'P';
                        });

                        // if any of the selections is R, mans Rac -> Race -> Leg
                        legSelections = _.filter(recordSelections, function (selection) {
                            return selection.selectionType.code === 'R';
                        });

                        if (positionSelections.length) {
                            record.columnType = 'Pos';
                        }

                        if (legSelections.length) {
                            record.columnType = 'Leg';
                        }

                        if ((record.wagerType.isBox || record.wagerType.isKey) && isNaN(Number(record.selection[0]))) {
                            record.selection[0] = record.selection[0].slice(record.selection[0].indexOf(',') + 1);
                        }
                    });

                    return transformedRecords;
                }

                function _handleRaceBetsSuccessRequest(wagers){
                    var previousBets = scope.raceBets;

                    var bets = _.filter(wagers, function (wager) {
                        return wager.betStatus.code !== 'C';
                    });

                    if (!bets || bets.length === 0) {
                        scope.raceBets = [];
                        return [];
                    }

                    // check bets
                    scope.raceBets = _transformMyBetsData(bets);

                    if (JSON.stringify(previousBets) !== JSON.stringify(scope.raceBets)) {
                        mediator.dispatch('BALANCE_UPDATE_FORCE', true);
                    }

                    return scope.raceBets;
                }

                function _caughtRaceBetsErrorRequest(){
                    scope.genericMessage.iconClass = 'tvg-icon tvg-icon-warning text-warning';
                    scope.genericMessage.message = 'Error getting wager history';
                    scope.genericMessage.showMessage = true;
                }

                function _setRaceBets(raceDate, trackAbbr, filterFunction) {
                    var behgMyBets = $rootScope.activeFeatures.tvgenablebehg;
                    scope.behgMyBets = behgMyBets;

                    if (pendingRequest && angular.isFunction(pendingRequest.abort)) {
                        pendingRequest.abort();
                    }

                  if(behgMyBets) {
                      pendingRequest = MyBetsSvc.getBetsByTvgRaceIdFromBehg(
                          scope.race.tvgRaceId,
                          'ALL'
                      );

                      return pendingRequest.then(_handleRaceBetsSuccessRequest, _caughtRaceBetsErrorRequest);
                  } else if (gkeMyBets) {
                        pendingRequest = MyBetsSvc.getBetsByTvgRaceIdFromGke(
                            scope.race.tvgRaceId,
                            'all',
                            1,
                            50,
                            'transactiondate',
                            false
                        );

                        return pendingRequest.then(function (response){
                            _handleRaceBetsSuccessRequest(response.wagers);
                        }, _caughtRaceBetsErrorRequest);
                    } else {
                        pendingRequest = MyBetsSvc.getBetsByDate(
                            moment(raceDate, 'MM-DD-YYYY').format('MM-DD-YYYY'),
                            moment(raceDate, 'MM-DD-YYYY').add(1, 'day').format('MM-DD-YYYY'),
                            trackAbbr, filterFunction);
                        return pendingRequest.then(function (bets) {
                            if (!bets || bets.length === 0) {
                                scope.raceBets = [];
                                return [];
                            }
                            scope.raceBets = bets.wagerHistory.rowData.map(MyBetsDataTransformer.toModel(METADATA.wagerTypes));
                            return scope.raceBets;
                        }, function (message) {
                            scope.genericMessage.iconClass = 'tvg-icon tvg-icon-warning text-warning';
                            scope.genericMessage.message = message;
                            scope.genericMessage.showMessage = true;
                        });
                    }

                }

                /**
                 * @private
                 * @description
                 * This function closes all opened notifications, if any.
                 * This includes generic messages and/or cancel forms.
                 */
                function _closeAllOpenedNotifications() {
                    scope.genericMessage.showMessage = false;
                    scope.raceBets.forEach(function (currentValue) {
                        // Close an already existing form.
                        currentValue.showCancelForm = false;
                        // Close any message that might be visible.
                        currentValue.cancelBet.showMessage = false;
                        currentValue.isCanceling = false;
                    });
                }

                function _countRepeatedBets(cancelledBet, allBets) {

                    return allBets
                        .filter(function (bet) {
                            return bet.betStatus.name === 'active' && bet.serialNumber !== cancelledBet.serialNumber;
                        })
                        .reduce(function (acc, raceBet) {
                            var cancellingBet = cancelledBet || {};

                            var isCancelingIterationRace = raceBet.isCanceling;
                            var areRepeated = raceBet.wagerReference === cancellingBet.wagerReference;

                            acc += areRepeated && !isCancelingIterationRace ? 1 : 0;
                            return acc;
                        }, 0);
                }

                /**
                 * @private
                 * @description
                 * Function called when the user clicks on one of the 'X' buttons.
                 * Causes the appearance of the cancel form in the correct position of the table replacing the bet's row.
                 * Closes an already existing form.
                 *
                 * @param {Number} index The index that matches the bet that might be canceled, in order to show the
                   form in the correct position.
                 */
                function _onCancelBetClick(index) {
                    var payload;
                    var repeatBet;

                    if (scope.raceBets[index] === undefined) {
                        return;
                    }

                    repeatBet = _countRepeatedBets(scope.raceBets[index], scope.raceBets);

                    // We do nothing if we have a cancellation in progress.
                    if (scope.raceBets.some(function (current) {
                            return current.isCanceling;
                        })) {
                        return;
                    }

                    // Close all messages that might be visible.
                    _closeAllOpenedNotifications();

                    scope.raceBets[index].showCancelForm = true;

                    payload = {
                        gaEventCategory: 'Bet',
                        gaEventAction: 'Cancel Bet Success',
                        gaEventLabel: scope.raceBets[index].serialNumber,
                        runnerAmmount: scope.raceBets[index].wagerAmount,
                        trackName: scope.raceBets[index].trackName,
                        raceNumber: scope.raceBets[index].raceNumber,
                        betAmount: scope.raceBets[index].betTotal,
                        betType: scope.raceBets[index].wagerType.name,
                        sport: scope.raceBets[index].raceTypeAbbreviation === 'G' ?
                            'Greyhounds Racing' :
                            'Horse Racing',
                        runnerSelectionList: scope.raceBets[index].selections.selectionString,
                        tag: undefined,
                        module: 'My Bets Race Card',
                        repeatBet: repeatBet
                    };

                    GTMFac.GTMEvent().send($rootScope, 'bet', payload);
                }

                /**
                 * @private
                 * @description
                 * Function called when the user clicks on the 'X' in the generic message.
                 *
                 * @param {Object} cancelBetObj The object that represents the generic message.
                 */
                function _onCloseButtonClick(cancelBetObj) {
                    cancelBetObj.showMessage = false;
                }

                function _viewAllMyBets() {
                    var p = {
                        gaEventCategory: 'Site Click',
                        gaEventAction: 'Program Page Click',
                        gaEventLabel: 'Right Rail | My Bets | See All My Bets',
                        screenName: 'Program Page',
                        raceNumber: 'R' + scope.race.raceNumber,
                        trackName: scope.race.trackName,
                        eventLabel: 'RightRail-AllMyBets'
                    };

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);

                    var eventData = GTMFac.gaEventBuilder()
                        .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                        .withGaEventCategory('Site Click')
                        .withGaEventLabel('Right Rail | My Bets | See All My Bets')
                        .withEventLabel('RightRail-AllMyBets')
                        .build();

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));

                }

                /**
                 * @private
                 * @description
                 * Function called to verify if we should show the bet gain value.
                 *
                 * @param {Object} bet the bet placed.
                 */
                function _showBetValue(bet) {
                    return bet.wagerStatus === "Winner";
                }

                /**
                 * @private
                 * @description
                 * Removes all the $rootScope event and watch listeners, cancels pending timeout
                 * and aborts pending HTTP requests.
                 */
                function _cleanUp() {
                    if (angular.isFunction(deregistrationWatchUserSessionListener)) {
                        deregistrationWatchUserSessionListener();
                        deregistrationWatchUserSessionListener = null;
                    }
                    if (angular.isFunction(betPlacedEventListener)) {
                        betPlacedEventListener();
                        betPlacedEventListener = null;
                    }
                    if (pendingRequest && angular.isFunction(pendingRequest.abort)) {
                        pendingRequest.abort();
                    }
                    if (intervalPromise) {
                        $interval.cancel(intervalPromise);
                    }

                    _.forEach(listenersToUnBind, function (unBindListener) {
                        unBindListener();
                    });

                    $interval.cancel(pollRaceBets);
                }

                /**
                 * @private
                 * @description
                 * Listener for the 'bet_placed' event emitted on the $rootScope.
                 * Uses a timeout function that updates the table after DEFAULT_INTERVAL_DELAY_MILLISECONDS seconds,
                 * to ensure that the bet transaction is totally completed.
                 * This function is executed until we receive all the bets we're expecting or
                 * until it's executed MAX_NUMBER_OF_INTERVAL_REPETITIONS times.
                 *
                 * @param {Event} event Event object
                 * @param {Object} race A race object with all race information for the placed bet
                 */
                function _onBetPlacedListener(event, race) {
                    /*jslint unparam: true*/
                    function removeOverlayAndResetNumberOfListenerExecutions() {
                        // reset values
                        numberOfTimesListenerWasExecuted = 0;
                        scope.isLoading = false;
                    }

                    // Check that the new bet is for the current track/race. If not, ignore.
                    if (scope.race.trackAbbr !== race.trackAbbr || parseInt(scope.race.raceNumber, 10) !== parseInt(race.raceNumber, 10)) {
                        return;
                    }

                    numberOfTimesListenerWasExecuted += 1;
                    scope.isLoading = true;

                    if (intervalPromise) {
                        $interval.cancel(intervalPromise);
                    }
                    // Using a 5 seconds interval to guarantee that the place bet transaction is completed.
                    intervalPromise = $interval(function () {
                        var numberOfBetsBeforeExecution = scope.raceBets.length;
                        // We do not reset state here because we don't want to have a flickering effect
                        // when the allBets changes to an empty array.

                        filteredBets = scope.race.showActiveBets() ? MyBetsFilters.filterActiveBets(scope.race) : MyBetsFilters.filterPlacedBets(scope.race);

                        _setRaceBets(raceDate, scope.race.trackAbbr, filteredBets).then(function (allBets) {
                            if (numberOfBetsBeforeExecution + numberOfTimesListenerWasExecuted > allBets.length) {
                                // In case 'scope.raceBets' have already been updated with some new bets ...
                                // 'Math.abs' because we always want to decrement the 'numberOfTimesListenerWasExecuted'
                                // and if the 'numberOfBetsBeforeExecution' is greater that the 'allBets.length'
                                // the 'numberOfTimesListenerWasExecuted' is incremented.
                                numberOfTimesListenerWasExecuted -= Math.abs(allBets.length - numberOfBetsBeforeExecution);
                            } else {
                                removeOverlayAndResetNumberOfListenerExecutions();
                                // cancel interval
                                $interval.cancel(intervalPromise);
                            }
                        }, removeOverlayAndResetNumberOfListenerExecutions);

                    }, DEFAULT_INTERVAL_DELAY_MILLISECONDS, MAX_NUMBER_OF_INTERVAL_REPETITIONS);

                    // The success callback is executed when the number of repetitions reaches its limit.
                    intervalPromise.then(removeOverlayAndResetNumberOfListenerExecutions);
                }

                /**
                 * @private
                 * @description
                 * Listener executed whenever the '$rootScope.userSession' changes.
                 *
                 * @param {Boolean} newVal True if user is logged in. False otherwise.
                 */
                function _watchUserSessionListener(newVal) {
                    var filteredBets;

                    if (newVal === undefined) {
                        return;
                    }

                    scope.userIsLoggedIn = newVal;

                    if (scope.userIsLoggedIn) {
                        betPlacedEventListener = $rootScope.$on('bet_placed', _onBetPlacedListener);

                        filteredBets = scope.race.closedForBetting ? MyBetsFilters.filterPlacedBets(scope.race) : MyBetsFilters.filterActiveBets(scope.race);

                        _resetState(scope);

                        scope.isLoading = true;
                        _setRaceBets(raceDate, scope.race.trackAbbr, filteredBets).finally(function () {
                            scope.isLoading = false;
                        });
                    } else {
                        _cleanUp();
                    }
                }

                function _loadRaceBetResults() {
                    filteredBets = scope.race.showActiveBets() ? MyBetsFilters.filterActiveBets(scope.race) : MyBetsFilters.filterPlacedBets(scope.race);
                    scope.isLoading = true;
                    $interval.cancel(pollRaceBets);
                    _setRaceBets(raceDate, scope.race.trackAbbr, filteredBets).finally(function () {
                        scope.isLoading = false;
                    });
                }

                /**
                 * @private
                 * @description
                 * Function called every time the race changes.
                 * This function updates the table that contains all the bets for the current race.
                 *
                 * @param {Object} newRace
                 * @param {Object} oldRace
                 */
                function _watchRaceListener(newRace, oldRace) {
                    if (!scope.userIsLoggedIn) {
                        return;
                    }

                    var diffTrack = newRace.trackName !== oldRace.trackName;
                    var diffRace = parseInt(newRace.raceNumber, 10) !== parseInt(oldRace.raceNumber, 10);
                    var diffStatus = newRace.raceStatus !== oldRace.raceStatus;

                    if (diffTrack || diffRace || diffStatus) {

                        // Remove the entries that belong to the races of the old track.
                        if (diffTrack) {
                            allRacesBets = [];
                        } else {
                            // Close all opened notifications, if any.
                            _closeAllOpenedNotifications();
                            // Save the raceBets before changing.
                            allRacesBets[oldRace.raceNumber - 1] = scope.raceBets;
                        }

                        filteredBets = scope.race.showActiveBets() ? MyBetsFilters.filterActiveBets(scope.race) : MyBetsFilters.filterPlacedBets(scope.race);
                        _resetState(scope);

                        scope.isLoading = (diffRace || diffTrack);
                        _setRaceBets(raceDate, scope.race.trackAbbr, filteredBets).finally(function () {
                            scope.isLoading = false;
                        });
                    }
                }

                (function init() {
                    _resetState();
                    // Do not put this inside the 'resetScopeState' because the value should be
                    // changed after the request has a response.
                    scope.isLoading = false;
                    // URL of the module that shows all the user's bets.
                    scope.myBetsUrl = '/my-bets';
                    // Object with all the possible user actions
                    scope.events = {
                        onCancelBetClick: _onCancelBetClick,
                        onCloseButtonClick: _onCloseButtonClick,
                        showBetValue: _showBetValue,
                        viewAllMyBets: _viewAllMyBets
                    };

                    deregistrationWatchUserSessionListener = $rootScope.$watch('userSession', _watchUserSessionListener);

                    scope.$watch('race', _watchRaceListener);
                    scope.$on('$destroy', _cleanUp);

                    pollRaceBets = $interval( function() {
                        if (!scope.userIsLoggedIn) {
                            return;
                        }

                        filteredBets = scope.race.showActiveBets() ? MyBetsFilters.filterActiveBets(scope.race) : MyBetsFilters.filterPlacedBets(scope.race);
                        _setRaceBets(raceDate, scope.race.trackAbbr, filteredBets).finally(function () {
                            scope.isLoading = false;
                        });
                    }, 60000);

                    listenersToUnBind.push($rootScope.$on('cancelingBet', function(){
                        scope.isLoading = true;
                    }));

                    listenersToUnBind.push($rootScope.$on('betCancelled', function(){
                        scope.isLoading = false;
                    }));

                    listenersToUnBind.push($rootScope.$on('cancelBetFail', function(){
                        scope.isLoading = false;
                    }));

                    listenersToUnBind.push(scope.$on('setRaceResults', _loadRaceBetResults));


                }());
            }

            return {
                restrict: 'EA',
                scope: {
                    race: '='
                },
                templateUrl: 'js/desktop/modules/RaceTrackCurrentBets/templates/race-track-current-bets.html',
                link: _linkFn
            };
        }

        raceTrackCurrentBetsDir.$inject = [
            '$rootScope',
            '$interval',
            'METADATA',
            'MyBetsSvc',
            'GTMFac'
        ];

        return raceTrackCurrentBetsDir;
    });

