'use strict';

define('TopScrollDir',['StatePersistenceFac'], function () {

    function TopScrollDir() {
        return {
            restrict: 'EA',
            scope: true,
            link: function ($scope, elem, attrs) {
                var $elem = angular.element(elem),
                    $parent = $elem.parent(),
                    leftArrow = $parent.find("prevarrow"),
                    rightArrow = $parent.find("nextarrow"),
                    $list = $parent.find("ul"),
                    list = $list[0],
                    parent = $parent[0],
                    scrollAmount = 90,
                    scrollPosition = attrs.position || 0,
                    sectionWidth = attrs.sectionwidth || 100,
                    scrolled = false;

                $scope.events = {
                    scrollLeft: _scrollLeft,
                    scrollRight: _scrollRight,
                    minScrollReached: _minScrollReached,
                    maxScrollReached: _maxScrollReached
                };

                scrollPosition = scrollPosition || 0;

                $scope.$on('swipeLeft', _scrollLeft);
                $scope.$on('swipeRight', _scrollRight);
                $scope.$on('scrollTo', function (event, data) {
                    if (!scrolled) {
                        scrollPosition = -data * sectionWidth;
                    }
                    _applyScrollAndCheckArrowsVisibility();
                });

                function _scrollLeft() {
                    scrolled = true;
                    scrollAmount = parseInt(parent.clientWidth * 0.8, 10);
                    if (!_minScrollReached()) {
                        scrollPosition += scrollAmount;
                        _applyScrollAndCheckArrowsVisibility();
                    }
                }

                function _scrollRight() {
                    scrolled = true;
                    scrollAmount = parseInt(parent.clientWidth * 0.8, 10);
                    if (!_maxScrollReached()) {
                        scrollPosition -= scrollAmount;
                        _applyScrollAndCheckArrowsVisibility();
                    }
                }

                function _needScroll() {
                    return list.clientWidth > parent.clientWidth;
                }

                function _applyScrollAndCheckArrowsVisibility() {
                    rightArrow.addClass("tvg-icon-arrow-right");
                    leftArrow.addClass("tvg-icon-arrow-left");

                    if (-scrollPosition < 0) {
                        scrollPosition = 0;
                    }
                    if (list.clientWidth - parent.clientWidth > 0 && -scrollPosition > list.clientWidth - parent.clientWidth) {
                        scrollPosition = -(list.clientWidth - parent.clientWidth);
                    }
                    $list.css({
                        '-webkit-transform': 'translateX(' + scrollPosition + 'px)',
                        '-moz-transform': 'translateX(' + scrollPosition + 'px)',
                        '-ms-transform': 'translateX(' + scrollPosition + 'px)',
                        '-o-transform': 'translateX(' + scrollPosition + 'px)',
                        'transform': 'translateX(' + scrollPosition + 'px)'
                    });
                }

                _applyScrollAndCheckArrowsVisibility();

                function _maxScrollReached() {
                    var currentScrollPosition = scrollPosition * -1;
                    return _needScroll() && (list.clientWidth - parent.clientWidth < 0 || (currentScrollPosition > 0 && list.clientWidth - currentScrollPosition <= parent.clientWidth));
                }

                function _minScrollReached() {
                    var currentScrollPosition = scrollPosition * -1;
                    return _needScroll() && currentScrollPosition <= 0;
                }
            }
        };
    }

    TopScrollDir.$inject = [];

    return TopScrollDir;
});

