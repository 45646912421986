'use strict';

define(
    'ManageFavoritesFac',[],
    function () {

        function ManageFavoritesFac($rootScope, FavoriteTracksFac) {

            function _checkIfTrackIsFavorite(track){
                var favTracks = FavoriteTracksFac.getFavoriteTracks();
                return favTracks.indexOf(track) > -1;
            }

            /** Get favorite track list
             * @returns {Array} or empty array if cannot access CookieFac
             * @private*/
            function _getFavTrackList() {
                return FavoriteTracksFac.getFavoriteTracks();
            }

            function _getFavTrackListAsync() {
                return FavoriteTracksFac.getFavoriteTracksAsync();
            }

            function _clearFavTrackList() {

                return FavoriteTracksFac.clearFavoriteList(true).finally(function(){
                    $rootScope.$broadcast("clearFavTrackList", {});
                });
            }


            /** Check if trackAbbr belong to favTracks
             * if it belong delete entry
             * otherwise add new entry
             * In the end broadcast the new favorite track*/
            function _updateFavTrackList(trackAbbr) {

                var favTrackAbbrList = FavoriteTracksFac.getFavoriteTracks(),
                    isNewFavTrack = false;

                if(angular.isDefined(favTrackAbbrList)){
                    isNewFavTrack = (favTrackAbbrList.indexOf(trackAbbr) < 0);
                }
                else{
                    isNewFavTrack = true;
                }

                if (isNewFavTrack) {
                    return FavoriteTracksFac.addFavoriteTrack(trackAbbr).then(function () {
                        $rootScope.$broadcast("newFavTrack", {trackAbbr: trackAbbr, newFavorite: isNewFavTrack});
                    });
                }

                return FavoriteTracksFac.deleteFavoriteTrack(trackAbbr).then(function () {
                    $rootScope.$broadcast("newFavTrack", {trackAbbr: trackAbbr, newFavorite: false});
                });
            }

            return {
                checkIfTrackIsFavorite: _checkIfTrackIsFavorite,
                updateFavTrackList: _updateFavTrackList,
                getFavTrackList: _getFavTrackList,
                clearFavTrackList: _clearFavTrackList,
                getFavTrackListAsync: _getFavTrackListAsync
            };
        }

        ManageFavoritesFac.$inject = ['$rootScope', 'FavoriteTracksFac'];

        return ManageFavoritesFac;
    }
);

