'use strict';

define(
    'EditUsernameCtrl',['lodash'],
    function (_) {

        function EditUsernameCtrl($controller, $scope, $rootScope, $uibModal, $timeout, UsernameValidatorSvc, ProfileHelperSvc, GTMFac, FindModalContainerFac, $filter) {
            $controller('ChangePasswordCtrl', {$scope: $scope});

            $scope.events = _.merge($scope.events, {
                onUsernameEdited: _onUsernameEdited,
                cancel: _cancel,
                checkUsername: _.debounce(_checkUsername, 500)
            });

            $scope.data = _.merge($scope.data, {
                username: $rootScope.user ? $rootScope.user.userName : "",
                usernameInput: "",
                usernameRules: {
                    hasUsername: hasUsername(),
                    isTaken: false,
                    isSizeValid: true,
                    hasInvalidCharacters: false,
                    isValid: true
                },
                loading: false,
                errorMessage: null,
                message: null,
                confirmDialog: null,
                shouldCloseOnMessage: false
            });

            $scope.limitKeypress = function ($event, value, maxLength) {
                if (value != undefined && value.toString().length >= maxLength) {
                    $event.preventDefault();
                }
            };

            function hasUsername() {
                return $rootScope.user ? $rootScope.user.userName !== undefined && $rootScope.user.userName.length > 0 : false;
            }

            function _onUsernameEdited(formValid) {
                _checkUsername();
                if ($scope.data.usernameRules.hasUsername) {
                    _onUsernameChanged(formValid);
                } else {
                    _onUsernameAdded(formValid);
                }
            }

            function _onUsernameChanged(formValid) {
                var oldUsername = $rootScope.user.userName;
                var username = $scope.data.username;
                var confirmPwd = $scope.data.confirmPassword;
                var accountId = $rootScope.user.accountNumber;

                if (!formValid || $scope.data.loading) {
                    UsernameValidatorSvc.validateEmptyUsernameToEdit(username, confirmPwd, _showErrorMessage);
                    return;
                }

                if (!UsernameValidatorSvc.validateUsernameToEdit(oldUsername, username, _showErrorMessage)) {
                    return;
                }

                $scope.data.loading = true;

                ProfileHelperSvc.changeUsername(accountId, username, oldUsername, confirmPwd).then(function (response) {
                    _handleSuccessfulAddedOrUpdatedUsername(response);

                    var p = {
                        gaEventCategory: 'Site Click',
                        gaEventAction: 'My Profile Page Click',
                        gaEventLabel: 'User Profile | Change Username',
                        sectionName: GTMFac.Tvg4SectionName(),
                        screenName: GTMFac.Tvg4ScreenName(),
                        eventLabel: "MyAccount_UserProfileUsername"
                    };

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
                }, function (response) {
                    _handleUnsuccessfulAddedOrUpdatedUsername(response, 2);
                });
            }

            function _onUsernameAdded(formValid) {

                var username = $scope.data.username;
                var newPwd = $scope.data.newPassword;
                var confirmPwd = $scope.data.confirmPassword;
                var accountId = $rootScope.user.accountNumber;

                if (!formValid || $scope.data.loading) {
                    UsernameValidatorSvc.validateEmptyUsernameToAdd(username, newPwd, confirmPwd, _showErrorMessage);
                    return;
                }

                if (!UsernameValidatorSvc.validateUsernameToAdd(username, newPwd, confirmPwd, _showErrorMessage)) {
                    return;
                }

                $scope.data.loading = true;

                ProfileHelperSvc.addUsername(accountId, username, newPwd).then(function (response) {
                    _handleSuccessfulAddedOrUpdatedUsername(response);
                }, function (response) {
                    _handleUnsuccessfulAddedOrUpdatedUsername(response, 1);
                });
            }

            function _handleSuccessfulAddedOrUpdatedUsername(response) {
                $scope.data.loading = false;
                $scope.data.shouldCloseOnMessage = true;
                $scope.data.usernameRules.hasUsername = true;
                _showMessage(response.message);
                _setPristine();
            }

            function _handleUnsuccessfulAddedOrUpdatedUsername(error, opType) {
                var errorMessage = error.message;
                if (opType && opType === 1) { // ADD USERNAME
                    var genericErrMessage = 'There was a problem adding a username to your account.';
                    errorMessage = error.code && $filter('CMSLabels')(error.code, 'profileAddUsernameErrorHandling') ?
                        $filter('CMSLabels')(error.code, 'profileAddUsernameErrorHandling') : genericErrMessage;
                } else if (opType && opType === 2) { // CHANGE USERNAME
                    var genericErrMessage = 'There was a problem updating your new username.';
                    errorMessage = error.code && $filter('CMSLabels')(error.code, 'profileUpdateUsernameErrorHandling') ?
                        $filter('CMSLabels')(error.code, 'profileUpdateUsernameErrorHandling') : genericErrMessage;
                }

                $scope.data.loading = false;
                _setPristine();
                _showErrorMessage(errorMessage);
            }

            function _checkUsername() {
                UsernameValidatorSvc.fillUsernameValidations(
                    $rootScope.user.userName, $scope.data.username, $scope.data.usernameRules);
            }

            function _setPristine() {
                $scope.changeCredentialsFrm.oldPassword.$setPristine();
                $scope.changeCredentialsFrm.newPassword.$setPristine();
                $scope.changeCredentialsFrm.confirmPassword.$setPristine();
                $scope.data.oldPassword = "";
                $scope.data.newPassword = "";
                $scope.data.confirmPassword = "";
            }

            function _showMessage(message) {
                $scope.data.message = message;

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Account/templates/change-pin-password-success.html',
                    scope: $scope
                });
            }

            function _showErrorMessage(message) {
                $scope.data.errorMessage = message;
                $scope.data.errorMessageTitle = "Change Username Failed";

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Login/templates/login-error.html',
                    scope: $scope
                });
            }

            var unbind = [];
            unbind.push($rootScope.$on('user', function(){
                $scope.data.username = $rootScope.user ? $rootScope.user.userName : "";
                $scope.data.usernameRules.hasUsername = hasUsername();
            }));

            function _cancel() {
                if ($scope.data.confirmDialog) {
                    $scope.data.confirmDialog.close();
                    $scope.data.confirmDialog = null;
                }

                $timeout(function () {
                    $scope.data.errorMessage = null;
                }, 300);
            }

            $scope.$on('$destroy', function(){
                _.forEach(unbind, function (bind) {
                    bind();
                });
            });
        }

        EditUsernameCtrl.$inject = [
            '$controller',
            '$scope',
            '$rootScope',
            '$uibModal',
            '$timeout',
            'UsernameValidatorSvc',
            'ProfileHelperSvc',
            'GTMFac',
            'FindModalContainerFac',
            '$filter'
        ];

        return EditUsernameCtrl;
    }
);

