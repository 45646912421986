'use strict';

define(
    'TrackSelectorDir',[],function () {
        function TrackSelectorDir() {
            return {
                restrict: 'E',
                templateUrl: 'js/desktop/modules/Tracks/components/trackSelector/templates/track-selector.html',
                controller: 'TrackSelectorCtrl',
                controllerAs: 'trackSelectorCtrl',
                bindToController: true,
                scope: {
                    trackSelectorDefaultOption: "=",
                    trackSelectorOnChange: "&",
                    trackSelectorType: "="
                }
            };
        }

        TrackSelectorDir.$inject = [

        ];
        return TrackSelectorDir;
    }
);


