
'use strict';

define('ResizableCustomDir',[],function () {

    function ResizableCustomDir($rootScope, GTMFac) {
        return {
            restrict: 'EA',
            scope: false,
            link: function ($scope, $element) {

                $scope.resizeWindow = function resizeWindow(size) {
                    var width, height, posX, posY;
                    switch (size) {
                        case "S":
                            width = 300;
                            height = 225;
                            break;
                        case "M":
                            width = 400;
                            height = 300;
                            break;
                        case "L":
                            width = 640;
                            height = 480;
                            break;
                        case "FS":
                            sendGTMFullScreenEvent();
                            width = window.innerWidth;
                            height = window.innerHeight;
                            break;
                        default:
                            width = 300;
                            height = 225;
                            break;
                    }

                    function sendGTMFullScreenEvent() {

                        var eventData = GTMFac.gaEventBuilder()
                            .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                            .withGaEventCategory('Site Click')
                            .withGaEventLabel('Right Rail | Video Player | Full Screen')
                            .withEventLabel('RightRail-FullScreenVideo')
                            .build();

                        GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
                    }

                    posX = (window.innerWidth / 2) - (width / 2);
                    posY = (window.innerHeight / 2) - (height / 2);
                    $element.find('.modal').css({
                        width: width + "px",
                        height: height + "px",
                        left: posX,
                        top: posY
                    });
                };
            }
        };
    }

    ResizableCustomDir.$inject = [
        '$rootScope',
        'GTMFac'
    ];

    return ResizableCustomDir;
});

