'use strict';

define(
    'TutorialVideosSvc',[],
    function () {

        function TutorialVideosSvc($rootScope, $q, ContentFac) {
            var tutorialVideosSvc;
            var tutorialVideosData;

            function _buildSlides(data) {
                try {
                    data = JSON.parse(data);
                } catch (e) {
                    return null;
                }
                return data;
            }

            function _getVideoId(videoUrl) {
                var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
                var match = videoUrl.match(regExp);
                return (match && match[7].length == 11) ? match[7] : false;
            }

            tutorialVideosSvc = {
                getTutorialVideosCmsData: function () {
                    var defer = $q.defer();
                    if (!tutorialVideosData) {
                        ContentFac.getNamespaceContent('VideoTutorials').then(function (data) {
                            tutorialVideosData = Object.keys(data).length ? _.map(data, _buildSlides) : [];
                            defer.resolve(tutorialVideosData);
                        });
                    } else {
                        defer.resolve(tutorialVideosData);
                    }

                    return defer.promise;
                },
                getImageVideoUrl: function (videoUrl) {
                  var imageUrl = 'https://img.youtube.com/vi/code/hqdefault.jpg';

                  return imageUrl.replace('code', _getVideoId(videoUrl));
                },
                getEmbeddedVideoUrl: function (videoUrl) {
                  var embeddedVideoUrl = 'https://www.youtube.com/embed/code?autoplay=1';

                  return embeddedVideoUrl.replace('code', _getVideoId(videoUrl));
                }
            };

            return tutorialVideosSvc;
        }

        TutorialVideosSvc.$inject = [
            '$rootScope',
            '$q',
            'ContentFac'
        ];

        return TutorialVideosSvc;
    });

