'use strict';

define('PromoWidgetDir',[],function() {
     function PromoWidgetDir($timeout, ExternalComponentsSvc) {
        return {
            restrict: 'E',
            scope: false,
            templateUrl: 'js/desktop/modules/WidgetsDesktop/templates/promos-widget-template.html',
            link: function ($scope, $element) {
                $element.addClass('loading');
                // eslint-disable-next-line no-console,angular/log
                $scope.onClickPreventDefault = function ($event) {
                    $event.preventDefault();
                };
            
                ExternalComponentsSvc.loadComponent('/hmp/promo/component', function (err, node) {
                    $timeout(function () {
                        $element.removeClass('loading');
                    });

                    if (err) {
                        $element.addClass('error');
                        throw err;
                    }
                    angular.element('#promosCalendarContainer', $element).html(node);
                    promo.RenderComponent(angular.element('#promosCalendarContainer', $element).get(0));

                    $scope.$on('$destroy', function () {
                        promo.destroy($element.get(0));
                    });
                }, $timeout);
            }
        };
    }

    PromoWidgetDir.$inject = ['$timeout', 'ExternalComponentsSvc'];

    return PromoWidgetDir;
});

