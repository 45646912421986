'use strict';

define('UserPreferencesCtrl',['PreferencesCookieFac'],

    function () {

        function UserPreferencesCtrl($scope, $uibModal, $timeout, METADATA, PreferencesCookieFac, UserProfileSvc, GTMFac, $rootScope, LoginBehaviourConst, FindModalContainerFac) {
            var betAmounts, wagerTypes;
            var errorMessage = "There was an error saving changes";

            (function init() {
                $scope.events = {
                    setRaceProgramPageBetType: _setRaceProgramPageBetType,
                    setBetAmount: _setBetAmount,
                    setRequestBetConfirmation: _setRequestBetConfirmation,
                    cancel: _cancel,
                    setCurrentLoginBehaviour: _setCurrentLoginBehaviour,
                    loginBehaviourChecked: _loginBehaviourChecked
                };

                var prefObj = PreferencesCookieFac.getPreferences();

                betAmounts = _getBetAmountsObj(PreferencesCookieFac.getBetAmounts());
                wagerTypes = METADATA.wagerTypes;

                $scope.data = {
                    raceprogram: wagerTypes[prefObj.bet_type],
                    betamount: betAmounts[prefObj.bet_amount],
                    confirmbetrequest: prefObj.show_bet_confirm == 1 ? {name: 'Yes', value: true} : { name: 'No', value: false},
                    wagertypes: _sortWagerTypes(METADATA.wagerTypes),
                    betamounts: PreferencesCookieFac.getBetAmounts(),
                    errorMessage: null,
                    confirmDialog: null,
                    betRequestValues: [{name: 'Yes', value: true}, {name: 'No', value: false}],
                    loading: false,
                    loginBehaviourOptions: LoginBehaviourConst,
                    currentLoginBehaviour: prefObj.homepageLoginBehaviour
                };
            }());

            function _setRaceProgramPageBetType(id) {
                var prefObj = PreferencesCookieFac.getPreferences();
                prefObj.bet_type = id;
                $scope.data.loading = true;
                PreferencesCookieFac.updatePreferences(prefObj).then(function () {
                    var p = {};
                    p.gaEventCategory = 'Site Click';
                    p.gaEventAction = 'My Profile Page Click';
                    p.gaEventLabel = 'User Preferences | Set Default Bet Type';
                    p.sectionName = GTMFac.Tvg4SectionName();
                    p.screenName = GTMFac.Tvg4ScreenName();
                    p.eventLabel = "MyAccount_SetBetType";

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);

                    $scope.data.raceprogram = wagerTypes[id];
                    $scope.data.loading = false;
                }, function () {
                    _showErrorMessage(errorMessage, "Bet type");
                });
            }

            function _setBetAmount(value) {
                var prefObj = PreferencesCookieFac.getPreferences();
                prefObj.bet_amount = value;
                $scope.data.loading = true;
                PreferencesCookieFac.updatePreferences(prefObj).then(function () {
                    var p = {};
                    p.gaEventCategory = 'Site Click';
                    p.gaEventAction = 'My Profile Page Click';
                    p.gaEventLabel = ' User Preferences | Set Bet Amount';
                    p.gaEventValue = value.toFixed(2);
                    p.sectionName = GTMFac.Tvg4SectionName();
                    p.screenName = GTMFac.Tvg4ScreenName();
                    p.eventLabel = "MyAccount_SetBetAmount";

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);

                    $scope.data.betamount = betAmounts[value];
                    $scope.data.loading = false;
                }, function () {
                    _showErrorMessage(errorMessage, "Bet amount");
                });
            }

            function _setRequestBetConfirmation(value) {
                $scope.data.loading = true;
                UserProfileSvc.setRequestBetConfirmationPreference(value)
                    .then(function (result) {
                        var p = {},
                            betConfirmationValue = value ? 'Yes' : 'No';
                        p.gaEventCategory = 'Site Click';
                        p.gaEventAction = 'My Profile Page Click';
                        p.gaEventLabel = 'User Preferences | Request Bet Confirmation | ' + betConfirmationValue;
                        p.sectionName = GTMFac.Tvg4SectionName();
                        p.screenName = GTMFac.Tvg4ScreenName();
                        p.eventLabel = "MyAccount_RequestBetConfirmation";

                        GTMFac.GTMEvent().send($rootScope, 'siteClick', p);

                        if (result && result.status && result.status != 'Success') {
                            $scope.data.confirmbetrequest = !$scope.data.confirmbetrequest;
                            _showErrorMessage(errorMessage, "Request bet confirmation");
                        }
                        $scope.data.loading = false;
                    },
                    function () {
                        _showErrorMessage(errorMessage, "Request bet confirmation");
                    });
            }

            function _showErrorMessage(message, component) {
                $scope.data.errorMessage = message;
                $scope.data.errorMessageTitle = "Change " + component + " Failed";

                $scope.data.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Login/templates/login-error.html',
                    scope: $scope
                });
                $scope.data.loading = false;
            }

            function _cancel() {
                if ($scope.data.confirmDialog) {
                    $scope.data.confirmDialog.close();
                    $scope.data.confirmDialog = null;
                }

                $timeout(function () {
                    $scope.data.errorMessage = null;
                }, 300);
            }

            function _sortWagerTypes(obj) {
                var myArray = [];
                for (var bet in obj) {
                    if (obj.hasOwnProperty(bet)) {
                        myArray.push(obj[bet]);
                    }
                }
                myArray.sort(function (a, b) {
                    return parseInt(a.Id, 10) - parseInt(b.Id, 10);
                });
                return myArray;
            }

            function _getBetAmountsObj(betAmountsArray) {
                return betAmountsArray.reduce(function (o, v) {
                    o[v.value] = v;
                    return o;
                }, {});
            }

            function _setCurrentLoginBehaviour(currentOption) {
                var prefObj = angular.copy(PreferencesCookieFac.getPreferences());
                var previousCookieValue = prefObj.homepageLoginBehaviour;

                $scope.data.currentLoginBehaviour = currentOption;
                prefObj.homepageLoginBehaviour = currentOption;
                $scope.data.loading = true;
                PreferencesCookieFac.updatePreferences(prefObj).then(function () {
                    $scope.data.loading = false;
                }, function () {
                    $scope.data.currentLoginBehaviour = previousCookieValue;
                    _showErrorMessage(errorMessage, "Login From Homepage");
                });
            }

            function _loginBehaviourChecked (option) {
                return $scope.data.currentLoginBehaviour === option;
            }

        }

        UserPreferencesCtrl.$inject = [
            '$scope',
            '$uibModal',
            '$timeout',
            'METADATA',
            'PreferencesCookieFac',
            'UserProfileSvc',
            'GTMFac',
            '$rootScope',
            'LoginBehaviourConst',
            'FindModalContainerFac'
        ];

        return UserPreferencesCtrl;
    }
);

