'use strict';

define('MyStableFaqCtrl',[],
    function () {
        function MyStableFaqCtrl($filter) {
            var vm = this;

            (function init() {

                vm.model = {
                    faqs: JSON.parse($filter('CMSValue')('myStableFaqInfos'))
                };
            }());

        }

        MyStableFaqCtrl.$inject = ['$filter'];

        return MyStableFaqCtrl;
    }
);

