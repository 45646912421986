"use strict";

define(
    'TimeUtilFac',[
        'lodash'
    ],
    function (_) {

        function TimeUtil($filter) {

            return {

                /***
                 *
                 * @param strDate
                 * @returns number. Returns 0 if couldn't retrieve a valid timestamp from the string parameter
                 *
                 */
                getTimestamp: function (strDate) {
                    if (_.isString(strDate) === false) {
                        return 0;
                    }
                    var numbers = strDate.match(/[0-9]+/g);
                    if (!numbers) {
                        numbers = [0];
                    }
                    return parseInt(numbers[0], 10);
                },

                convertToDateBasedOnTimestamp: function (strDate) {
                    return new Date(this.getTimestamp(strDate));
                },

                getShortTimeWithAMPM: function (date){
                    return $filter('date')(date, "h:mm a");
                }
            };

        }

        TimeUtil.$inject = [
            '$filter'
        ];

        return TimeUtil;

    });

