'use strict';

define(
    'Carousel',[
        'CarouselDir'
    ],
    function (CarouselDir) {

        angular.module('TVG.Carousel', ['ui.bootstrap'])
            .directive('tvgCarousel', CarouselDir)
            .config(['$provide', Decorate]);

            function Decorate($provide) {
                _decorateDirectiveTemplate($provide, 'uibCarouselDirective', 'js/desktop/modules/WidgetsDesktop/components/carousel/templates/carousel-override-template.html');
            }

            function _decorateDirectiveTemplate($provide, directiveName, directiveTemplateUrl) {
                $provide.decorator(directiveName, function($delegate) {
                    var directive = $delegate[0];
                    directive.templateUrl = directiveTemplateUrl;

                    return $delegate;
                });
            }

    }
);

