'use strict';

define(
    'AddAlertsToMyStableCtrl',[
        'lodash'
    ],

    function (_) {

        /***
         * Is receiving by parameter the runner information (horse, jockey, trainer, owner) and the function to close the dialog
         * @constructor
         */
        function AddAlertsToMyStableCtrl($rootScope, $q, MyStableSvc, runner, closeAddMyStableDialog, $location, $route) {
            var vm = this;

            vm.isLoading = true;

            function calculateAllEntitiesAlerts(entities, entityAlert, alertsEntityIndex) {
                if (entityAlert.subscriptions &&
                    entityAlert.subscriptions.length) {
                    var allSelected = entityAlert.inMyStable;
                    if (allSelected) {
                        _.forEach(entityAlert.subscriptions, function (alert) {
                            if (!alert.active) {
                                allSelected = false;
                            }
                        });
                    }
                    entities[alertsEntityIndex].active = allSelected;
                }
            }

            function calculateAllAlertTypes(alertTypes, alertEntities, alertsTypeIndex) {
                var allSelected = true;
                var isThereSubscriptionsAvailable = false;
                _.forEach(alertEntities, function (alert) {
                    if (alert.subscriptions &&
                        alert.subscriptions.length) {
                        isThereSubscriptionsAvailable = true;
                        if (!alert.subscriptions[alertsTypeIndex].active) {
                            allSelected = false;
                        }
                    }
                });
                if (isThereSubscriptionsAvailable) {
                    alertTypes.active = allSelected;
                } else {
                    alertTypes.active = false;
                }

            }

            function fillMyStable(row, column) {
                if (!_.isUndefined(row) && !_.isUndefined(column) && row.subscriptions[column].active) {
                    row.inMyStable = true;
                }
                var activeList = _.find(row.subscriptions, function(column){
                    return column.active;
                });

                if (!activeList) {
                    row.inMyStable = false;
                }
            }

            function refreshSelectAll() {
                var allMyStable = true;
                //verify rows
                _.forEach(vm.data.stableEntities, function (alert, $index) {
                    calculateAllEntitiesAlerts(vm.data.allEntitiesAlerts, alert, $index);
                    // verify myStable value
                    if (!alert.inMyStable) {
                        allMyStable = false;
                    }
                });
                vm.data.addAllMyStable = allMyStable;
                // verify columns
                _.forEach(vm.data.allAlertType, function (alertType, $index) {
                    calculateAllAlertTypes(alertType, vm.data.stableEntities, $index);
                });
            }

            function applyValueToAllRow(rowIndex, value) {
                var alertsRow = vm.data.stableEntities[rowIndex];
                if (alertsRow.subscriptions && alertsRow.subscriptions.length) {
                    _.forEach(alertsRow.subscriptions, function (alert) {
                        alert.active = value;
                    });
                }
            }

            function applyValueToAllColumn(columnIndex, value) {
                _.forEach(vm.data.stableEntities, function (alertRow) {
                    if (alertRow.subscriptions && alertRow.subscriptions.length) {
                        alertRow.subscriptions[columnIndex].active = value;
                    }
                });
            }

            function addAllToMyStable(value) {
                _.forEach(vm.data.stableEntities, function (row) {
                    row.inMyStable = value;
                });
            }

            function formatStableEntitiesUFC(stableEntities) {
                var resUFCEntities = [];
                stableEntities.forEach(function (stableEntitie) {
                    var ufcEntitie = {};

                    ufcEntitie.name = stableEntitie.name;
                    ufcEntitie.subscriptions = MyStableSvc.createSubscriptions(stableEntitie.subscriptions, true);

                    if (stableEntitie.entityType.name.toLowerCase() === 'horse') {
                        ufcEntitie.entityType = 'runner';
                        ufcEntitie.dob = runner.dob;
                    } else {
                        ufcEntitie.entityType = stableEntitie.entityType.name.toLowerCase();
                    }

                    resUFCEntities.push(ufcEntitie);
                });
                return resUFCEntities;
            }

            function createNewSubscriptionsList() {
                return [
                    {
                        active: false,
                        alert: {
                            id: 0,
                            name: "Results"
                        }
                    },
                    {
                        active: false,
                        alert: {
                            id: 1,
                            name: "Race Day"
                        }
                    },
                    {
                        active: false,
                        alert: {
                            id: 2,
                            name: "Entry"
                        }
                    }
                ];
            }

            function getDefaultEmptyStableEntites(stableEntities) {
                var baseStableEntities = [];
                var entities = ["runner", "jockey", "trainer", "owner"];

                // horse, jockey, trainer, owner
                stableEntities.forEach(function(entity, index) {
                    baseStableEntities.push({
                        entityType: MyStableSvc.getEntityType(entities[index]),
                        name: entities[index] === 'runner' ? runner.horse : runner[entities[index]],
                        dob: entities[index] === 'runner' ? runner.dob : null,
                        inMyStable: false,
                        subscriptions: createNewSubscriptionsList()
                    });
                });

                return baseStableEntities;
            }

            vm.handleSelectAll = function () {
                _.forEach(vm.data.allAlertType, function (column, index) {
                    column.active = true;
                    vm.handleAllColumnClick(index);
                });
            };

            vm.closeAddMyStableDialog = closeAddMyStableDialog;

            vm.handleAllToMyStable = function () {
                addAllToMyStable(vm.data.addAllMyStable);
                refreshSelectAll();
            };

            vm.handleAllColumnClick = function (columnIndex) {
                var allSelected = vm.data.allAlertType[columnIndex].active;
                applyValueToAllColumn(columnIndex, allSelected);
                if (allSelected) {
                    addAllToMyStable(allSelected);
                }
                refreshSelectAll();
            };

            vm.handleAllRowClick = function (rowIndex) {
                var allSelected = vm.data.allEntitiesAlerts[rowIndex].active;
                applyValueToAllRow(rowIndex, allSelected);
                vm.data.stableEntities[rowIndex].inMyStable = allSelected;
                refreshSelectAll();
            };
            /*
             * verify if rows/columns are filled
             */
            vm.valueChanged = function (row, column) {
                fillMyStable(row, column);
                refreshSelectAll();
            };

            vm.loadStableEntities = function () {
                if ($rootScope.activeFeatures.myStableSearchByHorseFCPGraph) {
                    vm.data.stableEntities =  getDefaultEmptyStableEntites([runner.horse, runner.jockey, runner.trainer, runner.owner]);
                    refreshSelectAll();
                } else {
                    vm.loadingIsLoading = true;
                    MyStableSvc
                        .getStableEntities(runner.horse, runner.jockey, runner.trainer, runner.owner)//"Thieving Magpie", "Burke, James J", "White, Rick H", "David & Freda Woolley,Langley,Bc")
                        .then(function (stableEntities) {
                            vm.data.stableEntities = stableEntities;
                            refreshSelectAll();
                            vm.loadingIsLoading = false;
                        }).catch(function () {
                        //TODO: Handle Error
                        vm.loadingIsLoading = false;
                    });
                }
            };

            vm.save = function () {

                vm.saveIsLoading = true;

                var addToMyStableDeferredArray = [];
                var addToRaceAlertsDeferred = $q.defer();
                var addToMyStableUFCList;
                var successMessages = [];
                var errorMessages = [];


                //ADD TO MY STABLE
                 var inMyStableEntities = vm.data.stableEntities
                    .filter(function (stableEntity) {
                        return stableEntity.inMyStable === true;
                    });


                if( !_.isArray(inMyStableEntities) || inMyStableEntities.length === 0) {
                    vm.closeAddMyStableMessage();
                    vm.warningMessage = true;
                    vm.saveIsLoading = false;
                    return;
                }

                if ($rootScope.activeFeatures.myStableSearchByHorseFCPGraph) {
                    addToMyStableUFCList = formatStableEntitiesUFC(vm.data.stableEntities);

                    addToMyStableUFCList.forEach(function (stableEntity) {
                       if (stableEntity.subscriptions.length > 0) {

                           MyStableSvc.addToMyStable(stableEntity, $rootScope.user.accountNumber)
                               .then(function () {
                                   var successMessageTpl = "Adding _stableEntityTypeName_ : _stableEntity_ to my stable.";
                                   successMessages.push(successMessageTpl
                                       .replace("_stableEntityTypeName_", stableEntity.entityType)
                                       .replace("_stableEntity_", stableEntity.name));

                               })
                               .catch(function () {
                                   var errorMessageTpl = "Adding _stableEntityTypeName_ : _stableEntity_ to my stable.";
                                   errorMessages.push(
                                       errorMessageTpl
                                           .replace("_stableEntityTypeName_", stableEntity.entityType.name)
                                           .replace("_stableEntity_", stableEntity.name)
                                   );

                               })
                               .finally(function () {
                                   vm.closeAddMyStableMessage();

                                   if (errorMessages.length > 0 || successMessages.length === 0) {
                                       vm.errorMessage = true;
                                   } else {
                                       vm.successMessage = true;
                                   }

                                   vm.saveIsLoading = false;
                           });
                       }
                    });
                } else {
                    inMyStableEntities.forEach(function (stableEntity, index) {
                        addToMyStableDeferredArray[index] = $q.defer();
                        MyStableSvc.addToMyStable(stableEntity)
                            .then(function () {
                                var successMessageTpl = "Adding _stableEntityTypeName_ : _stableEntity_ to my stable.";
                                successMessages.push(successMessageTpl
                                    .replace("_stableEntityTypeName_", stableEntity.entityType.name)
                                    .replace("_stableEntity_", stableEntity.name));
                                addToMyStableDeferredArray[index].resolve();
                            })
                            .catch(function () {
                                var errorMessageTpl = "Adding _stableEntityTypeName_ : _stableEntity_ to my stable.";
                                errorMessages.push(
                                    errorMessageTpl
                                        .replace("_stableEntityTypeName_", stableEntity.entityType.name)
                                        .replace("_stableEntity_", stableEntity.name)
                                );
                                addToMyStableDeferredArray[index].reject();
                            });
                    });

                    //ADD TO RACE ALERTS
                    MyStableSvc.addToRaceAlerts(vm.data.stableEntities, true)
                        .then(function () {
                            var successMessageTpl = "Adding entities to my alerts.";
                            successMessages.push(
                                successMessageTpl
                            );
                            addToRaceAlertsDeferred.resolve();
                        })
                        .catch(function () {
                            var errorMessageTpl = "Adding entities to my alerts.";
                            errorMessages.push(errorMessageTpl);
                            addToRaceAlertsDeferred.reject();
                        });

                    //HANDLING WHEN BOTH PROMISES ARE RESOLVED
                    var promisesArray = addToMyStableDeferredArray
                        .map(function (deferred) {
                            return deferred.promise.then(angular.noop).catch(angular.noop);
                        })
                        .concat([
                            addToRaceAlertsDeferred.promise.then(angular.noop).catch(angular.noop)
                        ]);

                    $q.all(promisesArray)
                        .then(function () {

                            vm.closeAddMyStableMessage();

                            if (errorMessages.length > 0 || successMessages.length == 0) {
                                vm.errorMessage = true;
                            } else {
                                vm.successMessage = true;
                            }
                        }).finally(function () {
                        vm.saveIsLoading = false;
                    });
                }
            };

            vm.closeAddMyStableMessage = function() {
                vm.errorMessage = false;
                vm.successMessage = false;
                vm.warningMessage = false;
            };
            vm.goToMyStable = function () {
                if ($location.path() === '/my-stable') {
                    $route.reload();
                    vm.closeAddMyStableDialog();
                    return;
                }
                $location.path('/my-stable').hash('');
            };

            function init() {
                vm.data = {
                    allEntitiesAlerts: [
                        {active: false},
                        {active: false},
                        {active: false},
                        {active: false}
                    ],
                    allAlertType: [
                        {active: false},
                        {active: false},
                        {active: false}
                    ],
                    addAllMyStable: false
                };

                vm.loadStableEntities();

            }

            init();

        }

        AddAlertsToMyStableCtrl.$inject = [
            '$rootScope',
            '$q',
            'MyStableSvc',
            'runner',
            'closeAddMyStableDialog',
            '$location',
            '$route'
        ];

        return AddAlertsToMyStableCtrl;
    }
);

