'use strict';

define('AmountOptionsFac',['lodash'],
    function (_) {
        function AmountOptionsFac(METADATA, trackSvc) {

            function _removeOtherAmountOptionForHKRaces(currentRace) {
                return function (item) {
                    var allTracks = METADATA.allTracks || [];
                    var trackAbbr = currentRace.trackAbbr;

                    /**
                     *  Due to some inconsistencies on METADATA, we need to guarantee that these tracks abbreviations
                     *  do not have otherAmount displayed for the users even if it they are not present on METADATA
                     */
                    var isHK = _.isUndefined(allTracks[trackAbbr]) ? trackSvc.isHongKongRace(trackAbbr) : trackSvc.isTrackFromHongKong(allTracks[trackAbbr]);

                    return !(isHK && (item.amountString === 'other' || item.amount === null));
                };
            }

            function _roundAmountToTwoDecimalPlaces(amount) {
                return amount % 1 ? (Math.round(amount * 100) / 100) : amount;
            }

            /**
             * @returns {number} 0 if both values are equal. <0 if first value is lesser than the second or >0 if the first value is greater than second value.
             */
            function _compareAmounts(firstAmount, secondAmount) {
                return (typeof firstAmount === 'string' ? parseFloat(firstAmount) : firstAmount) -
                    (typeof secondAmount === 'string' ? parseFloat(secondAmount) : secondAmount);
            }

            /**
             * Given an upward sorted array and an index check the index value and the index's siblings to see
             * which one is equal or is the closest to the provided element.
             *
             * If the difference between two indexes and the value is the same,
             * e.g. idx1 = 1.3, idx2 = 2.6, value = 1.95, then we return the index of the sibling with the
             * highest value, e.g. idx2.
             *
             * @param {Array} array An upward sorted list of elements that contain a property (propertyName) with
             * the value to compare.
             * @param {Object} element The element that contains a property (propertyName) with the value to compare.
             * @param {Number} idx The index of the array where to start the comparison.
             * @param {String} propertyName The name of the property that contains the value to compare.
             * @returns {Number} The index of the array that has the same value of the element or the index with
             * the closest value. Returns -1 if, at least, one of the parameters is not valid.
             */
            function _getIdxOfTheClosestOrEqualElement(array, element, idx, propertyName) {
                var compareResult;

                if (!array.length || !array[idx] || !element || isNaN(parseInt(idx, 10)) || idx < 0 ||
                    idx >= array.length || typeof propertyName !== 'string' || !propertyName.length) {
                    return -1;
                }

                compareResult = _compareAmounts(array[idx][propertyName], element[propertyName]);
                if (compareResult > 0) {
                    if (idx === 0) {
                        return idx;
                    }
                    // Check the previous index
                    // In case of a subtraction that returns a decimal value between 0 and 1 we need this arithmetic processing
                    // to get these decimal values with just two places.
                    // This is used to avoid the problem with the subtraction between floating point values.
                    if (_roundAmountToTwoDecimalPlaces(element[propertyName] - array[idx - 1][propertyName]) <
                        _roundAmountToTwoDecimalPlaces(array[idx][propertyName] - element[propertyName])) {
                        return (idx - 1);
                    }
                    return idx;
                }
                if (compareResult < 0) {
                    if (idx === (array.length - 1)) {
                        return idx;
                    }
                    // Check the following index
                    // In case of a subtraction that returns a decimal value between 0 and 1 we need this arithmetic processing
                    // to get these decimal values with just two houses.
                    // This is used to avoid the problem with the subtraction between floating point values.
                    if (_roundAmountToTwoDecimalPlaces(array[idx + 1][propertyName] - element[propertyName]) <
                        _roundAmountToTwoDecimalPlaces(element[propertyName] - array[idx][propertyName])) {
                        return (idx + 1);
                    }
                    return idx;
                }

                return idx;
            }

            /**
             * Gets the object from the array that is the closest, or equal, to the one provided.
             * The value to be compared/found is extracted from the objects using the value of the
             * "propertyName" parameter.
             * A binary search is used to find the provided value in the upward sorted array.
             *
             * @param {Object} amount The object that contains the "propertyName" property.
             * The value of this property should be a Number or a String that contains a number.
             * @param {Array} amounts An upward sorted array of objects that contain the "propertyName" property.
             * The value of this property should be a Number or a String that contains a number.
             * @param {String} propertyName The name of the property that contains the value to compare.
             * @return {Object} the found amount object or null if, at least, one of the parameters is not valid.
             */
            function _getClosestOrEqualAmountObject(amount, amounts, propertyName) {
                if (!Array.isArray(amounts) || !amounts.length || !amount || typeof propertyName !== 'string' ||
                    !propertyName.length || !amount[propertyName]) {
                    return null;
                }

                // Binary search
                // We pass the name of the property to use when comparing values.
                var idx = _.sortedIndexBy(amounts, amount, propertyName);

                // The function "_.sortedIndex" returns "... the lowest index at which value should be inserted into
                // array in order to maintain its sort order...". If the provided value is greater than any item
                // available in the array it returns the length of the array.
                // In this case, we need to decrease the index in one to be able to point to a correct array index.
                if (idx === amounts.length) {
                    idx -= 1;
                }

                return amounts[_getIdxOfTheClosestOrEqualElement(amounts, amount, idx, propertyName)];
            }

            return {
                compareAmounts: _compareAmounts,
                getClosestOrEqualAmountObject: _getClosestOrEqualAmountObject,
                getIdxOfTheClosestOrEqualElement: _getIdxOfTheClosestOrEqualElement,
                removeOtherAmountOptionForHKRaces: _removeOtherAmountOptionForHKRaces,
                roundAmountToTwoDecimalPlaces: _roundAmountToTwoDecimalPlaces
            };
        }

        AmountOptionsFac.$inject = [
            'METADATA',
            'trackSvc'
        ];

        return AmountOptionsFac;
    });

