'use strict';

define('DateTimeFormatters',[],
    function () {
        /***
         * Get relative string day between given date and current date
         * @param raceDate
         * @returns {string}
         *                      - "ystrd" When Yesterday;
         *                      - "today" When Today;
         *                      - "tmrrw" When Tomorrow;
         *                      - "" Fallback;
         * @private
         */
        function _getRelativeDayBasedOnGivenDate(raceDate, todayDate) {
            var raceDateDay = new Date(raceDate.getFullYear(), raceDate.getMonth(), raceDate.getDate()),
                todayWithoutHours = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate()),
                tomorrow = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() + 1),
                yesterday = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 1),
                relativeDay = "";

            if (raceDateDay.getTime() === todayWithoutHours.getTime()) {
                relativeDay = "today";
            } else if (raceDateDay.getTime() === yesterday.getTime()) {
                relativeDay = "ystrd";
            } else if (raceDateDay.getTime() === tomorrow.getTime()) {
                relativeDay = "tmrrw";
            }

            return relativeDay;
        }

        return {
            getRelativeDayBasedOnGivenDate: _getRelativeDayBasedOnGivenDate
        };
    });
