'use strict';

define('RunnersSelectionCtrl',[],
    function() {

        function RunnersSelectionCtrl($log) {
            var _this = this;

            function _validateScopeParams() {
                if (_this.raceNumber !== 0 && (!_this.raceNumber || isNaN(Number(_this.raceNumber)))) {
                    $log.error('runnersSelection directive: raceNumber is not a number.');
                }

                if (!Array.isArray(_this.selection)) {
                    $log.error('runnersSelection directive: selection is not an Array.');
                }
            }

            (function init() {
                _validateScopeParams();
            }());
        }

        RunnersSelectionCtrl.$inject = [
            '$log'
        ];

        return RunnersSelectionCtrl;
    });

