'use strict';
define('MTPCtrl',['lodash', 'MTPDateFac'], function (_, MTPDateFac) {
    function MTPController($scope) {
        var STATUS = ['NO_MTP', 'NEAR', 'MED', 'FAR', 'NO-LIVE', 'LIVE', 'WAIT-RESULT', 'RESULT', 'CANCELLED'];
        var ctrl = this;
        var MTPDateObject;
        var MTPDateValues;

        function _getMTPValue() {
            var mtp = 0;

            if (ctrl.data.mtp && ctrl.data.mtp <= 60) {
                mtp = ctrl.data.mtp;
            } else {
                MTPDateObject = new MTPDateFac(ctrl.data.postTime);
                MTPDateValues = MTPDateObject.getMTP();
                mtp = MTPDateValues.mtp;
            }

            return mtp;
        }

        function _getMTP() {
            var statusIndex = 0;
            var mtp = 0;
            var label = '';

            switch (ctrl.data.statusCode) {
                case 'SK':
                    statusIndex = ctrl.data.liveStreaming ? 5 : 4;
                    label = 'Race Off';
                    break;
                case 'RO':
                    statusIndex = 7;
                    label = 'Result';
                    break;
                case 'C':
                    statusIndex = 8;
                    label = 'Cancelled';
                    break;
                default:
                    mtp = _getMTPValue();

                    label = mtp + 'm';
                    if (mtp <= 5) {
                        statusIndex = 1;
                    } else if (mtp <= 15) {
                        statusIndex = 2;
                    } else if (mtp <= 60) {
                        statusIndex = 3;
                    } else {
                        statusIndex = 0;
                    }
            }

            return {
                value: mtp,
                label: label,
                status: STATUS[statusIndex]
            };
        }

        var unbindWatch = $scope.$watch('$ctrl.data', function () {
            ctrl.mtp = _getMTP();

            ctrl.showIcon = ctrl.mtp.value <= 60;

            if (!ctrl.showIcon && MTPDateValues) {
                ctrl.timeWithDay = MTPDateValues.timeWithDay;
            }
        });

        $scope.$on('$destroy', unbindWatch);
    }

    MTPController.$inject = ['$scope'];
    return MTPController;
});

