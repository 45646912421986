"use strict";

define('StableEntityType',[],function () {

    function StableEntityType(id, name){
        this.id = id;
        this.name = name;
    }

    return StableEntityType;

});

