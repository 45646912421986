'use strict';

define('WidgetsCtrl',[
		'Content'
	],
	function () {
		function WidgetsCtrl($rootScope, $scope, ContentFac) {

			$scope.cms = [];
			$scope.carouselInterval = 5000;

            if($rootScope.activeFeatures.useCmsCarousel) {
                ContentFac.getNamespaceContent('widget').then(function(data) {
                    $scope.cms = data;
                });
            } else {
                $scope.cms = [{
                        type: 'carousel',
                        value: []
                    }];
            }

		}

		return WidgetsCtrl;
	}
);

