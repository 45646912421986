'use strict';

define('TvgHostsFac',['lodash'], function (_) {

    /**
     * @TODO move this to configuration repository!
     *
     * Factory to check if a hostname matches any of TVG products
     */
    function TvgHostsFac() {

        /**
         * Is the host from NJ?
         *
         * NOTE: if your local development host is different,
         * this may not work properly!
         *
         * @param  {String}  host  Host to check
         * @return {Boolean}       Is a NJ host
         */
        function isNJ(host) {

            return _containsHost(host, [
                '4njbets.dev.tvg.com',
                '4njbets.qa.us.betfair.local',
                '4njbets-qa.us.betfair.local',
                '4njbets-qa.us.betfair.com',
                '4njbets.stage.us.betfair.local',
                '4njbets-staging.us.betfair.local',
                '4njbets-staging.us.betfair.com',
                '4njbets.us.betfair.com'
            ]);
        }

        /**
         * Is the host from PA?
         *
         * NOTE: if your local development host is different,
         * this may not work properly!
         *
         * @param  {String}  host  Host to check
         * @return {Boolean}       Is a PA host
         */
        function isPA(host) {

            return _containsHost(host, [
                'pabets.dev.tvg.com',
                'pabets.qa.tvg.com',
                'pabets-qa.tvg.com',
                'pabets.stage.tvg.com',
                'pabets-staging.tvg.com',
                'pabets.tvg.com'
            ]);
        }

        /**
         * Is the host from IA?
         *
         * NOTE: if your local development host is different,
         * this may not work properly!
         *
         * @param  {String}  host  Host to check
         * @return {Boolean}       Is a IA host
         */
        function isIA(host) {

            return _containsHost(host, [
                'ia.dev.tvg.com',
                'ia.qa.tvg.com',
                'ia-qa.tvg.com',
                'ia.stage.tvg.com',
                'ia-staging.tvg.com',
                'ia.tvg.com'
            ]);
        }

        /**
         * Is the host from generic TVG?
         *
         * NOTE: if your local development host is different,
         * this may not work properly!
         *
         * @param  {String}  host  Host to check
         * @return {Boolean}       Is a TVG host
         */
        function isTVG(host) {

            return _containsHost(host, [
                'tvg4.dev.tvg.com',
                'touch.dev.tvg.com',
                'spl.dev.tvg.com',
                'spl.qa.tvg.com',
                'stage.tvg.com',
                'touch.tvg.com',
                'us.tvg.com',
                'www-qa.tvg.com',
                'www-staging.tvg.com',
                'www.tvg.com'
            ]);
        }

        /**
         * Check if a host is inside a list of hosts
         * (doesn't have to be an exact match!)
         *
         * @param  {String} host  Host to find
         * @param  {List}   list  List of hosts to look into
         * @return {Boolean}      Is the host in the list?
         */
        function _containsHost(host, list) {
            host = (host || '').toLowerCase();
            list = list || [];

            return !!_.find(list, function (tvgHost) {
                tvgHost = (tvgHost || '').toLowerCase();
                return host.indexOf(tvgHost) != -1;
            });
        }


        // exports
        return {
            isNJ: isNJ,
            isPA: isPA,
            isIA: isIA,
            isTVG: isTVG,
            _containsHost: _containsHost
        };
    }

    // TvgHostsFac.$inject = [];

    return TvgHostsFac;
});

