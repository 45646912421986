'use strict';

define(
    'ProgramPageRunnersFac',["lodash", "RunnerComparators"],
    function (_, RunnerComparators) {

        function ProgramPageRunnersFac($rootScope) {

            function _programPageRunnerBuilder() {

                var programPageRunner = {};

                return {
                    withUniqueID: function(runnerDetails, raceId) {
                        programPageRunner.uniqueID = runnerDetails.horseID + raceId;
                        return this;
                    },
                    withRunnerDetails: function (runnerDetails) {
                        _.merge(programPageRunner, runnerDetails);
                        return this;
                    },
                    withHandicappingInfo: function (handicappingInfo) {
                        programPageRunner.handicappingInfo = {};
                        _.merge(programPageRunner.handicappingInfo, handicappingInfo);
                        return this;
                    },
                    build: function () {
                        return programPageRunner;
                    }
                };

            }

            var _currentRace;
            var _handicapping;
            var _runners = [];
            var _column = '', _reverse = false;

            function _updateCurrentRace(race) {
                _currentRace = race;
                _createProgramPageRunners();
            }

            function _updateHandicapping(handicapping) {
                _handicapping = handicapping;
                _createProgramPageRunners();
            }

            function _clearRunnersArray() {
                //clear array without breaking reference.
                _runners.splice(0, _runners.length);
            }

            function _addPickText(pick){
                switch (pick){
                    case 1: return '1st';
                    case 2: return '2nd';
                    case 3: return '3rd';
                    default : return '';
                }
            }

            function _addPickInfo(pickNumber){
                if(_.isArray(_handicapping.freePicks)){
                    return _.result(_.find(_handicapping.freePicks, function(obj) {
                        return obj.number === pickNumber;
                    }), 'info');
                }
            }

            function _clear() {
                _clearRunnersArray();
                _handicapping = undefined;
                _currentRace = undefined;
            }

            function _clearWhenChangingRace() {
                _handicapping = undefined;
                _currentRace = undefined;
                _column = '';
                _reverse = false;
            }

            function _getHandicapInfoBasedOnHorseId(runners, horseId) {
                var handicap = runners.filter(function (elem) {
                    return elem.horseId === horseId;
                });

                return (handicap.length === 0) ? undefined : handicap[0];
            }

            function _isValidHeader(header) {
                return _.isString(header) && _.trim(header).length > 0;
            }

            function _getBasicHandicappingFromRunner(runner) {
                return {
                    jockey_trainer_info: {
                        Weight: runner.weight,
                        Med: runner.medication
                    }
                };
            }

            function _createProgramPageRunnersBasicHandicapping() {

                _clearRunnersArray();

                var raceId = _currentRace.id;

                _currentRace.bettingInterests.forEach(function (runner) {

                    var handicapping = _getBasicHandicappingFromRunner(runner);

                    var programPageRunner = _programPageRunnerBuilder()
                        .withUniqueID(runner, raceId)
                        .withRunnerDetails(runner)
                        .withHandicappingInfo(handicapping)
                        .build();
                    _runners.push(programPageRunner);
                });
                _sortColumn(_column, _reverse, false);
            }

            function _createProgramPageRunners() {

                if (!angular.isDefined(_currentRace)) {
                    return;
                }

                if (angular.isDefined(_currentRace) && !angular.isDefined(_handicapping)) {
                    return _createProgramPageRunnersBasicHandicapping();
                }

                _clearRunnersArray();

                var raceId = _currentRace.id;

                _currentRace.bettingInterests.forEach(function (runner) {

                    var handicappingByRunner = {};

                    _handicapping.handicapData.forEach(function (handicap) {

                        var handicapInfoForRunner = _getHandicapInfoBasedOnHorseId(handicap.runners, runner.horseID);

                        if(!angular.isDefined(handicapInfoForRunner))
                            return;

                        //TODO: Remove product specifc login from factories and services.
                        //Specific behaviour based on product (touch or tvg4) should be on UI layers (controllers, directives, templates)
                        if($rootScope.layout === 'desktop'){
                            handicap.headers = handicap.headers.filter(_isValidHeader);
                        }

                        var output = handicap.headers.reduce(function (obj, current, index) {
                            if (!current || current === "") {
                                return obj;
                            }
                            obj[current] = handicapInfoForRunner.values[index];
                            return obj;
                        }, {});

                        //Add the pick also
                        if(handicap.type === 'snapshot_freepick_info'){
                            output.pick = handicapInfoForRunner.pick;
                            if(typeof output.pick !== 'undefined')
                                output.pickText = _addPickText(handicapInfoForRunner.pick);
                                output.pickInfo = _addPickInfo(output.pick);
                        }

                        handicappingByRunner[handicap.type] = output;
                    });

                    var programPageRunner = _programPageRunnerBuilder()
                        .withUniqueID(runner, raceId)
                        .withRunnerDetails(runner)
                        .withHandicappingInfo(handicappingByRunner)
                        .build();

                    _runners.push(programPageRunner);

                });
                _sortColumn(_column, _reverse, true);
                $rootScope.$emit('runners_updated');
            }

            /***
             * get the comparator's method based on columnName
             * @param columnName
             * @returns comparator based on columnName
             * @private
             */
            function _getComparatorBasedOnColumnName(columnName, handicappingAvailable) {
                var comparators = {
                    "ODDS": RunnerComparators.byOdds,
                    "WEIGHT": $rootScope.userSession && handicappingAvailable ? RunnerComparators.byWeight : RunnerComparators.byWeightLoggedOut,
                    "DAYS OFF": RunnerComparators.byDaysOff,
                    "POWER RATING": RunnerComparators.byPowerRating,
                    "WINS/ STARTS": RunnerComparators.byWinsStarts,
                    "1ST": RunnerComparators.byFirst,
                    "2ND": RunnerComparators.bySecond,
                    "3RD": RunnerComparators.byThird,
                    "STARTS": RunnerComparators.byStarts,
                    "# OF RACES": RunnerComparators.byNumberOfRaces,
                    "EARLY": RunnerComparators.byEarly,
                    "FINISH": RunnerComparators.byFinish,
                    "MIDDLE": RunnerComparators.byMiddle,
                    "AVG CLASS": RunnerComparators.byAvgClass,
                    "AVG DIST": RunnerComparators.byAvgDist,
                    "AVG SPEED": RunnerComparators.byAvgSpeed,
                    "HIGH SPEED": RunnerComparators.byHighSpeed,
                    "LAST CLASS": RunnerComparators.byLastClass,
                    "HORSEID": RunnerComparators.byId,
                    "AGE": RunnerComparators.byAge
                };

                return comparators[columnName.toUpperCase()];
            }

            function _generateEventLabels(eventLabel, gaEventLabel) {
                return {eventLabel: eventLabel, gaEventLabel: gaEventLabel};
            }

            /***
             * get the GTM event Labels based
             * @param columnName
             * @returns comparator based on columnName
             * @private
             * TODO Fill null entries when they become define
             */
            function _getGTMEventLabelsBasedOnColumnName(columnName) {
                var columns = {
                    "ODDS": _generateEventLabels("RaceInfo-OddsSort", "Race Card | Sort Odds"),
                    "WEIGHT": _generateEventLabels("RaceInfo-SummarySort", "Horse Details | Summary | Sort Weight"),
                    "DAYS OFF": null,
                    "POWER RATING": _generateEventLabels("RaceInfo-SnapshotSort", "Horse Details | Snapshot | Sort Power Rating"),
                    "WINS/ STARTS": null,
                    "1ST": null,
                    "2ND": null,
                    "3RD": null,
                    "STARTS": _generateEventLabels("RaceInfo-JockeyTrainer", "Horse Details | Jockey Trainer | Sort Starts"),
                    "# OF RACES": _generateEventLabels("RaceInfo-Pace", "Horse Details | Pace | Clear Number of Races"),
                    "EARLY": null,
                    "FINISH": null,
                    "MIDDLE": null,
                    "AVG CLASS": null,
                    "AVG DIST": null,
                    "AVG SPEED": _generateEventLabels("RaceInfo-SpeedClass", "Horse Details | Speed Class | Sort Avg Speed"),
                    "HIGH SPEED": null,
                    "LAST CLASS": null,
                    "HORSEID": _generateEventLabels("RaceInfo-HorseNumber", "Race Card | Sort Horse Number"),
                    "AGE": null
                };

                return columns[columnName.toUpperCase()];
            }

            /***
             * sort programPageRunners based on the columnName whether in ascending or descending order
             * @param columnName
             * @private
             */
            function _sortColumn(columnName, reverse, handicappingAvailable) {
                var comparator = _getComparatorBasedOnColumnName(columnName, handicappingAvailable);
                if (comparator) {
                    _runners.sort(comparator.bind(Object.create(_runners, {isReverse: {value: reverse}})));
                    if (reverse) {
                        _runners.reverse();
                    }
                }
                _reverse = reverse;
                _column = columnName;
            }

            return {
                runners: _runners,
                clear: _clear,
                sortColumn: _sortColumn,
                clearWhenChangingRace: _clearWhenChangingRace,
                updateCurrentRace: _updateCurrentRace,
                updateHandicapping: _updateHandicapping,
                getGTMEventLabelsBasedOnColumnName: _getGTMEventLabelsBasedOnColumnName
            };

        }

        ProgramPageRunnersFac.$inject = [
            '$rootScope'
        ];

        return ProgramPageRunnersFac;

    });


