'use strict';
define('HeaderButtonDir',[],function () {

    function HeaderButtonDir($rootScope, GTMFac) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                buttonLabel: '=',
                buttonInfo: '=',
                buttonIcon: '=',
                buttonSublabel: '=?bind',
                dirQaLabel: '=',
                buttonEvent: "=",
                closeEvent: "=",
                buttonClass: "=",
                windowWidth: "=",
                windowHeight: "=",
                openNewWindow: "=",
                betslipOpener: "=",
                displayArrow: "=",
                status: "=",
                href: "@"
            },
            replace: false,
            templateUrl: "js/desktop/modules/Account/templates/desktop/header-button-directive.html",
            link: function (scope, element) {

                // Create events scope object for betslip directive
                scope.events = {};
                // Create data scope object for betslip directive
                scope.data = {};
                scope.teste = function () {
                    if (scope.buttonInfo === 'ACCOUNT') {

                        var p = {
                            gaEventCategory: 'Site Click',
                            gaEventAction: 'Program Page Click',
                            gaEventLabel: 'Header | My Account',
                            screenName: $rootScope.appTitle
                        };
                        GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
                    }
                };
                if (scope.buttonEvent) {
                    element.bind('click', function (event) {
                        if (event) {
                            event.preventDefault();
                        }
                        scope.buttonEvent();
                    });
                }

                // Set track abreviation for betslip button
                if (scope.betslipOpener) {
                    scope.$watchCollection('data', function (newValue) {
                        if (newValue.trackAbbr && newValue.trackAbbr !== "") {
                            scope.buttonSublabel = '(' + newValue.trackAbbr + ')';
                        }
                    });
                }
            }
        };
    }
    HeaderButtonDir.$inject = ['$rootScope', 'GTMFac'];
    return HeaderButtonDir;
});

