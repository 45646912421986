'use strict';

define(
    'FooterLinksCtrl',[],
    function () {
        function FooterLinksCtrl ($filter, $log, $rootScope) {
            var vm = this;
            var key;

            var _getKey = function() {
                if($rootScope.activeFeatures.filterTVG3Access) {
                    key = localStorage.getItem('tvg3Link') === 'true' ? 'footerLinksLabels' :  'footerLinksLabelsNoTVG3';
                } else {
                    key = 'footerLinksLabels';
                }

                try {
                    vm.links = JSON.parse($filter('CMSValue')(key));
                } catch(e) {
                    vm.links = {};
                }
            };

            var undbind = $rootScope.$on('changedTvg3Link', function(){
                _getKey();
            });

            $rootScope.$on('$destroy', function(){
                undbind();
            });

            _getKey();
            vm.social = $filter('CMSValue')('tVG4SocialLinks');
        }


        FooterLinksCtrl.$inject = ['$filter', '$log', '$rootScope'];

        return FooterLinksCtrl;
    }
);

