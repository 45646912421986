"use strict";

define('AccountSummaryDetailEntity',[],function () {

    function AccountSummaryDetailEntity(id, amount, transactionDate, description){
        this.id = id;
        this.amount = amount;
        this.transactionDate = transactionDate;
        this.description = description;
    }

    return AccountSummaryDetailEntity;

});

