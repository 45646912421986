"use strict";

define('TrackInfoFac',[], function() {
    function TrackInfoFac(
        $rootScope,
        CookieFac,
        TimeUtilFac,
        poller,
        $q,
        $http,
        UserSessionSvc,
        WagerProfileFac,
        GraphPastTracks
    ) {
        var dayTracksPoller;
        var trackInfoSvc;
        var TracksFac;

        function Track(
            trackAbbr,
            perfAbbr,
            name,
            currentRace,
            races,
            featured,
            currentWagerableRace,
            currentWagerableRacePostTime
        ) {
            this.trackAbbr = trackAbbr;
            this.perfAbbr = perfAbbr;
            this.name = name;
            this.currentRace = currentRace;
            this.races = races;
            this.featured = featured;
            this.currentWagerableRace = currentWagerableRace;
            this.currentWagerableRacePostTime = currentWagerableRacePostTime;
        }

        TracksFac = {
            build: function(rawData) {
                return new Track(
                    rawData.Abbreviation,
                    rawData.PerfAbbr,
                    rawData.Name,
                    rawData.CurrentRace,
                    rawData.Races,
                    rawData.Featured,
                    rawData.CurrentWagerableRace,
                    rawData.CurrentWagerableRacePostTime
                );
            },
            apiResponseTransformer: function(data) {
                var dayTracks = [];
                _.forEach(data, function(track) {
                    track.CurrentWagerableRacePostTime = TimeUtilFac.convertToDateBasedOnTimestamp(
                        track.CurrentWagerableRacePostTime
                    );
                    if (track.CurrentWagerableRacePostTime !== 0) {
                        dayTracks.push(TracksFac.build(track));
                    }
                });
                return dayTracks;
            }
        };

        trackInfoSvc = {
            pollDayTracks: function() {
                dayTracksPoller = poller.get(trackInfoSvc, {
                    action: "getDayTracks",
                    delay: 30000,
                    argumentsArray: [{}]
                });

                return dayTracksPoller;
            },

            stopPollingDayTracks: function(deferred) {
                if (dayTracksPoller) {
                    dayTracksPoller.removeDeferred(deferred);
                }
            },

            /**
             * Get today's tracks.
             * (must wait for session startup!)
             *
             * @return {Promise} Request
             */
            getDayTracks: function() {
                if (!UserSessionSvc.hasSession()) {
                    return UserSessionSvc.callAfterSession(
                        trackInfoSvc.getDayTracks.bind(trackInfoSvc)
                    );
                }

                var wagerProfile = WagerProfileFac.getSessionOrAllTracksProfile();
                var url = "ajax/track/wp/" + wagerProfile;
                var deferred = $q.defer();

                if (typeof $rootScope.loadingTrack === "undefined") {
                    $rootScope.loadingTrack = true;
                }

                $http.get(url).then(
                    function(response) {
                        var dayTracks = TracksFac.apiResponseTransformer(
                            response.data
                        );
                        deferred.resolve(dayTracks);
                        $rootScope.loadingTrack = false;
                    },
                    function(e) {
                        deferred.reject(e);
                    }
                );

                return deferred.promise;
            },
            getAllTracks: function() {
                var wagerProfile = WagerProfileFac.getSessionOrGenericProfile();
                var deferred = $q.defer();
                var racesUSA = {};
                var otherRaces = {};

                if ($rootScope.activeFeatures.fetchTrackInfoFromFCP) {
                    GraphPastTracks.getAllTracks(wagerProfile)
                        .then(function(response) {
                            response.map(function(track) {
                                var country =
                                    (track.location &&
                                        track.location.country) ||
                                    "Other";
                                var state =
                                    (track.location && track.location.state) ||
                                    "";
                                var trackInfo = {
                                    Abbreviation: track.code,
                                    CountryName: country,
                                    Name: track.name,
                                    State: state
                                };

                                if (country === "USA") {
                                    if (!racesUSA[state]) {
                                        racesUSA[state] = [];
                                    }
                                    racesUSA[state].push(trackInfo);
                                } else {
                                    if (!otherRaces[country]) {
                                        otherRaces[country] = [];
                                    }
                                    otherRaces[country].push(trackInfo);
                                }
                            });
                            return deferred.resolve({
                                otherRaces: otherRaces,
                                racesUSA: racesUSA
                            });
                        })
                        .catch(function(e) {
                            deferred.reject(e);
                        });
                } else {
                    $http.get("ajax/Track").then(
                        function(response) {
                            var data = response.data;

                            Object.keys(data).forEach(function(key) {
                                var value = data[key];
                                var country = value.CountryName;
                                var state;
                                if (country === "USA") {
                                    state = value.State;
                                    racesUSA[state] = racesUSA[state] || [];
                                    racesUSA[state].push(value);
                                } else {
                                    otherRaces[country] =
                                        otherRaces[country] || [];
                                    otherRaces[country].push(value);
                                }
                            });

                            return deferred.resolve({
                                otherRaces: otherRaces,
                                racesUSA: racesUSA
                            });
                        },
                        function(e) {
                            deferred.reject(e);
                        }
                    );
                }

                return deferred.promise;
            },

            getTrackDetails: function(abbr) {
                var url = "ajax/Track/" + abbr;
                var wagerProfile = WagerProfileFac.getSessionOrAllTracksProfile();
                var deferred = $q.defer();

                if ($rootScope.activeFeatures.fetchTrackInfoFromFCP) {
                    GraphPastTracks.getTrackByTrackCode(wagerProfile, abbr)
                        .then(function(response) {
                            var track = (response && response[0]) || {};
                            deferred.resolve({
                                CountryName:
                                    (track.location &&
                                        track.location.country) ||
                                    "Other",
                                ImageLink: track.imageLink,
                                ImageName:
                                    "https://storage.googleapis.com/tvg-static/static/cms_files/" +
                                    track.imageName,
                                StateName:
                                    (track.location && track.location.state) ||
                                    "",
                                TrackName: track.name
                            });
                        })
                        .catch(function(e) {
                            deferred.reject(e);
                        });
                } else {
                    $http.get(url).then(
                        function(response) {
                            deferred.resolve(response.data);
                        },
                        function(e) {
                            deferred.reject(e);
                        }
                    );
                }

                return deferred.promise;
            },

            /**
             * Get track by date
             * (must wait for session startup!)
             *
             * @param  {String} date  Track's date
             * @return {Promise}      Request
             */
            getTracksByDate: function(date) {
                if (!UserSessionSvc.hasSession()) {
                    return UserSessionSvc.callAfterSession(
                        trackInfoSvc.getTracksByDate.bind(trackInfoSvc, date)
                    );
                }

                var wagerProfile = WagerProfileFac.getSessionOrAllTracksProfile();
                var url = "ajax/track/wp/" + wagerProfile + "/date/" + date;
                var deferred = $q.defer();
                date = date || moment().format("YYYY-MM-DD");

                if (typeof $rootScope.loadingTrack === "undefined") {
                    $rootScope.loadingTrack = true;
                }

                $http.get(url).then(
                    function(response) {
                        var dayTracks = TracksFac.apiResponseTransformer(
                            response.data
                        );
                        deferred.resolve(dayTracks);
                        $rootScope.loadingTrack = false;
                    },
                    function(e) {
                        deferred.reject(e);
                    }
                );

                return deferred.promise;
            }
        };

        return {
            pollDayTracks: trackInfoSvc.pollDayTracks,
            stopPollingDayTracks: trackInfoSvc.stopPollingDayTracks,
            getDayTracks: trackInfoSvc.getDayTracks,
            getAllTracks: trackInfoSvc.getAllTracks,
            getTracksByDate: trackInfoSvc.getTracksByDate,
            getTrackDetails: trackInfoSvc.getTrackDetails
        };
    }

    TrackInfoFac.$inject = [
        "$rootScope",
        "CookieFac",
        "TimeUtilFac",
        "poller",
        "$q",
        "$http",
        "UserSessionSvc",
        "WagerProfileFac",
        "GraphPastTracks"
    ];

    return TrackInfoFac;
});

