'use strict';

define('RunnersSelectionDir',[],
    function() {

        /**
         * @ngdoc directive
         * @name runnersSelection
         *
         * @description
         * This directive shows the number of the horses selected for the current bet.
         * If the type of bet implies a position order then the selections are prefixed
         * with the matching ordinal number.
         *
         * @example
         <p runners-selection="runnersList"
            data-column-type="betColumnType"
            data-race-number="betRaceNumber"
            data-is-key-or-box="true"></p>
         */
        function runnersSelection() {

            return {
                restrict: 'EA',
                scope: {
                    selection: '=runnersSelection',
                    columnType: '=',
                    raceNumber: '=',
                    isBetKeyOrBox: '=isKeyOrBox'
                },
                templateUrl: 'js/desktop/modules/RaceTrackCurrentBets/templates/runners-selection-template.html',
                controller: 'RunnersSelectionCtrl as vm',
                bindToController: true
            };
        }

        runnersSelection.$inject = [];

        return runnersSelection;
    });

