'use strict';

define('CarryOverPoolsCtrl',[
        'Content'
    ],
    function () {
        function CarryOverPoolsCtrl($scope, RaceUrlFac, GTMFac, RacesPageGTMFac, $rootScope,
                                    GraphCarryOverPools, WagerProfileFac) {
            function _getCarryOverPoolsFromGraph() {
                $scope.carryOverPools = [];

                GraphCarryOverPools.get({
                    wagerProfile: WagerProfileFac.getSessionOrGenericProfile()
                }).then(function (data) {
                    var carryOverPools = $rootScope.activeFeatures.carryoverPoolsLinkBetableRaces ? data.carryOverPools.filter(function(pool) {
                        return pool.race &&
                            pool.race.status &&
                            pool.race.status.code &&
                            (pool.race.status.code === 'O' || pool.race.status.code === 'IC');
                    }) : data.carryOverPools;
                    $scope.carryOverPools = carryOverPools;
                    $scope.hasCarryOverPools = carryOverPools.length > 0;
                    $scope.$emit('CarryOverPools::HasData');
                    $scope.$emit('appReady', 'carryoverPools');
                    $scope.$apply();
                });
            }

            function _handleBetSlipOpening(openWindowCallback) {
                if (!$rootScope.userSession) {
                    $rootScope.$emit("callLoginRequiredModal", openWindowCallback);
                } else {
                    setTimeout(openWindowCallback, 400);
                }
            }

            function _getRaceUrl(race) {
                var raceNumber;
                var trackAbbr;
                var trackName;

                if (race && race.raceNumber) {
                    raceNumber = race.raceNumber;
                    trackAbbr = race.trackCode;
                    trackName = race.trackName;

                    return RaceUrlFac.calculateRacePath(trackAbbr, trackName, raceNumber);
                }

                return '';
            }

            function _sendGTMEvent(pool) {
                var event = {
                    gaEventLabel: 'RightSideBar | TodaysCarryoverPools | ' + pool.TrackName,
                    eventLabel: 'RightSideBar-TodaysCarryoverPools'
                };

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel(event.gaEventLabel)
                    .withEventLabel(event.eventLabel)
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));

                RacesPageGTMFac.clickNavigateToRace({
                    raceUrl: _getRaceUrl(pool),
                    originComponent: "Carryover Pools",
                    trackName: pool.trackName,
                    raceNumber: pool.raceNumber,
                    mtp: undefined, // we dont have this field
                });
            }

            function _getBetSlipUrl(pool) {
                if (pool && pool.trackCode && pool.raceNumber && pool.wagerType && pool.wagerType.id) {
                    return '/betslip/'+ pool.trackCode + '/Day/' + pool.raceNumber + '?bettype=' + pool.wagerType.id;
                }
                return '';
            }

            (function init() {
                $scope.carryOverPools = [];
                $scope.hasCarryOverPools = false;

                var sessionSubscription = $scope.$on('session', function () {
                    _getCarryOverPoolsFromGraph();

                    // kill $on session right away after 1st call, because session keeps
                    // being called every time the user changes route
                    sessionSubscription();
                });

                $scope.$on('login', _getCarryOverPoolsFromGraph);
                $scope.$on('userLogout', _getCarryOverPoolsFromGraph);

                $scope.events = {
                    getRaceUrl: _getRaceUrl,
                    sendGTMEvent: _sendGTMEvent,
                    getBetSlipUrl: _getBetSlipUrl,
                    handleBetSlipOpening: _handleBetSlipOpening
                };
            }());
        }

        CarryOverPoolsCtrl.$inject = [
            '$scope',
            'RaceUrlFac',
            'GTMFac',
            'RacesPageGTMFac',
            '$rootScope',
            'GraphCarryOverPools',
            'WagerProfileFac'
        ];

        return CarryOverPoolsCtrl;
    });

