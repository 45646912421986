'use strict';

define('FooterLinksDir',[
    'FooterLinksCtrl'
], function(FooterLinksCtrl) {
    return {
        templateUrl: 'js/desktop/modules/FooterLinks/templates/footer-links-directive.html',
        controller: FooterLinksCtrl,
        controllerAs: 'vm'
    };
});

