'use strict';

define('GeoComplyLoginSvc',['lodash'], function (_) {

    function GeoComplyLoginSvc(
            $http,
            $rootScope,
            $log,
            $timeout,
            $q,
            $filter,
            $window,
            GeoComplySvc,
            CookieFac,
            RequestContextHeaders,
            FavoriteTracksFac,
            ConfigurationFac,
            GTMFac
        ) {

        var _errorDictionary = { //.Net Api errors
            INVALID_CREDENTIALS: 'loginFailureInvalidCredentials', //USA Api error
            ACCOUNT_LOCKED: 'loginFailureAccountLocked', //USA Api error
            ACCOUNT_DISABLED: 'loginFailureAccountDisabled', //USA Api error
            LOGIN_REDIRECT: 'loginApplicationRedirect', //USA Api error
            LOGIN_FAILED: 'loginFailureGeneric', //USA Api error
            GEO_SERVICE_FAILURE: 'loginFailureGeneric', //USA Api error
            OUT_OF_BOUNDARY: 'geoLocationOutOfBoundary', //USA Api error
            UNCONFIRMED_BOUNDARY: 'geoLocationUnconfirmedOrInvalid', //USA Api error
            INVALID_GEO: 'geoLocationUnconfirmedOrInvalid', //USA Api error
            BLOCKED_SERVICE: 'geoLocationBlockedService', //USA Api error
            BLOCKED_SOFTWARE: 'geoLocationBlockedSoftware', //USA Api error

            0: 'geoClientErrorUnexpected',
            801: 'geoClientGeoLocationNotSupportedByBrowser', //Solus SDK Error
            802: 'geoClientGeoLocationUnavailable', //Solus SDK Error
            803: 'geoLocationBlockedByDevice', //Solus SDK Error
            804: 'geoClientGeoLocationTimeout' //Solus SDK Error
        };

        GeoComplySvc.setupGeoComply();

        /**
         * @TODO  move to proper service
         *
         * Generate microservices' required headers
         * @return {Object}  Headers
         */
        function _generateServiceHeaders(overrides) {
            var clientApp = $window.clientApp;
            var locationContext = $window.locationContext;
            var tvgContext = RequestContextHeaders[clientApp + '_' + locationContext];
            var defaults = {
                'accept': 'application/json',
                'content-type': 'application/json',
                'x-clientapp': clientApp,
                'x-tvg-context': tvgContext
            };

            return _.defaults(overrides || {}, defaults);
        }

        /**
         * Register login 'success' using GTM
         * @param  {Object} response  API login response
         */
        function _logLoginSuccessWithGTM(response) {
            /* istanbul ignore next */
            response = response || {};

            // user details are defined here, because GTMFac may not have them
            // already defined in rootScope to use
            var event = {
                gaEventCategory: 'Login',
                gaEventAction: 'Login Success',
                accountId: response.AccountNumber,
                residenceState: response.HomeAddress ?
                    response.HomeAddress.StateAbbr : null,
                loginStatus: 'Logged In',
                registrationStatus: 'Registered'
            };

            GTMFac.GTMEvent()
                .send($rootScope, 'loginSuccess', event);
        }

        /**
         *
         * Sets necessary global user attributes and broadcasts login specific events.
         * It also sets the cookie hasLoginOnce to true with an expiration date of 365 days.
         * @private
         */
        function _setUpGlobalApplicationUserAttributes(response) {
            FavoriteTracksFac.loadFavoriteTracks(response.accountNumber).finally(function() {
                $rootScope.user = {
                    accountNumber: response.accountNumber,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    phoneNumber: response.primaryPhone,
                    userName: response.userName,
                    wagerStatus: response.wagerStatus,
                    homeAddress: response.homeAddress,
                    mailingAddress: response.mailingAddress,
                    emailAddress: response.emailAddress,
                    signalProviderId: response.signalProviderId,
                    transportId: response.transportId
                };

                $rootScope.userSession = true;

                _logLoginSuccessWithGTM(response);

                $rootScope.$broadcast('login', true);

                CookieFac.setCookie("hasLoginOnce", true, {path: "/", expires: 365});

                $timeout(function () {
                    //Timer Needed to insure that the pref cookie has been
                    //stored by the browser.
                    $rootScope.$broadcast('user', $rootScope.user);
                }, 100);
            });
            return response;
        }

        /**
         * Handles the response error from login
         * @param response
         * @returns {{message: string, messageKey: (*|string), redirectUrl: (*|string)}}
         * @private
         */
        function _handleErrorResponse(response) {

            //Error cause is used for geo location specific errors
            var errorKey = response.exception ? response.exception : (!_.isUndefined(response.code) ? response.code : null),
                redirectUrl = response.redirectUrl || ''; //TODO: Adjust this redirectUrl when USA is gonna be used on PA and IA

            var message = _errorDictionary[errorKey] ? $filter('CMSValue')(_errorDictionary[errorKey]) : $filter('CMSValue')('loginFailureGeneric');

            if (redirectUrl && redirectUrl.indexOf("http://") == -1 && redirectUrl.indexOf("https://") == -1) {
                redirectUrl = '//' + redirectUrl; // I don't really understand this double slash. Keeping it to avoid breaking stuff.
            }

            return {
                message: message,
                redirectUrl: redirectUrl
            };
        }

        /**
         * Get the login endpoint URL (either from AWS or GKE)
         * @return {String}  Login endpoint
         */
        function _getServiceUrl(path) {
            var baseUrl = '/usa/v2'; // from AWS
            var isGKE = !!($rootScope.activeFeatures || {}).gkeLogin;

            if (isGKE) {
                baseUrl = ConfigurationFac.getServiceApiUrl('usa'); // from GKE
            }

            return  baseUrl + path;
        }

        /**
         * Executes user login request
         * @param userData
         * @private
         */
        function _loginRequest(userData) {
            return function (geoPacket) {
                return $http({
                    method: 'POST',
                    url: _getServiceUrl('/login'),
                    data: {
                        account: userData.accountField,
                        stateAbbr: userData.stateAbbr,
                        pin: userData.pinField,
                        geo: geoPacket
                    },
                    headers: _generateServiceHeaders()
                })
                    .then(function (response) {
                        return response.data.userDetails;
                    })
                    .then(_setUpGlobalApplicationUserAttributes)
                    .then(function () { //Is this object even necessary? Keeping it for now ... TODO: Remove it asap.
                        return {
                            message: '',
                            messageKey: 'Valid',
                            redirectUrl: ''
                        };
                    })
                    .finally(function () {
                        $rootScope.loginOk = true;
                    });
            };
        }

        /**
         * Logs in user based on geo localization
         * Receives an object with: accountField,pinField,loginType,stateAbbr
         * @param params
         * @private
         */
        function _login(params) {
            $rootScope.loginOk = false;

            if (!GeoComplySvc.getGeoClient()) {
                $log.error('Non existing GeoClient');
                $rootScope.loginOk = true;
                return $q.reject(_handleErrorResponse({code: 0}));
            }

            return GeoComplySvc.getGeoPacket(params.accountField)
                .then(_loginRequest(params))
                .catch(function (err) {
                    var errorResponse = _handleErrorResponse(err.data || err);
                    $rootScope.$broadcast('loginError', errorResponse);
                    return $q.reject(errorResponse);
                }).finally(function () {
                    $rootScope.loginOk = true;
                });
        }

        /**
         * Logs out the current user.
         * @returns {*}
         * @private
         */
        function _logout() {
            $rootScope.logoutOk = false;

            return $http({
                method: 'POST',
                url: _getServiceUrl('/logout'),
                headers: _generateServiceHeaders()
            })
                .then(function (response) {
                    return response.data;
                })
                .then(function (response) {
                    $rootScope.user = null;
                    $rootScope.userSession = false;
                    FavoriteTracksFac.clearFavoriteList();
                    $rootScope.$broadcast('logout');
                    /*
                        TODO: luis almeida
                        created another event for logout, as I think this is the "real" logout:
                        this only fires when the user actually presses the logout button.
                        Lets review how we fire session / user / logout / login events
                    */
                    $rootScope.$broadcast('userLogout');
                    return response;
                })
                .catch(function (error) {
                    return $q.reject(error.data);
                })
                .finally(function () {
                    $rootScope.logoutOk = true;
                });
        }

        return {
            login: _login,
            logout: _logout
        };

    }

    GeoComplyLoginSvc.$inject = [
        '$http',
        '$rootScope',
        '$log',
        '$timeout',
        '$q',
        '$filter',
        '$window',
        'GeoComplySvc',
        'CookieFac',
        'RequestContextHeaders',
        'FavoriteTracksFac',
        'ConfigurationFac',
        'GTMFac'
    ];

    return GeoComplyLoginSvc;
});

