'use strict';

define('PasswordRecoveryGtmFac',[], function () {

    function PasswordRecoveryGtmFac(PasswordRecoveryEventsSvc, GTMFac, $rootScope) {

        function _eventShowResetPassword() {
            if (!PasswordRecoveryEventsSvc.eventShowResetPassword) {
                return;
            }

            PasswordRecoveryEventsSvc.eventShowResetPassword.subscribe(function () {
                var gtmEvent = {
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Password Setup',
                    siteVersion: 'desktop'
                };
                GTMFac.GTMEvent().send($rootScope, '', gtmEvent);
            });
        }

        function _eventShowResetPin() {
            if (!PasswordRecoveryEventsSvc.eventShowResetPin) {
                return;
            }

            PasswordRecoveryEventsSvc.eventShowResetPin.subscribe(function () {
                var gtmEvent = {
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Pin Setup',
                    siteVersion: 'desktop'
                };
                GTMFac.GTMEvent().send($rootScope, '', gtmEvent);
            });
        }

        function _eventResetPinSubmit() {
            if (!PasswordRecoveryEventsSvc.eventResetPinSubmit) {
                return;
            }

            PasswordRecoveryEventsSvc.eventResetPinSubmit.subscribe(function () {
                var gtmEvent = {
                    eventLabel: 'Credentials Recovery-SubmitPin',
                    gaEventAction: 'Credentials Recovery Page Click',
                    gaEventCategory: 'Site Click',
                    gaEventLabel: 'Credentials Recovery | Submit Pin',
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Pin Setup',
                    siteVersion: 'desktop'
                };
                GTMFac.GTMEvent().send($rootScope, 'siteClick', gtmEvent);
            });
        }

        function _eventResetPasswordSubmit() {
            if (!PasswordRecoveryEventsSvc.eventResetPasswordSubmit) {
                return;
            }

            PasswordRecoveryEventsSvc.eventResetPasswordSubmit.subscribe(function () {
                var gtmEvent = {
                    eventLabel: 'Credentials Recovery-SubmitPassword',
                    gaEventAction: 'Credentials Recovery Page Click',
                    gaEventCategory: 'Site Click',
                    gaEventLabel: 'Credentials Recovery | Submit Password',
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Password Setup',
                    siteVersion: 'desktop'
                };
                GTMFac.GTMEvent().send($rootScope, 'siteClick', gtmEvent);
            });
        }

        function _eventResetPasswordSuccess() {
            if (!PasswordRecoveryEventsSvc.eventResetPasswordSuccess) {
                return;
            }

            PasswordRecoveryEventsSvc.eventResetPasswordSuccess.subscribe(function () {
                var gtmEvent = {
                    eventLabel: 'Credentials Recovery-SubmitPasswordSuccess',
                    gaEventAction: 'Credentials Recovery Page Click',
                    gaEventCategory: 'Feedback',
                    gaEventLabel: 'Credentials Recovery | Submit Password Success',
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Password Setup',
                    siteVersion: 'desktop'
                };
                GTMFac.GTMEvent().send($rootScope, 'feedback', gtmEvent);
            });
        }

        function _eventResetPinSuccess() {
            if (!PasswordRecoveryEventsSvc.eventResetPinSuccess) {
                return;
            }

            PasswordRecoveryEventsSvc.eventResetPinSuccess.subscribe(function () {
                var gtmEvent = {
                    eventLabel: 'Credentials Recovery-SubmitPinSuccess',
                    gaEventAction: 'Credentials Recovery Page Click',
                    gaEventCategory: 'Feedback',
                    gaEventLabel: 'Credentials Recovery | Submit Pin Success',
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Pin Setup',
                    siteVersion: 'desktop'
                };
                GTMFac.GTMEvent().send($rootScope, 'feedback', gtmEvent);
            });
        }

        function _eventResetPinError() {
            if (!PasswordRecoveryEventsSvc.eventResetPinError) {
                return;
            }

            PasswordRecoveryEventsSvc.eventResetPinError.subscribe(function (ev, data) {
                var gtmEvent = {
                    errorType: 'Credentials Recovery Pin Submit Error',
                    errorMessage: data.errorMessage,
                    gaEventAction: 'Credentials Recovery Pin Submit Error',
                    gaEventCategory: 'Site',
                    gaEventLabel: data.errorMessage,
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Pin Setup',
                    siteVersion: 'desktop'
                };
                GTMFac.GTMEvent().send($rootScope, '', gtmEvent);
            });
        }

        function _eventResetPasswordError() {
            if (!PasswordRecoveryEventsSvc.eventResetPasswordError) {
                return;
            }

            PasswordRecoveryEventsSvc.eventResetPasswordError.subscribe(function (ev, data) {
                var gtmEvent = {
                    errorType: 'Credentials Recovery Password Submit Error',
                    errorMessage: data.errorMessage,
                    gaEventAction: 'Credentials Recovery Password Submit Error',
                    gaEventCategory: 'Site',
                    gaEventLabel: data.errorMessage,
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Password Setup',
                    siteVersion: 'desktop'
                };
                GTMFac.GTMEvent().send($rootScope, '', gtmEvent);
            });
        }

        // User submits credentials recovery request
        function _eventSubmitForgotCredentials() {
            if (!PasswordRecoveryEventsSvc.eventSubmitForgotCredentials) {
                return;
            }

            PasswordRecoveryEventsSvc.eventSubmitForgotCredentials.subscribe(function () {
                var gtmEvent = {
                    gaEventAction: 'Credentials Recovery Page Click',
                    gaEventCategory: 'Site Click',
                    gaEventLabel: 'Credentials Recovery | Submit Request',
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Start',
                    eventLabel: 'Credentials Recovery-SubmitRequest'
                };
                GTMFac.GTMEvent().send($rootScope, 'siteClick', gtmEvent);
            });
        }

        // Error occurs on submitting credential recovery request.
        // Apply event when something goes wrong
        function _eventForgotCredentialsError() {
            if (!PasswordRecoveryEventsSvc.eventForgotCredentialsError) {
                return;
            }

            PasswordRecoveryEventsSvc.eventForgotCredentialsError.subscribe(function () {
                var gtmEvent = {
                    gaEventAction: 'Credentials Recovery Submit Error',
                    gaEventCategory: 'Site',
                    gaEventLabel: 'We are not looking so good! Apparently something went wrong on our end, please try again. If the problem persists please contact our customer support.',
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Start',
                    errorType: 'Credentials Recovery Submit Error',
                    errorMessage: 'We are not looking so good! Apparently something went wrong on our end, please try again. If the problem persists please contact our customer support.'
                };
                GTMFac.GTMEvent().send($rootScope, '', gtmEvent);
            });
        }

        // Error occurs on submitting credential recovery request.
        // Apply event when token has expired
        function _eventTokenExpired() {
            if (!PasswordRecoveryEventsSvc.eventTokenExpired) {
                return;
            }

            PasswordRecoveryEventsSvc.eventTokenExpired.subscribe(function () {
                var gtmEvent = {
                    gaEventAction: 'Credentials Recovery Submit Error',
                    gaEventCategory: 'Site',
                    gaEventLabel: 'The link has now expired.',
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Start',
                    errorType: 'Credentials Recovery Submit Error',
                    errorMessage: 'The link has now expired.'
                };
                GTMFac.GTMEvent().send($rootScope, '', gtmEvent);
            });
        }

        // User sees the something went wrong web page
        function _eventSomethingWentWrong() {
            if(!PasswordRecoveryEventsSvc.eventSomethingWentWrong) {
                return;
            }

            PasswordRecoveryEventsSvc.eventSomethingWentWrong.subscribe(function () {
                var gtmEvent = {
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Something Went Wrong'
                };

                GTMFac.GTMEvent().send($rootScope, '', gtmEvent);
            });
        }

        // User views the submit success web page
        function _eventForgotCredentialsSuccess() {
            if (!PasswordRecoveryEventsSvc.eventForgotCredentialsSuccess) {
                return;
            }

            PasswordRecoveryEventsSvc.eventForgotCredentialsSuccess.subscribe(function () {
                var gtmEvent = {
                    screenName: 'Credentials Recovery',
                    sectionName: 'Credentials Recovery | Submit Success'
                };
                GTMFac.GTMEvent().send($rootScope, '', gtmEvent);
            });
        }

        // User clicks on the "Go to TVG.com" button
        function _eventUserClicksGoToTvg() {
            if (!PasswordRecoveryEventsSvc.eventUserClicksGoToTvg) {
                return;
            }

            PasswordRecoveryEventsSvc.eventUserClicksGoToTvg.subscribe(function () {
                var gtmEvent = {
                    gaEventAction: 'Credentials Recovery Page Click',
                    gaEventCategory: 'Site Click',
                    gaEventLabel: 'Credentials Recovery | TVGCOM Link',
                    screenName: GTMFac.Tvg4ScreenName(),
                    sectionName: GTMFac.Tvg4SectionName(),
                    eventLabel: 'Credentials Recovery-TVGCOMLink'
                };
                GTMFac.GTMEvent().send($rootScope, 'siteClick', gtmEvent);
            });
        }

        return {
            register: function () {
                //STEP 1
                _eventSubmitForgotCredentials();
                _eventForgotCredentialsError();
                _eventTokenExpired();
                _eventSomethingWentWrong();
                _eventForgotCredentialsSuccess();
                _eventUserClicksGoToTvg();

                //STEP 2
                _eventShowResetPassword();
                _eventShowResetPin();
                _eventResetPinSubmit();
                _eventResetPasswordSubmit();
                _eventResetPasswordSuccess();
                _eventResetPinSuccess();
                _eventResetPinError();
                _eventResetPasswordError();
            }
        };
    }

    PasswordRecoveryGtmFac.$inject = [
        'PasswordRecoveryEventsSvc',
        'GTMFac',
        '$rootScope'
    ];

    return PasswordRecoveryGtmFac;

});

