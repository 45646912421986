"use strict";

define(
    'StableEntityBuilder',[
        "StableEntity"
    ],
    function (StableEntity) {

        function builder() {
            var stableEntity = new StableEntity();

            return {
                withId: function (id) {
                    stableEntity.id = id;
                    return this;
                },
                withName: function (name) {
                    stableEntity.name = name;
                    return this;
                },
                withEntityType: function (entityType){
                    stableEntity.entityType = entityType;
                    return this;
                },
                withSubscriptions: function (subscriptions){
                    stableEntity.subscriptions = subscriptions;
                    return this;
                },
                withInMyStable: function (inMyStable){
                    stableEntity.inMyStable = inMyStable;
                    return this;
                },
                build: function () {
                    return stableEntity;
                }
            };
        }

        return builder;
    }
);

