'use strict';
define('RunBlockMediatorFactory',['lodash'], function (_) {
    var RunBlockMediatorFactory = function ($rootScope, $location, $route, LocationUnreloadFac, RaceUrlFac) {
            // Used to get all unique routes in the app to provide validation of routes
            // passed from outside modules
            function getAllRoutes() {
                var appRoutes = [];
                angular.forEach($route.routes, function (config, route) {
                    appRoutes.push(route);
                });
                return _.uniq(appRoutes);
            }

            //Programatically navigate to page
            function applyRouteChange(route) {
                var routeSplit = [];
                if (route.indexOf('#') >= 0) {
                    routeSplit = route.split('#');
                    changeRoute(routeSplit[0], routeSplit[1]);
                } else {
                    changeRoute(route, '');
                }
                forceApplyChanges();
            }


            // Check wheter route change needs login event and nav to route
            function navToRoute(route, routeNeedsLogin) {
                if(routeNeedsLogin && !$rootScope.user) {
                    var unbindLoginEvent = $rootScope.$on("login", function() {
                        unbindLoginEvent();
                        applyRouteChange(route);
                    });
                } else {
                    applyRouteChange(route);
                }
            }

            //active refresh on program page if we already are on program page
            function changeRoute(path, hash) {
                var samePathWithQueryParameter = $location.url();
                // splits by path and query parameter
                // might have to go back to test if it works going from track to race page
                var newUrl = path.split(/[\?/]+/);
                if ((($location.url().match('/racetracks/') &&
                    path.match('/racetracks/')) ||
                    ($location.url().match('/greyhounds/') &&
                    path.match('/greyhounds/'))) &&  (!_.isEqual($location.search(), {})
                    || ($location.search() && !newUrl[4]))
                ) {
                    LocationUnreloadFac.pathChange(
                        path,
                        true,
                        RaceUrlFac.getRouteParamsForRaceBasedOnLocationPath(path),
                        'race:Changed');
                } else {
                    $location.url(path).hash(hash);
                }
                if($location.url() != samePathWithQueryParameter && $location.path() == samePathWithQueryParameter){
                    $route.reload();
                }
            }

            // This short function will make sure the route provided matches any of the
            // defined routes
            function validateRoute(route) {
                //In case we have hashes on our route
                var routeSplit = route.indexOf('/') >= 0
                    ? route.split('/')
                    : [route];
                return !!(getAllRoutes().indexOf(routeSplit[0]) >= 0);
            }

            function validateRouteLogin(route, userSession) {
                if (userSession) {
                    return false;
                }

                if(route === '/wager-rewards' && $rootScope.activeFeatures.wagerRewardsApp) {
                    return false;
                }

                if ($rootScope.activeFeatures.equibaseHandicapStore && route === '/handicapping/handicapping-store') {
                    return true;
                }

                var appRoutes = $route.routes;
                var routeObj = appRoutes[route] || {};

                return routeObj.needsLogIn;
            }

            function forceApplyChanges() {
                //eslint-disable-next-line
                if (!_.isUndefined($rootScope.$$phase) && $rootScope.$$phase !== '$apply' && $rootScope.$$phase !== '$digest') {
                    $rootScope.$apply();
                }
            }

            return {validateRoute: validateRoute, validateRouteLogin: validateRouteLogin, navToRoute: navToRoute};
        };

    RunBlockMediatorFactory.$inject = [
        '$rootScope',
        '$location',
        '$route',
        'LocationUnreloadFac',
        'RaceUrlFac'
    ];

    return RunBlockMediatorFactory;
});

