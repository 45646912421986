'use strict';

define('RaceTodayEntity',[],function() {
    function RaceTodayEntity(id, name, type, time, trackName, trackAbbr, raceNumber, mtp) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.time = time;
        this.trackName = trackName;
        this.trackAbbr = trackAbbr;
        this.raceNumber = raceNumber;
        this.mtp = mtp;
    }
    return RaceTodayEntity;
});

