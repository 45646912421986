'use strict';

define('MdrCompileHtml',[],function () {

    function MdrCompileHtml($compile) {
        return {
            restrict: 'EA',
            scope: {
                tpl: "=mdrCompileHtmlTpl",
                model: "=mdrCompileHtmlModel"
            },
            link: function (scope, elem) {
                var childScope = scope.$new();
                var newScope;
                elem.html($compile(scope.tpl)(childScope));

                var unwatch = scope.$watch('model', function(){
                    childScope.$destroy();
                    if(newScope) {
                        newScope.$destroy();
                    }
                    newScope = scope.$new();
                    elem.html($compile(scope.tpl)(newScope));
                });

                elem.on('$destroy', function() {
                    unwatch();
                });
            }
        };
    }

    MdrCompileHtml.$inject = [
        '$compile'
    ];

    return MdrCompileHtml;
});

