'use strict';

define(
    'GenericComparators',[],
    function GenericComparators() {

        function GenericComparators() {
        }

        GenericComparators.greaterThan = function (x, y, transformFunc) {
            if(typeof transformFunc === "function"){
                x = transformFunc(x);
                y = transformFunc(y);
            }
            return x > y;
        };

        GenericComparators.lessThan = function (x, y, transformFunc) {
            if(typeof transformFunc === "function"){
                x = transformFunc(x);
                y = transformFunc(y);
            }
            return x < y;
        };

        GenericComparators.byNumber = function (item1, item2, callback) {
            if (GenericComparators.greaterThan(item1, item2, callback)) {
                return 1;
            } else if (GenericComparators.lessThan(item1, item2, callback)) {
                return -1;
            }
            return 0;
        };

        return GenericComparators;
    }
);

