'use strict';

define(
    'RaceProgramHeaderCtrl',['Mediator'],
    function (mediator) {

        function RaceProgramHeaderCtrl($scope, $rootScope, RaceUrlFac, METADATA, CalculatePurseFac,
            DistanceConverterFac, TrackPerformanceProvider, GraphRssFeed, WagerProfileFac, $filter, $log, GTMFac, $timeout) {
            var routeParams = RaceUrlFac.getRouteParamsForRaceBasedOnLocationPath();
            $scope.data = {
                loading: true,
                error: null,
                raceNumber: routeParams.raceNumber,
                trackAbbr: routeParams.trackAbbr,
                trackName: routeParams.trackName,
                racesForTrack: [],
                showDescription: false,
                wagerDetails: "js/desktop/modules/Races/templates/desktop/wager-details-template.html"
            };
            var graphqlEnabled = $rootScope.activeFeatures.graphqlEnabled;
            var graphqlVolatileDataToggle = graphqlEnabled && $rootScope.activeFeatures.graphqlVolatileData;
            var graphqlRaceProgramToggle = graphqlEnabled && graphqlVolatileDataToggle && $rootScope.activeFeatures.graphqlProgramPage;

            $scope.trackChangedLoading = false;
            function init() {
                $scope.data.loading = true;
                $scope.data.cenas = "cenas";
                $scope.profile = WagerProfileFac.getSessionOrGenericProfile();

                if (!routeParams.race) {
                    var queryParams = {
                        trackAbbr: routeParams.trackAbbr,
                        wagerProfile: WagerProfileFac.getSessionOrGenericProfile()
                    };
                    GraphRssFeed.get(queryParams)
                        .then(function (races) {
                            if (races.length > 0) {
                                var lastRaceStatus = races[races.length - 1].status;
                                $scope.closestRace = races[0].mtp;
                                races.forEach(function (race) {
                                    var purseMinimized = CalculatePurseFac.calculateMinimizedPurse(race);
                                    if (race.status === 'RO' || race.status === 'MC') {
                                        race.isOver = true;
                                    }
                                    if (race.status === 'SK') {
                                        race.isOff = true;
                                    }
                                    if (race.status === 'C') {
                                        race.isCanceled = true;
                                    }

                                    if (race.isGreyhound == true) {
                                        race.clickableUrl = '/greyhounds/' + routeParams.trackAbbr + '/' + routeParams.trackName + '?race=' + race.raceNumber;
                                    } else {
                                        race.clickableUrl = '/racetracks/' + routeParams.trackAbbr + '/' + routeParams.trackName + '?race=' + race.raceNumber;
                                    }
                                    if (['RO', 'MC', 'SK', 'C'].indexOf(lastRaceStatus) > -1) {
                                        if ($scope.$root && !$scope.$root.$$phase) {
                                            $scope.$apply(function () {
                                                $scope.hideGreenButton = true;
                                            });
                                        }
                                    }

                                    race.mtp < $scope.closestRace || $scope.closestRace == 0 ? $scope.closestRace = race.mtp : $scope.closestRace;
                                    race.postTime = new Date(race.postTime);
                                    race.purse = purseMinimized;

                                    return race;
                                });

                                $scope.data.races = races;

                            } else {
                                // displays standard message if no races exist
                                if ($scope.$root && !$scope.$root.$$phase) {
                                    $scope.$apply(function () {
                                        $scope.noActiveRaces = true;
                                    });
                                }

                            }
                        })
                        .catch(function (error) {
                            if ($scope.$root && !$scope.$root.$$phase) {
                                $scope.$apply(function () {
                                    $scope.noActiveRaces = true;
                                });
                            }
                            $log.error(error);
                            return null;
                        });
                };
            };

            function _updateCurrentRacesValues() {
                routeParams = RaceUrlFac.getRacesInfoFromRouteParams();
                $scope.data.raceNumber = routeParams.raceNumber;
                $scope.data.currentRace = TrackPerformanceProvider.getRace($scope.data.raceNumber);
                $scope.data.trackAbbr = routeParams.trackAbbr;
                if ($scope.data.currentRace) {
                    $scope.data.raceType = $scope.data.currentRace.raceType ? {
                        Name: $scope.data.currentRace.raceType.name,
                        Abbreviation: $scope.data.currentRace.raceType.code,
                        Id: $scope.data.currentRace.raceType.id
                    } : METADATA.raceTypes ? METADATA.raceTypes[$scope.data.currentRace.raceTypeId] : undefined;

                    $scope.data.purseMinimized = CalculatePurseFac.calculateMinimizedPurse($scope.data.currentRace);
                    //TODO: convertDistance should no longer be used, instead use raceDistance { name } from graph
                    $scope.data.distance = $scope.data.currentRace.distance;
                    $scope.data.distanceConverted = DistanceConverterFac.convertDistance($scope.data.currentRace.distance);
                    $scope.data.currentRace.wagerTypes = $scope.data.currentRace.wagerTypes.filter(function (wt) {
                        return !_isWagerTypeCombination(wt) && !(wt.isBox || wt.isKey || wt.isWheel);
                    });
                }

                if ($rootScope.$$phase !== '$apply' && $rootScope.$$phase !== '$digest') {
                    $scope.$apply();
                }

                if (!$scope.trackChangedLoading) {
                    $scope.data.loading = false;
                }
            }
            function _isWagerTypeCombination(wagerType) {
                return angular.isDefined(wagerType) && wagerType.hasOwnProperty("name") && wagerType.name.indexOf("/") > -1;
            }

            function _changeShowDescription() {
                $scope.data.showDescription = !$scope.data.showDescription;
            }

            function _showDescription() {
                $scope.data.showDescription = true;

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Race Info | ' + 'Open Race Details List')
                    .withEventLabel('RaceInfo-OpenRaceDetails')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
            }

            function _hideDescription() {
                $scope.data.showDescription = false;

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Race Info | ' + 'Close Race Details List')
                    .withEventLabel('RaceInfo-CloseRaceDetails')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
            }

            function _handleBetSlipOpening(openWindowCallback) {
                if (!$rootScope.userSession) {
                    $rootScope.$emit("callLoginRequiredModal", openWindowCallback);
                } else {
                    openWindowCallback();
                }
            }

            function _isBetTypeClickable() {
                return !!$scope.data.currentRace.raceOpenForBetting;
            }

            function _sendGTMwagerType(wager) {
                mediator.dispatch('PPMA_BET_TYPE_SELECTOR', {
                    betName: wager.name,
                });
            }

            $scope._navigateToProgramPage = function (trackAbbr, trackName, races) {
                var currentDate = new Date();
                var currentDateIso = currentDate.toISOString();

                var filteredActiveRaces = races.filter(function (race) {
                    return race.postTime.toISOString() > currentDateIso;
                });

                if (filteredActiveRaces.length) {
                    var firstAvailableRaceNumber = filteredActiveRaces[0].raceNumber;
                    if ($scope.data.races[0].isGreyhound == true) {
                        location.href = '/greyhounds/' + trackAbbr + '/' + trackName + '?race=' + firstAvailableRaceNumber;
                    } else {
                        location.href = '/racetracks/' + trackAbbr + '/' + trackName + '?race=' + firstAvailableRaceNumber;
                    }
                }
            };

            $scope.events = {
                changeShowDescription: _changeShowDescription,
                hideDescription: _hideDescription,
                showDescription: _showDescription,
                isWagerTypeCombination: _isWagerTypeCombination,
                handleBetSlipOpening: _handleBetSlipOpening,
                isBetTypeClickable: _isBetTypeClickable,
                sendGTMwagerType: _sendGTMwagerType
            };

            var unbind = [];
            unbind.push($rootScope.$on('trackDataUpdated', function () {
                $scope.trackChangedLoading = false;
                _updateCurrentRacesValues();
            }));

            unbind.push($rootScope.$on('race:Changed', function () {
                _updateCurrentRacesValues();
                if (graphqlRaceProgramToggle) {
                    $scope.data.loading = true;
                }
            }));

            unbind.push($rootScope.$on('trackChanged', function () {
                $scope.trackChangedLoading = true;
                $scope.data.loading = true;
            }));

            unbind.push($rootScope.$on('login', function () {
                if ($scope.profile !== WagerProfileFac.getSessionOrGenericProfile()) {
                    init();
                }
            }));

            $scope.$on('$destroy', function () {
                _.forEach(unbind, function (bind) {
                    bind();
                });
            });

            init();
        }

        RaceProgramHeaderCtrl.$inject = [
            '$scope',
            '$rootScope',
            'RaceUrlFac',
            'METADATA',
            'CalculatePurseFac',
            'DistanceConverterFac',
            'TrackPerformanceProvider',
            'GraphRssFeed',
            'WagerProfileFac',
            '$filter',
            '$log',
            'GTMFac',
            "$timeout"
        ];

        return RaceProgramHeaderCtrl;
    }
);

