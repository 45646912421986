'use strict';

define(
    'HandicappingRaceReplaysFac',[],
    function () {

        function HandicappingRaceReplaysFac($q, RaceReplaysGateway, $filter, $rootScope, GTMFac) {
            var HandicappingRaceReplays;

            function _searchHorseName(horseNameToSearch, maxRecords) {
                return RaceReplaysGateway.getHorsesByName(horseNameToSearch, maxRecords);
            }

            HandicappingRaceReplays = {
                horseNamesFinder : {
                    _oldSearchText: undefined,
                    _oldDataSource: undefined,
                    _inMemorySearch: function (searchText) {
                        var defer = $q.defer();
                        var ctx = this;
                        var horseNames = this._oldDataSource
                            .filter(function (horse) {
                                return horse.toLowerCase().indexOf(searchText) > -1;
                            })
                            .sort();
                        ctx._oldSearchText = searchText;
                        ctx._oldDataSource = horseNames;
                        defer.resolve(horseNames);

                        return defer.promise;
                    },
                    _apiMaxRecords: 50,
                    //for some reason API is not returning the number of elements specified in the max records parameter.
                    //eg: When search by horse name is called with 100 as max records the API sometimes returns 99 items even when there are more than 100 available on database.
                    //    Since we're using the length of items returned by API to decide if we should perform an in-memory search or api search that behaviour might result in bugs.
                    //    To mitigate that issue we're setting the property "_apiMaxRecordsRange" so we don't compare the dataSource length with the maxRecords but with some kind of maxRecordsRange.
                    _apiMaxRecordsRange: 5,
                    _apiSearch: function (searchText) {
                        var ctx = this;
                        return _searchHorseName(searchText, ctx._apiMaxRecords)
                            .then(function (horses) {
                                    var horseNames = horses
                                        .map(function (horse) {
                                            return horse.horseName;
                                        })
                                        .sort();
                                    ctx._oldSearchText = searchText;
                                    ctx._oldDataSource = horseNames;
                                    return horseNames;
                                },
                                function () {
                                });
                    },
                    execute: function (searchText) {
                        var ctx = this;
                        searchText = searchText.toLowerCase();

                        if (_.isString(ctx._oldSearchText)
                            && searchText.indexOf(ctx._oldSearchText) > -1
                            //here we're using the _apiMaxRecordsRange workaround
                            && (ctx._oldDataSource.length + ctx._apiMaxRecordsRange) < ctx._apiMaxRecords
                        ) {
                            return ctx._inMemorySearch(searchText);
                        }

                        return ctx._apiSearch(searchText);
                    }
                },
                buildResultReplay: function(raceReplay) {
                    return {
                        raceDate: $filter('date')(raceReplay.raceDate, 'yyyy-MM-dd'),
                        postTime: raceReplay.postTime,
                        isReplay: true,
                        trackAbbr: raceReplay.trackAbbr,
                        trackName: raceReplay.trackName,
                        raceNumber: raceReplay.raceNumber,
                        perfAbbr: raceReplay.perfAbbr,
                        instanceId: 'replay-video',
                        containerId: 'replay-video',
                        autoPlay: false,
                        useRCNMappingFromCMS: false,
                        replayFileName: raceReplay.replayFileName
                    };
                },
                sendGtmEvent: function(gaEventLabel, eventLabel) {
                    var eventData = GTMFac.gaEventBuilder()
                        .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Page Click')
                        .withGaEventCategory("Site Click")
                        .withGaEventLabel(gaEventLabel)
                        .withEventLabel(eventLabel)
                        .build();

                    var genericEvent = GTMFac.genericEvent(eventData);

                    GTMFac.GTMEvent().send($rootScope, "siteClick", genericEvent);
                }
            };

            return {
                horseNamesFinder: HandicappingRaceReplays.horseNamesFinder,
                buildResultReplay: HandicappingRaceReplays.buildResultReplay,
                sendGtmEvent: HandicappingRaceReplays.sendGtmEvent
            };
        }

        HandicappingRaceReplaysFac.$inject = [
            '$q',
            'RaceReplaysGateway',
            '$filter',
            '$rootScope',
            'GTMFac'
        ];

        return HandicappingRaceReplaysFac;
    });

