"use strict";

define('RaceReplaysSearchByDateCtrl',[], function() {
    function RaceReplaysSearchByDateCtrl(
        $scope,
        $rootScope,
        TrackInfoFac,
        RaceReplaysGateway,
        METADATA,
        $timeout,
        $filter,
        HandicappingRaceReplaysFac,
        GraphPastTracks,
        GraphPastRaces,
        WagerProfileFac
    ) {
        var vm = this;
        vm.model = $scope.model;
        vm.model.loading = true;
        vm.init = true;

        // initialize track dates
        if (vm.model.tabSelected === "Date") {
            vm.model.trackSelected = null;
            vm.model.trackList = [];
            vm.model.dateSelected = $rootScope.activeFeatures.enableGraphRaceReplaysByDate ? new Date() : new Date(METADATA.raceDate);
            _retrieveDateTracks(vm.model.dateSelected);
            vm.model.disableDatePicker = false;
        }

        function _getTracksByDateOnGraphSuccess(tracksList) {
            var pastTracks = [];

            if (Array.isArray(tracksList) && tracksList.length > 0) {
                if (_isToday(vm.model.dateSelected)) {
                    pastTracks.push({
                        name: "All tracks",
                        trackAbbr: "all"
                    });
                }

                tracksList.forEach(function(track) {
                    pastTracks.push({
                        trackAbbr: track.code,
                        name: track.name
                    });
                });
            }

            _getDateTracksSuccess(pastTracks);
        }

        function _retrieveDateTracks(date) {
            vm.model.loading = true;
            vm.model.infoMessage = "";
            vm.model.errorMessage = "";
            vm.model.selectedReplay = {};
            var raceDateToAsk = $filter("date")(date, "yyyy-MM-dd");

            if ($rootScope.activeFeatures.enableGraphRaceReplaysByDate) {
                GraphPastTracks.getTracksByDate(WagerProfileFac.getSessionOrAllTracksProfile(), raceDateToAsk, {isGreyhound:false})
                    .then(_getTracksByDateOnGraphSuccess, _getDateTracksFail)
                    .then(function() {
                        $timeout(function() {
                            $rootScope.$apply();
                        });
                    });
            } else {
                TrackInfoFac.getTracksByDate(raceDateToAsk).then(
                    _getDateTracksSuccess,
                    _getDateTracksFail
                );
            }
        }

        function _isToday(date) {
            return moment(date).format('YYYY-MM-DD') === moment().format("YYYY-MM-DD");
        }

        function _getDateTracksSuccess(listOfTracks) {
            vm.model.errorMessage = "";
            vm.model.infoMessage = "";
            vm.model.trackList = listOfTracks || [];

            if (vm.model.trackList.length > 0) {
                vm.model.trackSelected = vm.model.trackList[0];
                _getTrackReplaysListByDate(
                    vm.model.trackSelected.trackAbbr,
                    vm.model.dateSelected
                );
            } else {
                _resetTable();
                vm.model.trackList.push({
                    name: $filter("CMSLabels")(
                        "noTracks",
                        "handicappingRaceReplaysLabels"
                    )
                });
                vm.model.trackSelected = vm.model.trackList[0];
                vm.model.infoMessage = $filter("CMSLabels")(
                    "noReplaysForDate",
                    "handicappingRaceReplaysLabels"
                );
            }

            vm.model.loading = false;
        }
        function _getDateTracksFail() {
            vm.model.loading = false;
            vm.model.errorMessage = $filter("CMSLabels")(
                "failTrackDates",
                "handicappingRaceReplaysLabels"
            );
        }

        function _graphRaceReplaysSuccess(racesList) {
            var pastRaces = [];

            if (Array.isArray(racesList) && racesList.length > 0) {
                racesList.forEach(function(race) {
                    pastRaces.push({
                        distance:
                            race.distanceValue.toFixed(1) +
                            race.distanceCode.toLowerCase(),
                        postTime: race.postTime,
                        raceClass: race.className,
                        raceDate: race.date,
                        raceNumber: race.raceNumber,
                        raceTypeId: race.typeId,
                        raceTypeName: race.typeName,
                        trackAbbr: race.trackCode,
                        trackName: race.trackName,
                        providerTrack: race.trackCode,
                        replayFileName: race.video && race.video.replayFileName
                    });
                });
            }

            _getTrackReplaysByDateSuccess(pastRaces);
        }

        function _getRaceReplaysListOnGraph (trackCode, raceDate) {
            var wagerProfile = WagerProfileFac.getSessionOrGenericProfile();

            if (trackCode === "all") {
                return GraphPastRaces.getRacesByDate(
                    wagerProfile,
                    raceDate,
                    {isGreyhound: false}
                );
            }

            return GraphPastRaces.getRacesByDateAndTrack(
                wagerProfile,
                raceDate,
                trackCode,
                {isGreyhound: false}
            );
        }

        function _getTrackReplaysListByDate(trackAbbr, raceDate) {
            vm.model.loading = true;
            var raceDateToAsk = $filter("date")(raceDate, "yyyy-MM-dd");

            if ($rootScope.activeFeatures.enableGraphRaceReplaysByDate) {
                _getRaceReplaysListOnGraph(trackAbbr, raceDateToAsk)
                    .then(_graphRaceReplaysSuccess, _getTrackReplaysByDateFail)
                    .then(function() {
                        $timeout(function() {
                            $rootScope.$apply();
                        });
                    });
            } else {
                RaceReplaysGateway.getRacesReplaysByTrackAndDate(
                    trackAbbr,
                    raceDateToAsk
                ).then(
                    _getTrackReplaysByDateSuccess,
                    _getTrackReplaysByDateFail
                );
            }
        }

        function _getTrackReplaysByDateSuccess(replaysList) {
            _resetTable();
            vm.model.errorMessage = "";
            vm.model.infoMessage = "";

            vm.model.searchToShow = vm.model.trackSelected.name;
            if (_.isArray(replaysList) && replaysList.length) {
                vm.model.dataSource = $filter("orderBy")(replaysList, [
                    "-postTime"
                ]);
                vm.model.selectedReplay = vm.model.dataSource[0];
                vm.model.raceReplay = HandicappingRaceReplaysFac.buildResultReplay(
                    vm.model.selectedReplay
                );
            } else {
                vm.model.infoMessage = $filter("CMSLabels")(
                    "noReplaysForDate",
                    "handicappingRaceReplaysLabels"
                );
            }

            vm.model.loading = false;
        }

        function _getTrackReplaysByDateFail() {
            _resetTable();
            vm.model.errorMessage = $filter("CMSLabels")(
                "failTrackReplayList",
                "handicappingRaceReplaysLabels"
            );
        }

        function _resetTable() {
            vm.model.dataSource = [];
            vm.model.loading = false;
        }

        function _changeTrack(track) {
            if (!track) {
                return;
            }
            vm.model.trackSelected = track;
            HandicappingRaceReplaysFac.sendGtmEvent(
                "Past Results | Search By Date | Change Track | " +
                    vm.model.trackSelected.trackName,
                "PastResults-SearchByChangeTrack"
            );

            _getTrackReplaysListByDate(
                vm.model.trackSelected.trackAbbr,
                vm.model.dateSelected
            );
        }

        function _filterTrackRaceDates(data) {
            var date = data.date;
            var mode = data.mode;
            if (date && mode === "day") {
                var currentDate = $rootScope.activeFeatures.enableGraphRaceReplaysByDate ? new Date() : new Date(METADATA.raceDate);
                if (date <= currentDate) {
                    return false;
                }
                return true;
            }
            return false;
        }

        function _hideErrorTooltip() {
            angular
                .element(document)
                .find("tvg-datepicker")
                .trigger("hideTooltip");
        }

        function _showErrorTooltip() {
            angular
                .element(document)
                .find("tvg-datepicker")
                .trigger("showTooltip");
        }

        function _changeDay() {
            $timeout(_hideErrorTooltip, 0);
            if (vm.model.dateSelected) {
                var dataModel = {
                    date: vm.model.dateSelected,
                    mode: "day"
                };
                if (!_filterTrackRaceDates(dataModel)) {
                    _retrieveDateTracks(vm.model.dateSelected);
                } else {
                    vm.tooltipInfo = $filter("CMSLabels")(
                        "noReplaysForDate",
                        "handicappingRaceReplaysLabels"
                    );
                    $timeout(_showErrorTooltip, 0);
                    _resetTable();
                }
                HandicappingRaceReplaysFac.sendGtmEvent(
                    "Past Results | Search By Date | Change Date | " +
                        $filter("date")(vm.model.dateSelected, "yyyy-MM-dd"),
                    "PastResults-SearchByChangeDate"
                );

                // verify if we are on track search tab and track as replays
            } else if (vm.model.tabSelected === "Date") {
                vm.tooltipInfo = $filter("CMSLabels")(
                    "invalidDate",
                    "handicappingRaceReplaysLabels"
                );
                $timeout(_showErrorTooltip, 0);
                _resetTable();
            }
        }

        // Refetch data on login
          $scope.$on('login', function () {
            // initialize track dates
            if (vm.model.tabSelected === "Date") {
                vm.model.trackSelected = null;
                vm.model.trackList = [];
                vm.model.dateSelected = $rootScope.activeFeatures.enableGraphRaceReplaysByDate ? new Date() : new Date(METADATA.raceDate);
                _retrieveDateTracks(vm.model.dateSelected);
                vm.model.disableDatePicker = false;
            }
        });

        $scope.$watch("RaceReplaysSearchByDate.model.dateSelected", function() {
            if (vm.init) {
                vm.init = false;
            } else {
                _changeDay();
            }
        });

        vm.events = {
            changeDay: _changeDay,
            changeTrack: _changeTrack,
            filterTrackRaceDates: _filterTrackRaceDates
        };
    }

    RaceReplaysSearchByDateCtrl.$inject = [
        "$scope",
        "$rootScope",
        "TrackInfoFac",
        "RaceReplaysGateway",
        "METADATA",
        "$timeout",
        "$filter",
        "HandicappingRaceReplaysFac",
        "GraphPastTracks",
        "GraphPastRaces",
        "WagerProfileFac"
    ];

    return RaceReplaysSearchByDateCtrl;
});

