"use strict";

define('RaceResultsRunnerEntity',[],function () {

    function RaceResultRunner(bettingInterestNumber, finishPosition, placePayoff, showPayoff, winPayoff, runnerName, saddleColor, numberColor) {
        this.bettingInterestNumber = bettingInterestNumber;
        this.finishPosition = finishPosition;
        this.placePayoff = placePayoff;
        this.runnerName = runnerName;
        this.saddleColor = saddleColor;
        this.showPayoff = showPayoff;
        this.winPayoff = winPayoff;
        this.numberColor = numberColor;
    }

    return RaceResultRunner;

});


