'use strict';

define(
    'HandicappingByUrlCtrl',[
    'lodash'
    ],
    function (_) {

        function HandicappingByUrlCtrl($scope, $filter, $routeParams, GTMFac, $rootScope, $location, $http, $window, ContentFac, ConfigurationFac, RequestContextHeaders) {

            var routeParams = $routeParams;
            $scope.equibaseHandicapStore = $rootScope.activeFeatures.equibaseHandicapStore;
            $scope.getFreeHandicappingFromCapi = $rootScope.activeFeatures.getFreeHandicappingFromCapi;

            $scope.handicappingTabs = [
                {
                    name: $filter('CMSLabels')('store', 'mainMenuLabels'),
                    src: 'js/desktop/views/handicapping/handicapping-store.html',
                    qaLabel: 'handicapping-store',
                    id: 'handicapping-store'
                },
                {
                    name: $filter('CMSLabels')('freepp', 'mainMenuLabels'),
                    src: 'js/desktop/views/handicapping/handicapping-free-past-performances.html',
                    qaLabel: 'free-pp',
                    id: 'free-pp'
                },
                {
                    name: $filter('CMSLabels')('talentpick', 'mainMenuLabels'),
                    src: 'js/desktop/views/handicapping/talent-picks.html',
                    qaLabel: 'talentpicks',
                    id: 'talent-picks'
                },
                {
                    name: $filter('CMSLabels')('racereplays', 'mainMenuLabels'),
                    src: 'js/desktop/views/handicapping/handicapping-race-replays.html',
                    qaLabel: 'handicappingRaceReplaysView',
                    id: 'race-replays'
                }
            ];


            function _sendGTMEvent(tab) {
                var event = {
                    gaEventLabel: 'Header | Handicapping | ' + tab.name,
                    eventLabel: 'Header-HandicappingDropDown'
                };

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel(event.gaEventLabel)
                    .withEventLabel(event.eventLabel)
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
            }

            function _blogLinkSelect() {
                var domains = $filter('CMSValue')('TVGBlogDomains');

                try
                {
                    domains = JSON.parse(domains);
                }
                catch(error) {
                    domains = {environments: []};
                }

                var validUrl = _.find(domains.environments, function(dom) {
                    return $location.host().indexOf(dom.domain) > -1;
                });

                if (!_.isUndefined(validUrl)) {
                    return validUrl.value;
                } else {
                    return 'https://www.tvg.com/blog/';
                }
            }

            function _setActiveTab() {
                var tabSelected = _.find($scope.handicappingTabs, function (tab) {
                    return tab.id === routeParams.tabSelected;
                });

                if (tabSelected) {
                    $scope.activeTab = tabSelected;
                } else {
                    $scope.activeTab = $scope.handicappingTabs[0];
                }

                $scope.data.loading = false;
            }

            function _setHandicappingContent(data) {
                var pastPerformanceInternationalTracks = '<div class="tracks">';
                var groups = {};
                var countryType;

                if(data && data.length > 0) {

                    data.map(function(value) {
                        countryType =  value.international ? 'INT' : 'US';
                        if(!groups[countryType]) groups[countryType] = {};
                        if (!groups[countryType][value.group]) {
                            groups[countryType][value.group] = [];
                        }
                        groups[countryType][value.group].push('<a href="'+ value.link +'" target="_blank" ' +
                            'ng-click="events.validateLogin($event, \'' + value.link + '\', true, \'' + value.group + '\', \'' + value.name + '\')" >' +
                            value.name +'</a>');
                    });

                    if(groups.INT) {
                        Object.keys(groups.INT).map(function(group)  {
                            pastPerformanceInternationalTracks += '<br><span class="country"><strong>'+ group +'</strong></span><br>';
                            pastPerformanceInternationalTracks += groups.INT[group].join('');
                        });
                    }

                    $scope.data.pastPerformanceInternationalTracks = pastPerformanceInternationalTracks;
                }
            }

            function _validateLogin(event, callbackUrl, external, group, name) {
                if(!$rootScope.user || !$rootScope.user.accountNumber) {
                    $rootScope.$emit('callLoginRequiredModal', function() {
                        if(external) {
                            $window.open(callbackUrl, '_blank');
                        } else {
                            $location.path(callbackUrl);
                        }
                    });
                    event.preventDefault();
                } else {
                    // Trigger gtm event
                    var eventData = GTMFac.gaEventBuilder()
                        .withEventLabel('')
                        .withGaEventAction('Navigated To')
                        .withGaEventCategory('Navigation')
                        .withGaEventLabel(group + ' + ' + name)
                        .withEventLabel('Header-HandicappingDropDown')
                        .withModule('free international handicapping')
                        .withMicroApp('non-Microapp')
                        .withDestinationUrl(callbackUrl)
                        .build();

                    GTMFac.GTMEvent().send($rootScope, 'navigation', GTMFac.genericEvent(eventData));
                }
            }

            $scope.events = {
                sendGTMEvent: _sendGTMEvent,
                blogLinkSelect: _blogLinkSelect,
                validateLogin: _validateLogin
            };

            (function init() {
                $scope.data = {
                    loading: true
                };

                if($location.path().indexOf('free-pp') > 0) {
                    if($scope.getFreeHandicappingFromCapi) {
                        var brand = window.__TVG_GLOBALS__ && window.__TVG_GLOBALS__.BRAND || 'tvg';
                        var capiServiceUrl = ConfigurationFac.getServiceApiUrl('capi') + '/files/handicapping/available?brand=' + brand;

                        $http.get(capiServiceUrl, {
                            headers: {
                                'Content-Type': 'application/json',
                                'x-tvg-context': RequestContextHeaders[clientApp + '_' + locationContext]
                            }
                        }).success(function (data) {
                            _setHandicappingContent(data);
                        }).finally(function () {
                            _setActiveTab();
                        });
                    } else {
                        ContentFac.getNamespaceContent('freepastperformances').then(function (content) {
                            var _cmsContent = ContentFac.getCMSContent();
                            angular.extend(_cmsContent, content);
                            ContentFac.setCMSContent(_cmsContent);
                        }).finally(function () {
                            _setActiveTab();
                        });
                    }
                } else {
                    _setActiveTab();
                }

            }());
        }

        HandicappingByUrlCtrl.$inject = [
            '$scope',
            '$filter',
            '$routeParams',
            'GTMFac',
            '$rootScope',
            '$location',
            '$http',
            '$window',
            'ContentFac',
            'ConfigurationFac',
            'RequestContextHeaders'
        ];

        return HandicappingByUrlCtrl;
    }
);

