'use strict';

define(
    'EquibaseUtils',[
        'EquibaseTrackPastPerformanceButtonCtrl',
        'GTMMod'
    ],

    function (EquibaseTrackPastPerformanceButtonCtrl) {

        angular.module('TVG.EquibaseUtils', [
            'TVG.Common.GTM'
        ])
            .filter('trustedDependency', ['$sce', function ($sce) {
                return function (url) {
                    return $sce.trustAsResourceUrl(url);
                };
            }])

            .controller('EquibaseTrackPastPerformanceButtonCtrl', EquibaseTrackPastPerformanceButtonCtrl)

            .directive('equibaseTrackPastPerformanceButton', function () {
                return {
                    restrict: 'E',
                    templateUrl: 'js/desktop/modules/EquibaseUtils/templates/equibase-track-past-performance-button.html',
                    controller: 'EquibaseTrackPastPerformanceButtonCtrl',
                    controllerAs: 'btnCtrl',
                    bindToController: true,
                    scope: {
                        raceDate: '=',
                        trackAbbr: '=',
                        raceType: '=',
                        trackCountry: '='
                    }
                };
            });
    }
);

