'use strict';

define('SelectionSimpleOrKeyBoxDir',[],
    function() {

        function selectionSimpleOrKeyBox(stepSelectionsStringFilter) {

            function _linkFn(scope) {
                scope.selection = stepSelectionsStringFilter(scope.selectionSimpleOrKeyBox.split(','));
            }

            return {
                restrict: 'EA',
                require: '^runnersSelection',
                scope: {
                    selectionSimpleOrKeyBox: '='
                },
                templateUrl: 'js/desktop/modules/RaceTrackCurrentBets/templates/selection-simple-or-key-box-template.html',
                link: _linkFn
            };
        }

        selectionSimpleOrKeyBox.$inject = [
            'stepSelectionsStringFilter'
        ];

        return selectionSimpleOrKeyBox;
    });

