'use strict';

define('DrawerMenuButtonDir',[],function () {
    function drawerMenuButtonDir() {
        return {
            restrict: 'E',
            template: '<button name="drawerBtn" class="drawer-btn"></button>',
            link: function (scope) {
                var body = angular.element(document).find('body');

                scope.toggleExpandMenu = function (event) {
                    body.toggleClass("menu-expanded");
                    event.preventDefault();
                    event.stopPropagation();
                };

                body.on("click", function () {
                    if (body.hasClass("menu-expanded")) {
                        body.removeClass("menu-expanded");
                    }
                });

                scope.$on('$routeChangeStart', function () {
                    body.removeClass("menu-expanded");
                });
            }
        };
    }

    return drawerMenuButtonDir;
});

