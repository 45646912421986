'use strict';

define('MyFavoritesCtrl',[],
    function () {
        function MyFavoritesCtrl($scope) {
            $scope.myFavoritesTabs = $scope.activeTab.subTabs;
        }

        MyFavoritesCtrl.$inject = [
            '$scope'
        ];

        return MyFavoritesCtrl;
    }
);

