"use strict";

define('RaceResultsDesktopDirectiveCtrl',["lodash"], function(_) {
    /***
     * This Controller is binded to the race-results-desktop directive
     * Inherits raceId, racePerfAbbr and raceTrackAbbr from the parent isolated scope
     * @constructor
     */
    function RaceResultsDesktopDirectiveCtrl(
        RaceResultsUseCase,
        $filter,
        $rootScope,
        $location,
        $route,
        $scope,
        RaceUrlFac,
        GTMFac,
        GraphRaceResults,
        WagerProfileFac,
        GraphPastRaces,
        RaceMtpStatusFac,
        $timeout
    ) {
        var vm = this;
        // error flag for race results
        vm.errorGettingResults = false;

        var fetchResultsFromGraph = true;
        var resultsPollInterval =
            $filter("CMSLabels")("low", "graphqlPollingRates") || 30000; // in ms
        var raceResultsSubscription;

        vm.formatPayoffValue = function(value) {
            value = parseFloat(value);
            return value === 0 ? "" : $filter("currency")(value);
        };

        vm.toggleShowAllRaces = function() {
            $rootScope.$broadcast("showAllRaces", true);
        };

        vm.goToRace = function(raceNumber) {
            var url = RaceUrlFac.calculateRacePath(
                vm.raceTrackAbbr,
                vm.raceTrackName,
                raceNumber
            );

            if (url === $location.url()) {
                $route.reload();
            } else {
                $location.url(url);
            }
        };

        function _sendGtmEvent(gaEventLabel, eventLabel) {
            var eventData = GTMFac.gaEventBuilder()
                .withGaEventAction(GTMFac.Tvg4ScreenName() + " Page Click")
                .withGaEventCategory("Site Click")
                .withGaEventLabel(gaEventLabel)
                .withEventLabel(eventLabel)
                .build();

            var genericEvent = GTMFac.genericEvent(eventData);

            GTMFac.GTMEvent().send($rootScope, "siteClick", genericEvent);
        }

        vm.showResults = function() {
            vm.hideResults = false;

            _sendGtmEvent(
                "Race Card Button | Reveal Results",
                "RaceCardButton_RevealResults"
            );
        };

        vm.isLoading = true;

        function init() {
            if (
                _.isUndefined(vm.raceNumber) ||
                _.isUndefined(vm.raceTrackAbbr) ||
                _.isUndefined(vm.raceTypeId)
            ) {
                throw new Error(
                    "RaceResultsDesktopDirectiveCtrl(): Missing mandatory data to retrieve race results."
                );
            }

            if (vm.pastResults === "false") {
                _getRaceResultsGraph();
            } else {
                if (!$rootScope.activeFeatures.enableGraphRaceResults && _.isUndefined(vm.racePerfAbbr) && vm.racePerfAbbr.length) {
                    throw new Error(
                        "RaceResultsDesktopDirectiveCtrl(): Missing perfAbbr to retrieve race results."
                    );
                } else {
                    _getRaceResults()
                        .then(function() {
                            vm.isLoading = false;
                            _raceResultsLoaded();
                            $timeout(function() {
                                $rootScope.$apply();
                            });
                        });
                }
            }
        }

        function _getColor(biNumber, biArray, isSaddle) {
            if (Array.isArray(biArray) && biArray.length) {
                for (var i = 0; i < biArray.length; i += 1) {
                    if (biArray[i].biNumber === biNumber) {
                        return isSaddle
                            ? biArray[i].saddleColor
                            : biArray[i].numberColor;
                    }
                }
            }

            return isSaddle ? "#FFF" : "#000";
        }

        function _getPastRaceResultsSuccess(response) {
            var payoffs = [];
            var runners = [];
            var video = null;

            if (
                response.length &&
                vm.raceNumber === response[0].raceNumber &&
                response[0].results
            ) {
                video = response[0].video;
                Array.isArray(response[0].results.runners) &&
                response[0].results.runners.forEach(function(
                    runner
                ) {
                    runners.push({
                        bettingInterestNumber: runner.runnerNumber,
                        finishPosition: runner.finishPosition,
                        runnerName: runner.runnerName,
                        numberColor: _getColor(
                            runner.biNumber,
                            response[0].bettingInterests,
                            false
                        ),
                        saddleColor: _getColor(
                            runner.biNumber,
                            response[0].bettingInterests,
                            true
                        ),
                        winPayoff: runner.winPayoff.toFixed(2),
                        placePayoff: runner.placePayoff.toFixed(
                            2
                        ),
                        showPayoff: runner.showPayoff.toFixed(2)
                    });
                });

                Array.isArray(response[0].results.payoffs) &&
                response[0].results.payoffs.forEach(function(
                    payoff
                ) {
                    if (payoff.wagerType && !(payoff.wagerType.code === "WN" || payoff.wagerType.code === "PL" || payoff.wagerType.code === "SH")) {
                        var amount = payoff.wagerAmount
                            ? "$" + payoff.wagerAmount.toFixed(2)
                            : "";
                        var wagerType = payoff.wagerType.name
                            ? payoff.wagerType.name.toUpperCase()
                            : "";
                        var selections = payoff.selections.length
                            ? payoff.selections[0].selection
                            : "";
                        var payout = payoff.selections.length
                            ? "$" +
                            payoff.selections[0].payoutAmount.toFixed(
                                2
                            )
                            : "";

                        payoffs.push(
                            amount +
                            " " +
                            wagerType +
                            " " +
                            selections +
                            " " +
                            payout
                        );
                    }
                });
            }

            vm.raceResults = {
                raceDate: vm.raceDate,
                raceNumber: vm.raceNumber,
                trackAbbr: vm.raceTrackAbbr,
                payoffs: payoffs,
                runners: runners,
                raceDateString: $filter("date")(
                    vm.raceDate,
                    "MMM dd, yyyy"
                ),
                replayFileName: video && video.replayFileName
            };
        }

        function _getRaceResults() {
            if ($rootScope.activeFeatures.enableGraphRaceResults) {
                vm.errorGettingResults = false;
                return GraphPastRaces.getRacesByDateAndTrackAndRace(
                    WagerProfileFac.getSessionOrGenericProfile(),
                    vm.raceDate,
                    vm.raceTrackAbbr,
                    vm.raceNumber
                )
                    .then(_getPastRaceResultsSuccess)
                    .catch(function () {
                        vm.errorGettingResults = true;
                    });
            } else {
                return RaceResultsUseCase.getRaceResults(
                    vm.racePerfAbbr,
                    vm.raceTrackAbbr,
                    vm.raceNumber,
                    vm.raceTypeId,
                    vm.raceDate
                ).then(
                    function(raceResults) {
                        if (vm.raceNumber === raceResults.raceNumber) {
                            vm.raceResults = raceResults;
                            vm.raceResults.raceDateString = $filter("date")(
                                vm.raceResults.raceDate,
                                "MMM dd, yyyy"
                            );
                        }
                    }
                );
            }
        }

        function _raceResultsLoaded() {
            vm.loaded = true;
            $scope.$emit("raceResultsDirCompleted");
            $scope.$emit("pageLoaded", "race");
        }

        function _onResultsFail(error) {
            vm.isLoading = false;
            vm.errorGettingResults = (error !== 'No results');

            _raceResultsLoaded();
        }

        function _getRaceResultsGraph() {
            var options = {
                trackAbbr: vm.raceTrackAbbr,
                raceNumber: vm.raceNumber,
                wagerProfile: WagerProfileFac.getSessionOrGenericProfile()
            };

            if (!raceResultsSubscription) {
                raceResultsSubscription = GraphRaceResults.subscribe(
                    options,
                    {
                        success: _onResultsSuccess,
                        fail: _onResultsFail
                    },
                    resultsPollInterval,
                    true
                );
            } else {
                GraphRaceResults.refetch(options).then(function(data) {
                    _onResultsSuccess(data);
                }).catch(_onResultsFail);
            }
        }

        function _onResultsSuccess(raceResults) {
            vm.errorGettingResults = false;
            if (
                parseInt(vm.raceNumber, 10) === parseInt(raceResults.number, 10)
            ) {
                vm.raceResults = {
                    raceNumber: parseInt(raceResults.number, 10),
                    raceDate: vm.raceDate,
                    trackAbbr: raceResults.track.code,
                    perfAbbr: raceResults.track.perfAbbr,
                    hasReplays: raceResults.video
                        ? raceResults.video.hasReplay
                        : false,
                    onTvg: raceResults.video ? raceResults.video.onTvg : false,
                    onTvg2: raceResults.video ? raceResults.video.onTvg2 : false,
                    payoffs: [],
                    runners: []
                };

                vm.raceResults.raceDateString = $filter("date")(
                    vm.raceResults.raceDate,
                    "MMM dd, yyyy"
                );

                if (raceResults.results) {
                    if (
                        raceResults.results.payoffs &&
                        angular.isArray(raceResults.results.payoffs)
                    ) {
                        raceResults.results.payoffs.forEach(function(payoff) {
                            var payoffText = "";

                            switch (payoff.wagerType.code) {
                                case "WN":
                                case "PL":
                                case "SH":
                                    break;
                                default:
                                    if (
                                        payoff.wagerType &&
                                        payoff.selections.length > 0
                                    ) {
                                        payoff.selections.forEach(function(
                                            selection
                                        ) {
                                            payoffText =
                                                payoff.wagerAmount > 0 &&
                                                selection.selection !==
                                                "CarryOver"
                                                    ? vm.formatPayoffValue(
                                                    payoff.wagerAmount
                                                    )
                                                    : "-";
                                            payoffText +=
                                                " " +
                                                payoff.wagerType.name.toUpperCase();
                                            payoffText +=
                                                " " + selection.selection;
                                            payoffText +=
                                                " " +
                                                vm.formatPayoffValue(
                                                    selection.payoutAmount
                                                );
                                            vm.raceResults.payoffs.push(
                                                payoffText
                                            );
                                        });
                                    }
                                    break;
                            }
                        });
                    }

                    if (
                        raceResults.results.runners &&
                        angular.isArray(raceResults.results.runners)
                    ) {
                        raceResults.results.runners.forEach(function(runner) {
                            var RaceResultRunner = runner;
                            RaceResultRunner.bettingInterestNumber =
                                runner.biNumber;
                            if (raceResults.bettingInterests) {
                                raceResults.bettingInterests.forEach(function(
                                    bi
                                ) {
                                    if (bi.biNumber === runner.biNumber) {
                                        RaceResultRunner.numberColor =
                                            bi.numberColor;
                                        RaceResultRunner.saddleColor =
                                            bi.saddleColor;
                                    }
                                });
                            }
                            vm.raceResults.runners.push(RaceResultRunner);
                        });
                    }

                    if (
                        vm.raceResults.runners &&
                        vm.raceResults.payoffs &&
                        raceResultsSubscription
                    ) {
                        if (GraphRaceResults.isSubscribed()) {
                            GraphRaceResults.unsubscribe(
                                raceResultsSubscription
                            );
                            raceResultsSubscription = null;
                        }
                    }
                }
            }

            vm.isLoading = false;
            _raceResultsLoaded();

            $timeout(function() {
                $rootScope.$apply();
            });
        }

        function _unsubscribeResultsFromGraph() {
            if (
                fetchResultsFromGraph &&
                raceResultsSubscription &&
                GraphRaceResults.isSubscribed()
            ) {
                GraphRaceResults.unsubscribe(raceResultsSubscription);
                raceResultsSubscription = null;
            }
        }

        init();

        var unbind = [];

        unbind.push(
            $rootScope.$on("race:Changed", function() {
                var routeParams = RaceUrlFac.getRacesInfoFromRouteParams();

                vm.isLoading = true;
                vm.raceTrackAbbr = routeParams.trackAbbr;
                vm.raceTrackName = routeParams.trackName;
                vm.racePerfAbbr = routeParams.perfAbbr || "";
                vm.raceNumber = routeParams.raceNumber;

                _unsubscribeResultsFromGraph();
                init();
            })
        );

        unbind.push(
            $rootScope.$on("setRaceResults", function() {
                init();
            })
        );

        $scope.$on("$destroy", function() {
            _.forEach(unbind, function(bind) {
                bind();
            });
            _unsubscribeResultsFromGraph();
        });
    }

    RaceResultsDesktopDirectiveCtrl.$inject = [
        "RaceResultsUseCase",
        "$filter",
        "$rootScope",
        "$location",
        "$route",
        "$scope",
        "RaceUrlFac",
        "GTMFac",
        "GraphRaceResults",
        "WagerProfileFac",
        "GraphPastRaces",
        "RaceMtpStatusFac",
        "$timeout"
    ];

    return RaceResultsDesktopDirectiveCtrl;
});

