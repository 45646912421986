'use strict';

define(
    'MyTracksCtrl',[],
    function () {
        function MyTracksCtrl(
                $rootScope,
                $scope,
                $filter,
                METADATA,
                RaceInfoFac,
                ManageFavoritesFac,
                GTMFac,
                GraphPastTracks,
                WagerProfileFac,
                RaceMtpStatusFac
            ) {
            (function init() {
                var myFavoriteTracksFromGraph = $rootScope.activeFeatures.myFavoriteTracksFromGraph;
                var wagerProfile = WagerProfileFac.getSessionOrGenericProfile();
                var greyhoundProfiles = [];

                _.attempt(function () {
                    greyhoundProfiles = JSON.parse($filter('CMSValue')('greyhoundsProfiles')).profilesList;
                });

                $scope.data = {
                    loading: true,
                    columns: 4,
                    tracks: [],
                    tracksChunks: [],
                    activeFilter: 'all'
                };

                $scope.events = {
                    filterTracks: _filterTracks,
                    updateFavorite: _updateFavorite,
                    clearFavorites: _clearFavorites
                };


                if (myFavoriteTracksFromGraph) {
                    GraphPastTracks.getAllTracksAndTodays(
                        wagerProfile,
                        RaceMtpStatusFac.getRaceDate() || moment().format("YYYY-MM-DD")
                    ).then(function (graphTracks) {
                        var favoritesTracksAbbr = ManageFavoritesFac.getFavTrackList();
                        var tracksMap = {};

                        graphTracks.allPastTracks.forEach(function (track) {
                            tracksMap[track.code] = {
                                Abbreviation: track.code,
                                Name: track.name,
                            };
                        });

                        _.forEach(favoritesTracksAbbr, function (trackAbbr) {
                            if (angular.isDefined(tracksMap[trackAbbr])) {
                                tracksMap[trackAbbr].favorite = true;
                            }
                        });

                        _.forEach(graphTracks.pastTracksByDate, function (track) {
                            if (angular.isDefined(tracksMap[track.code])) {
                                tracksMap[track.code].today = true;
                            }
                        });

                        $scope.data.tracks = tracksMap;

                        _filterTracks($scope.data.activeFilter, false);

                        $scope.$apply();
                        $scope.data.loading = false;
                        $scope.$emit('pageLoaded', 'my-favorite-tracks');
                    });
                } else {
                    // modifying objects passed by refrence was causing favorites
                    // from one user to be shared with the second one
                    var tracksMap = _.cloneDeep(METADATA.allTracks || {});
                    var favoritesTracksAbbr = ManageFavoritesFac.getFavTrackList();


                    _.forEach(favoritesTracksAbbr, function (trackAbbr) {
                        if (angular.isDefined(tracksMap[trackAbbr])) {
                            tracksMap[trackAbbr].favorite = true;
                        }
                    });

                    RaceInfoFac.getUpcomingRaces().then(function (data) {
                        _.forEach(data, function (race) {
                            if (angular.isDefined(tracksMap[race.trackAbbr])) {
                                tracksMap[race.trackAbbr].today = true;
                            }
                        });
                    }).finally(function () {
                        $scope.data.tracks = tracksMap;
                        $scope.data.loading = false;
                        $scope.$emit('pageLoaded', 'my-favorite-tracks');
                    });

                    $scope.data.tracks = tracksMap;
                    _filterTracks($scope.data.activeFilter, false);
                }

            }());

            function _buildGtmEvent(gaEventLabel, eventLabel) {
                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Page Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel(gaEventLabel)
                    .withEventLabel(eventLabel)
                    .build();

                return GTMFac.genericEvent(eventData);
            }

            function _filterTracks(type, flag) {
                var tracks = $scope.data.tracks;
                var gtmSuffix;
                var genericEvent;

                switch (type) {
                    case 'today':
                        $scope.data.activeFilter = type;
                        tracks = _.filter($scope.data.tracks, function (track) {
                            return angular.isDefined(track.today) && track.today;
                        });
                        gtmSuffix = 'Today\'s Tracks';
                        break;

                    case 'favorites':
                        $scope.data.activeFilter = type;
                        tracks = _.filter($scope.data.tracks, function (track) {
                            return angular.isDefined(track.favorite) && track.favorite;
                        });
                        gtmSuffix = 'Favorite Tracks';
                        break;

                    default:
                        $scope.data.activeFilter = 'all';
                        tracks = $scope.data.tracks;
                        gtmSuffix = 'All Tracks';
                        break;
                }

                if (flag) {
                    genericEvent = _buildGtmEvent('My Favorite Tracks | TabFilter | ' + gtmSuffix, 'MyFavorites-TabFilter');
                    GTMFac.GTMEvent().send($rootScope, 'siteClick', genericEvent);
                }

                $scope.data.tracksChunks = _createColumns(_.toArray(tracks), $scope.data.columns);
            }


            function _createColumns(list, columns) {
                return _.chunk(list, Math.ceil(list.length / columns));
            }

            function _updateFavorite(track) {
                var p,
                    labelForGTM = track.favorite ? 'Remove from Favorite Track' : 'Add Favorite Track',
                    gtmEventLabel = track.favorite ? 'MyAccount-RemoveFavoriteTrack' : 'MyAccount-AddFavoriteTrack';

                if ((angular.isDefined(track.updating) && track.updating === false) || angular.isUndefined(track.updating)) {

                    var newState = !track.favorite;
                    track.updating = true;

                    ManageFavoritesFac.updateFavTrackList(track.Abbreviation).then(function () {
                        track.favorite = newState;
                        track.updating = false;

                        if ($scope.data.activeFilter === 'favorites' && track.favorite === false) {
                            $scope.events.filterTracks($scope.data.activeFilter);
                        }

                        p = GTMFac.EventBuilder()
                            .withGaEventLabel(labelForGTM)
                            .withGaEventAction('My Favorites Page Click')
                            .withGaEventCategory('Site Click')
                            .withSectionName(GTMFac.tvg4SectionNameForMyTracks($scope.data.activeFilter))
                            .withScreenName(GTMFac.Tvg4ScreenName())
                            .withEventLabel(gtmEventLabel)
                            .build();

                        GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
                    });
                    
                    if(!track.favorite) {
                        // Apptentive event
                        window.ApptentiveSDK.engage("Race track is favorited");
                    }

                    return true;
                }

                return false;
            }

            function _clearFavorites() {
                var genericEvent = _buildGtmEvent('My Favorite Tracks | Clear All Favorites','MyFavorites-ClearAllFavorites');
                GTMFac.GTMEvent().send($rootScope, 'siteClick', genericEvent);
                $scope.data.loading = true;

                ManageFavoritesFac.clearFavTrackList().finally(function () {
                    _.forEach($scope.data.tracks, function (track) {
                        track.favorite = false;
                    });

                    $scope.events.filterTracks($scope.data.activeFilter);
                    $scope.data.loading = false;
                });
            }

            $scope.$on('newFavTrack', function (event, data) {
                if (data && !_.isUndefined(data.trackAbbr) && !_.isUndefined(data.newFavorite)) {
                    _.forEach($scope.data.tracks, function (track) {
                        if (track.Abbreviation === data.trackAbbr) {
                            track.favorite = data.newFavorite;
                        }
                    });
                }
                if ($scope.data.activeFilter === 'favorites') {
                    $scope.events.filterTracks('favorites');
                }
            });

            $scope.$on('clearFavTrackList', function () {
                _.forEach($scope.data.tracks, function (track) {
                    track.favorite = false;
                });
                if ($scope.data.activeFilter === 'favorites') {
                    $scope.events.filterTracks('favorites');
                }
            });
        }

        MyTracksCtrl.$inject = [
            '$rootScope',
            '$scope',
            '$filter',
            'METADATA',
            'RaceInfoFac',
            'ManageFavoritesFac',
            'GTMFac',
            'GraphPastTracks',
            'WagerProfileFac',
            'RaceMtpStatusFac'
        ];

        return MyTracksCtrl;
    }
);

