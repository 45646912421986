"use strict";

define('FullScheduleReloadedModel',[],function () {

    var id = 0;
    function FullScheduleReloaded(trackAbbr, isFavorite, trackName, raceNumber, perfAbbr, runners, distance, raceClass, surfaceName, onTvg, ontvg2, liveStreaming, postTime, featured, mtp) {
        this.trackAbbr = trackAbbr;
        this.isFavorite = isFavorite;
        this.trackName = trackName;
        this.raceNumber = raceNumber;
        this.runners = runners;
        this.distance = distance;
        this.raceClass = raceClass;
        this.surfaceName = surfaceName;
        this.onTvg = onTvg;
        this.onTvg2 = ontvg2;
        this.liveStreaming = liveStreaming;
        this.postTime = postTime;
        this.perfAbbr = perfAbbr;
        this.featured = featured;
        this.id = id++;
        this.raceId = trackAbbr + '_' + raceNumber;

        // TODO: delete this validation once the globalMtpSystem toggle is 100%
        if (mtp) {
            this.mtp = mtp;
        }
    }

    return FullScheduleReloaded;

});

