'use strict';

define('MyStableManageCtrl',[], function () {

    function MyStableManageCtrl ($scope, $rootScope, MyStableSvc, RunnerReplayModalFac, $filter, GTMFac) {

        var vm = this;
        vm.data = {
            entityTypes: [ {name: "horse", label: $filter('CMSLabels')('horsesTabLabel', 'myStableLabels'), id: 1},
                           {name: "jockey", label: $filter('CMSLabels')('jockeysTabLabel', 'myStableLabels'), id: 2},
                           {name: "trainer", label: $filter('CMSLabels')('trainersTabLabel', 'myStableLabels'), id: 3},
                           {name: "owner", label: $filter('CMSLabels')('ownersTabLabel', 'myStableLabels'), id: 4}],
            myStable: [],
            selectAllArray: [],
            pageSelected: 1,
            totalRecords: 0,
            numberItemsPerPage: 10,
            emptyMessage: $filter('CMSLabels')('manageMessageEmpty', 'myStableLabels'),
            paginationMessage: $filter('CMSLabels')('manageNumberOfItems', 'myStableLabels'),
            successDeleteMessage: $filter('CMSLabels')('deleteSuccess', 'myStableLabels'),
            confirmationMessage: $filter('CMSLabels')('confirmationLabel', 'myStableLabels'),
            deleteRow: -1,
            isCancelling: false,
            isConfirmedDelete: false,
            deleteSelectionName: "",
            firstTime: true
        };

        vm.events = {
            addAlerts: _addAlerts,
            removeFromMyStable: _removeFromStable,
            selectEntityType: _selectEntityType,
            changePage: _changePage,
            selectAllClick: _selectAllClick,
            openHorseVideoReplay: _openHorseVideoReplay,
            goToFirstPage: _goToFirstPage,
            dontShowRow: _dontShowRow,
            showRemoveFlow: _showRemoveFlow,
            resetAlertMessage: _resetAlertMessage,
            isAnySelected: _isAnySelected
        };

        function _resetAlertMessage(cancel) {
            vm.data.deleteRow = -1;
            vm.data.isCancelling = false;
            vm.data.isConfirmedDelete = false;
            if(cancel) {
                _sendGtmEvent('My Stable | Cancel Remove', 'MyStable-CancelRemove');
            }
        }

        function _showRemoveFlow(name, index) {
            vm.data.deleteSelectionName = name;
            vm.data.deleteRow = index;
            vm.data.isCancelling = true;
            vm.data.isConfirmedDelete = false;
            _sendGtmEvent('My Stable | Show Remove Flow', 'MyStable-ShowRemoveFlow');
        }

        function _dontShowRow(index){
            return vm.data.deleteRow === index && vm.data.isCancelling;
        }

        function _changePage() {
            vm.loading = true;
            _resetAlertMessage();
            _getEntityInMyStable(vm.data.entityType);
        }

        function _selectAllClick(entity) {
            _sendGtmEvent('My Stable | Select All', 'MyStable-SelectAll');
            if(!Array.isArray(entity.subscriptions)) {
                return false;
            }

            if(_areAllSelected(entity)) {
                _selectAll(entity, false);
            } else {
                _selectAll(entity, true);
            }
        }

        function _selectAll(entity, value){
            entity.subscriptions.forEach(function(subcription) {
                subcription.active = value;
            });
            _addAlerts(entity);
        }

        function _areAllSelected(entity) {
            if(!Array.isArray(entity.subscriptions)) {
                return false;
            }

            var notSelected = entity.subscriptions.filter(function(subcription) {
                return subcription.active === false;
            });

            return notSelected.length === 0;
        }

        function _isAnySelected(entity) {
            if(!Array.isArray(entity.subscriptions)) {
                return false;
            }

            var notSelected = entity.subscriptions.filter(function(subcription) {
                return subcription.active === true;
            });

            return notSelected.length !== 0;
        }

        function _updateSelectAll() {
            vm.data.myStable.forEach(function(entity, index){
               vm.data.selectAllArray[index] = _areAllSelected(entity);
            });
        }

        function _selectEntityType(entity) {
            _sendGtmEvent('My Stable | TabFilter | ' + entity.label, 'MyStable-TabFilter');
            vm.data.entityType = entity;
            vm.loading = true;
            _goToFirstPage();
        }

        function _removeFromStable(element) {
            vm.loading = true;
            MyStableSvc.removeFromMyStable(element, $rootScope.user.accountNumber).then(_removeFromMyStableSuccess, _removeFromMyStableFail);
        }

        function _removeFromMyStableSuccess() {
            _sendGtmEvent('My Stable | Confirm Remove', 'MyStable-ConfirmRemove');
            _getEntityInMyStable(vm.data.entityType);
        }

        function _removeFromMyStableFail() {
            $scope.$emit('myStableErrorMessage', $filter('CMSLabels')('removeMyStableFailMessage', 'MyStableLabels'));
            vm.data.isCancelling = false;
            vm.loading = false;
        }

        function _getEntityInMyStable(entityType) {
            if(_.isUndefined(entityType)) {
                return;
            }

            MyStableSvc.getRaceAlertsByEntityTypeName(
                entityType.name,
                vm.data.pageSelected,
                vm.data.numberItemsPerPage,
                $rootScope.activeFeatures.myStableSearchByHorseFCPGraph,
                $rootScope.user.accountNumber
            ).then(_retrieveMyStableSuccess, _retrieveMyStableFail);
        }

        function _retrieveMyStableSuccess(data) {
            if(vm.data.isCancelling) {
                vm.data.isCancelling = false;
                vm.data.isConfirmedDelete = true;
            }
            vm.data.totalRecords = data.pageInfo.TotalRecordCount;
            vm.data.myStable = data.stableEntities;
            _updateSelectAll();
            vm.loading = false;
        }

        function _retrieveMyStableFail() {
            $scope.$emit('myStableErrorMessage', $filter('CMSLabels')('retriveMyStableFailMessage', 'myStableLabels'));
            vm.loading = false;
        }

        function _openHorseVideoReplay(horseName) {
            $scope.selectedRunnerForReplays = {horseName: horseName};
            _sendGtmEvent('My Stable | Open Horse Replay', 'MyStable-OpenHorseReplay');
            RunnerReplayModalFac.openRunnerReplaysModal($scope);
        }

        function _addAlerts(element) {
            _updateSelectAll();
            if ($rootScope.activeFeatures.myStableSearchByHorseFCPGraph) {
                MyStableSvc.addToRaceAlertsUfc(element, $rootScope.user.accountNumber).then(_addAlertsSuccess, _addAlertsFail);
            } else {
                MyStableSvc.addToRaceAlerts([element]).then(_addAlertsSuccess, _addAlertsFail);
            }
        }

        function _addAlertsSuccess() {
            _sendGtmEvent('My Stable | Add Alert', 'MyStable-AddAlert');
            vm.loading = false;
        }

        function _addAlertsFail() {
            $scope.$emit('myStableErrorMessage', $filter('CMSLabels')('addAlertsFailMessage', 'myStableLabels'));
            vm.loading = false;
        }

        function _goToFirstPage() {
            vm.data.pageSelected = 1;
            _changePage();
        }

        var unbind = $rootScope.$on("refreshMyStableManage", function() {
            vm.loading = true;
            _getEntityInMyStable(vm.data.entityType);

            GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.EventBuilder()
                .withGaEventAction('My Stable Page Click')
                .withGaEventCategory('Site Click')
                .withGaEventLabel('My Stable | Manage Tab')
                .withEventLabel('MyStable-ManageTab')
                .withScreenName('My Account')
                .withSectionName('My Account | My Stable | Manage')
                .build());
        });

        $scope.$on("$destroy", function() {
            unbind();
        });

        function _sendGtmEvent(gaEventLabel, eventLabel) {
            // gtm Event
            var eventData = GTMFac.gaEventBuilder()
                .withGaEventAction("My Stable Page Click")
                .withGaEventCategory('Site Click')
                .withGaEventLabel(gaEventLabel)
                .withEventLabel(eventLabel)
                .build();
            var genericEvent = GTMFac.genericEvent(eventData);
            genericEvent.sectionName = genericEvent.sectionName.concat(" | Manage");
            GTMFac.GTMEvent().send($rootScope, 'siteClick', genericEvent);
        }

        (function init(){
            vm.loading = true;
            vm.data.entityType = vm.data.entityTypes[0];
            _getEntityInMyStable(vm.data.entityType);
        }());
    }

    MyStableManageCtrl.$inject = [
        "$scope",
        "$rootScope",
        "MyStableSvc",
        "RunnerReplayModalFac",
        "$filter",
        "GTMFac"
    ];

    return MyStableManageCtrl;

});

