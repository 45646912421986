'use strict';

define('TalentPicksSvc',['lodash'],
    function (_) {

    function TalentPicksSvc(poller, $http, METADATA, $filter, ConfigurationFac, GTMFac, $rootScope, $log, GraphTalentPicks, WagerProfileFac) {

        var TalentPickModel,
            TalentPicksFac,
            _biColors = METADATA.biColors ? METADATA.biColors[1] : [],
            talentPicksPoller = null;

        function _getTimestamp(input) {
            if (!input) {
                return 0;
            }
            var numbers = input.match(/[0-9]+/g);
            if (!numbers) {
                numbers = [0];
            }
            return parseInt(numbers[0], 10);
        }

        function _getTimeToPost(postTime, currentTime) {
            var timeToPost = postTime - currentTime;
            timeToPost = timeToPost / 1000 / 60;
            if (timeToPost < 0) {
                timeToPost = 0;
            }
            return Math.round(timeToPost);
        }

        function _getMTPClass(minutesToPost) {
            if (minutesToPost <= 5) {
                return 'MTP immediate';
            }
            if (minutesToPost <= 15) {
                return 'MTP near';
            }
            if (minutesToPost <= 60) {
                return 'MTP far';
            }

            return null;
        }

        function _getBetInfo(betTypeId) {

            var metaBet = METADATA.wagerTypes && METADATA.wagerTypes[betTypeId] ? METADATA.wagerTypes[betTypeId] : {},
                betInfo = {};

            betInfo.name = metaBet.Name || '';
            betInfo.abbreviation = metaBet.Abbreviation;
            betInfo.columnCount = metaBet.ColumnCount;
            betInfo.columnType = metaBet.ColumnType || "Runners";
            betInfo.isBox = metaBet.IsBox;
            betInfo.isKey = metaBet.IsKey;
            betInfo.legCount = metaBet.LegCount;
            betInfo.maxBIsPerLeg = metaBet.MaxBIsPerLeg;
            betInfo.minBIsPerLeg = metaBet.MinBIsPerLeg;
            betInfo.poolsPerBI = metaBet.PoolsPerBI;
            betInfo.positionCount = metaBet.PositionCount;

            return betInfo;
        }

        function _getGraphBetInfo(wagerType) {
            var betInfo = {};

            betInfo.name = wagerType.type.name || '';
            betInfo.abbreviation = wagerType.type.code;
            betInfo.columnCount = wagerType.columnCount;
            betInfo.columnType = wagerType.columnType || "Runners";
            betInfo.isBox = wagerType.isBox;
            betInfo.isKey = wagerType.isKey;
            betInfo.legCount = wagerType.legCount;
            betInfo.maxBIsPerLeg = wagerType.maxBIsPerLeg;
            betInfo.minBIsPerLeg = wagerType.minBIsPerLeg;
            betInfo.poolsPerBI = wagerType.poolsPerBI;
            betInfo.positionCount = wagerType.positionCount;

            return betInfo;
        }

        function _getSaddleColor(arr, biNr) {
            var i;
            if (!arr) {
                // $log.warn('no data to search saddle color');
                return;
            }
            for (i = 0; i < arr.length; i++) {
                if (arr[i] && arr[i].BettingInterestNumber == biNr) {
                    return arr[i].SaddleColor;
                }
            }
        }

        function _getNumberColor(arr, biNr) {
            var i;
            if (!arr) {
                // $log.warn('no data to search number color');
                return;
            }
            for (i = 0; i < arr.length; i++) {
                if (arr[i].BettingInterestNumber == biNr) {
                    return arr[i].NumberColor;
                }
            }
        }

        function _getPrintableSelections(selections) {

            var consecutiveSelections = [],
                currentSelection = [],
                addSeparator = null,
                i;

            selections = selections.sort(function (a, b) {
                return a.bettingInterestNumber - b.bettingInterestNumber;
            });

            for (i = 0; i < selections.length; i++) {
                if (currentSelection.length) {
                    if (selections[i].bettingInterestNumber - currentSelection[currentSelection.length - 1].bettingInterestNumber === 1 && !selections[i].favorite) {
                        if (currentSelection.length == 2) {
                            currentSelection[1] = selections[i];
                        } else {
                            currentSelection.push(selections[i]);
                        }
                    } else {
                        addSeparator = currentSelection.length == 2 && currentSelection[1].bettingInterestNumber - currentSelection[0].bettingInterestNumber > 1;
                        if (addSeparator) {
                            currentSelection = [currentSelection[0], {isSeparator: true}, currentSelection[1]];
                        }

                        consecutiveSelections.push.apply(consecutiveSelections, currentSelection);
                        currentSelection = [selections[i]];
                    }
                } else {
                    currentSelection.push(selections[i]);
                }
            }

            if (currentSelection.length) {
                addSeparator = currentSelection.length == 2 && currentSelection[1].bettingInterestNumber - currentSelection[0].bettingInterestNumber > 1;
                if (addSeparator) {
                    currentSelection = [currentSelection[0], {isSeparator: true}, currentSelection[1]];
                }

                consecutiveSelections.push.apply(consecutiveSelections, currentSelection);
            }

            return consecutiveSelections;
        }

        function _buildTalentPickName(talent) {
            var talentName = talent.talentName ? talent.talentName.toLowerCase() : "";
            talentName = talent.talentName && talent.talentLastName ? talentName.concat("_") : talent.talentName;
            talentName = talent.talentLastName ? talentName.concat(talent.talentLastName.toLowerCase()) : talentName;
            return talentName;
        }

        function _buildGraphTalentPickImageUrl(imageUrl) {
            return "/" + imageUrl;
        }

        function _buildTalentPickImageUrl(talent) {
            return '//' + ConfigurationFac.getBaseUrlForCMSFiles(ConfigurationFac.getApplicationContext().application) + _buildTalentPickName(talent) + '.png';
        }

        function _getBIgraph(selectionsList, favorites, legCount) {
            var biArray = [];

            if (angular.isArray(selectionsList) && selectionsList.length) {
                selectionsList.forEach(function(selections, index) {
                    var BettingInterests = [];

                    selections.forEach(function(selection) {
                        BettingInterests.push({
                            BettingInterestNumber: selection.order,
                            Favorite: _.findIndex(favorites[legCount > 1 ? index : 0], ['order', selection.order]) >= 0
                        });
                    });

                    biArray.push({
                        BettingInterests: BettingInterests
                    });
                });
            }

            return biArray;
        }

        TalentPickModel = {
            TalentPick: function (betType, talentName, talentLastName, trackAbbr, raceNumber, perfAbbr, postTime, betAmount, betCount, stepType, steps, raceStatus, imageUrl, raceTypeCode, mtp) {

                var betInfo = $rootScope.activeFeatures.enableGraphTalentPicks ? _getGraphBetInfo(betType) : _getBetInfo(betType);
                this.betTypeId = $rootScope.activeFeatures.enableGraphTalentPicks ? betType.type.id : betType;
                this.betTypeName = betInfo.name;
                this.talentName = talentName;
                this.talentLastName = talentLastName;
                this.imageUrl = imageUrl ? _buildGraphTalentPickImageUrl(imageUrl) : _buildTalentPickImageUrl(this);
                this.trackAbbr = trackAbbr.trim();
                this.perfAbbr = perfAbbr;
                this.raceNumber = raceNumber;
                this.postTime = new Date(_getTimestamp(postTime));
                this.betAmount = betAmount;
                this.betAmountStr = $filter('currency')(this.betAmount, '$');
                this.raceTypeCode = raceTypeCode;

                this.betCount = betCount;

                this.trackName = (METADATA.allTracks && METADATA.allTracks[this.trackAbbr] ? METADATA.allTracks[this.trackAbbr].Name : this.trackAbbr);

                this.raceDescription = this.raceNumber + (stepType.Name == 'RaceNumber' ? ' - ' + (this.raceNumber + steps.length - 1) : '');
                // TODO: At first sight, this doesn't make sense but this is due to GTMFac.withRaceNumber
                // implementation. This will be refactored soon
                this.gtmRaceNumber = 'ace ' + this.raceNumber + ((stepType.Name === 'RaceNumber') ? ' thru ' + (this.raceNumber + steps.length - 1) : '');

                this.id = this.trackAbbr + '_' + this.raceNumber;

                this.MTP = mtp;
                this.MTPClass = _getMTPClass(this.MTP);
                this.near = this.MTPClass !== null;

                this.isCheckmark = this.betTypeId == 10 && steps.length == 1 && steps[0].BettingInterests.length == 1;

                this.stepName = this.isCheckmark ? 'Runner' : 'Runners';
                if (stepType.Name == 'RaceNumber') {
                    this.stepName = 'Leg';
                } else if (steps.length > 1) {
                    this.stepName = 'Pos';
                }
                this.isMultiColumn = steps.length > 1;

                this.BIs = [];
                this.printableBIs = [];

                for (var i = 0, length = steps.length; i < length; i++) {
                    var step = steps[i],
                        stepBIs = [],
                        printableStepBIs = [];

                    for (var j = 0, bisLength = step.BettingInterests.length; j < bisLength; j++) {
                        var bi = step.BettingInterests[j];
                        stepBIs.push(bi.BettingInterestNumber);
                        printableStepBIs.push({
                            bettingInterestNumber: bi.BettingInterestNumber,
                            favorite: bi.Favorite,
                            saddleColor: _getSaddleColor(_biColors, bi.BettingInterestNumber),
                            numberColor: _getNumberColor(_biColors, bi.BettingInterestNumber)
                        });
                    }

                    this.BIs.push(stepBIs.join(','));

                    this.printableBIs.push(_getPrintableSelections(printableStepBIs));
                }

                this.totalCost = this.betAmount * this.betCount;
                this.totalCostStr = $filter('currency')(this.totalCost, '$');

                this.action = 'bettype=' + this.betTypeId + '&' + 'betamount=' + this.betAmount + '&betselections=' + this.BIs.join(';') + '&' + 'talentPickId=' + this.id + '&' + 'talentPickName=' + this.talentName + (raceTypeCode === "G" ? "&greyhounds" : "");
                this.raceStatus = raceStatus;


                this.sendGTMEvent = function (val) {
                    var pageName = GTMFac.Tvg4SectionName().indexOf('Talent Picks') > -1 ? 'Talent Picks' : GTMFac.Tvg4ScreenName();

                    var obj = GTMFac.EventBuilder()
                        .withSectionName(GTMFac.Tvg4SectionName())
                        .withScreenName(GTMFac.Tvg4ScreenName())
                        .withGaEventAction(pageName + ' Page Click')
                        .withGaEventCategory('Site Click')
                        .withGaEventLabel(this.talentName + ' | ' + this.betTypeName + ' | ' + val)
                        .withEventLabel('TalentPicks-Talent' + val)
                        .withRaceNumber(this.gtmRaceNumber)
                        .withTrackName(this.trackName)
                        .build();

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', obj);
                };
            },
            build: function (rawData) {
                if ($rootScope.activeFeatures.enableGraphTalentPicks) {
                    return new TalentPickModel.TalentPick(
                        rawData.wagerType,
                        rawData.talent.name,
                        "", // TalentLastName
                        rawData.race.trackCode,
                        parseInt(rawData.race.number, 10),
                        "", // PerfAbbr
                        (new Date(rawData.race.postTime)).getTime().toString(),
                        rawData.amount,
                        rawData.betCount,
                        {Name: rawData.wagerType && rawData.wagerType.legCount > 1 ? "RaceNumber" : ""}, // StepType
                        _getBIgraph(rawData.selections, rawData.favorites, rawData.wagerType.legCount), // Steps
                        rawData.race.status.code,
                        rawData.talent.pictureUrl,
                        rawData.race.type.code,
                        rawData.race.mtp
                    );
                } else {
                    return new TalentPickModel.TalentPick(
                        rawData.WagerTypeID,
                        rawData.TalentFirstName,
                        rawData.TalentLastName,
                        rawData.TrackAbbr,
                        rawData.RaceNumber,
                        rawData.PerfAbbr,
                        rawData.PostTime,
                        rawData.WagerAmount,
                        rawData.BetCount,
                        rawData.StepType,
                        rawData.Steps,
                        rawData.RaceStatusAbbr
                    );
                }
            }
        };

        TalentPicksFac = {
            apiResponseTransformer: function (data) {
                if (angular.isArray(data)) {
                    return data
                        .map(TalentPickModel.build);
                }
                if (data) {
                    return TalentPickModel.build(data);
                }
                return [];
            },
            pollTalentPicksList: function (filter, parseDataFunction) {
                if ($rootScope.activeFeatures.enableGraphTalentPicks) {
                    talentPicksPoller = GraphTalentPicks.subscribe({
                        wagerProfile: WagerProfileFac.getSessionOrGenericProfile(),
                        filterBy: filter === "homepage" ? {isOpen: true} : {}
                    }, {
                        fail: _.noop,
                        success: function (response) {
                            parseDataFunction(TalentPicksFac.apiResponseTransformer(response));
                        },
                        error: _.noop
                    }, 60000);
                } else {
                    talentPicksPoller = poller.get(TalentPicksFac, {
                        action: 'getTalentPicks',
                        delay: $rootScope.layout === 'desktop' ? 60000 : 7200000,
                        argumentsArray: [filter]
                    });
                }

                return talentPicksPoller;
            },
            stopPollingTalentPicksList: function (deferred) {
                if (talentPicksPoller) {
                    if ($rootScope.activeFeatures.enableGraphTalentPicks) {
                        talentPicksPoller.unsubscribe();
                    } else {
                        talentPicksPoller.removeDeferred(deferred);
                    }
                }
            },
            getTalentPicks: function getTalentPicks(filter) {
                if ($rootScope.activeFeatures.enableGraphTalentPicks) {
                    return GraphTalentPicks
                        .get(WagerProfileFac.getSessionOrGenericProfile(), filter === "homepage" ? {isOpen: true} : {}, "", "") // wagerProfile, filterBy, trackCode, raceNumber
                        .then(function (response) {
                            return TalentPicksFac.apiResponseTransformer(response);
                        })
                        .catch(function (data, status) {
                            $log.error('Status: ' + status + ' | Message: ' + data);
                            return [];
                        });
                } else {
                    var url = '/ajax/talent-picks';

                    if (filter) {
                        url += '/filter/' + filter;
                    }

                    return $http.get(url)
                        .then(function (response) {
                            return TalentPicksFac.apiResponseTransformer(response.data);
                        })
                        .catch(function (data, status) {
                            $log.error('Status: ' + status + ' | Message: ' + data);
                            return [];
                        });
                }
            }
        };

        return {
            getTalentPicks: TalentPicksFac.getTalentPicks,
            pollTalentPicksList: TalentPicksFac.pollTalentPicksList,
            stopPollingTalentPicksList: TalentPicksFac.stopPollingTalentPicksList
        };
    }

    TalentPicksSvc.$inject = [
        'poller',
        '$http',
        'METADATA',
        '$filter',
        'ConfigurationFac',
        'GTMFac',
        '$rootScope',
        '$log',
        'GraphTalentPicks',
        'WagerProfileFac'
    ];

    return TalentPicksSvc;
});

