'use strict';

define('StatePersistenceFac',[],
    function () {
        function StatePersistenceFac($rootScope, $location) {

            var _state = {},
                _sessionState = {},
                _prevLocation = $location.path();

            $rootScope.$on('$viewContentLoaded', function () {
                if (_prevLocation != $location.path()) {
                    _state = {};

                    if ($rootScope.raceInfoModal) {
                        $rootScope.raceInfoModal.close();
                        $rootScope.raceInfoModal = null;
                    }
                }
                _prevLocation = $location.path();
            });

            function saveState(key, value, session) {
                if (session) {
                    _sessionState[key] = value;
                } else {
                    _state[key] = value;
                }
            }

            function loadState(key, session) {
                if (session) {
                    return _sessionState[key];
                } else {
                    return _state[key];
                }
            }

            function resetState(key, session) {
                if (session) {
                    _sessionState[key] = null;
                } else {
                    _state[key] = null;
                }
            }

            return {
                saveState: saveState,
                loadState: loadState,
                resetState: resetState
            };
        }

        StatePersistenceFac.$inject = ['$rootScope', '$location'];

        return StatePersistenceFac;
    }
);
