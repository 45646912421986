"use strict";

define('AccountSummaryCategoryEntity',[],function () {

    function AccountSummaryCategoryEntity(id, link, total, transaction){
        this.id = id;
        this.link = link;
        this.total = total;
        this.transaction = transaction;
     }

    return AccountSummaryCategoryEntity;

});

