'use strict';

define(
    'MyBetsTableCtrl',['moment', 'lodash', 'GenericMessage'],
    function (moment, _, GenericMessage) {
        /**
         * Controller for the MyBetsTable directive.
         * This controller is responsible to handle the interaction on the table, such as:
         * pagination, filtering, date periods changing, and sortings.
         * @param $scope
         * @param $filter
         * @param MyBetsSvc
         * @param AccountSummarySvc
         * @param $timeout
         * @constructor
         */
        function MyBetsTableCtrl($rootScope, $scope, $filter, MyBetsSvc, AccountSummarySvc, $timeout) {
            var dateFormat = 'YYYY-MM-DD';

            /**
             * Sets the pagination default parameters
             * @private
             */
            function _setUpPagination() {
                $scope.pagination = {
                    recordsPerPage: [25, 50, 100],
                    currentPage: 1,
                    totalRecords: 1,
                    maxPagesOnPagination: 3
                };

                $scope.pagination.selectedRecordsPerPage = $scope.pagination.recordsPerPage[0];
            }

            /**
             * Sets the columns and sorting options
             * @private
             */
            function _setUpSorting() {
                $scope.columns = [
                    { name: $filter('CMSLabels')('dateLabel', 'accountSummaryLabels'), sortId: 'transactiondate', defaultSortOrder: 'desc' },
                    { name: $filter('CMSLabels')('platformLabel', 'accountSummaryLabels') },
                    { name: 'Track', sortId: 'trackname', defaultSortOrder: 'asc' },
                    { name: 'R#', sortId: 'race' },
                    { name: $filter('CMSLabels')('betAmountLabel', 'accountSummaryLabels'), sortId: 'wageramount', defaultSortOrder: 'desc' },
                    { name: $filter('CMSLabels')('betTypeLabel', 'accountSummaryLabels'), sortId: 'bettype', defaultSortOrder: 'asc' },
                    { name: $filter('CMSLabels')('selectionsLabel', 'accountSummaryLabels') },
                    { name: $filter('CMSLabels')('betTotalLabel', 'accountSummaryLabels'), sortId: 'cost', defaultSortOrder: 'desc' },
                    { name: $filter('CMSLabels')('refundLabel', 'accountSummaryLabels') },
                    { name: $filter('CMSLabels')('payoutLabel', 'accountSummaryLabels'), sortId: 'winnings', defaultSortOrder: 'desc', cssClass: 'text-align-center' },
                    { name: $filter('CMSLabels')('statusLabel', 'accountSummaryLabels'), cssClass: 'text-align-center' }
                ];

                $scope.sortedColumn = $scope.columns[0];
                $scope.reverse = !($scope.sortedColumn.defaultSortOrder === 'asc');
            }

            /**
             * This function is responsible for treating WRO information that is fetched from the server
             * @param records
             * @returns {*}
             * @private
             */
            function _transformMyBetsData(records) {
                if (!records.length) {
                    return [];
                }
                var transformedRecords = records;
                var now = moment();

                transformedRecords.forEach(function (record) {
                    var wagerTypeName = record.wagerType.name || record.wagerType.code;
                    var recordSelections = record.selections.selection;
                    var transactionDate = moment(record.transactionDate);
                    var positionSelections, legSelections;
                    var daysBetweenTransactionDateAndNow = transactionDate.clone().startOf('day').diff(now.clone().startOf('day'), 'days');

                    record.selection = record.selections.selectionString.split(',WT,');

                    // we need to do this because WRO only send the base wager type, ignoring if it is a box or key or both
                    if (record.selections.selectionString.indexOf('KY') !== -1) {
                        wagerTypeName += ' Key';
                        record.wagerType.isKey = true;
                    }

                    if (record.selections.selectionString.indexOf('BX') !== -1) {
                        wagerTypeName += ' Box';
                        record.wagerType.isBox = true;
                    }

                    record.wagerType.name = wagerTypeName;

                    // detect the column type for this selections
                    // if any of the selection is P, means Pos -> Position
                    positionSelections = _.filter(recordSelections, function (selection) {
                        return selection.selectionType.code === 'P';
                    });

                    // if any of the selections is R, mans Rac -> Race -> Leg
                    legSelections = _.filter(recordSelections, function (selection) {
                        return selection.selectionType.code === 'R';
                    });

                    if (positionSelections.length) {
                        record.wagerType.columnType = 'Pos';
                    }

                    if (legSelections.length) {
                        record.wagerType.columnType = 'Leg';
                    }

                    // slices the KYBX from the selectionString so we can send it to the selections directives inside each row
                    if ((record.wagerType.isBox || record.wagerType.isKey) && isNaN(Number(record.selection[0]))) {
                        record.selection[0] = record.selection[0].slice(record.selection[0].indexOf(',') + 1);
                    }

                    // adds a transaction date string humanized for each bet
                    if (daysBetweenTransactionDateAndNow === 0) {
                        record.transactionDateString = transactionDate.format('[Today] [at] h:mm A');
                    } else if (daysBetweenTransactionDateAndNow === -1) {
                        record.transactionDateString = transactionDate.format('[Yesterday] [at] h:mm A');
                    } else {
                        record.transactionDateString = transactionDate.format('dddd, MMM Do [at] h:mm A');
                    }
                });

                return transformedRecords;
            }

            /**
             * Makes the call to the corresponding service.
             * The directive accepts an URL, so if the URL is given it means we are requesting UAS.
             * Other then that, request via WRO micro service.
             * @private
             */
            function _getBets() {
                $scope.loading = true;

                // When specifying the URL when declaring the directive, it means we are using the
                // WRO service from my account summary interface
                // Else, use the WRO micro service
                if ($scope.url) {
                    AccountSummarySvc.getWagersByDateFromGke(
                        $scope.url,
                        $scope.pagination.currentPage,
                        $scope.pagination.selectedRecordsPerPage
                    ).then(function (response) {
                        $scope.bets = _transformMyBetsData(response.categoryDetailsList);
                        $scope.pagination.totalRecords = response.totalRecordCount;
                    }).catch(function () {
                        $scope.bets = [];
                    }).finally(function () {
                        _closeAllOpenedNotifications();
                        $scope.loading = false;
                    });
                } else if(!$rootScope.activeFeatures.tvgenablebehg) {
                    MyBetsSvc.getBetsByDateFromGke(
                        moment($scope.startDate).format(dateFormat),
                        moment($scope.endDate).format(dateFormat),
                        $scope.trackAbbr,
                        null,
                        $scope.betStatus,
                        $scope.pagination.currentPage,
                        $scope.pagination.selectedRecordsPerPage,
                        $scope.sortedColumn.sortId,
                        !$scope.reverse
                    ).then(function (response) {
                        $scope.pagination.totalRecords = response.totalCount;
                        $scope.bets = _transformMyBetsData(response.wagers);
                    }).catch(function () {
                        $scope.bets = [];
                    }).finally(function () {
                        _closeAllOpenedNotifications();
                        $scope.loading = false;
                    });
                } else {
                    _closeAllOpenedNotifications();
                    $scope.loading = false;
                }
            }


            /**
             * @private
             * @description
             * This function closes all opened notifications, if any.
             * This includes generic messages and/or cancel forms.
             */
            function _closeAllOpenedNotifications() {
                $scope.genericMessage.showMessage = false;
                $scope.bets.forEach(function (bet) {
                    bet.showCancelForm = false;
                    bet.cancelBet = {
                        showMessage: false
                    };
                    bet.isCanceling = false;
                });
            }

            (function init() {
                $scope.loading = true;
                _setUpSorting();
                _setUpPagination();
                _getBets();

                $scope.genericMessage = new GenericMessage();

                /**
                 * Makes a new request according to new values that the directive is receiving
                 * Compares the old / new value to make sure that we only request the service again
                 * if actually the data has changed. Called in the my-bets-table directive
                 * declaration.
                 *
                 * For example, this runs when user changes the date filter
                 * @param oldVal
                 * @param newVal
                 */
                $scope.reloadBets = function (oldVal, newVal) {
                    if (newVal !== oldVal) {
                        _getBets();
                    }
                };

                /**
                 * Executed when user change spage number or records per page
                 * @param page
                 * @param changeRecordsPerPage
                 */
                $scope.changePagination = function (page, changeRecordsPerPage) {
                    $scope.pagination.currentPage = changeRecordsPerPage ? 1 : page || $scope.pagination.currentPage;
                    _getBets();
                };

                /**
                 * Function exposed to scope that gets activated every time the user clicks in
                 * one of the table header
                 * @param column
                 */
                $scope.sortColumn = function (column) {
                    $scope.loading = true;

                    if (column === $scope.sortedColumn) {
                        $scope.reverse = !$scope.reverse;
                    } else {
                        $scope.reverse = !(column.defaultSortOrder === 'asc');
                    }

                    $scope.sortedColumn = column;

                    _setUpPagination();
                    _getBets();
                };

                /**
                 * Triggered when clicking the close button on a info message when canceling a bet
                 * @param obj
                 */
                $scope.onCloseButtonClick = function (obj) {
                    obj.showMessage = false;
                };

                /**
                 * Listens for myBetsPeriodChanged to reset pagination.
                 * Executed for example when user changes the period in my bets interface
                 * (since yesterday, since last month, etc.)
                 */
                $scope.$on('myBetsPeriodChanged', function () {
                    _setUpPagination();
                    $timeout(function () {
                        _getBets();
                    });
                });

                /**
                 * Listens for when the user presses confirm/yes to cancel a bet
                 * Sent from myBetsCancelBetCtrl
                 */
                $scope.$on('cancelingBet', function () {
                    $scope.isCanceling = true;
                });

                /**
                 * Listens for failures on bet cancellation
                 * Sent from myBetsCancelBetCtrl
                 */
                $scope.$on('cancelBetFail', function () {
                    $scope.isCanceling = false;
                });

                /**
                 * Listens for success on bet cancellation
                 * Sent from myBetsCancelBetCtrl
                 */
                $scope.$on('betCancelled', function () {
                    $scope.isCanceling = false;
                });
            }());
        }

        MyBetsTableCtrl.$inject = [
            '$rootScope',
            '$scope',
            '$filter',
            'MyBetsSvc',
            'AccountSummarySvc',
            '$timeout'
        ];

        return MyBetsTableCtrl;
    }
);

