'use strict';

define('RaceReplayEntity',[],function () {

    /***
     *
     * @param raceDate
     * @param postTime
     * @param raceTypeName
     * @param trackName
     * @param trackAbbr
     * @param providerTrack
     * @param raceNumber
     * @param perfAbbr
     * @param raceClass
     * @param distance
     * @param provider
     * @param raceTypeId
     * @constructor
     */
    function RaceReplayEntity(raceDate, postTime, raceTypeName, trackName, trackAbbr, providerTrack, raceNumber, perfAbbr, raceClass,
                        distance, provider, raceTypeId){


        this.raceDate = raceDate;
        this.postTime = postTime;
        this.raceTypeName = raceTypeName;
        this.trackName = trackName;
        this.trackAbbr = trackAbbr;
        this.providerTrack = providerTrack;
        this.raceNumber = raceNumber;
        this.perfAbbr = perfAbbr;
        this.raceClass = raceClass;
        this.distance = distance;
        this.provider = provider;
        this.raceTypeId = raceTypeId;

    }

    return RaceReplayEntity;

});


