"use strict";

define('RaceMtpLabelMod',[
        'RaceMtpLabelDir'

    ],
    function (raceMtpLabelDir) {

        angular.module('TVG.RaceMTPLabel', [])

            .directive('raceMtpLabel', raceMtpLabelDir);

    }
);

