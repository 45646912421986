'use strict';

define('UserPromotionsSvc',[], function () {

    function UserPromotionsSvc($rootScope, PromotionsSvc, poller) {

        var PromotionsPollerDeferred = null;
        var userPromotions = [];

        var promosFac = {
            requestPromos: function () {
                return PromotionsSvc.getPromotionsByUser(
                    $rootScope.user.accountNumber,
                    "race"
                )
                    .then(function(promotions) {
                        userPromotions = promotions;
                        $rootScope.$broadcast("userPromosUpdate");
                    })
                    .catch(function() {
                        userPromotions = [];
                        $rootScope.$broadcast("userPromosUpdate");
                    });
            }
        };

        function _forcePromotionsUpdate() {
            _stopPromotionsPoller();
            _startPromotionsPoller();
        }

        function _startPromotionsPoller(){
            if (!PromotionsPollerDeferred) {
                PromotionsPollerDeferred = poller.get(promosFac, {
                    action: 'requestPromos',
                    delay: 60000,
                    argumentsArray: [
                        {}
                    ]
                });
            }
        }

        function _stopPromotionsPoller(){
            if (PromotionsPollerDeferred) {
                PromotionsPollerDeferred.removeDeferred(PromotionsPollerDeferred);
                PromotionsPollerDeferred = null;
                userPromotions = [];
            }
        }

        function _getUserPromos() {
            return userPromotions;
        }

        return {
            forcePromotionsUpdate: _forcePromotionsUpdate,
            getUserPromos: _getUserPromos,
            startPromotionsPoller: _startPromotionsPoller,
            stopPromotionsPoller: _stopPromotionsPoller
        };

    }

    UserPromotionsSvc.$inject = [
        '$rootScope',
        'PromotionsSvc',
        'poller'
    ];

    return UserPromotionsSvc;
});

