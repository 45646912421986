'use strict';

define('RaceInfoFac',[
    'RaceStatusUtils',
    'lodash'
],
    function (RaceStatusUtils, _) {
        function RaceInfoFac(
            $rootScope,
            poller,
            METADATA,
            $http,
            $q,
            BetsSvc,
            $filter,
            TalentPicksSvc,
            TimeUtilFac,
            UserSessionSvc,
            WagerProfileFac
        ) {
            var upcomingPoller,
                upcomingResource,
                resultsPoller,
                resultsResource,
                trackCollectionPoller,
                InfoSvc,
                _lastUpData = null,
                _lastUpTimestamp = 0,
                RacesFac,
                RunnersFac,
                ResultsFac,
                PoolsFac,
                ProbablesFac,
                ChangesFac,
                WillPaysFac,
                HandicappingInfoFac,
                _currentDate = new Date(),
                _currentRace = null,
                _biColors = [],
                _lowestOdd = Number.MAX_VALUE,
                _prevWagerProfile = WagerProfileFac.PROFILES.ALL_TRACKS,
                validHorseIdRegex = /^\d+[A-Z]?$/;


            PoolsFac = {
                poolsBuilder: function (pools, bi_runner, bi_colors, totals) {
                    if (pools && angular.isArray(pools) && pools.length > 0 && typeof bi_runner !== 'undefined') {

                        var winPool = 0,
                            placePool = 0,
                            showPool = 0,
                            wagerTypeId = 0;

                        pools.forEach(function (value) {
                            wagerTypeId = value.wagerType ? value.wagerType.id : value.WagerTypeID;

                            switch (wagerTypeId) {
                                case 10: // Win
                                    winPool = value.poolRunnersData ? value.poolRunnersData[0].amount : value.Amount;
                                    break;
                                case 20: // Place
                                    placePool = value.poolRunnersData ? value.poolRunnersData[0].amount : value.Amount;
                                    break;
                                case 30: // Show
                                    showPool = value.poolRunnersData ? value.poolRunnersData[0].amount : value.Amount;
                                    break;
                            }
                        });

                        return {
                            totals: totals,
                            pool: {
                                runnerName: bi_runner.horseName || bi_runner.HorseName,
                                bettingInterestNumber: bi_runner.runnerId || bi_runner.RunnerID,
                                saddleColor: bi_colors ? bi_colors[1] : _getSaddleColor(_biColors, bi_runner.RunnerID),
                                numberColor: bi_colors ? bi_colors[0] : _getNumberColor(_biColors, bi_runner.RunnerID),
                                winPayoff: $filter('currency')(winPool, '$', 0),
                                placePayoff: $filter('currency')(placePool, '$', 0),
                                showPayoff: $filter('currency')(showPool, '$', 0)
                            }
                        };
                    }
                    return null;
                },

                apiResponseTransformer: function (response) {

                    var BIs = response,
                        data = { runners: [] },
                        totals = [
                            { name: "Win", amount: 0 },
                            { name: "Place", amount: 0 },
                            { name: "Show", amount: 0 }
                        ];

                    if (angular.isArray(BIs)) {
                        angular.forEach(BIs, function (BI) {
                            var biPools = BI.hasOwnProperty('biPools') ? BI.biPools : BI.Pools || [];

                            if (angular.isArray(biPools)) {
                                for (var i = 0; i < biPools.length; i++) {
                                    var wagerTypeId = biPools[i].wagerType ? biPools[i].wagerType.id : biPools[i].WagerTypeID;
                                    var poolAmount = biPools[i].poolRunnersData ? biPools[i].poolRunnersData[0].amount : biPools[i].Amount;

                                    switch (wagerTypeId) {
                                        case 10: // Win
                                            totals[0].amount += poolAmount;
                                            break;
                                        case 20: // Place
                                            totals[1].amount += poolAmount;
                                            break;
                                        case 30: // Show
                                            totals[2].amount += poolAmount;
                                            break;
                                    }
                                }
                            }
                        });

                        angular.forEach(BIs, function (BI) {
                            var runners = BI.runners ? BI.runners[0] : BI.Runners[0];
                            var pools = BI.hasOwnProperty('biPools') ? BI.biPools : BI.Pools || [];
                            var colors = BI.numberColor && BI.saddleColor ? [BI.numberColor, BI.saddleColor] : false;
                            if (runners && validHorseIdRegex.test(runners.runnerId || runners.RunnerID)) {
                                data.runners.push(PoolsFac.poolsBuilder(pools, runners, colors, totals));
                            }
                        });
                    }

                    return data;
                }
            };

            ProbablesFac = {
                probablesBuilder: function (response, raceTypeID, pools, bettingInterests) {
                    var probs = [];
                    var wagerTypesToSkip = [10, 20, 30, 40, 50, 60, 70];

                    angular.forEach(response, function (prob) {
                        var poolAmount,
                            wagerTypeID = prob.hasOwnProperty('wagerType') ? prob.wagerType.id : prob.WagerTypeID,
                            betCombos = prob.hasOwnProperty('betCombos') ? prob.betCombos : prob.BetCombos,
                            matrix = [],
                            colors = METADATA.biColors ? METADATA.biColors[raceTypeID] : [],
                            colOrderedHeaders = [],
                            rowOrderedHeaders = [],
                            runnersNumbers = {},
                            saddleColors = {},
                            runnerNumberColors = {},
                            headerBis = [],
                            rowBis = [];

                        if (wagerTypesToSkip.indexOf(wagerTypeID) === -1) {
                            headerBis = bettingInterests ? bettingInterests[0] : null;

                            // if the wager type is 310 (daily double), it means the rowBis are going
                            // to be the Bis for the next race
                            // else, it's just the same as the header bis (this race bis)
                            if (wagerTypeID === 310) {
                                rowBis = bettingInterests ? bettingInterests[1] : null;
                            } else {
                                rowBis = headerBis;
                            }

                            poolAmount = pools.filter(function (pool) {
                                return pool.hasOwnProperty('wagerType') ? pool.wagerType.id == wagerTypeID : pool.WagerTypeID == wagerTypeID;
                            });

                            if (angular.isArray(poolAmount) && poolAmount.length) {
                                poolAmount = poolAmount[0].hasOwnProperty('amount') ? poolAmount[0].amount : poolAmount[0].Amount;
                            }

                            betCombos = _.sortBy(
                                _.sortBy(betCombos, function (bet) {
                                    return parseInt(bet.runner1, 10);
                                }), function (bet) {
                                    return parseInt(bet.runner2, 10);
                                }
                            );

                            angular.forEach(betCombos, function (bc) {
                                var runner1Number = bc.hasOwnProperty('runner1') ? parseInt(bc.runner1, 10) : bc.Runner1,
                                    runner2Number = bc.hasOwnProperty('runner2') ? parseInt(bc.runner2, 10) : bc.Runner2,
                                    payout = bc.hasOwnProperty('payout') ? bc.payout > 0 ? parseInt(bc.payout, 10) : '-' : bc.Payout,
                                    bettingInterestForRunner1 = headerBis ? _.find(headerBis, { biNumber: runner1Number }) : null,
                                    bettingInterestForRunner2 = rowBis ? _.find(rowBis, { biNumber: runner2Number }) : null;

                                if (runner1Number && runner2Number) {
                                    var headerObj = {
                                        runnerNumber: runner1Number,
                                        runnerSaddleColor: bettingInterestForRunner1 ? bettingInterestForRunner1.saddleColor : _getSaddleColor(colors, runner1Number),
                                        runnerNumberColor: bettingInterestForRunner1 ? bettingInterestForRunner1.numberColor : _getNumberColor(colors, runner1Number)
                                    };

                                    if (!matrix[runner2Number - 1]) {
                                        matrix[runner2Number - 1] = [];
                                    }

                                    matrix[runner2Number - 1][runner1Number - 1] = {
                                        payout: payout,
                                        runner1: runner1Number,
                                        runner2: runner2Number
                                    };

                                    saddleColors[runner1Number] = bettingInterestForRunner1 ? bettingInterestForRunner1.saddleColor : _getSaddleColor(colors, runner1Number);
                                    saddleColors[runner2Number] = bettingInterestForRunner2 ? bettingInterestForRunner2.saddleColor : _getSaddleColor(colors, runner2Number);
                                    runnerNumberColors[runner1Number] = bettingInterestForRunner1 ? bettingInterestForRunner1.numberColor : _getNumberColor(colors, runner1Number);
                                    runnerNumberColors[runner2Number] = bettingInterestForRunner2 ? bettingInterestForRunner2.numberColor : _getNumberColor(colors, runner2Number);

                                    if (!runnersNumbers.hasOwnProperty(headerObj.runnerNumber)) {
                                        runnersNumbers[headerObj.runnerNumber] = headerObj;
                                    }

                                    if (colOrderedHeaders.indexOf(runner1Number) == -1) {
                                        colOrderedHeaders.push(runner1Number);
                                    }

                                    if (rowOrderedHeaders.indexOf(runner2Number) == -1) {
                                        rowOrderedHeaders.push(runner2Number);
                                    }
                                }

                            });

                            if (matrix.length > 0 && Object.keys(runnersNumbers).length > 0) {
                                probs.push({
                                    wagerTypeId: wagerTypeID,
                                    wagerTypeName: prob.hasOwnProperty('wagerType') ? prob.wagerType.name : METADATA.wagerTypes && METADATA.wagerTypes[wagerTypeID] ? METADATA.wagerTypes[wagerTypeID].Name : '',
                                    minWagerAmount: prob.hasOwnProperty('minWagerAmount') ? prob.minWagerAmount : "",
                                    poolAmount: poolAmount,
                                    betCombos: matrix,
                                    firstColHeaders: runnersNumbers,
                                    colOrderedHeaders: colOrderedHeaders,
                                    rowOrderedHeaders: rowOrderedHeaders,
                                    saddleColors: saddleColors,
                                    runnerNumberColors: runnerNumberColors
                                });
                            }
                        }
                    });

                    return probs;
                },

                apiResponseTransformer: function (response, raceTypeID, pools) {
                    return this.probablesBuilder(response, raceTypeID, pools);
                }
            };

            ChangesFac = {

                changesBuilder: function (changes) {

                    var raceChanges = [];
                    var horseChanges = [];
                    var jockeyChanges = [];

                    if (changes) {
                        var raceChangesList = changes.raceChanges || changes.RaceChanges;
                        var horseChangesList = changes.horseChanges || changes.HorseChanges;

                        angular.forEach(raceChangesList, function (raceChange) {
                            raceChanges.push({
                                description: raceChange.description || raceChange.Description,
                                oldValue: raceChange.oldValue || raceChange.OldValue,
                                newValue: raceChange.newValue || raceChange.NewValue,
                                timestamp: raceChange.date ? new Date(raceChange.date) : new Date(TimeUtilFac.getTimestamp(raceChange.TimeStamp))
                            });
                        });

                        angular.forEach(horseChangesList, function (horseChange) {
                            var horseChangeChanges = horseChange.changes || horseChange.Changes;
                            angular.forEach(horseChangeChanges, function (change) {
                                var description = change.description || change.Description;
                                if (description == "Scratched") {
                                    horseChanges.push({
                                        horseName: horseChange.horseName || horseChange.Horse,
                                        programNumber: horseChange.runnerId || horseChange.ProgramNumber,
                                        numberColor: _getNumberColor(_biColors, parseInt(horseChange.runnerId || horseChange.ProgramNumber, 10)),
                                        saddleColor: _getSaddleColor(_biColors, parseInt(horseChange.runnerId || horseChange.ProgramNumber, 10)),
                                        description: description,
                                        oldValue: change.oldValue || change.OldValue,
                                        newValue: change.newValue || change.NewValue,
                                        timestamp: new Date(TimeUtilFac.getTimestamp(change.TimeStamp))
                                    });
                                } else if (description == "Jockey") {
                                    jockeyChanges.push({
                                        horseName: horseChange.horseName || horseChange.Horse,
                                        programNumber: horseChange.runnerId || horseChange.ProgramNumber,
                                        numberColor: _getNumberColor(_biColors, parseInt(horseChange.runnerId || horseChange.ProgramNumber, 10)),
                                        saddleColor: _getSaddleColor(_biColors, parseInt(horseChange.runnerId || horseChange.ProgramNumber, 10)),
                                        description: description,
                                        oldValue: change.oldValue || change.OldValue,
                                        newValue: change.newValue || change.NewValue,
                                        timestamp: change.date ? new Date(change.date) : new Date(TimeUtilFac.getTimestamp(change.TimeStamp))
                                    });
                                }
                            });
                        });
                    }
                    return {
                        raceChanges: raceChanges,
                        horseChanges: horseChanges,
                        jockeyChanges: jockeyChanges
                    };
                },

                apiResponseTransformer: function (response) {
                    return this.changesBuilder(response);
                }
            };

            WillPaysFac = {
                parseWillPaysDescription: function (amount, wagerId, wagerName, previousResults) {
                    var results = "Results:";

                    angular.forEach(previousResults, function (previous, index) {
                        var winningBi = previous.hasOwnProperty('winningBi') ? previous.winningBi : previous.WinningBI;
                        if (index != 0) {
                            results += ' |';
                        }
                        results += ' ' + winningBi;
                    });

                    results += ' | *';

                    if (!wagerName && !!METADATA.wagerTypes[wagerId]) {
                        wagerName = METADATA.wagerTypes[wagerId].Name;
                    }
                    return $filter('currency')(amount, '$') + ' ' + wagerName + ' ' + results;
                },

                parsePayouts: function (rawData, bettingInterests, _biColors) {
                    var payouts = [];

                    angular.forEach(rawData, function (payout) {
                        var runners = [],
                            bi = [],
                            horseName = horseName;
                        if (bettingInterests) {
                            bi = bettingInterests.filter(function (BI) {
                                var biNumber = payout.bettingInterestNumber || payout.BettingInterestNumber;
                                var horseId = BI.runners ? BI.runners[0].runnerId : BI.horseID;
                                return horseId == biNumber;
                            });

                            if (bi[0] && bi[0].runners) {
                                runners = bi[0].runners;
                            } else {
                                runners = bi;
                            }

                            if (angular.isArray(runners) && runners.length) {
                                horseName = runners[0].horseName;
                            }
                        }

                        var showPayoff = payout.hasOwnProperty('payoutAmount') ? payout.payoutAmount : payout.PayoutAmount;

                        payouts.push({
                            saddleColor: bi[0] ? bi[0].saddleColor : _getSaddleColor(_biColors, payout.BettingInterestNumber),
                            numberColor: bi[0] ? bi[0].numberColor : _getNumberColor(_biColors, payout.BettingInterestNumber),
                            showPayoff: $filter('currency')(showPayoff, '$'),
                            bettingInterestNumber: payout.bettingInterestNumber || payout.BettingInterestNumber,
                            runnerName: horseName
                        });
                    });

                    return payouts;
                },

                willPaysBuilder: function (rawData, bettingInterests, _biColors) {
                    var wagerAmount = rawData.hasOwnProperty('wagerAmount') ? rawData.wagerAmount : rawData.WagerAmount;
                    var wagerTypeId = rawData.type ? rawData.type.id : rawData.WagerTypeID;
                    var wagerTypeName = rawData.type ? rawData.type.name : "";
                    var legResults = rawData.legResults || rawData.PreviousLegResults;
                    var payouts = rawData.payouts || rawData.WillPayPayouts;

                    return {
                        wagerAmount: wagerAmount,
                        wagerTypeId: wagerTypeId,
                        previousLegResults: legResults,
                        description: WillPaysFac.parseWillPaysDescription(wagerAmount, wagerTypeId, wagerTypeName, legResults),
                        payouts: WillPaysFac.parsePayouts(payouts, bettingInterests, _biColors)
                    };

                },
                apiResponseTransformer: function (data, bettingInterests, raceTypeId) {
                    var _biColors = METADATA.biColors ? METADATA.biColors[raceTypeId] : [];
                    if (angular.isArray(data)) {
                        var parsedData = [];
                        angular.forEach(data, function (willPays) {
                            parsedData.push(WillPaysFac.willPaysBuilder(willPays, bettingInterests, _biColors));
                        });
                        return parsedData;
                    }

                    return [];
                }
            };

            HandicappingInfoFac = {

                getBIForRunner: function (runnerId, bettingInterests) {
                    var correspondingBI = null;
                    for (var i = 0; i < bettingInterests.length; i++) {
                        var BI = bettingInterests[i];

                        if (BI.horseID == runnerId) {
                            correspondingBI = BI;
                            break;
                        }
                    }

                    return correspondingBI;
                },

                getJockeyAndTrainerNames: function (horse) {
                    return horse.BI.jockeyName + " & " + horse.BI.trainerName;
                },

                getOwnerAndParentInfo: function (horse) {
                    return "(" + horse.BI.ownerName + ") " + horse.BI.sire + "-" + horse.BI.dam;
                },

                getHorsePedigree: function (horse) {
                    return horse.BI.age + " " + horse.BI.sex + " " + horse.BI.sire + "-" + horse.BI.dam + (horse.BI.damSire ? "<strong> by " + horse.BI.damSire + "</strong>" : "") + " (" + horse.BI.ownerName + ")";
                },

                buildHandicappingListsForTypes: function (handicapData, _biColors, raceTypeId) {

                    var handicappingInfo = [],

                        handicappingOptions = [
                            'jockey_trainer_info',
                            'pedigree',
                            'snapshot_freepick_info',
                            'speed_class_rating',
                            'pace',
                            'jockey_trainer_stats'
                        ],

                        handicappingOptionsStrings = [
                            (raceTypeId == 2 ? 'Driver & Trainer Info' : 'Jockey & Trainer Info'),
                            'Pedigree',
                            'Snapshot & Free Pick Info',
                            'Speed & Class Rating',
                            'Pace',
                            (raceTypeId == 2 ? 'Driver & Trainer Stats' : 'Jockey & Trainer Stats')
                        ];

                    angular.forEach(handicappingOptions, function (option, optionIndex) {
                        var optionInfo = {
                            type: option,
                            description: handicappingOptionsStrings[optionIndex],
                            runners: [],
                            headers: [],
                            sortOptions: []
                        };

                        switch (option) {
                            case 'jockey_trainer_info':
                                optionInfo.headers = ['', '', 'Med', 'Weight'];
                                optionInfo.sortOptions = ['', '', 'JTMed', 'JTWeight'];
                                break;
                            case 'pedigree':
                                optionInfo.headers = [];
                                optionInfo.sortOptions = [];
                                break;
                            case 'snapshot_freepick_info':
                                optionInfo.headers = ['', '', 'Days Off', 'Wins/ Starts', 'Power Rating'];
                                optionInfo.sortOptions = ['', '', 'SDaysOff', 'SWinsStarts', 'SPowerRating'];
                                break;
                            case 'speed_class_rating':
                                optionInfo.headers = ['', '', 'Avg Speed', 'Avg Dist', 'High Speed', 'Avg Class', 'Last Class'];
                                optionInfo.sortOptions = ['', '', 'RAvgSpeed', 'RAvgDist', 'RHighSpeed', 'RAvgClass', 'RLastClass'];
                                break;
                            case 'pace':
                                optionInfo.headers = ['', '', 'Early', 'Middle', 'Finish', '# of Races'];
                                optionInfo.sortOptions = ['', '', 'PEarly', 'PMiddle', 'PFinish', 'P#ofRaces'];
                                break;
                            case 'jockey_trainer_stats':
                                optionInfo.headers = ['', '', 'Starts', '1st', '2nd', '3rd'];
                                optionInfo.sortOptions = ['', '', 'JTSStarts', 'JTS1st', 'JTS2nd', 'JTS3rd'];
                                break;
                            default:
                                optionInfo.headers = ['', '', 'Weight'];
                                optionInfo.sortOptions = ['', '', 'Weight'];
                                break;
                        }

                        angular.forEach(handicapData, function (horseData) {
                            var runnerData = {
                                horseId: horseData.runner.RunnerID,
                                horseName: horseData.BI.horseName,
                                saddleColor: _getSaddleColor(_biColors, horseData.BI.bettingInterestNumber),
                                numberColor: _getNumberColor(_biColors, horseData.BI.bettingInterestNumber),
                                values: []
                            };

                            switch (option) {
                                case 'jockey_trainer_info':
                                    runnerData.values = [
                                        (horseData.BI.medication || '-'),
                                        (horseData.BI.weight || '-')
                                    ];
                                    runnerData.description = HandicappingInfoFac.getJockeyAndTrainerNames(horseData);
                                    break;
                                case 'pedigree':
                                    runnerData.values = [];
                                    runnerData.description = HandicappingInfoFac.getHorsePedigree(horseData);
                                    break;
                                case 'snapshot_freepick_info':
                                    runnerData.pick = horseData.runner.Pick;
                                    runnerData.values = [
                                        (horseData.runner.DaysOff || '-'),
                                        (horseData.runner.Wins + '/' + horseData.runner.Starts),
                                        (horseData.runner.PowerRating || '-')
                                    ];
                                    runnerData.description = HandicappingInfoFac.getOwnerAndParentInfo(horseData);

                                    break;
                                case 'speed_class_rating':
                                    runnerData.values = [
                                        (horseData.runner.AvgSpeed || '-'),
                                        (horseData.runner.AvgDistance || '-'),
                                        (horseData.runner.HighSpeed || '-'),
                                        (horseData.runner.AvgClassRating || '-'),
                                        (horseData.runner.LastClassRating || '-')
                                    ];
                                    runnerData.description = HandicappingInfoFac.getOwnerAndParentInfo(horseData);

                                    break;
                                case 'pace':
                                    runnerData.values = [
                                        (horseData.runner.AvgPace.Early || '0'),
                                        (horseData.runner.AvgPace.Middle || '0'),
                                        (horseData.runner.AvgPace.Finish || '0'),
                                        (horseData.runner.AvgPace.NumRaces || '0')
                                    ];
                                    runnerData.description = HandicappingInfoFac.getOwnerAndParentInfo(horseData);
                                    break;
                                case 'jockey_trainer_stats':
                                    runnerData.values = [
                                        (horseData.runner.JockeyTrainer.Starts || '0'),
                                        (horseData.runner.JockeyTrainer.Wins || '0'),
                                        (horseData.runner.JockeyTrainer.Places || '0'),
                                        (horseData.runner.JockeyTrainer.Shows || '0')
                                    ];
                                    runnerData.description = HandicappingInfoFac.getJockeyAndTrainerNames(horseData);
                                    break;
                                default:
                                    runnerData.values = [
                                        (horseData.BI.weight || '-')
                                    ];
                                    runnerData.description = HandicappingInfoFac.getHorsePedigree(horseData);
                                    break;
                            }

                            optionInfo.runners.push(runnerData);
                        });
                        if (optionInfo.runners.length) {
                            handicappingInfo.push(optionInfo);
                        }
                    });

                    return handicappingInfo;
                },

                handicappingBuilder: function (data, race) {
                    var handicappingInfo,
                        runnersHandicappingInfo = [],
                        bettingInterests = race.bettingInterests,
                        _biColors = METADATA.biColors ? METADATA.biColors[race.raceTypeId] : [],
                        freePicksSelections = [],
                        freePicksSelectionsPositions = [],
                        freePicks = [];

                    angular.forEach(data.freePicks, function (freePick) {
                        if (freePick.Info) {
                            freePicks.push({
                                number: parseInt(freePick.Number, 10),
                                info: freePick.Info,
                                runnerId: freePick.runnerId
                            });

                            if (freePick.hasOwnProperty('biNumber')) {
                                freePicksSelections.push(parseInt(freePick.biNumber, 10));
                                freePicksSelectionsPositions.push(parseInt(freePick.Number, 10));
                            } else {
                                var bettingInterest = freePick.Info.match(/#[0-9]+[^\s]*/i);
                                if (bettingInterest && bettingInterest.length > 0) {
                                    bettingInterest = bettingInterest[0].substr(1);
                                    freePicksSelections.push(parseInt(bettingInterest, 10));
                                    freePicksSelectionsPositions.push(parseInt(freePick.Number, 10));
                                }
                            }
                        }
                    });

                    angular.forEach(data.handicappingData, function (horseHandicap) {
                        angular.forEach(horseHandicap.Runners, function (runner) {
                            var runnerId = parseInt(runner.RunnerID, 10),
                                BI = HandicappingInfoFac.getBIForRunner(runnerId, bettingInterests),
                                freePickIndex = freePicksSelections.indexOf(runnerId);

                            var pickForRunner = _.find(freePicks, function (pick) {
                                return pick.runnerId === runner.RunnerID;
                            });

                            if (freePickIndex >= 0 && angular.isDefined(pickForRunner)) {
                                runner.Pick = pickForRunner.number;
                            }

                            if (BI) {
                                runnersHandicappingInfo.push({ runner: runner, BI: BI });
                            }
                        });
                    });
                    handicappingInfo = HandicappingInfoFac.buildHandicappingListsForTypes(runnersHandicappingInfo, _biColors, race.raceTypeId);

                    return {
                        raceId: race.id,
                        handicapData: handicappingInfo,
                        freePicks: freePicks.sort(function (a, b) {
                            return a - b;
                        })
                    };
                },

                apiResponseTransformer: function (response, race) {
                    var data = response.data;
                    return HandicappingInfoFac.handicappingBuilder(data, race);
                }
            };

            ResultsFac = {
                payoffsBuilder: function (payoffs) {
                    var payoffsArray = [];

                    angular.forEach(payoffs, function (payoff) {
                        var wagerAmount = payoff.WagerAmount || payoff.wagerAmount,
                            wagerTypeID = payoff.wagerType ? payoff.wagerType.id : payoff.WagerTypeID;

                        if (!!METADATA.wagerTypes[wagerTypeID]) {
                            angular.forEach(payoff.Selections || payoff.selections, function (sel) {
                                payoffsArray.push({
                                    wagerAmount: wagerAmount,
                                    wagerTypeID: wagerTypeID,
                                    wagerTypeName: METADATA.wagerTypes[wagerTypeID].Name,
                                    wagerTypeAbbr: METADATA.wagerTypes[wagerTypeID].Abbreviation,
                                    payoutAmount: sel.PayoutAmount || sel.payoutAmount,
                                    selection: sel.Selection || sel.selection
                                });
                            });
                        }
                    });
                    return payoffsArray;
                },

                runnersBuilder: function (runners, _biColors) {
                    var runnersArray = [];

                    angular.forEach(runners, function (runner) {
                        var ba = runner.BetAmount || runner.betAmount,
                            bin = runner.BettingInterestNumber || runner.biNumber,
                            fp = runner.FinishPosition || runner.finishPosition,
                            pp = (fp <= 2 || (runner.PlacePayoff || runner.placePayoff) > 0) ? $filter('currency')(runner.PlacePayoff || runner.placePayoff, '$') : '',
                            rn = runner.RunnerName || runner.runnerName,
                            sp = ((runner.ShowPayoff || runner.showPayoff) > 0) ? $filter('currency')(runner.ShowPayoff || runner.showPayoff, '$') : '',
                            wp = (fp == 1 || (runner.WinPayoff || runner.winPayoff) > 0) ? $filter('currency')(runner.WinPayoff || runner.winPayoff, '$') : "";

                        runnersArray.push({
                            betAmount: ba,
                            bettingInterestNumber: bin,
                            finishPosition: fp,
                            placePayoff: pp,
                            runnerName: rn,
                            showPayoff: sp,
                            winPayoff: wp,
                            saddleColor: _getSaddleColor(_biColors, bin),
                            numberColor: _getNumberColor(_biColors, bin)
                        });
                    });
                    return runnersArray;
                },

                apiResponseTransformer: function (response, raceTypeId) {
                    var payoffs = response.Payoffs || response.payoffs,
                        runners = response.Runners || response.runners;

                    _biColors = METADATA.biColors ? METADATA.biColors[raceTypeId] : [];

                    if (angular.isArray(payoffs) && angular.isArray(runners)) {
                        return {
                            payoffs: this.payoffsBuilder(payoffs),
                            runners: this.runnersBuilder(runners, _biColors)
                        };
                    }
                },

                buildRaceResultsTitle: function (race) {
                    if (!angular.isObject(race) || !race.raceNumber || !race.postTimeString || !race.postTimeMarker) {
                        throw new Error("raceInfoFac.buildRaceResultsTitle(): Invalid race argument.");
                    }

                    var template = "Race: __raceNumber__ __racePostTime__ __racePostTimeMarker__";

                    return template.replace("__raceNumber__", race.raceNumber)
                        .replace("__racePostTime__", race.postTimeString)
                        .replace("__racePostTimeMarker__", race.postTimeMarker);
                }
            };

            function _getSaddleLayout(arr, biNr) {
                return _.find(arr, function (item) {
                    return parseInt(item.id) === parseInt(biNr);
                });
            }

            function _getSaddleColor(arr, biNr) {
                if (!arr) {
                    // $log.warn("no data to search saddle color");
                    return;
                }
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i] && arr[i].BettingInterestNumber == biNr) {
                        return arr[i].SaddleColor;
                    }
                }
            }

            function _getNumberColor(arr, biNr) {
                if (!arr) {
                    // $log.warn("no data to search number color");
                    return;
                }
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i].BettingInterestNumber == biNr) {
                        return arr[i].NumberColor;
                    }
                }
            }

            function _setLowestOdd(arr) {
                _lowestOdd = Number.MAX_VALUE;
                var myOdd = Number.MAX_VALUE;

                //filter scratched horses for odd calculation
                var newArr = arr.length ? arr.filter(function (bettingInterest) {
                    var available = false;
                    var runners = bettingInterest.runners || bettingInterest.Runners;
                    _.each(runners, function (runner) {
                        var scratched = runner.scratched || runner.Scratched;
                        if (!scratched) {
                            available = true;
                        }
                    });
                    return available;
                }) : [];

                for (var i = 0; i < newArr.length; i++) {

                    myOdd = _getDecimalOddsValue(newArr[i].CurrentOdds || newArr[i].currentOdds);

                    if (myOdd < _lowestOdd) {
                        _lowestOdd = myOdd;
                    }

                }
            }

            function _getLowestOdd() {
                return _lowestOdd;
            }

            function _getDecimalOddsValue(obj) {
                if (obj) {
                    var denominator = obj.denominator;
                    var numerator = obj.numerator;
                    var decimal = denominator ? numerator / denominator : numerator;

                    if (isNaN(decimal)) {
                        return Number.MAX_VALUE;
                    }
                    return parseFloat(decimal);
                }
                else {
                    return Number.MAX_VALUE;
                }
            }

            function Runner(bettingInterestNumber, currentOdds, favorite, morningLineOdds, horseID, horseName, age, sex, weight, dam, sire, damSire, jockeyName, trainerName, ownerName, medication, scratched, dob) {

                var currentOddsNumerator;
                var currentOddsDenominator;
                var morningLineOddsNumerator;
                var morningLineOddsDenominator;

                if (currentOdds) {
                    currentOddsNumerator = currentOdds.hasOwnProperty('numerator') ? currentOdds.numerator : currentOdds.Numerator;
                    currentOddsDenominator = currentOdds.hasOwnProperty('denominator') ? currentOdds.denominator : currentOdds.Denominator;
                    morningLineOddsNumerator = morningLineOdds.hasOwnProperty('numerator') ? morningLineOdds.numerator : morningLineOdds.Numerator;
                    morningLineOddsDenominator = morningLineOdds.hasOwnProperty('denominator') ? morningLineOdds.denominator : morningLineOdds.Denominator;
                }

                this.bettingInterestNumber = bettingInterestNumber;
                this.currentOdds = currentOdds;
                this.currentOddsString = currentOddsNumerator != null ? currentOddsNumerator + (currentOddsDenominator ? "/" + currentOddsDenominator : "") : 'N/A';
                this.morningLineOdds = morningLineOdds;
                this.favorite = favorite;
                this.morningLineOddsString = morningLineOddsNumerator != null ? morningLineOddsNumerator + (morningLineOddsDenominator ? "/" + morningLineOddsDenominator : "") : 'N/A';
                this.horseID = horseID;
                this.horseName = horseName;
                this.age = age;
                this.sex = sex;
                this.weight = weight;
                this.dam = dam;
                this.sire = sire;
                this.damSire = damSire;
                this.jockeyName = jockeyName;
                this.trainerName = trainerName;
                this.ownerName = ownerName;
                this.medication = medication;
                this.scratched = scratched || false;
                this.dob = dob;
                this.oddsDecimalValue = _getDecimalOddsValue(this.currentOdds);
                this.saddleColor = _getSaddleColor(_biColors, this.bettingInterestNumber);
                this.numberColor = _getNumberColor(_biColors, this.bettingInterestNumber);
            }

            RunnersFac = {
                build: function (rawData) {

                    return new Runner(
                        rawData.biNumber || rawData.Number,
                        rawData.CurrentOdds,
                        rawData.Favorite,
                        rawData.MorningLineOdds,
                        rawData.Runners[0].runnerId || rawData.Runners[0].RunnerID,
                        rawData.Runners[0].horseName || rawData.Runners[0].HorseName,
                        rawData.Runners[0].age || rawData.Runners[0].Age,
                        rawData.Runners[0].sex || rawData.Runners[0].Sex,
                        rawData.Runners[0].weight || rawData.Runners[0].Weight,
                        rawData.Runners[0].dam || rawData.Runners[0].Dam,
                        rawData.Runners[0].sire || rawData.Runners[0].Sire,
                        rawData.Runners[0].damSire || rawData.Runners[0].DamSire,
                        rawData.Runners[0].jockey || rawData.Runners[0].JockeyName,
                        rawData.Runners[0].trainer || rawData.Runners[0].TrainerName,
                        rawData.Runners[0].ownerName || rawData.Runners[0].OwnerName,
                        rawData.Runners[0].med || rawData.Runners[0].Medication,
                        rawData.Runners[0].scratched || rawData.Runners[0].Scratched,
                        rawData.Runners[0].dob || rawData.Runners[0].dob
                    );
                },

                apiResponseTransformer: function (response, raceTypeId, graph) {

                    var data = response.data || response;
                    var parsedData;

                    _biColors = METADATA.biColors ? METADATA.biColors[raceTypeId] : [];

                    _setLowestOdd(data);

                    if (angular.isArray(data)) {
                        parsedData = [];
                        angular.forEach(data, function (item) {
                            var number = item.biNumber || item.Number;
                            var currentOdds = item.currentOdds || item.CurrentOdds;
                            var morningLineOdds = item.morningLineOdds || item.MorningLineOdds;
                            var runners = item.runners || item.Runners;
                            var favorite = item.favorite;

                            angular.forEach(runners, function (rawRunner) {
                                if (validHorseIdRegex.test(rawRunner.runnerId || rawRunner.RunnerID)) {
                                    var runner = RunnersFac.build({
                                        Number: number,
                                        CurrentOdds: currentOdds,
                                        MorningLineOdds: morningLineOdds,
                                        Runners: [rawRunner],
                                        Favorite: favorite
                                    });

                                    if (graph) {
                                        runner.numberColor = item.numberColor;
                                        runner.saddleColor = item.saddleColor;
                                    }

                                    parsedData.push(runner);
                                }
                            });
                        });
                        if (_isFavoriteBIScratched(parsedData)) {
                            angular.forEach(parsedData, function (runner) {
                                runner.favorite = _lowestOdd == _getDecimalOddsValue(runner.currentOdds) && _lowestOdd !== 99;
                            });
                        }
                        return parsedData;
                    }
                    if (data) {
                        parsedData = [];
                        var runners = data.runners || data.Runners;
                        angular.forEach(runners, function (rawRunner) {
                            var number = rawRunner.biNumber || data.Number;
                            var currentOdds = rawRunner.currentOdds || data.CurrentOdds;
                            var morningLineOdds = rawRunner.morningLineOdds || data.MorningLineOdds;
                            var favorite = rawRunner.favorite;

                            if (validHorseIdRegex.test(rawRunner.runnerId || rawRunner.RunnerID)) {
                                parsedData.push(RunnersFac.build({
                                    Number: number,
                                    CurrentOdds: currentOdds,
                                    MorningLineOdds: morningLineOdds,
                                    Runners: [rawRunner],
                                    Favorite: favorite
                                }));
                            }
                        });
                        if (_isFavoriteBIScratched(parsedData)) {
                            angular.forEach(parsedData, function (runner) {
                                runner.favorite = _lowestOdd == _getDecimalOddsValue(runner.currentOdds) && _lowestOdd !== 99;
                            });
                        }
                        return parsedData;
                    }
                    return {};
                }
            };

            function _getTimeToPost(postTime, currentTime) {
                var timeToPost = postTime - currentTime;
                timeToPost = timeToPost / 1000 / 60;
                if (timeToPost < 0) {
                    timeToPost = 0;
                }
                return Math.round(timeToPost);
            }

            function _getMTPClass(minutesToPost) {
                if (minutesToPost <= 5) {
                    return "MTP immediate";
                } else if (minutesToPost <= 15) {
                    return "MTP near";
                } else if (minutesToPost <= 60) {
                    return "MTP far";
                } else {
                    return null;
                }
            }

            function _getSurfaceType(surfaceId) {
                var surfaceName = METADATA.surfaceTypes && METADATA.surfaceTypes[surfaceId] ? METADATA.surfaceTypes[surfaceId].Name : null;

                if (!surfaceName) {
                    surfaceName = METADATA.surfaceTypes && METADATA.surfaceTypes[surfaceId] ? METADATA.surfaceTypes[surfaceId].Name : "";
                }
                surfaceName = surfaceName.toLowerCase();

                var colorClass = "gray";

                if (surfaceName.search("turf") >= 0) {
                    colorClass = "green";
                } else if (surfaceName.search("synthetic") >= 0) {
                    colorClass = "blue";
                } else if (surfaceName.search("dirt") >= 0) {
                    colorClass = "brown";
                }

                return colorClass;
            }

            function _getTimeDayDescription(raceDate) {
                var format = "YYYY-MM-DD";
                var raceDateDay = moment(raceDate).format(format);
                var tomorrow = moment().add(1, 'days').format(format);
                var yesterday = moment().subtract(1, 'days').format(format);
                var description = "";

                if (raceDateDay === moment().format(format)) {
                    description = "today";
                } else if (raceDateDay === yesterday) {
                    description = "ystrd";
                } else if (raceDateDay === tomorrow) {
                    description = "tmrrw";
                } else {
                    description = $filter('date')(raceDate, 'MMM dd');
                }

                return description;
            }

            function _filterResults(status) {

                return RaceStatusUtils.isStatusStewardsKey(status) ||
                    RaceStatusUtils.isStatusManuallyClosed(status) ||
                    RaceStatusUtils.isStatusRaceOfficial(status);
            }

            function _isFavoriteOdd(bettingInterest) {
                return _lowestOdd === _getDecimalOddsValue(bettingInterest.currentOdds) && _lowestOdd !== 99;
            }

            function _isFavoriteBIScratched(bettingInterests) {
                for (var i = 0; i < bettingInterests.length; i++) {
                    if (bettingInterests[i].favorite && !bettingInterests[i].scratched) {
                      return false;
                    }
                  }
                  return true;
            }

            function Race(postTime, raceDate, surfaceTypeId, raceTypeID, raceClassId, featured, raceStatus, trackAbbr, trackName, raceNumber, runners, distance, perfAbbr, status, bettingInterests, wagerTypes, results, fromResults, pools, probables, willPays, changes, purse, description, onTvg, onTvg2, hasReplays, showLiveVideo) {
                // Public properties, assigned to the instance ('this')
                this.postTime = TimeUtilFac.convertToDateBasedOnTimestamp(postTime);
                this.postTimeString = $filter('date')(this.postTime, 'hh:mm');
                this.postTimeMarker = $filter('date')(this.postTime, 'a');
                this.postTimeDateString = $filter('date')(this.postTime, 'MMM dd');
                this.postTimeDay = _getTimeDayDescription(this.postTime, _currentDate).toLocaleUpperCase();
                this.raceDate = new Date(TimeUtilFac.getTimestamp(raceDate));
                this.MTP = _getTimeToPost(this.postTime, _currentDate.getTime());
                this.MTPClass = _getMTPClass(this.MTP);
                this.near = this.MTPClass !== null;
                this.surfaceTypeId = surfaceTypeId;
                this.cenas = "cenas";
                this.raceTypeId = raceTypeID;
                this.surfaceType = _getSurfaceType(this.surfaceTypeId);
                this.surfaceName = METADATA.surfaceTypes && METADATA.surfaceTypes[this.surfaceTypeId] ? METADATA.surfaceTypes[this.surfaceTypeId].Name : "";
                this.raceClassId = raceClassId;
                this.raceClass = METADATA.raceClasses && METADATA.raceClasses[this.raceClassId] ? METADATA.raceClasses[this.raceClassId].Name : null;
                this.featured = featured;
                this.raceStatus = raceStatus || "";
                this.trackAbbr = trackAbbr || _currentRace.trackAbbr;
                this.trackName = trackName || _currentRace.trackName || (METADATA.allTracks && METADATA.allTracks[this.trackAbbr] ? METADATA.allTracks[this.trackAbbr].Name : this.trackAbbr);
                // RCN doesn't use the liveVideoSchedule which is dependent on Neulion so the liveStreaming is always true
                this.liveStreaming = $rootScope.activeFeatures.videoProviderRCN || (METADATA && METADATA.liveVideoSchedule && (METADATA.liveVideoSchedule[this.trackAbbr]) ? true : false);
                this.raceNumber = raceNumber;
                this.runners = runners;
                this.distance = distance;
                this.perfAbbr = perfAbbr || _currentRace.perfAbbr;
                this.status = status || "";
                this.breed = METADATA.raceTypes && METADATA.raceTypes[raceTypeID] ? METADATA.raceTypes[raceTypeID].Name : "";

                this.id = this.trackAbbr + '_' + this.raceNumber;

                // A race is closed for betting when its status is one of those "Race Official", "Stewards Key" or "Manually Closed"
                this.closedForBetting = RaceStatusUtils.isStatusStewardsKey(this.status) ||
                    RaceStatusUtils.isStatusManuallyClosed(this.status) ||
                    RaceStatusUtils.isStatusRaceOfficial(this.status);

                this.showLiveVideo = showLiveVideo;

                this.bettingInterests = bettingInterests ? RunnersFac.apiResponseTransformer(bettingInterests, this.raceTypeId) : [];

                this.wagerTypes = wagerTypes ? BetsSvc.apiResponseWagerTypesTransformer(wagerTypes) : [];

                this.results = results ? ResultsFac.apiResponseTransformer(results, this.raceTypeId) : {
                    payoffs: [],
                    runners: []
                };
                this.fromResults = fromResults;

                this.poolsPerBI = bettingInterests && pools ? PoolsFac.apiResponseTransformer(bettingInterests, this.raceTypeId, pools) : { runners: [] };

                //this.probables		  = ProbablesFac.apiResponseTransformer(probables, raceTypeID, pools); // Mock
                this.probables = (angular.isArray(probables) && probables.length > 0 && pools) ? ProbablesFac.apiResponseTransformer(probables, raceTypeID, pools) : [];

                this.willPays = willPays ? WillPaysFac.apiResponseTransformer(willPays, this.bettingInterests, this.raceTypeId) : [];

                if (this.distance) {
                    if (!this.distance.match(/[myf]/g)) {
                        this.distance += "m";
                    } else if (this.distance.match(/[m]/g)) {
                        this.distance += "ts";
                    }
                }

                this.changes = ChangesFac.apiResponseTransformer(changes);

                //tvg 4 data needed
                this.purse = purse;
                this.description = description;
                this.onTvg = onTvg;
                this.onTvg2 = onTvg2;

                this.hasReplays = hasReplays;
            }

            RacesFac = {
                build: function (rawData) {
                    return new Race(
                        rawData.PostTime,
                        rawData.RaceDate,
                        (rawData.SurfaceTypeId || rawData.SurfaceTypeID),
                        rawData.RaceTypeID,
                        (rawData.RaceClassId || rawData.ClassID),

                        rawData.Featured,
                        rawData.RaceStatus,

                        rawData.TrackAbbr,
                        rawData.TrackName,
                        (rawData.RaceNumber || rawData.Number),
                        (rawData.Runners || (rawData.BettingInterests ? rawData.BettingInterests.length : null)),

                        rawData.Distance,

                        rawData.PerfAbbr,

                        (rawData.RaceStatus || rawData.Status),
                        rawData.BettingInterests,
                        rawData.WagerTypes,
                        rawData.Results,
                        rawData.FromResults,
                        rawData.Pools,
                        rawData.Probables,
                        rawData.WillPays,
                        rawData.LateChanges,
                        rawData.Purse,
                        rawData.Description,
                        rawData.OnTvg,
                        rawData.OnTvg2,
                        rawData.HasReplay,
                        (!RaceStatusUtils.hasRaceResults((rawData.RaceStatus || rawData.Status)))
                    );
                },

                apiResponseTransformer: function (response, race) {
                    _currentDate = new Date();
                    _currentRace = race || {};

                    var data = response.data;
                    if (response.data && response.data.Races) {
                        data = response.data.Races;
                    }
                    if (angular.isArray(data)) {
                        return data
                            .map(RacesFac.build);
                    }
                    if (data) {
                        return RacesFac.build(data);
                    }
                    return [];
                },

                create: function (data) {
                    _currentDate = new Date();
                    _currentRace = {};
                    if (data) {
                        return RacesFac.build(data);
                    } else {
                        return {};
                    }
                }
            };


            //Info Fac - responsible for call api and get
            InfoSvc = {
                pollUpcomingRaces: function () {

                    upcomingPoller = poller.get(InfoSvc, {
                        action: 'getUpcomingRaces',
                        delay: 30000,
                        argumentsArray: [
                            {}
                        ]
                    });

                    return upcomingPoller;
                },

                stopPollingUpcomingRaces: function (deferred) {
                    if (upcomingPoller) {
                        upcomingPoller.removeDeferred(deferred);
                    }
                },

                /**
                 * Get upcoming races.
                 * Serves a cached response (if not expired), returns any
                 * pending request or creates a new request to fetch upcoming races.
                 *
                 * (must wait for session startup!)
                 *
                 * @return {Promise} Request
                 */
                getUpcomingRaces: function (date) {

                    if (!UserSessionSvc.hasSession()) {
                        return UserSessionSvc.callAfterSession(InfoSvc.getUpcomingRaces.bind(InfoSvc));
                    }
                    var wagerProfile = WagerProfileFac.getSessionOrAllTracksProfile();
                    var raceDate = Date.parse(date) ? date : METADATA.raceDate ? METADATA.raceDate : moment().format('YYYY-MM-DD');
                    var url = '/ajax/upcoming-races/id/upcomingList/date/' + raceDate + '/wp/' + wagerProfile;
                    if (wagerProfile != _prevWagerProfile) {
                        _lastUpData = null;
                    }

                    // save because of cache expiration calc
                    _prevWagerProfile = wagerProfile;

                    // should return a cached response?
                    if (_lastUpData && _lastUpData.data && _lastUpData.data.length) {
                        var parsedLastUpdate = RacesFac.apiResponseTransformer(_lastUpData);
                        var timeDifferenceToNow = Math.round((parsedLastUpdate[0].postTime.getTime() - Date.now()) / 1000 / 60),
                            deltaTimeStamp = Math.round((Date.now() - _lastUpTimestamp) / 1000 / 60);

                        if (timeDifferenceToNow < -2 || deltaTimeStamp > 1) {
                            _lastUpData = null;
                        } else {
                            var deferred = $q.defer();
                            deferred.resolve(parsedLastUpdate);
                            return deferred.promise;
                        }
                    }

                    // should return a pending request?
                    if (!upcomingResource) {
                        upcomingResource = $http.get(url).then(function (response) {
                            upcomingResource = null;
                            _lastUpData = response;
                            _lastUpTimestamp = Date.now();
                            if (response) {
                                return RacesFac.apiResponseTransformer(response).sort(function (a, b) {
                                    return a.postTime - b.postTime;
                                });
                            }
                        })
                            .then(null, function () {
                                upcomingResource = null;
                                _lastUpData = null;
                                _lastUpTimestamp = Date.now();
                                return [];
                            });
                    }
                    return upcomingResource;
                },

                pollRaceResultsList: function (data) {
                    var date = data || moment().format('YYYY-MM-DD');
                    resultsPoller = poller.get(InfoSvc, {
                        action: 'getRaceResultsList',
                        delay: 60000,
                        argumentsArray: [
                            { date: date }
                        ]
                    });

                    return resultsPoller;
                },

                stopPollingRaceResultsList: function (deferred) {
                    if (resultsPoller) {
                        resultsPoller.removeDeferred(deferred);
                    }
                },

                getRaceResultsList: function (data) {
                    var date = (data || {}).date || moment().format('YYYY-MM-DD');
                    var url = '/ajax/upcoming-races/id/trackSummary/date/' + date;
                    if (!resultsResource) {
                        resultsResource = $http.get(url).then(function (response) {
                            resultsResource = null;
                            var mergedData = [];
                            if (response.data && angular.isArray(response.data)) {
                                var responseLength = response.data.length;
                                for (var i = 0; i < responseLength; i++) {
                                    var track = response.data[i],
                                        trackRacesLength = track.TrackRaces.length;
                                    for (var j = 0; j < trackRacesLength; j++) {
                                        var race = track.TrackRaces[j];
                                        if (race.Status === null || _filterResults(race.Status)) {
                                            mergedData.push({
                                                PostTime: race.PostTime,
                                                RaceDate: race.RaceDate || race.PostTime,

                                                SurfaceTypeId: race.SurfaceTypeId,
                                                RaceTypeID: race.RaceTypeID,
                                                RaceClassId: race.RaceClassId,

                                                RaceStatus: race.Status,
                                                RaceNumber: race.Number,

                                                TrackAbbr: track.Abbreviation.trim(),
                                                TrackName: track.Name,
                                                PerfAbbr: track.PerfAbbr,
                                                FromResults: true,
                                                OnTvg: race.OnTVG,
                                                OnTvg2: race.OnTVG2
                                            });
                                        }
                                    }
                                }
                            }
                            return RacesFac.apiResponseTransformer({ data: mergedData }).sort(function (a, b) {
                                return b.postTime - a.postTime;
                            });

                        })
                            .then(null, function () {
                                resultsResource = null;
                                return [];
                            });
                    }
                    return resultsResource;
                },

                pollTrackCollection: function (race) {
                    trackCollectionPoller = poller.get(InfoSvc, {
                        action: 'getTrackCollection',
                        delay: 30000,
                        argumentsArray: [race]
                    });

                    return trackCollectionPoller;
                },

                stopPollingTrackCollection: function (deferred) {
                    if (trackCollectionPoller) {
                        trackCollectionPoller.removeDeferred(deferred);
                    }
                },

                getTrackCollection: function (race) {
                    var url = '/ajax/races/track/' + race.trackAbbr + '/performance/' + race.perfAbbr + '/get/collection';
                    var deferred = $q.defer();

                    if (typeof $rootScope.loadingTrack === 'undefined') {
                        $rootScope.loadingTrack = true;
                    }

                    $http.get(url).then(function (response) {
                        var trackData = RacesFac.apiResponseTransformer(response, race);
                        deferred.resolve(trackData);
                        $rootScope.loadingTrack = false;
                        /* TODO: onTvg Flag
                         The request from url is not returning the onTvg flag, so we need to populate it from allowedRaces.
                         On the future, we need to change the request to populate this field. */
                        if (trackData && trackData.length > 0 && $rootScope.allowedRaces && $rootScope.allowedRaces.length > 0) {
                            trackData.filter(function (trackRace) {
                                $rootScope.allowedRaces.filter(function (aRace) {
                                    if (trackRace.id == aRace.id) {
                                        trackRace.onTvg = aRace.onTvg;
                                        trackRace.onTvg2 = aRace.onTvg2;
                                        return;
                                    }
                                });
                            });
                        }
                        $rootScope.$emit('newTrackData', race.trackAbbr, trackData);
                    }, function (e) {
                        deferred.reject(e);
                    });

                    return deferred.promise;
                },
                getRaceInfo: function (race) {
                    return this.getTrackCollection(race).then(function (races) {
                        if (races) {
                            var currentRace = {}, racesLength = races.length;
                            for (var i = 0; i < racesLength; i++) {
                                if (races[i].id === race.id) {
                                    currentRace = races[i];
                                    break;
                                }
                            }
                            return currentRace;
                        }
                    })
                        .then(null, function (e) {
                            $q.reject(e);
                        });
                },

                getTalentPicks: function (raceId) {
                    var deferred = $q.defer();

                    TalentPicksSvc.getTalentPicks().then(function (data) {
                        var talentPicks = [];

                        data.forEach(function (talentPick) {
                            if (raceId === talentPick.id) {
                                talentPicks.push(talentPick);
                            }
                        });

                        deferred.resolve(talentPicks);
                    });

                    return deferred.promise;
                },

                getRaceHandicappingInfo: function (race) {
                    var url = '/ajax/races/track/' + race.trackAbbr + '/performance/' + race.perfAbbr + '/get/handicapping/id/' + race.raceNumber;
                    var deferred = $q.defer();

                    //This request needs an authenticated user
                    if ($rootScope.user) {
                        $http.get(url).then(function (response) {
                            deferred.resolve(HandicappingInfoFac.apiResponseTransformer(response, race));
                        }, function (e) {
                            deferred.reject(e);
                        });
                    } else {
                        deferred.reject({});
                    }

                    return deferred.promise;
                },

                groupRacesByTrack: function (races) {
                    var byTrack = {}, groups = [], racesLength = races.length;

                    for (var i = 0; i < racesLength; i++) {
                        var race = races[i];
                        var currentTrack = byTrack[race.trackAbbr];
                        if (!currentTrack) {
                            currentTrack = byTrack[race.trackAbbr] = {
                                trackName: race.trackName,
                                trackAbbr: race.trackAbbr,
                                liveStreaming: false,
                                races: []
                            };
                        }
                        if (race.liveStreaming) {
                            currentTrack.liveStreaming = true;
                        }
                        currentTrack.races.push(race);
                    }

                    for (var trackName in byTrack) {
                        groups.push({
                            liveStreaming: byTrack[trackName].liveStreaming,
                            trackName: byTrack[trackName].trackName,
                            trackAbbr: byTrack[trackName].trackAbbr,
                            races: byTrack[trackName].races
                        });
                    }

                    return groups;
                },

                // get tracks with different trackName
                searchForUniqueTracksInRaceList: function (races, trackList) {
                    _.forEach(_.uniqBy(races, function (race) {
                        return race.trackName;
                    }), function (race) {
                        trackList.push({
                            id: trackList.length, name: race.trackName,
                            trackAbbr: race.trackAbbr, perfAbbr: race.perfAbbr
                        });
                    });
                },

                resetTracks: function () {
                    return [
                        { id: 0, name: "All Tracks" }
                    ];
                },

                racesFilters: function (races, trackSelected, timeSelected, tracks, liveStreamingCheck, OnTvgCheck, grouped) {

                    var filteredRaces = races;
                    if (filteredRaces) {

                        // filter by hours
                        if (timeSelected.id == 1) {
                            var time = new Date();
                            time.setTime(time.getTime() + 2 * 60 * 60 * 1000); // next 2 hours
                            if (grouped) {
                                var filteredGroupRaces = [];

                                _.forEach(filteredRaces, function (data) {
                                    if (data.races.length) {
                                        var groupRaces = data.races.filter(function (race) {
                                            return race.postTime.getTime() < time.getTime();
                                        });
                                        if (groupRaces.length) {
                                            var track = data;
                                            track.races = groupRaces;
                                            filteredGroupRaces.push(track);
                                        }
                                    }
                                });
                                filteredRaces = filteredGroupRaces;
                            } else {
                                filteredRaces = filteredRaces.filter(function (race) {
                                    return race.postTime.getTime() < time.getTime();
                                });
                            }
                        }

                        // filter trackList
                        var oldTrackSelection = trackSelected;
                        var newTracks = this.resetTracks();
                        this.searchForUniqueTracksInRaceList(filteredRaces, newTracks);
                        var findOldTrack = _.find(newTracks, function (track) {
                            return track.name == oldTrackSelection.name;
                        });

                        tracks = newTracks;
                        if (findOldTrack) {
                            trackSelected = findOldTrack;
                        } else {
                            trackSelected = tracks[0];
                        }

                        // filter by track
                        if (trackSelected.id != 0) {
                            filteredRaces = filteredRaces.filter(function (race) {
                                return race.trackName == trackSelected.name;
                            });
                        }

                        // filter by liveStream and onTvg
                        filteredRaces = filteredRaces.filter(function (race) {
                            if (liveStreamingCheck && OnTvgCheck) {
                                return (race.liveStreaming || race.onTvg);
                            } else if (liveStreamingCheck) {
                                return race.liveStreaming;
                            } else if (OnTvgCheck) {
                                return race.onTvg;
                            } else {
                                return true;
                            }
                        });

                        // return filtered
                        return { races: filteredRaces, tracks: tracks, trackSelected: trackSelected };
                    }
                }
            };

            return {
                pollUpcomingRaces: InfoSvc.pollUpcomingRaces,
                stopPollingUpcomingRaces: InfoSvc.stopPollingUpcomingRaces,
                getUpcomingRaces: InfoSvc.getUpcomingRaces,
                pollRaceResultsList: InfoSvc.pollRaceResultsList,
                stopPollingRaceResultsList: InfoSvc.stopPollingRaceResultsList,
                getRaceResultsList: InfoSvc.getRaceResultsList, // For test purposes
                pollTrackCollection: InfoSvc.pollTrackCollection,
                stopPollingTrackCollection: InfoSvc.stopPollingTrackCollection,
                getTrackCollection: InfoSvc.getTrackCollection,
                getRaceInfo: InfoSvc.getRaceInfo,
                getRaceHandicappingInfo: InfoSvc.getRaceHandicappingInfo,
                groupRacesByTrack: InfoSvc.groupRacesByTrack,
                createRace: RacesFac.create,
                searchForUniqueTracksInRaceList: InfoSvc.searchForUniqueTracksInRaceList,
                resetTracks: InfoSvc.resetTracks,
                racesFilters: InfoSvc.racesFilters,
                buildRaceResultsTitle: ResultsFac.buildRaceResultsTitle,
                getTalentPicks: InfoSvc.getTalentPicks,
                setLowestOdd: _setLowestOdd,
                getLowestOdd: _getLowestOdd,
                isFavoriteBIScratched: _isFavoriteBIScratched,
                isFavoriteOdd: _isFavoriteOdd,
                getSaddleColor: _getSaddleColor,
                getNumberColor: _getNumberColor,
                getSaddleLayout: _getSaddleLayout,
                getTimeDayDescription: _getTimeDayDescription,
                getTimeToPost: _getTimeToPost,
                getMTPClass: _getMTPClass,
                getSurfaceType: _getSurfaceType,
                getDecimalOddsValue: _getDecimalOddsValue,
                changesBuilder: ChangesFac.changesBuilder,
                runnersBuilder: RunnersFac.apiResponseTransformer,
                handicappingInfoBuilder: HandicappingInfoFac.handicappingBuilder,
                poolsBuilder: PoolsFac.apiResponseTransformer,
                probablesBuilder: ProbablesFac.probablesBuilder,
                willPaysBuilder: WillPaysFac.apiResponseTransformer,
                resultsBuilder: ResultsFac.apiResponseTransformer
            };
        }

        RaceInfoFac.$inject = [
            '$rootScope',
            'poller',
            'METADATA',
            '$http',
            '$q',
            'BetsSvc',
            '$filter',
            'TalentPicksSvc',
            'TimeUtilFac',
            'UserSessionSvc',
            'WagerProfileFac'
        ];

        return RaceInfoFac;

    });

