'use strict';

define('MyStableRaceTodayCtrl',[], function() {
  function MyStableRaceTodayCtrl(MyStableSvc) {
    var vm = this;

    vm.data = {
        racesAvailable: false,
        raceTodayEntities: []
    };

    function _fillRaceTodayEntities() {
        MyStableSvc.getRaceTodayEntities().then(function(raceTodayEntities) {
            vm.data.raceTodayEntities = raceTodayEntities;
            vm.data.racesAvailable = raceTodayEntities.length > 0 ? true : false;
        }, function() {
            vm.data.raceTodayEntities = [];
            vm.data.racesAvailable = false;
        });
    }

    (function init() {
        _fillRaceTodayEntities();
    }());

  }

  MyStableRaceTodayCtrl.$inject = [
    'MyStableSvc'
  ];

  return MyStableRaceTodayCtrl;
});

