"use strict";

define('RaceReplaysSearchByTrackCtrl',["DateComparators"], function(DateComparators) {
    function RaceReplaysSearchByTrackCtrl(
        $scope,
        TrackListFromMetadataFac,
        RaceReplaysGateway,
        HandicappingRaceReplaysFac,
        $timeout,
        $filter,
        $rootScope,
        GraphPastTracks,
        GraphPastRaces,
        WagerProfileFac
    ) {
        var vm = this;
        vm.model = $scope.model;
        vm.model.loading = true;
        vm.init = true;

        $scope.$watch(
            "RaceReplaysSearchByTrack.model.dateSelected",
            function() {
                if (vm.init) {
                    vm.init = false;
                } else {
                    _changeDay();
                }
            }
        );

        // initialize track dates
        if (vm.model.tabSelected === "Track") {
            vm.model.dateSelected = null;
            vm.model.disableDatePicker = true;
            _setTrackslist();
        }

        function _setTrackslist() {
            if ($rootScope.activeFeatures.enableGraphRaceReplaysByTrack) {
                GraphPastTracks.getAllTracks(WagerProfileFac.getSessionOrAllTracksProfile(), {isGreyhound:false})
                    .then(function(response) {
                        var pastTracks = [];
                        if (Array.isArray(response) && response.length > 0) {
                            response.forEach(function(track) {
                                pastTracks.push({
                                    trackAbbr: track.code,
                                    name: track.name
                                });
                            });
                        }

                        vm.model.trackList = pastTracks;
                        vm.model.trackSelected = vm.model.trackList[0];
                        _retrieveTrackRaceDates(vm.model.trackSelected);
                    }, function() {
                        vm.model.trackList = [];
                        vm.model.trackSelected = {
                            name: "No tracks"
                        };
                        vm.model.loading = false;
                    })
                    .then(function() {
                        $timeout(function() {
                            $rootScope.$apply();
                        });
                    });
            } else {
                vm.model.trackList = TrackListFromMetadataFac.generateMetadataTrackList();
                vm.model.trackSelected = vm.model.trackList[0];
                _retrieveTrackRaceDates(vm.model.trackSelected);
            }
        }

        function _retrieveTrackRaceDates(track) {
            vm.model.loading = true;
            vm.model.infoMessage = "";
            vm.model.errorMessage = "";
            vm.model.selectedReplay = {};

            if ($rootScope.activeFeatures.enableGraphRaceReplaysByTrack) {
                GraphPastTracks.getTrackDatesByTrackCode(
                    WagerProfileFac.getSessionOrAllTracksProfile(),
                    track.trackAbbr,
                    {isGreyhound:false}
                )
                    .then(function(response) {
                        var dateList = [];

                        if (
                            Array.isArray(response) &&
                            response.length > 0 &&
                            response[0].dates.length > 0
                        ) {
                            response[0].dates.forEach(function(dateString) {
                                dateList.push(new Date(dateString));
                            });
                        }

                        _getTrackReplaysDatesSuccess(dateList);
                    }, _getTrackReplaysDatesFail)
                    .then(function() {
                        $timeout(function() {
                            $rootScope.$apply();
                        });
                    });
            } else {
                RaceReplaysGateway.getTrackReplaysDates(track.trackAbbr).then(
                    _getTrackReplaysDatesSuccess,
                    _getTrackReplaysDatesFail
                );
            }
        }

        function _getTrackReplaysDatesSuccess(listOfDates) {
            vm.model.infoMessage = "";
            vm.model.errorMessage = "";
            _resetTable();

            vm.model.raceReplaysTrackDates = listOfDates;
            if (vm.model.raceReplaysTrackDates.length > 0) {
                vm.model.dateSelected = vm.model.raceReplaysTrackDates[0];
                _getTrackReplaysListByDate(
                    vm.model.trackSelected.trackAbbr,
                    vm.model.dateSelected
                );
                vm.model.disableDatePicker = false;
            } else {
                vm.model.infoMessage = $filter("CMSLabels")(
                    "noReplaysForTheTrack",
                    "handicappingRaceReplaysLabels"
                );
                vm.model.loading = false;
                vm.model.disableDatePicker = true;
                vm.model.dateSelected = null;
            }
        }
        function _getTrackReplaysDatesFail() {
            vm.model.infoMessage = "";
            vm.model.loading = false;
            _resetTable();
            vm.model.errorMessage = $filter("CMSLabels")(
                "failTrackDates",
                "handicappingRaceReplaysLabels"
            );
        }

        function _getTrackReplaysListByDateOnGraph(trackCode, raceDate) {
            var wagerProfile = WagerProfileFac.getSessionOrGenericProfile();

            GraphPastRaces.getRacesByDateAndTrack(
                wagerProfile,
                raceDate,
                trackCode,
                {isGreyhound: false}
            )
                .then(function(response) {
                    var pastRaces = [];

                    if (Array.isArray(response) && response.length > 0) {
                        response.forEach(function(race) {
                            pastRaces.push({
                                distance:
                                    race.distanceValue.toFixed(1) +
                                    race.distanceCode.toLowerCase(),
                                postTime: race.postTime,
                                raceClass: race.className,
                                raceDate: race.date,
                                raceNumber: race.raceNumber,
                                raceTypeId: race.typeId,
                                raceTypeName: race.typeName,
                                trackAbbr: race.trackCode,
                                trackName: race.trackName,
                                providerTrack: race.trackCode,
                                replayFileName: race.video && race.video.replayFileName
                            });
                        });
                    }

                    _getTrackReplaysByDateSuccess(pastRaces);
                }, _getTrackReplaysByDateFail)
                .then(function() {
                    $timeout(function() {
                        $rootScope.$apply();
                    });
                });
        }

        function _getTrackReplaysListByDate(trackAbbr, raceDate) {
            vm.model.loading = true;
            var raceDateToAsk = $filter("date")(raceDate, "yyyy-MM-dd");

            return $rootScope.activeFeatures.enableGraphRaceReplaysByTrack
                ? _getTrackReplaysListByDateOnGraph(trackAbbr, raceDateToAsk)
                : RaceReplaysGateway.getRacesReplaysByTrackAndDate(
                      trackAbbr,
                      raceDateToAsk
                  ).then(
                      _getTrackReplaysByDateSuccess,
                      _getTrackReplaysByDateFail
                  );
        }

        function _getTrackReplaysByDateSuccess(replaysList) {
            vm.model.infoMessage = "";
            vm.model.errorMessage = "";
            _resetTable();

            vm.model.searchToShow = vm.model.trackSelected.name;
            if (_.isArray(replaysList) && replaysList.length) {
                vm.model.dataSource = $filter("orderBy")(replaysList, [
                    "-postTime"
                ]);
                vm.model.selectedReplay = vm.model.dataSource[0];
                vm.model.raceReplay = HandicappingRaceReplaysFac.buildResultReplay(
                    vm.model.selectedReplay
                );
            } else {
                vm.model.infoMessage = $filter("CMSLabels")(
                    "noReplaysForDate",
                    "handicappingRaceReplaysLabels"
                );
            }
            vm.model.loading = false;
        }

        function _getTrackReplaysByDateFail() {
            vm.model.infoMessage = "";
            _resetTable();
            vm.model.errorMessage = $filter("CMSLabels")(
                "failTrackReplayList",
                "handicappingRaceReplaysLabels"
            );
        }

        function _resetTable() {
            vm.model.dataSource = [];
            vm.model.loading = false;
        }

        function _changeTrack(track) {
            vm.model.trackSelected = track;
            _retrieveTrackRaceDates(vm.model.trackSelected);
        }

        function _filterTrackRaceDates(data) {
            var date = data.date;
            var mode = data.mode;
            if (date && mode === "day") {
                for (
                    var i = 0;
                    i < vm.model.raceReplaysTrackDates.length;
                    i++
                ) {
                    if (
                        DateComparators.isSameDate(
                            date,
                            vm.model.raceReplaysTrackDates[i]
                        )
                    ) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        }

        function _hideErrorTooltip() {
            angular
                .element(document)
                .find("tvg-datepicker")
                .trigger("hideTooltip");
        }

        function _showErrorTooltip() {
            angular
                .element(document)
                .find("tvg-datepicker")
                .trigger("showTooltip");
        }

        function _changeDay() {
            $timeout(_hideErrorTooltip, 0);
            if (vm.model.dateSelected) {
                var dataModel = {
                    date: vm.model.dateSelected,
                    mode: "day"
                };
                if (!_filterTrackRaceDates(dataModel)) {
                    _getTrackReplaysListByDate(
                        vm.model.trackSelected.trackAbbr,
                        vm.model.dateSelected
                    );
                } else {
                    vm.tooltipInfo = $filter("CMSLabels")(
                        "noReplaysForDate",
                        "handicappingRaceReplaysLabels"
                    );
                    $timeout(_showErrorTooltip, 0);
                    _resetTable();
                }
                // verify if we are on track search tab and track as replays
            } else if (vm.model.tabSelected === "Track") {
                if (vm.model.raceReplaysTrackDates.length) {
                    vm.tooltipInfo = $filter("CMSLabels")(
                        "invalidDate",
                        "handicappingRaceReplaysLabels"
                    );
                    $timeout(_showErrorTooltip, 0);
                }
                _resetTable();
            }
        }

        vm.events = {
            changeDay: _changeDay,
            changeTrack: _changeTrack,
            filterTrackRaceDates: _filterTrackRaceDates
        };
    }

    RaceReplaysSearchByTrackCtrl.$inject = [
        "$scope",
        "TrackListFromMetadataFac",
        "RaceReplaysGateway",
        "HandicappingRaceReplaysFac",
        "$timeout",
        "$filter",
        "$rootScope",
        "GraphPastTracks",
        "GraphPastRaces",
        "WagerProfileFac"
    ];

    return RaceReplaysSearchByTrackCtrl;
});

