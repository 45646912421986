'use strict';

define(
    'SeoPageTitleDirective',[],function () {
        function SeoPageTitleDir() {

            return {
                restrict: 'A',
                scope: {
                },
                link: function(scope, elem){

                    function _setContent(value){
                        elem.html(value);
                    }

                    scope.$on('seo:page-meta-info:updated', function(event, newMetaInfo){
                        _setContent(newMetaInfo.title);
                    });
                }
            };
        }

        SeoPageTitleDir.$inject = [
        ];

        return SeoPageTitleDir;
    }
);


