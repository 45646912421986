'use strict';

define('StepSelectionsStringFilter',[],function() {
    var DEFAULT_SEPARATOR = ',';
    var CONSECUTIVE_SELECTIONS_SEPARATOR = '-';
    var consecutiveSelections;
    var currentSelection;

    function _getStringSeparator(currentSelection) {
        return currentSelection.length === 2 && currentSelection[1] - currentSelection[0] > 1 ?
            CONSECUTIVE_SELECTIONS_SEPARATOR : DEFAULT_SEPARATOR;
    }

    function _pushCurrentSelectionToConsecutiveSelections() {
        consecutiveSelections.push(
            currentSelection.join(
                _getStringSeparator(currentSelection)));
    }

    function _forEachSelectionCallback(selection) {
        if (currentSelection.length) {
            if (selection - currentSelection[currentSelection.length - 1] === 1) {
                if (currentSelection.length === 2) {
                    currentSelection[1] = selection;
                } else {
                    currentSelection.push(selection);
                }
            } else {
                _pushCurrentSelectionToConsecutiveSelections();
                currentSelection = [selection];
            }
        } else {
            currentSelection.push(selection);
        }
    }

    function _stepSelectionsStringFilter(selections) {
        // Reset state
        consecutiveSelections = [];
        currentSelection = [];

        if (!Array.isArray(selections)) {
            return selections;
        }

        selections = selections.sort(function(a, b) {
            return a - b;
        });

        selections.forEach(_forEachSelectionCallback);

        _pushCurrentSelectionToConsecutiveSelections();

        return consecutiveSelections.join(DEFAULT_SEPARATOR);
    }

    return function() {
        return _stepSelectionsStringFilter;
    };
});

