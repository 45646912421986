'use strict';

define('SecuritySvc',['lodash', 'Mediator'], function (_, mediator) {

    /**
     * Security service
     * Handles session authentication and user account details management
     *
     * Some of the methods that need to connect to the backend may use different
     * versions of the API/services, according to the feature flags
     * "gkeLogin" and "gkeSession".
     */
    function SecuritySvc(
            $http,
            $q,
            $rootScope,
            $timeout,
            $filter,
            $window,
            $location,
            $httpParamSerializerJQLike,
            CookieFac,
            GTMFac,
            GeoComplyLoginSvc,
            ConfigurationFac,
            FavoriteTracksFac,
            RequestContextHeaders,
            PreferencesCookieReloadedFac
        ) {
        var Security = {

            /**
             * Extract login data from a login done in other app
             *
             * @param  {Object} user    user data
             *
             * @return {boolean}        Login result
             */
            externalLogin: function(user) {

                if(_parseSuccessResponse(
                    _toLegacyRestoreSessionSuccessResponse(user)
                    )
                ) {
                    // true is passed as an argument because the function is expecting it to return back
                    _loadFavoriteTracks(true).then(function() {
                        $rootScope.$broadcast('newFavTrack');
                    });

                    PreferencesCookieReloadedFac.getPreferences();

                    _emitGlobalLoginEvent();

                    return true;
                } else {
                    return false;
                }
            },

            /**
             * Execute a logout done in other app
             *
             * @param  {Object} user    user data
             *
             * @return {Promise}        Login promise
             */
            externalLogout: function() {
                var goHome = false;

                if ($rootScope.user) {
                    goHome = true;
                }

                $rootScope.user = null;
                $rootScope.userSession = false;

                $timeout(function () {
                    FavoriteTracksFac.clearFavoriteList();

                    _emitGlobalLogoutEvent();
                    _stopLogoutLoader();

                    if (goHome) {
                        $location.path('/').search('');
                    }

                }, 100);
            },

            /**
             * Login user using one of the following methods:
             * - new login, using 'usa' microservices
             * - new geolocation login, using 'usa' microservices
             * - old login, using tvg3 php services
             *
             * @param  {Object} params               Login credentials
             * @param  {String} params.loginType     Login type ['pin', 'email']
             * @param  {String} params.stateAbbr     US state abbreviation
             * @param  {String} params.accountField  User's account number or email
             * @param  {String} params.pinField      User's account pin or password
             *
             * @return {Promise}
             */
            login: function (params) {

                // @TODO include 4njbets logic in new _login()
                if (_isNjBets() && _useGeoComply()) {
                    return GeoComplyLoginSvc.login(params).then(_getSessionCookies);
                }

                if (_isGkeLogin()) {
                    return _login(params);
                } else {
                    return _legacyLogin(params);
                }
            },

            /**
             * Logout user using one of the following methods:
             * - new login, using 'usa' microservices
             * - new geolocation login, using 'usa' microservices
             * - old login, using tvg3 php services
             *
             * @return {Promise}
             */
            logout: function (param) {
                param = (typeof param !== 'undefined') ?  param : '';

                // @TODO include 4njbets logic in new _login()
                if (_isNjBets()) {
                    return GeoComplyLoginSvc.logout();
                }

                if (_isGkeLogout()) {
                    return _logout(param);
                } else {
                    return _legacyLogout();
                }
            },

            /**
             * Validate user session only.
             * This method does not change the global user object.
             * (the cookie "tvg3token" must be present in the request)
             *
             * @return {Promise} Promised request
             */
            validateSession: function () {

                if (_isGkeSession()) {
                    return _validateSession();
                } else {
                    return _legacyValidateSession();
                }
            },

            /***
             * Validate user session and set the global user object based on response
             * Was setUserProfileFromSession() before.
             * (the cookie "tvg3token" must be present in the request)
             *
             * @returns {Promise}  Promised request
             */
            restoreSession: function () {

                if (_isGkeSession()) {
                    return _restoreSession();
                } else {
                    return _legacyRestoreSession();
                }
            },

            /**
             * Get user validation endpoint to use in other modules
             * @return {String}  Session validation endpoint URL
             */
            getValidateSessionUrl: getValidateSessionUrl,

            changePin: function (params) {
                var deferred = $q.defer();
                var ret = null;

                var paramsAux = angular.copy(params);
                params = {};
                params.pin = paramsAux.old_pin;
                params.newPin = paramsAux.new_pin;

                $http(_getAccountConfig(params, true, 'change_pin')).success(function (response) {
                    response = {};
                    response.status = 'success';
                    ret = _handlePinUpdateResponse(response);

                    if ($rootScope.user && $window.clientApp === 'touchclient') {
                        var p = {};
                        p.gaEventCategory = 'Account';
                        p.gaEventAction = GTMFac.isTVG4() ? 'Profile' : 'Preferences';
                        p.gaEventLabel = 'Change Pin Number';
                        p.sectionName = GTMFac.isTVG4() ? 'My Account|Profile' : 'My Account';

                        GTMFac.GTMEvent().send($rootScope, 'changePin', p);
                    }
                    deferred.resolve(ret);
                }).error(function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            },

            changePassword: function (params) {
                var deferred = $q.defer();
                var ret = null;

                var paramsAux = angular.copy(params);
                params = {};
                params.userName = $rootScope.user.userName;
                params.password = paramsAux.old_password;
                params.newPassword = paramsAux.new_password;

                $http(_getAccountConfig(params, true, 'change_password')).success(function (response) {
                    response = {};
                    response.status = 'success';
                    ret = _handlePasswordUpdateResponse(response);

                    if ($rootScope.user) {
                        var p = {};
                        p.gaEventCategory = 'Account';
                        p.gaEventAction = GTMFac.isTVG4() ? 'Profile' : 'Preferences';
                        p.gaEventLabel = 'Change Password Number';
                        p.sectionName = GTMFac.isTVG4() ? 'My Account|Profile' : 'My Account';

                        GTMFac.GTMEvent().send($rootScope, 'changePassword', p);
                    }
                    deferred.resolve(ret);
                }).error(function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            },

            changeEmail: function (params) {
                var deferred = $q.defer();
                var ret = null;

                var paramsAux = angular.copy(params);
                params = {};
                params.emailAddress = paramsAux.email;

                $http(_getAccountConfig(params, true, 'change_email')).success(function (response) {
                    response = {};
                    response.status = 'success';
                    ret = _handleEmailUpdateResponse(response);

                    if ($rootScope.user) {
                        $rootScope.user.emailAddress = params.emailAddress;
                        _broadcastUser();

                        var p = {};
                        p.gaEventCategory = 'Account';
                        p.gaEventAction = GTMFac.isTVG4() ? 'Profile' : 'Preferences';
                        p.gaEventLabel = 'Change Email';
                        p.sectionName = GTMFac.isTVG4() ? 'My Account|Profile' : 'My Account';

                        GTMFac.GTMEvent().send($rootScope, 'changeEmail', p);
                    }
                    deferred.resolve(ret);
                }).error(function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            },

            addUsername: function (params) {
                var deferred = $q.defer();
                var ret = null;

                var paramsAux = angular.copy(params);
                params = {};
                params.userName = paramsAux.username;
                params.password = paramsAux.password;

                $http(_getAccountConfig(params, true, 'add_username')).success(function (response) {
                    response = {};
                    response.status = 'success';
                    ret = _handleAddUsernameResponse(response);
                    $rootScope.user.userName = params.userName;
                    _broadcastUser();
                    deferred.resolve(ret);
                }).error(function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            },

            changeUsername: function (params) {
                var deferred = $q.defer();
                var ret = null;

                var paramsAux = angular.copy(params);
                params = {};
                params.userName = paramsAux.old_username;
                params.newUsername = paramsAux.username;
                params.password = paramsAux.password;


                $http(_getAccountConfig(params, true, 'change_username')).success(function (response) {

                    response = {};
                    response.status = 'success';

                    ret = _handleChangeUsernameResponse(response);
                     $rootScope.user.userName = params.newUsername;
                    _broadcastUser();
                     deferred.resolve(ret);
                }).error(function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            },

            checkUsername: function (username, isEmail) {
                var deferred = $q.defer();
                var serviceUrl = ConfigurationFac.getServiceApiUrl('uam');
                $http.get(serviceUrl + '/registration/emails/validation?email=' + username).success(function (response) {
                    var valid = true;
                    var exists = false;

                    var invalidEmailError = _.includes(response.errors, 'invalid_format_email');
                    var registeredEmailError = _.includes(response.errors, 'already_registered_email');
                    var registeredUsernameError = _.includes(response.errors, 'already_registered_username');

                    if(isEmail) {
                        valid = !invalidEmailError;
                        exists = registeredEmailError;
                    } else {
                        exists = registeredUsernameError;
                    }

                    deferred.resolve({
                        valid: valid,
                        exists: exists
                    });
                }).error(function () {
                    // The error type doesn't matter too much because the app will try to add the user anyway
                    var errorResponse = {
                        status: 'error'
                    };
                    deferred.reject(errorResponse);
                });

                return deferred.promise;
            },

            apiResponseTransformer: function (rawData) {
                rawData = rawData || {};

                return {
                    accountNumber: rawData.AccountNumber,
                    firstName: rawData.FirstName,
                    lastName: rawData.LastName,
                    phoneNumber: rawData.PrimaryPhone,
                    userName: rawData.userName,
                    wagerStatus: rawData.WagerStatus,
                    homeAddress: rawData.HomeAddress,
                    mailingAddress: rawData.MailingAddress,
                    emailAddress: rawData.EmailAddress,
                    signalProviderId: rawData.SignalProviderId,
                    signalProviderNeedsUpdate: rawData.SignalProviderNeedsUpdate,
                    transportId: rawData.TransportId
                };
            }
        };


        function _broadcastUser() {
            CookieFac.setCookie('hasLoginOnce', true, {path: '/', expires: 365});

            $timeout(function () {
                //Timer Needed to insure that the pref cookie has been
                //stored by the browser.
                $rootScope.$broadcast('user', $rootScope.user);

                mediator.dispatch('UPDATE_SESSION', $rootScope.user);
            }, 100);
        }

        function _broadcastInvalidSession() {
            $rootScope.$broadcast('invalidSession');
        }

        function _broadcastGTMUserLogout() {
            $rootScope.$broadcast('GTMUserLoggedOut');
        }


        function _getAccountConfig(params, useNewUamEndpoints, fieldToUpdate) {

            if(useNewUamEndpoints) {
                switch (fieldToUpdate) {
                    case 'change_email':
                        return _changeEmailRequest(params);
                    case 'change_pin':
                        return _changePinRequest(params);
                    case 'change_password':
                        return _changePasswordRequest(params);
                    case 'change_username':
                        return _changeUsernameRequest(params);
                    case 'add_username':
                        return _addUsernameRequest(params);
                    default:
                        break;
                }

            } else {
                return {
                    method: 'POST',
                    url: '/ajax/accounts/',
                    data: $httpParamSerializerJQLike(params),
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded'
                    }
                };
            }

        }

        /*
         Request to the new UAM endpoint to change the EMAIL
         SHOULD BE CHANGED WHEN SINGLE BUTTON IS ADDED TO PROFILE EDITION

         params {
            emailAddress: "email@example.com"
         }
         */
        function _changeEmailRequest(params) {
            return {
                method: 'PUT',
                url: _getServiceUrl('/users/' + $rootScope.user.accountNumber + '/profile/email', 'uam'),
                data: params,
                headers: _generateServiceHeaders()
            };
        }

        /*
         Request to the new UAM endpoint to change the PIN
         SHOULD BE CHANGED WHEN SINGLE BUTTON IS ADDED TO PROFILE EDITION

         params {
             username: "username",
             pin: "1111",
             newPin: "2222"
         }
         */
        function _changePinRequest(params) {
            return {
                method: 'PUT',
                url: _getServiceUrl('/users/' + $rootScope.user.accountNumber + '/profile/pin', 'uam'),
                data: params,
                headers: _generateServiceHeaders()
            };
        }

        /*
         Request to the new UAM endpoint to change the PASSWORD
         SHOULD BE CHANGED WHEN SINGLE BUTTON IS ADDED TO PROFILE EDITION

         params {
             userName: "username",
             password: "oldpass1234",
             newPassword: "newpass1234"
         }
         */
        function _changePasswordRequest(params) {
            return {
                method: 'PUT',
                url: _getServiceUrl('/users/' + $rootScope.user.accountNumber + '/profile/password', 'uam'),
                data: params,
                headers: _generateServiceHeaders()
            };
        }

        /*
         Request to the new UAM endpoint to change the USERNAME
         SHOULD BE CHANGED WHEN SINGLE BUTTON IS ADDED TO PROFILE EDITION

         params {
             username: "username123",
             newUsername: "username1234",
             password: "pass1234"
         }
         */
        function _changeUsernameRequest(params) {
            return {
                method: 'PUT',
                url: _getServiceUrl('/users/' + $rootScope.user.accountNumber + '/profile/username', 'uam'),
                data: params,
                headers: _generateServiceHeaders()
            };
        }

        /*
         Request to the new UAM endpoint to create the USERNAME
         SHOULD BE CHANGED WHEN SINGLE BUTTON IS ADDED TO PROFILE EDITION

         params {
             userName: "username1234",
             password: "pass1234"
         }
         */
        function _addUsernameRequest(params) {
            return {
                method: 'POST',
                url: _getServiceUrl('/users/' + $rootScope.user.accountNumber + '/profile/username', 'uam'),
                data: params,
                headers: _generateServiceHeaders()
            };
        }

        function _handlePinUpdateResponse(response) {
            var message = $filter('CMSValue')('changePinError');
            var status = 'error';

            if ($rootScope.activeFeatures.useUamChangePIN) {
                if (response.message)
                    var responseMessage = response.message.trim().indexOf(':') > - 1 ?
                        response.message.trim().split(':')[1].trim() : response.message.trim();

                if (response.status == 'success') {
                    status = 'success';
                    message = $filter('CMSValue')('pinSuccessfullyChanged');
                } else if (response && responseMessage && responseMessage == 'Old pin invalid') {
                    message = $filter('CMSValue')('oldPinInvalid');
                }
            } else {
                if (response.status == 'success') {
                    status = 'success';
                    message = $filter('CMSValue')('pinSuccessfullyChanged');
                } else if (response && response.Error && response.Error.Name == 'AccountInvalidPin') {
                    message = $filter('CMSValue')('oldPinInvalid');
                }
            }

            return {
                status: status,
                message: message
            };
        }

        function _handlePasswordUpdateResponse(response) {
            var message = $filter('CMSValue')('errorChangePassword');
            var status = 'error';

            if (response && response.status == 'success') {
                status = 'success';
                message = $filter('CMSValue')('passwordSuccessfullyChanged');
            } else if (response && (response.ErrorMessage == 'InputInvalidPassword' || response.ErrorMessage == 'IncorrectPassword')) {
                message = $filter('CMSValue')('oldPasswordInvalid');
            }

            return {
                status: status,
                message: message
            };
        }

        function _handleEmailUpdateResponse(response) {
            var message = $filter('CMSValue')('changeEmailError');
            var status = 'error';

            //TODO Review this once the service is implemented
            if (response && response.status == 'success') {
                status = 'success';
                message = $filter('CMSValue')('emailSuccessfullyChanged');
            }
            return {
                status: status,
                message: message
            };
        }

        /**
         * Select the appropriate message according to the API response
         * @param  {Object} response  API response
         * @return {Object}           Handled error/success information
         */
        function _handleAddUsernameResponse(response) {
            /* istanbul ignore next */
            response = response || {};

            var errors = {
                'UsernameAlreadyExists': 'usernameAlreadyExists',
                'InputInvalidUsername': 'accountInputInvalidUsername',
                'AccountAlreadyHasUsername': 'accountAlreadyHasUsername',
                'default': 'errorCreatingUsername'
            };
            var status = 'success';
            var code = 'usernameSuccessfullyCreated';


            if (response.status !== 'success') {
                code = errors[response.ErrorMessage] || errors.default;
                status = 'error';
            }

            return {
                status: status,
                message: $filter('CMSValue')(code)
            };
        }

        /**
         * Select the appropriate message according to the API response
         * @param  {Object} response  API response
         * @return {Object}           Handled error/success information
         */
        function _handleChangeUsernameResponse(response) {
            /* istanbul ignore next */
            response = response || {};

            var errors = {
                'UsernameAlreadyExists': 'usernameAlreadyExists',
                'InputInvalidUsername': 'accountInputInvalidUsername',
                'AccountAlreadyHasUsername': 'accountAlreadyHasUsername',
                'IncorrectPassword': 'usernameChangeIncorrectPassword',
                'default': 'errorUpdatingUsername'
            };
            var status = 'success';
            var code = 'usernameSuccessfullyUpdated';


            if (response.status !== 'success') {
                code = errors[response.ErrorMessage] || errors.default;
                status = 'error';
            }

            return {
                status: status,
                message: $filter('CMSValue')(code)
            };
        }

        function _buildSuccessResponse(response) {
            var status = response.AccountStatus;
            var errors = {
                14: 'ValidButNeedsEmailAndQuestions',
                15: 'ValidButNeedsQuestions',
                16: 'ValidButNeedsEmail',
                17: 'ValidButNeedsSignProvider',
                18: 'ValidButHasNewPricingPlan',
                19: 'ValidButHasDelinquentPricingPlan',
                default: 'Valid'
            };

            return {
                message: '',
                messageKey: errors[status] || errors.default,
                redirectUrl: ''
            };
        }

        /**
         * Get the microservice URL, prepending the correct domain (if needed)
         * Service keys must match the ones in ConfigurationFac.js
         *
         * @return {String}  Service URL with correct host prepended
         */
        function _getServiceUrl(path, service) {
            // available services [usa, usa_v1, uam] -> match ConfigurationFac
            var baseUrl = ConfigurationFac.getServiceApiUrl(service || 'usa');

            return baseUrl + path;
        }

        /**
         * Extract the response.data to comply with legacy
         * responses/methods.
         *
         * @TODO move to some kind of utilities
         *
         * @param  {Object} response  Response data
         * @return {Object}
         */
        function _extractResponseData(response) {

            /* istanbul ignore next */
            var data = response || {};
            var hasWrapper = _.every(['data', 'status', 'headers'], function (key) {
                return data.hasOwnProperty(key);
            });

            return hasWrapper ?
                response.data : response;
        }

        /**
         * Generate microservices' required headers
         * @return {Object}  Headers
         */
        function _generateServiceHeaders(overrides) {

            var clientApp = $window.clientApp;
            var locationContext = $window.locationContext;
            var tvgContext = RequestContextHeaders[clientApp + '_' + locationContext];
            var defaults = {
                'accept': 'application/json',
                'content-type': 'application/json',
                'x-clientapp': clientApp,
                'x-tvg-context': tvgContext
            };

            return _.defaults(overrides || {}, defaults);
        }

        /**
         * Are we currently in 4NJBets platform?
         *
         * @TODO  move to configuration fac
         *
         * @return {Boolean} is NJBets?
         */
        function _isNjBets() {
            return (ConfigurationFac.getApplicationContext().location === 'nj');
        }

        /**
         * Should we use the 'usa' microservice to login?
         * @return {Boolean}  Use 'usa' microservice?
         */
        function _isGkeLogin() {
            /* istanbul ignore next */
            return ($rootScope.activeFeatures || {}).gkeLogin;
        }

        /**
         * Should we use the 'usa' microservice to logout?
         * @return {Boolean}  Use 'usa' microservice?
         */
        function _isGkeLogout() {
            /* istanbul ignore next */
            return ($rootScope.activeFeatures || {}).gkeLogout || ($rootScope.activeFeatures || {}).gkeLogin;
        }

        /**
         * Should we use the 'usa' microservice to restore sessions?
         * @return {Boolean}  Use 'usa' microservice?
         */
        function _isGkeSession() {
            /* istanbul ignore next */
            return ($rootScope.activeFeatures || {}).gkeSession;
        }

        /**
         * Should use geocomply service to authenticate?
         * @returns {boolean}
         * @private
         */
        function _useGeoComply() {
            /* istanbul ignore next */
            return !!($rootScope.activeFeatures || {}).geoComply;
        }

        /**
         * Get session cookies from old /ajax session request
         * @TODO remove as soon as preferences are ready!
         *
         * @param {Object}  response  Login response to reply with
         * @return {Object}           Handled login response
         */
        function _getSessionCookies(response) {

                return $http({
                    method: 'GET',
                    url: '/ajax/accounts/id/session'
                })
                // don't mess with login/logout's original response
                // don't break on errors...
                    .then(function () {
                        return response;
                    })
                    .catch(function () {
                        return response;
                    });
            }

        /**
         * Try to load user's favorites tracks, ignoring any error that may occur,
         * and bypassing the input response to the next method of the promise chain
         *
         * @param {Object} response   Response to bypass
         * @return {Promise}          Promise wrapping the input response argument
         */
        function _loadFavoriteTracks(response) {

            var returnReponse = function () {
                return response;
            };

            return FavoriteTracksFac.loadFavoriteTracks($rootScope.user.accountNumber)
                .then(returnReponse)
                .catch(returnReponse); // don't block login because of this
        }

        //
        // LOGIN
        //

        /**
         * Login using the 'usa' microservice
         * (login v2)
         *
         * @param  {Object} params               Login credentials
         * @param  {String} params.stateAbbr     US state abbreviation
         * @param  {String} params.accountField  User's account number or email
         * @param  {String} params.pinField      User's account pin or password
         * @param  {String} [params.geoPacket]   GeoComply information
         *
         * @return {Promise}        Login promise
         */
        function _login(params) {

            var data = {
                account: params.accountField,
                stateAbbr: params.stateAbbr,
                pin: params.pinField
            };


            _startLoginLoader();


            return $http({
                    method: 'POST',
                    url: _getServiceUrl('/login', 'usa'),
                    data: data,
                    headers: _generateServiceHeaders()
                })
                .then(_extractResponseData)
                .then(_appendSignalProviderProperty)
                .then(_toLegacyLoginSuccessResponse)
                .then(_getSessionCookies)
                .then(_handleLoginSuccess)
                .then(_loadFavoriteTracks)
                .catch(_handleLoginError);
        }

        /**
         * Login using old TVG3 PHP services and .net api
         * (login v1)
         *
         * @param  {Object} params               Login credentials
         * @param  {String} params.loginType     Login type ['pin', 'email']
         * @param  {String} params.stateAbbr     US state abbreviation
         * @param  {String} params.accountField  User's account number or email
         * @param  {String} params.pinField      User's account pin or password
         * @return {Promise}                     Login promise
         */
        function _legacyLogin(params) {

            var data = {
                loginType: params.loginType,
                stateAbbr: params.stateAbbr,
                accountField: params.accountField,
                pinField: params.pinField
            };


            _startLoginLoader();

            return $http({
                    method: 'POST',
                    url: '/login',
                    data: $httpParamSerializerJQLike(data),
                    headers: _generateServiceHeaders({
                        'content-type': 'application/x-www-form-urlencoded'
                    })
                })
                .then(_extractResponseData)
                .then(_appendSignalProviderProperty)
                .then(_handleLoginSuccess)
                .then(_loadFavoriteTracks)
                .catch(_handleLoginError);
        }

        /**
         * Extracts SignalProviderNeedsUpdate property from the response
         * TEMPORARY SOLUTION
         * **/
        function _appendSignalProviderProperty(response) {

            var isSignalProviderEnabled = ($rootScope.activeFeatures || {}).signalProviderModal;

            if (!isSignalProviderEnabled) {
                return $q.resolve(response);
            }

            if ($rootScope.activeFeatures.useUamGetProfile) {
                return $http({
                    method: 'GET',
                    url: _getServiceUrl('/users/' + $rootScope.accountId + '/profile', 'uam'),
                    headers: _generateServiceHeaders()
                })
                    .then(_extractResponseData)
                    .then(function (data) {
                        _.merge(response.userDetails, _.pick(data, 'signalProviderNeedsUpdate'));
                        return response;
                    })
                    .catch(function () {
                        return response;
                    });
            } else {
                return $http({
                    method: 'GET',
                    url: _getServiceUrl('/profile', 'uam'),
                    headers: _generateServiceHeaders()
                })
                    .then(_extractResponseData)
                    .then(function (data) {
                        _.merge(response.userDetails, _.pick(data, 'signalProviderNeedsUpdate'));
                        return response;
                    })
                    .catch(function () {
                        return response;
                    });
            }
        }

        /**
         * Transform the login v2 success response into a login v1 sucecss response
         * so the following parsers and ui models don't break.
         *
         * @param {Object} response  New microservice response
         * @return {Object}          Parsed response (similar to old service)
         */
        function _toLegacyLoginSuccessResponse(response) {
            return _extractLegacyUserDetails(response);
        }

        /*
         * Extract a user details model from login or session restore API
         * responses.
         *
         * @param {Object} response  New microservice response
         * @return {Object}          Parsed response (similar to old service)
         */
        function _extractLegacyUserDetails(response) {

            /* istanbul ignore next */
            var user = (response || {}).userDetails;
            var parsed = response;
            var homeAddress;
            var mailingAddress;


            if (user) {
                /* istanbul ignore next */
                homeAddress = user.homeAddress || {};
                /* istanbul ignore next */
                mailingAddress = user.mailingAddress || {};

                parsed = {
                    status: (user.status || '').toLowerCase(),
                    AccountNumber: user.accountNumber,
                    FirstName: user.firstName,
                    LastName: user.lastName,
                    PrimaryPhone: user.primaryPhone,
                    WagerStatus: user.wagerStatus,
                    EmailAddress: user.emailAddress,
                    HomeAddress: {
                        StreetNumber: homeAddress.streetNumber,
                        Address1: homeAddress.address1,
                        Address2: homeAddress.address2,
                        City: homeAddress.city,
                        StateAbbr: homeAddress.state,
                        Zip: homeAddress.zipCode
                    },
                    MailingAddress: {
                        StreetNumber: mailingAddress.streetNumber,
                        Address1: mailingAddress.address1,
                        Address2: mailingAddress.address2,
                        City: mailingAddress.city,
                        StateAbbr: mailingAddress.state,
                        Zip: mailingAddress.zipCode
                    },
                    SignalProviderId: user.signalProviderId,
                    SignalProviderNeedsUpdate: user.signalProviderNeedsUpdate,
                    TransportId: user.transportId,
                    userName: user.userName
                };
            }

            // normalize missing email address as string
            if (parsed && !parsed.EmailAddress) {
                parsed.EmailAddress = '';
            }

            return parsed;
        }

        /**
         * Transform the login v2 error response into a login v1 error response
         * so the following parsers and ui models don't break.
         *
         * @param {Object} response  New microservice response
         * @return {Object}          Parsed response (similar to old service)
         */
        function _toLegacyLoginErrorResponse(response) {

            /* istanbul ignore next */
            response = response || {};

            // some codes from .net API, proxied by USA microservice
            var handledErrors = {
                ACCOUNT_DISABLED           : { ErrorCode: 213 , ErrorMessage: 'LoginFailureAccountDisabled'         },
                ACCOUNT_LOCKED             : { ErrorCode: 207 , ErrorMessage: 'LoginFailureAccountLocked'           },
                ACCOUNT_UPDATE_REQUIRED    : { ErrorCode: 0   , ErrorMessage: 'LoginFailureAccountUpdateRequired'   },
                BLACK_LISTED_LOCATION      : { ErrorCode: 0   , ErrorMessage: 'BlackListedLocation'                 },
                BLOCKED_SERVICE            : { ErrorCode: 0   , ErrorMessage: 'GeoLocationBlockedService'           },
                BLOCKED_SOFTWARE           : { ErrorCode: 0   , ErrorMessage: 'GeoLocationBlockedSoftware'          },
                GEO_SERVICE_FAILURE        : { ErrorCode: 0   , ErrorMessage: 'LoginFailureGeneric'                 },
                INVALID_CREDENTIALS        : { ErrorCode: 201 , ErrorMessage: 'LoginFailureInvalidCredentials'      },
                INVALID_GEO                : { ErrorCode: 0   , ErrorMessage: 'GeoLocationUnconfirmedOrInvalid'     },
                INVALID_GEOPACKET          : { ErrorCode: 0   , ErrorMessage: 'GeoLocationUnconfirmedOrInvalid'     },
                INVALID_WAGERING_STATE     : { ErrorCode: 208 , ErrorMessage: 'LoginFailureInvalidWageringState'    },
                LOGIN_FAILED               : { ErrorCode: 0   , ErrorMessage: 'LoginFailureGeneric'                 },
                LOGIN_REDIRECT             : { ErrorCode: 0   , ErrorMessage: 'LoginSiteRedirect'                   },
                MISSING_TOKEN              : { ErrorCode: 0   , ErrorMessage: 'ApiMissingUserSessionToken'          },
                NON_LEGAL_STATE            : { ErrorCode: 0   , ErrorMessage: 'LoginFailureGeneric'                 },
                OUT_OF_BOUNDARY            : { ErrorCode: 0   , ErrorMessage: 'GeoLocationOutOfBoundary'            },
                SESSION_NOT_FOUND          : { ErrorCode: 0   , ErrorMessage: 'ApiUserSessionNotFound'              },
                SESSION_TIMEOUT            : { ErrorCode: 0   , ErrorMessage: 'ApiUserSessionTimedOut'              },
                UNCONFIRMED_BOUNDARY       : { ErrorCode: 0   , ErrorMessage: 'GeoLocationUnconfirmedOrInvalid'     },
                USER_SESSION_LIMIT_REACHED : { ErrorCode: 0   , ErrorMessage: 'LoginFailureUserSessionLimitReached' },
                default                    : { ErrorCode: 0   , ErrorMessage: 'LoginFailureGeneric'                 }
            };
            var parsed = response;
            var redirectUrl = parsed.redirectUrl || parsed.RedirectUrl;


            // convert (if not already converted)
            if (!parsed.ErrorCode && !parsed.ErrorMessage) {
                parsed = handledErrors[response.exception];

                if (!parsed) {
                    if (redirectUrl) {
                        parsed = handledErrors.LOGIN_REDIRECT;
                        parsed.redirectUrl = redirectUrl;
                    } else {
                        parsed = handledErrors.default;
                    }
                }
            }

            return parsed;
        }

        /**
         * Handle login success by loading user's favorite tracks and then
         * logging the event with GTM and telemetron and parsing API response
         *
         * @param  {Object} response  API response
         * @return {Object}           Parsed login response
         */
        function _handleLoginSuccess(response) {

            // catch login errors with _handleLoginError()
            if (!response || response.status !== 'success') {
                return $q.reject(response);
            }

            var data = _parseSuccessResponse(response);

            _stopLoginLoader();
            _logLoginSuccessWithGTM(response);
            _emitGlobalLoginEvent();

            return data;
        }

        /**
         * Parse the successfull login response data
         * @param  {Object} response  Raw login response
         * @return {Object}           Parsed login response
         */
        function _parseSuccessResponse(response) {
            //TODO: response.ErrorMessage === 'LoginRedirect' -> For Now lets just consider this a success login and do nothing!!
            var successResponse = _buildSuccessResponse(response);

            $rootScope.user = Security.apiResponseTransformer(response);
            $rootScope.userSession = true;

            return successResponse;
        }

        /**
         * Handle login error by logging the event with GTM and telemetron and
         * parsing API response
         *
         * @param  {Object} response  Unsuccessful API response
         * @return {Object}           Parsed error login object
         */
        function _handleLoginError(response) {
            var data = null;

            response = _extractResponseData(response);
            response = _toLegacyLoginErrorResponse(response);
            data = _parseLoginErrors(response);

            mediator.dispatch('TVG4_SESSION_TIMEOUT', {});
            _stopLoginLoader();
            _logLoginErrorWithGTM(response);

            $rootScope.$broadcast('loginError', data);

            return $q.reject(data);
        }

        /**
         * Parse the unsuccessfull login response data
         * @param  {Object} response  Raw login response
         * @return {Object}           Parsed login response
         */
        function _parseLoginErrors(response) {

            var errorKey = response.ErrorMessage || response.errorMessage || '';
            var errorCode = response.ErrorCode || response.errorCode || 0;
            var httpKey = 'http_' + errorCode;
            var redirectUrl = response.RedirectUrl || response.redirectUrl || '';
            var messages = {
                LoginFailureUserSessionLimitReached : 'loginFailureUserSessionLimitReached',
                LoginFailureInvalidCredentials      : 'loginFailureInvalidCredentials',
                LoginFailureAccountUpdateRequired   : 'loginFailureAccountUpdateRequired',
                LoginFailureAccountLocked           : 'loginFailureAccountLocked',
                LoginFailureAccountDisabled         : 'loginFailureAccountDisabled',
                LoginSiteRedirect                   : 'loginSiteRedirect',
                ApiMissingUserSessionToken          : 'apiUserSessionTimedOut',
                ApiUserSessionTimedOut              : 'apiUserSessionTimedOut',
                ApiUserSessionNotFound              : 'apiUserSessionTimedOut',
                http_208                            : 'loginFailureNonLegalState',
                default                             : 'loginFailureGeneric'
            };
            var cmsKey = messages[httpKey] || messages[errorKey] || messages.default;
            var message = $filter('CMSValue')(cmsKey);


            // new services only return "redirectUrl", without the "LoginSiteRedirect" errorMessage
            if (redirectUrl) {
                message += redirectUrl;
                redirectUrl = _ensureProtocol(redirectUrl);
            }

            return {
                message: message,
                messageKey: errorKey,
                redirectUrl: redirectUrl
            };
        }

        /**
         * Make sure the URL contains a valid HTTP/HTTPS protocol
         * @param {String} url  Raw URL
         * @return {String}     Parsed URL
         */
        function _ensureProtocol(url) {
            url = url || '';

            // does not start with http://, https:// or //
            if (!url.match('^(https?:)?//.*')) {
                url = '//' + url;
            }

            return url;
        }

        /**
         * Start login's loader
         */
        function _startLoginLoader() {
            $rootScope.loginOk = false;
        }

        /**
         * Stop login's loader
         */
        function _stopLoginLoader() {
            $rootScope.loginOk = true;
        }

        /**
         * Broadcast everyone that a login has been made
         * (don't know why the timeout hack is needed, because _broadcastUser()
         * alreay has a timeout inside...)
         */
        function _emitGlobalLoginEvent() {

            $timeout(function () {
                $rootScope.$broadcast('login', true);
            });

            _broadcastUser();
        }

        /**
         * Register login 'success' using GTM
         * @param  {Object} response  API login response
         */
        function _logLoginSuccessWithGTM(response) {
            /* istanbul ignore next */
            response = response || {};

            // user details are defined here, because GTMFac may not have them
            // already defined in rootScope to use
            var event = {
                gaEventCategory: 'Login',
                gaEventAction: 'Login Success',
                accountId: response.AccountNumber,
                residenceState: response.HomeAddress ?
                    response.HomeAddress.StateAbbr : null,
                loginStatus: 'Logged In',
                registrationStatus: 'Registered'
            };

            GTMFac.GTMEvent()
                .send($rootScope, 'loginSuccess', event);
        }

        /**
         * Register any login errors using GTM
         * @param  {Object} response  API login response
         */
        function _logLoginErrorWithGTM(response) {
            /* istanbul ignore next */
            response = response || {};

            var event = {
                gaEventCategory: 'Login',
                gaEventAction: 'Login Error',
                gaEventLabel: response.message,
                errorType: 'Login Error',
                errorMessage: response.message
            };

            GTMFac.GTMEvent()
                .send($rootScope, 'loginError', event);
        }

        //
        // LOGOUT
        //

        /**
         * Logout using the 'usa' microservice
         * (logout v2)
         *
         * @return {Promise}        Logout promise
         */
        function _logout(param) {

            _startLogoutLoader();

            return $http({
                    method: 'POST',
                    url: _getServiceUrl('/logout' + param, 'usa'),
                    headers: _generateServiceHeaders()
                })
                .then(_extractResponseData)
                .then(_toLegacyLogoutSuccessResponse)
                .then(_getSessionCookies)
                .then(_handleLogoutSuccess)
                .then(_removeTVG3Link)
                .catch(_handleLogoutError);
        }

        /**
         * Remove TVG3 Link local storage key
         */
        function _removeTVG3Link() {
            localStorage.removeItem("tvg3Link");
            $rootScope.$broadcast('changedTvg3Link');
        }

        /**
         * Logout using old TVG3 PHP services and .net api
         * (logout v1)
         *
         * @return {Promise}        Logout promise
         */
        function _legacyLogout() {

            _startLogoutLoader();

            return $http.get('/login/log/out', {
                    headers: _generateServiceHeaders()
                })
                .then(_extractResponseData)
                .then(_removeTVG3Link)
                .then(_handleLogoutSuccess)
                .catch(_handleLogoutError);
        }

        /**
         * Transform the logout v2 response into a logout v1 response
         * so the following parsers and ui models don't break.
         *
         * @param {Object} response  New microservice response
         * @return {Object}          Parsed response (similar to old service)
         */
        function _toLegacyLogoutSuccessResponse(response) {
            return response;
        }

        /**
         * Transform the logout v2 error response into a logout v1 error response
         * so the following parsers and ui models don't break.
         *
         * @param {Object} response  New microservice response
         * @return {Object}          Parsed response (similar to old service)
         */
        function _toLegacyLogoutErrorResponse(response) {
            return response;
        }

        /**
         * Handle logout success by loading clearing user's favorite tracks and then
         * logging the event with GTM and telemetron and parsing API response
         *
         * @param  {Object} response  API response
         * @return {Promise}          Parsed logout response
         */
        function _handleLogoutSuccess(response) {

            return $q(function (resolve) {

                _logLogoutSuccessWithGTM();

                $rootScope.user = null;
                $rootScope.userSession = false;

                $timeout(function () {
                    FavoriteTracksFac.clearFavoriteList();

                    _emitGlobalLogoutEvent();
                    _stopLogoutLoader();

                    resolve(response);
                }, 100);
            });
        }

        /**
         * Handle logout error by logging the event with GTM and telemetron and
         * parsing API response
         *
         * @param  {Object} response  Unsuccessful API response or runtime error
         * @return {Promise}          Rejected API response
         */
        function _handleLogoutError(response) {

            response = _extractResponseData(response);
            response = _toLegacyLogoutErrorResponse(response);

            _stopLogoutLoader();

            return $q.reject(response);
        }

        /**
         * Start logout's loader
         */
        function _startLogoutLoader() {
            $rootScope.logoutOk = false;
        }

        /**
         * Stop logout's loader
         */
        function _stopLogoutLoader() {
            $rootScope.logoutOk = true;
        }

        /**
         * Broadcast everyone that a logout has been made
         */
        function _emitGlobalLogoutEvent() {
            $rootScope.$broadcast('logout');

            /*
             TODO: luis almeida
             created another event for logout, as I think this is the 'real' logout:
             this only fires when the user actually presses the logout button.
             Lets review how we fire session / user / logout / login events
             */
            $rootScope.$broadcast('userLogout');
        }

        /**
         * Register logout 'success' using GTM
         */
        function _logLogoutSuccessWithGTM() {

            var event = {
                gaEventCategory: 'Login',
                gaEventAction: 'Logout Success'
            };

            GTMFac.GTMEvent()
                .send($rootScope, 'logoutSuccess', event);
        }

        //
        // SESSION
        //

        /**
         * Validate session using USA v2 microservice.
         * The cookie "tvg3token" must be present in the request!
         * (hosted in GKE).
         *
         * @return {Promise} Promised request
         */
        function _validateSession() {

            return $http({
                    method: 'GET',
                    url: getValidateSessionUrl(),
                    headers: _generateServiceHeaders()
                })
                .then(_extractResponseData)
                .then(_toLegacySessionSuccessResponse)
                .then(_handleValidateSessionSuccess)
                .catch(_handleValidateSessionError);
        }

        /**
         * Validate session using USA v1 microservice.
         * The cookie "tvg3token" must be present in the request!
         * (hosted in AWS)
         *
         * @return {Promise} Promised request
         */
        function _legacyValidateSession() {

            return $http({
                    method: 'GET',
                    url: getValidateSessionUrl(),
                    headers: _generateServiceHeaders()
                })
                .then(_extractResponseData)
                .then(_handleValidateSessionSuccess)
                .catch(_handleValidateSessionError);
        }

        /**
         * Get user validation endpoint to use in other modules (e.g. videos)
         * @return {String}  Session validation endpoint URL
         */
        function getValidateSessionUrl() {
            return _getServiceUrl('/authenticated', 'usa');
        }

        /**
         * Transform the session v2 response into a session v1 response
         * so the following parsers and ui models don't break.
         *
         * @param {Object} response  New microservice response
         * @return {Object}          Parsed response (similar to old service)
         */
        function _toLegacySessionSuccessResponse(response) {
            return response;
        }

        /**
         * Transform the session v2 error response into a session v1 error response
         * so the following parsers and ui models don't break.
         *
         * @param {Object} response  New microservice response
         * @return {Object}          Parsed response (similar to old service)
         */
        function _toLegacySessionErrorResponse(response) {

            /* istanbul ignore next */
            response = response || {};

            // some codes from .net API, proxied by USA microservice
            var handledErrors = {
                default: { 'ErrorCode': 0, 'ErrorMessage': 'Not Authenticated' }
            };
            var parsed = response;


            // convert (if not already converted)
            if (!parsed.ErrorCode && !parsed.ErrorMessage) {
                parsed = handledErrors[response.exception] || handledErrors.default;
            }

            return parsed;
        }

        function _handleValidateSessionSuccess() {

            //
            // @TODO must check if broadcast user really makes sense, because
            // validate session doesn't return any user details
            //

            $rootScope.userSession = true;
            _broadcastUser();

            return null;
        }

        function _handleValidateSessionError(response) {

            mediator.dispatch('TVG4_SESSION_TIMEOUT', {});
            response = _extractResponseData(response);
            response = _toLegacySessionErrorResponse(response);

            _broadcastInvalidSession();

            return $q.reject(response);
        }

        /**
         * Validate user session and set the global user object based
         * on response, using USA v2 microservice.
         * The cookie "tvg3token" must be present in the request!
         * (hosted in GKE)
         *
         * @return {Promise} Promised request
         */
        function _restoreSession() {
            if ($rootScope.activeFeatures.useUamGetProfile) {
            return $http({
                method: 'GET',
                url: _getServiceUrl('/users/' + $rootScope.accountId + '/profile', 'uam'),
                headers: _generateServiceHeaders()
                })
                    .then(_extractResponseData)
                    .then(_toLegacyRestoreSessionSuccessResponse)
                    .then(_handleRestoreSessionSuccess)
                    .catch(_handleRestoreSessionError);
            } else {
                return $http({
                    method: 'GET',
                    url: _getServiceUrl('/profile', 'uam'),
                    headers: _generateServiceHeaders()
            })
                .then(_extractResponseData)
                .then(_toLegacyRestoreSessionSuccessResponse)
                .then(_handleRestoreSessionSuccess)
                .catch(_handleRestoreSessionError);
        }


        }

        /**
         * Validate session using USA v1 microservice.
         * The cookie "tvg3token" must be present in the request!
         * (hosted in AWS)
         *
         * @return {Promise} Promised request
         */
        function _legacyRestoreSession() {

            return $http({
                    method: 'GET',
                    url: '/ajax/accounts/id/session'
                })
                .then(_extractResponseData)
                .then(_handleRestoreSessionSuccess)
                .catch(_handleRestoreSessionError);
        }

        function _toLegacyRestoreSessionSuccessResponse(response) {

            response = response || {};
            response.status = 'success';

            return _extractLegacyUserDetails({userDetails: response });
        }

        function _handleRestoreSessionSuccess(response) {

            // catch login errors with _handleRestoreSessionError()
            if (!response || response.status !== 'success') {
                return $q.reject(response);
            }


            var data = _parseSuccessResponse(response);

            // success -> return data after loading favs
            return _loadFavoriteTracks()
                .then(_broadcastUser) // must exec after _parseSuccessResponse
                .then(function () {
                    return data;
                });
        }

        function _handleRestoreSessionError(response) {
            var err = null;

            response = response || {};
            response = _extractResponseData(response);
            response = _toLegacySessionErrorResponse(response);

            mediator.dispatch('TVG4_SESSION_TIMEOUT', {});
            if (response.hasOwnProperty('ErrorCode')) {
                _broadcastInvalidSession();
                err = _parseLoginErrors(response);
                return $q.reject(err);
            } else {
                //if we dont have a user session, reject
                if(!$rootScope.user) {
                    _broadcastGTMUserLogout();
                    _broadcastInvalidSession();
                    err = response;
                    return $q.reject(err);
                } else {
                //keep active user session when the request fails
                    return $q.resolve();
                }
            }
        }

        return Security;
    }

    SecuritySvc.$inject = [
        '$http',
        '$q',
        '$rootScope',
        '$timeout',
        '$filter',
        '$window',
        '$location',
        '$httpParamSerializerJQLike',
        'CookieFac',
        'GTMFac',
        'GeoComplyLoginSvc',
        'ConfigurationFac',
        'FavoriteTracksFac',
        'RequestContextHeaders',
        'PreferencesCookieReloadedFac'
    ];

    return SecuritySvc;
});

