'use strict';

define(
    'ReportsCtrl',[],
    function() {

        function ReportsCtrl($scope, ConfigurationFac, $sce, $http, $location, $window, $rootScope) {

            $scope.data = {
                loading: true,
                error: false
            };
            var pageTitle = $location.path().split('/')[1];
            var yearEnd = 'year-end-reports';
            var url = $window.location.origin + '/reports/';
            var tab = pageTitle === yearEnd ? 'year-end/' : 'w2g/';
            var user = !!$rootScope.user && $rootScope.user.accountNumber;
            var requestUrl = url + tab + user;

            $http.get(requestUrl).then(function() {
                $scope.url = $sce.trustAsResourceUrl(requestUrl);
            })
            .catch(function() {
                $scope.data.error = true;
            })
            .finally(function() {
                $scope.data.loading = false;
            });
        }

        ReportsCtrl.$inject = [
            '$scope',
            'ConfigurationFac',
            '$sce',
            '$http',
            '$location',
            '$window',
            '$rootScope'
        ];

        return ReportsCtrl;
    }
);

