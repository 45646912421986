'use strict';

define('Logging',[
        'VideosLoggingFac',
        'Utilities',
        'GTMMod',
        'Security'
    ], function (VideosLoggingFac) {

        angular
            .module('TVG.Logging', [
                'TVG.Utilities',
                'TVG.Common.GTM',
                'TVG.Security'
            ])
            .factory('VideosLoggingFac', VideosLoggingFac);
    }
);

