'use strict';

define(
    'HandicappingStoreCtrl',['lodash', 'uuid'],
    function (_, uuid) {
        function HandicappingStoreCtrl(HandicappingStoreFac, $scope, $rootScope, $timeout) {
            this.HandicappingStoreFac = HandicappingStoreFac;

            this.equibaseHandicapStore = $rootScope.activeFeatures.equibaseHandicapStore;
            this.scope = $scope;

            this.init($rootScope, $timeout);

            return this;
        }

        function getHandicappingStoreUrl(domain) {
            var equibaseUrlParameter;
            var localEquibaseId = _.attempt(function () {
                return window.sessionStorage.getItem('equibaseId');
            });

            if (!localEquibaseId) {
                var equibaseId = uuid();
                _.attempt(function () {
                    return window.sessionStorage.setItem('equibaseId', equibaseId);
                });
            }
            equibaseUrlParameter = 'uuid=' + (localEquibaseId ? localEquibaseId : equibaseId);

            var equibaseDomain = "equibase-store.tvg.com";
            // calculate domain
            if (domain.search('qa') >= 0) {
                if (domain.search('racing') >= 0) {
                    equibaseDomain =  "equibase-store.qa.fndl.dev";
                } else if (domain.search('4njbets') >= 0) {
                    equibaseDomain =  "equibase-store-qa.us.betfair.com";
                } else {
                    equibaseDomain = "equibase-store-qa.tvg.com";
                }
            } else {
                if (domain.search('racing') >= 0) {
                    equibaseDomain =  "equibase-store.fanduel.com";
                } else if (domain.search('4njbets') >= 0) {
                    equibaseDomain =  "equibase-store.us.betfair.com";
                }
            }

            return "https://" + equibaseDomain + '?' + equibaseUrlParameter;
        }

        HandicappingStoreCtrl.prototype = {
            init: function (root, timeout) {
                var vm = this;

                vm.removeLoading = function () {
                    // we need this time to wait for equibase 'loaded' event
                    timeout(function () {
                        if (!vm.handicappingStoreLoaded) {
                            vm.errorLoadingHandicapStore = true;
                        }
                        vm.loading = false;
                    });
                };

                vm.equibaseLoaded = function(event) {
                    if (event.data.type === 'handicapping_loaded') {
                        vm.handicappingStoreLoaded = true;
                        root.$apply();
                    }
                };

                vm.refreshEquibase = function() {
                    vm.errorLoadingHandicapStore = false;
                    vm.loading = true;
                    vm.findIframe();
                };

                vm.findIframe = function() {
                    timeout(function() {
                        vm.errorLoadingHandicapStore = false;
                        var iFrame = angular.element("#handicappingStore-iframe");

                        // this event is triggered by equibase team
                        window.addEventListener('message', vm.equibaseLoaded);

                        // this event is triggered when iframe is finished the loading of content
                        if (iFrame) {
                            iFrame.on("load", vm.removeLoading);
                        }

                        vm.loading = true;
                        vm.storeUrl = getHandicappingStoreUrl(window.location.hostname);
                    });
                };

                if (vm.equibaseHandicapStore && typeof window !== "undefined") {
                    vm.findIframe();
                } else {
                    this.loading = true;

                    this.HandicappingStoreFac.getHandicappingStoreUrl().then(
                        function (data) {
                            vm.storeUrl = data;
                            vm.loading = false;
                        },
                        function () {
                            vm.loading = false;
                            vm.errorMessage = 'Handicapping Store not available';
                        }
                    ).finally( function () {
                        vm.scope.$emit('pageLoaded', 'handicapping#handicapping-store');
                    });
                }
            }
        };

        HandicappingStoreCtrl.$inject = [
            'HandicappingStoreFac',
            '$scope',
            '$rootScope',
            '$timeout'
        ];

        return HandicappingStoreCtrl;
    }
);

