'use strict';

define('PageMetaInfoEntityBuilder',['PageMetaInfoEntity'], function (PageInfoEntity) {

    function builder(){
        var pageInfo = new PageInfoEntity();

        return {
            withTitle: function (title) {
                pageInfo.title = title;
                return this;
            },
            withMetaTag: function (metaTag) {
                pageInfo.metaTag = metaTag;
                return this;
            },
            build: function () {
                return pageInfo;
            }
        };
    }

    return builder;

});


