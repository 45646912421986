"use strict";

define(
    'SubscriptionAlertsFactory',[
        'RaceAlertFactory',
        'SubscriptionAlerts',
        'lodash'
    ],
    function (RaceAlertFactory, SubscriptionAlerts, _) {



        return {
            createAllSubscriptionsAsDisabled: function () {
                var raceAlerts = RaceAlertFactory.createAllRaceAlerts();
                return raceAlerts.map(function (raceAlert){
                    return new SubscriptionAlerts(raceAlert, false);
                });
            },
            createAllSubscriptionsAsEnabled: function () {
                var raceAlerts = RaceAlertFactory.createAllRaceAlerts();
                return raceAlerts.map(function (raceAlert){
                    return new SubscriptionAlerts(raceAlert, true);
                });
            },
            createSubscription: function (alert, enable) {
                //checking types here alert instanceof RaceAlert
                if(_.isObject(alert) && _.isBoolean(enable)){
                    return new SubscriptionAlerts(alert, enable);
                }
                return -1;
            },
            createMissingSubscriptionsAsDisabled: function (subscriptions){
                var allSubscriptionsDisabled = this.createAllSubscriptionsAsDisabled();

                var currentSubscriptionsAlertsIds = subscriptions.map(function(subscription) {
                    return subscription.alert.id;
                });

                allSubscriptionsDisabled.forEach(function (subscriptionDisabled) {
                    if(currentSubscriptionsAlertsIds.indexOf(subscriptionDisabled.alert.id) === -1){
                        subscriptions[subscriptionDisabled.alert.id] = subscriptionDisabled;
                    }
                });

                return subscriptions;
            }

        };

    });

