'use strict';

define('FirstTimeHelperCtrl',[],
    function () {
        function FirstTimeHelperCtrl($scope, $filter) {
            var videoObj = {helpTutorialList: []};
            try {
                videoObj = JSON.parse($filter('CMSValue')('globalHelpTutorialConfiguration'));
            } catch (e) {
                //console.log('[WARNING] Failed to load help video tutorial list');
            }

            $scope.data = {
                videoObjects: videoObj.helpTutorialList,
                showYoutubePlayer: false,
                playerId: null

            };

            $scope.events.playButtonPressed = _playButtonPressed;

            $scope.$on("$destroy", function () {
                $scope.events.closeHelpModal(false);
            });

            function _playButtonPressed(playerId) {
                $scope.data.playerId = playerId;
                $scope.data.showYoutubePlayer = true;
            }

        }

        FirstTimeHelperCtrl.$$inject = [
            '$scope',
            '$filter'
        ];

        return FirstTimeHelperCtrl;
    });

