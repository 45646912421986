'use strict';

define('LayoutDir',[],function () {
    function layoutDir($window,
                       $location,
                       $rootScope,
                       $route,
                       $timeout,
                       $routeParams,
                       GTMFac,
                       LayoutFac,
                       METADATA,
                       MediatorSubscriptionsFac
    ) {
        return {
            restrict: 'E',
            link: function (scope, elem) {
                 // The parameter is empty for now (we want mediatorActions and don't need to specify a domain for that)

                var layout = "desktop",
                    subLayout = null,
                    body = angular.element(document.getElementById('angular-app'));



                scope.routeParams = $routeParams;
                scope.currentPage = $location.path().split('/')[1];

                scope.showSideMenu = function () {
                    var currRoute = $location.path();
                    return ($route.routes[currRoute] && $route.routes[currRoute].hideSideMenu === true);
                };

                scope.isPathActive = function (path, mainRoute) {
                    if (mainRoute) {
                        return path == ($location.path().substr(1).split('/')[0]);
                    } else {
                        return path == $location.path().substr(1);
                    }
                };

                scope.setPageTitleForRace = function (aRace) {

                    scope.currentPage = $location.path().split('/')[1];
                    if (!aRace) {

                        if (scope.currentPage != "videos" && scope.currentPage != "races" && scope.currentPage != "results") {
                            $rootScope.pageTitle = null;
                            $rootScope.pageSubTitle = null;
                            $rootScope.moreDetails = null;
                        }

                        if (scope.currentPage === "registration") {
                            $rootScope.pageTitle = "TVG";
                            $rootScope.pageSubTitle = "Member Registration";
                        }

                        $rootScope.showPreviousTitleArrow = false;
                        $rootScope.showNextTitleArrow = false;
                    } else {
                        $rootScope.pageTitle = "Race " + aRace.raceNumber + " | " + (aRace.trackName || aRace.trackAbbr);
                        if ($rootScope.layout !== 'desktop') {
                            $rootScope.pageTitleRaceMobile = "Race " + aRace.raceNumber;
                            $rootScope.pageTitleTrackNameMobile = (aRace.trackName || aRace.trackAbbr);
                            $rootScope.pageSubTitle = aRace.distance + " " + aRace.surfaceName + " | " + aRace.raceClass + " | " + aRace.runners + " Runners" + (aRace.breed ? (" | " + aRace.breed) : "");
                        }
                    }
                };

                scope.setShowPreviousTitleArrow = function (value) {
                    $rootScope.showPreviousTitleArrow = value;
                };

                scope.setShowNextTitleArrow = function (value) {
                    $rootScope.showNextTitleArrow = value;
                };

                scope.GTMEvents = function (section) {
                    var p = {};
                    p.gaEventCategory = 'Site Click';
                    p.gaEventAction = GTMFac.Tvg4ScreenName() + ' Page Click';
                    p.screenName = GTMFac.Tvg4ScreenName();


                    switch (section) {
                        case 'logo':
                            p.gaEventLabel = 'Header | TVG Logo';
                            p.eventLabel = 'Header-TVGLogo';
                            break;
                        case 'horseRacing':
                            p.gaEventLabel = 'Header | Races Tab';
                            p.eventLabel = 'Header-HorseRacing';
                            break;
                        case 'fullSchedule':
                            p.gaEventLabel = 'Header | Races | Full Schedule';
                            p.eventLabel = 'Header-HorseRacingDropDown';
                            break;
                        case 'results':
                            p.gaEventLabel = 'Header | Races | Results';
                            p.eventLabel = 'Header-HorseRacingDropDown';
                            break;
                        case 'handicapping':
                            p.gaEventLabel = 'Header | Handicapping Tab';
                            p.eventLabel = 'Header-Handicapping';
                            break;
                        case 'Handicapping Store':
                        case 'Free Past Performances':
                        case 'promotions':
                            p.eventLabel    = 'Header-Promotion';
                            p.gaEventLabel  = 'Header | Promotion';
                            break;
                        case 'Wager Rewards':
                            p.eventLabel = 'Header-Promotion';
                            p.gaEventLabel = 'Header | Promotion | Wager Rewards';
                            break;
                        case 'wager-rewards':
                            p.eventLabel = 'Header-Promotion';
                            p.gaEventLabel = 'Header | Promotion | Wager Rewards';
                            break;
                        case 'Past Results':
                            p.eventLabel = 'Past-Results';
                            p.gaEventLabel = 'Header | Handicaping | Past Results';
                            break;
                        case 'TVG Picks':
                            p.gaEventLabel = 'Header | Handicapping | ' + section;
                            p.eventLabel = 'Header-HandicappingDropDown';
                            break;
                        case 'help':
                            p.gaEventLabel = 'Header | Help Tab';
                            p.eventLabel = 'Header-Help';
                            break;
                        case 'termsandconditions':
                            p.gaEventLabel = 'Terms And Conditions';
                            p.eventLabel = 'Footer-TermsConditions';
                            p.gaEventAction = 'Footer Click';
                            break;
                        case 'privacypolicy':
                            p.gaEventLabel = 'Privacy Policy';
                            p.eventLabel = 'Footer-PrivacyPolicy';
                            p.gaEventAction = 'Footer Click';
                            break;
                        case 'wagerresponsibly':
                            p.gaEventLabel = 'Wager Responsibly';
                            p.eventLabel = 'Footer-WagerResponsibly';
                            p.gaEventAction = 'Footer Click';
                            break;
                        case 'optout':
                            p.gaEventLabel = 'Back to Previous Site';
                            p.eventLabel = 'Footer-BackToPreviousSite';
                            p.gaEventAction = 'Footer Click';
                            break;
                        case 'social-facebook':
                            p.gaEventLabel = 'Facebook Logo';
                            p.eventLabel = 'Footer-FaceBook';
                            p.gaEventAction = 'Footer Click';
                            break;
                        case 'social-twitter':
                            p.gaEventLabel = 'Twitter Logo';
                            p.eventLabel = 'Footer-TwitterLogo';
                            p.gaEventAction = 'Footer Click';
                            break;
                        case 'social-youtube':
                            p.gaEventLabel = 'YouTube Logo';
                            p.eventLabel = 'Footer-YouTubeLogo';
                            p.gaEventAction = 'Footer Click';
                            break;
                        case 'email-cs':
                            p.gaEventLabel = 'Email Customer Service';
                            p.eventLabel = 'Footer-EmailCS';
                            p.gaEventAction = 'Footer Click';
                            break;
                        case 'tutorial-videos':
                            p.gaEventLabel = 'Tutorial Videos';
                            p.eventLabel = 'Tutorial-Videos';
                            p.gaEventAction = 'Tutorial Videos Click';
                            break;
                        case 'tracks':
                            p.gaEventLabel = 'Header | Races | Track List';
                            p.eventLabel = 'Header-HorseRacingDropDown';
                            break;
                    }

                    if (GTMFac.isTVG4()) {
                        p.sectionName = GTMFac.Tvg4SectionName();
                    }

                    if($routeParams.trackAbbr && $routeParams.race && METADATA.hasOwnProperty('allTracks') && METADATA.allTracks.hasOwnProperty($routeParams.trackAbbr) && METADATA.allTracks[$routeParams.trackAbbr].Name) {
                        p.trackName = METADATA.allTracks[$routeParams.trackAbbr].Name;
                        p.raceNumber = "R" + $routeParams.race;
                    }

                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
                };

                scope.$on("goto_new_deposit", function () {
                    var destPath = "/my-funds";
                    scope.quickDepositModal.dismiss();
                    $location.path(destPath);
                });

                scope.$on("websiteRedirect", function () {
                    scope.websiteRedirect();
                });

                scope.websiteRedirect = function () {
                    var p = {};
                    p.gaEventCategory = 'Site Click';
                    p.gaEventAction = 'Website Tab Click';
                    p.gaEventLabel = 'Left Nav Click';
                    GTMFac.GTMEvent().send($rootScope, 'siteClick', p);

                    $timeout(function () {
                        $window.location.href = '//' + $rootScope.redirectSite;
                    });
                };

                scope.$on("videoRendered", function () {
                    var player = document.getElementsByTagName("video")[0];
                    if (player) {
                        player.addEventListener('webkitbeginfullscreen', function () {
                            document.webkitIsFullScreen = true;
                        }, false);
                        player.addEventListener('webkitendfullscreen', function () {
                            document.webkitIsFullScreen = false;
                        }, false);
                    }
                });

                scope.onResize = function () {
                    var calculatedLayout = LayoutFac.calculateLayout(scope.currentPage);
                    layout = calculatedLayout.layout;
                    subLayout = calculatedLayout.subLayout;

                    if (layout != scope.layout) {
                        body.removeClass("desktop").removeClass("phone").removeClass("tablet").addClass(layout);
                        scope.layout = layout;
                        scope.subLayout = subLayout;
                        $rootScope.layout = layout;
                        $rootScope.subLayout = subLayout;
                        if ($rootScope.raceInfoModal) {
                            $rootScope.raceInfoModal.close();
                            $rootScope.raceInfoModal = null;
                        }
                        _applyChanges();
                    } else if (subLayout != scope.subLayout) {
                        scope.subLayout = subLayout;
                        _applyChanges();
                    }


                    if ($window.CSS_LOADED === true) {
                        /*
                         Time to apply CSS
                         The app is already loading the modules in the background
                         because the $rootScope.layout is already defined
                         */
                        setTimeout(function () {
                            body.removeClass("loading");
                            body.removeClass("menu-expanded");
                            elem.removeClass("hidden");
                            $rootScope.apploaded = true;
                        }, 150);
                    } else {
                        scope.$watch(
                            function () {
                                return $window.CSS_LOADED;
                            }, function (currentValue) {
                                /*
                                 Time to apply CSS
                                 The app is already loading the modules in the background
                                 because the $rootScope.layout is already defined
                                 */
                                if (currentValue) {
                                    setTimeout(function () {
                                        body.removeClass("loading");
                                        body.removeClass("menu-expanded");
                                        elem.removeClass("hidden");
                                        $rootScope.apploaded = true;
                                    }, 150);
                                }
                            }
                        );
                    }
                };

                //CMS data is present when we boot the app
                scope.onResize();

                angular.element($window).bind('resize', function () {
                    var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.webkitIsFullScreen;
                    if (!fullscreenElement) {
                        scope.onResize();
                    }
                });

                //detect orientation change
                angular.element($window).bind('orientationchange', function () {
                    $rootScope.$broadcast('orientationchange');
                });

                scope.goToHomePage = function () {
                    $location.path("/");
                };

                function _applyChanges() {
                    if (!_.isUndefined($rootScope.$$phase) &&
                        $rootScope.$$phase !== '$apply' &&
                        $rootScope.$$phase !== '$digest') {

                        scope.$apply();
                    }
                }

                function _isUserLoggedOut(){
                    return !$rootScope.userSession;
                }

                scope.handleOpenMenu = function(path, askLogin, tab, event) {
                    if (event) {
                        event.preventDefault();
                    }
                    tab = tab || false;
                    if (_isUserLoggedOut() && !!askLogin) {
                        $rootScope.$emit("callLoginRequiredModal", _goToPath(path, tab));
                    } else {
                        _goToPath(path, tab)();
                    }
                };

                function _goToPath(path, tab) {
                   return function() {
                       if(tab) {
                           scope.GTMEvents(tab.name);
                           $location.path(path).hash(tab.id);
                       } else {
                           scope.GTMEvents(path);
                           $location.path(path).hash("");
                       }
                   };
                }

                scope.handleOpenTab = function(path, tab, event) {
                    if (event) {
                        event.preventDefault();
                    }
                    if (_isUserLoggedOut() && tab.askForLogin) {
                        $rootScope.$emit("callLoginRequiredModal", _goToPath(path, tab));
                    } else {
                        _goToPath(path, tab)();
                    }
                };

                /***
                 * Updates the layout scope values when tvg4V1 value is changed
                 */
                var unbindWatch = $rootScope.$watch("activeFeatures.tvg4V1", function () {
                    if ($rootScope.layout === 'desktop') {
                        scope.onResize();
                    }
                });

                //Make all configured subscritions (mediator module)
                MediatorSubscriptionsFac.subscribeAll();

                /***
                 * As there is a watch on $rootScope we should unbind it when this scope is destroyed
                 */
                scope.$on("$destroy", function () {
                    MediatorSubscriptionsFac.unsubscribeAll();
                    unbindWatch();
                });
            }
        };
    }

    layoutDir.$inject = [
        '$window',
        '$location',
        '$rootScope',
        '$route',
        '$timeout',
        '$routeParams',
        'GTMFac',
        'LayoutFac',
        'METADATA',
        'MediatorSubscriptionsFac'
    ];

    return layoutDir;
});

