'use strict';

define(
    'Handicapping',[
        'HandicappingCtrl',
        'HandicappingStoreCtrl',
        'HandicappingStoreFac',
        'HandicappingRaceReplaysCtrl',
        'HandicappingRaceReplaysFac',
        'RaceReplaysSearchByDateCtrl',
        'RaceReplaysSearchByTrackCtrl',
        'RaceReplaysSearchByHorseCtrl',
        'RaceReplaysSearchByFavoriteCtrl',
        'HandicappingByUrlCtrl',
        'GTMMod'
    ],

    function (HandicappingCtrl, HandicappingStoreCtrl, HandicappingStoreFac, HandicappingRaceReplaysCtrl, HandicappingRaceReplaysFac,
              RaceReplaysSearchByDateCtrl, RaceReplaysSearchByTrackCtrl, RaceReplaysSearchByHorseCtrl, RaceReplaysSearchByFavoriteCtrl, HandicappingByUrlCtrl) {

        angular.module('TVG.Handicapping', [
            'TVG.Common.GTM'
        ])
            .controller('HandicappingCtrl', HandicappingCtrl)
            .controller('HandicappingStoreCtrl', HandicappingStoreCtrl)
            .controller('HandicappingRaceReplaysCtrl', HandicappingRaceReplaysCtrl)
            .controller('RaceReplaysSearchByDateCtrl', RaceReplaysSearchByDateCtrl)
            .controller('RaceReplaysSearchByTrackCtrl', RaceReplaysSearchByTrackCtrl)
            .controller('RaceReplaysSearchByHorseCtrl', RaceReplaysSearchByHorseCtrl)
            .controller('RaceReplaysSearchByFavoriteCtrl', RaceReplaysSearchByFavoriteCtrl)
            .controller('HandicappingByUrlCtrl', HandicappingByUrlCtrl)

            .factory('HandicappingStoreFac', HandicappingStoreFac)
            .factory('HandicappingRaceReplaysFac', HandicappingRaceReplaysFac)

            .filter('trustedDependency', ['$sce', function ($sce) {
                return function (url) {
                    return $sce.trustAsResourceUrl(url);
                };
            }])

            .directive('handicappingStore', function () {
                return {
                    restrict: 'E',
                    scope: false,
                    controller: 'HandicappingStoreCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'js/desktop/modules/Handicapping/templates/handicapping-store.html'
                };
            })

            .directive('handicappingRaceReplays', function () {
                return {
                    restrict: 'E',
                    scope: false,
                    controller: 'HandicappingRaceReplaysCtrl',
                    controllerAs: 'HandicappingRaceReplays',
                    templateUrl: 'js/desktop/modules/Handicapping/templates/handicapping-race-replays.html'
                };
            })

            .directive('raceReplaysSearchByTrack', function () {
                return {
                    restrict: 'E',
                    scope: {
                        model: "="
                    },
                    controller: 'RaceReplaysSearchByTrackCtrl',
                    controllerAs: 'RaceReplaysSearchByTrack',
                    templateUrl: 'js/desktop/modules/Handicapping/templates/raceReplaysSearchByTrackDir.html'
                };
            })

            .directive('raceReplaysSearchByHorse', function () {
                return {
                    restrict: 'E',
                    scope: {
                        model: "="
                    },
                    controller: 'RaceReplaysSearchByHorseCtrl',
                    controllerAs: 'RaceReplaysSearchByHorse',
                    templateUrl: 'js/desktop/modules/Handicapping/templates/raceReplaysSearchByHorseDir.html'
                };
            })

            .directive('raceReplaysSearchByDate', function () {
                return {
                    restrict: 'E',
                    scope: {
                        model: "="
                    },
                    controller: 'RaceReplaysSearchByDateCtrl',
                    controllerAs: 'RaceReplaysSearchByDate',
                    templateUrl: 'js/desktop/modules/Handicapping/templates/raceReplaysSearchByDateDir.html'
                };
            })

            .directive('raceReplaysSearchByFavorite', function () {
                return {
                    restrict: 'E',
                    scope: {
                        model: "="
                    },
                    controller: 'RaceReplaysSearchByFavoriteCtrl',
                    controllerAs: 'RaceReplaysSearchByFavorite',
                    templateUrl: 'js/desktop/modules/Handicapping/templates/raceReplaysSearchByFavoriteDir.html'
                };
            });
    }
);

