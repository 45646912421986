"use strict";

define('RaceResultsPayoffEntity',[],function () {

    function RaceResultsPayoffEntity(payoffText) {
        this.text = payoffText;
    }

    return RaceResultsPayoffEntity;
});

