'use strict';

define(
    'TrackSelectorMod',[
        'TrackSelectorDir',
        'TrackSelectorCtrl',
        'TrackSelectorItemFactory',
        'TrackSelectorItemCurrentRaceFactory',
        'uibootstrap',
        'uibootstraptpls',
        'GTMMod'
    ],

    function TrackSelectorModule(TrackSelectorDir, TrackSelectorCtrl, TrackSelectorItemFactory, TrackSelectorItemCurrentRaceFactory) {
        angular.module('TVG.TrackSelector', [
            'ui.bootstrap',
            'TVG.Common.GTM'
        ])
            .factory('TrackSelectorItemFactory', TrackSelectorItemFactory)
            .factory('TrackSelectorItemCurrentRaceFactory', TrackSelectorItemCurrentRaceFactory)
            .controller('TrackSelectorCtrl', TrackSelectorCtrl)
            .directive('trackSelector', TrackSelectorDir);
    }
);

