'use strict';

define('RegistrationPage',[
    'RegistrationPageCtrl'
], function (RegistrationPageCtrl) {

    return angular.module('TVG.RegistrationPage', [])
        .controller('RegistrationPageCtrl', RegistrationPageCtrl)
        .directive('memberRegistration', function () {
            return {
                restrict: 'E',
                templateUrl: 'js/desktop/modules/RegistrationPage/templates/member-registration.html'
            };
        });
});

