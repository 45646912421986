'use strict';

define(
    'SeoMetaDescriptionDirective',[],function () {
        function SeoMetaDescriptionDir() {

            return {
                restrict: 'A',
                scope: {
                },
                link: function(scope, elem){

                    function _setContent(value){
                        elem.attr('content', value);
                    }

                    scope.$on('seo:page-meta-info:updated', function(event, newMetaInfo){
                        _setContent(newMetaInfo.metaTag);
                    });

                    (function _init(){
                        elem.attr('name', 'description');
                    }());
                }
            };
        }

        SeoMetaDescriptionDir.$inject = [
        ];

        return SeoMetaDescriptionDir;
    }
);


