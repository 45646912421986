'use strict';
define('MyBetsCurrentRaceInput',['RaceStatusUtils'], function(RaceStatusUtils){

    function MyBetsCurrentRaceInput(trackAbbr, perfAbbr, raceNumber, raceStatus, trackName, tvgRaceId) {
        this.trackAbbr = trackAbbr;
        this.perfAbbr = perfAbbr;
        this.raceNumber = raceNumber;
        this.raceStatus = raceStatus;
        this.trackName = trackName;
        this.tvgRaceId = tvgRaceId;
    }

    MyBetsCurrentRaceInput.prototype.showActiveBets = function(){
        return !RaceStatusUtils.hasRaceResults(this.raceStatus);
    };

    MyBetsCurrentRaceInput.prototype.closedForBetting = function(){
        return RaceStatusUtils.isClosedForBetting(this.raceStatus);
    };

    return MyBetsCurrentRaceInput;
});

