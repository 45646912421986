'use strict';

define('PreventDefaultDir',[],function () {

    function PreventDefaultDir() {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.bind('click', function (event) {
                    if (event) {
                        event.preventDefault();
                    }
                });
            }
        };
    }

    return PreventDefaultDir;
});

