"use strict";

define('RaceParamsEntityBuilder',['RaceParamsEntity'], function (RaceParamsEntity) {

    function builder(){
        var raceParams = new RaceParamsEntity();

        return {
            withRaceId: function (raceId) {
                raceParams.raceId = raceId;
                return this;
            },
            withTrackAbbr: function (trackAbbr) {
                raceParams.trackAbbr = trackAbbr;
                return this;
            },
            withPerfAbbr: function (perfAbbr) {
                raceParams.perfAbbr = perfAbbr;
                return this;
            },
            withRaceNumber: function (raceNumber) {
                raceParams.raceNumber = raceNumber;
                return this;
            },
            build: function () {
                return raceParams;
            }
        };
    }

    return builder;

});


