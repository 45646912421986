"use strict";

define(
    'RegistrationContainerCtrl',[
        "SecuritySvc",
        "CookieFac"
    ],
    function() {

        function RegistrationContainerCtrl($scope, $location) {

            var searchObject = $location.search();

            $scope.data = {
                promoCode: searchObject.promoCode || "",
                channelSource: searchObject.channelsource || "",
                campaignCode: searchObject.campaigncode || ""
            };

            //TODO: this needs to be passe to the registration component
            $scope.$emit('pageLoaded', 'registration');
        }

        RegistrationContainerCtrl.$inject = [
            '$scope',
            '$location'
        ];

        return RegistrationContainerCtrl;
    }
);

