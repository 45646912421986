'use strict';

define(
    'RunnerReplayModalFac',[],
    function () {

        function RunnerReplayModalFac($rootScope, $uibModal, FindModalContainerFac) {
            var resubmit = false;

            function _tryToOpenVideoReplaysForRunner(runner, scope) {
                if ($rootScope.userSession) {
                    _openRunnerReplaysModal(scope);
                }
                else {
                    $rootScope.$emit("callLoginRequiredModal");
                    resubmit = true;
                }
            }

            function _openRunnerReplaysModal(scope) {
                $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    windowTemplateUrl: 'js/desktop/modules/Races/templates/desktop/default-modal-window.html',
                    templateUrl: 'js/desktop/modules/Races/templates/desktop/race-replays-popup-inline.html',
                    controller: 'RaceReplaysNavigationCtrl',
                    windowClass: 'race-replay-modal',
                    backdrop: true,
                    scope: scope
                });
            }

            function _checkIfResubmitAndOpenRunnerReplaysModal(scope) {
                if (resubmit) {
                    _openRunnerReplaysModal(scope);
                    resubmit = false;
                }
            }

            return {
                tryToOpenVideoReplaysForRunner: _tryToOpenVideoReplaysForRunner,
                openRunnerReplaysModal: _openRunnerReplaysModal,
                checkIfResubmitAndOpenRunnerReplaysModal: _checkIfResubmitAndOpenRunnerReplaysModal
            };
        }

        RunnerReplayModalFac.$inject = [
            '$rootScope',
            '$uibModal',
            'FindModalContainerFac'
        ];

        return RunnerReplayModalFac;

    });

