'use strict';

define(
    'FavoriteTracks',[
        'FavoriteTracksFac'
    ],

    function (FavoriteTracksFac) {

        angular.module('TVG.FavoriteTracks', [])
            .factory('FavoriteTracksFac', FavoriteTracksFac);
    }
);

