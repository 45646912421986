'use strict';

define('OrdinalNumberStringFilter',[],function() {

    function _ordinalNumberStringFilter(number) {
        var ordinalNumber = String(number);

        // 11th, 12th, 13th
        if ([11, 12, 13].indexOf(number) === -1) {
            switch (number % 10) {
                // Handle 1st, 2nd, 3rd
                case 1:
                    ordinalNumber += 'st';
                    break;
                case 2:
                    ordinalNumber += 'nd';
                    break;
                case 3:
                    ordinalNumber += 'rd';
                    break;
                default:
                    ordinalNumber += 'th';
            }
        } else {
            ordinalNumber += 'th';
        }

        ordinalNumber += ':';

        return ordinalNumber;
    }

    return function() {
        return _ordinalNumberStringFilter;
    };
});

