'use strict';

define('RaceTrackCurrentBetsMod',[
        'RunnersSelectionCtrl',
        'RaceTrackCurrentBetsDir',
        'GenericMessageDir',
        'RunnersSelectionDir',
        'SelectionLegDir',
        'SelectionPositionAndNotKeyBoxDir',
        'SelectionSimpleOrKeyBoxDir',
        'OrdinalNumberStringFilter',
        'WordBreakOpportunityStringFilter',
        'GTMMod'
    ],
    function(RunnersSelectionCtrl, RaceTrackCurrentBetsDir, GenericMessageDir, RunnersSelectionDir, SelectionLegDir,
             SelectionPositionAndNotKeyBoxDir, SelectionSimpleOrKeyBoxDir, OrdinalNumberStringFilter,
             WordBreakOpportunityStringFilter) {

        /**
         * @ngdoc module
         * @name TVG.RaceTrackCurrentBetsMod
         * @require TVG.Security To show the login modal.
         *
         * @description
         * Race info sub-module: list of my bets placed for the current track and race selected in the program page.
         */
        angular.module('TVG.RaceTrackCurrentBetsMod', [
            'TVG.Security',
            'TVG.Common.GTM'
        ])
            .controller('RunnersSelectionCtrl', RunnersSelectionCtrl)
            .directive('raceTrackCurrentBets', RaceTrackCurrentBetsDir)
            .directive('genericMessage', GenericMessageDir)
            .directive('runnersSelection', RunnersSelectionDir)
            .directive('selectionLeg', SelectionLegDir)
            .directive('selectionPositionAndNotKeyBox', SelectionPositionAndNotKeyBoxDir)
            .directive('selectionSimpleOrKeyBox', SelectionSimpleOrKeyBoxDir)
            .filter('ordinalNumberString', OrdinalNumberStringFilter)
            .filter('wordBreakOpportunityString', WordBreakOpportunityStringFilter);
    }
);

