'use strict';

define(
    'BetSlipOpenerHelperDir',[],function () {
        function BetSlipOpenerHelperDir(BetSlipOpenerHelperFactory) {

            BetSlipOpenerHelperDir.$inject = [
              'BetSlipOpenerHelperFactory'
            ];

            return {
                restrict: 'A',
                controller: 'BetSlipOpenerHelperCtrl',
                scope: false, //Using false because i want to add the validateConditions function to $scope.events object of the "father"
                link: function(scope, element){
                    element.attr('window-route', '');
                    element.attr('window-callback', 'validateConditions');

                    BetSlipOpenerHelperFactory.getBetSlipUrl().then(function(betslipUrl){
                        element.attr('window-route', betslipUrl);
                        scope.data.isLoading = false;
                        scope.data.trackAbbr = BetSlipOpenerHelperFactory.getCurrentTrackAbbr();
                    });

                    scope.$on('BetSlipOpenerHelper::UpdateWindowRoute', function(event, params){
                        element.attr('window-route', params);
                    });
                }
            };
        }
        return BetSlipOpenerHelperDir;
    }
);

