"use strict";

define('FeaturesHandlerSvc',[],function () {

        function FeaturesHandlerSvc(FeaturesFac, UserPropertiesSvc, $rootScope) {

            var self = this;
            self.features = {
                activeFeatures: undefined
            };

            function _setActiveFeatures() {
                var userProperties = UserPropertiesSvc.getUserProperties();
                self.features.activeFeatures = FeaturesFac.getFeatures(userProperties);
                $rootScope.featureOverridesEnabled = FeaturesFac.areOverridesEnabled();
                $rootScope.activeFeatures = self.features.activeFeatures;
            }

            function _overrideFeatures() {
                FeaturesFac.setFeatures(self.features.activeFeatures);
                $rootScope.activeFeatures = self.features.activeFeatures;
            }

            function _changeFeatureState(featureName, featureState) {
                self.features.activeFeatures[featureName] = featureState;
            }

            function _getFeatures() {
                return self.features;
            }

            //listeners
            $rootScope.$on("logout", function () {
                _setActiveFeatures();
            });
            $rootScope.$on("user", function () {
                _setActiveFeatures();
            });
            // when user logs in or he already has a valid session
            $rootScope.$on("invalidSession", function () {
                _setActiveFeatures();
            });

            //public members
            this.overrideFeatures = _overrideFeatures;
            this.changeFeatureState = _changeFeatureState;
            this.getFeatures = _getFeatures;
            this.init = _setActiveFeatures;
        }

        FeaturesHandlerSvc.$inject = [
            'FeaturesFac',
            'UserPropertiesSvc',
            '$rootScope'
        ];

        return FeaturesHandlerSvc;

    }
);

