'use strict';

define('AccountMarketingCtrl',[],function () {
        function AccountMarketingCtrl($scope, $rootScope, UserProfileSvc, GTMFac, PreferencesLabelForGTM, AppRootFac) {

            (function init() {

                $scope.sectionLabel = "Communication Preferences";
                $scope.showResponseFeedback = false;
                $scope.canUpdatePref = false;
                $scope.loading = false;
                $scope.preferences = [];

                // Temporary - this will be stored in CMS as soon as it's working again
                $scope.availableSubscriptions = {
                    newsletters: {
                        title: "eNewsletter",
                        description: "The TVG Horseracing Insider is a bi-weekly eNewsletter that provides you with free picks, analysis, and insight from industry experts."
                    },
                    offersAnnouncements: {
                        title: "Special Offers and Announcements",
                        description: "Special offers and announcements including exclusive TVG offers, contests and promotions."
                    },
                    alerts: {
                        title: "Alerts",
                        description: "Breaking news regarding important information about your account and industry related news."
                    },
                    partnerNewsOffers: {
                        title: "Partner News and Offers",
                        description: "News and offers from TVG partners and affiliates"
                    }
                };

                $scope.events = {
                    subscribeAll: _subscribeAll
                };

                AppRootFac.createGTMandGA();

                var unbindWatch = $rootScope.$watch("user", _setUser);
                $scope.$on("$destroy", unbindWatch);
            }());

            $scope.UpdatePreferences = function () {
                $scope.loading = true;
                _checkGlobalOpt();
                _sendUpdatePreferencesEvent();
                $scope.canUpdatePref = false;
                UserProfileSvc.changeCommunicationsPreferences(
                    null,
                    $scope.preferences
                ).then(function () {
                        $scope.updateFeedback = _subscriptionsDisabled() ? 'Successfully unsubscribed from TVG Emails.' : 'Successfully subscribed to TVG Emails.';
                        $scope.updateFeedBackClass = 'success';
                    })
                    .catch(function () {
                        $scope.updateFeedback = 'An error has occurred processing your request';
                        $scope.updateFeedBackClass = 'error';
                    })
                    .finally(function () {
                        $scope.loading = false;
                        $scope.showResponseFeedback = true;
                    });
            };


            $scope._handleClick = function() {
                $scope.canUpdatePref = true;
                var subscriptionsKeys = Object.keys($scope.preferences.subscriptions);
                for (var i = 0 ; i < subscriptionsKeys.length; i++) {
                    $scope.preferences.subscriptions[subscriptionsKeys[i]] = $scope.preferences.globalEnabled;
                }

                var labelVal = $scope.preferences.globalEnabled ? ' | Yes' : ' | No';

                var p = {
                    gaEventCategory: 'Site Click',
                    gaEventAction: 'My Profile Page Click',
                    sectionName: GTMFac.Tvg4SectionName(),
                    screenName: GTMFac.Tvg4ScreenName(),
                    gaEventLabel: 'Communications | Subscribe to TVG Offer' + labelVal,
                    eventLabel: 'MyAccount_CommunicationsSubscribe'
                };


                GTMFac.GTMEvent().send($rootScope, 'siteClick', p);
            };

            function _watchOptions(subLabel, newValue, oldValue) {
                if(newValue === oldValue){ // prevent invokation on initial load of watch
                    return;
                }
                $scope.canUpdatePref = true;
                if(_subscriptionsDisabled()) {
                    $scope.preferences.globalEnabled = false;
                }
                else if(_anyActiveSubscription()) {
                    $scope.preferences.globalEnabled = true;
                }

                var labelValue = newValue ? ' | Yes' : ' | No';

                var eventData = GTMFac.gaEventBuilder()
                    .withSectionName(GTMFac.Tvg4SectionName())
                    .withScreenName(GTMFac.Tvg4ScreenName())
                    .withGaEventAction('My Profile Page Click')
                    .withGaEventCategory('Site Click')
                    .withTag(undefined)
                    .withModule('Communication Preferences')
                    .withMicroApp('non-Microapp')
                    .withGaEventLabel('Communications | ' + _processSubscriptionLabel(subLabel) + labelValue)
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
            }

            function _watchSubscriptions(subscriptionsKeys){
                for (var j = 0 ; j < subscriptionsKeys.length; j++) {
                    $scope.$watch('preferences.subscriptions.' + subscriptionsKeys[j] , _watchOptions.bind(null, subscriptionsKeys[j]));
                }
            }


            function _sendUpdatePreferencesEvent() {
                var gaEventLabel = '';
                var subs = Object.keys($scope.preferences.subscriptions);

                var enabledSubs = subs.filter(function (key) {
                    return $scope.preferences.subscriptions[key] === true;
                });

                for (var i = 0; i < enabledSubs.length; i++){
                    if(i === (enabledSubs.length -1)) {
                        gaEventLabel += _processSubscriptionLabel(enabledSubs[i]);
                        break;
                    }
                    gaEventLabel += _processSubscriptionLabel(enabledSubs[i]) + '||';
                }

                var eventData = GTMFac.gaEventBuilder()
                    .withSectionName(GTMFac.Tvg4SectionName())
                    .withScreenName(GTMFac.Tvg4ScreenName())
                    .withGaEventAction('My Profile Page Click')
                    .withGaEventCategory('Site Click')
                    .withTag(undefined)
                    .withModule('Communication Preferences')
                    .withMicroApp('non-Microapp')
                    .withGaEventLabel(gaEventLabel)
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
            }


            function _processSubscriptionLabel(label) {
                switch(label) {
                    case 'newsletters':
                        return 'eNewsLetter';
                    case 'offersAnnouncements':
                        return 'Special Offers';
                    case 'alerts':
                        return 'Alerts';
                    case 'partnerNewsOffers':
                        return 'Partner News';
                    default:
                        return label;
                }
            }

            /* istanbul ignore next */
            function _subscriptionsDisabled () {
                return Object.values($scope.preferences.subscriptions).every(function (element) {
                    return element === false;
                });
            }

            /* istanbul ignore next */
            function _anyActiveSubscription () {
                return Object.values($scope.preferences.subscriptions).indexOf(true) > -1;
            }

            /* istanbul ignore next */
            function _checkGlobalOpt() {
                if (!_anyActiveSubscription()) {
                    $scope.preferences.globalEnabled = false;
                }
            }

            function _setUser() {
                if (!$rootScope.user) {
                    return;
                }

                if (!$scope.user) {
                    $scope.user = $rootScope.user;
                }

                UserProfileSvc.getCommunicationsPreferences().then(function(res) {
                    if(res.hasOwnProperty('accountNumber')) {
                        $scope.preferences = _.omit(angular.copy(res), 'accountNumber');
                    }
                    else {
                        $scope.preferences = angular.copy(res);
                    }

                    _watchSubscriptions(Object.keys($scope.preferences.subscriptions));

                }).finally(function() {
                    $scope.loading = false;
                });
            }

            function _subscribeAll(preference) {
                $scope.preferences.globalEnabled = true;
                $scope._handleClick();
            }
        }

        AccountMarketingCtrl.$inject = [
            '$scope',
            '$rootScope',
            'UserProfileSvc',
            'GTMFac',
            'PreferencesLabelForGTM',
            'AppRootFac'
        ];

        return AccountMarketingCtrl;
    }
);

