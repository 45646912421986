'use strict';

define(
    'QuickAccessCtrl',[],
    function () {

        function QuickAccessCtrl($scope, $uibModal, $rootScope, $filter, PreferencesCookieFac, CookieFac, $timeout, $location, $route, $window, GTMFac, FindModalContainerFac) {

            $scope.quickDepositModal = null;
            var prefObj = PreferencesCookieFac.getPreferences();
            var defaultBalance = prefObj.balance_is_shown == 1 ? $rootScope.accountBalance || "ACCOUNT" : "ACCOUNT";
            var shouldShowBalance = prefObj.balance_is_shown === 1;

            var rootUrl = "/my-profile",
                destination = "reports";

            var redirectPageExceptions = {
                'BlackListedLocation': '/blocked-country'
            };

            $scope.events = {
                dismiss: _dismiss,
                openLogin: _openLogin,
                openRegistration: _openRegistration,
                stackView: _stackView,
                cancel: _cancel,
                openQuickDeposit: _openQuickDeposit,
                closeHelpModal: _closeHelpModal,
                gotoMyBets: _gotoMyBets,
                goToWatchLive: _goToWatchLive
            };

            $scope.data = {
                shouldShowBalance: shouldShowBalance,
                accountBalance: defaultBalance,
                gotoDeposit: false,
                confirmDialog: null,
                loginCloseEnabled: 'enabled',
                registrationPromoCode: $filter('CMSValue')('registrationPromoCode'),
                loginButtonLabel: 'Login',
                signupButtonLabel: 'Sign Up',
                accountButtonLabel: 'My Account',
                depositButtonLabel: 'Quick Deposit',
                betslipButtonLabel: 'Create Bet Ticket',
                watchLiveButtonLabel: $filter('CMSLabels')('tvgLiveButton', 'headerLabels')
            };

            if ($route.hasOwnProperty('current') && $route.current.originalPath == '/open-login') {
                $scope.events.openLogin();
            }

            $scope.$on("user", function () {
                $scope.userSession = true;
                $rootScope.userSession = true;

                if ($rootScope.showAccountPanel) {
                    $scope.events.stackView("js/desktop/modules/Account/templates/account.html", "Account");
                }

                if (!$rootScope.withdrawData.haveRequestedInfo) {
                    $rootScope.withdrawData.haveRequestedInfo = true;
                    $rootScope.refreshListPendingWithdraw();
                }
            });

            $scope.$on("$destroy", function () {
                _cancel();
            });

            $scope.$on("preferencesUpdated", function () {
                prefObj = PreferencesCookieFac.getPreferences();
                if (prefObj.balance_is_shown === 1 && $scope.data.accountBalance === "ACCOUNT") {
                    $scope.data.accountBalance = $rootScope.accountBalance || "ACCOUNT";
                    $scope.data.shouldShowBalance = true;
                } else if (prefObj.balance_is_shown === 0) {
                    $scope.data.accountBalance = "ACCOUNT";
                    $scope.data.shouldShowBalance = false;
                }
            });

            $scope.$on("loginError", _showLoginError);

            $scope.$on("logout", function () {
                $scope.data.accountBalance = "ACCOUNT";
                $rootScope.accountBalance = $scope.data.accountBalance;
                $rootScope.accountBalanceNumber = undefined;
                $rootScope.user = null;
                $rootScope.userSession = false;
                $scope.userSession = false;
            });

            $scope.$on("goto_new_deposit", function () {

                if ($rootScope.layout === 'desktop') {
                    var destPath = "/my-funds";
                    $scope.quickDepositModal.dismiss();
                    $location.path(destPath);
                }
                else {
                    $scope.quickDepositModal.dismiss();
                    var scope = $rootScope.$new();
                    scope.option = 'quick_deposit';
                    $uibModal.open({
                        appendTo: FindModalContainerFac('#angular-app'),
                        windowClass: 'account',
                        backdropClass: 'tvgmodal-backdrop',
                        windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                        template: "<stack-view data-root-url='js/desktop/modules/Account/templates/account.html' data-root-title='Account' data-url='js/desktop/modules/Account/templates/account-deposit.html' data-panel='Deposit'></stack-view>",
                        scope: scope
                    });
                }

            });

            $scope.$on("openHelp", function () {
                _openHelp();
            });

            $scope.$on("goto_quick_deposit", function () {
                _openQuickDeposit();
            });

            $scope.$on("accountBalance_changed", function () {
                if (prefObj.balance_is_shown === 1) {
                    $scope.data.accountBalance = $filter("currency")($rootScope.accountBalanceNumber, "$");
                    $scope.data.shouldShowBalance = true;
                } else {
                    $scope.data.accountBalance = "ACCOUNT";
                    $scope.data.shouldShowBalance = false;
                }
            });

            $scope.$on("sessionExpired", function () {
                $scope.data.errorMessage = "Your session has expired and you were automatically logged out. Please log in again.";
                $scope.data.errorMessageTitle = "Logout";

                $scope.confirmDialog = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    windowClass: 'login-error',
                    templateUrl: 'js/desktop/modules/Login/templates/login-error.html',
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    scope: $scope
                });
            });

            $scope.$on("CMSLoaded", function () {
                _checkFirstTimeHelp();
            });

            // Prevent login dropdown from closing
            $scope.$on("loadingChanged", function(e, value) {
                if(value) {
                    $scope.data.loginCloseEnabled = 'disabled';
                }
                else {
                    $scope.data.loginCloseEnabled = 'enabled';
                }
            });


            function _gotoMyBets() {
                if (!($location.path() === rootUrl && $location.hash() === destination)) {
                    $location.path(rootUrl).hash(destination);
                }

                $route.reload();
                $rootScope.$emit('collapsePanel');
            }

            function _init() {
                _checkFirstTimeHelp();
            }

            function _checkFirstTimeHelp() {
                var videoObj,
                    shouldOpenHelp = false;

                try {
                    videoObj = JSON.parse($filter('CMSValue')('globalHelpTutorialConfiguration'));

                    if (videoObj.showHelp && CookieFac.getCookie("hasSeenTutorial") != true && $rootScope.layout != "desktop" && $scope.$root.activeFeatures.helpTutorial) {
                        shouldOpenHelp = true;
                    }
                } catch (e) {
                    //console.log('[WARNING] Failed to load help video tutorial list');
                }
                if (shouldOpenHelp) {
                    _openHelp();
                }
            }

            function _openLogin() {
                $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    windowClass: 'login',
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    template: '<login name="loginModal"></login>'
                });
            }

            function _openRegistration() {
                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Page Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Header | Sign Up')
                    .withEventLabel('Header-SignUp')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));

                if($scope.data.registrationPromoCode) {
                    $rootScope.go('registration', {'promoCode': $scope.data.registrationPromoCode});
                }
                else {
                    $rootScope.go('registration');
                }
            }

            function _stackView(rootUrl, title) {

                $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    windowClass: 'account',
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    template: '<stack-view data-root-url=\"' + rootUrl + '\" data-root-title=\"' + title + '\"></stack-view>'
                });
            }

            function _openQuickDeposit() {
                $scope.quickDepositModal = $uibModal.open({
                    appendTo: FindModalContainerFac('#angular-app'),
                    windowClass: 'quick-deposit new-quick-deposit',
                    backdropClass: 'tvgmodal-backdrop',
                    windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                    templateUrl: 'js/desktop/modules/Account/templates/quick-deposit.html',
                    controller: 'QuickDepositCtrl'
                });

                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Header | Deposit')
                    .withEventLabel('Header-DepositFunds')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
            }

            function _goToWatchLive() {
                var eventData = GTMFac.gaEventBuilder()
                    .withGaEventAction(GTMFac.Tvg4ScreenName() + ' Click')
                    .withGaEventCategory('Site Click')
                    .withGaEventLabel('Header | Watch TVG Live')
                    .withEventLabel('Header-WatchTVGLive')
                    .build();

                GTMFac.GTMEvent().send($rootScope, 'siteClick', GTMFac.genericEvent(eventData));
                $window.open('/live');
            }

            function _openHelp() {

                if (!$rootScope.helpModal) {
                    $rootScope.helpModal = $uibModal.open({
                        appendTo: FindModalContainerFac('#angular-app'),
                        windowClass: 'first-time-help',
                        backdropClass: 'tvgmodal-backdrop',
                        windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                        templateUrl: 'js/desktop/modules/Account/templates/first-time-help.html',
                        controller: 'FirstTimeHelperCtrl',
                        scope: $scope
                    });
                }

            }

            function _dismiss() {
                $scope.$close();
            }

            function isToRedirectWithoutPopup(error) {
                return redirectPageExceptions.hasOwnProperty(error.messageKey);
            }

            function _showLoginError(type, error) {

                if ($scope.confirmDialog) {
                    return;
                }

                $rootScope.userSession = false;
                $scope.userSession = false;

                if(isToRedirectWithoutPopup(error)){
                    $location.path(redirectPageExceptions[error.messageKey]);
                } else {
                    if (error.messageKey === "ApiUserSessionTimedOut" || error.messageKey === "ApiUserSessionNotFound" || error.messageKey === "ApiMissingUserSessionToken") {
                        if (angular.isUndefined($rootScope.user) || $rootScope.user == null) {
                            //no message is needed
                            return;
                        }
                    }

                    $rootScope.user = null;
                    $scope.data.errorMessage = error.message;

                if(angular.isDefined(error.redirectUrl) && error.redirectUrl !== "") {
                    $scope.data.redirectUrl = error.redirectUrl;
                    $scope.confirmDialog = $uibModal.open({
                        appendTo: FindModalContainerFac('#angular-app'),
                        windowClass: 'login-error',
                        templateUrl: 'js/desktop/modules/Login/templates/login-error.html',
                        backdropClass: 'tvgmodal-backdrop',
                        windowTemplateUrl: 'js/common/modules/Utilities/templates/modal-window.html',
                        scope: $scope
                    });
                }
                else {
                    $rootScope.$broadcast('showLoginErrorMsg', $scope.data.errorMessage);
                }
            }
            }

            function _closeHelpModal(closed) {
                if ($rootScope.helpModal) {
                    $rootScope.helpModal.close();
                    $rootScope.helpModal = null;
                    if (closed) {
                        CookieFac.setCookie("hasSeenTutorial", true, {path: "/", expires: 365});
                    }
                }
            }


            function _cancel() {

                if ($scope.confirmDialog) {
                    $scope.confirmDialog.close();
                    $scope.confirmDialog = null;
                }

                //Redirect user if it is in the wrong app
                if ($scope.data.redirectUrl && $scope.data.redirectUrl != "") {
                    $window.location.href = $scope.data.redirectUrl;
                }

                $timeout(function () {
                    $scope.data.errorMessageTitle = null;
                    $scope.data.errorMessage = null;
                }, 300);
            }

            _init();

        }

        QuickAccessCtrl.$inject = [
            '$scope',
            '$uibModal',
            '$rootScope',
            '$filter',
            'PreferencesCookieFac',
            'CookieFac',
            '$timeout',
            '$location',
            '$route',
            '$window',
            'GTMFac',
            'FindModalContainerFac'
        ];

        return QuickAccessCtrl;
    }
);

