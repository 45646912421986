'use strict';

define('YoutubeVideoDir',[],function () {
    function youtubeVideoDir(YouTubeLoaderFac) {
        return {
            restrict: "E",
            scope: {
                height: "@",
                width: "@",
                videoid: "@"
            },
            template: '<div></div>',
            link: function (scope, element) {
                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                var player;

                YouTubeLoaderFac.whenLoaded().then(function () {
                    player = new YT.Player(element.children()[0], {
                        playerVars: {
                            autoplay: 1,
                            html5: 1,
                            theme: "light",
                            modesbranding: 0,
                            color: "white",
                            iv_load_policy: 3,
                            showinfo: 0,
                            controls: 0,
                            modestbranding: 1,
                            playsinline: 1
                        },
                        height: scope.height > 0 ? scope.height : element.parent().parent().height(),
                        width: scope.width > 0 ? scope.width : element.parent().parent().width(),
                        videoId: scope.videoid
                    });
                });

                scope.$watch('videoid', function (newValue, oldValue) {
                    if (newValue == oldValue) {
                        return;
                    }

                    player.cueVideoById(scope.videoid);

                });

                scope.$watch('height + width', function (newValue, oldValue) {
                    if (newValue == oldValue) {
                        return;
                    }

                    player.setSize(scope.width, scope.height);

                });
            }
        };
    }

    youtubeVideoDir.$inject = [
        'YouTubeLoaderFac'
    ];
    return youtubeVideoDir;
});
