'use strict';

define(
    'FeaturedTracks',[
        'FeaturedTracksDir',
        'FeaturedTrackElementDir',
        'FeaturedTracksCtrl',
        'FeaturedTracksFac',
        'GTMMod'
    ],

    function (FeaturedTracksDir, FeaturedTrackElementDir, FeaturedTracksCtrl, FeaturedTracksFac) {

        return angular.module('TVG.FeaturedTracks', [
            'TVG.Common.GTM'
        ])
            .controller('FeaturedTracksController', FeaturedTracksCtrl)
            .directive('featuredTracks', FeaturedTracksDir)
            .directive('featuredTrackElement', FeaturedTrackElementDir)
            .factory('FeaturedTracksFac', FeaturedTracksFac)
            .name;
    }
);

