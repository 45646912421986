'use strict';

define('Video',[
        'VideoPopOutFac'
    ],
    function (VideoPopOutFac) {
        angular.module('TVG.Video', [
                'TVG.Security'
            ])
            .factory('VideoPopOutFac', VideoPopOutFac);

    }
);

