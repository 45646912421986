/**
 * @constructor
 * @description
 * Type that represents the generic messages that appear in this table.
 */
"use strict";

define('GenericMessage',[],function () {

    function GenericMessage() {
        this.iconClass = null;
        this.message = null;
        this.showMessage = false;
    }

    return GenericMessage;

});

