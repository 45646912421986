'use strict';

define('BetsSvc',['lodash'],function (_) {

    function BetsSvc($http, $q, METADATA, $filter, $rootScope, GTMFac, stepSelectionsStringFilter, $cookies, ConfigurationFac, RequestContextHeaders, $timeout, $sce) {

        var wagerUUID = _generateUUID();
        var Bets = {
            transactionLog: function (msg) {
                var deferred = $q.defer();
                var wagerTransactionType = 158;

                var data = {
                    eventType: 'PlaceBet',
                    userId: $rootScope.user.accountNumber,
                    eventTime: (new Date()).toISOString(),
                    siteVersion: (($rootScope.layout === 'desktop') ? 'desktop' : 'mobile_web') + ' - ' + window.location.hostname,
                    transactionType: wagerTransactionType,
                    msg: msg
                };

                $http({
                    method: 'POST',
                    url: ConfigurationFac.getServiceApiUrl('log'),
                    data: data,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-tvg-context': RequestContextHeaders[clientApp + '_' + locationContext]
                    }
                }).success(function (response) {
                    deferred.resolve(response);
                }).error(function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            },

            placeBet: function (betTotalResults, race) {
                var asyncPlaceWagerHandler = $rootScope.activeFeatures.asyncPlaceWagerHandler;
                var newWagerEndpointToggle = $rootScope.activeFeatures.newWagerEndpoint;
                var betToPlace;
                var deferred = $q.defer();

                if (newWagerEndpointToggle) {
                    var uuid = wagerUUID;
                    var csrfToken = _generateUUID();
                    var repeat = betTotalResults.repeat ? betTotalResults.repeat : 1;
                    var regionDetails = null;
                    var location = null;
                    var repeatQueryString = '';
                    var selections = [];

                    for (var i=0; i < betTotalResults.BIs.length; i++) {
                        var tempArray = [];
                        for (var j=0; j < betTotalResults.BIs[i].length; j++) {
                            var bi = {
                                order: betTotalResults.BIs[i][j]
                            };
                            tempArray.push(bi);
                        }
                        selections.push(tempArray);
                    }

                    if(typeof $rootScope.regions !== 'undefined'){
                        regionDetails = $rootScope.regions;
                        location = $rootScope.location;
                    }

                    betToPlace = {
                        wagerReference: uuid,
                        track: race.trackAbbr,
                        race: race.raceNumber,
                        perf: race.perfAbbr,
                        amount: betTotalResults.betAmount,
                        price: parseFloat(betTotalResults.betCost),
                        type: betTotalResults.wagerCode,
                        selections: selections
                    };

                    var postData = {
                        wager: betToPlace,
                        regions: regionDetails,
                        location: location
                    };

                    if(repeat > 0){
                        repeatQueryString = '?wagersToBePlaced=' + repeat;
                    }

                    // Setting a cookie
                    $cookies.put('csrfToken', csrfToken);

                    var url = ConfigurationFac.getServiceApiUrl('wtx');

                    $http({
                        method: 'POST',
                        url: url + repeatQueryString,
                        data: postData,
                        headers: {
                            'Content-Type': 'application/json',
                            'X-XSRF-TOKEN' : csrfToken,
                            'x-tvg-context': RequestContextHeaders[clientApp + '_' + locationContext]
                        }
                    }).then(function successCallback(response) {
                        if(response.status == 202 && asyncPlaceWagerHandler){
                            if(response.headers('location') != null) {
                                var trustedResource = $sce.trustAsResourceUrl(response.headers('location'));
                                var checkUrl = $sce.valueOf(trustedResource);
                                _checkAsyncTimeout(checkUrl, $q.defer()).then(function () {
                                    wagerUUID = _generateUUID();
                                    deferred.resolve(betTotalResults);
                                });
                            } else {
                                // console.log("location header not set.");
                                wagerUUID = _generateUUID();
                                deferred.resolve(betTotalResults);
                            }

                        } else {
                            wagerUUID = _generateUUID();
                            deferred.resolve(betTotalResults);
                        }
                    }, function errorCallback(response) {
                        wagerUUID = _generateUUID();
                        //handle new endpoint errors
                        if (_.get(response, 'data.exception')) {
                            var error = {
                                errorCode: response.data.exception,
                                errorMessageKey: response.data.message
                            };
                            deferred.reject(error);
                            return;
                        }

                        deferred.reject(_parseErrorMessage(0, "Default")); //return the default error message
                    });

                    return deferred.promise;

                } else {

                    betToPlace = {
                        date: METADATA.raceDate,
                        perf: race.perfAbbr,
                        amount: betTotalResults.betAmount,
                        price: betTotalResults.betCost,
                        wageramount: betTotalResults.betAmount,
                        track: race.trackAbbr,
                        race: race.raceNumber,
                        type: betTotalResults.wagerId,
                        bi: betTotalResults.BIs,
                        otherAmt: "",
                        balance: "",
                        repeat: betTotalResults.repeat ? betTotalResults.repeat : 1
                    };

                    $http.get("/ajax/bet/betTicketInitData").then(function (response) {

                        if (response.data.csrf_token) {
                            betToPlace.token = response.data.csrf_token;
                        }
                        $http({
                            method: 'POST',
                            url: "/ajax/bet/wager/json",
                            data: betToPlace,
                            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: _tranformPostData
                        }).success(function (response) {
                            var errorCode = !response ? 0 : response.code || (response.result && response.result.code) || response.ErrorCode || 0;
                            var errorName = !response ? "" : response.name || (response.result && response.result.name) || response.ErrorMessage || "";

                            if (response && (!response.result && response.status && response.status.match(/error/i) ||
                                response.result && response.result.status && response.result.status.match(/error/i) ||
                                response.ErrorCode)) {
                                deferred.reject(_parseErrorMessage(errorCode, errorName));
                            } else if (response && response.result && response.result !== "") {
                                deferred.resolve(betTotalResults);
                            } else {
                                deferred.reject(_parseErrorMessage(0, "")); //return the default error message
                            }
                        }).error(function () {
                            var errorCode = !response ? 0 : response.code || (response.result && response.result.code) || response.ErrorCode || 0;
                            var errorName = !response ? "" : response.name || (response.result && response.result.name) || response.ErrorMessage || "";

                            deferred.reject(_parseErrorMessage(errorCode, errorName)); //return the default error message
                        });
                    });

                    $cookies.remove('wagerRef');

                    return deferred.promise;

                }
            },

            calculateBetTotal: function (oAmount, oWagerType, BIs) {
                var result = {},
                    betCount;

                BIs = $filter('filter')(BIs, function (elem) {
                    return elem !== undefined;
                });

                if (!(oAmount > 0.0 && BIs && BIs.length > 0)) {
                    result.betCount = 0;
                    result.betCost = '0.00';
                    result.wagerName = oWagerType.name;
                    result.wagerId = oWagerType.id;
                    result.wagersString = "";
                    result.BIs = BIs;
                    return result;
                }

                if (oWagerType.abbreviation == "QN") {

                    betCount = _calculateQuinellaTotalBets(BIs);

                } else if (oWagerType.abbreviation == "QNB") {

                    betCount = _calculateQuinellaBoxTotalBets(BIs);

                } else if (oWagerType.abbreviation == "QNW") {

                    betCount = _calculateQuinellaWheelTotalBets(BIs).length;

                } else if (oWagerType.isKey) {

                    betCount = _calculateKeysTotalBets(BIs, oWagerType.positionCount, oWagerType.isBox);

                } else if (oWagerType.isBox) {

                    var numberOfPositions = _getMinBisPerStep(oWagerType);
                    betCount = _calculateFectaBoxTotalBets(BIs, numberOfPositions);

                } else if (oWagerType.columnCount > 1 && oWagerType.legCount == 1) {

                    if (oWagerType.isWheel) {
                        betCount = _calculateFectaWheelsTotalBets(BIs).length;
                    } else {
                        betCount = _calculateFectaTotalBets(BIs).length;
                    }

                } else {
                    var betTypeMultiplier = _getWagerTypeMultiplier(oWagerType);
                    betCount = _calculateStraightAndLegsTotalBets(BIs) * betTypeMultiplier;
                }

                result.betCount = betCount;
                result.betAmount = oAmount;
                result.betCost = (betCount * oAmount).toFixed(2);
                result.wagerName = oWagerType.name;
                result.wagerId = oWagerType.id;
                result.wagerCode = oWagerType.abbreviation;
                result.wagersString = _getWagerString(oWagerType, BIs);
                result.BIs = BIs;

                return result;
            },

            buildWagerType: function (rawData) {
                return new WagerType(rawData.BetAmounts, rawData.MaxBetAmount, rawData.MinBetAmount, rawData.WagerTypeID);
            },

            apiResponseWagerTypesTransformer: function (wagerTypes, fromGraph) {
                return wagerTypes.map(function (wagerType) {
                    // because we have many templates / controllers depending on the actual WagerType constructor, let's keep this logic for now
                    if (fromGraph) {
                        var wagerTypeTransformed = wagerType;

                        wagerTypeTransformed.id = wagerType.type ? wagerType.type.id || null : null;
                        wagerTypeTransformed.name = wagerType.type ? wagerType.type.name || "" : "";
                        wagerTypeTransformed.abbreviation = wagerType.type ? wagerType.type.code || "" : "";

                        wagerTypeTransformed.betAmounts = wagerType.wagerAmounts ? wagerType.wagerAmounts.map(_formatBetAmount) : [];
                        wagerTypeTransformed.maxBetAmount = parseFloat(wagerType.maxWagerAmount);
                        wagerTypeTransformed.minBetAmount = parseFloat(wagerType.minWagerAmount);

                        if (wagerTypeTransformed.betAmounts.length) {
                            wagerTypeTransformed.betAmounts.push({
                                amountString: 'other',
                                amount: null
                            });
                        }

                        // delete unnecessary fields that come from graph
                        delete wagerTypeTransformed.type;
                        delete wagerTypeTransformed.wagerAmounts;
                        delete wagerTypeTransformed.minWagerAmount;
                        delete wagerTypeTransformed.maxWagerAmount;

                        return wagerTypeTransformed;
                    } else {
                        return Bets.buildWagerType(wagerType);
                    }
                }).sort(function (a, b) {
                    return parseInt(a.id, 10) - parseInt(b.id, 10);
                });
            },
            cancelBet: function (bet) {
                var cancelBetWtx = $rootScope.activeFeatures.cancelBetWtx;
                var deferred = $q.defer();
                var errorMessages = [];
                try {
                    errorMessages = angular.fromJson($filter('CMSValue')('wtxErrorMessages'));
                } catch (e) {};

                if (cancelBetWtx) {
                    var wagerReference = bet.wagerReference || '0';
                    var betId = bet.serialNumber || bet.id;
                    var queryString = '/' + wagerReference + '/' + betId;
                    var url = ConfigurationFac.getServiceApiUrl('wtx') + "/" + $rootScope.user.accountNumber + "/wagers";

                    $http({
                        method: 'DELETE',
                        url: url + queryString,
                        headers: {
                            'Content-Type': 'application/json',
                            'x-tvg-context': RequestContextHeaders[clientApp + '_' + locationContext]
                        }
                    }).success(function () {
                        deferred.resolve("OK");
                        $rootScope.$broadcast("accountBalance_update");
                    }).error(function (response) {
                        var errorCode = _.get(response, 'code');
                        var errorMessage = errorMessages[errorCode] || $filter('CMSValue')('betTicketErrorCancelingWager');

                        deferred.reject(Object.assign({}, response, {
                            errorMessage: errorMessage
                        }));
                    });

                    return deferred.promise;
                } else {
                    $http({
                        method: 'POST',
                        url: "/ajax/wager-history/report/wager/filter/",
                        data: {id: bet.id, date: bet.wagerDate},
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                        transformRequest: _tranformPostData
                    }).success(function (data) {
                        if (data && data.status.match(/success/i)) {
                            deferred.resolve(data);
                            $rootScope.$broadcast("accountBalance_update");
                        } else {
                            deferred.reject($filter('CMSValue')('betTicketErrorCancelingWager'));
                        }

                    }).error(function () {
                        deferred.reject($filter('CMSValue')('betTicketErrorCancelingWager'));
                    });

                    return deferred.promise;
                }
            }
        };



        function _checkAsyncTimeout(checkUrl, deferred){
            $timeout(function() {
                _checkAsyncWagers(checkUrl).then(function (){
                    deferred.resolve();
                }).catch(function(){
                    _checkAsyncTimeout(checkUrl, deferred);
                });
            }, (5000));

            return deferred.promise;
        }

        function _checkAsyncWagers(checkUrl){
            /*
             wagerStatus:
             PLACE_IN_PROGRESS
             PLACE_COMPLETED
             CANCEL_IN_PROGRESS
             CANCEL_COMPLETED
             */
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: checkUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'x-tvg-context': RequestContextHeaders[clientApp + '_' + locationContext]
                }
            }).then(function successCallback(response) {
                if(response.data.wagerSummary && response.data.wagerSummary.wagerStatus == 'PLACE_COMPLETED'){
                    deferred.resolve();
                } else {
                    deferred.reject();
                }
            }, function errorCallback() {
                deferred.reject();
            });
            return deferred.promise;
        }

        function _generateUUID() {
            var d = new Date().getTime();
            if(window.performance && typeof window.performance.now === "function"){
                d += performance.now(); //use high-precision timer if available
            }
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = (d + Math.random()*16)%16 | 0;
                d = Math.floor(d/16);
                return (c=='x' ? r : (r&0x3|0x8)).toString(16);
            });
            return uuid;
        }

        function _cartesianProductOf(matrix) {
            return matrix.reduce(function (a, b) {
                var ret = [];
                a.forEach(function (a) {
                    b.forEach(function (b) {
                        if (a.indexOf(b) < 0) {
                            ret.push(a.concat([b]));
                        }
                    });
                });
                return ret;
            }, [[]]);
        }

        function _calculateFectaTotalBets(BIs) {
            return _cartesianProductOf(BIs);
        }

        function _calculateFectaWheelsTotalBets(BIs) {
            var res = [],
                firstCol = [],
                selectionsLength = 0,
                otherCols = [],
                i;

            for (i = 0; i < BIs.length; i++) {
                var col = BIs[i];
                if (i == 0) {
                    firstCol = col;
                    selectionsLength = firstCol.length;
                } else {
                    otherCols.push(col);
                }
            }

            for (i = 0; i < selectionsLength; i++) {
                var fc = [firstCol[i]];
                var matrix = otherCols.slice(0);
                matrix.unshift(fc);
                res = res.concat(_cartesianProductOf(matrix));
            }
            return res;
        }

        function _calculateFectaBoxTotalBets(BIs, numOfPositions) {
            var selections = BIs[0].length,
                res = 1;
            for (var i = 0; i < numOfPositions; i++) {
                res *= selections;
                --selections;
            }
            return res;
        }

        function _calculateQuinellaTotalBets(BIs) {
            var selections = BIs[0].length,
                res = 1;
            if (selections <= 2) {
                for (var i = 0; i < 2; i++) {
                    res *= selections;
                    --selections;
                }
            } else {
                res = 0;
            }

            res /= 2;
            return res;
        }

        function _calculateQuinellaBoxTotalBets(BIs) {
            var selections = BIs[0].length,
                res = 1;
            if (selections >= 2) {
                for (var i = 0; i < 2; i++) {
                    res *= selections;
                    --selections;
                }
            } else {
                res = 0;
            }

            res /= 2;
            return res;
        }

        function _calculateQuinellaWheelTotalBets(BIs) {
            var res = [],
                firstCol = BIs[0],
                firstColLength = firstCol.length,
                secCol = BIs[1].slice(0),
                secColLength = secCol.length,
                alreadyUsedKeys = [];

            for (var i = 0; i < firstColLength; i++) {
                var fc = [firstCol[i]];
                var matrix = [[]];

                for (var j = 0; j < secColLength; j++) {
                    var sColItem = secCol[j];
                    var keyPar = (1 << sColItem ^ 1 << fc);

                    if (sColItem != fc && alreadyUsedKeys.indexOf(keyPar) < 0) {
                        matrix[0].push(sColItem);
                    }
                    alreadyUsedKeys.push(keyPar);
                }

                matrix.unshift(fc);
                res = res.concat(_cartesianProductOf(matrix));
            }
            return res;
        }

        function _calculateKeysTotalBets(BIs, numOfPositions, isBox) {
            var selections = 0,
                res = 1;

            if (BIs[0].length && BIs[1] !== undefined && BIs[1].length) {
                selections = BIs[1].length;
                for (var i = 0; i < (numOfPositions - 1); i++) {
                    res *= selections;
                    --selections;
                }

                if (isBox) {
                    res *= numOfPositions;
                }
            } else {
                res = 0;
            }
            return res;
        }

        function _calculateStraightAndLegsTotalBets(BIs) {
            var res = 1;
            for (var i = 0; i < BIs.length; i++) {
                var legSelections = BIs[i].length;
                res *= legSelections;
            }
            return res;
        }

        function _getWagerTypeMultiplier(wt) {
            var multiplier = 1;

            switch (wt.abbreviation) {
                case 'WP':
                case 'WS':
                case 'PS':
                    multiplier = 2;
                    break;
                case 'WPS':
                    multiplier = 3;
                    break;
                default:
                    break;
            }

            return multiplier;
        }

        function _getMinBisPerStep(wt) {
            var biPerStep = 1;

            switch (wt.abbreviation) {
                case 'QN':
                    biPerStep = 2;
                    break;
                case 'QNB':
                    biPerStep = 2;
                    break;
                case 'EXB':/* maybe not needed see line 149 'if (oWagerType.isBox)'*/
                    biPerStep = 2;
                    break;
                case 'TRB':/* maybe not needed see line 149 'if (oWagerType.isBox)'*/
                    biPerStep = 3;
                    break;
                case 'SUB':/* maybe not needed see line 149 'if (oWagerType.isBox)'*/
                    biPerStep = 4;
                    break;
                case 'H5B':
                    biPerStep = 5;
                    break;
                default:
                    break;
            }

            return biPerStep;
        }

        function _getWagerString(wt, wagers) {
            var wagerString = "";

            for (var i = 0; i < wt.columnCount; i++) {
                if (i != 0) {
                    wagerString += " | ";
                }

                if (wagers && wagers[i] && wagers[i].length) {

                    wagerString += stepSelectionsStringFilter(wagers[i]);
                } else {
                    wagerString += "?";
                }
            }

            if ($rootScope.user) {
                $rootScope.user.wagerString = wagerString;
                GTMFac.setCurrentSelectionList(wagers);
            }

            return wagerString;
        }

        function _formatBetAmount(amount) {
            return {
                amountString: $filter('currency')(amount, "$"),
                amount: amount
            };
        }

        function WagerType(betAmounts, maxBetAmount, minBetAmount, wagerTypeID) {
            this.id = wagerTypeID;
            if (!!METADATA.wagerTypes[this.id]) {
                this.betAmounts = betAmounts ? betAmounts.map(_formatBetAmount) : [];
                this.maxBetAmount = parseFloat(maxBetAmount);
                this.minBetAmount = parseFloat(minBetAmount);

                this.name = METADATA.wagerTypes[this.id].Name;
                this.abbreviation = METADATA.wagerTypes[this.id].Abbreviation;
                this.columnCount = parseInt(METADATA.wagerTypes[this.id].ColumnCount, 10);
                this.columnType = METADATA.wagerTypes[this.id].ColumnType || "Runners";
                this.isBox = METADATA.wagerTypes[this.id].IsBox;
                this.isKey = METADATA.wagerTypes[this.id].IsKey;
                this.legCount = METADATA.wagerTypes[this.id].LegCount;
                this.maxBIsPerLeg = METADATA.wagerTypes[this.id].MaxBIsPerLeg;
                this.minBIsPerLeg = METADATA.wagerTypes[this.id].MinBIsPerLeg;
                this.poolsPerBI = METADATA.wagerTypes[this.id].PoolsPerBI;
                this.positionCount = METADATA.wagerTypes[this.id].PositionCount;
                this.isWheel = (
                    this.abbreviation == "QNW" ||
                    this.abbreviation == "EXW" ||
                    this.abbreviation == "TRW" ||
                    this.abbreviation == "SUW" ||
                    this.abbreviation == "H5W"
                );

                if (this.betAmounts.length) {
                    this.betAmounts.push({
                        amountString: "other",
                        amount: null
                    });
                }
            }
        }

        function _tranformPostData(obj) {
            var str = [];
            for (var key in obj) {
                if (obj[key] instanceof Array) {
                    for (var idx in obj[key]) {
                        var subObj = obj[key][idx];
                        for (var subKey in subObj) {
                            str.push(encodeURIComponent(key) + "[" + parseInt(parseInt(idx, 10) + 1, 10) + "][]=" + encodeURIComponent(subObj[subKey]));
                        }
                    }
                }
                else {
                    str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
                }
            }
            return str.join("&");
        }

        function _parseErrorMessage(errorCode, errorName) {
            var errorMessageKey;

            if (errorName === "InsufficientFunds") {
                errorMessageKey = $filter('CMSValue')('betTicketInsufficientFunds');
            } else if (errorName === "RaceClosed") {
                errorMessageKey = $filter('CMSValue')('betTicketRaceClosed');
            } else if (errorName === "ToteInvalidWagerAmount" || errorName === "ToteWagerAmountTooLarge" || errorName === "ToteWagerAmountTooSmall") {
                errorMessageKey = $filter('CMSValue')('betTicketToteInvalidWagerAmount');
            } else if (errorName === "WagerBadRunner") {
                errorMessageKey = $filter('CMSValue')('betTicketWagerBadRunner');
            } else if (errorName === "InvalidWagerType") {
                errorMessageKey = $filter('CMSValue')('betTicketInvalidWagerType');
            } else if (errorCode == 619) {
                errorMessageKey = $filter('CMSValue')('apiUserSessionInvalid');
            } else {
                errorMessageKey = $filter('CMSValue')('betTicketGenericErrorMessage');
            }

            return {
                errorCode: errorCode,
                errorMessageKey: errorMessageKey
            };
        }

        return Bets;
    }

    BetsSvc.$inject = [
        '$http',
        '$q',
        'METADATA',
        '$filter',
        '$rootScope',
        'GTMFac',
        'stepSelectionsStringFilter',
        '$cookies',
        'ConfigurationFac',
        'RequestContextHeaders',
        '$timeout',
        '$sce'
    ];

    return BetsSvc;
});

