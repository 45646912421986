'use strict';

define(
    'ComingSoonSvc',[],
    function () {

        function ComingSoonSvc($rootScope, ContentFac) {
            var comingSoonSvc;
            var comingSoonFeatures = null;

            comingSoonSvc = {
                getComingSoonFeatures: function () {
                    if (!comingSoonFeatures) {
                        ContentFac.getNamespaceContent('ComingSoon').then(function (data) {
                            comingSoonFeatures = Object.keys(data).length ? _.filter(_.map(data, _buildSlides), 'visible') : [];
                            $rootScope.$emit('comingSoonFeatures:ready', comingSoonFeatures);
                        });
                        return [];
                    } else {
                        return comingSoonFeatures;
                    }
                }
            };

            function _buildSlides(data) {
                try {
                    data = JSON.parse(data);
                } catch (e) {
                    return null;
                }
                return data;
            }
            return comingSoonSvc;
        }

        ComingSoonSvc.$inject = [
            '$rootScope',
            'ContentFac'
        ];

        return ComingSoonSvc;
    });

