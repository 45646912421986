'use strict';

define('RaceInfo',[
    'RaceInfoFac',
    'RaceVideoFac',
    'RacesNavigationCtrl',
    'RaceUrlFac',
    'RaceLinkFilter',
    'RaceMtpStatusFac'
], function (RaceInfoFac, RaceVideoFac, RacesNavigationCtrl, RaceUrlFac, RaceLinkFilter, RaceMtpStatusFac) {

    return angular
        .module('TVG.RaceInfo', [])
        .factory('RaceInfoFac', RaceInfoFac)
        .factory('RaceVideoFac', RaceVideoFac)
        .factory('RaceUrlFac', RaceUrlFac)
        .factory('RaceMtpStatusFac', RaceMtpStatusFac)
        .filter('raceLink', RaceLinkFilter)
        .controller('RacesNavigationCtrl', RacesNavigationCtrl)
        .constant('racePerformances', [
            {perfAbbr:'Adv', fullPerf:'Advance'},
            {perfAbbr:'Day', fullPerf:'Day'},
            {perfAbbr:'Eve', fullPerf:'Evening'},
            {perfAbbr:'Fut', fullPerf:'Future'},
            {perfAbbr:'Mat', fullPerf:'Matinee'},
            {perfAbbr:'Ngt', fullPerf:'Night'},
            {perfAbbr:'Twi', fullPerf:'Twilight'}
        ])
        .name;
    }
);

