'use strict';

define('FeaturedTrackModel',[], function () {

    function FeaturedTrackModel(trackName, trackAbbr, perfAbbr, mtp, raceNumber, raceStatus, racePostTime, mtpDisplay, imageUrl) {
        this.trackName = trackName;
        this.trackAbbr = trackAbbr;
        this.perfAbbr = perfAbbr;
        this.mtp = mtp;
        this.raceNumber = raceNumber;
        this.raceStatus = raceStatus;
        this.racePostTime = racePostTime;
        this.mtpDisplay = mtpDisplay;
        this.imageUrl = imageUrl;
    }

    return FeaturedTrackModel;

});

