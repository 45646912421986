'use strict';

define('AccountSummarySvc',[
        'AccountSummaryDetailBuilder',
        'AccountSummaryCategoryBuilder',
        'lodash'
    ],
    function (AccountSummaryDetailBuilder, AccountSummaryCategoryBuilder, _) {
        function AccountSummarySvc($http, $q, ConfigurationFac, RequestContextHeaders) {
            function _createAccountCategoriesList(list) {
                return function (element) {
                    list.push(AccountSummaryCategoryBuilder()
                        .withId(element.id.toString())
                        .withLink(element.link || null)
                        .withTotal(element.total)
                        .withTransaction(element.transaction)
                        .build()
                    );
                };
            }

            function _createAccountCategoryDetailList(list) {
                return function (element) {
                    list.push(AccountSummaryDetailBuilder()
                        .withId(element.id ? element.id.toString() : element.transactionType ? element.transactionType.toString() : '')
                        .withAmount(element.amount)
                        .withDescription(element.description)
                        .withTransactionDate(new Date(element.transactionDate))
                        .build()
                    );
                };
            }

            function _transformDataIntoFormData(postData) {
                var formData = [];
                Object.keys(postData).forEach(function (key) {
                    var parameter = [key, "=", postData[key]].join("");
                    formData.push(parameter);
                });
                return formData.join("&");
            }

            /**
             * Used to parse the URL from the GKE UAS service that comes inside hyperlinks.
             * @param urlString
             * @returns {{protocol, hostname, port, path: (string|*|string), search, hash, host}}
             */
            function urlParser(urlString) {
                var a = document.createElement('a');
                a.href = urlString;

                return {
                    protocol: a.protocol,
                    hostname: a.hostname,
                    port: a.port,
                    path: a.pathname,
                    search: a.search,
                    hash: a.hash,
                    host: a.host
                };
            }

            /**
             * Builds a query string with the start and end dates to be used by the GKE account
             * summary micro service
             * @param dateObj {Date}
             * @param startOrEnd {String}
             * @returns {string}
             */
            function getDateUrlStringParams(dateObj, startOrEnd) {
                var s = startOrEnd === 'start' ? 'start' : 'end';

                return s + 'Year=' + dateObj.getFullYear() + '&' +
                    s + 'Month=' + parseInt(dateObj.getMonth() + 1) + '&' +
                    s + 'Day=' + dateObj.getDate();
            }

            return {
                getAccountSummaryCategoryDetailByDate: function (categoryId, startDate, endDate) {
                    var deferred = $q.defer();

                    if (_.isUndefined(categoryId) || _.isUndefined(startDate) || _.isUndefined(endDate)) {
                        deferred.reject("Invalid parameters");
                        return deferred.promise;
                    }

                    //BUILD URL
                    var url = "ajax/wager-history/transaction-details/_ID_/start/_startDate_/end/_endDate_"
                        .replace("_ID_", categoryId)
                        .replace("_startDate_", startDate)
                        .replace("_endDate_", endDate);

                    $http.get(url).then(function (response) {
                        //success
                        //handle success data
                        var categoryDetailsResponse = {
                            totalRecordCount: response.data.totalRecordCount,
                            categoryDetailsList: []
                        };

                        if (Array.isArray(response.data.accountTransactions)) {
                            response.data.accountTransactions.forEach(_createAccountCategoryDetailList(categoryDetailsResponse.categoryDetailsList));
                        }

                        deferred.resolve(categoryDetailsResponse);
                    }, function (response) {
                        //error
                        //handle err
                        deferred.reject(response.data);
                    });

                    return deferred.promise;
                },

                getAccountSummaryCategoryDetailByDateFromGke: function (url, page, pageSize) {
                    var deferred = $q.defer();

                    if (_.isUndefined(url) ) {
                        deferred.reject('You need to specify an URL to request');
                        return deferred.promise;
                    }

                    var urlParsed = urlParser(url);
                    var requestUrl = url;

                    requestUrl = requestUrl
                        .replace(/page=\d+/, 'page=' + page)
                        .replace(/itemsPerPage=\d+/, 'itemsPerPage=' + pageSize);

                    $http.get(requestUrl).then(function (response) {
                        var totalRecordCount = pageSize * response.data.totalPages;
                        var categoryDetailsResponse = {
                            categoryDetailsList: []
                        };

                        // quick hack so we correct the number of total records as the service doesn't return that anywhere
                        if (!response.data.nextPage) {
                            totalRecordCount = totalRecordCount - (pageSize - response.data.records.length);
                        }

                        categoryDetailsResponse.totalRecordCount = totalRecordCount;

                        if (Array.isArray(response.data.records)) {
                            response.data.records.forEach(_createAccountCategoryDetailList(categoryDetailsResponse.categoryDetailsList));
                        }

                        deferred.resolve(categoryDetailsResponse);
                    }, function (response) {
                        deferred.reject(response.data);
                    });

                    return deferred.promise;
                },

                getWagersByDateFromGke: function (url, page, pageSize) {
                    var deferred = $q.defer();

                    if (_.isUndefined(url) ) {
                        deferred.reject('You need to specify an URL to request');
                        return deferred.promise;
                    }

                    var urlParsed = urlParser(url);
                    var requestUrl = url;

                    requestUrl = requestUrl
                        .replace(/page=\d+/, 'page=' + page)
                        .replace(/itemsPerPage=\d+/, 'itemsPerPage=' + pageSize);

                    // TODO: after WRO is finished we don't need this anymore (just a $http.get(url) is enough
                    $http({
                        method: 'GET',
                        url: requestUrl,
                        headers: {
                            'Content-Type': 'application/json',
                            'x-tvg-context': RequestContextHeaders[clientApp + '_' + locationContext]
                        }
                    }).then(function (response) {
                        var totalRecordCount = pageSize * response.data.totalPages;
                        var categoryDetailsResponse = {
                            categoryDetailsList: []
                        };

                        // quick hack so we correct the number of total records as the service doesn't return that anywhere
                        if (!response.data.nextPage) {
                            totalRecordCount = totalRecordCount - (pageSize - response.data.records.length);
                        }

                        categoryDetailsResponse.totalRecordCount = totalRecordCount;
                        categoryDetailsResponse.categoryDetailsList = response.data.records;
                        deferred.resolve(categoryDetailsResponse);
                    }, function (response) {
                        deferred.reject(response.data);
                    });

                    return deferred.promise;
                },

                getAccountSummaryCategoriesByDate: function (startDate, endDate, gke) {
                    var deferred = $q.defer();
                    var startDateObj;
                    var endDateObj;
                    var url;
                    var headers;

                    if (_.isUndefined(startDate) || _.isUndefined(endDate)) {
                        deferred.reject('Invalid parameters');
                        return deferred.promise;
                    }

                    if (gke) {
                        startDateObj = new Date(startDate);
                        endDateObj = new Date(endDate);

                        url = ConfigurationFac.getServiceApiUrl('uas') + '/summary?' +
                            getDateUrlStringParams(startDateObj, 'start') + '&' +
                            getDateUrlStringParams(endDateObj, 'end');

                        $http.get(url).then(function (res) {
                            deferred.resolve(res.data);
                        }, function (err) {
                            deferred.reject(err);
                        });
                    } else {
                        url = 'ajax/account-summary';

                        headers = {
                            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                        };

                        $http({
                            method: 'POST',
                            url: url,
                            transformRequest: _transformDataIntoFormData,
                            data: {
                                report: 'summary',
                                startDate: startDate,
                                endDate: endDate
                            },
                            headers: headers
                        }).then(function (response) {
                            //success
                            //handle success data
                            var accountSummaryCategories = [];
                            if (Array.isArray(response.data.accountSummary)) {
                                response.data.accountSummary.forEach(_createAccountCategoriesList(accountSummaryCategories));
                            }
                            deferred.resolve(accountSummaryCategories);
                        }, function (response) {
                            //error
                            //handle err
                            deferred.reject(response.data);
                        });
                    }

                    return deferred.promise;
                }
            };
        }

        AccountSummarySvc.$inject = [
            '$http',
            '$q',
            'ConfigurationFac',
            'RequestContextHeaders'
        ];

        return AccountSummarySvc;
    });

