var namespaceList = [
    'Global',
    'Deposits',
    'Funds',
    'Login',
    'BetTicket',
    'Race',
    'PasswordRecovery',
    'ComingSoon',
    'VideoTutorials',
    'WagerRewards',
    'SeoData'
];

var cmsCache = {};
var __FEATURES = {};
var __METADATA = {};
var __CMSCONTENT = {};

/*
 Method to get Metadata
 */
function getMetaData(initInjector, $http, $q, $timeout) {
    var timeout;
    var url = '/ajax/meta-data';

    //Before angular bootstrap app we need to use injector
    if (initInjector !== null) {
        timeout = initInjector.get('$timeout');
    } else {
        //After angular bootstrap app we can use the loaded dependencies
        timeout = $timeout;
    }

    var counter = 0;
    var requestResult = $q.defer();
    var retries = 3;

    function requestMetaData() {
        $http.get(url, {headers: {'X-ClientApp': clientApp, Accept: 'application/json'}, timeout: 5000})
            .success(function (response) {
                if (typeof response == "object") {
                    // METADATA needs to be updated when day changes
                    requestResult.resolve(response);
                }
                else {
                    if (counter < retries) {
                        timeout(function () {
                            requestMetaData();
                        }, 200);
                        counter++;
                    }
                    else {
                        requestResult.reject();
                    }
                }
            })
            .error(function () {
                // IMPORTANT!!!
                // in case TVG3 is down, just resolve an empty object as METADATA.
                // every usage of METADATA inside TVG4 should be avoided and considered as DEPRECATED
                // the basic user flow (session, betting, wager history) is secured without METADATA already
                requestResult.resolve({});
            });
    }

    requestMetaData();
    return requestResult.promise;
}

function propertyIsDefined() {
    return function (prop) {
        return !_.isUndefined(prop);
    };
}

function omitAllProperties() {
    return function (feature) {
        return _.omit(feature, propertyIsDefined);
    };
}

function getCMSContent(initInjector, $q, $http) {

    var ContentFac = initInjector.get('ContentFac');
    var ConfigurationFac = initInjector.get('ConfigurationFac');
    var deferred = $q.defer();

    if (clientApp == "tvg4") {
        productContext = "tvg4";
        applicationContext = "desktop";

        ConfigurationFac.setApplicationContextItem('device', 'desktop');
    }

    ConfigurationFac.setApplicationContextItem('product', productContext);
    ConfigurationFac.setApplicationContextItem('application', applicationContext);
    ConfigurationFac.setApplicationContextItem('location', locationContext);

    ContentFac.fetchAllDataFromCMS(namespaceList, false)
        .then(function (data) {
            var requestFeatures = false;
            if (data._featureToggle) {
                var filteredFeatures = _.mapValues(defaultFeatures, omitAllProperties);
                __FEATURES = _.merge(filteredFeatures, sanitiseCmsFeaturesResponse(data._featureToggle));
                delete data._featureToggle;
            } else {
                if(typeof __TVG_GLOBALS__ === 'undefined' || !__TVG_GLOBALS__.FEATURES || !parseData(__TVG_GLOBALS__.FEATURES)) {
                    requestFeatures = true;
                    //request directly to capi
                    var url = ConfigurationFac.getServiceApiUrl('capi');
                    var brand =  locationContext == 'all' ? 'tvg' : locationContext;
                    url = url + '/featureToggles/legacy/' + productContext + '/' + applicationContext + '/' + brand;
                    $http.get(url, {headers: {Accept: 'application/json'}, timeout: 5000})
                        .success(function (response) {
                            __FEATURES = response.response;
                            deferred.resolve(data);
                        });
                }
                else{
                    __FEATURES = parseData(__TVG_GLOBALS__.FEATURES).response;
                }
            }

            if(!requestFeatures) {
                deferred.resolve(data);
            }
        }, function () {
            __FEATURES = defaultFeatures;
            deferred.resolve({});
        });

    return deferred.promise;
}

function sanitiseCmsFeaturesResponse(rawFeatures) {
    var sanitisedFeatures = {};
    _.forEach(rawFeatures, function (rawFeature, key) {
        var obj = {};
        _.attempt(function () {
            obj[key] = JSON.parse(rawFeature);
        });
        _.merge(sanitisedFeatures, obj);
    });
    return sanitisedFeatures;
}

function defaultMetadata() {
    return {
        "biColors": {},
        "raceClasses": {},
        "raceTypes": {},
        "surfaceTypes": {},
        "wagerTypes": {},
        "allTracks": {},
        "liveVideoSchedule": []
    };
}

function parseData(data) {
    var object;
    try {
        _.attempt(function () {
            object = JSON.parse(decodeURIComponent(data));
        });
    }
    catch (e) {
    }
    return object;
}

function _validateMetaData(metadata) {
    var valid = false;

    if (typeof(metadata) === 'string') {
        var metadataObject = parseData(metadata);

        if (typeof metadataObject === 'object') {
            valid = true;

            if (valid) {
                __METADATA = metadataObject;
            }
        }
    }

    return valid;
}

function _validateCMSContent(cmscontent) {
    var valid = false;

    if (typeof(cmscontent) === 'string') {
        var cmsObject = parseData(cmscontent);

        if (typeof cmsObject === 'object' && cmsObject.hasOwnProperty('response')) {
            Object.keys(cmsObject.response).forEach(function (namespace) {
                cmsCache[namespace] = cmsObject.response[namespace];
            });
            valid = true;
        }
    }

    return valid;
}

/**
 * Load script
 *
 * @param  {Promise} Promise              Promise class
 * @param  {String}  src                  File source
 * @param  {Object}  [opts]               Loading options
 * @param  {Object}  [opts.ignoreErrors]  Loading errors will be treated with success
 *                                        to prevent breaking the app startup when
 *                                        third-parties are blocked by addblocker
 *
 * @return {Promise}  Loading script promise
 */
function loadScript(Promise, src, opts) {

    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');

        /**
         * Reject error or ignore them (using resolver)
         * @param  {Object} [err]  Error
         */
        var ignoreOrReject = function (err) {
            return (opts || {}).ignoreErrors ?
                resolve() : reject(err);
        };

        /**
         * Script loading error (e.g. blocked by addblocker)
         */
        script.onerror = function (err) {
            return ignoreOrReject(err);
        };

        /**
         * Bind to script "state change" to check when file is loaded
         */
        script.onload = script.onreadystatechange = function () {
            if (!this.readyState || this.readyState == 'complete') {
                return resolve();
            }

            return ignoreOrReject();
        };

        script.async = true;
        script.src = src;

        document.getElementsByTagName('head')[0].appendChild(script);
    });
}

function _createGoogleMapsAPI(Promise, apiKey) {
    return loadScript(Promise, 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&libraries=places', {
        ignoreErrors: true
    });
}

function _loadOptimizely(Promise) {
    // ignore (don't know why?...)
    if (window.opener) {
        Promise.resolve();
    }

    return loadScript(Promise, '//cdn.optimizely.com/js/3131590850.js', {
        ignoreErrors: true
    });
}

/*
 Method to get all information needed to load bootstrap app
 */
function initializeApp() {

    /* Angular app initialization */
    var initInjector = angular.injector(['ng', 'ngCookies', 'TVG.Content', 'TVG.Configuration']);
    var $http = initInjector.get('$http');
    var $q = initInjector.get('$q');
    var $cookies = initInjector.get('$cookies');
    var ConfigurationFac = initInjector.get('ConfigurationFac');

    var ContentFac = initInjector.get('ContentFac');

    $http.defaults.headers.common = {'X-ClientApp': clientApp};

    var promisesList = [];
    var metaDataRequested = false;

    // Parse or request METADATA
    if (typeof __TVG_GLOBALS__ === 'undefined' && __TVG_GLOBALS__.METADATA || !_validateMetaData(__TVG_GLOBALS__.METADATA)) {
        __METADATA = defaultMetadata();
        promisesList.push(getMetaData(initInjector, $http, $q));
        metaDataRequested = true;
    }

    // Parse or request CMSCONTENT
    if (typeof __TVG_GLOBALS__ !== 'undefined' && __TVG_GLOBALS__.MESSAGES && _validateCMSContent(__TVG_GLOBALS__.MESSAGES)) {
        ContentFac.restoreCache(cmsCache);
    }

    // CMSContent call should be always made but it will be returned from cache if __CMSCONTENT is valid
    promisesList.push(getCMSContent(initInjector, $q, $http));

    $q.all(promisesList)
        .then(function (data) {
            if (data) {
                if (metaDataRequested) {
                    __METADATA = data[0];
                    __CMSCONTENT = data[1];
                }
                else {
                    __CMSCONTENT = data[0];
                }

                cmsCache = ContentFac.retrieveCache();
            }
        })
        .then(function () {
            var scripts = [
                _createGoogleMapsAPI($q, ConfigurationFac.getGoogleAPIKey('apikey'))
            ];

            if (_.get(__FEATURES, 'enableOptimizely.toggle')) {
                scripts.push(_loadOptimizely($q));
            }

            return $q.all(scripts);
        })
        .then(function () {
            bootApplication();
        }).catch(function (err) {
        console.error(err);
    });
}
;
define("initData", function(){});

