'use strict';

define(
    'HandicappingCtrl',[],
    function () {

        function HandicappingCtrl($scope, $filter) {
            $scope.handicappingTabs = [
                {
                    name: 'Handicapping Store',
                    src: 'js/desktop/views/handicapping/handicapping-store.html',
                    qaLabel: 'handicapping-store',
                    id: 'handicapping-store',
                    show: true
                },
                {
                    name: 'TVG Picks',
                    src: 'js/desktop/views/handicapping/talent-picks.html',
                    qaLabel: 'talentpicks',
                    id: 'talentpicks',
                    show: true
                }
            ];

            (function init() {
                $scope.handicappingTabs.splice(1, 0, {
                    name: 'Free Past Performances',
                    src: 'js/desktop/views/handicapping/handicapping-free-past-performances.html',
                    qaLabel: 'free-pp',
                    id: 'free-pp',
                    show: true
                });

                var tabId = 'racereplays';
                $scope.handicappingTabs.splice(3, 0, {
                    name: $filter('CMSLabels')(tabId, 'mainMenuLabels'),
                    src: 'js/desktop/views/handicapping/handicapping-race-replays.html',
                    qaLabel: 'handicappingRaceReplaysView',
                    id: tabId,
                    show: true
                });
            }());
        }

        HandicappingCtrl.$inject = [
            '$scope',
            '$filter'
        ];

        return HandicappingCtrl;
    }
);

