"use strict";

define(
    'AccountSummaryCategoryBuilder',[
        "AccountSummaryCategoryEntity"
    ],
    function (AccountSummaryCategoryEntity) {

        function builder() {
            var accountSummaryCategoryEntity = new AccountSummaryCategoryEntity();

            return {
                withId: function (id) {
                    accountSummaryCategoryEntity.id = id;
                    return this;
                },
                withLink: function (link) {
                    accountSummaryCategoryEntity.link = link;
                    return this;
                },
                withTotal: function (total) {
                    accountSummaryCategoryEntity.total = total;
                    return this;
                },
                withTransaction: function (transaction) {
                    accountSummaryCategoryEntity.transaction = transaction;
                    return this;
                },
                build: function () {
                    return accountSummaryCategoryEntity;
                }
            };
        }

        return builder;
    }
);

