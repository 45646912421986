'use strict';

define(
    'Help',[
        'HelpCtrl',
        'TutorialVideosCtrl',
        'TutorialVideosSvc',
        'ResourceCenterCtrl'
    ],

    function (HelpCtrl, TutorialVideosCtrl, TutorialVideosSvc, ResourceCenterCtrl) {

        angular.module('TVG.Help', [])
            .controller('HelpCtrl', HelpCtrl)
            .controller('TutorialVideosCtrl', TutorialVideosCtrl)
            .controller('ResourceCenterCtrl', ResourceCenterCtrl)
            .service('TutorialVideosSvc', TutorialVideosSvc)
            .directive('tutorialVideosItem', function () {
                return {
                    restrict: 'E',
                    scope: true,
                    templateUrl: 'js/desktop/modules/Help/templates/tutorial-videos-item.html'
                };
            });
    }
);

