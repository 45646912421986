'use strict';

define(
    'Widgets',[
        'WidgetsFac',
        'WidgetsCtrl'
    ],
    function (WidgetsFac, WidgetsCtrl) {

        angular.module('TVG.Widgets', [])

            .factory("WidgetsFac", WidgetsFac)

            .controller('WidgetsCtrl', WidgetsCtrl);
    }
);

