'use strict';

define('CMSPagesCtrl',[
		'Content'
	],
	function () {
		function CMSPagesCtrl($rootScope, $scope, ContentFac, WagerProfileFac, $routeParams, $route, $location) {

            $scope.page = '';

            var pageName = ($routeParams.cmsPageName || $route.current && $route.current.cmsPageName || "").toLowerCase();
            var wagerProfilePageName = (pageName + WagerProfileFac.getSessionOrGenericProfile().replace("PORT-","")).toLowerCase();
            var nameSpace = $route.current && $route.current.namespace || "";
            var className = $route.current && $route.current.class || '';

            $scope.className = 'loading';

            $scope.fetchData = function() {
                if($rootScope.activeFeatures.specificCMSMessages) {
                    ContentFac.getNamespaceMessagesContent(nameSpace, pageName).then(function (data) {
                        getContent(data);
                    }, function () {
                        $location.path('/');
                    });
                }
                else {
                    ContentFac.getNamespaceContent(nameSpace).then(function(data) {
                        getContent(data);
                    }, function () {
                        $location.path('/');
                    });
                }
            };
            
            function getContent(data) {
                var cmsPages = {};
                angular.forEach(data, function(content, name) {
                    cmsPages[name.toLowerCase()] = content;
                });
                
                $scope.page = cmsPages[wagerProfilePageName] || cmsPages[pageName] || "";

                $scope.$emit('pageLoaded', 'page');

                if($scope.page === 'needs_log_in') {
                    $scope.page = '';
                    $rootScope.$emit('callLoginRequiredModal', null, function() {
                        $location.path('/');
                    });
                }
                else if($scope.page === "") {
                    $location.path('/');
                }
                else {
                    $scope.className = className;
                }
            }

            $scope.$on('session', function() {
                $scope.fetchData();
            });

            $scope.$on('afterLogin', function() {
                $scope.fetchData();
            });

            $scope.$on('$destroy', function () {
                $location.hash('');
            });
		}

		return CMSPagesCtrl;
	}
);

