'use strict';

define('DraggableDir',[],function () {

    function DraggableDir($document) {
        return {
            restrict: 'A',
            scope: {
                left: '=',
                top: '='
            },
            link: function ($scope, $element) {

                var startX = $scope.left || 0,
                    startY = $scope.top || 0;

                var newElement = angular.element('<div class="draggable"></div>');

                $element.append(newElement);
                newElement.on("mousedown", function ($event) {
                    $event.preventDefault();

                    // To keep the last selected box in front
                    angular.element(document.querySelectorAll('.' + $element[0].className.split(' ')[0])).css("z-index", "1000");
                    $element.css("z-index", "1050");

                    startX = $event.pageX - $element[0].offsetLeft;
                    startY = $event.pageY - $element[0].offsetTop;
                    $document.on("mousemove", mousemove);
                    $document.on("mouseup", mouseup);
                });

                placeNode($element, startY, startX);

                function placeNode(node, top, left) {
                    node.css({
                        position: "absolute",
                        top: top + "px",
                        left: left + "px"
                    });
                }

                function mousemove($event) {
                    placeNode($element, $event.pageY - startY, $event.pageX - startX);
                }

                function mouseup() {
                    $document.off("mousemove", mousemove);
                    $document.off("mouseup", mouseup);
                }
            }
        };
    }

    DraggableDir.$inject = [
        '$document'
    ];

    return DraggableDir;
});

