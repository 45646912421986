'use strict';

define(
    'RaceReplaysGateway',[
        'HorseEntityBuilder', 'RaceReplayEntityBuilder', 'lodash'
    ],
    function (horseEntityBuilder, raceReplayEntityBuilder, _) {

        function RaceReplaysGateway($http, $q, TimeUtilFac) {

            function _buildApiUrlGetHorsesByName(horseName, maxRecords) {
                var MAX_RECORDS = 100;
                var url = 'ajax/race-replays/id/search/searchString/_horseName_/max/_max_';

                return url
                    .replace("_horseName_", horseName)
                    .replace("_max_", maxRecords || MAX_RECORDS);
            }

            function _buildApiUrlRacesReplaysByHorseName(horseName) {
                var url = 'ajax/race-replays/id/horse/name/_horseName_';

                return url
                    .replace("_horseName_", horseName);
            }

            function _buildApiUrlTrackReplaysDates(trackAbbr) {
                var url = 'ajax/race-replays/id/track-dates/trackabbr/_trackAbbr_';

                return url
                    .replace("_trackAbbr_", trackAbbr);
            }

            function _buildApiUrlTrackReplays(trackAbbr, raceDate) {
                var url = 'ajax/race-replays/track/_trackAbbr_/racedate/_raceDate_';

                return url
                    .replace("_trackAbbr_", trackAbbr)
                    .replace("_raceDate_", raceDate);
            }


            function _buildHorseEntitiesList(list) {
                return function (horse) {
                    var horseEntity = horseEntityBuilder()
                        .withHorseID(horse.id)
                        .withHorseName(horse.name)
                        .withHorseType(horse.type)
                        .build();

                    list.push(horseEntity);
                };
            }

            function _buildRacesReplaysEntitiesList(list) {
                return function (raceReplay) {
                    var raceReplayEntity = raceReplayEntityBuilder()
                        .withRaceDate(raceReplay.raceDate)
                        .withPostTime(raceReplay.postTime)
                        .withTrackName(raceReplay.trackName)
                        .withTrackAbbr(raceReplay.track)
                        .withProviderTrack(raceReplay.providerTrack)
                        .withRaceNumber(raceReplay.race)
                        .withPerfAbbr(raceReplay.perf)
                        .withRaceClass(raceReplay.class)
                        .withDistance(raceReplay.distance)
                        .withProvider(raceReplay.provider)
                        .withRaceTypeId(raceReplay.raceTypeId)
                        .build();

                    list.push(raceReplayEntity);
                };
            }

            function _buildDateObjectList(list) {
                return function (dateString) {
                    list.push(new Date(TimeUtilFac.getTimestamp(dateString)));
                };
            }

            function _getHorsesByName(horseName, maxRecords) {

                var url = _buildApiUrlGetHorsesByName(horseName, maxRecords);
                var deferred = $q.defer();

                $http.get(url, { cache: true }).then(function (apiResponse) {
                    var raceRawData = apiResponse.data;
                    var horseList = [];

                    _.forEach(raceRawData, _buildHorseEntitiesList(horseList));

                    deferred.resolve(horseList);
                }, function (e) {
                    deferred.reject(e);
                });

                return deferred.promise;
            }

            function _getRacesReplaysByHorseName(horseName) {
                var url = _buildApiUrlRacesReplaysByHorseName(horseName);
                var deferred = $q.defer();

                $http.get(url, { cache: true }).then(function (apiResponse) {
                    var raceRawData = apiResponse.data;
                    var raceReplaysList = [];

                    _.forEach(raceRawData, _buildRacesReplaysEntitiesList(raceReplaysList));

                    deferred.resolve(raceReplaysList);
                }, function (e) {
                    deferred.reject(e);
                });

                return deferred.promise;
            }

            function _getTrackReplaysDates(trackAbbr) {
                var url = _buildApiUrlTrackReplaysDates(trackAbbr);
                var deferred = $q.defer();
                $http.get(url, { cache: true }).then(function (apiResponse) {
                    var trackDatesRawData = apiResponse.data;
                    var dateList = [];
                    _.forEach(trackDatesRawData, _buildDateObjectList(dateList));
                    deferred.resolve(dateList);
                }, function (e) {
                    deferred.reject(e);
                });

                return deferred.promise;
            }

            function _getRacesReplaysByTrackAndDate(trackAbbr, raceDate) {
                var url = _buildApiUrlTrackReplays(trackAbbr, raceDate);
                var deferred = $q.defer();

                $http.get(url, { cache: true }).then(function (apiResponse) {
                    var raceRawData = apiResponse.data;
                    var raceReplaysList = [];

                    _.forEach(raceRawData, _buildRacesReplaysEntitiesList(raceReplaysList));

                    deferred.resolve(raceReplaysList);
                }, function (e) {
                    deferred.reject(e);
                });

                return deferred.promise;
            }

            return {
                getHorsesByName: _getHorsesByName,
                getRacesReplaysByHorseName: _getRacesReplaysByHorseName,
                getTrackReplaysDates: _getTrackReplaysDates,
                getRacesReplaysByTrackAndDate: _getRacesReplaysByTrackAndDate

            };
        }

        RaceReplaysGateway.$inject = [
            '$http',
            '$q',
            'TimeUtilFac'
        ];


        return RaceReplaysGateway;
    }

);

