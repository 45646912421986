'use strict';

define('StackViewDir',[],function () {

    function StackViewDir($timeout, GTMFac) {
        return {
            restrict: 'E',
            transclude: true,
            scope: true,
            link: function ($scope, elem) {
                var _attributes = elem[0].attributes,
                    mainTitle = _attributes.getNamedItem('data-root-title').value,
                    $elem = angular.element(elem),
                    content = $elem.find("content")[0],
                    messageTimeout;

                $scope.stack = [];
                $scope.panel = _attributes.getNamedItem('data-root-url').value;

                $scope.index = 0;
                $scope.title = $scope.panel;
                $scope.name = mainTitle || "Settings";
                $scope.msgType = "";
                $scope.msgText = "";
                $scope.msgHidden = true;

                $scope.push = function (templateUrl, attr) {
                    content.scrollTop = 0;
                    var prevPanel = {
                        panel: $scope.panel,
                        name: $scope.name
                    };

                    $elem.removeClass("backward");
                    $elem.addClass("forward");
                    // Store previous panel
                    $scope.stack.push(prevPanel);

                    // This is my current panel (displayed on stack view)
                    $scope.name = attr.title;
                    $scope.index = $scope.index + 1;
                    $scope.panel = templateUrl;
                    $scope.options = attr.Objs;
                };

                $scope.pop = function (event) {
                    if (event && event.target !== undefined && event.target.parentElement !== undefined && event.target.parentElement !== undefined &&
                        event.target.parentElement.tagName === 'HEADER') {

                        var p = {};
                        p.event = 'backButton';
                        p.gaEventCategory = 'Account';
                        p.gaEventAction = 'Back Button';
                        p.sectionName = 'My Account';

                        GTMFac.GTMEvent().send($scope, 'backButton', p);
                    }

                    if ($scope.index > 0) {
                        $scope.option = null;
                        content.scrollTop = 0;
                        $elem.removeClass("forward");
                        $elem.addClass("backward");
                        $scope.index = $scope.index - 1;

                        var prevPanel = $scope.stack.pop();

                        $scope.panel = prevPanel.panel;
                        $scope.name = prevPanel.name;
                    }

                    if ($scope.index == 0) {
                        $scope.name = mainTitle;
                    }
                    return $scope.panel;
                };
                $scope.dismiss = function () {
                    $scope.$close();
                };

                $scope.dismissNotification = function () {
                    $scope.msgHidden = true;
                };

                $scope.notify = function (type, message, sticky) {
                    if (!sticky) {
                        $timeout.cancel(messageTimeout);
                    }

                    $scope.msgType = type;
                    $scope.msgText = message;
                    $scope.msgHidden = false;

                    if (!sticky) {
                        messageTimeout = $timeout(function () {
                            $scope.msgHidden = true;
                        }, 3000);
                    }

                };

                if (_attributes.getNamedItem('data-url') && _attributes.getNamedItem('data-panel')) {
                    var templateToShow = {
                        url: _attributes.getNamedItem('data-url').value,
                        title: _attributes.getNamedItem('data-panel').value
                    };

                    $scope.push(templateToShow.url, templateToShow);
                }
            }
            ,
            templateUrl: 'js/desktop/modules/Account/templates/stackView.html'
        };
    }

    StackViewDir.$inject = ['$timeout', 'GTMFac'];

    return StackViewDir;
});

