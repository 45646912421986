"use strict";

define('LocationUnreloadFac',['Mediator'], function (mediator) {

        function LocationUnreloadFac($route, $rootScope, $location, $routeParams) {

            function _updateRouteParams(newParams) {
                angular.forEach(newParams, function (value, key) {
                    $route.current.pathParams[key] = value;
                    $route.current.params[key] = value;
                    $routeParams[key] = value;
                });
            }

            function _pathChange(path, keepPathInHistory, newParams, eventList) {

                if ($location.url() === path) return;
                if(eventList && !angular.isArray(eventList)) {
                    eventList = [eventList];
                }

                var routeToKeep = $route.current;
                var deRegister = $rootScope.$on('$locationChangeSuccess', function () {
                    if (routeToKeep) {
                        $route.current = routeToKeep;
                        routeToKeep = null;
                    }
                    _updateRouteParams(newParams);

                    //Add route change event
                    mediator.dispatch("UPDATE_ROUTER", {
                        payload: $location.url(path)
                    });
                    
                    if (eventList) {
                        eventList.forEach(function(event) {
                            $rootScope.$emit(event);
                        });
                    }

                    deRegister();
                });

                $location.url(path);

                if (!keepPathInHistory)
                    $location.replace();
            }

            return {
                pathChange: _pathChange
            };
        }

        LocationUnreloadFac.$inject = [
            '$route',
            '$rootScope',
            '$location',
            '$routeParams'
        ];

        return LocationUnreloadFac;

    }
);

