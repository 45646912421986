"use strict";

define('RaceUrlFac',['RaceParamsEntityBuilder', 'lodash'],function(RaceParamsEntityBuilder, _){

    /*
    * This fac will be use to handle races url decode and encode
    * */
    function RaceUrlFac($location, $route, $rootScope){


        /*
            this function will look to route params to calculate trackAbbr, and raceNumber
         */
        function _getRacesInfoFromRouteParams() {
            var trackAbbr = _.get($route, 'current.params.trackAbbr') || '';
            var trackName = _.get($route, 'current.params.trackName') || '';
            var raceNumber = _.get($route, 'current.params.race') || '';
            var perfAbbr = 'Day'; // Since perfAbbr was remove from the url, use a default one
            if (typeof raceNumber === "string") {
                raceNumber = raceNumber.replace('race=',"");
            }

            var raceId = trackAbbr + '_' + raceNumber;

            if (trackName){
                trackName = trackName.split('-').join(' ');
            }
            return {
                raceId: raceId,
                trackAbbr: trackAbbr,
                trackName: trackName,
                raceNumber: raceNumber,
                perfAbbr: perfAbbr
            };
        }

        /**
         * This function uses the '$location.url' function instead of the '$routeParams' service
         * to get the new URL parameters' values.
         * This is useful when we want to get these values before a route change occurs,
         * '$routeChangeStart' and '$routeChangeSuccess' events.
         *
         * @returns {Object} route params that match the 'racetracks' route.
         */
        function _getRouteParamsForRaceBasedOnLocationPath(location) {
            var routeParams = {};
            var currentPathArray = location ? location.split(/[\?/]+/) : $location.url().split(/[\?/]+/);
            routeParams.trackAbbr = currentPathArray[2];
            routeParams.trackName = currentPathArray[3];
            if(currentPathArray[4] == 'autoplay'){
                routeParams.autoplay = currentPathArray[5];
                routeParams.race = currentPathArray[6];
            } else {
                routeParams.race = currentPathArray[4];
            };
            return routeParams;
        }

    function _getRouteParamsForRace(trackAbbr, trackName, raceNumber, autoplay) {
            if(trackName) {
                trackName = trackName.trim().replace(/\s/g,"-").replace(/-+/g,"-").toLowerCase();
            }

            return {
                autoplay: autoplay ? autoplay.toString() : undefined,
                trackName: trackName,
                trackAbbr: trackAbbr,
                race: raceNumber
            };
        }

        function _getRoute(raceTypeCode) {
            if (raceTypeCode && raceTypeCode === "G") {
                return '/greyhounds/';
            }
            return $rootScope.isGreyhoundRace ? '/greyhounds/' : '/racetracks/';
        }

        function _calculateRacePath(trackAbbr, trackName, raceNumber, autoplay, raceTypeCode) {
            var routeParams = _getRouteParamsForRace(trackAbbr, trackName, raceNumber, autoplay);
            //removes apostrophes for URL
            var trackName = routeParams.trackName && routeParams.trackName.includes("'") ? routeParams.trackName.replace(/'/g, '') : routeParams.trackName;
            if (routeParams.trackAbbr && routeParams.trackName) {
                return (_getRoute(raceTypeCode) + routeParams.trackAbbr +
                "/" + trackName)
                    .concat(autoplay ? '/autoplay/' + autoplay.toString() : '')
                    .concat(raceNumber ? '?race=' + raceNumber.toString(): '');
            }
            //responsible for the redirect
            return _getRoute(raceTypeCode) + "unknown/unknown/unknown/unknown";
        }

        function _generateRacePath(trackAbbr, trackName, raceNumber, autoplay) {
            if(trackName) {
                trackName = trackName.trim().replace(/\s/g,"-").replace(/-+/g,"-").toLowerCase();
            }

            if (trackAbbr && trackName) {
                return (_getRoute() + trackAbbr + "/" + trackName)
                .concat(autoplay ? '/autoplay/' + autoplay.toString() : '')
                .concat(raceNumber ? '?race=' + raceNumber.toString(): '');
            }

            return _getRoute() + "unknown/unknown/unknown";
        }

        return {
            getRouteParamsForRace: _getRouteParamsForRace,
            getRouteParamsForRaceBasedOnLocationPath: _getRouteParamsForRaceBasedOnLocationPath,
            getRacesInfoFromRouteParams: _getRacesInfoFromRouteParams,
            calculateRacePath: _calculateRacePath,
            generateRacePath: _generateRacePath,
        };
    }

    RaceUrlFac.$inject = [
        '$location',
        '$route',
        '$rootScope'
    ];

    return RaceUrlFac;

});

