'use strict';
//TODO we need to select only one way to handle times and dates
define('RaceMtpDisplay',[], function () {

    return function () {
        return {
            calcMtpDisplay: function (postTime, raceMtp) {
                var mtp = raceMtp;

                function timeText() {
                    if (mtp <= 60) {
                        return mtp + ' MTP';
                    }
                    return moment(postTime).format('hh:mm A');
                }

                function getMtpStatus() {
                    var mtpStatusValues = ['near', 'med', 'far', 'time-format'];
                    if (mtp <= 5) {
                        return mtpStatusValues[0];
                    } else if (mtp <= 15) {
                        return mtpStatusValues[1];
                    } else if (mtp <= 60) {
                        return mtpStatusValues[2];
                    }

                    return mtpStatusValues[3];
                }

                return {
                    mtpStatus: getMtpStatus(),
                    timeText: timeText()
                };
            }
        };

    };
});

