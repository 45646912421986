'use strict';

define('SeoLifeCycleManagerSvc',['lodash'], function (_) {

    function SeoLifeCycleManagerSvc($rootScope, PageMetaInfoFac, CurrentPageMetaInfoSingleton, $location) {
        var _active = false;
        var _removeLocationChangeListener = _.noop;

        function _stop(){
            if(!_active){
                return;
            }
            _removeLocationChangeListener();
            _active = false;
        }

        function _start() {

            if (_active) {
                return;
            }

            _removeLocationChangeListener = $rootScope.$on("$locationChangeSuccess", function () {
                PageMetaInfoFac.createBasedOnUrl($location.path())
                    .then(function (pageMetaInfo) {
                        CurrentPageMetaInfoSingleton.instance = pageMetaInfo;
                        $rootScope.$broadcast('seo:page-meta-info:updated', pageMetaInfo);
                    });
            });

            _active = true;
        }

        return {
            start: _start,
            stop: _stop
        };
    }

    SeoLifeCycleManagerSvc.$inject = [
        '$rootScope',
        'PageMetaInfoFac',
        'CurrentPageMetaInfoSingleton',
        '$location'
    ];

    return SeoLifeCycleManagerSvc;
});

