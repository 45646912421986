'use strict';

define('MyBetsTableDir',[],function() {
    return function MyBetsTableDir() {
        return {
            restrict: 'E',
            templateUrl: 'js/desktop/modules/MyBets/templates/my-bets-table.html',
            controller: 'MyBetsTableCtrl',
            scope: {
                startDate: '=',
                endDate: '=',
                trackAbbr: '=',
                betStatus: '=',
                url: '='
            },
            link: function (scope) {
                scope.$watch('trackAbbr', scope.reloadBets);
                scope.$watch('betStatus', scope.reloadBets);
            }
        };
    };
});

