'use strict';

define('RaceTimeformCtrl',[
    'lodash',
    'RaceTimeForm'
], function (_, RaceTimeForm) {

    function RaceTimeformCtrl($scope, $rootScope, ProgramPageRunnersFac, TrackPerformanceProvider, $filter, RaceUrlFac) {

        var vm = this;
        var eventRemovers = [];

        vm.model = {
            userLoggedIn: $rootScope.userSession ? $rootScope.userSession : false,
            runners: [],
            handicappingAvailable: false,
            hideComponent: true,
            equibaseBetButton: "",
            showEquibaseBetButton: false
        };

        vm.events = {
            handleBetSlipOpening: _handleBetSlipOpening,
            getBetSlipUrlForThisRunner: _getBetSlipUrlForThisRunner,
            getEquibaseBet: _getEquibaseBet,
            calcEquibaseBetButton: _calcEquibaseBetButton,
            showEquibaseBetButton: _showEquibaseBetButton,
            getTimeFormLabel: _getTimeFormLabel
        };

        function _showEquibaseBetButton() {
            return vm.model.showEquibaseBetButton && vm.model.handicappingAvailable;
        }

        function _handleBetSlipOpening(openWindowCallback) {
            if (!vm.model.userLoggedIn) {
                $rootScope.$emit("callLoginRequiredModal", openWindowCallback);
            } else {
                openWindowCallback();
            }
        }

        function _getBetSlipUrlForThisRunner(runnerId) {
            var routeParams = RaceUrlFac.getRacesInfoFromRouteParams();
            if( !routeParams.trackAbbr ||
                !routeParams.perfAbbr  ||
                !routeParams.raceNumber){
                //"TrackAbbr, PerfAbbr and RaceNumber must be defined!!"
                return "/betslip/";
            }

            var url = "/betslip/{trackAbbr}/{perfAbbr}/{raceNumber}?betselections={selectedHorse}&fromRunnerClick=true" + ($rootScope.isGreyhoundRace ? '&greyhounds' : '');
            return url
                .replace("{trackAbbr}", routeParams.trackAbbr)
                .replace("{perfAbbr}", routeParams.perfAbbr)
                .replace("{raceNumber}", routeParams.raceNumber)
                .replace("{selectedHorse}", runnerId);
        }

        function _getTimeFormLabel(dataSource) {
            switch (dataSource) {
                case 'EQB': return  $filter('CMSValue')('timeformLabel');
                case 'SRW': return  $filter('CMSValue')('skyRacingTimeformLabel');
                case 'GSA': return  $filter('CMSValue')('timeformLabelGSA');
                case 'USGSA': return $filter('CMSValue')('timeformLabelUSGSA');
                case 'RAS': return $filter('CMSValue')('timeformLabelRAS');
                default: return "Timeform 1-2-3";
            }
        }

        function _setRunners() {
            var handicappedRunners = ProgramPageRunnersFac.runners
                .filter(function (runner) {
                    return !_.isUndefined(runner.handicappingInfo.snapshot_freepick_info) &&
                           !_.isUndefined(runner.handicappingInfo.snapshot_freepick_info.pick);
                })
                .map(function (runner) {
                    return new RaceTimeForm(runner, runner.handicappingInfo);
                })
                .sort(_comparePick);

            if (_.isArray(handicappedRunners) && handicappedRunners.length > 0) {
                vm.model.runners = handicappedRunners;
                vm.model.hideComponent = false;
                _calcEquibaseBetButton(vm.model.runners);
            } else {
                vm.model.hideComponent = true;
            }
        }

        function _equibaseBetButtonConfig(validRunners) {
            return validRunners.length > 1 ? {text: $filter('CMSValue')('equibaseBetExactaButton'), type: 120 } :
                                             {text: $filter('CMSValue')('equibaseBetShowButton'), type: 30};
        }

        function _isSameWagerType(searchType) {
            return function(type) {
                return type.id === searchType;
            };
        }

        function _isEquibaseBetAvailable(buttonConfig) {
            var routeParams = RaceUrlFac.getRacesInfoFromRouteParams();
            var race = TrackPerformanceProvider.getRace(routeParams.raceNumber);

            return _.isUndefined(race) || race.raceOpenForBetting === false ? false : _.find(race.wagerTypes, _isSameWagerType(buttonConfig.type));
        }

        function _calcEquibaseBetButton(runners) {
            var validRunners = _getValidRunnersIDs(runners);
            var buttonConfig = _equibaseBetButtonConfig(validRunners);

            if (validRunners.length > 0 && _isEquibaseBetAvailable(buttonConfig)) {
                vm.model.equibaseBetButton = buttonConfig.text;
                vm.model.showEquibaseBetButton = true;
            } else {
                vm.model.equibaseBetButton = "";
                vm.model.showEquibaseBetButton = false;
            }
        }

        function _getValidRunnersIDs(runners) {
            return _.reduce(runners, function (result, runner) {
                if (!_.includes(result, runner.horseNumber) && !runner.isScratched) {
                    result.push(runner.horseNumber);
                }
                return result;
            }, []).sort(function (a, b) {
                return a - b;
            });
        }

        function _calcEquibaseWagerType(runners) {
            // 30 is show bet
            // 120 is Exacta box bet
            return runners.length > 1 ? 120 : 30;
        }

        function _makeEquibaseBet() {
            var validRunners = _getValidRunnersIDs(vm.model.runners);
            return 'bettype=' + _calcEquibaseWagerType(validRunners) + '&betselections=' + validRunners;
        }

        function _getEquibaseBet() {
            var routeParams = RaceUrlFac.getRacesInfoFromRouteParams();
            if( !routeParams.trackAbbr ||
                !routeParams.perfAbbr  ||
                !routeParams.raceNumber){
                //"TrackAbbr, PerfAbbr and RaceNumber must be defined!!"
                return "/betslip/";
            }

            return "/betslip/{trackAbbr}/{perfAbbr}/{raceNumber}?{betAction}"
                .replace("{trackAbbr}", routeParams.trackAbbr)
                .replace("{perfAbbr}", routeParams.perfAbbr)
                .replace("{raceNumber}", routeParams.raceNumber)
                .replace("{betAction}", _makeEquibaseBet());
        }

        function _setLoading(bool) {
            vm.model.handicappingAvailable = bool;
        }

        function _comparePick(obj, obj2) {
            return obj.pickNumber < obj2.pickNumber ? -1 : 1;
        }

        /**
         * $scope events
         */
        $scope.$on('user', function () {
            vm.model.userLoggedIn = $rootScope.userSession;
        });

        $scope.$on('Hadicapping::Information', function () {
            _setLoading(true);
            _setRunners();
        });

        $scope.$on('$destroy', function () {
            _.forEach(eventRemovers, function (remove) {
                remove();
            });
        });

        /**
         * $rootScope events
         */
        eventRemovers.push($rootScope.$on('race:Changed', function () {
            _setLoading(false);
        }));

        eventRemovers.push($rootScope.$on('raceChangedLoaded', function () {
            _setRunners();
        }));

        eventRemovers.push($rootScope.$on('trackDataUpdated', function () {
            _calcEquibaseBetButton(vm.model.runners);
        }));
    }

    RaceTimeformCtrl.$inject = [
        '$scope',
        '$rootScope',
        'ProgramPageRunnersFac',
        'TrackPerformanceProvider',
        '$filter',
        'RaceUrlFac'
    ];

    return RaceTimeformCtrl;

});

