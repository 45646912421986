"use strict";

define('ExternalComponentsSvc',[],
    function() {

        function externalComponentsSvc() {

            // module exports
            // (let us mock methods from this service)
            var module = {};

            /**
             * series
             * Given an array of functions, it will call every function,
             * once at a time, sequentially.
             * Every function will have a trigger function as its last argument,
             * that should be called when the function is done.
             * If arguments are given to this trigger function, those will be passed
             * to the next function.
             *
             * @example
             *     series([
                *         function doSomething(next) {
                *             //when it's done
                *             next(argument1, arguments2)
                *         },
                *         function doSomethingElse(arg1, arg2, next) {
                *             // do something with the arguments,
                *             // and when it's done:
                *             next();
                *         },
                *         function doAnotherThing(next) {
                *             // because we didn't give arguments to this function
                *             // only the 'next' is available
                *             // when done.
                *             next();
                *         }
                *     ]);
                *
                *
                * @param   {Array} fns Array of functions
                */
            var cachedComponent;
        
            var series = function (fns) {
                (function next() {
                    var args = Array.prototype.slice.call(arguments);
                    fns.length && fns.shift().apply(this, args.concat(next));
                }());
            };
        
            var parseJSON = function(json) {
                var data = {};
                var error = null;
        
                try { data = JSON.parse(json); }
                catch (e) {
                    error = e;
                }
        
                return error ? error : data;
            };
        
            function getURL(path) {
                return path;
            }
        
            function request(url, callback) {
                var oReq = new XMLHttpRequest();
        
                oReq.addEventListener('error', callback);
                oReq.addEventListener('load', function () {
                    var data = parseJSON(this.responseText);
                    return (data instanceof Error)
                        ? callback(data)
                        : callback(null, data);
                });
                oReq.open('GET', url);
                oReq.setRequestHeader('Accept', 'application/json');
                oReq.setRequestHeader('Content-Type', 'application/json');
        
                oReq.send();
            }
        
            var createLink = function (url) {
                var link = document.createElement('link');
                link.setAttribute('rel', 'stylesheet');
                link.setAttribute('href', getURL(url));
                document.head.appendChild(link);
                return link;
            };
        
            var createScript = function (url, cb) {
                var script = document.createElement('script');
                script.setAttribute('src', getURL(url));
                script.onload = function() { cb(); };
                script.onerror = function (e) { cb(e); };
                document.body.appendChild(script);
                return script;
            };
        
            var createHTML = function (str) {
                var fragment = document.createDocumentFragment();
                if (str) { fragment.innerHTML = str; }
                cachedComponent = fragment;
                return fragment;
            };
        
            var append = function (manifest, cb) {
                manifest.css.map(createLink);
        
                // Prepare an array of functions
                var tasks = manifest.js.map(function (url) {
                    return function(next) {
                        if (next instanceof Error) { return cb(next); }
                        return createScript(url, next);
                    };
                });
        
                tasks = tasks.concat(function (next) {
                    if (next instanceof Error) { return cb(next); }
        
                    cb(null);
                    next();
                });
        
                series(tasks);
            };
        
            module.loadComponent = function loadComponent(url, callback, $timeout) {
                if(cachedComponent) {
                    // deffer call for later time so the html from widget to be created
                    $timeout(function () {
                        callback(null, cachedComponent);
                    });
                } else {
                    request(getURL(url), function (err, data) {
                        if (err) { return callback(err); }
                        append(data, function () {
                            return callback(null, createHTML(data.html));
                        });
                    });
                }
            };

            // Public methods
            return module;
        }

    return externalComponentsSvc;
});

