"use strict";

define('StableEntity',[],function () {

    function StableEntity(id, name, entityType, subscriptions, inMyStable) {
        this.id = id;
        this.name = name;
        this.entityType = entityType;
        this.subscriptions = subscriptions;
        this.inMyStable = inMyStable;
    }


    return StableEntity;

});


