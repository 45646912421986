'use strict';

define(
    'RaceResultsUseCase',[],
    function () {

        /***
         *
         * @param RaceResultsGateway
         * @constructor
         */
        function RaceResultsUseCase(RaceResultsGateway) {

            /***
             *
             * @param racePerfAbbr
             * @param raceTrackAbbr
             * @param raceNumber
             * @param raceDate
             * @param raceTypeId
             * @returns {*}
             */
            this.getRaceResults = function (racePerfAbbr, raceTrackAbbr, raceNumber, raceTypeId, raceDate) {

                var getRaceResultsInput = {
                    perfAbbr: racePerfAbbr,
                    trackAbbr: raceTrackAbbr,
                    raceNumber: raceNumber,
                    raceDate: raceDate,
                    raceTypeId: raceTypeId
                };

                return RaceResultsGateway.getRaceResults(getRaceResultsInput)
                    .then(function (raceResults) {
                        return raceResults;
                    });

            };

            this.getRaceRunnersList = function (racePerfAbbr, raceTrackAbbr, raceNumber, raceTypeId, raceDate) {

                var getRaceRunnersList = {
                    perfAbbr: racePerfAbbr,
                    trackAbbr: raceTrackAbbr,
                    raceNumber: raceNumber,
                    raceDate: raceDate,
                    raceTypeId: raceTypeId
                };

                return RaceResultsGateway.getRaceRunnersList(getRaceRunnersList);
            };

            this.getRaceInfo = function (racePerfAbbr, raceTrackAbbr, raceNumber, raceTypeId, raceDate) {

                var getRaceInfo = {
                    perfAbbr: racePerfAbbr,
                    trackAbbr: raceTrackAbbr,
                    raceNumber: raceNumber,
                    raceDate: raceDate,
                    raceTypeId: raceTypeId
                };

                return RaceResultsGateway.getRaceRunnersList(getRaceInfo)
                    .then(function (raceResults) {
                        return raceResults.runners;
                    });

            };

            this.doesRaceHaveReplays = function (racePerfAbbr, raceTrackAbbr, raceNumber, raceTypeId, raceDate) {
                return this.getRaceResults(racePerfAbbr, raceTrackAbbr, raceNumber, raceTypeId, raceDate)
                    .then(function (raceResult) {
                        return raceResult.hasReplays;
                    }, function () {
                        return false;
                    });
            };
        }

        RaceResultsUseCase.$inject = [
            'RaceResultsGateway'
        ];

        return RaceResultsUseCase;
    }
);

