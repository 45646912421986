"use strict";

define('RacesPageGTMFac',["lodash"], function (_) {
    function RacesPageGTMFac(GTMFac, $rootScope) {
        function _clickRacesViewOrder(params) {
            var gtmEvent = {
                event: "siteClick",
                gaEventCategory: "Site Click",
                gaEventAction: "View Type Selected",
                gaEventLabel: params.showByTime ? "By Time" : "By Track",
                module: "Filter Section",
                tag: undefined,
            };

            GTMFac.GTMEvent().send($rootScope, "siteClick", gtmEvent);
        }

        function _clickRacesAddToFavFeaturedTrack(params) {
            var gtmEvent = {
                event: "siteClick ",
                gaEventCategory: "Site Click",
                gaEventAction: "Add to Favorites Success",
                gaEventLabel: params.trackName,
                module: "Featured Tracks",
                tag: undefined,
            };

            GTMFac.GTMEvent().send($rootScope, "siteClick", gtmEvent);
        }

        function _clickNavigateToRace(params) {
            var gtmEvent = {
                event: "navigation",
                gaEventCategory: "Navigation",
                gaEventAction: "Navigated To",
                gaEventLabel:
                    params.trackName +
                    " - " +
                    params.raceNumber +
                    " - " +
                    params.mtp,
                module: params.originComponent,
                destinationUrl: params.raceUrl,
                tag: params.trackName,
            };

            GTMFac.GTMEvent().send($rootScope, "navigation", gtmEvent);
        }

        return {
            clickRacesViewOrder: _clickRacesViewOrder,
            clickRacesAddToFavFeaturedTrack: _clickRacesAddToFavFeaturedTrack,
            clickNavigateToRace: _clickNavigateToRace,
        };
    }

    RacesPageGTMFac.$inject = ["GTMFac", "$rootScope"];

    return RacesPageGTMFac;
});

