"use strict";

define('FeaturesConfigController',[],function () {

    function FeaturesConfigController($scope, FeaturesHandlerSvc) {

        $scope.onFeatureStateChange = function (name, state) {
            FeaturesHandlerSvc.changeFeatureState(name, state);
            FeaturesHandlerSvc.overrideFeatures();
        };

        var features = FeaturesHandlerSvc.getFeatures();
        $scope.features = [];

        for (var key in features.activeFeatures) {
            $scope.features.push({
                name: key,
                value: features.activeFeatures[key]
            });
        }
    }

    FeaturesConfigController.$inject = [
        '$scope',
        'FeaturesHandlerSvc'
    ];

    return FeaturesConfigController;
});

