'use strict';

define(
    'ComingSoonHomepageDir',[],
    function () {
        function ComingSoonHomepageDir() {
            return {
                restrict: 'E',
                scope: true,
                templateUrl: 'js/desktop/modules/ComingSoon/templates/coming-soon-homepage.html',
                link: function ($scope) {
                    $scope.carousel = {
                        interval: 5000,
                        activeSlide: 1,
                        wrap: false
                    };
                },
                controller: 'ComingSoonCtrl'
            };
        }

        return ComingSoonHomepageDir;
    }
);

