"use strict";

define('RaceRunnerEntity',[],function () {

    function RaceRunnerEntity(biNumber, owner, horse, odds, oddsML, med, trainer, weight, jockey, age, nColor, sColor, scratched, dam, sire) {
        this.bettingInterestNumber = biNumber;
        this.horse = horse;
        this.owner = owner;
        this.odds = odds;
        this.oddsML = oddsML;
        this.med = med;
        this.trainer = trainer;
        this.weight = weight;
        this.jockey = jockey;
        this.age = age;
        this.numberColor = nColor;
        this.saddleColor = sColor;
        this.scratched = scratched;
        this.dam = dam;
        this.sire = sire;
    }


    return RaceRunnerEntity;

});

