"use strict";

define('RaceResultsRunnersListDirectiveCtrl',["lodash"], function(_) {
    /***
     * This Controller is binded to the race-results-desktop directive
     * Inherits raceId, racePerfAbbr and raceTrackAbbr from the parent isolated scope
     * @constructor
     */
    function RaceResultsRunnersListDirectiveCtrl(
        RaceResultsUseCase,
        RunnerReplayModalFac,
        $rootScope,
        GraphPastRaces,
        WagerProfileFac,
        $timeout
    ) {
        var vm = this,
            scope;

        vm.isLoading = true;

        vm.displayRunnerMed = function(med) {
            return _.isString(med) && med.trim().length > 0 ? med : "-";
        };

        vm.showVideoReplaysForRunner = function(runner) {
            scope = $rootScope.$new();
            scope.selectedRunnerForReplays = runner;
            if (runner.horse) {
                scope.selectedRunnerForReplays.horseName = runner.horse;
            }
            RunnerReplayModalFac.tryToOpenVideoReplaysForRunner(runner, scope);
        };

        var wagerProfileDisattach = $rootScope.$on("wagerprofile", function() {
            RunnerReplayModalFac.checkIfResubmitAndOpenRunnerReplaysModal(
                scope
            );
            wagerProfileDisattach();
        });

        function _getDecimalOddsValue(oddsString) {
            oddsString = oddsString || "";
            var oddsArr = oddsString.split("/");
            var odds = {};
            odds.Numerator = oddsArr[0];

            if (oddsArr.length > 1) {
                odds.Denominator = oddsArr[1];
            }

            var decimal = odds.Denominator
                ? odds.Numerator / odds.Denominator
                : odds.Numerator;
            if (isNaN(decimal)) {
                decimal = Number.MAX_VALUE;
            }
            return parseFloat(decimal);
        }

        function _getLowestOdd (arr) {
            var lowestOdd = Number.MAX_VALUE;
            var myOdd = Number.MAX_VALUE;
            
            for (var i = 0; i < arr.length; i += 1) {
                if (!arr[i].scratched) {
                    myOdd = _getDecimalOddsValue(arr[i].odds);
                
                    if (myOdd < lowestOdd) {
                        lowestOdd = myOdd;
                    }
                }
            }
            
            return lowestOdd;
        }

        function _setFavorites() {
            for (var i = 0; i < vm.runners.length; i++) {
                var runner = vm.runners[i];
                if (runner.favorite) {
                    if (runner.scratched) {
                        // favorite runner is scratched go find new favorite from lowest odds
                        break;
                    } else {
                        // favorite runner not scratched, no need to find new favorite
                        return;
                    }
                }
            }
            var lowerOdd = _getLowestOdd(vm.runners);

            vm.runners.forEach(function(runner) {
                var odd = _getDecimalOddsValue(runner.odds);
                if (odd === lowerOdd && odd !== 99) {
                    runner.favorite = true;
                }
            });
        }

        function _getOddString(odd) {
            if (!odd || (!odd.numerator && !odd.denominator)) {
                return "-";
            } else if (!odd.numerator || !odd.denominator) {
                return (odd.numerator
                    ? odd.numerator
                    : odd.denominator
                ).toString();
            } else {
                return odd.numerator + "/" + odd.denominator;
            }
        }

        function _getRaceRunnersListByGraph(
            raceDate,
            raceTrackAbbr,
            raceNumber
        ) {
            var wagerProfile = WagerProfileFac.getSessionOrGenericProfile();

            GraphPastRaces.getRacesByDateAndTrackAndRace(
                wagerProfile,
                raceDate,
                raceTrackAbbr,
                raceNumber
            )
                .then(function(response) {
                    if (
                        response.length &&
                        vm.raceNumber === response[0].raceNumber
                    ) {
                        var pastRace = response[0];
                        var runners = [];

                        if (pastRace.bettingInterests) {
                            pastRace.bettingInterests.forEach(function(bi) {
                                bi.runners.forEach(function(runner) {
                                    runners.push({
                                        bettingInterestNumber: runner.runnerId,
                                        numberColor: bi.numberColor,
                                        favorite: bi.favorite,
                                        saddleColor: bi.saddleColor,
                                        oddsML: _getOddString(bi.morningLineOdds),
                                        odds: _getOddString(bi.currentOdds),
                                        age: runner.age,
                                        dam: runner.dam,
                                        damSire: runner.damSire,
                                        horse: runner.horseName,
                                        jockey: runner.jockey,
                                        med: runner.med,
                                        owner: runner.ownerName,
                                        scratched: runner.scratched,
                                        sex: runner.sex,
                                        sire: runner.sire,
                                        trainer: runner.trainer,
                                        weight: runner.weight,
                                        dob: runner.dob
                                    });
                                });
                            });
                        }

                        vm.runners = runners;
                        vm.raceSummary = {
                            description: pastRace.description,
                            purse: pastRace.purse ? (pastRace.purse / 1000).toFixed(1) : "",
                            raceClass: {
                                name: pastRace.className,
                                abbr: pastRace.classCode
                            },
                            raceTypeId: pastRace.typeId,
                            surfaceName: pastRace.surfaceName,
                            distance:
                                pastRace.distanceValue.toFixed(1) +
                                pastRace.distanceCode.toLowerCase(),
                            trackAbbr: pastRace.trackCode
                        };
                        _setFavorites();
                    }
                })
                .then(function() {
                    vm.isLoading = false;
                    vm.loaded = true;
                    $timeout(function() {
                        $rootScope.$apply();
                    });
                })
                .catch(function() {
                    vm.isLoading = false;
                    vm.loaded = true;
                    $timeout(function() {
                        $rootScope.$apply();
                    });
                });
        }

        function init() {
            if (
                _.isUndefined(vm.raceNumber) ||
                _.isUndefined(vm.racePerfAbbr) ||
                _.isUndefined(vm.raceTrackAbbr) ||
                _.isUndefined(vm.raceTypeId)
            ) {
                throw new Error(
                    "RaceResultsDesktopDirectiveCtrl(): Missing mandatory data to retrieve race runners list."
                );
            }

            if ($rootScope.activeFeatures.enableGraphRaceResults) {
                _getRaceRunnersListByGraph(
                    vm.raceDate,
                    vm.raceTrackAbbr,
                    vm.raceNumber
                );
            } else {
                RaceResultsUseCase.getRaceRunnersList(
                    vm.racePerfAbbr,
                    vm.raceTrackAbbr,
                    vm.raceNumber,
                    vm.raceTypeId,
                    vm.raceDate
                )
                    .then(
                        function(race) {
                            //success
                            vm.runners = race.runners;
                            vm.raceSummary = race.raceSummary;
                            _setFavorites();
                        },
                        function() {
                            //error
                        }
                    )
                    .finally(function() {
                        vm.isLoading = false;
                        vm.loaded = true;
                    });
            }
        }

        init();
    }

    RaceResultsRunnersListDirectiveCtrl.$inject = [
        "RaceResultsUseCase",
        "RunnerReplayModalFac",
        "$rootScope",
        "GraphPastRaces",
        "WagerProfileFac",
        "$timeout"
    ];

    return RaceResultsRunnersListDirectiveCtrl;
});

